import React from "react"
import Button from "components/CustomButtons/Button"
import connectRedux from "../../../reduxjs/connectRedux"
import submissionsScoreServices from "../../../dataServices/submissionsScoreServices"

import * as XLSX from "xlsx"
import FileSaver from "file-saver"

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
const fileExtension = ".xlsx"

class ExportSubmissionScoringData extends React.Component {
	constructor({ challengeId, fileName }) {
		super({ challengeId, fileName })
	}

	handleOnClickExportData = (e) => {
		this.props.setLoadingSpinner()
		submissionsScoreServices
			.getSubmissionsScoreData(this.props.challengeId)
			.then((data) => {
				this.exportToSheet(data)
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("error", "Oops!", err.message)
				console.log("error getting submissions score data, err =", err.message)
			})
	}

	exportToSheet = (scoreData) => {
		var headingRow = []
		var rowDataArrayOfArray = []

		let maxCriteriaCount = 0
		scoreData.map((score, index) => {
			let criteriaSerial = 1
			let criteriaScoreKey = `criterion${criteriaSerial}Score`
			while (score.hasOwnProperty(criteriaScoreKey)) {
				criteriaSerial++
				criteriaScoreKey = `criterion${criteriaSerial}Score`
			}
			maxCriteriaCount = Math.max(maxCriteriaCount, criteriaSerial - 1)

			criteriaSerial = 1
			criteriaScoreKey = `commentOnCriterion${criteriaSerial}`
			while (score.hasOwnProperty(criteriaScoreKey)) {
				criteriaSerial++
				criteriaScoreKey = `commentOnCriterion${criteriaSerial}`
			}
			maxCriteriaCount = Math.max(maxCriteriaCount, criteriaSerial - 1)
		})

		rowDataArrayOfArray = scoreData.map((score, index) => {
			if (index == 0) {
				headingRow = [
					"solverUserId",
					"Solver Name",
					"Solver Email",

					"evaluatorUserId",
					"Evaluator Name",

					"solutionId",
					"submissions Title",

					"Total Score",
					"Average Score",
					"Score Variance"
				]

				let criteriaSerial = 1
				let criteriaScoreKey = `criterion${criteriaSerial}Score`
				while (criteriaSerial <= maxCriteriaCount) {
					headingRow.push(criteriaScoreKey)
					criteriaSerial++
					criteriaScoreKey = `criterion${criteriaSerial}Score`
				}

				headingRow.push("Evaluator's Total Score")

				criteriaSerial = 1
				criteriaScoreKey = `commentOnCriterion${criteriaSerial}`
				while (criteriaSerial <= maxCriteriaCount) {
					headingRow.push(criteriaScoreKey)
					criteriaSerial++
					criteriaScoreKey = `commentOnCriterion${criteriaSerial}`
				}
			}

			let row = [
				score.solverUserId,
				score.solverUserName,
				score.solverUserEmail,

				score.evaluatorUserId || null,
				score.evaluatorUserName || null,

				score.solutionId,
				score.submissionsTitle || null,
				score.hasOwnProperty("globalTotalScore") ? score.globalTotalScore : null,
				score.hasOwnProperty("globalAverageScore") ? score.globalAverageScore : null,
				score.hasOwnProperty("scoreVariance") ? score.scoreVariance : null
			]

			let criteriaSerial = 1
			let criteriaScoreKey = `criterion${criteriaSerial}Score`
			while (criteriaSerial <= maxCriteriaCount) {
				if (score.hasOwnProperty(criteriaScoreKey)) {
					row.push(score[criteriaScoreKey])
				} else {
					row.push(null)
				}
				criteriaSerial++
				criteriaScoreKey = `criterion${criteriaSerial}Score`
			}

			if (score.hasOwnProperty("totalScore")) {
				row.push(score.totalScore)
			} else {
				row.push(null)
			}

			criteriaSerial = 1
			criteriaScoreKey = `commentOnCriterion${criteriaSerial}`
			while (criteriaSerial <= maxCriteriaCount) {
				if (score.hasOwnProperty(criteriaScoreKey)) {
					row.push(score[criteriaScoreKey])
				} else {
					row.push(null)
				}

				criteriaSerial++
				criteriaScoreKey = `commentOnCriterion${criteriaSerial}`
			}

			return row
		})

		const ws = XLSX.utils.book_new()

		XLSX.utils.sheet_add_aoa(ws, [headingRow])
		XLSX.utils.sheet_add_aoa(ws, rowDataArrayOfArray, { origin: "A2", skipHeader: true })

		let columnWidthStyle = []
		headingRow.map((headerColumn) => {
			columnWidthStyle.push({ width: 20 })
		})
		ws["!cols"] = columnWidthStyle

		const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
		const data = new Blob([excelBuffer], { type: fileType })
		FileSaver.saveAs(data, this.props.fileName + fileExtension)
	}

	render = () => (
		<Button color="success" onClick={this.handleOnClickExportData}>
			Export Submissions Scores
		</Button>
	)
}

export default connectRedux(ExportSubmissionScoringData)
