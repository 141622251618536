import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/styles"
import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Grid from "@material-ui/core/Grid"
import Button from "components/CustomButtons/Button.jsx"

import Close from "@material-ui/icons/Close"
import Tooltip from "@material-ui/core/Tooltip"

import cx from "classnames"

import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle"

function Transition(props) {
	return <Slide direction="down" {...props} />
}

class PopupModal extends React.Component {
	render() {
		const { classes } = this.props
		return (
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modal
				}}
				open={true}
				maxWidth={"md"}
				fullWidth={true}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
					style={{
						margin: "0px 0px"
					}}
				>
					<Grid container direction="column" justify="center" alignItems="center">
						<Grid item>
							<h4 className={cx(classes.modalTitle, classes.modalTitleStyle)}>
								{this.props.popupHeaderText}
							</h4>
						</Grid>
					</Grid>
				</DialogTitle>

				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody + " " + classes.modalBody2}
				>
					<Grid container direction="column" justify="center" alignItems="center">
						<Grid item>{this.props.popupMessageText}</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color="danger" onClick={this.props.onSubmit}>
						{this.props.yesButtonText || "Yes"}
					</Button>
					<Button color="success" onClick={this.props.onCancel}>
						{this.props.cancelButtonText || "Cancel"}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

PopupModal.propTypes = {
	classes: PropTypes.object.isRequired,
	popupHeaderText: PropTypes.string.isRequired,
	popupMessageText: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
}

export default withStyles(modalStyle)(PopupModal)
