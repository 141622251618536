import React from "react"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Tooltip from "@material-ui/core/Tooltip"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"

import connectRedux from "reduxjs/connectRedux"
import Divider from "@material-ui/core/Divider"

import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import discussionServices from "dataServices/discussionServices.js"

import CardFooter from "components/Card/CardFooter"
import { Typography } from "@material-ui/core"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import ReactHtmlParser from 'html-react-parser'
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import userServices from "dataServices/userServices"

import DiscussionListView from "./DiscussionListView"

import dummyAvatar from "assets/img/faces/dummy-avatar-300x300.webp"

import styles from "./discussionStyles"
import constants from "../../../../constants"
import helper from "helper"
import htmlToDraft from "html-to-draftjs"
import FontOverridePatch from "utils/FontOverridePatch"

import CommentThread from "./CommentThread"

let discussionPerPageLimit = 10

class Discussion extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: 0,
			discussionList: [],
			loadMore: true,
			discussionLimit: discussionPerPageLimit,
			totalDiscussion: 0,
			editDiscussionMode: false,
			viewDiscussionDetailsMode: false,
			dataFetchDone: false,
			newDiscussionTitle: "",
			pageContent: null,
			editorState: EditorState.createEmpty(),
			currentDiscussion: null,
			currentDiscussionToEdit: null,
			canModerateDiscussion: false,
			moderateDiscussionMode: false,
			discussionIdDiscussionObjectMapper: new Map(),
			parentReplyId: "",
			editingReplyId: ""
		}
	}

	handleSimple = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleChange = (event, value) => {
		this.setState({ value })
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			editorState
		})
	}

	handleOnClickEditReply = (replyId) => {
		this.setState({ editingReplyId: replyId, editorState: EditorState.createEmpty() })
	}

	handleOnClickEditDiscussion = (discussion) => {
		this.setState({ currentDiscussionToEdit: discussion, moderateDiscussionMode: true })
	}

	handleOnclickCancelDiscussionEditModerate = () => {
		this.setState({
			moderateDiscussionMode: false,
			currentDiscussionToEdit: null
		})
	}

	deleteDiscussion = (discussionId) => {
		this.props.setLoadingSpinner()
		discussionServices
			.deleteDiscussionById(discussionId)
			.then((data) => {
				this.fetchDiscussion().then((discussions) => {
					let discussionIdDiscussionObjectMapper = this.state.discussionIdDiscussionObjectMapper
					discussions.map((discussion) => {
						discussionIdDiscussionObjectMapper.set(discussion.discussionId, discussion)
					})
					this.setState({
						discussionList: discussions,
						discussionIdDiscussionObjectMapper: discussionIdDiscussionObjectMapper,
						totalDiscussion: discussions.length,
						dataFetchDone: true
					})
				})
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("warning", "Oops!", err.message)
			})
	}

	fetchDiscussion = () => {
		this.props.setLoadingSpinner()
		return discussionServices
			.getDiscussionByChallengeId(0, this.state.discussionLimit, this.props.challengeId)
			.then((data) => {
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				return []
			})
	}

	loadMoreDiscussions = () => {
		this.setState(
			{
				discussionLimit: this.state.discussionLimit + discussionPerPageLimit
			},
			() => {
				this.fetchDiscussion().then((discussions) => {
					let loadMore = true
					if (this.state.totalDiscussion == discussions.length) loadMore = false
					let totalDiscussion = discussions.length
					let discussionIdDiscussionObjectMapper = this.state.discussionIdDiscussionObjectMapper
					discussions.map((discussion) => {
						discussionIdDiscussionObjectMapper.set(discussion.discussionId, discussion)
					})
					this.setState({
						discussionList: discussions,
						discussionIdDiscussionObjectMapper: discussionIdDiscussionObjectMapper,
						totalDiscussion: totalDiscussion,
						loadMore: loadMore
					})
				})
			}
		)
	}

	addNewDiscussion = () => {
		let rteContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))

		if (!this.state.newDiscussionTitle || !helper.isRTEHtmlStringValid(rteContent))
			this.props.showAlert("warning", "Oops!", "You can not enter empty value")
		else {
			let variables = {
				challengeId: this.props.challengeId,
				title: this.state.newDiscussionTitle,
				discussionContent: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
			}
			this.props.setLoadingSpinner()
			discussionServices
				.createOrUpdateDiscussion(variables)
				.then((data) => {
					this.props.resetLoadingSpinner()
					this.setState({
						editDiscussionMode: false,
						newDiscussionTitle: "",
						editorState: EditorState.createEmpty()
					})
					this.fetchDiscussion()
						.then((discussions) => {
							let discussionIdDiscussionObjectMapper = this.state.discussionIdDiscussionObjectMapper
							discussions.map((discussion) => {
								discussionIdDiscussionObjectMapper.set(discussion.discussionId, discussion)
							})
							this.setState({
								discussionList: discussions,
								discussionIdDiscussionObjectMapper: discussionIdDiscussionObjectMapper,
								totalDiscussion: discussions.length,
								dataFetchDone: true
							})
						})
						.catch((err) => {
							console.log("Error fetching discussions, err =", err.message)
						})
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					this.props.showAlert("warning", "Oops!", err.message)
				})
		}
	}

	updateDiscussion = (discussionId, newDiscussionTitle, rteContent) => {
		if (!newDiscussionTitle || !helper.isRTEHtmlStringValid(rteContent))
			this.props.showAlert("warning", "Oops!", "You can not enter empty value")
		else {
			let variables = {
				challengeId: this.props.challengeId,
				discussionId: discussionId,
				title: newDiscussionTitle,
				discussionContent: rteContent
			}
			this.props.setLoadingSpinner()
			discussionServices
				.createOrUpdateDiscussion(variables)
				.then((data) => {
					this.props.resetLoadingSpinner()
					this.setState({
						moderateDiscussionMode: false
					})
					this.fetchDiscussion()
						.then((discussions) => {
							let discussionIdDiscussionObjectMapper = this.state.discussionIdDiscussionObjectMapper
							discussions.map((discussion) => {
								discussionIdDiscussionObjectMapper.set(discussion.discussionId, discussion)
							})
							this.setState({
								discussionList: discussions,
								discussionIdDiscussionObjectMapper: discussionIdDiscussionObjectMapper,
								totalDiscussion: discussions.length,
								dataFetchDone: true
							})
						})
						.catch((err) => {
							console.log("Error fetching discussions, err =", err.message)
						})
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					this.props.showAlert("warning", "Oops!", err.message)
				})
		}
	}

	addNewReply = (discussionId, replyContent) => {
		if (!helper.isRTEHtmlStringValid(replyContent))
			this.props.showAlert("warning", "Oops!", "You can not enter empty value")
		else {
			let variables = {
				discussionId: discussionId,
				challengeId: this.props.challengeId,
				replies: {
					parentId: this.state.parentReplyId,
					replyContent: replyContent
				}
			}

			this.props.setLoadingSpinner()
			discussionServices
				.createOrUpdateDiscussion(variables)
				.then((discussion) => {
					this.props.resetLoadingSpinner()
					this.setState({
						editorState: EditorState.createEmpty()
					})
					this.fetchDiscussion().then((data) => {
						let currentDiscussionId = discussion.discussionId
						let currentDiscussion = discussion
						let discussionIdDiscussionObjectMapper = this.state.discussionIdDiscussionObjectMapper

						data.map((discussion) => {
							if (currentDiscussionId === discussion.discussionId) {
								currentDiscussion = discussion
							}
							discussionIdDiscussionObjectMapper.set(discussion.discussionId, discussion)
						})

						this.setState({
							discussionList: data,
							currentDiscussion: currentDiscussion,
							parentReplyId: "",
							discussionIdDiscussionObjectMapper: discussionIdDiscussionObjectMapper
						})
					})
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					this.props.showAlert("warning", "Oops!", err.message)
				})
		}
	}

	updateReply = (discussionId, replyId, replyContent) => {
		if (!helper.isRTEHtmlStringValid(replyContent))
			this.props.showAlert("warning", "Oops!", "You can not enter empty value")
		else {
			let variables = {
				discussionId: discussionId,
				challengeId: this.props.challengeId,
				replies: {
					replyId: replyId,
					replyContent: replyContent
				}
			}

			this.props.setLoadingSpinner()
			discussionServices
				.createOrUpdateDiscussion(variables)
				.then((discussion) => {
					this.props.resetLoadingSpinner()
					this.setState({
						editorState: EditorState.createEmpty()
					})

					this.fetchDiscussion().then((data) => {
						let currentDiscussionId = discussion.discussionId
						let currentDiscussion = discussion
						let discussionIdDiscussionObjectMapper = this.state.discussionIdDiscussionObjectMapper
						data.map((discussion) => {
							if (currentDiscussionId === discussion.discussionId) {
								currentDiscussion = discussion
							}
							discussionIdDiscussionObjectMapper.set(discussion.discussionId, discussion)
						})

						this.setState({
							discussionList: data,
							discussionIdDiscussionObjectMapper: discussionIdDiscussionObjectMapper,
							editReplyMode: false,
							currentDiscussionToEdit: null,
							currentReplyToEdit: null,
							editorState: EditorState.createEmpty(),
							currentDiscussion: currentDiscussion,
							editingReplyId: ""
						})
					})
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					this.props.showAlert("warning", "Oops!", err.message)
				})
		}
	}

	fetchCurrentUserProfile = () => {
		return userServices
			.getCurrentUser()
			.then((data) => {
				if (!data) return null
				return data
			})
			.catch((err) => {
				console.log("Error fetching currentUserProfile, err =", err.message)
				return null
			})
	}

	componentDidMount() {
		Promise.all([this.fetchCurrentUserProfile(), this.fetchDiscussion()])
			.then((result) => {
				let discussions = result[1] ? result[1] : []
				let userProfile = result[0] ? result[0] : null
				let canModerateDiscussion = false
				if (userProfile) {
					userProfile.roles.map((r) => {
						if (r.RoleId == "superAdmin" || r.RoleId == "licenseeAdmin") {
							canModerateDiscussion = true
						}
					})
				}

				let discussionIdDiscussionObjectMapper = this.state.discussionIdDiscussionObjectMapper
				discussions.map((discussion) => {
					discussionIdDiscussionObjectMapper.set(discussion.discussionId, discussion)
				})

				this.setState({
					discussionList: discussions,
					discussionIdDiscussionObjectMapper: discussionIdDiscussionObjectMapper,
					totalDiscussion: discussions.length,
					dataFetchDone: true,
					canModerateDiscussion: canModerateDiscussion
				})
			})
			.catch((err) => {
				console.log("Error fetching data, err =", err.message)
			})

		const { history } = this.props
		let hash = history.location.hash || ""
		this.handleLocationHashChange(hash)

		history.listen((location, action) => {
			let hash = location.hash || ""
			this.handleLocationHashChange(hash)
		})
	}

	handleLocationHashChange = (hash) => {
		if (hash) {
			hash = hash.substr(1)
			let discussion = null
			if (this.state.discussionIdDiscussionObjectMapper.has(hash)) {
				discussion = this.state.discussionIdDiscussionObjectMapper.get(hash)
			}
			if (discussion) {
				this.setState({
					viewDiscussionDetailsMode: true,
					currentDiscussion: discussion,
					moderateDiscussionMode: false,
					currentDiscussionToEdit: null,
					editorState: EditorState.createEmpty()
				})
			} else {
				discussionServices
					.getDiscussionById(hash)
					.then((discussion) => {
						if (discussion) {
							let discussionIdDiscussionObjectMapper = this.state.discussionIdDiscussionObjectMapper
							discussionIdDiscussionObjectMapper.set(discussion.discussionId, discussion)
							this.setState({
								viewDiscussionDetailsMode: true,
								currentDiscussion: discussion,
								discussionIdDiscussionObjectMapper: discussionIdDiscussionObjectMapper,
								moderateDiscussionMode: false,
								currentDiscussionToEdit: null,
								editorState: EditorState.createEmpty()
							})
						} else {
							throw new Error("Discussion post is not found")
						}
					})
					.catch((err) => {
						this.setState({
							viewDiscussionDetailsMode: false,
							currentDiscussion: null,
							moderateDiscussionMode: false,
							currentDiscussionToEdit: null,
							editorState: EditorState.createEmpty()
						})
						this.props.showAlert("error", "Oops!", "Discussion post is not found")
						this.props.history.push(this.props.history.location.pathname)
					})
			}
		} else {
			this.setState({
				viewDiscussionDetailsMode: false,
				currentDiscussion: null,
				moderateDiscussionMode: false,
				currentDiscussionToEdit: null,
				editorState: EditorState.createEmpty()
			})
		}
	}

	deleteReply = (discussionId, replyId) => {
		this.props.setLoadingSpinner()
		discussionServices
			.deleteReplyById(discussionId, replyId)
			.then((discussion) => {
				this.props.resetLoadingSpinner()
				this.fetchDiscussion().then((data) => {
					let currentDiscussionId = discussion.discussionId
					let currentDiscussion = discussion
					let discussionIdDiscussionObjectMapper = this.state.discussionIdDiscussionObjectMapper
					data.map((discussion) => {
						if (currentDiscussionId === discussion.discussionId) {
							currentDiscussion = discussion
						}
						discussionIdDiscussionObjectMapper.set(discussion.discussionId, discussion)
					})

					this.setState({
						discussionList: data,
						currentDiscussion: currentDiscussion,
						discussionIdDiscussionObjectMapper: discussionIdDiscussionObjectMapper
					})
				})
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("warning", "Oops!", err.message)
			})
	}

	renderDiscussionDetails = () => {
		const { classes } = this.props
		let discussion = this.state.currentDiscussion

		let avatarImageUrl = dummyAvatar
		if (discussion.discussionDetails.createdBy.avatarImagePath) {
			if (discussion.discussionDetails.createdBy.avatarImagePath.includes("http")) {
				avatarImageUrl = discussion.discussionDetails.createdBy.avatarImagePath
			} else {
				avatarImageUrl =
					constants.Settings.APIURL + discussion.discussionDetails.createdBy.avatarImagePath
			}
		}

		return (
			<div>
				<Button
					color="twitter"
					onClick={() => {
						this.props.history.push(this.props.history.location.pathname)
					}}
				>
					Go Back
				</Button>

				<Card raised color="gmgTheme">
					<CardHeader>
						<Typography className={classes.whiteTextColorStyle} variant="h4" color="textSecondary">
							<div style={{ textTransform: "capitalize" }}>
								{discussion.discussionDetails.title}
							</div>
						</Typography>
						<br />

						<Typography
							className={classes.whiteTextColorStyle}
							variant="body2"
							color="textSecondary"
							align="left"
						>
							<GridContainer>
								<GridItem>
									<img
										src={avatarImageUrl}
										style={{
											height: "50px",
											width: "50px",
											borderRadius: "50%"
										}}
										onError={(e) => (e.target.src = dummyAvatar)}
									/>
								</GridItem>
								<GridItem>
									<br />
									<div
										style={{ textTransform: "capitalize", fontWeight: "bold", fontSize: "1.3em" }}
									>
										{discussion.discussionDetails.createdBy.userName}
									</div>
								</GridItem>
							</GridContainer>
						</Typography>
					</CardHeader>
					<CardBody>
						<div>
							<FontOverridePatch>
								{ReactHtmlParser(discussion.discussionDetails.discussionContent || "")}
							</FontOverridePatch>
						</div>
					</CardBody>
					<CardFooter>
						<GridContainer direction="row" justify="space-between" alignItems="center">
							<GridItem>
								<Typography
									className={classes.whiteTextColorStyle}
									variant="body2"
									color="textSecondary"
									align="right"
								>
									<div>{new Date(discussion.discussionDetails.createdAt).toLocaleString()}</div>
								</Typography>
							</GridItem>

							<GridItem>
								<Typography
									className={classes.whiteTextColorStyle}
									variant="body2"
									color="textSecondary"
									align="right"
								>
									<div style={{ textTransform: "capitalize" }}>
										{discussion.discussionDetails.replies
											? discussion.discussionDetails.replies.length
											: "0"}{" "}
										Replies
									</div>
								</Typography>
							</GridItem>
						</GridContainer>
					</CardFooter>
				</Card>

				<CommentThread
					replies={discussion.discussionDetails.replies}
					parentId=""
					editingReplyId={this.state.editingReplyId}
					onClickEditReply={this.handleOnClickEditReply}
					onClickUpdateReply={(replyId, replyContent) => {
						this.updateReply(discussion.discussionId, replyId, replyContent)
					}}
					onClickDeleteReply={(replyId) => {
						this.deleteReply(discussion.discussionId, replyId)
					}}
					onClickReplySetParentReplyId={(parentReplyId) => {
						this.setState({ parentReplyId: parentReplyId })
					}}
					replyingToParentId={this.state.parentReplyId}
					onClickAddReply={(replyContent) => {
						this.addNewReply(discussion.discussionId, replyContent)
					}}
					canEditDeleteModerateReply={this.canEditDeleteModerateReply}
				/>

				{this.state.editingReplyId === "" && !this.state.parentReplyId && (
					<Card raised profile color="gmgTheme">
						<CardBody>
							<div
								style={{
									border: "1px solid grey",
									marginTop: "50px",
									minHeight: "100px",
									backgroundColor: "#FFF"
								}}
							>
								<FontOverridePatch isDraftEditor>
									<Editor
										editorState={this.state.editorState}
										toolbarClassName={classes.toolbarClassName}
										wrapperClassName={classes.wrapperClassName}
										editorClassName={classes.editorClassName}
										className={classes.editRulesStyle}
										toolbar={{
											options: [
												"inline",
												"blockType",
												"fontSize",
												"fontFamily",
												"list",
												"textAlign",
												"colorPicker",
												"link",
												"embedded",
												"remove",
												"history"
											]
										}}
										onEditorStateChange={this.onEditorStateChange}
									/>
								</FontOverridePatch>
							</div>
							<Button
								style={{ marginTop: "20px" }}
								className={classes.buttonSuccessColor}
								disabled={
									!helper.isRTEHtmlStringValid(
										helper.getHtmlStringFromEditorState(this.state.editorState)
									)
								}
								onClick={() => {
									let replyContent = helper.getHtmlStringFromEditorState(this.state.editorState)
									this.addNewReply(discussion.discussionId, replyContent)
								}}
							>
								Add Reply
							</Button>
						</CardBody>
					</Card>
				)}
			</div>
		)
	}

	renderAddDiscussionEditor = () => {
		const { classes } = this.props

		return (
			<Card profile color="gmgTheme">
				<CardHeader color="gmgTheme">
					<div style={{ textTransform: "capitalize" }}>Add New Discussion</div>
				</CardHeader>
				<CardBody>
					<TextField
						label="Discussion Title"
						className={classes.inputRoot}
						fullWidth
						InputLabelProps={{
							className: classes.inputLabel
						}}
						value={this.state.newDiscussionTitle || ""}
						onChange={this.handleSimple}
						name={"newDiscussionTitle"}
						inputProps={{
							className: classes.textField,
							overflow: "auto"
						}}
					/>
					<br />
					<br />
					<div style={{ border: "1px solid grey" }}>
						<FontOverridePatch isDraftEditor>
							<Editor
								editorState={this.state.editorState}
								toolbarClassName={classes.toolbarClassName}
								wrapperClassName={classes.wrapperClassName}
								editorClassName={classes.editorClassName}
								className={classes.editRulesStyle}
								toolbar={{
									options: [
										"inline",
										"blockType",
										"fontSize",
										"fontFamily",
										"list",
										"textAlign",
										"colorPicker",
										"link",
										"embedded",
										"remove",
										"history"
									]
								}}
								onEditorStateChange={this.onEditorStateChange}
							/>
						</FontOverridePatch>
					</div>
				</CardBody>
				<CardFooter>
					<GridContainer direction="row" justify="space-between">
						<GridItem>
							<Button
								color="warning"
								onClick={() => {
									this.setState({ editDiscussionMode: false })
								}}
							>
								Cancel
							</Button>
						</GridItem>
						<GridItem>
							<Button
								className={classes.buttonSuccessColor}
								disabled={
									!helper.isRTEHtmlStringValid(
										draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
									) || !this.state.newDiscussionTitle
								}
								onClick={() => {
									this.addNewDiscussion()
								}}
							>
								Add
							</Button>
						</GridItem>
					</GridContainer>
				</CardFooter>
			</Card>
		)
	}

	canEditDeleteModerateReply = (reply) => {
		if (this.state.canModerateDiscussion) {
			return true
		}

		if (reply.createdBy.userId === this.props.loggedInUserData.userId) {
			return true
		}

		return false
	}

	render() {
		const { classes } = this.props

		let xs = 12
		let sm = 12
		let md = 12
		let lg = 12

		return (
			<div className={classes.container}>
				<GridContainer justify="center">
					<GridItem xs={xs} sm={sm} md={md} lg={lg}>
						{this.state.editDiscussionMode && !this.state.viewDiscussionDetailsMode ? (
							this.renderAddDiscussionEditor()
						) : !this.state.editDiscussionMode && this.state.viewDiscussionDetailsMode ? (
							this.renderDiscussionDetails()
						) : !this.state.editDiscussionMode && !this.state.viewDiscussionDetailsMode ? (
							<DiscussionListView
								history={this.props.history}
								dataFetchDone={this.state.dataFetchDone}
								loadMore={this.state.loadMore}
								onClickLoadMore={this.loadMoreDiscussions}
								discussionList={this.state.discussionList}
								moderateDiscussionMode={this.state.moderateDiscussionMode}
								currentDiscussionToEdit={this.state.currentDiscussionToEdit}
								canModerateDiscussion={this.state.canModerateDiscussion}
								onClickCancelEditModerate={this.handleOnclickCancelDiscussionEditModerate}
								onClickUpdateDiscussion={this.updateDiscussion}
								onClickEditDiscussion={this.handleOnClickEditDiscussion}
								onClickDeleteDiscussion={this.deleteDiscussion}
							/>
						) : null}

						<div className={classes.addDiscussionButtonStyle}>
							{!this.state.editDiscussionMode && !this.state.viewDiscussionDetailsMode ? (
								<Button
									className={classes.buttonSuccessColor}
									onClick={() => {
										this.setState({ editDiscussionMode: true })
									}}
								>
									Add Discussion
								</Button>
							) : null}
						</div>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

Discussion.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

export default connectRedux(withStyles(styles)(Discussion))
