export const getBreadcrumArray = (challenge) => {
  const breadCrumbArray = [
    {
      link: `/dashboard/index`,
      name: `Dashboard`,
    },
    {
      link: `/dashboard/challenge-details/${challenge.urlSlug}/overview`,
      name: `${challenge.challengeDetails.title}`,
    },
    {
      link: `/dashboard/submissions/leaderboard/${challenge.urlSlug}`,
      name: "Submissions Leaderboard",
    },
  ];

  return breadCrumbArray;
};