import apolloFetcher from "apolloFetcher"
import helper from "helper"

let formServices = {}

formServices.createOrUpdate = (formId, challengeId, formData, phaseId, formName) => {
	let query = `
  mutation createOrUpdateForm(
    $formId: String
    $challengeId: String!
    $phaseId: String
    $formDataStringified: String
    $formName: String!
  ) {
    createOrUpdateForm(
      form: {
        formId: $formId
        challengeId: $challengeId
        phaseId: $phaseId
        formDataStringified: $formDataStringified
        formName: $formName
      }
    ) {
      status
      message
      data {
        challengeId
        formId
        phaseId
        formDataStringified
        formName
      }
      totalCount
    }
  }
	`
	formData.components = helper.configureFileUploadApiOnForm(formData.components)
	let variables = {
		formId: formId || undefined,
		challengeId: challengeId,
		formDataStringified: JSON.stringify(formData),
		phaseId: phaseId || undefined,
		formName: formName
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then(res => {
				if (!res || !res.data || !res.data.createOrUpdateForm)
					throw new Error("Error occured while creating or updating aboutPage")
				if (!res.data.createOrUpdateForm.status)
					throw new Error(res.data.createOrUpdateForm.message)
				res.data.createOrUpdateForm.data.formData = JSON.parse(
					res.data.createOrUpdateForm.data.formDataStringified
				)
				res.data.createOrUpdateForm.data.formData.components = helper.configureFileUploadApiOnForm(
					res.data.createOrUpdateForm.data.formData.components
				)
				resolve(res.data.createOrUpdateForm.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}

formServices.getFormByChallengeId = challengeId => {
	let query = `
  query getFormByChallengeId($challengeId: String!) {
    getFormByChallengeId(challengeId: $challengeId) {
      status
      message
      data {
        challengeId
        formId
        phaseId
        formDataStringified
        formName
      }
      totalCount
    }
  }
  `
	let variables = {
		challengeId: challengeId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then(res => {
				if (!res || !res.data || !res.data.getFormByChallengeId)
					throw new Error("Error occured while creating or updating aboutPage")
				if (!res.data.getFormByChallengeId.status)
					throw new Error(res.data.getFormByChallengeId.message)
				res.data.getFormByChallengeId.data.formData = JSON.parse(
					res.data.getFormByChallengeId.data.formDataStringified
				)
				res.data.getFormByChallengeId.data.formData.components = helper.configureFileUploadApiOnForm(
					res.data.getFormByChallengeId.data.formData.components
				)
				resolve(res.data.getFormByChallengeId.data)
			})
			.catch(err => {
				console.log("Error getting the forms for challenge, err=", err.message)
				reject(err)
			})
	})
}

formServices.getAllFormsByChallengeId = challengeId => {
	let query = `
  query getAllFormsByChallengeId($challengeId: String!) {
    getAllFormsByChallengeId(challengeId: $challengeId) {
      status
      message
      data {
        challengeId
        formId
        phaseId
        formDataStringified
        formName
      }
      totalCount
    }
  }
  `
	let variables = {
		challengeId: challengeId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then(res => {
				if (!res || !res.data || !res.data.getAllFormsByChallengeId)
					throw new Error("Error occured while creating or updating aboutPage")
				if (!res.data.getAllFormsByChallengeId.status)
					throw new Error(res.data.getAllFormsByChallengeId.message)
				if (!res.data.getAllFormsByChallengeId.data)
					throw new Error("No forms found for the challenge")
				res.data.getAllFormsByChallengeId.data = res.data.getAllFormsByChallengeId.data.map(f => {
					let form = {
						...f,
						formData: JSON.parse(f.formDataStringified)
					}
					form.formData.components = helper.configureFileUploadApiOnForm(form.formData.components)
					return form
				})
				resolve(res.data.getAllFormsByChallengeId.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}

formServices.deleteById = formId => {
	let query = `
	mutation deleteFormById($formId: String!) {
		deleteFormById(formId: $formId) {
			status
			message
		}
	}
	`
	let variables = {
		formId: formId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then(res => {
				if (!res || !res.data || !res.data.deleteFormById)
					throw new Error("Error occured while creating or updating aboutPage")
				if (!res.data.deleteFormById.status) throw new Error(res.data.deleteFormById.message)
				resolve(res.data.deleteFormById.status)
			})
			.catch(err => {
				reject(err)
			})
	})
}

export default formServices
