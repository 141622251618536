import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import { Divider } from '@material-ui/core';


const reviewStyles = theme => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2)
  },
});


class Review extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid>
          <Typography variant="h6" gutterBottom>
            Invitations for
          </Typography>
          <Divider variant="outset" />

          {this.props.selectedUsers && (
            this.props.selectedUsers.map(user => {
              return (
                <div>
                  <Typography variant="h7" gutterBottom>
                    {user.name || "Invitee User"}
                  </Typography>
                  <br />
                  <Typography variant="h7" gutterBottom>
                    Email: {user.email}
                  </Typography>
                  <br />
                  <Typography variant="h7" gutterBottom>
                    Role: {user.role}
                  </Typography>
                  <Divider variant="outset" />
                </div>)
            })
          )}

        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(reviewStyles)(Review);