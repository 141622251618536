import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import { Divider, Paper, GridList } from '@material-ui/core';
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Accordion from "components/Accordion/Accordion";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import combineStyles from "utils/combineStyle";
import Table from "components/Table/Table.jsx";

const roleStyle = theme => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: '700',
  },
  title: {
    marginTop: theme.spacing(2),
  },
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
});


class AddRole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: (this.props.usersRole ? this.props.usersRole : ""),
      opens: props.selectedUsers.map(() => false),
    }
  }

  handleChange = (index, value) => {
    let selectedUsers = this.props.selectedUsers.map((user, i) => {
      if (i == index) return { ...user, role: value }
      return { ...user }
    })
    this.props.setSelectedUsers(selectedUsers);
  }

  handleDelete = index => () => {
    const selectedItem = [...this.props.selectedItem];
    selectedItem.splice(index, 1);
    this.props.setSelectedUsers(selectedItem);
    this.setState({ opens: selectedItem.map(() => false) });
  };

  handleClose = (index) => {
    let opens = this.state.opens.map((v, i) => (i == index ? false : v))
    this.setState({ opens: opens });
  }

  handleOpen = (index) => {
    let opens = this.state.opens.map((v, i) => (i == index ? true : v))
    this.setState({ opens: opens });
  }

  render() {
    const { classes } = this.props
    const { selectedUsers } = this.props;
    let tableData = selectedUsers.map((user, index) => {
      return [
        (user.name ? user.name : "Invitee User"),
        user.email,
        (<div>
          <form autoComplete="off">
            <FormControl className={classes.formControl}>
              <InputLabel> Role </InputLabel>
              <Select
                value={user.role}
                onChange={e => this.handleChange(index, e.target.value)}
              >
                {this.props.availableRoles && this.props.availableRoles.map((role, jndex) => {
                  return <MenuItem value={role.value} key={jndex} >{role.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </form>
        </div>
        )
      ]
    })

    return (
      <React.Fragment>
        <Grid>
          <Typography variant="h6" gutterBottom>
            User Details
          </Typography>
          <Divider variant="outset" />
          <Table
            tableHead={["Name", "Email", "Role"]}
            tableData={tableData}
          />
        </Grid>
      </React.Fragment>
    );
  }
}

let finalStyles = combineStyles(extendedTablesStyle, roleStyle);

export default withStyles(finalStyles)(AddRole)