import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import { NavLink } from "react-router-dom"

import combineStyles from "utils/combineStyle"
import commonStyles from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../../helper"
import constants from "../../../constants"

/*
solutions
classes
scores
*/

class AssignedSolutionsList extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { classes } = this.props
		if (!this.props.solutions || !this.props.solutions.length) return null

		return (
			<Paper className={classes.root}>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell>Team/Solver</TableCell>
							<TableCell align="center">Submission</TableCell>
							<TableCell align="center">Date</TableCell>
							<TableCell align="center">Status</TableCell>
							<TableCell align="center">Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{this.props.solutions.map((row, rowIndex) => (
							<TableRow key={row.solutionId}>
								<TableCell align="left" scope="row">
									{row.solutionDetails.teamName || row.solutionDetails.userName}
								</TableCell>
								<TableCell align="center" style={{ color: "blue" }}>
									<NavLink
										to="#"
										onClick={(e) => this.props.handleOnClickSubmissionTitle(e, rowIndex)}
									>
										{row.solutionDetails.title ||
											helper.getSubmissionName(
												row.solutionDetails.userName,
												row.solutionDetails.teamName,
												row.solutionId
											)}
									</NavLink>
								</TableCell>
								<TableCell align="center">
									{row.solutionDetails.submissionDate
										? row.solutionDetails.submissionDate.split("T")[0]
										: "N/A"}
								</TableCell>

								{!row.solutionDetails.resubmittedAfterScoring &&
								this.props.scores[rowIndex] &&
								this.props.scores[rowIndex].id ? (
									<TableCell align="center" h1 style={{ color: "green" }}>
										Review Completed
									</TableCell>
								) : (
									<TableCell align="center" h1 style={{ color: "red" }}>
										Under Review
										{row.solutionDetails.resubmittedAfterScoring === true
											? ": Updated Solution"
											: ""}
									</TableCell>
								)}

								{this.props.scores[rowIndex] && this.props.scores[rowIndex].id ? (
									<TableCell align="center">
										<Button
											color="primary"
											onClick={() => {
												this.props.onClickSeeScore(rowIndex)
											}}
										>
											See scores
										</Button>
									</TableCell>
								) : (
									<TableCell align="center">
										<Button disabled> See scores </Button>
									</TableCell>
								)}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Paper>
		)
	}
}

export default AssignedSolutionsList
