import React from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Constants from "constants.js"
import authentication from "authentication.js"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import aboutPageServices from "dataServices/aboutPageServices.js"
import CardFooter from "components/Card/CardFooter"
import { Typography, ListItemIcon, TableHead, TableCell, TableRow } from "@material-ui/core"
import { Redirect, NavLink } from "react-router-dom"
import CardAvatar from "components/Card/CardAvatar"
import emptyAvatar from "assets/img/faces/emptyProfilePicture.webp"
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx"
import LaunchIcon from "@material-ui/icons/Launch"
import ManageChallengeMenu from "../Pages/ManageChallengeMenu.jsx"
import challengeServices from "dataServices/challengeServices.js"
import solutionServices from "dataServices/solutionServices.js"
import userServices from "dataServices/userServices.js"
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"
import ErrorPage from "views/Pages/ErrorPage"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import Table from "components/Table/Table.jsx"
import TableBody from "@material-ui/core/TableBody"
import validator from "validator"
import Pagination from "material-ui-flat-pagination"

import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"

var solverByStateMap = new Map()
var reverseSolverByStateMap = new Map()
var solverBySubmissionsMap = new Map()
var reverseSolverBySubmissionsMap = new Map()

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	textAlignCenter: {
		textAlign: "center"
	},
	totalSolverCountStyle: {
		backgroundColor: "#E0296A"
	},
	reportPageTableFixedHeightStyle: {
		height: "250px",
		overflow: "auto"
	},
	paginationDiv: {
		width: "100%",
		color: theme.palette.text.cardBody
	},
	...userProfileStyles,
	...extendedTablesStyle
})

class ReportsPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			showErrorComponent: false,
			dataFetchDone: false,
			challengeTitle: null,
			challengeId: null,
			urlSlug: "",
			challenge: null,
			totalSolverCount: 0,
			totalTeamCount: 0,
			totalSubmissionCount: 0,
			allSolversByChallengeId: [],
			solverCountByState: [],
			solverBySubmissionCountByState: [],
			winnerCountByAwardType: [],
			participantsPagination: {
				pageNo: 1,
				itemsPerPage: 10,
				totalCount: 0,
				totalFetched: 0
			},
			participants: []
		}
	}

	setBreadCrumb = (title) => {
		this.setState(
			{
				challengeTitle: title
			},
			() => {
				if (title.length > 50) title = title.substring(0, 50) + " ..."
				this.props.pushBreadCrumbStack({
					name: title,
					link: "/dashboard/reports/" + this.state.challengeId
				})
			}
		)
	}

	setSolverAndTeamCount = (participants) => {
		let tempSolverCount = 0
		let teamMapper = new Map()
		if (participants && participants.length) {
			participants.map((participant) => {
				if (participant.teamId) {
					if (teamMapper.has(participant.teamId))
						teamMapper.set(participant.teamId, teamMapper.get(participant.teamId) + 1)
					else teamMapper.set(participant.teamId, 1)
				} else tempSolverCount++
			})
		}
		this.setState({
			totalSolverCount: tempSolverCount,
			totalTeamCount: teamMapper.size
		})
	}

	setSubmissionCount = (submissions) => {
		submissions = submissions.filter((submission) => submission.solutionDetails.officialSubmission)
		this.setState({
			totalSubmissionCount: submissions.length
		})
	}

	setAllSolverByChallengeId = (allSolversByChallengeId) => {
		this.setState({
			allSolversByChallengeId: allSolversByChallengeId
		})
	}

	setSolverCountByStates = (allSolversByChallengeId) => {
		solverByStateMap.clear()
		reverseSolverByStateMap.clear()
		var counter = 0
		var tempSolverCountByState = []
		if (
			allSolversByChallengeId &&
			allSolversByChallengeId.data &&
			allSolversByChallengeId.data.length
		) {
			allSolversByChallengeId.data.map((solver) => {
				if (solver.state) {
					if (!solverByStateMap.has(solver.state)) {
						solverByStateMap.set(solver.state, counter)
						reverseSolverByStateMap.set(counter, solver.state)
						counter++
						tempSolverCountByState[solverByStateMap.get(solver.state)] = 1
					} else {
						tempSolverCountByState[solverByStateMap.get(solver.state)]++
					}
				}
			})
		}

		this.setState({
			solverCountByState: tempSolverCountByState
		})
	}

	setSolverCountWhoSubmittedSolutionByStates = (userList) => {
		solverBySubmissionsMap.clear()
		reverseSolverBySubmissionsMap.clear()
		var counter = 0
		var tempSolverBySubmissionCount = []
		userList.map((user) => {
			if (!user.teamId && user.state) {
				if (!solverBySubmissionsMap.has(user.state)) {
					solverBySubmissionsMap.set(user.state, counter)
					reverseSolverBySubmissionsMap.set(counter, user.state)
					counter++
					tempSolverBySubmissionCount[solverBySubmissionsMap.get(user.state)] = 1
				} else {
					tempSolverBySubmissionCount[solverBySubmissionsMap.get(user.state)]++
				}
			}
		})

		this.setState({
			solverBySubmissionCountByState: tempSolverBySubmissionCount
		})
	}

	setWinnerCount = (leaderboards) => {
		let tempWinnerCounter = []
		let temp
		if (leaderboards) {
			leaderboards.map((stage) => {
				if (!tempWinnerCounter[stage.standing]) {
					tempWinnerCounter[stage.standing] = 1
				} else {
					tempWinnerCounter[stage.standing]++
				}
			})
		}
		this.setState({
			winnerCountByAwardType: tempWinnerCounter
		})
	}

	fetchParticipantsByChallengeId = () => {
		let requiredDataField = `
            challengeDetails{
                title
                participants{
                    userId
                    teamId
                    role
                }
                leaderboard{
                    solutionId
                    submissionFileName
                    submissionFilePath
                    solverUserId
                    userName
                    solverTeamId
                    teamName
                    averageScore
                    variance
                    standing
                    remarks
                }
            }
						`
		return challengeServices
			.getChallengeById(this.state.challengeId, requiredDataField)
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log(err.message)
				return []
			})
	}

	fetchParticipantsPaginated = () => {
		return challengeServices
			.getChallengeParticipantsPaginated(
				this.state.challengeId,
				this.state.participantsPagination.pageNo,
				this.state.participantsPagination.itemsPerPage
			)
			.then((data) => {
				console.log("participants = ", data)
				return data
			})
			.catch((err) => {
				console.log("Error = ", err.message)
				return null
			})
	}

	fetchSolutionByChallengeId = () => {
		return solutionServices
			.getSolutionsByChallengeId(this.state.challengeId)
			.then((data) => {
				if (!data) throw new Error("An error occurred while fetching the solutions")
				return data
			})
			.catch((err) => {
				console.log(err.message)
				return []
			})
	}

	fetchAllsolversByChallengeId = () => {
		return userServices
			.getAllsolversByChallengeId(this.state.challengeId)
			.then((data) => {
				if (!data) throw new Error("Error fetching solvers")
				return data
			})
			.catch((err) => {
				console.log(err.message)
				return { data: [], totalCount: 0 }
			})
	}

	fetchSolversWhoSubmittedSolutionByChallengeId = () => {
		return solutionServices
			.getSolversWhoSubmittedSolutionByChallengeId(this.state.challengeId)
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log(err.message)
				return []
			})
	}

	fetchTeamStatesCount = (teamsParticipanrts) => {
		console.log("teams = ", teamsParticipanrts)
		return Promise.all(
			teamsParticipanrts.map((p) => {
				return userServices.getUserProfileById(p.userId).then((user) => {
					return { userId: user.userId, state: user.state }
				})
			})
		)
			.then((results) => {
				console.log("results= ", results)
				let stateUserMapper = new Map()
				results.map((u) => {
					if (!u.state) {
						return
					}
					if (stateUserMapper.has(u.state)) {
						stateUserMapper.set(u.state, stateUserMapper.get(u.state) + 1)
					} else {
						stateUserMapper.set(u.state, 1)
					}
				})
				let teamCountByState = []
				;[...stateUserMapper.keys()].map((stateName) => {
					teamCountByState.push({ state: stateName, count: stateUserMapper.get(stateName) })
				})
				this.setState({ teamCountByState: teamCountByState })
				return teamCountByState
			})
			.catch((err) => {
				console.log("Error getting team state data = ", err.message)
				return []
			})
	}

	fetchTeamSubmittedSolutionByStatesCount = (teamsSubmitted) => {
		return Promise.all(
			teamsSubmitted.map((t) => {
				return userServices.getUserProfileById(t.userId).then((user) => {
					return { userId: user.userId, state: user.state }
				})
			})
		).then((results) => {
			let stateUserMapper = new Map()
			results.map((u) => {
				if (!u.state) {
					return
				}
				if (stateUserMapper.has(u.state)) {
					stateUserMapper.set(u.state, stateUserMapper.get(u.state) + 1)
				} else {
					stateUserMapper.set(u.state, 1)
				}
			})
			let teamCountByState = []
			;[...stateUserMapper.keys()].map((stateName) => {
				teamCountByState.push({ state: stateName, count: stateUserMapper.get(stateName) })
			})
			this.setState({ teamSubmittedCountByState: teamCountByState })
			return teamCountByState
		})
	}

	getChallengeById() {
		return challengeServices
			.getChallengeById(this.state.urlSlug)
			.then((data) => {
				return data
			})
			.catch((err) => {
				return null
			})
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		this.getChallengeById()
			.then((challenge) => {
				this.setState({ challenge: challenge, challengeId: challenge.challengeId })
				return Promise.all([
					this.fetchParticipantsByChallengeId(),
					this.fetchSolutionByChallengeId(),
					this.fetchAllsolversByChallengeId(),
					this.fetchSolversWhoSubmittedSolutionByChallengeId(),
					this.fetchParticipantsPaginated()
				])
			})
			.then((results) => {
				console.log("results = ", results)
				this.props.resetLoadingSpinner()
				this.setBreadCrumb(results[0].challengeDetails.title)
				this.setSolverAndTeamCount(results[0].challengeDetails.participants)
				this.setWinnerCount(results[0].challengeDetails.leaderboard)
				this.setSubmissionCount(results[1])
				this.setAllSolverByChallengeId(results[2])
				this.setSolverCountByStates(results[2])
				this.setSolverCountWhoSubmittedSolutionByStates(results[3])
				if (results[4]) {
					this.setState({
						participantsPagination: {
							...this.state.participantsPagination,
							totalCount: results[4].totalCount,
							totalFetched: results[4].totalFetched
						},
						participants: results[4].data
					})
				}
				return results
			})
			.then((results) => {
				return Promise.all(
					results.map((r, i) => {
						if (i == 0) {
							let teamsParticipanrts = r.challengeDetails.participants || []
							teamsParticipanrts = teamsParticipanrts.filter(
								(p) => p.teamId != null && p.role == "teamLead"
							)
							return this.fetchTeamStatesCount(teamsParticipanrts)
						} else if (i == 1) {
							let teamsSubmitted = r || []
							teamsSubmitted = teamsSubmitted.filter((s) => s.teamId)
							return this.fetchTeamSubmittedSolutionByStatesCount(teamsSubmitted)
						} else {
							return r
						}
					})
				).then((result) => {
					this.setState({
						dataFetchDone: true
					})
				})
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log(err.message)
			})
	}

	onClickParticipantsPaginationButton = (offset) => {
		let currentPage = offset / this.state.participantsPagination.itemsPerPage + 1
		this.setState(
			{
				participantsPagination: {
					...this.state.participantsPagination,
					pageNo: currentPage
				}
			},
			() => {
				this.props.setLoadingSpinner()
				this.fetchParticipantsPaginated()
					.then((data) => {
						this.props.resetLoadingSpinner()
						if (!data) throw new Error("Error occured while getting the participants list")
						this.setState({
							participantsPagination: {
								...this.state.participantsPagination,
								totalCount: data.totalCount,
								totalFetched: data.totalFetched
							},
							participants: data.data
						})
					})
					.catch((err) => {
						this.props.resetLoadingSpinner()
						console.log("error occured while fetching challenge participants data", err.message)
						this.props.showAlert("error", "Opps!", err.message)
					})
			}
		)
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props
		console.log("challengeId inside params", params.challengeId)
		if (params.challengeId !== ":challengeId") {
			this.setState({
				showErrorComponent: false
			})
		} else {
			this.props.setRedirectOnError("/home/index")
		}
		if (validator.isUUID(params.challengeId)) {
			this.setState({ challengeId: params.challengeId }, () => {
				this.fetchAllData()
			})
		} else {
			this.setState({ urlSlug: params.challengeId }, () => {
				this.fetchAllData()
			})
		}
	}

	renderSolverCount = () => {
		const { classes } = this.props
		let totalSolverCount = this.state.totalSolverCount
		return (
			<Card raised>
				<CardBody profile>
					<h4 className={`${classes.cardCategory}`}>Total no of solvers</h4>
					<Divider variant="middle" />
					<h3 className={`${classes.cardTitle} ${classes.textAlignCenter}`}>{totalSolverCount}</h3>
				</CardBody>
			</Card>
		)
	}

	renderTeamCount = () => {
		const { classes } = this.props
		let totalTeamCount = this.state.totalTeamCount
		return (
			<Card raised>
				<CardBody profile>
					<h4 className={`${classes.cardCategory}`}>Total no of teams</h4>
					<Divider variant="middle" />
					<h3 className={`${classes.cardTitle} ${classes.textAlignCenter}`}>{totalTeamCount}</h3>
				</CardBody>
			</Card>
		)
	}

	renderSubmissionCount = () => {
		const { classes } = this.props
		let totalSubmissionCount = this.state.totalSubmissionCount
		return (
			<Card raised>
				<CardBody profile>
					<h4 className={`${classes.cardCategory}`}>Total no of Submissions</h4>
					<Divider variant="middle" />
					<h3 className={`${classes.cardTitle} ${classes.textAlignCenter}`}>
						{totalSubmissionCount}
					</h3>
				</CardBody>
			</Card>
		)
	}

	renderSolverByStates = () => {
		const { classes } = this.props
		let solverCountByState = this.state.solverCountByState
		let solverCountByStateTableData = solverCountByState.map((solverCount, index) => {
			return [
				<Typography variant="body2" color="textPrimary" align="center">
					{reverseSolverByStateMap.get(index)}
				</Typography>,
				<Typography variant="body2" color="textPrimary" align="center">
					{solverCount}
				</Typography>
			]
		})
		return (
			<Card raised>
				<CardBody profile>
					<h4 className={`${classes.cardCategory}`}>List of solvers by state</h4>
					{!solverCountByState.length ? (
						<div style={{ height: "250px", paddingTop: "100px" }}>
							<Typography variant="subtitle2" color="textPrimary" align="center">
								No Solver data found
							</Typography>
						</div>
					) : (
						<div className={classes.reportPageTableFixedHeightStyle}>
							<Table
								tableHeaderColor="rose"
								hover
								tableHead={[
									<Typography variant="body2" color="textPrimary" align="center">
										State
									</Typography>,
									<Typography variant="body2" color="textPrimary" align="center">
										No. of Solver
									</Typography>
								]}
								tableData={solverCountByStateTableData}
							/>
						</div>
					)}
				</CardBody>
			</Card>
		)
	}

	renderTeamByStates = () => {
		const { classes } = this.props
		let teamCountByState = this.state.teamCountByState || []

		let teamCountByStateTableData = teamCountByState.map((tCount, index) => {
			return [
				<Typography variant="body2" color="textPrimary" align="center">
					{tCount.state}
				</Typography>,
				<Typography variant="body2" color="textPrimary" align="center">
					{tCount.count}
				</Typography>
			]
		})
		return (
			<Card raised>
				<CardBody profile>
					<h4 className={`${classes.cardCategory}`}>List of Teams by state</h4>
					{!teamCountByState.length ? (
						<div style={{ height: "250px", paddingTop: "100px" }}>
							<Typography variant="subtitle2" color="textPrimary" align="center">
								No Team data found
							</Typography>
						</div>
					) : (
						<div className={classes.reportPageTableFixedHeightStyle}>
							<Table
								tableHeaderColor="rose"
								hover
								tableHead={[
									<Typography variant="body2" color="textPrimary" align="center">
										State
									</Typography>,
									<Typography variant="body2" color="textPrimary" align="center">
										No. of Teams
									</Typography>
								]}
								tableData={teamCountByStateTableData}
							/>
						</div>
					)}
				</CardBody>
			</Card>
		)
	}

	renderSolverBySubmissionByStates = () => {
		const { classes } = this.props
		let solverBySubmissionCountByState = this.state.solverBySubmissionCountByState
		let solverBySubmissionCountByStateTableData = solverBySubmissionCountByState.map(
			(solverCount, index) => {
				return [
					<Typography variant="body2" color="textPrimary" align="center">
						{reverseSolverBySubmissionsMap.get(index)}
					</Typography>,
					<Typography variant="body2" color="textPrimary" align="center">
						{solverCount}
					</Typography>
				]
			}
		)
		return (
			<Card raised>
				<CardBody profile>
					<h4 className={`${classes.cardCategory}`}>
						List of solvers who have submitted solutions
					</h4>
					{!solverBySubmissionCountByState.length ? (
						<div style={{ height: "250px", paddingTop: "100px" }}>
							<Typography variant="subtitle2" color="textPrimary" align="center">
								No Solver data found
							</Typography>
						</div>
					) : (
						<div className={classes.reportPageTableFixedHeightStyle}>
							<Table
								tableHeaderColor="rose"
								hover
								tableHead={[
									<Typography variant="body2" color="textPrimary" align="center">
										State
									</Typography>,
									<Typography variant="body2" color="textPrimary" align="center">
										No. of Solver
									</Typography>
								]}
								tableData={solverBySubmissionCountByStateTableData}
							/>
						</div>
					)}
				</CardBody>
			</Card>
		)
	}

	renderTeamBySubmissionByStates = () => {
		const { classes } = this.props
		let teamsBySubmissionCountByState = this.state.teamSubmittedCountByState
		let teamsBySubmissionCountByStateTableData = teamsBySubmissionCountByState.map(
			(tCount, index) => {
				return [
					<Typography variant="body2" color="textPrimary" align="center">
						{tCount.state}
					</Typography>,
					<Typography variant="body2" color="textPrimary" align="center">
						{tCount.count}
					</Typography>
				]
			}
		)
		return (
			<Card raised>
				<CardBody profile>
					<h4 className={`${classes.cardCategory}`}>List of teams who have submitted solutions</h4>
					{!teamsBySubmissionCountByState.length ? (
						<div style={{ height: "250px", paddingTop: "100px" }}>
							<Typography variant="subtitle2" color="textPrimary" align="center">
								No Team data found
							</Typography>
						</div>
					) : (
						<div className={classes.reportPageTableFixedHeightStyle}>
							<Table
								tableHeaderColor="rose"
								hover
								tableHead={[
									<Typography variant="body2" color="textPrimary" align="center">
										State
									</Typography>,
									<Typography variant="body2" color="textPrimary" align="center">
										No. of Teams
									</Typography>
								]}
								tableData={teamsBySubmissionCountByStateTableData}
							/>
						</div>
					)}
				</CardBody>
			</Card>
		)
	}

	renderWinnerCountByAward = () => {
		const { classes } = this.props
		let winnerCountByAwardType = this.state.winnerCountByAwardType
		let winnerCountByAwardTypeTableData = winnerCountByAwardType.map((winnerCount, index) => {
			return [
				<Typography variant="body2" color="textPrimary" align="center">
					{Constants.Awards[index - 1]}
				</Typography>,
				<Typography variant="body2" color="textPrimary" align="center">
					{winnerCount}
				</Typography>
			]
		})
		return (
			<Card raised>
				<CardBody profile>
					<h4 className={`${classes.cardCategory}`}>List of winners by award type</h4>
					{!winnerCountByAwardTypeTableData.length ? (
						<div style={{ height: "250px", paddingTop: "100px" }}>
							<Typography variant="subtitle2" color="textPrimary" align="center">
								No winner data found
							</Typography>
						</div>
					) : (
						<div className={classes.reportPageTableFixedHeightStyle}>
							<Table
								tableHeaderColor="rose"
								hover
								tableHead={[
									<Typography variant="body2" color="textPrimary" align="center">
										Award type
									</Typography>,
									<Typography variant="body2" color="textPrimary" align="center">
										No. of winners
									</Typography>
								]}
								tableData={winnerCountByAwardTypeTableData}
							/>
						</div>
					)}
				</CardBody>
			</Card>
		)
	}

	renderParticipantsPaginated = () => {
		let limit = this.state.participantsPagination.itemsPerPage
		let offset = Math.max(this.state.participantsPagination.pageNo - 1, 0) * limit
		let total = this.state.participantsPagination.totalCount
		const { classes } = this.props

		const paginationClasses = {
			colorInheritCurrent: classes.colorInheritCurrent,
			colorInheritOther: classes.colorInheritOther
		}
		return (
			<Card raised>
				<CardBody profile>
					<h4 className={`${classes.cardCategory}`}> Participants </h4>
					{!this.state.participants.length ? (
						<div style={{ height: "250px", paddingTop: "100px" }}>
							<Typography variant="subtitle2" color="textPrimary" align="center">
								No participants found
							</Typography>
						</div>
					) : (
						<div>
							<Table
								tableHeaderColor="rose"
								hover
								tableHead={["Name", "Email", "Join Date", "Team Name"]}
								tableData={this.state.participants.map((u) => {
									return [
										<Typography variant="body2" color="textPrimary">
											{u.name}
										</Typography>,
										<Typography variant="body2" color="textPrimary">
											{u.email}
										</Typography>,

										<Typography variant="body2" color="textPrimary">
											{u.joinedAt ? new Date(u.joinedAt).toLocaleDateString() : "-"}
										</Typography>,
										<Typography variant="body2" color="textPrimary">
											{u.teamName ? u.teamName : "-"}
										</Typography>
									]
								})}
							/>
							<div style={{ height: "20px" }}> </div>
							<GridContainer direction="row" alignItems="center" justify="center">
								<GridItem sm={12} md={8} lg={6}>
									<div className={classes.paginationDiv}>
										<Pagination
											classes={paginationClasses}
											currentPageColor="inherit"
											otherPageColor="inherit"
											size={"large"}
											limit={limit}
											offset={offset}
											total={total}
											onClick={(e, offset) => this.onClickParticipantsPaginationButton(offset)}
										/>
									</div>
								</GridItem>
							</GridContainer>
						</div>
					)}
				</CardBody>
			</Card>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				{this.state.showErrorComponent ? (
					<div>
						<SimpleBreadcrumbs
							breadLinkData={[
								{ name: "Dashboard", link: "/dashboard/index" },
								{ name: "Error", link: "/dashboard/challenge-details" }
							]}
						/>
						<GridContainer direction="row" justify="space-evenly" alignItems="center">
							<GridItem>
								<ErrorPage
									errorCode={403}
									message={"Access Denied! :("}
									description={"You are not logged in, please login to see this page"}
								/>
							</GridItem>
						</GridContainer>
					</div>
				) : this.state.dataFetchDone ? (
					<div>
						<GridContainer direction="row" justify="space-between">
							<GridItem xs={9} sm={10} md={10} lg={11}>
								<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
							</GridItem>
							<GridItem className={classes.right} xs={3} sm={2} md={2} lg={1}>
								<ManageChallengeMenu
									challengeId={this.state.challengeId}
									urlSlug={this.state.urlSlug}
									challenge={this.state.challenge}
									history={this.props.history}
								/>
							</GridItem>
						</GridContainer>
						<br />
						<SnackbarContent
							message={this.state.challenge.challengeDetails.phaseTitle}
							color="success"
						/>
						<GridContainer direction="row" justify="center" alignItems="center">
							<GridItem xs={12} sm={6} md={4} lg={4}>
								{this.renderSolverCount()}
							</GridItem>
							<GridItem xs={12} sm={6} md={4} lg={4}>
								{this.renderTeamCount()}
							</GridItem>
							<GridItem xs={12} sm={6} md={4} lg={4}>
								{this.renderSubmissionCount()}
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12} sm={6} md={6} lg={6}>
								{this.renderSolverByStates()}
							</GridItem>
							<GridItem xs={12} sm={6} md={6} lg={6}>
								{this.renderSolverBySubmissionByStates()}
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12} sm={6} md={6} lg={6}>
								{this.renderTeamByStates()}
							</GridItem>
							<GridItem xs={12} sm={6} md={6} lg={6}>
								{this.renderTeamBySubmissionByStates()}
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12} sm={6} md={6} lg={6}>
								{this.renderWinnerCountByAward()}
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12} lg={12}>
								{this.renderParticipantsPaginated()}
							</GridItem>
						</GridContainer>
					</div>
				) : null}
			</div>
		)
	}
}

ReportsPage.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReportsPage))
