import apolloFetcher from "apolloFetcher"

let aboutPageServices = {}

aboutPageServices.getAboutPageByCustomerId = () => {
	let query = `
        {
            getAboutPageByCustomerId{
                status
                message
                totalCount
                data{
                    aboutPageId
                    aboutPageDetails{
                        aboutCCC
                        privacy
                        termsAndConditions
                        cookiePolicy
                        contactUs
                        pricing
                        news
                        resources
                    }
                }
            }
        }
    `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({
				query
			})
			.then(res => {
				if (!res || !res.data || !res.data.getAboutPageByCustomerId)
					throw new Error("Error occured while fetching about pages details")
				if (!res.data.getAboutPageByCustomerId.status)
					throw new Error(res.data.getAboutPageByCustomerId.message)
				resolve(res.data.getAboutPageByCustomerId.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}

aboutPageServices.createOrUpdateAboutPage = variables => {
	let query = `
	mutation createOrUpdateAboutPage(
		$aboutCCC: String
		$privacy: String
		$termsAndConditions: String
		$cookiePolicy: String
		$contactUs: String
		$pricing: String
		$news: String
		$resources: String
		$newsletterSubscribers: [newsletterSubscriberInput]
	) {
		createOrUpdateAboutPage(
			input: {
				aboutPageDetails: {
					aboutCCC: $aboutCCC
					privacy: $privacy
					termsAndConditions: $termsAndConditions
					cookiePolicy: $cookiePolicy
					contactUs: $contactUs
					pricing: $pricing
					news: $news
					resources: $resources
					newsletterSubscribers: $newsletterSubscribers
				}
			}
		) {
			status
			message
			totalCount
			data {
				aboutPageId
				aboutPageDetails {
					aboutCCC
					privacy
					termsAndConditions
					cookiePolicy
					contactUs
					pricing
					news
					resources
					newsletterSubscribers {
						name
						email
					}
				}
			}
		}
	}	
	`

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then(res => {
				if (!res || !res.data || !res.data.createOrUpdateAboutPage)
					throw new Error("Error occured while creating or updating aboutPage")
				if (!res.data.createOrUpdateAboutPage.status)
					throw new Error(res.data.createOrUpdateAboutPage.message)
				resolve(res.data.createOrUpdateAboutPage.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}

aboutPageServices.subscribeToNewsletter = variables => {
	let query = `
	mutation subscribeToNewsletter($email: String! $name: String) {
		subscribeToNewsletter(email: $email name: $name) {
			status
			message
			data {
				aboutPageId
				aboutPageDetails {
					newsletterSubscribers{
						name
						email
					}
				}
			}
			totalCount
		}
	}	
  `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({
				query,
				variables
			})
			.then(res => {
				if (!res || !res.data || !res.data.subscribeToNewsletter)
					throw new Error("Error occured while creating or updating aboutPage")
				if (!res.data.subscribeToNewsletter.status)
					throw new Error(res.data.subscribeToNewsletter.message)
				resolve(res.data.subscribeToNewsletter.status)
			})
			.catch(err => {
				reject(err)
			})
	})
}

aboutPageServices.unsubscribeToNewsletter = variables => {
	let query = `
	mutation unsubscribeToNewsletter($email: String!) {
		unsubscribeToNewsletter(email: $email) {
			status
			message
			data {
				aboutPageId
				aboutPageDetails {
					newsletterSubscribers {
						name
						email
					}
				}
			}
			totalCount
		}
	}
  `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then(res => {
				if (!res || !res.data || !res.data.unsubscribeToNewsletter)
					throw new Error("Error occured while creating or updating aboutPage")
				if (!res.data.unsubscribeToNewsletter.status)
					throw new Error(res.data.unsubscribeToNewsletter.message)
				resolve(res.data.unsubscribeToNewsletter.status)
			})
			.catch(err => {
				reject(err)
			})
	})
}

export default aboutPageServices
