const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	container: {
		margin: "0px 0px",
		padding: "40px 20px",
		backgroundColor: theme.palette.background.page
	},
	addDiscussionButtonStyle: {
		display: "grid",
		justifyContent: "flex-end",
		alignContent: "center"
	},
	textOverflowEllipsisStyle: {
		height: "40px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap"
	},
	deleteButtonStyle: {
		backgroundColor: "#e91e63",
		"&:hover,&:focus": {
			backgroundColor: "#e91e63",
			boxShadow: "0px 0px 10px #e91e63"
		}
	},
	editButtonStyle: {
		backgroundColor: "#3DAEFF",
		"&:hover,&:focus": {
			backgroundColor: "#3DAEFF",
			boxShadow: "0px 0px 10px #3DAEFF"
		}
	},
	buttonSuccessColor: {
		backgroundColor: "#1eb980",
		"&:hover,&:focus": {
			backgroundColor: "#1eb980",
			boxShadow: "0px 6px 14px rgba(30, 185, 128, .3)"
		}
	},
	whiteTextColorStyle: {
		color: theme.palette.text.page
	},
	inputRoot: {
		backgroundColor: "transparent"
	},
	inputLabel: {
		color: theme.palette.text.page
	},
	textField: {
		color: theme.palette.text.page
	},
	toolbarClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	wrapperClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	editorClassName: {
		backgroundColor: "#FFF",
		color: "#000"
	}
})

export default styles
