import React from 'react';
import PropTypes from "prop-types";

import { Menu, MenuItem } from '@material-ui/core';

const DropDownMenu = (props) => {
  const {
    open,
    onClose,
    anchorEl,
    menuItems,
  } = props;
  return (
    <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
      >
        {(menuItems || []).map(item => {
          const {
            title,
            onClick,
          } = item;

          return <MenuItem onClick={onClick}>{title}</MenuItem>;
        })}
      </Menu>
  )
}

DropDownMenu.propTypes = {
  anchorEl: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  menuItems: PropTypes.array.isRequired
};

DropDownMenu.defaultProps = {
  open: false,
}

export default DropDownMenu;
