import React from "react"
import PropTypes from "prop-types"
import deburr from "lodash/deburr"
import Downshift from "downshift"
import { withStyles } from "@material-ui/core/styles"
import TextField from "@material-ui/core/TextField"
import Popper from "@material-ui/core/Popper"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"
import Chip from "@material-ui/core/Chip"
import validator from "validator"

function renderInput(inputProps) {
	const { InputProps, classes, ref, ...other } = inputProps

	return (
		<TextField
			InputProps={{
				inputRef: ref,
				classes: {
					root: classes.inputRoot,
					input: classes.inputInput
				},
				...InputProps
			}}
			{...other}
		/>
	)
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
	const isHighlighted = highlightedIndex === index
	const isSelected = (selectedItem || []).map((item) => item.id).indexOf(suggestion.id) > -1
	let menuLabel = suggestion.label
	return (
		<MenuItem
			{...itemProps}
			key={suggestion.label}
			selected={isHighlighted}
			component="div"
			style={{
				display: "block",
				fontWeight: isSelected ? 500 : 400
			}}
		>
			<p> {menuLabel} </p>
		</MenuItem>
	)
}

renderSuggestion.propTypes = {
	highlightedIndex: PropTypes.number,
	index: PropTypes.number,
	itemProps: PropTypes.object,
	selectedItem: PropTypes.string,
	suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
}

class DownshiftMultiple extends React.Component {
	state = {
		inputValue: "",
		suggestions: [],
		selectedItem: this.props.selectedItems
			? this.props.selectedItems.map((d) => ({ ...d, label: d.label, id: d.label }))
			: []
	}

	handleKeyDown = (event) => {
		const { inputValue, selectedItem } = this.state
		if (selectedItem.length && !inputValue.length && event.key === "Backspace") {
			let selectedItemTemp = selectedItem.slice(0, selectedItem.length - 1)
			this.setState({
				selectedItem: selectedItemTemp
			})
			this.props.setSelectedItems(selectedItemTemp)
		}
	}

	getSuggestions = (value) => {
		const inputValue = deburr(value.trim()).toLowerCase()
		const inputLength = inputValue.length
		return inputLength === 0 ? [] : this.state.suggestions.slice(0, 5)
	}

	searchItemsAndProcessData = (searchText) => {
		if (!searchText) return
		this.props
			.searchItemsPromise(searchText)
			.then((data) => {
				if (!data || data.length == 0) throw new Error("Tags not found")
				let suggestions = data.map((d) => {
					return {
						...d,
						label: d.label,
						id: d.label
					}
				})
				this.setState({ suggestions: suggestions })
			})
			.catch((err) => {
				if (searchText && searchText != "" && searchText.length > 2 && this.props.allowCreateNew) {
					this.setState({
						suggestions: [
							{
								label: `Create '${searchText}' as new`,
								id: searchText,
								value: searchText,
								newItem: true
							}
						]
					})
				} else this.setState({ suggestions: [] })
				console.log("Search items error: ", err.message)
			})
	}

	handleInputChange = (event) => {
		let value = event.target.value
		if (this.props.textTransformToLower) {
			value = value.toLowerCase()
		}
		if (!this.state.inputValue) this.setState({ suggestions: [], inputValue: value })
		else this.setState({ inputValue: value })
		this.searchItemsAndProcessData(value)
	}

	handleChange = (item) => {
		let { selectedItem } = this.state
		if (selectedItem.map((tmp) => tmp.id).indexOf(item.id) === -1) {
			selectedItem = [...selectedItem, item]
		}
		this.setState({
			inputValue: "",
			selectedItem
		})
		this.props.setSelectedItems(selectedItem)
	}

	handleDelete = (item) => () => {
		const selectedItem = [...this.state.selectedItem]
		selectedItem.splice(selectedItem.map((tmp) => tmp.id).indexOf(item.id), 1)
		this.setState({
			selectedItem
		})
		this.props.setSelectedItems(selectedItem)
	}

	render() {
		const { classes } = this.props
		const { inputValue, selectedItem } = this.state

		return (
			<Downshift
				id="downshift-multiple"
				inputValue={inputValue}
				itemToString={(item) => (item ? item.label : "")}
				onChange={this.handleChange}
				selectedItem={selectedItem}
			>
				{({
					getInputProps,
					getItemProps,
					isOpen,
					inputValue: inputValue2,
					selectedItem: selectedItem2,
					highlightedIndex
				}) => (
					<div className={classes.container}>
						{renderInput({
							fullWidth: true,
							classes,
							InputProps: getInputProps({
								startAdornment: selectedItem.map((item) => (
									<Chip
										key={item.id}
										tabIndex={-1}
										label={item.label}
										className={classes.chip}
										onDelete={this.handleDelete(item)}
									/>
								)),
								onChange: this.handleInputChange,
								onKeyDown: this.handleKeyDown,
								placeholder: this.props.placeholder
							}),
							label: this.props.inputLabel
						})}
						{isOpen ? (
							<Paper className={classes.paper} square>
								{this.getSuggestions(inputValue2).map((suggestion, index) =>
									renderSuggestion({
										suggestion,
										index,
										itemProps: getItemProps({ item: suggestion }),
										highlightedIndex,
										selectedItem: selectedItem2
									})
								)}
							</Paper>
						) : null}
					</div>
				)}
			</Downshift>
		)
	}
}

DownshiftMultiple.propTypes = {
	classes: PropTypes.object.isRequired,
	setSelectedItems: PropTypes.func.isRequired
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		height: "100%",
		color: "#FFF",
		marginBottom: "30px"
	},
	container: {
		flexGrow: 1,
		height: "100%",
		position: "relative",
		color: "#FFF"
	},
	paper: {
		position: "absolute",
		zIndex: 1000,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	chip: {
		margin: theme.spacing(0.5, 0.25)
	},
	inputRoot: {
		flexWrap: "wrap",
		color: "#FFF"
	},
	inputInput: {
		width: "auto",
		flexGrow: 1,
		color: theme.palette.text.page
	},
	divider: {
		height: theme.spacing(2)
	}
})

let popperNode

function IntegrationDownshift(props) {
	const { classes } = props

	return (
		<div className={classes.root}>
			<DownshiftMultiple {...props} />
		</div>
	)
}

IntegrationDownshift.propTypes = {
	classes: PropTypes.object.isRequired,
	inputLabel: PropTypes.string,
	placeholder: PropTypes.string,
	allowCreateNew: PropTypes.bool,
	textTransformToLower: PropTypes.bool,
	selectedItems: PropTypes.array,
	setSelectedItems: PropTypes.func.isRequired,
	searchItemsPromise: PropTypes.func.isRequired
}

export default withStyles(styles)(IntegrationDownshift)
