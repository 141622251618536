import apolloFetcher from "apolloFetcher"

let services = {}

services.createOrUpdate = (
	id,
	challengeId,
	solutionId,
	evaluatorUserId,
	evaluatorTeamId,
	solverUserId,
	solverTeamId,
	criteriaBasedScore
) => {
	let query = `
    mutation evaluationScore(
        $id: String
        $solutionId: String!
        $challengeId: String!
        $evaluatorUserId: String!
        $evaluatorTeamId:String
        $solverUserId: String!
        $solverTeamId: String
        $criteriaBasedScore: [CriteriaBasedScoreInput!]!
      ) {
        evaluationScore(evaluationScoreInput:{
            id: $id
            evaluationScoreDetails: {
            solutionId: $solutionId
            challengeId: $challengeId
            evaluatorUserId: $evaluatorUserId
            evaluatorTeamId: $evaluatorTeamId
            solverUserId: $solverUserId
            solverTeamId: $solverTeamId
            criteriaBasedScore: $criteriaBasedScore
          }
        }){
          status
          message
          data{
            id
            evaluationScoreDetails{
              solutionId
              challengeId
              evaluatorUserId
              evaluatorUser{
                userId
                name
                email
                username
              }
              evaluatorTeamId
              solverUserId
              solverTeamId
              criteriaBasedScore{
                criteriaName
                scoreValue
                commentText
              }
            }
          }
          totalCount
        }
      }
    `
	let variables = {
		id: id ? id : null,
		challengeId: challengeId,
		solutionId: solutionId,
		evaluatorUserId: evaluatorUserId,
		evaluatorTeamId: evaluatorTeamId,
		solverUserId: solverUserId,
		solverTeamId: solverTeamId,
		criteriaBasedScore: criteriaBasedScore
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.evaluationScore)
					throw new Error("Error submitting score")
				if (!res.data.evaluationScore.status) throw new Error(res.data.evaluationScore.message)
				resolve(res.data.evaluationScore.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.getEvaluationScoresForSolutions = (solutionId) => {
	let query = `
  query evaluationScores($solutionId: String!) {
    evaluationScores(solutionId: $solutionId) {
      status
      message
      totalCount
      data {
        id
        evaluationScoreDetails {
          solutionId
          challengeId
          evaluatorUserId
          evaluatorUser{
            userId
            name
            email
            username
          }
          solverUserId
          solverTeamId
          evaluatorTeamId
          criteriaBasedScore {
            criteriaName
            scoreValue
            commentText
          }
        }
      }
    }
  }  
  `
	let variables = {
		solutionId: solutionId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.evaluationScores)
					throw new Error("Error submitting score")
				if (!res.data.evaluationScores.status) throw new Error(res.data.evaluationScores.message)
				resolve(res.data.evaluationScores.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.getEvaluationScoreBySolutionByEvaluator = (id, solutionId) => {
	let query = `
    query getEvaluationScoreBySolutionByEvaluator( $solutionId:String! ) {
        getEvaluationScoreBySolutionByEvaluator( solutionId: $solutionId ) {
          status
          message
          data {
            id
            evaluationScoreDetails {
              solutionId
              challengeId
              evaluatorUserId
              evaluatorTeamId
              solverUserId
              solverTeamId
              criteriaBasedScore {
                criteriaName
                scoreValue
                commentText
              }
            }
          }
          totalCount
        }
      }
    `
	let variables = {
		id: id ? id : null,
		solutionId: solutionId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getEvaluationScoreBySolutionByEvaluator)
					throw new Error("Error submitting score")
				if (!res.data.getEvaluationScoreBySolutionByEvaluator.status)
					throw new Error(res.data.getEvaluationScoreBySolutionByEvaluator.message)
				resolve(res.data.getEvaluationScoreBySolutionByEvaluator.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.getInternalLeaderboard = (variables) => {
	let query = `
    query internalLeaderboard($challengeId: String!){
      internalLeaderboard(
        challengeId: $challengeId
      ){
        status
        message
        totalCount
        data{
          solutionId
          challengeId
          solverUserId
          solverTeamId
          averageScore
          variance
        }
      }
    }
  `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.internalLeaderboard)
					throw new Error("Error getting internal leaderboard")
				if (!res.data.internalLeaderboard.status)
					throw new Error(res.data.internalLeaderboard.message)
				resolve(res.data.internalLeaderboard.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.getLeaderboardFinal = (variables) => {
	let query = `
  query leaderboardFinal( $challengeId: String! ){
    leaderboardFinal( challengeId: $challengeId ){
      status
      message
      data{
        solutionId
        challengeId
        solverUserId
        solverTeamId
        averageScore
        variance
        standing
        remarks
        userName
        teamName
        username
      }
      totalCount
    }
  }
  `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.leaderboardFinal)
					throw new Error("Error getting internal leaderboard")
				if (!res.data.leaderboardFinal.status) throw new Error(res.data.leaderboardFinal.message)
				resolve(res.data.leaderboardFinal.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.sendEmailToAdminOnScoringComplete = (challengeId) => {
	let query = `
  mutation onScoringCompleteSendMailToAdmin($challengeId:String!){
    onScoringCompleteSendMailToAdmin(challengeId: $challengeId){
      status
      message
      totalCount
      data
    }
  }
  `
	let variables = {
		challengeId: challengeId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.onScoringCompleteSendMailToAdmin)
					throw new Error("Error submitting score")
				if (!res.data.onScoringCompleteSendMailToAdmin.status)
					throw new Error(res.data.onScoringCompleteSendMailToAdmin.message)
				resolve(res.data.onScoringCompleteSendMailToAdmin.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export default services
