import React from 'react';
// react components used to create a google map
import { LoadScript, GoogleMap, Marker } from '@react-google-maps/api';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import Map from '@material-ui/icons/Map';
import AddLocation from '@material-ui/icons/AddLocation';
import Place from '@material-ui/icons/Place';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  }
};

class GoogleMaps extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Map />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Satellite Map</h4>
            </CardHeader>
            <CardBody>
              <LoadScript googleMapsApiKey="YOUR_KEY_HERE" loadingElement={<div style={{ height: `100%` }} />}>
                <GoogleMap
                  mapContainerStyle={{
                    height: `280px`,
                    borderRadius: '6px',
                    overflow: 'hidden'
                  }}
                  zoom={3}
                  mapTypeId={'satellite'}
                  center={{ lat: 40.748817, lng: -73.985428 }}
                  options={{
                    scrollwheel: false
                  }}>
                  <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
                </GoogleMap>
              </LoadScript>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <AddLocation />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Regular Map</h4>
            </CardHeader>
            <CardBody>
              <LoadScript googleMapsApiKey="YOUR_KEY_HERE" loadingElement={<div style={{ height: `100%` }} />}>
                <GoogleMap
                  mapContainerStyle={{
                    height: `280px`,
                    borderRadius: '6px',
                    overflow: 'hidden'
                  }}
                  zoom={8}
                  center={{ lat: 40.748817, lng: -73.985428 }}
                  options={{
                    scrollwheel: false
                  }}>
                  <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
                </GoogleMap>
              </LoadScript>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Place />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Custom Skin & Settings Map</h4>
            </CardHeader>
            <CardBody>
              <LoadScript googleMapsApiKey="YOUR_KEY_HERE" loadingElement={<div style={{ height: `100%` }} />}>
                <GoogleMap
                  mapContainerStyle={{
                    height: `280px`,
                      borderRadius: '6px',
                      overflow: 'hidden'
                  }}
                  zoom={13}
                  center={{ lat: 40.748817, lng: -73.985428 }}
                  options={{
                    scrollwheel: false,
                    disableDefaultUI: true,
                    zoomControl: true,
                    styles: [
                      {
                        featureType: 'water',
                        stylers: [{ saturation: 43 }, { lightness: -11 }, { hue: '#0088ff' }]
                      },
                      {
                        featureType: 'road',
                        elementType: 'geometry.fill',
                        stylers: [{ hue: '#ff0000' }, { saturation: -100 }, { lightness: 99 }]
                      },
                      {
                        featureType: 'road',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#808080' }, { lightness: 54 }]
                      },
                      {
                        featureType: 'landscape.man_made',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#ece2d9' }]
                      },
                      {
                        featureType: 'poi.park',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#ccdca1' }]
                      },
                      {
                        featureType: 'road',
                        elementType: 'labels.text.fill',
                        stylers: [{ color: '#767676' }]
                      },
                      {
                        featureType: 'road',
                        elementType: 'labels.text.stroke',
                        stylers: [{ color: '#ffffff' }]
                      },
                      { featureType: 'poi', stylers: [{ visibility: 'off' }] },
                      {
                        featureType: 'landscape.natural',
                        elementType: 'geometry.fill',
                        stylers: [{ visibility: 'on' }, { color: '#b8cb93' }]
                      },
                      { featureType: 'poi.park', stylers: [{ visibility: 'on' }] },
                      {
                        featureType: 'poi.sports_complex',
                        stylers: [{ visibility: 'on' }]
                      },
                      { featureType: 'poi.medical', stylers: [{ visibility: 'on' }] },
                      {
                        featureType: 'poi.business',
                        stylers: [{ visibility: 'simplified' }]
                      }
                    ]
                  }}>
                  <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
                </GoogleMap>
              </LoadScript>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(GoogleMaps);
