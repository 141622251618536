import React from "react"
import { createBrowserHistory } from "history"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import { GoogleOAuthProvider } from "@react-oauth/google";

import AuthLayout from "layouts/Auth.jsx"
import RtlLayout from "layouts/RTL.jsx"
import AdminLayout from "layouts/Admin.jsx"
import HomeLayout from "layouts/Home.jsx"
import DashboardLayout from "layouts/Dashboard.jsx"
import LoginSignupModal from "views/Pages/LoginSignupModal"
import LoadingSpinner from "views/Components/LoadingSpinner"
import ShowAlertGlobal from "views/Components/ShowAlertGlobal"
import GoogleAnalytics from "views/Components/GoogleAnalytics"
import GlobalActionHandler from "views/Components/GlobalActionHandler"
import "assets/scss/material-dashboard-pro-react.scss?v=1.5.0"
import "./formio_styles.scss"

import { Provider, connect } from "react-redux"
import actionTypes from "./reduxjs/actionTypes"
import store from "./reduxjs/store"
import constant from "./constants"

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { createUploadLink } from "apollo-upload-client"

import { MuiThemeProvider } from "@material-ui/core/styles"

import getTheme from "./themes"
import ThemeCheck from "views/ThemeTest/ThemeCheck"

const apolloClient = new ApolloClient({
	cache: new InMemoryCache(),
	link: createUploadLink({ uri: `${constant.Settings.APIURL}/graphql` })
})

const hist = createBrowserHistory()

function ThemedApp(props) {
	console.log("theme name on App.js = ", props.themeMode)
	let theme = getTheme(props.themeMode)
	return (
		<MuiThemeProvider theme={theme}>
			<Router history={hist}>
				<Route
					path="*"
					render={() => (
						<div>
							<GoogleAnalytics history={hist} />
							<LoginSignupModal />
							<LoadingSpinner />
							<ShowAlertGlobal />
							<GlobalActionHandler history={hist} />
						</div>
					)}
				/>
			</Router>
			<Router history={hist}>
				<Switch>
					<Route path="/theme" component={ThemeCheck} />
					<Route path="/rtl" component={RtlLayout} />
					<Route path="/auth" component={AuthLayout} />
					<Route path="/admin" component={AdminLayout} />
					<Route path="/home" component={HomeLayout} />
					<Route path="/dashboard" component={DashboardLayout} />
					<Redirect from="/" to="/home/index" />
				</Switch>
			</Router>
		</MuiThemeProvider>
	)
}

const mapStateToProps = (state) => {
	return {
		themeMode: state.theme
	}
}

const mapDispatchToStore = (dispatch) => {
	return {
		setTheme: (themeName) => dispatch({ type: actionTypes.SWITCH_THEME, payload: themeName })
	}
}

let ConnectedThemedApp = connect(mapStateToProps, mapDispatchToStore)(ThemedApp)

function App() {
	return (
		<GoogleOAuthProvider clientId={constant.GAPI_CLIENT_ID}>
			<Provider store={store}>
				<ApolloProvider client={apolloClient}>
					<ConnectedThemedApp />
				</ApolloProvider>
			</Provider>
		</GoogleOAuthProvider>
	)
}

export default App
