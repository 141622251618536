import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';

import { ButtonGroup, Button } from '@material-ui/core';

import BreadcrumbAndMenuSection from 'components/Challenges/ChallengeDetails/BreadcrumbAndMenuSection';
import SubmissionLeaderboard from 'components/Challenges/ChallengeDetails/SubmissionLeaderboard';

import { adminUpdateSolverAccessForSubmission } from 'dataServices/AdminPageServices/challenges';
import challengeServices from 'dataServices/challengeServices';
import userServices from 'dataServices/userServices';

import actionTypes from 'reduxjs/actionTypes';
import { getIsUserAdmin, getIsUserChallengeAdmin } from 'utils/AuthUtils/role';

import { getBreadcrumArray } from './utilities';
import { SOLVER_ACCESS_BUTTON_TITLE } from './constants';
import styles from './styles.module.scss';

const SubmissionLeaderboardPage = props => {
  const {
    setLoadingSpinner,
    resetLoadingSpinner,
    showAlert,
    setRedirectOnError
  } = props;
  const { challengeId } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);
  const [submissionAccessStatus, setSubmissionAccessStatus] = useState(false);
  const [challenge, setChallenge] = useState();

  const fetchCurrentUser = async () => {
    setIsLoading(true);
    setLoadingSpinner();
    
    try {
      const [userData, challengeData] = await Promise.all([
        userServices.getCurrentUser(),
        challengeServices.getChallengeById(challengeId),
      ]);

      setChallenge(challengeData);
      setSubmissionAccessStatus(
        get(
          challengeData,
          "challengeDetails.submissionAccessStatus",
          false,
        )
      );

      setIsLoading(false);
      resetLoadingSpinner();

      if (
        getIsUserAdmin(userData.roles || []) ||
        getIsUserChallengeAdmin(userData.roles || [], challengeData.challengeId)
      ) {
      } else{
        setRedirectOnError(`/dashboard/challenge-details/${challengeId}/overview`);
      }
    } catch(err) {
      setIsLoading(false);
      resetLoadingSpinner();

      setRedirectOnError(`/dashboard/challenge-details/${challengeId}/overview`);
    }
  };

  const onButtonGroupClick = async (value) => {
    if (submissionAccessStatus == value) {
      return;
    }

    setLoadingSpinner();

    try {
      await adminUpdateSolverAccessForSubmission(value, get(challenge, 'challengeId', ''));
      setSubmissionAccessStatus(value);
      resetLoadingSpinner();

      showAlert(
        'success',
        'Access updated',
        `Access of submission for solvers has been updated.`,
      );
    } catch(err) {
      resetLoadingSpinner();
      showAlert('error', 'Opps!', err.message);
    }
  };

  const renderSolverAccessButtonGroup = () => {
    const { ON, OFF } = SOLVER_ACCESS_BUTTON_TITLE;
    const title = 'Grant Solvers\' Access';

    return (
      <div className={styles.buttonGroupContainer}>
        <ButtonGroup className={styles.buttonGroup} size='large'>
          <Button
            className={submissionAccessStatus ? styles.buttonSelected : styles.button}
            onClick={() => onButtonGroupClick(true)}
          >
            {ON.label}
          </Button>
          <Button
            className={submissionAccessStatus ? styles.button : styles.buttonSelected}
            onClick={() => onButtonGroupClick(false)}
          >
            {OFF.label}
          </Button>
        </ButtonGroup>
        <div className={styles.buttonTitle}>{title}</div>
      </div>
    );
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <BreadcrumbAndMenuSection
        challenge={challenge}
        urlSlug={challengeId}
        breadCrumb={challenge ? getBreadcrumArray(challenge) : []}
        history={props.history}
      />
      {renderSolverAccessButtonGroup()}
      <SubmissionLeaderboard challenge={challenge} urlSlug={challengeId} />
    </div>
  );
}

const reduxProps = {
  resetBreadCrumbStack: PropTypes.func.isRequired,
  setLoadingSpinner: PropTypes.func.isRequired,
  resetLoadingSpinner: PropTypes.func.isRequired,
  setRedirectOnError: PropTypes.func.isRequired,
};

SubmissionLeaderboardPage.propTypes = {
  ...reduxProps,
};

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack,
		themeMode: state.theme
	};
};

const mapDispatchToProps = (dispatch) => {
  const {
    RESET_BREAD_CRUMB_STACK,
    LOADING_SPINNER_SET,
    LOADING_SPINNER_RESET,
    SHOW_ALERT,
    REDIRECT_ON_ERROR,
  } = actionTypes;

	return {
    resetBreadCrumbStack: () => dispatch({ type: RESET_BREAD_CRUMB_STACK }),
    setLoadingSpinner: () => dispatch({ type: LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: LOADING_SPINNER_RESET }),
    showAlert: (alertType, title, description) => {
      return dispatch({
        type: SHOW_ALERT,
        payload: {
          alertType,
          title,
          description
        },
      })
    },
    setRedirectOnError: (redirectTo = "") => dispatch({ type: REDIRECT_ON_ERROR, payload: redirectTo }),
  };
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SubmissionLeaderboardPage);