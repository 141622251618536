import React from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Pagination from "material-ui-flat-pagination"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Constants from "constants.js"
import authentication from "authentication.js"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import aboutPageServices from "dataServices/aboutPageServices.js"
import CardFooter from "components/Card/CardFooter"
import { Typography, ListItemIcon } from "@material-ui/core"
import userServices from "dataServices/userServices.js"
import { Redirect, NavLink } from "react-router-dom"
import CardAvatar from "components/Card/CardAvatar"
import emptyAvatar from "assets/img/faces/emptyProfilePicture.webp"
import emptyTeamAvatar from "assets/img/noImageAvailable.webp"

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx"
import LaunchIcon from "@material-ui/icons/Launch"
import teamServices from "dataServices/teamServices"
import { fade } from "@material-ui/core/styles/colorManipulator"

import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Tooltip from "@material-ui/core/Tooltip"
import Close from "@material-ui/icons/Close"

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle"
import combineStyles from "utils/combineStyle"
import SearchBox from "views/Components/SearchBox"

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	textOverflowEllipsisStyle: {
		overflowWrap: "break-word",
		height: "auto",
		overflow: "hidden",
		textOverflow: "ellipsis",
		textAlign: "center",
		contentAlign: "center",
		color: theme.palette.text.page
	},
	textOverflowEllipsisStyleForUserNameOnly: {
		overflowWrap: "break-word",
		height: "45px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		color: theme.palette.text.page
	},
	textOverflowEllipsisStyleForOrganizationNameOnly: {
		overflowWrap: "break-word",
		height: "45px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		textAlign: "center",
		contentAlign: "center",
		color: theme.palette.text.page
	},
	textOverflowEllipsisStyleForTeamDescription: {
		overflowWrap: "break-word",
		height: "100px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		textAlign: "center",
		contentAlign: "center",
		color: theme.palette.text.page
	},
	whiteTextColorStyle: {
		color: theme.palette.text.page
	},
	...userProfileStyles,
	paginationDiv: {
		width: "100%",
		//border: `1px solid ${theme.palette.background.cardBody}`,
		color: theme.palette.text.cardBody
	},
	colorInheritCurrent: {
		margin: theme.spacing(0.5),
		color: theme.palette.text.page,
		backgroundColor: fade("#00FF00", 0.5),
		"&:hover": {
			backgroundColor: fade("#00FF00", theme.palette.action.hoverOpacity)
		}
	},
	colorInheritOther: {
		margin: theme.spacing(0.5),
		color: theme.palette.text.page,
		//backgroundColor: "#FF0000",
		"&:hover": {
			backgroundColor: fade("#FF0000", theme.palette.action.hoverOpacity)
		}
	},
	modalFullWith: {
		[theme.breakpoints.up("sm")]: {
			maxWidth: "1500px",
			margin: "auto"
		},
		borderRadius: "6px",
		marginTop: "100px !important",
		overflow: "visible",
		maxHeight: "unset",
		position: "relative",
		height: "fit-content"
	}
})

class TeamsListUI extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedTeamMembers: null,
			selectedTeamName: ""
		}
	}

	renderMember = (solver, key) => {
		var xs = 12,
			sm = 6,
			md = 4,
			lg = 3
		const { classes } = this.props
		if (!solver) return null
		var avatarImagePath = null
		if (solver.avatarImagePath) {
			avatarImagePath = solver.avatarImagePath
			if (!avatarImagePath.includes("http"))
				avatarImagePath = Constants.Settings.APIURL + solver.avatarImagePath
		}

		return (
			<GridItem key={key} xs={xs} sm={sm} md={md} lg={lg}>
				<Card profile color="gmgTheme" raised={true}>
					<CardAvatar profile style={{ height: "130px", marginTop: "0px" }}>
						<img
							style={{ zoom: "500%", objectFit: "cover" }}
							src={avatarImagePath || emptyAvatar}
							alt="Team cover photo"
							onError={(event) => {
								event.target.src = emptyAvatar
							}}
						/>
					</CardAvatar>
					<CardBody profile>
						<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
							Username: {solver.username}
						</h6>
						<h3
							className={`${classes.cardTitle} ${classes.textOverflowEllipsisStyleForUserNameOnly}`}
						>
							{solver.name}
						</h3>
						<Divider variant="middle" />
						{solver.organizationName ? (
							<h6
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForOrganizationNameOnly}`}
							>
								Organization Name: {solver.organizationName}
							</h6>
						) : (
							<h6
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForOrganizationNameOnly}`}
							>
								Organization Name: Not Available
							</h6>
						)}
						<Divider variant="middle" />
						{solver.state ? (
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								State: {solver.state}
							</h6>
						) : (
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								State: Not Available
							</h6>
						)}
					</CardBody>
				</Card>
			</GridItem>
		)
	}

	renderMembersView = (members) => {
		const { classes } = this.props
		if (members.length == 0) {
			return (
				<div style={{ margin: "30px auto" }}>
					<GridContainer justify="center" alignItems="flex-start">
						<GridItem>
							<Typography className={classes.whiteTextColorStyle}>
								{" "}
								<div style={{ textTransform: "capitalize" }}>No Solvers Found </div>
							</Typography>
						</GridItem>
					</GridContainer>
				</div>
			)
		}

		let view = members.map((member, key) => {
			return this.renderMember(member, key)
		})
		return (
			<GridContainer
				container
				direction="row"
				justify="space-evenly"
				alignItems="flex-start"
				spacing={3}
			>
				{view}
			</GridContainer>
		)
	}

	renderTeam = (team, key) => {
		var xs = 12,
			sm = 8,
			md = 6,
			lg = 4
		const { classes } = this.props
		if (!team) return null
		var teamAvatar = null
		if (team.teamAvatar) {
			teamAvatar = team.teamAvatar
			if (!teamAvatar.includes("http")) teamAvatar = Constants.Settings.APIURL + team.teamAvatar
		}

		return (
			<GridItem key={key} xs={xs} sm={sm} md={md} lg={lg}>
				<Card color="gmgTheme" raised={true}>
					<CardHeader color="gmgTheme">
						<div style={{ height: "100px", width: "100%" }}>
							<img
								style={{ objectFit: "cover", width: "100%", height: "100px" }}
								src={teamAvatar || emptyTeamAvatar}
								alt="User Profile Avatar"
								onError={(event) => {
									event.target.src = emptyTeamAvatar
								}}
							/>
						</div>
					</CardHeader>
					<CardBody>
						<h4 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
							Team Name: {team.teamName}
						</h4>

						<Divider variant="middle" />
						<div
							className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForTeamDescription}`}
						>
							{team.teamDescription && <div> Description: {team.teamDescription} </div>}
						</div>
						<Divider variant="middle" />
						<GridContainer direction="row" alignItems="center" justify="center">
							<GridItem>
								{team.teamMembers && team.teamMembers.length ? (
									<Button
										color="success"
										onClick={() => {
											this.setState({
												selectedTeamMembers: team.teamMembers,
												selectedTeamName: team.teamName
											})
										}}
									>
										View Members
									</Button>
								) : null}
							</GridItem>
							<GridItem>
								<Button
									color="danger"
									onClick={(e) => {
										this.props.onClickRemoveTeam(team.id)
									}}
								>
									Remove Team
								</Button>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</GridItem>
		)
	}

	renderTeamsView = (teamsList) => {
		const { classes } = this.props
		if (teamsList.length == 0) {
			return (
				<div style={{ margin: "30px auto" }}>
					<GridContainer justify="center" alignItems="flex-start">
						<GridItem>
							<Typography className={classes.whiteTextColorStyle}>
								<div style={{ textTransform: "capitalize" }}>No Teams Found </div>
							</Typography>
						</GridItem>
					</GridContainer>
				</div>
			)
		}

		let view = teamsList.map((team, key) => {
			return this.renderTeam(team, key)
		})
		return (
			<GridContainer
				container
				direction="row"
				justify="space-evenly"
				alignItems="flex-start"
				spacing={3}
			>
				{view}
			</GridContainer>
		)
	}

	renderTeamMembersModal = () => {
		const { classes } = this.props
		if (!this.state.selectedTeamMembers) return null

		return (
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modalFullWith
				}}
				open={true}
				keepMounted
				fullWidth
				maxWidth={"lg"}
				onClose={() => {
					this.setState({ selectedTeamMembers: null })
				}}
			>
				<DialogTitle
					disableTypography
					className={classes.modalHeader}
					style={{
						margin: "0px 0px"
					}}
				>
					<GridContainer direction="column">
						<GridItem>
							<Button
								justIcon
								className={classes.modalCloseButton}
								color="transparent"
								onClick={() => {
									this.setState({ selectedTeamMembers: null })
								}}
							>
								<Tooltip title="Close" placement="top">
									<Close />
								</Tooltip>
							</Button>
						</GridItem>
						<GridItem>
							<h4 className={classes.modalTitle}>Team members of {this.state.selectedTeamName}</h4>
						</GridItem>
					</GridContainer>
				</DialogTitle>

				<DialogContent className={classes.modalBody}>
					{this.renderMembersView(this.state.selectedTeamMembers)}
				</DialogContent>
			</Dialog>
		)
	}

	render() {
		var tabName = "Teams"
		const { classes } = this.props
		let limit = this.props.itemsPerPage
		let offset = Math.max(this.props.currentPage - 1, 0) * limit
		let total = this.props.totalCount
		const paginationClasses = {
			colorInheritCurrent: classes.colorInheritCurrent,
			colorInheritOther: classes.colorInheritOther
		}
		return (
			<div>
				{this.renderTeamMembersModal()}
				<GridContainer direction="row" alignItems="flex-start">
					<GridItem xs={12} sm={12}>
						<Card raised color="gmgTheme">
							<CardHeader color="gmgTheme">
								<GridContainer alignItems="center" justify="space-between" direction="row">
									<GridItem xs={12} sm={6} md={6} lg={8}>
										<h4 className={classes.whiteTextColorStyle}>
											<strong>{tabName}</strong>
										</h4>
									</GridItem>
									<GridItem xs={12} sm={6} md={6} lg={4}>
										<SearchBox
											label="Search"
											placeholder="Search Team"
											value={this.props.searchText}
											onChange={(text) => {
												this.props.onChangeSearchText(text)
											}}
										/>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody>{this.renderTeamsView(this.props.teamsList)}</CardBody>
							<CardFooter>
								<GridContainer direction="row" alignItems="center" justify="center">
									<GridItem sm={12} md={8} lg={6}>
										<div className={classes.paginationDiv}>
											<Pagination
												classes={paginationClasses}
												currentPageColor="inherit"
												otherPageColor="inherit"
												size={"large"}
												limit={limit}
												offset={offset}
												total={total}
												onClick={(e, offset) => this.props.onClickPaginationButton(offset)}
											/>
										</div>
									</GridItem>
								</GridContainer>
							</CardFooter>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

TeamsListUI.propTypes = {
	classes: PropTypes.object.isRequired,
	teamsList: PropTypes.arrayOf(PropTypes.object).isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

let finalStyles = combineStyles(styles, modalStyle, extendedTablesStyle)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(finalStyles)(TeamsListUI))
