import actionTypes from "./actionTypes"

const initialState = {
	loggedInUserData: null,
	isLoginModalOpen: false,
	isLoading: false,
	sweetAlert: null, // {alertType, title, description}
	redirectAfterLogin: null,
	breadCrumbStack: [],
	darkMode: false,
	scrollUpdatedCount: 0,
	redirectOnError: "",
	theme: "light",
	keywordSearchText: "",
	notificationCount: 0
}

const reducer = (state = initialState, action) => {
	const newState = { ...state }

	switch (action.type) {
		case actionTypes.LOGIN_MODAL_OPEN:
			newState.isLoginModalOpen = true
			break

		case actionTypes.LOGIN_MODAL_CLOSE:
			newState.isLoginModalOpen = false
			newState.redirectAfterLogin = null
			break

		case actionTypes.LOGGEDIN_USER_DATA_SET:
			newState.loggedInUserData = action.payload
			break

		case actionTypes.LOADING_SPINNER_SET:
			newState.isLoading = true
			break
		case actionTypes.LOADING_SPINNER_RESET:
			newState.isLoading = false
			break

		case actionTypes.SHOW_ALERT:
			newState.sweetAlert = action.payload
			break

		case actionTypes.HIDE_ALERT:
			newState.sweetAlert = null
			break

		case actionTypes.AFTER_LOGIN_REDIRECT_URL_SET:
			newState.redirectAfterLogin = action.payload
			break

		case actionTypes.RESET_BREAD_CRUMB_STACK:
			newState.breadCrumbStack = []
			break

		case actionTypes.PUSH_BREAD_CRUMB_STACK:
			let brdStk = []
			let found = false
			newState.breadCrumbStack.map((data) => {
				if (!found) brdStk.push(data)
				if (data.link == action.payload.link) found = true
			})
			//newState.breadCrumbStack = newState.breadCrumbStack.filter(data => (data.link != action.payload.link));
			newState.breadCrumbStack = brdStk
			if (!found) newState.breadCrumbStack.push(action.payload)
			break

		case actionTypes.POP_BREAD_CRUMB_STACK:
			newState.breadCrumbStack.pop()
			break

		case actionTypes.TOGGLE_DARK_MODE:
			newState.darkMode = action.payload
			break

		case actionTypes.CONTENT_HEIGHT_UPDATED:
			newState.scrollUpdatedCount = newState.scrollUpdatedCount + 1
			break

		case actionTypes.REDIRECT_ON_ERROR:
			newState.redirectOnError = action.payload
			break

		case actionTypes.SWITCH_THEME:
			newState.theme = action.payload
			break

		case actionTypes.SET_KEYWORD_SEARCH_TEXT:
			newState.keywordSearchText = action.payload
			break

		case actionTypes.SET_NOTIFICATION_COUNT:
			newState.notificationCount = action.payload
			break
	}
	return newState
}

export default reducer
