import { debounce } from "lodash"
import { useCallback, useEffect } from "react"

function useDebounce(callback, delay) {
	const debouncedFn = useCallback(
		debounce((...args) => callback(...args), delay),
		[delay] // will recreate if delay changes
	)
	return debouncedFn
}

export default function DebouncedActionPerformer({ action, delay, sourceValue }) {
	const debouncedAction = useDebounce((value) => action(value), delay)
	useEffect(() => {
		debouncedAction(sourceValue)
	}, [sourceValue])

	return null
}
