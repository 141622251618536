import React from "react";
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import Button from "../../../components/CustomButtons/Button";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: "solver",
      name: "",
      email: "",
      participants: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.inputParticipants = this.inputParticipants.bind(this);

  }

  inputParticipants() {
    //this.state.participants.push({ name: this.state.name, email: this.state.email, role: this.state.role});
    //this.setState({}, this.updateState);

    let participants = this.state.participants;
    participants.push({ name: this.state.name, email: this.state.email, role: this.state.role });
    this.setState({ participants: participants }, this.updateState);

  }

  updateState() {
    console.log("inside stepAppPeople", this.state);
    this.props.handleAddPeople(this.state);
  };

  handleSimple = id => event => {
    this.setState({ [id]: event.target.value }, this.updateState);
  };

  handleChange = event => {
    this.setState({ role: event.target.value }, this.updateState);
  };

  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    }, this.updateState);
  }

  render() {
    const { classes } = this.props;
    const { name } = this.state;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12}>
            <h4 className={classes.infoText}>Add people to your challenge</h4>
          </GridItem>
          <GridItem xs>
            <CustomInput
              labelText="Your Name"
              inputProps={{
                onChange: this.handleInputChange,
                name: "name"
              }}
            />
          </GridItem>
          <GridItem xs>
            <CustomInput
              labelText="Your Email"
              inputProps={{
                onChange: this.handleInputChange,
                name: "email"
              }}
            />
          </GridItem>
        </GridContainer>

        <GridContainer direction="row" justify="center" alignItems="baseline">
          <GridItem>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Choose their role</FormLabel>
              <RadioGroup
                aria-label="Role"
                name="Role"
                className={classes.group}
                value={this.state.value}
                onChange={this.handleChange}
                row={true}
              >
                <FormControlLabel value="solver" control={<Radio />} label="Solver" />
                <FormControlLabel value="teamlead" control={<Radio />} label="Teamlead" />
                <FormControlLabel value="administrator" control={<Radio />} label="Administrator" />
                <FormControlLabel value="judge" control={<Radio />} label="Judge" />
                <FormControlLabel value="moderator" control={<Radio />} label="Moderator" />
              </RadioGroup>
            </FormControl>

          </GridItem>
        </GridContainer>

        <GridContainer justify="flex-end">
          <GridItem>
            <div className={classes.center}>
              <Button round color="rose" onClick={this.inputParticipants}>
                Save and Add Another
              </Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(style)(Step3);
