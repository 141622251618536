import { connect } from "react-redux"
import actionTypes from "./actionTypes"

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack,
		themeMode: state.theme
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: { alertType, title, description }
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

export default function connectRedux(wrappedComponent) {
	return connect(mapStateToProps, mapDispatchToProps)(wrappedComponent)
}
