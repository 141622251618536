import apolloFetcher from "apolloFetcher";

let challengeTagServices = {};

challengeTagServices.searchChallengeTags = (searchKey) => {
    let query = `
    query searchChallengeTags( $searchKey: String ){
        searchChallengeTags(searchKey: $searchKey){
          status
          message
          totalCount
          data
        }
    }
    `;
    let variables = { searchKey: searchKey };
    return new Promise((resolve, reject) => {
        apolloFetcher.fetchWithToken({
            query, variables
        }).then(res => {
            if (!res || !res.data || !res.data.searchChallengeTags) throw new Error("Error occured while fetching challenges");
            if (!res.data.searchChallengeTags.status) throw new Error(res.data.searchChallengeTags.message);
            resolve(res.data.searchChallengeTags.data);
        }).catch(err => {
            reject(err);
        });
    });
}

export default challengeTagServices;