import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Grid, TextField, Typography, Button } from '@material-ui/core';

import CustomModal from 'components/general/CustomModal';

import { adminEditUser } from 'dataServices/AdminPageServices/users';

import styles from "./styles.module.scss";
import actionTypes from 'reduxjs/actionTypes';

const AdminEditUser = props => {
  const {
    user,
    isModalOpen,
    closeModal,
    setLoadingSpinner,
    resetLoadingSpinner,
  } = props;
  
  const [name, setName] = useState("");
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState(null);

  const resetStates = () => {
    setName(user.name);
    setError(false);
    setApiError(null);
  };

  const onModalClose = (shouldRefresh=false) => {
    resetStates();
    closeModal(shouldRefresh);
  };

  const onInputValueChange = e => {
    setName(e.target.value);
    setError(false);
    setApiError(null);
  };

  const onEditUser = async () => {
    if ((name.trim() || '').length == 0) {
      setError(true);
      return;
    }

    try {
      setLoadingSpinner();
      
      const response = await adminEditUser(name, user.username, user.email);
      
      if (response.status == 200) {
        onModalClose(true);
        setApiError(null);
      } else {
        setApiError(response.message);
      }

      resetLoadingSpinner();
    } catch (err) {
      setApiError(err.message || err);
      resetLoadingSpinner();
    }
  };

  const renderEditUserSection = () => {
    return (
      <Grid container spacing={2} className={styles.wrapper}>
        <Grid item xs={12} sm={12}>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            value={user.username}
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            value={user.email}
            disabled
          />
        </Grid>

        <Grid item xs={12} sm={12} spacing={2}>
          <Typography variant={'subtitle2'}>
            Enter your name
          </Typography>
          <div style={{ height: "10px" }}></div>
          <TextField
            label="Name *"
            variant="outlined"
            fullWidth
            value={name}
            onChange={onInputValueChange}
            error={error && (name.trim() || '').length == 0}
            helperText={error ? "This field is required" : ""}
          />
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent={apiError ? "space-between" : "flex-end"}
        >
        { apiError && (
          <div style={{ color: "red" }}>{apiError}</div>
        )}
          <Button
            size={'large'}
            variant={'contained'}
            style={{ backgroundColor: "#3daeff", color: "white" }}
            onClick={onEditUser}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    setName(user.name || "");
  }, [user]);

  return (
    <CustomModal
      title="Edit User"
      visible={isModalOpen}
      onClose={onModalClose}
    >
      {renderEditUserSection()}
    </CustomModal>
  );
};

AdminEditUser.propTypes = {
  user: PropTypes.any.isRequired,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

AdminEditUser.defaultProps = {
  isModalOpen: false,
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
    setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditUser);
