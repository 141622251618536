import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import Tooltip from "@material-ui/core/Tooltip"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import SweetAlert from "react-bootstrap-sweetalert"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import challengeServices from "dataServices/challengeServices"
import { Typography } from "@material-ui/core"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import ReactHtmlParser from 'html-react-parser'

import DeleteIcon from "@material-ui/icons/Delete"
import timeUtils from "utils/timeUtils.js"
import aboutPageServices from "dataServices/aboutPageServices.js"
import helper from "helper"
import FontOverridePatch from "utils/FontOverridePatch"

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	container: {
		margin: "0px 0px",
		padding: "40px 20px",
		// backgroundColor: "transparent",
		//color: theme.palette.text.page,
		backgroundColor: theme.palette.background.page
	},
	bottomNavigationBarStyle: {
		backgroundColor: "#C3FDFF",
		color: "#101316"
	},
	faqPageBackgroundColorStyle: {
		//backgroundColor: "#302f2f"
	},
	buttonSuccessColor: {
		backgroundColor: "#1eb980",
		"&:hover,&:focus": {
			backgroundColor: "#1eb980",
			boxShadow: "0px 6px 14px rgba(30, 185, 128, .3)"
		}
	},
	whiteTextColorStyle: {
		color: theme.palette.text.page
	},
	...sweetAlertStyle
})

class Faq extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: 0,
			editFaqMode: false,
			questionEditorState: EditorState.createEmpty(),
			answerEditorState: EditorState.createEmpty(),
			currentFaq: null,
			faqList: [],
			contactInfo: null,
			faqLastUpdate: null,
			canEditFaq: false,
			dataFetchDone: false
		}
	}

	handleSimple = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleChange = (event, value) => {
		this.setState({ value })
	}

	onQuestionEditorStateChange = (questionEditorState) => {
		this.setState({
			questionEditorState
		})
	}

	onAnswerEditorStateChange = (answerEditorState) => {
		this.setState({
			answerEditorState
		})
	}

	addFaq() {
		let question = draftToHtml(convertToRaw(this.state.questionEditorState.getCurrentContent()))
		let answer = draftToHtml(convertToRaw(this.state.answerEditorState.getCurrentContent()))

		if (!helper.isRTEHtmlStringValid(question)) {
			this.props.showAlert("warning", "Oops!", "Question can not be empty")
			return
		}

		if (!helper.isRTEHtmlStringValid(answer)) {
			this.props.showAlert("warning", "Oops!", "Answer can not be empty")
			return
		}

		var variables = {
			challengeId: this.props.challengeId,
			faq: [
				{
					question: question,
					answer: answer
				}
			]
		}

		this.props.setLoadingSpinner()
		challengeServices
			.updateChallenge(variables)
			.then((status) => {
				this.props.resetLoadingSpinner()
				if (status) {
					console.log("FAQ update successfully done!")
					this.setState(
						{
							questionEditorState: EditorState.createEmpty(),
							answerEditorState: EditorState.createEmpty(),
							editFaqMode: false
						},
						() => {
							this.fetchAllData()
						}
					)
				}
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("warning", "Opps!", "Error occured while updating the FAQ")
			})
	}

	deleteFaqById = (faqId) => {
		this.props.setLoadingSpinner()
		challengeServices
			.deleteFaqById(this.props.challengeId, faqId)
			.then((data) => {
				let faqList = data.challengeDetails.faq ? data.challengeDetails.faq : []
				let faqLastUpdate = data.challengeDetails.faqLastUpdate
					? data.challengeDetails.faqLastUpdate
					: null
				this.props.resetLoadingSpinner()
				this.setState({
					faqList: faqList,
					faqLastUpdate: faqLastUpdate
				})
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log(err.message)
				this.props.showAlert("error", "Opps!", "Error deleting FAQ, Please try again")
			})
	}

	fetchFaq() {
		let challengeId = this.props.challengeId
		return challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				return data ? data : null
			})
			.catch((err) => {
				this.props.showAlert("warning", "Error", err.message)
				return null
			})
	}

	fetchContactInformation = () => {
		return aboutPageServices
			.getAboutPageByCustomerId()
			.then((data) => {
				return data ? data : null
			})
			.catch((err) => {
				this.props.showAlert("warning", "Ooops!", err.message)
				return null
			})
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		Promise.all([this.fetchFaq(), this.fetchContactInformation()])
			.then((result) => {
				console.log("multiple api calls ", result)
				console.log("Both APIs call okay")
				this.props.resetLoadingSpinner()
				let faqList = result[0] ? result[0].challengeDetails.faq : []
				let faqLastUpdate = result[0] ? result[0].challengeDetails.faqLastUpdate : null
				let contactInfo = result[1] ? result[1].aboutPageDetails.contactUs : null
				this.setState({
					faqList: faqList,
					contactInfo: contactInfo,
					faqLastUpdate: faqLastUpdate,
					dataFetchDone: true
				})
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log(err.message)
			})
	}

	componentWillReceiveProps(newProps) {
		this.setState({ canEditFaq: newProps.canEditChallenge })
	}

	componentDidMount() {
		if (this.props.canEditChallenge) {
			this.setState({
				canEditFaq: true
			})
		}

		this.fetchAllData()
	}

	renderFaq = (faq, key) => {
		const { classes } = this.props
		return (
			<GridContainer key={key} direction="column" justify="space-between" allignItems="stretch">
				<GridItem xs={12}>
					<div className={classes.whiteTextColorStyle} style={{ float: "left" }}>
						<strong>Q.&nbsp;&nbsp;</strong>
					</div>
					<div className={classes.whiteTextColorStyle}>
						<FontOverridePatch>{ReactHtmlParser(faq.question || "")}</FontOverridePatch>
					</div>
				</GridItem>
				<GridItem xs={12}>
					<div className={classes.whiteTextColorStyle} style={{ float: "left" }}>
						<strong>A.&nbsp;&nbsp;</strong>
					</div>
					<div className={classes.whiteTextColorStyle}>
						<FontOverridePatch>{ReactHtmlParser(faq.answer || "")}</FontOverridePatch>
					</div>
				</GridItem>
				{this.state.canEditFaq && (
					<GridItem sx={12}>
						<GridContainer direction="row" justify="flex-end">
							<GridItem sx={12}>
								<Button
									round
									color="rose"
									justIcon
									onClick={() => {
										this.deleteFaqById(faq.faqId)
									}}
								>
									<Tooltip title="Delete Faq" placement="top">
										<DeleteIcon />
									</Tooltip>
								</Button>
							</GridItem>
						</GridContainer>
					</GridItem>
				)}
				<GridItem xs={12} style={{ margin: "20px 0px 20px 0px" }}>
					<Divider />
				</GridItem>
			</GridContainer>
		)
	}

	renderFaqListView = () => {
		const { classes } = this.props
		if ((!this.state.faqList || !this.state.faqList.length) && this.state.dataFetchDone == true) {
			return (
				<Card color="gmgTheme" raised className={classes.faqPageBackgroundColorStyle}>
					<CardBody>
						{this.state.canEditFaq ? (
							<Typography
								color="textSecondary"
								align="center"
								className={classes.whiteTextColorStyle}
							>
								{" "}
								<div style={{ textTransform: "capitalize" }}>Please add some FAQ</div>
							</Typography>
						) : (
							<Typography
								color="textSecondary"
								align="center"
								className={classes.whiteTextColorStyle}
							>
								{" "}
								<div style={{ textTransform: "capitalize" }}>No FAQ is available yet</div>
							</Typography>
						)}
						{this.state.canEditFaq ? (
							<GridContainer direction="row" justify="flex-end">
								<GridItem>
									<Button
										className={classes.buttonSuccessColor}
										onClick={() => {
											this.setState({
												editFaqMode: true
											})
										}}
									>
										Add FAQ
									</Button>
								</GridItem>
							</GridContainer>
						) : null}
					</CardBody>
				</Card>
			)
		}

		let view = this.state.faqList.map((faq, key) => {
			return this.renderFaq(faq, key)
		})

		return (
			<Card
				color="gmgTheme"
				raised
				style={{ padding: "10px 10px 10px 10px" }}
				className={classes.faqPageBackgroundColorStyle}
			>
				<GridContainer direction="row" justify="space-between">
					<GridItem sx={12} sm={12} md={6} lg={8}>
						<Typography variant="subtitle2" className={classes.whiteTextColorStyle}>
							{" "}
							<div style={{ textTransform: "capitalize", marginBottom: "20px" }}>
								{" "}
								<strong>
									Last Updated:&nbsp;
									{timeUtils.convertUtcToLocalDateTime(
										this.state.faqLastUpdate,
										"YYYY/MM/DD hh:mm:ss A Z"
									)}
								</strong>{" "}
							</div>
						</Typography>
						{view}
						{this.state.canEditFaq ? (
							<Button
								className={classes.buttonSuccessColor}
								onClick={() => {
									this.setState({
										editFaqMode: true
									})
								}}
							>
								Add FAQ
							</Button>
						) : null}
					</GridItem>
					<GridItem sx={12} sm={8} md={6} lg={4}>
						<div className={classes.whiteTextColorStyle}>
							<FontOverridePatch>{ReactHtmlParser(this.state.contactInfo || "")}</FontOverridePatch>
						</div>
					</GridItem>
				</GridContainer>
			</Card>
		)
	}

	renderEditFaqView = () => {
		const { classes } = this.props
		return (
			<Card raised>
				<CardBody>
					<GridContainer direction="row" justify="flex-end">
						<GridItem xs={12} sm={12}>
							<Typography className={classes.whiteTextColorStyle} color="textSecondary">
								{" "}
								<div style={{ textTransform: "capitalize" }}>Question: </div>
							</Typography>
							<h4> Question: </h4>
							<div style={{ border: "1px solid grey" }}>
								<FontOverridePatch isDraftEditor>
									<Editor
										editorState={this.state.questionEditorState}
										toolbarClassName="toolbarClassName"
										wrapperClassName=""
										editorClassName={classes.cccEditorStyle}
										className={classes.editRulesStyle}
										toolbar={{
											options: [
												"inline",
												"blockType",
												"fontSize",
												"fontFamily",
												"list",
												"textAlign",
												"link",
												"embedded",
												"remove",
												"history"
											]
										}}
										onEditorStateChange={this.onQuestionEditorStateChange}
									/>
								</FontOverridePatch>
							</div>
						</GridItem>
						<GridItem xs={12} sm={12}>
							<Typography className={classes.whiteTextColorStyle} color="textSecondary">
								{" "}
								<div style={{ textTransform: "capitalize" }}>Answer: </div>
							</Typography>
							<h4> Answer: </h4>
							<div style={{ border: "1px solid grey" }}>
								<FontOverridePatch isDraftEditor>
									<Editor
										editorState={this.state.answerEditorState}
										toolbarClassName="toolbarClassName"
										wrapperClassName=""
										editorClassName={classes.cccEditorStyle}
										className={classes.editRulesStyle}
										toolbar={{
											options: [
												"inline",
												"blockType",
												"fontSize",
												"fontFamily",
												"list",
												"textAlign",
												"link",
												"embedded",
												"remove",
												"history"
											]
										}}
										onEditorStateChange={this.onAnswerEditorStateChange}
									/>
								</FontOverridePatch>
							</div>
						</GridItem>
						<GridItem xs={12} sm={12}>
							<GridContainer direction="row" justify="space-between">
								<GridItem>
									<Button
										color="warning"
										onClick={() => {
											this.setState({
												editFaqMode: false,
												questionEditorState: EditorState.createEmpty(),
												answerEditorState: EditorState.createEmpty()
											})
										}}
									>
										Cancel
									</Button>
								</GridItem>
								<GridItem>
									<Button
										color="success"
										disabled={
											!helper.isRTEHtmlStringValid(
												draftToHtml(
													convertToRaw(this.state.questionEditorState.getCurrentContent())
												)
											) ||
											!helper.isRTEHtmlStringValid(
												draftToHtml(convertToRaw(this.state.answerEditorState.getCurrentContent()))
											)
										}
										onClick={() => {
											this.addFaq()
										}}
									>
										Add
									</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		)
	}

	render() {
		const { classes } = this.props

		let xs = 12,
			sm = 12,
			md = 12,
			lg = 12
		if (this.state.editFaqMode) {
			xs = 12
			sm = 10
			md = 8
			lg = 8
		}

		return (
			<div className={classes.container}>
				{this.state.editFaqMode && this.state.dataFetchDone
					? this.renderEditFaqView()
					: !this.state.editFaqMode && this.state.dataFetchDone
					? this.renderFaqListView()
					: null}
			</div>
		)
		// return (
		// 	<GridContainer direction="row" justify="center">
		// 		<GridItem xs={xs} sm={sm} md={md} lg={lg}>
		// 			{this.state.editFaqMode && this.state.dataFetchDone
		// 				? this.renderEditFaqView()
		// 				: !this.state.editFaqMode && this.state.dataFetchDone
		// 				? this.renderFaqListView()
		// 				: null}
		// 		</GridItem>
		// 	</GridContainer>
		// )
	}
}

Faq.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Faq))
