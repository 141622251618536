import React from "react"
import combineStyles from "utils/combineStyle"
import { withStyles } from "@material-ui/styles"
import { connect } from "react-redux"
import { TextField } from "@material-ui/core"
import actionTypes from "reduxjs/actionTypes"
import querystring from "querystring"
import keywordBasedSearchService from "dataServices/keywordBasedFtsService"
import userServices from "dataServices/userServices"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import CardIcon from "components/Card/CardIcon"
import Divider from "@material-ui/core/Divider"
import { Typography } from "@material-ui/core"

import CardAvatar from "components/Card/CardAvatar"
import emptyAvatar from "assets/img/faces/emptyProfilePicture.webp"
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button"

import Accordion from "components/Accordion/Accordion"
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"

import Constants from "constants.js"
import ReactHtmlParser from 'html-react-parser'

import defaultChallengeImage from "assets/img/defaultChallengeImage.webp"
import challengeCompletedLogo from "assets/img/faces/challengeCompleted.webp"

import helper from "../../helper"
import PhaseUtils from "utils/phaseUtils.js"
import challengeUtils from "utils/challengeUtils"
import FontOverridePatch from "utils/FontOverridePatch.jsx"

class SearchPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			challenges: [],
			users: [],
			isSearching: false,
			isSearchDone: false,
			searchText: "",
			errorText: "Please type something to search!",
			userProfile: null
		}
	}

	startSearch = (searchText) => {
		if (!searchText || searchText == "" || searchText.length < 3) {
			this.setState({ challenges: [], users: [], errorText: "Please type something to search!" })
			return
		}
		this.setState({ isSearching: true })
		keywordBasedSearchService
			.keywordBasedFTS(searchText)
			.then((data) => {
				console.log("search data = ", data)
				if (!data || !data.challenges || !data.users)
					throw new Error("Error occurred while searching")
				this.setState({
					challenges: data.challenges,
					users: data.users,
					isSearching: false,
					isSearchDone: true,
					errorText: ""
				})
			})
			.catch((err) => {
				console.log("Error searching, err =", err.message)
				this.setState({
					challenges: [],
					users: [],
					errorText: err.message,
					isSearching: false,
					isSearchDone: true
				})
			})
	}

	fetchCurrentUserProfile = () => {
		userServices
			.getCurrentUser()
			.then((data) => {
				this.setState({ userProfile: data })
			})
			.catch((err) => {
				console.log("Error getting current User", err)
			})
	}

	componentDidMount() {
		document.getElementById("keyword-search-input").focus()
		this.setState({ searchText: this.props.keywordSearchText })
		this.startSearch(this.props.keywordSearchText)
		this.props.resetBreadCrumbStack()
		this.props.pushBreadCrumbStack({ name: "Home", link: "/home/index" })
		this.props.pushBreadCrumbStack({
			name: "Search Result",
			link: this.props.location
		})
		this.fetchCurrentUserProfile()
	}

	componentWillUnmount() {
		this.props.setKeywordSearchText("")
	}

	componentDidUpdate(prevProps) {
		if (prevProps.keywordSearchText !== this.props.keywordSearchText) {
			this.startSearch(this.props.keywordSearchText)
		}
	}

	render() {
		const { classes } = this.props

		return (
			<div>
				{this.state.isSearching && !this.state.isSearchDone && (
					<div>
						<h2> Searching... </h2>
					</div>
				)}
				{!this.props.keywordSearchText || this.props.keywordSearchText.length < 3 ? (
					<div>
						<h2> Please type something to search </h2>
					</div>
				) : (
					<div>
						{this.renderChallenges()}
						{this.renderUsersView()}
					</div>
				)}
			</div>
		)
	}

	renderChallenges = () => {
		const { classes } = this.props
		if (this.state.isSearchDone == false || this.props.keywordSearchText == "") return null
		return (
			<div>
				<Card color="gmgTheme">
					<CardHeader color="gmgTheme">
						Search results in <strong>Challenges</strong>:
					</CardHeader>
					<CardBody>
						{this.state.challenges.length == 0 ? (
							<h4> {"No Challenges found"} </h4>
						) : (
							<GridContainer container direction="row" justify="flex-start" alignItems="flex-start">
								{this.state.challenges.map((challenge) => this.renderChallenge(challenge))}
							</GridContainer>
						)}
					</CardBody>
				</Card>
			</div>
		)
	}

	renderChallenge = (challenge) => {
		const { classes } = this.props
		let cardColor = "gmgTheme"
		if (!challenge) return null
		let isJoinedAlready = false
		let canJoinChallenge = this.state.canJoinChallenge || false
		if (this.state.userProfile) {
			let solvers = challenge.challengeDetails.participants
			if (solvers) {
				solvers.map((user) => {
					if (user.userId === this.state.userProfile.userId) isJoinedAlready = true
				})
			}
			this.state.userProfile.roles.map((r) => {
				if (
					!(r.RoleId == "solver" || r.RoleId == "solverForTeam") &&
					r.challengeId == challenge.challengeId
				) {
					canJoinChallenge = false
				}
			})
		}

		let currentPhase = "" //PhaseUtils.getCurrentPhase(challenge.challengeDetails.phases)
		let startDate = "",
			endDate = ""
		if (challenge.challengeDetails.currentPhase) {
			currentPhase = challenge.challengeDetails.currentPhase.phaseTitle
			startDate = challenge.challengeDetails.currentPhase.startDate
			endDate = challenge.challengeDetails.currentPhase.endDate
		}
		let winnerUserName = null
		if (challenge.challengeDetails.leaderboard) {
			challenge.challengeDetails.leaderboard.map((row) => {
				if (row.standing == 1) winnerUserName = row.userName
			})
		}
		let statusBGColor = "grey"
		if (challenge.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.ACTIVE)
			statusBGColor = "blue"
		if (challenge.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.COMPLETED)
			statusBGColor = "green"
		if (challenge.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.CLOSED)
			statusBGColor = "red"
		let showChallengeStatus = false
		//if challenge status is nedded to be displayed based on role please update the fts searchAPI and add filtering logic here
		return (
			<GridItem key={challenge.challengeId} xs={12} sm={6} md={4} lg={3}>
				<Card raised={true} color={cardColor}>
					<CardHeader
						color={cardColor}
						className={classes.textOverflowEllipsisStyleForChallengeTitle}
					>
						{challenge.challengeDetails.leaderboard &&
						challenge.challengeDetails.leaderboard.length ? (
							<CardIcon color="success">
								<img src={challengeCompletedLogo} alt="Image of challenge completed logo" />
							</CardIcon>
						) : null}
						{challenge.challengeDetails.title}
					</CardHeader>
					<CardBody>
						<img
							width={"100%"}
							className={classes.challengeCoverImage}
							src={Constants.Settings.APIURL + challenge.challengeDetails.challengeImage}
							alt="Image of challenge cover"
							onError={(event) => {
								event.target.src = defaultChallengeImage
							}}
						/>

						<p className={classes.currentPhaseStyle}>{currentPhase}</p>

						{showChallengeStatus ? (
							<div style={{ height: "40px", marginTop: "15px" }}>
								<GridContainer direction="row" alignItems="center" justify="center">
									<GridItem>
										<span
											style={{
												padding: "5px 10px",
												borderRadius: "50px",
												backgroundColor: statusBGColor,
												color: "#FFF"
											}}
										>
											{challenge.challengeDetails.challengeStatus}
										</span>
									</GridItem>
								</GridContainer>
							</div>
						) : (
							<div style={{ height: "5px", marginTop: "10px" }}></div>
						)}

						<Accordion
							collapses={[
								{
									title: (
										<div
											style={{
												height: "23px",
												overflow: "hidden",
												textOverflow: "hidden"
											}}
										>
											{challenge.challengeDetails.shortDescription.substr(0, 30)}
										</div>
									),
									content: (
										<div style={{ paddingLeft: "5px", width: "100%" }}>
											<p className={classes.cardProductDesciprion}>
												<FontOverridePatch>
													{ReactHtmlParser(challenge.challengeDetails.description || "")}
												</FontOverridePatch>
											</p>
											<p className={classes.cardProductDesciprion}>
												Prize Amount:{" "}
												{challengeUtils.getPrizeFormatted(challenge.challengeDetails.prizeAmount)}
											</p>
											{startDate && (
												<p className={classes.cardProductDesciprion}>Start Date: {startDate}</p>
											)}
											{endDate && (
												<p className={classes.cardProductDesciprion}>End Date: {endDate}</p>
											)}

											{challenge.challengeDetails.sponsorsName && (
												<p className={classes.cardProductDesciprion}>
													By{" "}
													{challenge.challengeDetails.sponsorsName
														? challenge.challengeDetails.sponsorsName
														: "Not available"}
												</p>
											)}
										</div>
									)
								}
							]}
						/>
					</CardBody>
					<CardFooter className={classes.justifyContentCenter}>
						<div className={classes.center}>
							<Button
								square
								color="info"
								size="sm"
								onClick={() => this.handleOnClickLearnMore(challenge.urlSlug)}
							>
								Learn more
							</Button>
							{challenge.challengeDetails.leaderboard &&
							challenge.challengeDetails.leaderboard.length &&
							this.state.userProfile &&
							this.state.canGetFinalLeaderboardForChallenge ? (
								<Button
									square
									color="primary"
									size="sm"
									onClick={() =>
										this.props.history.push("/dashboard/leaderboard/" + challenge.urlSlug)
									}
								>
									Leaderboard
								</Button>
							) : null}
							{challenge.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.ACTIVE &&
							(canJoinChallenge || !this.props.loggedInUserData) ? (
								<Button
									squre
									disabled={isJoinedAlready}
									color={isJoinedAlready ? "gmgGreen900" : "gmgGreen900"}
									size="sm"
									onClick={() => this.handleOnClickJoin(challenge.urlSlug)}
								>
									{isJoinedAlready ? "Already Joined" : "Join"}
								</Button>
							) : null}
						</div>
					</CardFooter>
				</Card>
			</GridItem>
		)
	}

	renderUser = (user, key) => {
		var xs = 12,
			sm = 6,
			md = 4,
			lg = 3
		const { classes } = this.props
		if (!user) return null
		console.log("avatar image path - ", user.avatarImagePath)
		return (
			<GridItem key={key} xs={xs} sm={sm} md={md} lg={lg}>
				<Card profile raised={true} color="gmgTheme">
					<CardAvatar profile style={{ height: "130px", minWidth: "130px" }}>
						{/* <a href="#pablo" onClick={e => e.preventDefault()}> */}
						{user.avatarImagePath ? (
							<img
								src={
									user.avatarImagePath.includes("http")
										? user.avatarImagePath
										: Constants.Settings.APIURL + user.avatarImagePath
								}
								alt="Image of user's profile avatar"
								style={{ objectFit: "contain" }}
								onError={(event) => {
									event.target.src = emptyAvatar
								}}
							/>
						) : (
							<img src={emptyAvatar} alt="Image of user's profile avatar default" />
						)}
						{/* </a> */}
					</CardAvatar>
					<CardBody profile>
						<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
							Username: {user.username}
						</h6>
						<h3
							className={`${classes.cardTitle} ${classes.textOverflowEllipsisStyleForUserNameOnly}`}
						>
							{user.name}
						</h3>
						<Divider variant="middle" />
						{user.organizationName ? (
							<h6
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForOrganizationNameOnly}`}
							>
								Organization Name: {user.organizationName}
							</h6>
						) : (
							<h6
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForOrganizationNameOnly}`}
							>
								Organization Name: Not Available
							</h6>
						)}
						<Divider variant="middle" />
						{user.state ? (
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								State: {user.state}
							</h6>
						) : (
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								State: Not Available
							</h6>
						)}
					</CardBody>
				</Card>
			</GridItem>
		)
	}

	renderUsersView = () => {
		const { classes } = this.props
		if (this.state.isSearchDone == false || this.props.keywordSearchText == "") return null
		let users = this.state.users

		return (
			<div>
				<Card color="gmgTheme">
					<CardHeader color="gmgTheme">
						Search results in <strong>Users</strong>:
					</CardHeader>
					<CardBody>
						{this.state.users.length == 0 ? (
							<h4> {"No users found"} </h4>
						) : (
							<div style={{ paddingTop: "10px" }}>
								<GridContainer
									container
									direction="row"
									justify="flex-start"
									alignItems="flex-start"
								>
									{this.state.users.map((user) => this.renderUser(user))}
								</GridContainer>
							</div>
						)}
					</CardBody>
				</Card>
			</div>
		)
	}

	handleOnClickLearnMore = (urlSlug) => {
		if (!this.state.userProfile) {
			this.props.history.push("/home/challenge-details/" + urlSlug)
		} else {
			this.props.history.push("/dashboard/challenge-details/" + urlSlug)
		}
	}
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack,
		keywordSearchText: state.keywordSearchText
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		setKeywordSearchText: (data) =>
			dispatch({ type: actionTypes.SET_KEYWORD_SEARCH_TEXT, payload: data })
	}
}

let extraStyles = (theme) => ({
	cardProductDesciprion: {
		borderLeft: "5px solid cyan",
		padding: "5px 10px 5px 10px"
	},
	currentPhaseStyle: {
		borderTop: "3px solid #FF6859",
		borderBottom: "3px solid #FF6859",
		margin: "20px 20px 20px 20px",
		padding: "5px 10px 5px 10px",
		fontSize: "1.3em",
		display: "block",
		color: theme.palette.text.page, //"#FFFFFF",
		textAlign: "center",
		overflowWrap: "break-word",
		height: "55px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontWeight: "400"
	},
	textOverflowEllipsisStyleForChallengeTitle: {
		overflowWrap: "break-word",
		height: "125px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontSize: "1.4em",
		textAlign: "center"
	},
	challengeCoverImage: {
		height: "250px",
		width: "100%",
		objectFit: "contain"
	}
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(extraStyles)(SearchPage))
