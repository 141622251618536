import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core";

class LoadingSpinner extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { classes } = this.props;
        if (this.props.isLoading) {
            return (<CircularProgress className={classes.progress} size={80} />)
        }
        else return null;
    }
}

let styles = theme => ({
    progress: {
        margin: "auto",
        position: "fixed",
        top: "0",
        right: "0",
        bottom: "0",
        left: "0",
        zIndex: "9999",
    },
});

LoadingSpinner.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading,
    };
};

export default connect(
    mapStateToProps,
    null
)(withStyles(styles)(LoadingSpinner));