import React, { Component } from "react"
import PropTypes from "prop-types"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import combineStyles from "utils/combineStyle";
import { connect } from "react-redux";
import actionTypes from "reduxjs/actionTypes";
import { withStyles } from "@material-ui/core";

class ShowAlertGlobal extends Component {
    render() {
        if (this.props.sweetAlert) {
            const { alertType, title, description } = this.props.sweetAlert;
            if (alertType) {
                var type = {}
                type[alertType] = alertType;
            }
            return (
                <SweetAlert
                    {...type}
                    style={{ display: "block", marginTop: "-100px" }}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes[alertType]
                    }
                >
                    {description}
                </SweetAlert>
            )
        }
        else return null;
    }

    hideAlert = () => {
        this.props.hideAlert();
    }
}

ShowAlertGlobal.propTypes = {
    classes: PropTypes.object.isRequired,
    sweetAlert: PropTypes.object.isRequired,
    hideAlert: PropTypes.func.isRequired,
    showAlert: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
    return {
        sweetAlert: state.sweetAlert,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
        showAlert: (alertType, title, description) => dispatch({
            type: actionTypes.SHOW_ALERT,
            payload: {
                alertType, title, description
            }
        }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(sweetAlertStyle)(ShowAlertGlobal));