import { fade } from '@material-ui/core/styles/colorManipulator';

export const LEADERBOARD_TABLE_COLUMN = [
  "#",
  "Name",
  "Title",
  "Average Score",
  "Score Variance",
  "Evaluation",
];

export const style = {
  colorInheritCurrent: {
    margin: '0.5px',
    color: '#000000',
    backgroundColor: fade("#00FF00", 0.5),
    "&:hover": {
      backgroundColor: fade("#00FF00", 0.5),
    },
  },
  colorInheritOther: {
    margin: '0.5px',
    color: '#000000',
    "&:hover": {
      backgroundColor: fade("#FF0000", 0.5)
    }
  },
};