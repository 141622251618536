import React from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Constants from "constants.js"
import authentication from "authentication.js"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import aboutPageServices from "dataServices/aboutPageServices.js"
import CardFooter from "components/Card/CardFooter"
import { Typography, ListItemIcon } from "@material-ui/core"
import userServices from "dataServices/userServices.js"
import { Redirect, NavLink } from "react-router-dom"
import CardAvatar from "components/Card/CardAvatar"
import emptyAvatar from "assets/img/faces/emptyProfilePicture.webp"
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx"
import LaunchIcon from "@material-ui/icons/Launch"
import teamServices from "dataServices/teamServices"

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	textOverflowEllipsisStyle: {
		overflowWrap: "break-word",
		height: "auto",
		overflow: "hidden",
		textOverflow: "ellipsis"
		// whiteSpace: "nowrap",
		// MaxWidth: "inherit",
		// overflow: "hidden",
		// textOverflow: "ellipsis"
	},
	textOverflowEllipsisStyleForOrganizationNameOnly: {
		overflowWrap: "break-word",
		height: "45px",
		overflow: "hidden",
		textOverflow: "ellipsis"
		// whiteSpace: "nowrap",
		// MaxWidth: "inherit",
		// overflow: "hidden",
		// textOverflow: "ellipsis"
	},
	textOverflowEllipsisStyleForUserNameOnly: {
		overflowWrap: "break-word",
		height: "65px",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	cardHeaderStyle: {},
	activeChallengesLinkStyle: {
		"&:hover,&:focus": {
			color: "#A45AB1"
		}
	},
	AccordianTextOverflowEllipsisStyle: {
		overflowWrap: "normal",
		MaxWidth: "inherit",
		overflow: "hidden",
		textOverflow: "ellipsis",
		"&:hover, &:focus": {}
	},
	activeChallengesListIconVerticalBar: {
		borderLeft: "5px solid cyan",
		padding: "5px 10px 5px 10px"
	},
	activeChallengesAccordionStyle: {
		width: "100%",
		margin: "0% 0% 0% 0%"
	},
	solversPageDarkBackgroundStyle: {
		backgroundColor: theme.palette.background.page //"#302f2f"
	},
	buttonSuccessColor: {
		backgroundColor: "#1eb980",
		"&:hover,&:focus": {
			backgroundColor: "#1eb980",
			boxShadow: "0px 6px 14px rgba(30, 185, 128, .3)"
		}
	},
	whiteTextColorStyle: {
		color: theme.palette.text.page
	},
	...userProfileStyles
})

class TeamUI extends React.Component {
	constructor(props) {
		super(props)
	}

	componentDidMount() {
		console.log("ComponentDidMount of TeamUI:", this.props)
	}

	renderSolver = (solver, key) => {
		console.log("solver = ", key, solver)
		var xs = 12,
			sm = 6,
			md = 4,
			lg = 3
		const { classes } = this.props
		if (!solver) return null
		var avatarImagePath = null
		if (solver.avatarImagePath) {
			avatarImagePath = solver.avatarImagePath
			if (!avatarImagePath.includes("http"))
				avatarImagePath = Constants.Settings.APIURL + solver.avatarImagePath
		}
		return (
			<GridItem key={key} xs={xs} sm={sm} md={md} lg={lg}>
				<Card color="gmgTheme" profile raised={true}>
					<CardAvatar profile style={{ height: "130px", marginTop: "0px" }}>
						{avatarImagePath ? (
							<img
								style={{ zoom: "500%", objectFit: "cover" }}
								src={avatarImagePath}
								alt="Image of user's profile avatar"
								onError={(event) => {
									event.target.src = emptyAvatar
								}}
							/>
						) : (
							<img src={emptyAvatar} alt="Image of user's profile avatar default" />
						)}
					</CardAvatar>
					<CardBody profile>
						<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
							Username: {solver.username}
						</h6>
						<h3
							className={`${classes.cardTitle} ${classes.textOverflowEllipsisStyleForUserNameOnly}`}
						>
							{solver.name}
						</h3>
						<Divider variant="middle" />
						{solver.organizationName ? (
							<h6
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForOrganizationNameOnly}`}
							>
								Organization Name: {solver.organizationName}
							</h6>
						) : (
							<h6
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForOrganizationNameOnly}`}
							>
								Organization Name: Not Available
							</h6>
						)}
						<Divider variant="middle" />
						{solver.state ? (
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								State: {solver.state}
							</h6>
						) : (
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								State: Not Available
							</h6>
						)}
					</CardBody>
				</Card>
			</GridItem>
		)
	}

	renderSolversView = (solverList) => {
		const { classes } = this.props
		if (solverList.length == 0) {
			return (
				<div style={{ margin: "30px auto" }}>
					<GridContainer justify="center" alignItems="flex-start">
						<GridItem>
							<Typography className={classes.whiteTextColorStyle}>
								<div style={{ textTransform: "capitalize" }}>No Members Found </div>
							</Typography>
						</GridItem>
					</GridContainer>
				</div>
			)
		}

		let view = solverList.map((solver, key) => {
			return this.renderSolver(solver, key)
		})
		return (
			<GridContainer
				container
				direction="row"
				justify="space-evenly"
				alignItems="flex-start"
				spacing={3}
			>
				{view}
			</GridContainer>
		)
	}

	render() {
		var tabName = "Team: " + this.props.teamName
		const { classes } = this.props
		return (
			<GridContainer direction="row" alignItems="flex-start">
				<GridItem xs={12} sm={12}>
					<Card color="gmgTheme" raised>
						<CardHeader color="gmgTheme">
							<GridContainer alignItems="center" justify="space-between" direction="row">
								<GridItem>
									<h4 className={classes.whiteTextColorStyle}>
										<strong>{tabName}</strong>
									</h4>
								</GridItem>
								<GridItem>
									{this.props.showEditButton && (
										<Button
											square
											color="gmgGreen900"
											disabled={this.props.disableTeamEditMode}
											onClick={() => {
												this.props.onClickEditTeam
													? this.props.onClickEditTeam()
													: console.log("Edit button onClick is missing on the props")
											}}
										>
											Edit Team
										</Button>
									)}
								</GridItem>
							</GridContainer>
						</CardHeader>
						<CardBody>{this.renderSolversView(this.props.teamMembers)}</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		)
	}
}

TeamUI.propTypes = {
	classes: PropTypes.object.isRequired,
	teamName: PropTypes.string.isRequired,
	teamMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TeamUI))
