import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Assignment from "@material-ui/icons/Assignment"
import Person from "@material-ui/icons/Person"
import Edit from "@material-ui/icons/Edit"
import Close from "@material-ui/icons/Close"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import manageChallengeServices from "dataServices/manageChallengeServices"
import { withStyles } from "@material-ui/core/styles"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import combineStyles from "utils/combineStyle"
import Table from "components/Table/Table.jsx"
import SimpleBreadcrumbs from "components/SimpleBreadcumbs.jsx"
import Constants from "constants.js"
import authentication from "authentication.js"
import ErrorPage from "../ErrorPage"
import querystring from "querystring"
import cookieHelper from "cookiesHelper"
import { NavLink } from "react-router-dom"
import constants from "constants.js"

class AcceptManageChallengeInvitation extends Component {
	constructor(props) {
		super(props)
		this.state = {
			canViewThisPage: true,
			showErrorComponent: false,
			challengeId: "",
			code: "",
			challengeTitle: "",
			urlSlug: "",
			isLoading: true,
			isAccepted: false,
			errorInAccepting: false,
			name: "",
			email: "",
			userId: "",
			role: ""
		}
	}

	acceptManageChallengeInvitation = (challengeId, code) => {
		return manageChallengeServices
			.acceptManageChallengeInvitation(challengeId, code)
			.then((data) => {
				if (!data) throw new Error("Error ocured while accepting manage challenge invitation")
				console.log("Invitation accept response data", data)
				this.setState({
					isAccepted: true,
					...data,
					challengeTitle: data.challengeTitle,
					urlSlug: data.urlSlug
				})
				return true
			})
			.catch((err) => {
				console.log("Error accepting manage challenge invitation =", err.message)
				return false
			})
	}

	checkPermission = () => {
		let permissionsObject = [
			{ permissionName: Constants.Functionalities.CanGetManageChallengeInvitation, objectId: null }
		]
		authentication
			.hasMultiplePermissions(permissionsObject)
			.then((res) => {
				let permissionOkay = true
				if (res && res.length > 0) {
					res.map((object) => {
						permissionOkay = permissionOkay && object.permissionStatus
					})
				} else {
					permissionOkay = false
				}
				if (!permissionOkay) {
					this.setState({ showErrorComponent: true })
				}
			})
			.catch((err) => {
				console.log("Err = ", err.message)
				this.setState({ showErrorComponent: true })
			})
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		this.acceptManageChallengeInvitation(this.state.challengeId, this.state.code)
			.then((data) => {
				console.log("all data fetched, status =", data)
				if (!data) throw new Error("Error occured while accpeting manage people invitation")
				this.setState({
					isLoading: false
				})
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.setState({ isLoading: false, errorInAccepting: true })
				this.props.resetLoadingSpinner()
				console.log("Error fetching all data, err =", err.message)
			})
	}

	componentDidMount() {
		let loggedInUserData = cookieHelper.get(constants.Settings.LoggedInCookieName)
		if (!loggedInUserData) {
			console.log("User is not logged in, So opening loginModal")
			this.setState({ isLoading: false })
			this.props.setAfterLoginRedirectUrl(window.location.href)
			this.props.openLoginModal()
			return
		}
		let qParams = querystring.parse(this.props.location.search.slice(1))
		this.setState({ challengeId: qParams.challenge, code: qParams.code }, () => {
			this.fetchAllData()
			// this.checkPermission();
		})
	}

	render() {
		const { classes } = this.props
		let title = this.state.challengeTitle
		if (this.state.showErrorComponent) {
			return (
				<ErrorPage
					errorCode={403}
					message={"Access Denied! :("}
					description={"You are not logged in, please login to see this page"}
				/>
			)
		}
		if (this.state.isLoading) {
			console.log("isLoading")
			return <div> </div>
		}

		let linkToChallenge = "#"
		if (this.state.urlSlug) linkToChallenge = "/dashboard/challenge-details/" + this.state.urlSlug

		return (
			<div>
				<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
				<br />
				<br />
				<GridContainer direction="row" justify="center" alignItems="flex-start">
					<GridItem xs={12} sm={12} md={8} lg={6}>
						<Card raised>
							<CardHeader color="rose" icon>
								<CardIcon color="rose">
									<Assignment />
								</CardIcon>
								<div style={{ fontSize: "2em" }} className={classes.cardIconTitle}>
									Invitation to manage{" "}
									<strong>
										<NavLink to={linkToChallenge}>
											<span style={{ textDecoration: "underline", color: "blue" }}>{title}</span>
										</NavLink>
									</strong>{" "}
									challenge
								</div>
							</CardHeader>
							<CardBody>
								<GridContainer direction="row" justify="space-around" alignItems="center">
									<GridItem>{this.renderInvitationMessages()}</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		)
	}

	renderInvitationMessages = () => {
		const { classes } = this.props
		let message = null

		if (this.props.loggedInUserData) {
			if (this.state.isLoading) {
				message = <React.Fragment> Accepting invitation ... </React.Fragment>
			} else if (this.state.errorInAccepting) {
				message = (
					<React.Fragment>
						Error accepting manage challenge invitation. Invitation link is not valid. Either the
						link is expired or the invitation link didn't match the email address associated with
						your account.
					</React.Fragment>
				)
			} else if (this.state.isAccepted) {
				message = (
					<React.Fragment>
						Thank you <strong> {this.state.name} </strong> <br /> You have accepted the invitation!
						<br /> Now you are one of the {this.state.role}(s) for
						<strong> {this.state.challengeTitle} </strong> challenge
					</React.Fragment>
				)
			}
		} else {
			message = <React.Fragment> Please login to continue </React.Fragment>
		}
		return (
			<div>
				<h4 className={classes.cardIconTitle}> {message} </h4>
			</div>
		)
	}
}

AcceptManageChallengeInvitation.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE }),
		setAfterLoginRedirectUrl: (url) =>
			dispatch({ type: actionTypes.AFTER_LOGIN_REDIRECT_URL_SET, payload: url })
	}
}

let extraStyles = (theme) => ({})

let finalStyle = combineStyles(extraStyles, extendedTablesStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyle)(AcceptManageChallengeInvitation))
