import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import GridContainer from "../../../components/Grid/GridContainer.jsx"
import GridItem from "../../../components/Grid/GridItem.jsx"

const styles = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  group: {
    margin: theme.spacing(1, 0),
  },
});

class RadioButtonsGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'video'
    };
    this.handleChange = this.handleChange.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  updateState() {
    this.props.handleSelectType(this.state);
  }

  handleChange = event => {
    this.setState({ value: event.target.value }, this.updateState);
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} >

        <GridContainer justify="center">
          <GridItem>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend">Select type of challenge</FormLabel>
              <RadioGroup
                aria-label="Ideation"
                name="Ideation1"
                className={classes.group}
                value={this.state.value}
                onChange={this.handleChange}
                row={true}
              >
                <FormControlLabel value="video" control={<Radio />} label="Video" />
                <FormControlLabel value="subjective" control={<Radio />} label="Subjective" />
                <FormControlLabel value="data" control={<Radio />} label="Data" />
                <FormControlLabel value="algorithm" control={<Radio />} label="Algorithm" />
              </RadioGroup>
            </FormControl>
          </GridItem>
        </GridContainer>

      </div>
    );
  }
}

RadioButtonsGroup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadioButtonsGroup);
