import React from 'react';
import PropTypes from "prop-types";

import { InputAdornment } from '@material-ui/core';
import ToolTip from "@material-ui/core/Tooltip";
import {
  Face,
  LockOutlined as LockIcon,
  Info as InfoIcon
} from '@material-ui/icons';

import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';

import { isStrongPassword } from '../utilities';
import styles from './styles.module.scss';

const ResetPassword = props => {
  const {
    email,
    password,
    onPasswordChange,
    onResetPassword,
  } = props;

  const passwordChange = (event) => {
    onPasswordChange(event.target.value);
  };

  const renderInputs = () => {
    return (
      <div className={styles.resetPasswordContainer}>
        <CustomInput
          id="email"
          labelText="Email"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Face />
              </InputAdornment>
            ),
            type: "text",
            name: "emailOrUsername",
            value: email,
          }}
        />
        <CustomInput
          id="password"
          labelText="Password"
          formControlProps={{
            fullWidth: true
          }}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <ToolTip
                  title={
                    <span style={{ color: "yellow", fontSize: "1.5em" }}>
                      Password must contains uppercase, lowercase letters, digits, special
                      characters; Length atleast 8 character.
                    </span>
                  }
                  placement="left-start"
                >
                  <InfoIcon />
                </ToolTip>
                <LockIcon />
              </InputAdornment>
            ),
            error: password && !isStrongPassword(password),
            type: "password",
            name: "password",
            value: password,
            onChange: passwordChange,
          }}
        />
      </div>
    );
  }

  const renderButtons = () => {
    return (
      <div className={styles.buttonGroup}>
        <Button
          square
          color="gmgGreen"
          disabled={!isStrongPassword(password)}
          onClick={onResetPassword}
        >
          Reset Password
        </Button>
      </div>
    );
  };

  return (
    <div className={`${styles.resetPassword} center`}>
      { renderInputs() }
      { renderButtons() }
    </div>
  );
};

ResetPassword.propTypes = {
  email: PropTypes.string,
  password: PropTypes.string.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  onResetPassword: PropTypes.func.isRequired,
};

ResetPassword.defaultProps = {};

export default ResetPassword;
