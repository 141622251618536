import { gql } from '@apollo/client'

let uploadFileServices = {}

const uploadFileMutation = gql`
	mutation uploadFile($file: Upload!, $uploadType: String!) {
		uploadFile(file: $file, uploadType: $uploadType) {
			status
			message
			totalCount
			data {
				id
				filename
				path
				mimetype
				encoding
			}
		}
	}
`

const uploadFileHandler = (mutation, file, uploadType) => {
	return new Promise((resolve, reject) => {
		if (file) {
			console.log("File valid. lets call graphQL api!", file)
			mutation({
				mutation: uploadFileMutation,
				variables: { ...file, uploadType: uploadType },
				fetchPolicy: "no-cache"
			})
				.then(res => {
					console.log("File upload resposne =", res.data.uploadFile)
					if (res.data.uploadFile.status == true) {
						console.log("File upload data", res.data.uploadFile.data)
						resolve(res.data.uploadFile.data)
					} else {
						throw new Error(res.data.uploadFile.message)
					}
				})
				.catch(err => {
					console.log("Error uploading file, err =", err.message)
					reject(err)
				})
		} else {
			console.log("No File is selected")
			reject(new Error("No File is selected to upload"))
		}
	})
}

uploadFileServices = {
	uploadFileMutation: uploadFileMutation,
	uploadFileHandler: uploadFileHandler
}

export { uploadFileMutation, uploadFileHandler }
export default uploadFileServices //{ uploadFileMutation, uploadFileHandler }
