export const SOLVER_ACCESS_BUTTON_TITLE = {
  ON: {
    label: "On",
    value: "on",
  },
  OFF: {
    label: "Off",
    value: "off",
  },
};
