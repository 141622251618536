import React, { useState } from 'react';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import PropTypes from "prop-types";

import {
  MoreVert as VerticalDotIcon
} from '@material-ui/icons'; 
import { Button, Popover, Divider } from '@material-ui/core';

import Table from 'components/Table/Table';
import DropDownMenu from 'components/general/DropDownMenu';
import ResetPasswordByAdmin from 'components/general/auth/ResetPasswordByAdmin';
import AdminEditUser from 'components/Admin/Users/EditUser';
import CustomModal from 'components/general/CustomModal';
import ShowUserRole from 'components/Admin/Users/ShowUserRole';

import { adminVerifyUserEmail } from 'dataServices/AdminPageServices/users';
import actionTypes from "reduxjs/actionTypes";

import { getUsersTableColumns } from "../utilities";

import styles from "../styles.module.scss";

const UserTable = (props) => {
  const {
    users,
    totalUsers,
    reFetchUser,
    setLoadingSpinner,
    resetLoadingSpinner,
    showAlert,
  } = props;
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState();

  const [isRolesPopoverOpen, setIsRolesPopoverOpen] = useState(false);
  const [anchorElForRolesPopup, setAnchorElForRolesPopup] = useState(null);
  const [rolesDataArray, setRolesDataArray] = useState(null);

  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);

  const getDropdownItems = () => {
    return [
      {
        title: "Edit",
        onClick: () => setIsEditUserModalOpen(true),
      },
      {
        title: "Verify",
        onClick: async () => await onVerifyUserEmail()
      },
      {
        title: "Reset Password",
        onClick: () => setIsResetPasswordModalOpen(true)
      }
    ];
  };

  const onDropdownOpen = (e, user) => {
    setAnchorEl(e.currentTarget)
    setDropdownOpen(true);
    setSelectedUser(user);
  };

  const onCloseDropdown = () => {
    setSelectedUser(null);
    setDropdownOpen(false);
  };

  const onCloseResetPasswordModal = () => {
    onCloseDropdown();
    setIsResetPasswordModalOpen(false);
  }

  const onCloseEditUserModal = async (shouldRefresh=false) => {
    onCloseDropdown();
    setIsEditUserModalOpen(false);

    if(shouldRefresh) {
      await reFetchUser();
    }
  };

  const onVerifyUserEmail = async () => {
    onCloseDropdown();

    try {
      setLoadingSpinner();

      if (selectedUser) {
        const response = await adminVerifyUserEmail(selectedUser.userId);

        if (response.status == 200) {
          showAlert(
            'success',
            'Email verified',
            `Email for ${selectedUser.name} is verified`,
          );
        } else {
          showAlert('error', 'Opps!', response.message);
        }
      }

      resetLoadingSpinner();
    } catch (err) {
      showAlert('error', 'Opps!', err.message);
    }
  };

  const renderRolesColumnData = user => {
    const rolesArray = [];

    get (user, "roles", []).forEach(role => {
      if (!rolesArray.includes(role.RoleId)) {
        rolesArray.push(role.RoleId);
      }
    });

    const rolesText = rolesArray.length <= 1
                        ? rolesArray.length == 0 ? `No role` : `${rolesArray.length} role`
                        : `${rolesArray.length} roles`;

    return (
      <Button
        onClick={e => {
          setAnchorElForRolesPopup(e.currentTarget);
          setRolesDataArray(rolesArray);
          setIsRolesPopoverOpen(true);
          setSelectedUser(user);
        }}
        style={{ backgroundColor: "#d7d3d3" }}
      >
        {rolesText}
      </Button>
    );
  };

  const renderActionIcon = user => {
    return (
      <div className={`center`}>
        <VerticalDotIcon className={styles.actionIcon} onClick={e => onDropdownOpen(e, user)}/>
      </div>
    );
  };

  const getTableData = () => {
    const tableData = (users || []).map(user => {
      return [
        user.name,
        user.email,
        renderRolesColumnData(user),
        renderActionIcon(user),
      ]
    });

    return tableData;
  };

  const renderRolesPopup = () => {
    return (
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={isRolesPopoverOpen}
        anchorEl={anchorElForRolesPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchorElForRolesPopup(null);
          setRolesDataArray(null);
          setIsRolesPopoverOpen(false);
        }}
        disableRestoreFocus
      >
        <Typography className={styles.rolesPopup}>
          {
            (rolesDataArray || []).map((role, index) => {
              return (
                <>
                <div style={{ margin: "5px 0px" }}>
                  <p>{role}</p>
                </div>
                {(index < (rolesDataArray || []).length - 1) && (
                  <Divider className={styles.rolesPopupItemDivider}/>
                )}
                </>
              );
            })
          }
        </Typography>
      </Popover>
    );
  };

  const renderDropdownMenu = () => {
    return (
      <DropDownMenu
        anchorEl={anchorEl}
        open={dropdownOpen}
        onClose={() => setDropdownOpen(false)}
        menuItems={getDropdownItems()}
      />
    );
  };

  const renderResetPasswordModal = () => {
    return (
      <ResetPasswordByAdmin
        open={isResetPasswordModalOpen}
        userId={selectedUser.userId}
        email={selectedUser.email}
        closeModal={onCloseResetPasswordModal}
      />
    );
  };

  const renderEditUserModal = () => {
    return (
      <AdminEditUser
        user={selectedUser}
        isModalOpen={isEditUserModalOpen}
        closeModal={onCloseEditUserModal}
      />
    );
  };

  const renderShowRolesModal = () => {
    return (
      <CustomModal
        title={`${selectedUser.name} : Roles`}
        visible={isRolesPopoverOpen}
        onClose={() => {
          setAnchorElForRolesPopup(null);
          setRolesDataArray(null);
          setIsRolesPopoverOpen(false);
          setSelectedUser(null);
        }}
      >
        <ShowUserRole roles={rolesDataArray} userId={selectedUser.userId}/>
    </CustomModal>
    );
  };

  return (
    <>
      {renderDropdownMenu()}
      {selectedUser && renderResetPasswordModal()}
      {selectedUser && renderEditUserModal()}
      {selectedUser && rolesDataArray && renderShowRolesModal()}
      <Table tableHead={getUsersTableColumns()} tableData={getTableData()}/>
    </>
  );
}

UserTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.object),
  totalUsers: PropTypes.number.isRequired,
  reFetchUser: PropTypes.func,
  setLoadingSpinner: PropTypes.func.isRequired,
  resetLoadingSpinner: PropTypes.func.isRequired,
};

UserTable.defaultProps = {
  reFetchUser: noop,
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
    setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
    showAlert: (alertType, title, description) =>
      dispatch({
        type: actionTypes.SHOW_ALERT,
        payload: {
          alertType,
          title,
          description
        }
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTable);
