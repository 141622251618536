import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import CustomInput from "components/CustomInput/CustomInputForChallengeBuilder.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Tooltip from "@material-ui/core/Tooltip"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import SweetAlert from "react-bootstrap-sweetalert"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Datetime, { moment } from "react-datetime"
import InputLabel from "@material-ui/core/InputLabel"
import LibraryBooks from "@material-ui/icons/LibraryBooks"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import MaterialTimeline from "components/Timeline/Timeline.jsx"
import challengeServices from "dataServices/challengeServices"
import CardFooter from "components/Card/CardFooter"
import CardTravel from "@material-ui/icons/CardTravel"

import Extension from "@material-ui/icons/Extension"

import Fingerprint from "@material-ui/icons/Fingerprint"
import FlightLand from "@material-ui/icons/FlightLand"
import Build from "@material-ui/icons/Build"
import Edit from "@material-ui/icons/Edit"
import { Typography } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import { TextField } from "@material-ui/core"

import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import helper from "../../../helper"

import { EditorState, ContentState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import ReactHtmlParser from 'html-react-parser'
import htmlToDraft from "html-to-draftjs"
import FontOverridePatch from "utils/FontOverridePatch"

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	container: {
		margin: "0px 0px",
		padding: "40px 20px",
		backgroundColor: theme.palette.background.page
	},
	bottomNavigationBarStyle: {
		backgroundColor: "#C3FDFF",
		color: "#101316"
	},
	phaseTimeLineCustomStyle: {
		maxHeight: "600px",
		overflow: "scroll"
	},
	phaseListPageBackgroundStyle: {
		backgroundColor: "#302f2f"
	},
	buttonSuccessColor: {
		backgroundColor: "#1eb980",
		"&:hover,&:focus": {
			backgroundColor: "#1eb980",
			boxShadow: "0px 6px 14px rgba(30, 185, 128, .3)"
		}
	},
	whiteTextColorStyle: {
		color: theme.palette.text.page,
		backgroundColor: theme.palette.background.page
	},
	phaseIconSelected: {
		color: theme.palette.text.page
	},
	...sweetAlertStyle,
	inputRoot: {
		backgroundColor: "transparent"
	},
	inputLabel: {
		color: theme.palette.text.page
	},
	textField: {
		color: theme.palette.text.page
	},
	toolbarClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	wrapperClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	editorClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	}
})

class Phase extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: 0,
			startDate: null,
			endDate: null,
			title: null,
			description: null,
			phaseIcon: null,
			updatedStartDate: null,
			updatedEndDate: null,
			updatedTitle: null,
			updatedDescription: null,
			updatedPhaseIcon: null,
			phases: [],
			alert: null,
			canEditPhase: false,
			addPhaseMode: false,
			editPhaseMode: false,
			phaseStories: [],
			editphaseByIdMode: "",
			updatedDescriptionByPhaseId: "",
			dataFetchDone: false,
			toBeUpdatedPhase: null,
			phaseIconsAnchorEl: null,
			phaseDescriptionEditorState: EditorState.createEmpty()
		}
	}

	handleChoosePhaseIconClick = (event) => {
		this.setState({
			phaseIconsAnchorEl: event.currentTarget
		})
	}

	handleChoosePhaseIconClose = () => {
		this.setState({
			phaseIconsAnchorEl: null
		})
	}

	isPhaseDataValid = () => {
		let descriptionHtml = draftToHtml(
			convertToRaw(this.state.phaseDescriptionEditorState.getCurrentContent())
		)
		return !(
			!this.state.startDate ||
			!this.state.endDate ||
			!this.state.title ||
			!this.state.phaseIcon ||
			!helper.isRTEHtmlStringValid(descriptionHtml)
		)
	}

	isUpdatedPhaseDataValid = () => {
		let descriptionHtml = draftToHtml(
			convertToRaw(this.state.phaseDescriptionEditorState.getCurrentContent())
		)
		return !(
			!this.state.updatedStartDate ||
			!this.state.updatedEndDate ||
			!this.state.updatedTitle ||
			!helper.isRTEHtmlStringValid(descriptionHtml) ||
			!this.state.updatedPhaseIcon
		)
	}

	addPhase() {
		let descriptionHtml = draftToHtml(
			convertToRaw(this.state.phaseDescriptionEditorState.getCurrentContent())
		)
		if (
			!this.state.startDate ||
			!this.state.endDate ||
			!helper.isRTEHtmlStringValid(descriptionHtml) ||
			!this.state.title ||
			!this.state.phaseIcon
		) {
			this.props.showAlert("warning", "Ooops!", "You can not add an empty value")
		} else {
			this.props.setLoadingSpinner()
			var variables = {
				challengeId: this.props.challengeId,
				phases: [
					{
						startDate: this.state.startDate.format("MM-DD-YYYY, h:mm a"),
						endDate: this.state.endDate.format("MM-DD-YYYY, h:mm a"),
						title: this.state.title,
						description: descriptionHtml,
						phaseIcon: this.state.phaseIcon
					}
				]
			}

			challengeServices
				.updateChallenge(variables)
				.then((status) => {
					this.props.resetLoadingSpinner()
					console.log("add phase res = ", status)
					if (status) {
						this.setState(
							{
								startDate: "",
								endDate: "",
								title: "",
								description: "",
								phaseDescriptionEditorState: EditorState.createEmpty(),
								addPhaseMode: false,
								phaseIcon: ""
							},
							() => {
								this.fetchPhaseList(this.props.challengeId)
							}
						)
					}
				})
				.catch((err) => {
					console.log("Err = ", err.message)
					this.props.resetLoadingSpinner()
					this.props.showAlert("warning", "Error", err.message)
				})
		}
	}

	updatePhase = () => {
		let toBeUpdatedPhase = this.state.toBeUpdatedPhase
		let descriptionHtml = draftToHtml(
			convertToRaw(this.state.phaseDescriptionEditorState.getCurrentContent())
		)

		if (!this.isUpdatedPhaseDataValid()) {
			this.props.showAlert("warning", "Oops!", "Phase data is not valid")
			return
		}

		let variables = {
			challengeId: this.props.challengeId,
			phaseId: toBeUpdatedPhase.phaseId,
			startDate: this.state.updatedStartDate.format("MM-DD-YYYY, h:mm a"),
			endDate: this.state.updatedEndDate.format("MM-DD-YYYY, h:mm a"),
			title: this.state.updatedTitle,
			description: descriptionHtml,
			phaseIcon: this.state.updatedPhaseIcon
		}

		this.props.setLoadingSpinner()
		challengeServices
			.updatePhaseById(variables)
			.then((res) => {
				this.props.resetLoadingSpinner()
				this.fetchPhaseList(this.props.challengeId)
				this.setState(
					{
						updatedStartDate: "",
						updatedEndDate: "",
						updatedTitle: "",
						updatedDescription: "",
						updatedPhaseIcon: "",
						phaseDescriptionEditorState: EditorState.createEmpty(),
						editPhaseMode: false
					},
					() => this.props.scrollUpdate()
				)
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("warning", "Ooops!", err.message)
			})
	}

	deletePhaseById = (phaseId) => {
		this.props.setLoadingSpinner()
		return challengeServices
			.deletePhaseById(this.props.challengeId, phaseId)
			.then((data) => {
				this.fetchPhaseList(this.props.challengeId)
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.scrollUpdate()
				this.props.showAlert("warning", "Ooops!", err.message)
			})
	}

	fetchPhaseList(challengeId) {
		this.props.setLoadingSpinner()
		return challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				this.props.resetLoadingSpinner()
				if (data.challengeDetails.phases) {
					this.setState({
						phases: data.challengeDetails.phases
					})
					const phaseList = data.challengeDetails.phases
					const phaseStories = []
					const colors = ["success", "info", "warning"]
					if (phaseList) {
						let phaseStoriesTempObject = {
							inverted: true,
							badgeColor: "success",
							badgeIcon: Extension,
							titleColor: "success",
							title: null,
							body: null
						}
						phaseList.map((item, key) => {
							let tmp = {
								...phaseStoriesTempObject,
								badgeColor: colors[key % 3],
								titleColor: colors[key % 3],
								title: this.renderPhaseTitle(item),
								body: this.renderPhaseBody(item),
								badgeIcon: helper.convertPhaseIcon(item.phaseIcon)
							}
							phaseStories.push(tmp)
						})

						this.setState({
							phaseStories: phaseStories
						})
					}
				}
				this.setState(
					{
						dataFetchDone: true
					},
					() => this.props.scrollUpdate()
				)
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("warning", "Error", err.message)
				return null
			})
	}

	setUpdatedStartDate = (selDate) => {
		if (selDate) {
			if (selDate.isSameOrAfter(this.state.updatedEndDate)) {
				this.setState({
					updatedStartDate: selDate,
					updatedEndDate: ""
				})
			} else this.setState({ updatedStartDate: selDate })
		}
	}

	setUpdatedEndDate = (selDate) => {
		if (selDate) {
			this.setState({ updatedEndDate: selDate })
		}
	}

	setStartDate = (selDate) => {
		if (selDate) {
			this.setState({ startDate: selDate })
		}
	}

	setEndDate = (selDate) => {
		if (selDate) {
			this.setState({ endDate: selDate })
		}
	}

	isStartDateValid = (currentDate, selectedDate) => {
		//var yesterday = moment().subtract(1, 'day');
		if (this.state.endDate) return currentDate.isBefore(this.state.endDate)
		return true
		// if (this.state.endDate)
		//     return currentDate.isAfter(yesterday) && currentDate.isBefore(this.state.endDate);
		// else
		//     return currentDate.isAfter(yesterday);
	}

	isEndDateValid = (currentDate, selectedDate) => {
		//var today = moment();
		if (this.state.startDate) return currentDate.isAfter(this.state.startDate)
		// else
		//     return currentDate.isAfter(today);
		return true
	}

	isUpdatedStartDateValid = (currentDate, selectedDate) => {
		// var yesterday = moment().subtract(1, 'day');
		// return currentDate.isAfter(yesterday);
		if (this.state.updatedEndDate) return currentDate.isBefore(this.state.updatedEndDate)
		return true
	}

	isUpdatedEndDateValid = (currentDate, selectedDate) => {
		// var today = moment().subtract(1, 'day');
		// if (this.state.updatedStartDate)
		//     return currentDate.isAfter(this.state.updatedStartDate);
		// else
		//     return currentDate.isAfter(today);
		if (this.state.updatedStartDate) return currentDate.isAfter(this.state.updatedStartDate)
		// else
		//     return currentDate.isAfter(today);
		return true
	}

	handleSimple = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleChange = (event, value) => {
		this.setState({ value })
		console.log(this.state)
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			phaseDescriptionEditorState: editorState
		})
	}

	componentWillReceiveProps(newProps) {
		this.setState({ canEditPhase: newProps.canEditChallenge })
	}

	componentDidMount() {
		if (this.props.canEditChallenge) {
			this.setState({
				canEditPhase: true
			})
		}
		console.log("Component Did Mount of PhaseStepTab: .. ", this.props.challengeId)
		this.fetchPhaseList(this.props.challengeId)
	}

	renderRTE = () => {
		const { classes } = this.props
		return (
			<div style={{ border: "1px solid grey" }}>
				<FontOverridePatch isDraftEditor>
					<Editor
						editorState={this.state.phaseDescriptionEditorState}
						toolbarClassName={classes.toolbarClassName}
						wrapperClassName={classes.wrapperClassName}
						editorClassName={classes.cccEditorStyle}
						className={classes.editRulesStyle}
						toolbar={{
							options: [
								"inline",
								"blockType",
								"fontSize",
								"fontFamily",
								"list",
								"textAlign",
								"colorPicker",
								"link",
								"embedded",
								"remove",
								"history"
							]
						}}
						onEditorStateChange={this.onEditorStateChange}
					/>
				</FontOverridePatch>
			</div>
		)
	}

	renderPhaseBody = (item) => {
		const { classes } = this.props
		console.log("this.state.editphaseByIdMode: ", this.state.editphaseByIdMode)
		console.log("item.phaseId: ", item.phaseId)
		return (
			<GridContainer direction="row" justify="space-between" alignItems="flex-start">
				<GridItem xs={12} sm={7} md={9} lg={10}>
					<Typography variant="button" component="h6" color="textSecondary">
						{" "}
						<div style={{ textTransform: "initial", textAlign: "justify" }}>
							<FontOverridePatch>{ReactHtmlParser(item.description || "")}</FontOverridePatch>
						</div>{" "}
					</Typography>
				</GridItem>
				<GridItem xs={12} sm={5} md={3} lg={2}>
					{this.state.canEditPhase ? (
						<GridContainer direction="row" justify="flex-end">
							<GridItem>
								<Button
									color="twitter"
									round
									onClick={() => {
										const blocksFromHTML = htmlToDraft(item.description)
										const contentState = ContentState.createFromBlockArray(
											blocksFromHTML.contentBlocks,
											blocksFromHTML.entityMap
										)
										var updatedEditorState = EditorState.createWithContent(contentState)
										this.setState(
											{
												editPhaseMode: true,
												toBeUpdatedPhase: item,
												updatedTitle: item.title,
												updatedStartDate: moment(item.startDate), // need conversion from plain string to moment object
												updatedEndDate: moment(item.endDate), // need conversion from plain string to moment object,
												updatedDescription: item.description,
												updatedPhaseIcon: item.phaseIcon,
												phaseDescriptionEditorState: updatedEditorState
											},
											() => {
												this.props.scrollUpdate()
											}
										)
									}}
									justIcon
								>
									<Tooltip title="Edit Phase" placement="top">
										<Edit />
									</Tooltip>
								</Button>
							</GridItem>
							<GridItem>
								<Button
									justIcon
									round
									color="rose"
									onClick={() => {
										this.deletePhaseById(item.phaseId)
										this.props.scrollUpdate()
									}}
								>
									<Tooltip title="Delete Phase" placement="top">
										<DeleteIcon />
									</Tooltip>
								</Button>
							</GridItem>
						</GridContainer>
					) : null}
				</GridItem>
			</GridContainer>
		)
	}

	renderPhaseTitle = (item) => {
		const { classes } = this.props
		return (
			<div>
				<Typography variant="subtitle1" component="h1" color="textSecondary">
					{" "}
					<div style={{ textTransform: "initial" }}>{item.title}</div>
				</Typography>
				<Typography variant="subtitle2" component="h6" color="textSecondary">
					{" "}
					<div style={{ textTransform: "initial" }}>Start Date: {item.startDate}</div>
				</Typography>
				<Typography variant="subtitle2" component="h6" color="textSecondary">
					{" "}
					<div style={{ textTransform: "initial" }}>End Date: {item.endDate}</div>{" "}
				</Typography>
			</div>
		)
	}

	selectPhaseIcon = (event, selectPhaseType) => {
		if (selectPhaseType == "addPhase") {
			this.setState({
				phaseIcon: event.currentTarget.id,
				phaseIconsAnchorEl: null
			})
		} else if (selectPhaseType == "updatePhase") {
			this.setState({
				updatedPhaseIcon: event.currentTarget.id,
				phaseIconsAnchorEl: null
			})
		}
	}

	renderPhaseIconList = (selectPhaseType) => {
		return helper.phaseIcons.map((icon, index) => {
			return (
				<MenuItem
					id={icon}
					key={index}
					onClick={(event) => {
						this.selectPhaseIcon(event, selectPhaseType)
					}}
				>
					{helper.renderPhaseIcon(icon)}
				</MenuItem>
			)
		})
	}

	renderAddPhaseView = () => {
		const { classes } = this.props
		return (
			<div>
				<InputLabel className={classes.whiteTextColorStyle}>Select Start Date: </InputLabel>
				<FormControl FormHelperText="Select Start Date" fullWidth>
					<Datetime
						isValidDate={this.isStartDateValid}
						value={this.state.startDate}
						closeOnSelect={true}
						timeFormat={true}
						onChange={this.setStartDate}
						inputProps={{
							onKeyDown: (event) => {
								return event.preventDefault()
							}
						}}
					/>
				</FormControl>

				<InputLabel style={{ marginTop: "20px" }} className={classes.whiteTextColorStyle}>
					Select End Date:{" "}
				</InputLabel>
				<FormControl fullWidth>
					<Datetime
						isValidDate={this.isEndDateValid}
						value={this.state.endDate}
						closeOnSelect={true}
						timeFormat={true}
						onChange={this.setEndDate}
						inputProps={{
							onKeyDown: (event) => {
								return event.preventDefault()
							}
						}}
					/>
				</FormControl>

				<TextField
					label="Title"
					className={classes.inputRoot}
					fullWidth
					InputLabelProps={{
						className: classes.inputLabel
					}}
					name={"title"}
					value={this.state.title}
					onChange={this.handleSimple}
					inputProps={{
						className: classes.textField,
						overflow: "auto"
					}}
				/>
				<br />
				<br />
				<GridContainer direction="row" justify="flex-start" alignItems="center">
					<GridItem>
						<Button
							size="sm"
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={(event) => {
								this.handleChoosePhaseIconClick(event)
							}}
							color="twitter"
						>
							Change Phase Icon
						</Button>
					</GridItem>
					<GridItem>
						<div className={classes.phaseIconSelected}>
							{helper.renderPhaseIcon(this.state.phaseIcon)}
						</div>
					</GridItem>
				</GridContainer>
				<br />
				<Menu
					id="simple-menu"
					anchorEl={this.state.phaseIconsAnchorEl}
					keepMounted
					open={Boolean(this.state.phaseIconsAnchorEl)}
					onClose={this.handleChoosePhaseIconClose}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					transformOrigin={{ vertical: "top", horizontal: "left" }}
					disableAutoFocusItem={true}
				>
					{this.renderPhaseIconList("addPhase")}
				</Menu>
				{this.renderRTE()}
			</div>
		)
	}

	renderEditPhaseView = () => {
		const { classes } = this.props
		return (
			<div>
				<InputLabel className={classes.whiteTextColorStyle}>Select Start Date: </InputLabel>
				<FormControl fullWidth>
					<Datetime
						isValidDate={this.isUpdatedStartDateValid}
						value={this.state.updatedStartDate}
						closeOnSelect={true}
						timeFormat={true}
						onChange={this.setUpdatedStartDate}
						inputProps={{
							onKeyDown: (event) => {
								return event.preventDefault()
							}
						}}
					/>
				</FormControl>
				<InputLabel style={{ marginTop: "20px" }} className={classes.whiteTextColorStyle}>
					Select End Date:{" "}
				</InputLabel>
				<FormControl fullWidth>
					<Datetime
						isValidDate={this.isUpdatedEndDateValid}
						value={this.state.updatedEndDate}
						closeOnSelect={true}
						timeFormat={true}
						onChange={this.setUpdatedEndDate}
						inputProps={{
							onKeyPress: (event) => {
								event.preventDefault()
							}
						}}
					/>
				</FormControl>

				<TextField
					label="Title"
					className={classes.inputRoot}
					fullWidth
					InputLabelProps={{
						className: classes.inputLabel
					}}
					name={"updatedTitle"}
					value={this.state.updatedTitle}
					onChange={this.handleSimple}
					inputProps={{
						className: classes.textField,
						overflow: "auto"
					}}
				/>
				<GridContainer direction="row" justify="flex-start" alignItems="center">
					<GridItem>
						<Button
							size="sm"
							aria-controls="simple-menu"
							aria-haspopup="true"
							onClick={(event) => {
								this.handleChoosePhaseIconClick(event)
							}}
							color="twitter"
						>
							Change Phase Icon
						</Button>
					</GridItem>
					<GridItem>
						<div>{helper.renderPhaseIcon(this.state.updatedPhaseIcon)}</div>
					</GridItem>
				</GridContainer>
				<Menu
					id="simple-menu"
					anchorEl={this.state.phaseIconsAnchorEl}
					keepMounted
					open={Boolean(this.state.phaseIconsAnchorEl)}
					onClose={this.handleChoosePhaseIconClose}
					anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
					transformOrigin={{ vertical: "top", horizontal: "left" }}
					disableAutoFocusItem={true}
				>
					{this.renderPhaseIconList("updatePhase")}
				</Menu>
				{this.renderRTE()}
			</div>
		)
	}

	render() {
		const { classes } = this.props
		let xs = 12,
			sm = 12,
			md = 12,
			lg = 12
		if (this.state.addPhaseMode || this.state.editPhaseMode) {
			xs = 12
			sm = 12
			md = 10
			lg = 8
		}

		// return (
		//     <div className={classes.container}>
		//         <div style={{ minHeight: "400px" }}>
		//             <div >
		//                 {
		//                     (this.state.phases && this.state.phases.length > 0) ?
		//                         <MaterialTimeline simple stories={this.state.phaseStories}></MaterialTimeline>
		//                         :
		//                         this.state.canEditPhase ?
		//                             <Typography className={classes.whiteTextColorStyle} align="center"><div style={{ textTransform: "initial" }}>Please update the timeline for this challenge</div></Typography>
		//                             :
		//                             <Typography className={classes.whiteTextColorStyle} align="center"><div style={{ textTransform: "initial" }}>No phase has been added to this challenge yet</div></Typography>
		//                 }
		//             </div>
		//         </div>
		//     </div>
		// )
		return (
			<div className={classes.container}>
				<GridContainer justify="center">
					<GridItem xs={xs} sm={sm} md={md} lg={lg}>
						{this.state.dataFetchDone ? (
							<div>
								{this.state.addPhaseMode ? (
									this.renderAddPhaseView()
								) : this.state.editPhaseMode ? (
									this.renderEditPhaseView()
								) : (
									<div>
										{this.state.phases.length > 0 ? (
											<MaterialTimeline simple stories={this.state.phaseStories}></MaterialTimeline>
										) : this.state.canEditPhase ? (
											<Typography className={classes.whiteTextColorStyle} align="center">
												<div style={{ textTransform: "initial" }}>
													Please update the timeline for this challenge
												</div>
											</Typography>
										) : (
											<Typography className={classes.whiteTextColorStyle} align="center">
												<div style={{ textTransform: "initial" }}>
													No phase has been added to this challenge yet
												</div>
											</Typography>
										)}
									</div>
								)}

								<GridContainer direction="row" justify="space-between" alignItems="center">
									<GridItem>
										{this.state.addPhaseMode || this.state.editPhaseMode ? (
											<Button
												color="warning"
												onClick={() =>
													this.setState(
														{
															addPhaseMode: false,
															editPhaseMode: false,
															startDate: "",
															endDate: "",
															description: "",
															phaseIcon: "",
															updatedStartDate: "",
															updatedEndDate: "",
															updatedDescription: ""
														},
														() => {
															this.props.scrollUpdate()
														}
													)
												}
											>
												Cancel
											</Button>
										) : null}
									</GridItem>
									<GridItem>
										{this.state.canEditPhase &&
										!this.state.addPhaseMode &&
										!this.state.editPhaseMode ? (
											<Button
												className={classes.buttonSuccessColor}
												onClick={() =>
													this.setState(
														{
															addPhaseMode: true,
															phaseDescriptionEditorState: EditorState.createEmpty()
														},
														() => this.props.scrollUpdate()
													)
												}
											>
												Add Phase
											</Button>
										) : this.state.canEditPhase &&
										  this.state.addPhaseMode &&
										  !this.state.editPhaseMode ? (
											<Button
												color="success"
												disabled={!this.isPhaseDataValid()}
												onClick={() => {
													this.addPhase()
													this.props.scrollUpdate()
												}}
											>
												Add
											</Button>
										) : this.state.canEditPhase &&
										  !this.state.addPhaseMode &&
										  this.state.editPhaseMode ? (
											<Button
												color="success"
												disabled={!this.isUpdatedPhaseDataValid()}
												onClick={() => {
													this.updatePhase()
													this.props.scrollUpdate()
												}}
											>
												Update
											</Button>
										) : null}
									</GridItem>
								</GridContainer>
							</div>
						) : null}
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

Phase.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		scrollUpdate: () => dispatch({ type: actionTypes.CONTENT_HEIGHT_UPDATED })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Phase))
