import React, { useRef, useEffect } from "react"
import useMutationObserver from "@rooks/use-mutation-observer"

function FontOverridePatch({ isDraftEditor, children }) {
	const myRef = useRef()

	const applyChangesInsideDomOnMutation = () => {
		if (myRef && myRef.current) {
			if (!isDraftEditor) {
				applyFontAsImportantInlineStyle(myRef.current, "")
			}
			if (isDraftEditor === true) {
				applyFontAsImportantInlineStyleForDraftJsEditor(myRef.current, "")
			}
		}
	}

	useMutationObserver(myRef, applyChangesInsideDomOnMutation)

	useEffect(applyChangesInsideDomOnMutation, [myRef, myRef.current, children])

	const applyFontAsImportantInlineStyle = (domNode, parentNodeFont) => {
		let children = domNode.childNodes
		let nodeFont = ""

		if (domNode.style && domNode.style.fontFamily) {
			nodeFont = domNode.style.fontFamily
		}

		for (var i = 0; i < children.length; i++) {
			applyFontAsImportantInlineStyle(children[i], nodeFont)
		}

		if (nodeFont) {
			domNode.style.setProperty("font-family", nodeFont, "important")
		} else if (parentNodeFont && domNode.style) {
			domNode.style.setProperty("font-family", parentNodeFont, "important")
		}
	}

	const applyFontAsImportantInlineStyleForDraftJsEditor = (domNode, parentNodeFont) => {
		let children = domNode.childNodes
		let nodeFont = ""

		if (domNode.style && domNode.style.fontFamily) {
			nodeFont = domNode.style.fontFamily
		}

		for (var i = 0; i < children.length; i++) {
			applyFontAsImportantInlineStyleForDraftJsEditor(children[i], nodeFont)
		}

		if (domNode.style) {
			if (nodeFont && domNode.dataset.text !== "true") {
				domNode.style.setProperty("font-family", nodeFont, "important")
			} else if (parentNodeFont && domNode.dataset.text === "true") {
				domNode.style.setProperty("font-family", parentNodeFont, "important")
			} else {
				domNode.style.setProperty("font-family", "")
			}
		}
	}

	return (
		<>
			<div ref={myRef}>{children && children}</div>
		</>
	)
}

export default FontOverridePatch
