import React from "react"
import ReactDom from "react-dom"
import PropTypes from "prop-types"
import SweetAlert from "react-bootstrap-sweetalert"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInputForChallengeBuilder"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import apolloFetcher from "../../../apolloFetcher"
import combineStyles from "utils/combineStyle"
import cookiesHelper from "../../../cookiesHelper"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"

import { Mutation } from "@apollo/client/react/components"
import { gql } from '@apollo/client'

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import { Typography } from "@material-ui/core"
import { Form, FormBuilder } from "react-formio"
import constants from "../../../constants"
//import "../../../../node_modules/bootstrap/dist/css/bootstrap.css" //it is covered in formio_styles.scss that is imported on App.js

const uploadFileMutation = gql`
	mutation uploadFile($file: Upload!, $uploadType: String!) {
		uploadFile(file: $file, uploadType: $uploadType) {
			status
			message
			totalCount
			data {
				id
				filename
				path
				mimetype
				encoding
			}
		}
	}
`

let extraStyle = (theme) => ({
	fileUploaderStyle: {
		backgroundColor: "#55ACEE",
		borderRadius: "20px"
	},
	marginTopAndBottom: {
		marginTop: "50px",
		marginBottom: "25px"
	},
	...sweetAlertStyle
})

class UploadStepTab extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			alert: null,
			submissionTitle: "",
			showSubmissionTitleInput: true,
			fileName: "",
			file: null,
			fileUploadDone: false,
			validFileExtensions: [
				"avi",
				"mpg",
				"mov",
				"mp4",
				"jpeg",
				"tif",
				"tiff",
				"gif",
				"bmp",
				"png",
				"svg",
				"3ds",
				"max",
				"pdf",
				"doc",
				"docx",
				"rtf",
				"txt",
				"csv",
				"dat",
				"ppt",
				"pptx",
				"xml",
				"xls",
				"xlsx",
				"mp3",
				"wav",
				"wma",
				"mid"
			]
		}
		this.hideAlert = this.hideAlert.bind(this)
		this.successAlert = this.successAlert.bind(this)
		this.fileUploadSuccessAlert = this.fileUploadSuccessAlert.bind(this)
		this.handleSimple = this.handleSimple.bind(this)
		this.handleFileChange = this.handleFileChange.bind(this)
		this.inputAlert = this.inputAlert.bind(this)
		this.emptyFileAlert = this.emptyFileAlert.bind(this)
		this.inputConfirmAlert = this.inputConfirmAlert.bind(this)
		this.inputConfirmAlertNext = this.inputConfirmAlertNext.bind(this)
		this.submissionTitleRef = React.createRef()
	}

	successAlert() {
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: "block", marginTop: "-100px", color: "black" }}
					title=""
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
				>
					Submissions only possible from teamlead and solo solver
				</SweetAlert>
			)
		})
	}

	emptyFileAlert() {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: "block", marginTop: "-100px", color: "black" }}
					title="File is empty"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
				>
					You can't upload empty file
				</SweetAlert>
			)
		})
	}

	fileUploadSuccessAlert() {
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: "block", marginTop: "-100px" }}
					title="File Upload successful"
					onConfirm={() => {
						this.hideAlert()
						if (!this.state.submissionTitle) document.getElementById("submissionTitle").focus()
					}}
					onCancel={() => this.hideAlert()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
				>
					You have successfully uploaded the file
				</SweetAlert>
			)
		})
	}

	noFilesSelectedAlert() {
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: "block", marginTop: "-100px", color: "black" }}
					title="File is not selected"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.info}
				>
					You have select a file before uploading
				</SweetAlert>
			)
		})
	}

	inputAlert() {
		this.setState({
			alert: (
				<SweetAlert
					input
					showCancel
					style={{ display: "block", marginTop: "-100px", color: "black" }}
					title="Enter your file extension"
					onConfirm={(e) => this.inputConfirmAlert(e)}
					onCancel={() => this.hideAlert()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.info}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
				/>
			)
		})
	}

	inputConfirmAlert(e) {
		this.setState({ alert: e })
		setTimeout(this.inputConfirmAlertNext, 200)
	}
	inputConfirmAlertNext() {
		const inputValue = this.state.alert
		var i,
			isValid = false
		for (i in this.state.validFileExtensions) {
			if (this.state.validFileExtensions[i] == inputValue) {
				isValid = true
			}
		}
		if (isValid) {
			this.setState({
				alert: (
					<SweetAlert
						success
						style={{ display: "block", marginTop: "-100px", color: "black" }}
						onConfirm={() => this.hideAlert()}
						onCancel={() => this.hideAlert()}
						confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.info}
						title={
							<p>
								<b>{inputValue}</b> is allowed
							</p>
						}
					/>
				)
			})
		} else {
			this.setState({
				alert: (
					<SweetAlert
						warning
						style={{ display: "block", marginTop: "-100px" }}
						onConfirm={() => this.hideAlert()}
						onCancel={() => this.hideAlert()}
						confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.info}
						title={
							<p>
								<b>{inputValue}</b> is not allowed
							</p>
						}
					/>
				)
			})
		}
	}

	hideAlert() {
		this.setState({
			alert: null
		})
	}

	handleUploadFile = (mutation) => {
		this.props.setLoadingSpinner()
		if (this.state.file) {
			console.log("File valid. lets call graphQL api!", this.state.file)
			mutation({
				mutation: uploadFileMutation,
				variables: { ...this.state.file, uploadType: "solution" },
				fetchPolicy: "no-cache",
				context: {
				  headers: {
					'x-apollo-operation-name': 'upload-file'
				  }
				},
			})
				.then((res) => {
					this.props.resetLoadingSpinner()
					if (res.data.uploadFile.status == true) {
						console.log("File upload response", res.data.uploadFile.data)
						//this.fileUploadSuccessAlert();
						this.setState({
							fileUploadDone: true
						})
						this.props.getValueFromUpload(res.data.uploadFile.data)
						if (this.state.fileUploadDone && this.state.submissionTitle) {
							this.props.getSubmissionTitle(this.state.submissionTitle)
							this.props.getValueFromSubmit("final")
						}
					} else {
						throw new Error(res.data.uploadFile.message)
					}
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					this.props.showAlert("warning", "Ooops!", err.message)
				})
		} else {
			this.noFilesSelectedAlert()
			this.props.resetLoadingSpinner()
		}
	}

	handleFileChange(e) {
		e.preventDefault()
		let reader = new FileReader()
		let file = e.target.files[0]
		if (!file) {
			this.setState({ file: null, fileName: "" })
			return
		}
		reader.onloadend = () => {
			this.setState({
				file: { file },
				fileName: file.name,
				isDirty: true
			})
			console.log("state: ", this.state)
		}
		reader.readAsDataURL(file)
	}

	handleSimple = (event) => {
		this.setState({ [event.target.name]: event.target.value }, () => {
			if (this.state.fileUploadDone && this.state.submissionTitle) {
				this.props.getSubmissionTitle(this.state.submissionTitle)
				this.props.getValueFromSubmit("final")
			}
		})
	}

	handleOnClickNext = () => {
		if (this.state.fileUploadDone && this.state.submissionTitle) {
			this.props.getSubmissionTitle(this.state.submissionTitle)
			this.props.getValueFromSubmit("final")
		} else {
			return
		}
	}

	onUnload = (event) => {
		// the method that will be used for both add and remove event
		if (this.state.isDirty) {
			event.returnValue = true
		}
	}

	componentDidMount() {
		console.log("UploadStepTab componentDidMount")
		window.addEventListener("beforeunload", this.onUnload)
		console.log("ref = ", this.submissionTitleRef)
		if (document.getElementById("submissionTitle"))
			document.getElementById("submissionTitle").focus()
	}

	componentWillUnmount() {
		window.removeEventListener("beforeunload", this.onUnload)
	}

	render() {
		console.log("ref = ", this.submissionTitleRef)
		const { classes } = this.props
		let showFormBuilder = false
		let formData = {}
		if (this.props.submissionForm && this.props.submissionForm.formData) {
			showFormBuilder = true
			formData = {
				...this.props.submissionForm.formData
			}
		}

		return (
			<div style={{ backgroundColor: "#302F2F", color: "#FFF", marginBottom: "-20px" }}>
				{this.state.alert}
				{showFormBuilder && (
					<div>
						{this.state.showSubmissionTitleInput ? (
							<div>
								<h5> Submission Title: </h5>
								<br />
								<CustomInput
									id={"submissionTitle"}
									ref={(el) => {
										this.submissionTitleRef = el
									}}
									labelText="Submission Title"
									formControlProps={{ fullWidth: true }}
									inputProps={{
										name: "submissionTitle",
										value: this.state.submissionTitle,
										onChange: this.handleSimple
									}}
								/>
								<div style={{ height: "20px" }}> </div>
								<Button
									color="success"
									disabled={!this.state.submissionTitle}
									onClick={(e) => {
										this.setState({ showSubmissionTitleInput: false })
									}}
								>
									Next
								</Button>
							</div>
						) : (
							<div>
								<h5> Submission Form: </h5>
								<br />
								<div
									style={{ backgroundColor: "#ffffff", color: "#333", padding: "10px" }}
									className="formio"
								>
									<Form
										form={{ ...formData }}
										options={{ display: "form" }}
										onSubmit={(submittedData) => {
											console.log("submitted data =", submittedData)
											this.props.setSubmissionData(submittedData)
											this.props.getSubmissionTitle(this.state.submissionTitle)
											this.props.getValueFromSubmit("final")
											this.props.onClickSubmitForm()
										}}
									/>
								</div>
							</div>
						)}
					</div>
				)}

				{!showFormBuilder && (
					<GridContainer direction="column" justify="space-between" alignItems="flex-start">
						<GridItem xs>
							<div>Valid File Extensions:</div>
							<div>
								avi, mpg, mov, mp4, jpeg, tif, tiff, gif, bmp, png, svg, 3ds, max, pdf, doc, docx,
								rtf, txt, csv, dat, ppt, pptx, xml, xls, xlsx, mp3, wav, wma
							</div>
						</GridItem>
						<GridItem xs>
							<br />
							<br />
						</GridItem>
						<GridItem xs>
							<div> Submission title: </div>
							<CustomInput
								id={"submissionTitle"}
								ref={(el) => {
									this.submissionTitleRef = el
								}}
								labelText="Submission title"
								formControlProps={{ fullWidth: true }}
								inputProps={{
									name: "submissionTitle",
									value: this.state.submissionTitle,
									onChange: this.handleSimple
								}}
							/>
						</GridItem>
						<GridItem xs className={classes.marginTopAndBottom}>
							<input
								type="file"
								onChange={(e) => this.handleFileChange(e)}
								className={classes.fileUploaderStyle}
							/>
						</GridItem>
						<GridItem xs>
							<Mutation mutation={uploadFileMutation} fetchPolicy="no-cache" context={{ headers: { 'x-apollo-operation-name': 'upload-file' } }}>
								{(mutation, { loading }) => (
									<Button
										color="twitter"
										onClick={() => {
											this.handleUploadFile(mutation)
										}}
									>
										Upload
									</Button>
								)}
							</Mutation>
						</GridItem>
					</GridContainer>
				)}
			</div>
		)
	}
}

UploadStepTab.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(extraStyle)(UploadStepTab))
