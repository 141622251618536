import { createTheme } from "@material-ui/core/styles"

let darkTheme = createTheme({
	themeName: "dark",
	palette: {
		background: {
			page: "#121212",
			cardBody: "#302F2F",
			cardHeader: "#1B5E20"
		},
		text: {
			page: "#FFFFFF",
			cardBody: "#FFFFFF",
			cardHeader: "#FFFFFF",
			heading: "#1B5E20"
		},
		action: {
			add: "#7E7575",
			update: "#302F2F",
			delete: "#F44336",
			success: "#00FF00",
			danger: "#FF0000"
		},
		accordion: {
			summary: {
				color: "#FFFFFF",
				backgroundColor: "#212121"
			},
			expanded: {
				color: "#FFFFFF",
				backgroundColor: "#313131"
			}
		},
		card: {
			background: {
				card: ["#121212", "#414141"],
				cardHeader: ["#1B5E20", "#124116"]
			},
			color: {
				card: "#FFFFFF",
				cardHeader: "#FFFFFF"
			}
		}
	},
	card: {
		borderRadius: "6px",
		disableBoxShadow: false,
		cardHeader: {
			borderRadius: "6px",
			disableBorderRadius: false,
			disableBoxShadow: false
		}
	}
})

let lightTheme = createTheme({
	themeName: "light",
	palette: {
		background: {
			page: "#E0E1E3",
			cardBody: "#018CB4",
			cardHeader: "#53D6FD"
		},
		text: {
			page: "#000000",
			cardBody: "#000000",
			cardHeader: "#000000",
			heading: "#000000"
		},
		action: {
			add: "#7E7575",
			update: "#302F2F",
			delete: "#F44336",
			success: "#00FF00",
			danger: "#FF0000"
		},
		accordion: {
			summary: {
				color: "#000000",
				backgroundColor: "#bdbdbd"
			},
			expanded: {
				color: "#000000",
				backgroundColor: "#CCCCCC"
			}
		},
		card: {
			background: {
				card: [/*"#ababab",*/ "#f5f5f5", "#f5f5f5"],
				cardHeader: [/*"#26c6da",*/ "#2196f3", "#2196f3"]
			},
			color: {
				card: "#000000",
				cardHeader: "#000000"
			}
		}
	},
	card: {
		borderRadius: "0px",
		disableBoxShadow: true,
		cardHeader: {
			borderRadius: "0px",
			disableBorderRadius: true,
			disableBoxShadow: true
		}
	}
})

export default mode => {
	if (mode == "dark") return darkTheme
	else if (mode == "light") return lightTheme
	else {
		console.log(`Theme mode '${mode}' is not configured!`)
		return createTheme({})
	}
}
