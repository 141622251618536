import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import PropTypes from "prop-types"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "../../components/CustomButtons/Button"
import Card from "../../components/Card/Card.jsx"
import CardHeader from "../../components/Card/CardHeader.jsx"
import CardBody from "../../components/Card/CardBody.jsx"
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx"

import Divider from "@material-ui/core/Divider"
import Checkbox from "@material-ui/core/Checkbox"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

const style = {
	infoText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center",
		color: "#FFFFFF"
	},
	cardHeaderText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center",
		color: "#FFFFFF"
	},
	submitButtonStyle: {
		backgroundColor: "#ff5722"
	},
	cardHeaderStyle: {
		backgroundColor: "#545252"
	},
	cardBodyStyle: {
		backgroundColor: "#605959",
		paddingTop: "50px"
	},
	cardFooterStyle: {
		backgroundColor: "#605959"
	},
	challengeBuilderFormBackgroundStyle: {
		backgroundColor: "#4C4A4A",
		// width: "75%",
		margin: "0 auto",
		padding: "10px 10px 10px 10px",
		marginBottom: "25px",
		boxShadow: "0px 5px 15px #000000"
	},
	textColor2: {
		color: "#FFF"
	},
	...customSelectStyle
}

class UsersTeamsListSelection extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			participants: [],
			checked: [],
			...this.prepareParticipantsForState(this.props.participants)
		}
	}

	prepareParticipantsForState = (participants) => {
		if (!participants) participants = []
		let checked = []
		participants = participants.map((p, index) => {
			if (p.checked) checked.push(index)
			return { ...p }
		})
		return {
			participants: participants,
			checked: checked
		}
	}

	handleOnClickAddSelected = () => {
		let participants = this.state.participants.filter((p, index) => {
			return this.state.checked.indexOf(index) != -1
		})
		console.log("Selected users = ", participants)
		if (this.props.onAddSelected) this.props.onAddSelected(participants)
	}

	componentDidMount() {
		this.setState({ ...this.prepareParticipantsForState(this.props.participants) })
	}

	componentDidUpdate(prevProps) {
		if (
			JSON.stringify(this.props.participants || []) != JSON.stringify(prevProps.participants || [])
		) {
			this.setState({ ...this.prepareParticipantsForState(this.props.participants) })
		}
	}

	render() {
		const { classes } = this.props

		return (
			<div>
				<Card raised={true}>
					<CardHeader className={classes.cardHeaderStyle}>
						<h3 className={classes.cardHeaderText}>Select Solvers From Previous Challenge</h3>
					</CardHeader>
					<CardBody className={classes.cardBodyStyle}>
						<div className={classes.challengeBuilderFormBackgroundStyle + " " + classes.textColor}>
							{this.state.participants.length == 0 && (
								<h4 style={{ margin: "20px 20px", color: "#FFF" }}>
									No participants are found on previous phase
								</h4>
							)}
							{this.state.participants.length > 0 && (
								<List dense className={classes.root + " " + classes.textColor}>
									<ListItem>
										<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
											<GridItem xs>
												<h6 className={classes.textColor2}>
													<strong>Solver / Team Name</strong>
												</h6>
											</GridItem>
											<GridItem xs>
												<h6 className={classes.textColor2}>
													<strong>Select</strong>
												</h6>
											</GridItem>
										</GridContainer>
									</ListItem>
									<Divider />
									{this.state.participants.map((value, index) => {
										const labelId = `checkbox-list-secondary-label-${index}`
										return (
											<ListItem key={index} button divider>
												<GridContainer direction="row" justify="flex-start" alignItems="center">
													<GridItem xs>
														{value.teamName ? (
															<h6 className={classes.textColor2}> {value.teamName} </h6>
														) : (
															<h6 className={classes.textColor2}> {value.userName} </h6>
														)}
													</GridItem>

													<GridItem xs className={classes.textColor2}>
														<Checkbox
															edge="end"
															className={classes.textColor2}
															onChange={() => {
																let checked = this.state.checked
																if (checked.indexOf(index) == -1) checked.push(index)
																else checked = checked.filter((v) => v != index)
																this.setState({ checked: checked })
															}}
															checked={this.state.checked.indexOf(index) !== -1}
															inputProps={{ "aria-labelledby": labelId }}
														/>
													</GridItem>
												</GridContainer>
											</ListItem>
										)
									})}
								</List>
							)}
						</div>
						<GridContainer direction="row" justify="space-between" alignItems="flex-end">
							<GridItem>
								<Button
									color="warning"
									onClick={() => {
										console.log("onClick cancel")
										if (this.props.onCancel) this.props.onCancel()
									}}
								>
									Cancel
								</Button>
							</GridItem>
							<GridItem>
								<Button
									disabled={!(this.props.allowSelectNone === true || this.state.checked.length)}
									color="success"
									onClick={() => {
										console.log("onClick Add")
										this.handleOnClickAddSelected()
									}}
								>
									Add selected Teams/Solvers
								</Button>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</div>
		)
	}
}

UsersTeamsListSelection.propTypes = {
	classes: PropTypes.object.isRequired,
	participants: PropTypes.array.isRequired,
	onCancel: PropTypes.func.isRequired,
	onAddSelected: PropTypes.func.isRequired,
	allowSelectNone: PropTypes.bool.isRequired
}

export default withStyles(style)(UsersTeamsListSelection)
