import React from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Constants from "constants.js"
import authentication from "authentication.js"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import aboutPageServices from "dataServices/aboutPageServices.js"
import CardFooter from "components/Card/CardFooter"
import { Typography } from "@material-ui/core"

import { Mutation } from "@apollo/client/react/components"
import { gql } from '@apollo/client'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import ReactHtmlParser from 'html-react-parser'
import htmlToDraft from "html-to-draftjs"
import FontOverridePatch from "utils/FontOverridePatch"

const uploadFileMutation = gql`
	mutation uploadFile($file: Upload!, $uploadType: String!) {
		uploadFile(file: $file, uploadType: $uploadType) {
			status
			message
			totalCount
			data {
				id
				filename
				path
				mimetype
				encoding
			}
		}
	}
`

var self = this

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	}
})

class AboutCCCPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			canEditAboutPage: false,
			editAboutPageMode: false,
			pageTitle: null,
			pageContent: null,
			editorState: EditorState.createEmpty(),
			pageContentType: null
		}
		self = this
	}

	updateAboutPage = () => {
		this.props.setLoadingSpinner()
		let pageContentType = this.state.pageContentType
		var variables = {}
		if (pageContentType == "about-ccc") {
			variables = {
				aboutCCC: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
			}
		} else if (pageContentType == "privacy") {
			variables = { privacy: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) }
		} else if (pageContentType == "terms") {
			variables = {
				termsAndConditions: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
			}
		} else if (pageContentType == "cookie-policy") {
			variables = {
				cookiePolicy: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
			}
		} else if (pageContentType == "contact-us") {
			variables = {
				contactUs: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
			}
		} else if (pageContentType == "pricing") {
			variables = { pricing: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) }
		} else if (pageContentType == "competition-regulations") {
			variables = { news: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) }
		} else if (pageContentType == "how-we-work") {
			variables = {
				resources: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
			}
		}
		aboutPageServices
			.createOrUpdateAboutPage(variables)
			.then((status) => {
				this.props.resetLoadingSpinner()
				if (status) {
					this.setState({ editAboutPageMode: false })
					this.fetchAboutCCC(this.state.pageContentType)
				}
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("warning", "Oops!", err.message)
			})
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			editorState
		})
	}

	uploadImageCallBack = (file, mutation) => {
		console.log(file)
		console.log(mutation)
		return new Promise((resolve, reject) => {
			mutation({
				mutation: uploadFileMutation,
				variables: { file: file, uploadType: "image" },
				fetchPolicy: "no-cache",
				context: {
				  headers: {
					'x-apollo-operation-name': 'upload-file'
				  }
				},
			})
				.then((res) => {
					if (res.data.uploadFile.status == true) {
						console.log("File upload response", res.data.uploadFile.data)
						let filePath = res.data.uploadFile.data.path
						var uploadResponse = { data: { link: Constants.Settings.APIURL + filePath } }
						resolve(uploadResponse)
					} else {
						throw new Error(res.data.uploadFile.message)
					}
				})
				.catch((err) => {
					reject(err.message)
				})
		})
	}

	prepareDataAndUpdateEditorState = (des) => {
		if (des != "<p></p>\n" && des) {
			const blocksFromHTML = htmlToDraft(des)
			const state = ContentState.createFromBlockArray(
				blocksFromHTML.contentBlocks,
				blocksFromHTML.entityMap
			)
			var updatedEditorState = EditorState.createWithContent(state)
			this.setState({
				editorState: updatedEditorState
			})
		}
	}

	fetchAboutCCC = (pageContentType) => {
		console.log("Page content type: ", pageContentType)
		aboutPageServices
			.getAboutPageByCustomerId()
			.then((aboutPageData) => {
				if (pageContentType == "about-ccc") {
					this.prepareDataAndUpdateEditorState(aboutPageData.aboutPageDetails.aboutCCC)
					this.setState({
						pageTitle: "About CCC",
						pageContent: aboutPageData.aboutPageDetails.aboutCCC
					})
				} else if (pageContentType == "privacy") {
					this.prepareDataAndUpdateEditorState(aboutPageData.aboutPageDetails.privacy)
					this.setState({
						pageTitle: "Privacy",
						pageContent: aboutPageData.aboutPageDetails.privacy
					})
				} else if (pageContentType == "terms") {
					this.prepareDataAndUpdateEditorState(aboutPageData.aboutPageDetails.termsAndConditions)
					this.setState({
						pageTitle: "Terms & Condition",
						pageContent: aboutPageData.aboutPageDetails.termsAndConditions
					})
				} else if (pageContentType == "cookie-policy") {
					this.prepareDataAndUpdateEditorState(aboutPageData.aboutPageDetails.cookiePolicy)
					this.setState({
						pageTitle: "Cookie Policy",
						pageContent: aboutPageData.aboutPageDetails.cookiePolicy
					})
				} else if (pageContentType == "contact-us") {
					this.prepareDataAndUpdateEditorState(aboutPageData.aboutPageDetails.contactUs)
					this.setState({
						pageTitle: "Contact Us",
						pageContent: aboutPageData.aboutPageDetails.contactUs
					})
				} else if (pageContentType == "pricing") {
					this.prepareDataAndUpdateEditorState(aboutPageData.aboutPageDetails.pricing)
					this.setState({
						pageTitle: "Pricing",
						pageContent: aboutPageData.aboutPageDetails.pricing
					})
				} else if (pageContentType == "competition-regulations") {
					this.prepareDataAndUpdateEditorState(aboutPageData.aboutPageDetails.news)
					this.setState({
						pageTitle: "Competition Regulations",
						pageContent: aboutPageData.aboutPageDetails.news
					})
				} else if (pageContentType == "how-we-work") {
					this.prepareDataAndUpdateEditorState(aboutPageData.aboutPageDetails.resources)
					this.setState({
						pageTitle: "How We Work",
						pageContent: aboutPageData.aboutPageDetails.resources
					})
				}
			})
			.catch((err) => {
				this.props.showAlert("warning", "Oops!", err.message)
			})
	}

	componentDidMount() {
		console.log("Component Did Mount of About CCC page: .. ")
		authentication
			.hasPermission(Constants.Functionalities.CanEditAboutPage)
			.then((permission) => {
				if (permission == Constants.Functionalities.CanEditAboutPage)
					this.setState({ canEditAboutPage: true })
			})
			.catch((err) => {
				console.log("Err = ", err.message)
				this.setState({ canEditAboutPage: false })
			})
		let pageContentType = window.location.href.split("/")
		let arrayLength = pageContentType.length
		this.setState({ pageContentType: pageContentType[arrayLength - 1] })
		this.fetchAboutCCC(pageContentType[arrayLength - 1])
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<GridContainer direction="row" justify="center" alignment="center">
					<GridItem xs={12} sm={8}>
						{!this.state.editAboutPageMode ? (
							<div>
								<CardBody>
									<Typography variant="body2" color="textPrimary" component="p">
										<FontOverridePatch>{ReactHtmlParser(this.state.pageContent || "")}</FontOverridePatch>
									</Typography>
								</CardBody>
								<CardFooter>
									<GridContainer direction="row" justify="flex-end">
										<GridItem>
											{this.state.canEditAboutPage && this.props.loggedInUserData ? (
												<Button
													color="twitter"
													onClick={() => this.setState({ editAboutPageMode: true })}
												>
													Edit
												</Button>
											) : null}
										</GridItem>
									</GridContainer>
								</CardFooter>
							</div>
						) : (
							<Card raised>
								<CardBody>
									<FontOverridePatch isDraftEditor>
										<Mutation mutation={uploadFileMutation} fetchPolicy="no-cache" context={{ headers: { 'x-apollo-operation-name': 'upload-file' } }}>
											{(mutation, { loading }) => (
												<Editor
													editorState={this.state.editorState}
													toolbarClassName="toolbarClassName"
													wrapperClassName=""
													editorClassName={classes.cccEditorStyle}
													className={classes.editRulesStyle}
													toolbar={{
														options: [
															"inline",
															"blockType",
															"fontSize",
															"fontFamily",
															"list",
															"textAlign",
															"colorPicker",
															"link",
															"embedded",
															"remove",
															"history",
															"image"
														],
														image: {
															uploadCallback: function (file) {
																return self.uploadImageCallBack(file, mutation)
															},
															previewImage: true,
															alt: { present: true, mandatory: true },
															defaultSize: {
																height: "auto",
																width: "100%"
															}
														}
													}}
													onEditorStateChange={this.onEditorStateChange}
												/>
											)}
										</Mutation>
									</FontOverridePatch>
								</CardBody>
								<CardFooter>
									<Button
										color="warning"
										onClick={() => {
											this.setState({ editAboutPageMode: false })
										}}
									>
										Cancel
									</Button>
									<Button
										color="success"
										disabled={
											draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) ==
											"<p></p>\n"
										}
										onClick={() => this.updateAboutPage()}
									>
										Update
									</Button>
								</CardFooter>
							</Card>
						)}
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

AboutCCCPage.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AboutCCCPage))
