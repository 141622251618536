import React, { Component } from "react";

import { GoogleLogin } from "@react-oauth/google";
import JWTDecoder from "jwt-decode";

import styles from './styles.module.scss';

class LoginWithGoogle extends Component {
    constructor(props) {
        super(props);
    }

    handleLoginSuccess = (credentialResponse) => {
        const data = JWTDecoder(credentialResponse.credential);
        const authData = {
            id: data.sub,
            email: data.email,
            name: data.name,
            profilePictureUrl: data.picture,
        }

        this.props.handleGoogleLogin && this.props.handleGoogleLogin(authData);
    }

    handleLoginFailed = (err) => {
        console.log("GoogleLogin Error, err = ", err.message);
        this.props.handleGoogleLogin && this.props.handleGoogleLogin(err);
    }

    render() {
        return (
            <div className={styles.iconButtons}>
                <GoogleLogin
                    type="icon"
                    theme="outline"
                    onSuccess={this.handleLoginSuccess}
                    onError={this.handleLoginFailed}
                />
            </div>
        );
    }
}

export default LoginWithGoogle;