export const evaluationScoreDetailsTableRow = [
  {
    title: 'Evaluator\'s Name',
    style: { align: 'left', width: "20%" }
  },
  {
    title: 'Criterion Name',
    style: { align: 'left' }
  },
  {
    title: 'Criterion Score',
    style: { align: 'left' }
  },
  {
    title: 'Remarks',
    style: { align: 'left' }
  },
];
