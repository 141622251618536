import React from "react"
// react component for creating dynamic tables
import ReactTable from "react-table"
import PropTypes from "prop-types"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import TextField from "@material-ui/core/TextField"
import Input from "@material-ui/core/Input"
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment"
import Dvr from "@material-ui/icons/Dvr"
import Favorite from "@material-ui/icons/Favorite"
import CloseIcon from "@material-ui/icons/Close"
import SearchIcon from "@material-ui/icons/Search"
import Tooltip from "@material-ui/core/Tooltip"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import Accordion from "components/Accordion/Accordion"
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx"
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import ErrorPage from "views/Pages/ErrorPage"

import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"

import Constants from "constants.js"
import ReactHtmlParser from 'html-react-parser'
import defaultChallengeImage from "assets/img/defaultChallengeImage.webp"

import helper from "../../helper"
import combineStyles from "utils/combineStyle"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import users from "dataServices/userServices"

import PhaseUtils from "utils/phaseUtils"
import challengeUtils from "utils/challengeUtils"
import FontOverridePatch from "utils/FontOverridePatch.jsx"

const styles = {
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	},
	featuredCardHead: {
		backgroundColor: "#CBCAF9",
		height: "105px"
	},
	challengesCardHead: {
		backgroundColor: "#CBCAF9",
		height: "105px"
	}
}

let challengesPerPageLimit = 10

class SelectFeaturedChallenges extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			challengeId: "",
			challenges: [],
			featuredChallenges: [],
			userProfile: null,
			searchText: "",
			searchKey: "",
			challengesLimit: challengesPerPageLimit,
			totalChallenges: 0,
			loadMore: false,
			hasPermission: false,
			isLoading: true
		}
	}

	goToChallengeDetails(challengeId) {
		if (this.state.userProfile) {
			this.props.history.push("/dashboard/challenge-details/" + challengeId)
		} else {
			this.props.history.push("/home/challenge-details/" + challengeId)
		}
	}

	handleOnClickAddRemove = (challengeId, isFeatured) => {
		console.log("add ", isFeatured)
		if (isFeatured && this.state.featuredChallenges.length >= 3) {
			this.props.showAlert(
				"warning",
				"Ooops!",
				"You can't add more than three challenges as featured"
			)
			return
		}
		this.props.setLoadingSpinner()
		challengeServices
			.setResetFeaturedChallenges(challengeId, isFeatured)
			.then((data) => {
				if (!data) throw new Error("Error occured while updating featuired challenges")
				return Promise.all([this.fetchChallenges(), this.fetchFeaturedChallenges()])
			})
			.then((result) => {
				this.props.resetLoadingSpinner()
				let featuredChallenges = result[1] ? result[1] : []
				let challenges = result[0] ? result[0].challenges : []
				let totalCount = result[0] ? result[0].totalCount : 0
				let filteredChallenges = challenges.filter((chal) => !chal.challengeDetails.isFeatured)
				let loadMore = totalCount != challenges.length
				this.setState({
					challenges: filteredChallenges,
					featuredChallenges: featuredChallenges,
					loadMore: loadMore
				})
			})
			.catch((err) => {
				console.log("Err: ", err.message)
				this.props.resetLoadingSpinner()
				this.props.showAlert("error", "Error!", err.message)
			})
	}

	handleOnClickLearnMore = (challengeId) => {
		if (!this.state.userProfile) {
			this.props.history.push("/home/challenge-details/" + challengeId)
		} else {
			this.props.history.push("/dashboard/challenge-details/" + challengeId)
		}
	}

	fetchChallenges = () => {
		return challengeServices
			.searchChallengesPaginated(1, this.state.challengesLimit, this.state.searchText, null)
			.then((data) => {
				if (!data) throw new Error("No Challenges found!")
				return data
			})
			.catch((err) => {
				console.log("Error fetching search and fetch challenges", err.message)
				return { challenges: [], totalCount: 0 }
			})
	}

	fetchFeaturedChallenges = () => {
		return challengeServices
			.getFeaturedChallenges()
			.then((challenges) => {
				return challenges ? challenges : []
			})
			.catch((err) => {
				console.log("Error fetching featured challenges", err.message)
				return []
			})
	}

	fetchCurrentUserProfile = () => {
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				console.log("Current User", data)
				return data
			})
			.catch((err) => {
				console.log("Error getting current User", err)
				return null
			})
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		Promise.all([
			this.fetchCurrentUserProfile(),
			this.fetchChallenges(),
			this.fetchFeaturedChallenges()
		])
			.then((result) => {
				console.log("multiple api calls ", result)
				console.log("Both APIs call okay")
				this.props.resetLoadingSpinner()
				let featuredChallenges = result[2] ? result[2] : []
				let challenges = result[1] ? result[1].challenges : []
				let totalCount = result[1] ? result[1].totalCount : 0
				let userProfile = result[0] ? result[0] : null
				let loadMore = challenges.length != totalCount
				let filteredChallenges = challenges.filter((chal) => !chal.challengeDetails.isFeatured)
				let totalChallenges = challenges.length
				this.setState({
					challenges: filteredChallenges,
					totalChallenges: totalChallenges,
					featuredChallenges: featuredChallenges,
					userProfile: userProfile,
					isLoading: false,
					loadMore: loadMore
				})
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error:", err)
			})
	}

	handleOnChangeInput = (e) => {
		this.setState(
			{
				[e.target.name]: e.target.value,
				challengesLimit: challengesPerPageLimit
			},
			() => {
				this.handleOnClickSearch()
			}
		)
	}

	handleClearSearchText = () => {
		this.setState(
			{
				searchText: "",
				challengesLimit: challengesPerPageLimit
			},
			() => {
				this.handleOnClickSearch()
			}
		)
	}

	handleOnClickSearch = () => {
		console.log("Lets Search")
		this.fetchChallenges().then((data) => {
			let challenges = data.challenges
			console.log("Search Done", challenges)
			let filteredChallenges = challenges.filter((chal) => !chal.challengeDetails.isFeatured)
			let loadMore = data.totalCount != challenges.length
			let totalChallenges = challenges.length
			this.setState({
				challenges: filteredChallenges,
				totalChallenges: totalChallenges,
				loadMore: loadMore
			})
		})
	}

	loadMoreChallenges = () => {
		console.log("Loading more challenges")
		this.setState(
			{
				challengesLimit: this.state.challengesLimit + challengesPerPageLimit
			},
			() => {
				this.fetchChallenges().then((data) => {
					console.log("load more data = ", data, this.state.challengesLimit)
					let challenges = data.challenges
					let loadMore = data.totalCount != challenges.length
					let filteredChallenges = challenges.filter((chal) => !chal.challengeDetails.isFeatured)
					let totalChallenges = challenges.length
					this.setState({
						challenges: filteredChallenges,
						totalChallenges: totalChallenges,
						loadMore: loadMore
					})
				})
			}
		)
	}

	checkPermission = () => {
		this.props.setLoadingSpinner()
		return users
			.hasPermission(Constants.Functionalities.CanSetFeaturedChallenge, null)
			.then((data) => {
				console.log("hasPermission = ", data)
				this.setState({ hasPermission: data })
				return data
			})
			.catch((err) => {
				console.log("hasPermission Error = ", err)
				this.props.resetLoadingSpinner()
				this.setState({ hasPermission: false, isLoading: false })
				return false
			})
	}

	componentDidMount() {
		console.log("Component Did Mount!")
		this.checkPermission().then((data) => {
			if (data) this.fetchAllData()
		})
	}
	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	render() {
		const { classes } = this.props
		helper.setPageTitle("Set Featured Challenges | BestInCrowd")
		return (
			<div>
				{!this.state.hasPermission && !this.state.isLoading && (
					<div>
						<SimpleBreadcrumbs
							breadLinkData={[
								{ name: "Home", link: "/home/index" },
								{ name: "Error", link: "/dashboard/featured-challenges" }
							]}
						/>
						<GridContainer direction="row" justify="space-evenly" alignItems="center">
							<GridItem>
								<ErrorPage
									errorCode={403}
									message={"Access Denied! :("}
									description={"You don't have permission to see this page!"}
								/>
							</GridItem>
						</GridContainer>
					</div>
				)}
				{this.state.hasPermission && !this.state.isLoading && (
					<div>
						<SimpleBreadcrumbs
							breadLinkData={[
								{ name: "Dashboard", link: "/dashboard/index" },
								{ name: "Challenges", link: "/dashboard/featured-challenges" }
							]}
						/>

						<GridContainer container alignItems="flex-start" direction="row">
							<GridItem xs={12} sm={12} md={6}>
								<Card>
									<CardHeader className={classes.cardHead + ' ' + classes.challengesCardHead}>
										<GridContainer
											container
											alignItems="center"
											justify="space-between"
											direction="row"
										>
											<GridItem xs={12} sm={6}>
												{" "}
												<h4>
													{" "}
													<strong>Challenges </strong>{" "}
												</h4>{" "}
											</GridItem>
											<GridItem xs={12} sm={6}>
												{this.state.challenges && this.state.challenges.length ? (
													<div style={{ textAlign: "right", fontWeight: "bold" }}>
														{" "}
														Currently showing: {1} - {this.state.challenges.length}{" "}
													</div>
												) : null}
											</GridItem>
											<GridItem xs={12} sm={12} md={12}>
												<Input
													fullWidth
													inputProps={{
														placeholder: "Search",
														name: "searchText",
														value: this.state.searchText,
														onChange: this.handleOnChangeInput
													}}
													endAdornment={
														<InputAdornment position="end">
															<IconButton
																aria-label="clear"
																disabled={!this.state.searchText}
																onClick={this.handleClearSearchText}
															>
																{this.state.searchText ? (
																	<Tooltip title="Clear" placement="top">
																		<CloseIcon />
																	</Tooltip>
																) : (
																	<Tooltip title="Search" placement="top">
																		<SearchIcon />
																	</Tooltip>
																)}
															</IconButton>
														</InputAdornment>
													}
												/>
											</GridItem>
										</GridContainer>
									</CardHeader>
									<CardBody>
										<GridContainer>
											{this.state.challenges && (
												<GridItem xs={12} sm={12} md={12}>
													<div
														style={{
															height: "900px",
															overflow: "scroll",
															backgroundColor: "#E5E8E8",
															margin: "0px -10px"
														}}
													>
														<div style={{ margin: "0px 15px" }}>
															{this.renderChallengesView(this.state.challenges)}
														</div>
													</div>
												</GridItem>
											)}
										</GridContainer>
									</CardBody>
									<CardFooter>
										<GridContainer container alignItems="center" justify="center" direction="row">
											{this.state.loadMore && (
												<GridItem>
													<Button
														color="twitter"
														disabled={!this.state.loadMore}
														onClick={this.loadMoreChallenges}
													>
														Load more
													</Button>
												</GridItem>
											)}
										</GridContainer>
									</CardFooter>
								</Card>
							</GridItem>

							<GridItem xs={12} sm={12} md={6}>
								<Card>
									<CardHeader className={classes.cardHead + ' ' + classes.featuredCardHead}>
										<GridContainer
											container
											alignItems="center"
											justify="space-between"
											direction="row"
										>
											<GridItem xs={12} sm={6}>
												{" "}
												<h4>
													{" "}
													<strong> Featured Challenges </strong>
												</h4>{" "}
											</GridItem>
											<GridItem xs={12} sm={6}>
												{this.state.featuredChallenges && this.state.featuredChallenges.length ? (
													<div style={{ textAlign: "right", fontWeight: "bold" }}>
														{" "}
														Currently Added: {this.state.featuredChallenges.length}{" "}
													</div>
												) : null}
											</GridItem>
										</GridContainer>
									</CardHeader>
									<CardBody>
										<GridContainer>
											{this.state.featuredChallenges && (
												<GridItem xs={12} sm={12} md={12}>
													<div
														style={{
															height: "900px",
															overflow: "scroll",
															backgroundColor: "#E5E8E8",
															margin: "0px -10px"
														}}
													>
														<div style={{ margin: "0px 15px" }}>
															{this.renderChallengesView(this.state.featuredChallenges)}
														</div>
													</div>
												</GridItem>
											)}
										</GridContainer>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
					</div>
				)}
			</div>
		)
	}

	renderChallengesView = (challenges) => {
		const { classes } = this.props
		if (challenges) {
			console.log("Challenges =", challenges)
			if (!challenges || !challenges.length) {
				return (
					<div style={{ margin: "30px auto" }}>
						<GridContainer justify="center" alignItems="flex-start">
							<GridItem>
								{" "}
								<h5> No Challenges Found </h5>{" "}
							</GridItem>
						</GridContainer>
					</div>
				)
			}
			let view = challenges.map((challenge) => {
				return this.renderChallenge(challenge)
			})
			return (
				<GridContainer container direction="row" justify="center" alignItems="flex-start">
					{view}
				</GridContainer>
			)
		}
		return null
	}

	renderChallenge = (challenge) => {
		const { classes } = this.props
		if (!challenge) return null
		let isFeatured = challenge.challengeDetails.isFeatured

		let currentPhase = "Not Available" //PhaseUtils.getCurrentPhase(challenge.challengeDetails.phases)
		let startDate = "",
			endDate = ""
		if (challenge.challengeDetails.currentPhase) {
			currentPhase = challenge.challengeDetails.currentPhase.phaseTitle
			startDate = challenge.challengeDetails.currentPhase.startDate
			endDate = challenge.challengeDetails.currentPhase.endDate
		}
		return (
			<GridItem key={challenge.challengeId} xs={10} sm={7} md={12} lg={7}>
				<Card raised={true}>
					<CardHeader color="info" className={classes.textOverflowEllipsisStyleForChallengeTitle}>
						<h4>{challenge.challengeDetails.title}</h4>
					</CardHeader>
					<CardBody>
						{challenge.challengeDetails.challengeImage ? (
							<img
								width={"100%"}
								src={Constants.Settings.APIURL + challenge.challengeDetails.challengeImage}
								alt="Image of challenge cover"
								onError={(event) => {
									event.target.src = defaultChallengeImage
								}}
							/>
						) : (
							<img
								width={"100%"}
								src={defaultChallengeImage}
								alt="Image of challenge cover default"
							/>
						)}
						{currentPhase && (
							<p className={classes.currentPhaseStyle}>Current Phase: {currentPhase}</p>
						)}
						<br />
						<Accordion
							collapses={[
								{
									title: (
										<div
											style={{
												height: "23px",
												overflow: "hidden",
												textOverflow: "ellipsis"
											}}
										>
											{challenge.challengeDetails.shortDescription.substr(0, 30)}
										</div>
									),
									content: (
										<div style={{ paddingLeft: "5px" }}>
											<p className={classes.cardProductDesciprion}>
												<FontOverridePatch>
													{ReactHtmlParser(challenge.challengeDetails.description || "")}
												</FontOverridePatch>
											</p>
											<p className={classes.cardProductDesciprion}>
												Prize Amount:{" "}
												{challengeUtils.getPrizeFormatted(challenge.challengeDetails.prizeAmount)}
											</p>
											{startDate && (
												<p className={classes.cardProductDesciprion}>Start Date: {startDate}</p>
											)}
											{endDate && (
												<p className={classes.cardProductDesciprion}>End Date: {endDate}</p>
											)}
										</div>
									)
								}
							]}
						/>
					</CardBody>
					<CardFooter className={classes.justifyContentCenter}>
						<div className={classes.center}>
							<Button
								round
								color="twitter"
								size="sm"
								onClick={() => this.handleOnClickLearnMore(challenge.challengeId)}
							>
								Learn more
							</Button>

							<Button
								round
								color={isFeatured ? "danger" : "success"}
								size="sm"
								onClick={() => this.handleOnClickAddRemove(challenge.challengeId, !isFeatured)}
							>
								{isFeatured ? "Remove" : "Add"}
							</Button>
						</div>
					</CardFooter>
				</Card>
			</GridItem>
		)
	}
}

let extraStyle = (theme) => ({
	cardProductDesciprion: {
		borderLeft: "5px solid cyan",
		padding: "5px 10px 5px 10px"
	},
	currentPhaseStyle: {
		margin: "10px 20px 0px 20px",
		borderRadius: "50px",
		padding: "5px 10px 5px 10px",
		fontSize: "1.3em",
		display: "block",
		backgroundColor: "#08D358",
		color: "#FFFFFF",
		textAlign: "center",
		overflowWrap: "break-word",
		height: "48px",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	textOverflowEllipsisStyleForChallengeTitle: {
		overflowWrap: "break-word",
		height: "125px",
		overflow: "hidden",
		textOverflow: "ellipsis"
	}
})

let finalStyles = combineStyles(styles, extraStyle)

SelectFeaturedChallenges.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	openLoginModal: PropTypes.func.isRequired,
	closeLoginModal: PropTypes.func.isRequired,
	setAfterLoginRedirectUrl: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE }),
		setAfterLoginRedirectUrl: (url) =>
			dispatch({ type: actionTypes.AFTER_LOGIN_REDIRECT_URL_SET, payload: url })
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyles)(SelectFeaturedChallenges))
