import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import ReactGA from 'react-ga';
import Constants from "../../constants";

class GoogleAnalytics extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
        }
        ReactGA.initialize(Constants.Settings.GA_ID);
        this.props.history.listen(location => {
            ReactGA.set({ page: location.pathname });
            ReactGA.pageview(location.pathname);
        })
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.setState({ loaded: true });
            ReactGA.set({ page: this.props.history.location.pathname });
            ReactGA.pageview(this.props.history.location.pathname);
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>

            </div>
        )
    }
}

let styles = theme => ({

});

GoogleAnalytics.propTypes = {
    classes: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
    return {
        isLoading: state.isLoading,
    };
};

export default connect(
    mapStateToProps,
    null
)(withStyles(styles)(GoogleAnalytics));