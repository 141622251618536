import React from "react"
import PropTypes from "prop-types"
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar"
import { NavLink } from "react-router-dom"
import cx from "classnames"
import Tooltip from "@material-ui/core/Tooltip"
import Fade from "@material-ui/core/Fade"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Hidden from "@material-ui/core/Hidden"
import Collapse from "@material-ui/core/Collapse"
import Icon from "@material-ui/core/Icon"
import InfoIcon from "@material-ui/icons/Info"
import SettingsIcon from "@material-ui/icons/Settings"
import BookIcon from "@material-ui/icons/LibraryBooks"
import MessageIcon from "@material-ui/icons/Message"
import AccountBox from "@material-ui/icons/AccountBox"

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx"
import Button from "components/CustomButtons/Button.jsx"
import cookiesHelper from "../../cookiesHelper"
import Constants from "../../constants"
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

//import avatar from "assets/img/faces/avatar.jpg";
import dummyAvatar from "assets/img/faces/dummy-avatar-300x300.webp"
import cccLogo from "assets/img/BIC_Logo.webp"
import userServices from "dataServices/userServices"
import constants from "constants.js"
import authentication from "authentication.js"
import { Divider } from "@material-ui/core"

import EditProfilePage from "views/Pages/EditProfilePage.jsx"
import FaceIcon from "@material-ui/icons/AccountBox"
import ThemeSwitcher from "ThemeSwitcher"

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
	constructor(props) {
		super(props)
		this.ps = null
	}

	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			this.ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false
			})
		}
	}

	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			this.ps.destroy()
		}
	}

	render() {
		const { className, user, headerLinks, links, settings, about, themeSwitcherMenu } = this.props
		return (
			<div className={className} ref="sidebarWrapper">
				{user}
				{headerLinks}
				{links}
				{headerLinks}
				{settings}
				{headerLinks}
				{about}
				{/* {themeSwitcherMenu} */}
			</div>
		)
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			openAvatar: false,
			openAbout: false,
			openSettings: false,
			miniActive: true,
			userName: "",
			avatar: null,
			canSetFeaturedChallenge: false,
			canSendMessage: false,
			canViewSettings: false,
			canViewSolversPage: false,
			canViewUsersPage: false,
			...this.getCollapseStates(props.routes)
		}
	}

	fetchCurrentUser = () => {
		userServices
			.getCurrentUser()
			.then((data) => {
				this.setState({
					avatar: data.avatarImagePath,
					userName: data.name,
				});
				
				let currentHighestRole =
					data.roles.filter((role) => role.RoleId === "admin" || role.RoleId === "superAdmin")
						.length > 0
						? "admin"
						: null;

				if (!currentHighestRole) {
					currentHighestRole =
						data.roles.filter((role) => role.RoleId === "licenseeAdmin").length > 0
							? "licenseeAdmin"
							: null;
				}

				if (!currentHighestRole) {
					currentHighestRole =
						data.roles.filter((role) => role.RoleId === "challengeAdmin").length > 0
							? "challengeAdmin"
							: null;
				}

				if (currentHighestRole) {
					this.setState({ canSendMessage: true });
					this.setState({ canViewUsersPage: true });

					if (currentHighestRole == "admin" || currentHighestRole == "licenseeAdmin") {
						this.setState({ canViewSolversPage: true });
					}
				}
			})
			.catch((err) => {
				console.log("Error getting currentUserData, err =", err.message)
			})
	}

	componentDidMount() {
		let data = cookiesHelper.get(constants.Settings.LoggedInCookieName)
		if (data) {
			this.props.setLoggedInUserData(data)
			this.fetchCurrentUser()
			this.setState({ canViewSettings: true })
		}
		authentication
			.hasPermission(constants.Functionalities.CanSetFeaturedChallenge)
			.then((permission) => {
				if (permission == constants.Functionalities.CanSetFeaturedChallenge)
					this.setState({ canSetFeaturedChallenge: true })
			})
			.catch((err) => {
				console.log("Err = ", err.message)
				this.setState({ canSetFeaturedChallenge: false })
			})
	}

	// this creates the intial state of this component based on the collapse routes
	// that it gets through this.props.routes
	getCollapseStates = (routes) => {
		let initialState = {}
		routes.map((prop, key) => {
			if (prop.collapse) {
				initialState = {
					[prop.state]: this.getCollapseInitialState(prop.views),
					...this.getCollapseStates(prop.views),
					...initialState
				}
			}
			return null
		})
		return initialState
	}

	// this verifies if any of the collapses should be default opened on a rerender of this component
	// for example, on the refresh of the page,
	// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
	getCollapseInitialState(routes) {
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
				return true
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return true
			}
		}
		return false
	}

	// verifies if routeName is the one active (in browser input)
	activeRoute = (routeName) => {
		return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : ""
	}

	openCollapse(collapse) {
		var st = {}
		st[collapse] = !this.state[collapse]
		this.setState(st)
	}

	// this function creates the links and collapses that appear in the sidebar (left menu)
	createLinks = (routes) => {
		const { classes, color, rtlActive } = this.props;

		if (!this.props.loggedInUserData) {
			var tempRoutes = routes.filter((value) => {
				return value.name != "Dashboard";
			});

			routes = tempRoutes;
		} else {
			tempRoutes = routes.filter((value) => {
				return value.name != "Home";
			});

			routes = tempRoutes;
		}

		if (!this.state.canSetFeaturedChallenge) {
			tempRoutes = routes.filter((value) => {
				return value.name != "Featured Challenges";
			})

			routes = tempRoutes;
		}

		return routes.map((prop, key) => {
			if (prop.redirect) {
				return null;
			}

			if ((prop.name == "Solvers" || prop.path == "/solvers") && !this.state.canViewSolversPage) {
				return null;
			}

			if ((prop.name == "Users" || prop.path == "/users") && !this.state.canViewUsersPage) {
				return null;
			}

			if (prop.collapse) {
				var st = {};
				st[prop["state"]] = !this.state[prop.state];
				const navLinkClasses =
					classes.itemLink +
					" " +
					cx({
						[" " + classes.collapseActive]: this.getCollapseInitialState(prop.views)
					});
				const itemText =
					classes.itemText +
					" " +
					cx({
						[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
						[classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.itemTextRTL]: rtlActive
					});
				const collapseItemText =
					classes.collapseItemText +
					" " +
					cx({
						[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextMiniRTL]:
							rtlActive && this.props.miniActive && this.state.miniActive,
						[classes.collapseItemTextRTL]: rtlActive
					});
				const itemIcon =
					classes.itemIcon +
					" " +
					cx({
						[classes.itemIconRTL]: rtlActive
					});
				const caret =
					classes.caret +
					" " +
					cx({
						[classes.caretRTL]: rtlActive
					});
				const collapseItemMini =
					classes.collapseItemMini +
					" " +
					cx({
						[classes.collapseItemMiniRTL]: rtlActive
					});
	
				return (
					<ListItem
						key={key}
						className={cx(
							{ [classes.item]: prop.icon !== undefined },
							{ [classes.collapseItem]: prop.icon === undefined }
						)}
					>
						<NavLink
							to={"#"}
							className={navLinkClasses}
							onClick={(e) => {
								e.preventDefault()
								this.setState(st)
							}}
						>
							{prop.icon !== undefined ? (
								typeof prop.icon === "string" ? (
									<Icon className={itemIcon}>{prop.icon}</Icon>
								) : (
									<prop.icon className={itemIcon} />
								)
							) : (
								<span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
							)}
							<ListItemText
								primary={rtlActive ? prop.rtlName : prop.name}
								secondary={
									<b
										className={caret + " " + (this.state[prop.state] ? classes.caretActive : "")}
									/>
								}
								disableTypography={true}
								className={cx(
									{ [itemText]: prop.icon !== undefined },
									{ [collapseItemText]: prop.icon === undefined }
								)}
							/>
						</NavLink>
						<Collapse
							in={this.state[prop.state]}
							unmountOnExit
							classes={{
								container: classes.collapseContainer,
								hidden: classes.collapseHidden,
								wrapper: classes.collapseWrapper
							}}
						>
							<List className={classes.list + " " + classes.collapseList}>
								{this.createLinks(prop.views)}
							</List>
						</Collapse>
					</ListItem>
				)
			}

			const innerNavLinkClasses =
				classes.collapseItemLink +
				" " +
				cx({
					[" " + classes[color]]: this.activeRoute(prop.layout + "/" + prop.path)
				});
			const collapseItemMini =
				classes.collapseItemMini +
				" " +
				cx({
					[classes.collapseItemMiniRTL]: rtlActive
				});
			const navLinkClasses =
				classes.itemLink +
				" " +
				cx({
					[" " + classes[color]]: this.activeRoute(prop.layout + "/" + prop.path)
				});
			const itemText =
				classes.itemText +
				" " +
				cx({
					[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
					[classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
					[classes.itemTextRTL]: rtlActive
				});
			const collapseItemText =
				classes.collapseItemText +
				" " +
				cx({
					[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
					[classes.collapseItemTextMiniRTL]:
						rtlActive && this.props.miniActive && this.state.miniActive,
					[classes.collapseItemTextRTL]: rtlActive
				});
			const itemIcon =
				classes.itemIcon +
				" " +
				cx({
					[classes.itemIconRTL]: rtlActive
				});

			return (
				<ListItem
					key={key}
					className={cx(
						{ [classes.item]: prop.icon !== undefined },
						{ [classes.collapseItem]: prop.icon === undefined }
					)}
				>
					{this.props.miniActive ? (
						<NavLink
							to={prop.layout + prop.path}
							className={cx(
								{ [navLinkClasses]: prop.icon !== undefined },
								{ [innerNavLinkClasses]: prop.icon === undefined }
							)}
						>
							<Tooltip
								title={prop.name}
								placement="right"
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 0 }}
							>
								<div>
									{prop.icon !== undefined ? (
										typeof prop.icon === "string" ? (
											<Icon className={itemIcon}>{prop.icon}</Icon>
										) : (
											<prop.icon className={itemIcon} />
										)
									) : (
										<span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
									)}
									<ListItemText
										primary={rtlActive ? prop.rtlName : prop.name}
										disableTypography={true}
										className={cx(
											{ [itemText]: prop.icon !== undefined },
											{ [collapseItemText]: prop.icon === undefined }
										)}
									/>
								</div>
							</Tooltip>
						</NavLink>
					) : (
						<NavLink
							to={prop.layout + prop.path}
							className={cx(
								{ [navLinkClasses]: prop.icon !== undefined },
								{ [innerNavLinkClasses]: prop.icon === undefined }
							)}
						>
							{prop.icon !== undefined ? (
								typeof prop.icon === "string" ? (
									<Icon className={itemIcon}>{prop.icon}</Icon>
								) : (
									<prop.icon className={itemIcon} />
								)
							) : (
								<span className={collapseItemMini}>{rtlActive ? prop.rtlMini : prop.mini}</span>
							)}
							<ListItemText
								primary={rtlActive ? prop.rtlName : prop.name}
								disableTypography={true}
								className={cx(
									{ [itemText]: prop.icon !== undefined },
									{ [collapseItemText]: prop.icon === undefined }
								)}
							/>
						</NavLink>
					)}
				</ListItem>
			)
		})
	}

	render() {
		const { classes, image, routes, bgColor, rtlActive } = this.props;
		const itemText =
			classes.itemText +
			" " +
			cx({
				[classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
				[classes.itemTextMiniRTL]: rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.itemTextRTL]: rtlActive
			});
		const collapseItemText =
			classes.collapseItemText +
			" " +
			cx({
				[classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextMiniRTL]:
					rtlActive && this.props.miniActive && this.state.miniActive,
				[classes.collapseItemTextRTL]: rtlActive
			});

		const userWrapperClass = classes.user + " " + cx({ [classes.whiteAfter]: bgColor === "white" });
		const caret = classes.caret + " " + cx({ [classes.caretRTL]: rtlActive });
		const collapseItemMini = classes.collapseItemMini + " " + cx({ [classes.collapseItemMiniRTL]: rtlActive });
		const photo = classes.photo + " " + cx({ [classes.photoRTL]: rtlActive });
		const logoClasses = classes.logo + " " + cx({ [classes.whiteAfter]: bgColor === "white" });

		if (this.state.avatar) {
			if (this.state.avatar.includes("http")) {
				var avatar = this.state.avatar;
			} else {
				avatar = Constants.Settings.APIURL + this.state.avatar;
			}
		} else {
			avatar = dummyAvatar;
		}

		let userName = this.props.loggedInUserData
			? this.props.loggedInUserData.userName
				? this.props.loggedInUserData.userName
				: "No Name Found"
			: "User Name";

		const user = this.props.loggedInUserData ? (
			<div className={userWrapperClass}>
				<div className={photo}>
					<img src={avatar} className={classes.avatarImg} alt="Image of user's profile avatar" />
				</div>
				<List className={classes.list}>
					<ListItem className={classes.item + " " + classes.userItem}>
						{this.props.miniActive ? (
							<NavLink
								to={"#"}
								className={classes.itemLink + " " + classes.userCollapseButton}
								onClick={() => this.openCollapse("openAvatar")}
							>
								<Tooltip
									title="Edit Profile"
									placement="right"
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 0 }}
								>
									<ListItemText
										primary={rtlActive ? "تانيا أندرو" : userName}
										secondary={
											<b
												className={
													caret +
													"Text" +
													classes.userCaret +
													" " +
													(this.state.openAvatar ? classes.caretActive : "")
												}
											/>
										}
										disableTypography={true}
										className={itemText + " " + classes.userItemText}
									/>
								</Tooltip>
							</NavLink>
						) : (
							<NavLink
								to={"#"}
								className={classes.itemLink + " " + classes.userCollapseButton}
								onClick={() => this.openCollapse("openAvatar")}
							>
								<ListItemText
									primary={rtlActive ? "تانيا أندرو" : userName}
									secondary={
										<b
											className={
												caret +
												"Text" +
												classes.userCaret +
												" " +
												(this.state.openAvatar ? classes.caretActive : "")
											}
										/>
									}
									disableTypography={true}
									className={itemText + " " + classes.userItemText}
								/>
							</NavLink>
						)}

						<Collapse
							in={this.state.openAvatar}
							unmountOnExit
							classes={{
								container: classes.collapseContainer,
								hidden: classes.collapseHidden,
								wrapper: classes.collapseWrapper
							}}
						>
							<List className={classes.list + " " + classes.collapseList}>
								<ListItem className={classes.collapseItem}>
									{this.props.miniActive ? (
										<NavLink
											to="/dashboard/edit-profile"
											className={classes.itemLink + " " + classes.userCollapseLinks}
										>
											<Tooltip
												title="My Profile"
												placement="right"
												TransitionComponent={Fade}
												TransitionProps={{ timeout: 0 }}
											>
												<div>
													<span className={collapseItemMini}>
														<AccountBox />
													</span>

													<ListItemText
														primary={rtlActive ? "ملفي" : "My Profile"}
														disableTypography={true}
														className={collapseItemText}
													/>
												</div>
											</Tooltip>
										</NavLink>
									) : (
										<NavLink
											to="/dashboard/edit-profile"
											className={classes.itemLink + " " + classes.userCollapseLinks}
										>
											<span className={collapseItemMini}>
												<AccountBox />
											</span>
											<ListItemText
												primary={rtlActive ? "ملفي" : "My Profile"}
												disableTypography={true}
												className={collapseItemText}
											/>
										</NavLink>
									)}
								</ListItem>
							</List>
						</Collapse>
					</ListItem>
				</List>
			</div>
		) : null;

		const links = <List className={classes.list}>{this.createLinks(routes)}</List>;

		const aboutCCC = (
			<div className={userWrapperClass}>
				<div className={photo}>
					{/* <img src={dummyAvatar} className={classes.avatarImg} alt="..." /> */}
					<InfoIcon className={classes.avatarImg} />
				</div>
				<List className={classes.list}>
					<ListItem className={classes.item + " " + classes.userItem}>
						{this.props.miniActive ? (
							<NavLink
								to={"#"}
								className={classes.itemLink + " " + classes.userCollapseButton}
								onClick={() => this.openCollapse("openAbout")}
							>
								<Tooltip
									title="About"
									placement="right"
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 0 }}
								>
									<ListItemText
										primary={rtlActive ? "تانيا أندرو" : "About"}
										secondary={
											<b
												className={
													caret +
													"Text" +
													classes.userCaret +
													" " +
													(this.state.openAbout ? classes.caretActive : "")
												}
											/>
										}
										disableTypography={true}
										className={itemText + " " + classes.userItemText}
									/>
								</Tooltip>
							</NavLink>
						) : (
							<NavLink
								to={"#"}
								className={classes.itemLink + " " + classes.userCollapseButton}
								onClick={() => this.openCollapse("openAbout")}
							>
								<ListItemText
									primary={rtlActive ? "تانيا أندرو" : "About"}
									secondary={
										<b
											className={
												caret +
												"Text" +
												classes.userCaret +
												" " +
												(this.state.openAbout ? classes.caretActive : "")
											}
										/>
									}
									disableTypography={true}
									className={itemText + " " + classes.userItemText}
								/>
							</NavLink>
						)}

						<Collapse
							in={this.state.openAbout}
							unmountOnExit
							classes={{
								container: classes.collapseContainer,
								hidden: classes.collapseHidden,
								wrapper: classes.collapseWrapper
							}}
						>
							<List className={classes.list + " " + classes.collapseList}>
								<ListItem className={classes.collapseItem}>
									{this.props.miniActive ? (
										<NavLink
											to="/home/about-ccc"
											className={classes.itemLink + " " + classes.userCollapseLinks}
										>
											<Tooltip
												title="About CCC"
												placement="right"
												TransitionComponent={Fade}
												TransitionProps={{ timeout: 0 }}
											>
												<div>
													<span className={collapseItemMini}>
														<InfoIcon />
													</span>
													<ListItemText
														primary={rtlActive ? "ملفي" : "About CCC"}
														disableTypography={true}
														className={collapseItemText}
													/>
												</div>
											</Tooltip>
										</NavLink>
									) : (
										<NavLink
											to="/home/about-ccc"
											className={classes.itemLink + " " + classes.userCollapseLinks}
										>
											<span className={collapseItemMini}>
												<InfoIcon />
											</span>
											<ListItemText
												primary={rtlActive ? "ملفي" : "About CCC"}
												disableTypography={true}
												className={collapseItemText}
											/>
										</NavLink>
									)}
								</ListItem>

								{/* <ListItem className={classes.collapseItem}>
                  {
                    this.props.miniActive ?
                      <NavLink
                        to="/home/team"
                        className={
                          classes.itemLink + " " + classes.userCollapseLinks
                        }
                      >
                        <Tooltip title="Team" placement="right" TransitionComponent={Fade} TransitionProps={{ timeout: 0 }}>
                          <div>
                            <span className={collapseItemMini}>
                              <InfoIcon />
                            </span>
                            <ListItemText
                              primary={
                                rtlActive ? "تعديل الملف الشخصي" : "Team"
                              }
                              disableTypography={true}
                              className={collapseItemText}
                            />
                          </div>
                        </Tooltip>
                      </NavLink>
                      :
                      <NavLink
                        to="/home/team"
                        className={
                          classes.itemLink + " " + classes.userCollapseLinks
                        }
                      >
                        <span className={collapseItemMini}>
                          <InfoIcon />
                        </span>
                        <ListItemText
                          primary={
                            rtlActive ? "تعديل الملف الشخصي" : "Team"
                          }
                          disableTypography={true}
                          className={collapseItemText}
                        />
                      </NavLink>
                  }
                </ListItem> */}

								<ListItem className={classes.collapseItem}>
									{this.props.miniActive ? (
										<NavLink
											to="/home/contact-us"
											className={classes.itemLink + " " + classes.userCollapseLinks}
										>
											<Tooltip
												title="Contact us"
												placement="right"
												TransitionComponent={Fade}
												TransitionProps={{ timeout: 0 }}
											>
												<div>
													<span className={collapseItemMini}>
														<InfoIcon />
													</span>
													<ListItemText
														primary={rtlActive ? "إعدادات" : "Contact Us"}
														disableTypography={true}
														className={collapseItemText}
													/>
												</div>
											</Tooltip>
										</NavLink>
									) : (
										<NavLink
											to="/home/contact-us"
											className={classes.itemLink + " " + classes.userCollapseLinks}
										>
											<span className={collapseItemMini}>
												<InfoIcon />
											</span>
											<ListItemText
												primary={rtlActive ? "إعدادات" : "Contact Us"}
												disableTypography={true}
												className={collapseItemText}
											/>
										</NavLink>
									)}
								</ListItem>
							</List>
						</Collapse>
					</ListItem>
				</List>
			</div>
		);

		const settingsMenu =
			this.state.canSendMessage || this.state.canSetFeaturedChallenge ? (
				<div className={userWrapperClass}>
					<div className={photo}>
						<SettingsIcon className={classes.avatarImg} />
					</div>
					<List className={classes.list}>
						<ListItem className={classes.item + " " + classes.userItem}>
							{this.props.miniActive ? (
								<NavLink
									to={"#"}
									className={classes.itemLink + " " + classes.userCollapseButton}
									onClick={() => this.openCollapse("openSettings")}
								>
									<Tooltip
										title="Settings"
										placement="right"
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 0 }}
									>
										<ListItemText
											primary={rtlActive ? "تانيا أندرو" : "Settings"}
											secondary={
												<b
													className={
														caret +
														"Text" +
														classes.userCaret +
														" " +
														(this.state.openSettings ? classes.caretActive : "")
													}
												/>
											}
											disableTypography={true}
											className={itemText + " " + classes.userItemText}
										/>
									</Tooltip>
								</NavLink>
							) : (
								<NavLink
									to={"#"}
									className={classes.itemLink + " " + classes.userCollapseButton}
									onClick={() => this.openCollapse("openSettings")}
								>
									<ListItemText
										primary={rtlActive ? "تانيا أندرو" : "Settings"}
										secondary={
											<b
												className={
													caret +
													"Text" +
													classes.userCaret +
													" " +
													(this.state.openSettings ? classes.caretActive : "")
												}
											/>
										}
										disableTypography={true}
										className={itemText + " " + classes.userItemText}
									/>
								</NavLink>
							)}

							<Collapse
								in={this.state.openSettings}
								unmountOnExit
								classes={{
									container: classes.collapseContainer,
									hidden: classes.collapseHidden,
									wrapper: classes.collapseWrapper
								}}
							>
								<List className={classes.list + " " + classes.collapseList}>
									{this.state.canSetFeaturedChallenge && (
										<ListItem className={classes.collapseItem}>
											{this.props.miniActive ? (
												<NavLink
													to="/dashboard/featured-challenges"
													className={classes.itemLink + " " + classes.userCollapseLinks}
												>
													<Tooltip
														title="Featured Challenges"
														placement="right"
														TransitionComponent={Fade}
														TransitionProps={{ timeout: 0 }}
													>
														<div>
															<span className={collapseItemMini}>
																<BookIcon />
															</span>
															<ListItemText
																primary={rtlActive ? "ملفي" : "Featured Challenges"}
																disableTypography={true}
																className={collapseItemText}
															/>
														</div>
													</Tooltip>
												</NavLink>
											) : (
												<NavLink
													to="/dashboard/featured-challenges"
													className={classes.itemLink + " " + classes.userCollapseLinks}
												>
													<span className={collapseItemMini}>
														<BookIcon />
													</span>
													<ListItemText
														primary={rtlActive ? "ملفي" : "Featured Challenges"}
														disableTypography={true}
														className={collapseItemText}
													/>
												</NavLink>
											)}
										</ListItem>
									)}
									{this.state.canSendMessage && (
										<ListItem className={classes.collapseItem}>
											{this.props.miniActive ? (
												<NavLink
													to="/dashboard/send-general-mail"
													className={classes.itemLink + " " + classes.userCollapseLinks}
												>
													<Tooltip
														title="Messages"
														placement="right"
														TransitionComponent={Fade}
														TransitionProps={{ timeout: 0 }}
													>
														<div>
															<span className={collapseItemMini}>
																<MessageIcon />
															</span>
															<ListItemText
																primary={rtlActive ? "تعديل الملف الشخصي" : "Messages"}
																disableTypography={true}
																className={collapseItemText}
															/>
														</div>
													</Tooltip>
												</NavLink>
											) : (
												<NavLink
													to="/dashboard/send-general-mail"
													className={classes.itemLink + " " + classes.userCollapseLinks}
												>
													<span className={collapseItemMini}>
														<InfoIcon />
													</span>
													<ListItemText
														primary={rtlActive ? "تعديل الملف الشخصي" : "Messages"}
														disableTypography={true}
														className={collapseItemText}
													/>
												</NavLink>
											)}
										</ListItem>
									)}
								</List>
							</Collapse>
						</ListItem>
					</List>
				</div>
			) : null

		const themeSwitcherMenu = (
			<div className={userWrapperClass}>
				<div style={{ margin: "0px 20px" }}>
					<ThemeSwitcher />
				</div>
			</div>
		);

		const brand = (
			<div className={logoClasses}>
				<NavLink to="/home/index">
					<img
						src={cccLogo}
						alt="Image of ccc Logo"
						style={{
							maxWidth: "70%",
							margin: "20px 20px",
							verticalAlign: "center",
							align: "center"
						}}
					/>
				</NavLink>
			</div>
		);

		const drawerPaper =
			classes.drawerPaper +
			" " +
			cx({
				[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
				[classes.drawerPaperRTL]: rtlActive
			});
		const sidebarWrapper =
			classes.sidebarWrapper +
			" " +
			cx({
				[classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
				[classes.sidebarWrapperWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
			});

		return (
			<div ref="mainPanel">
				<Hidden mdUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={rtlActive ? "left" : "right"}
						open={this.props.open}
						classes={{
							paper: drawerPaper + " " + classes[bgColor + "Background"]
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true // Better open performance on mobile.
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							links={links}
							settings={settingsMenu}
							about={aboutCCC}
							themeSwitcherMenu={themeSwitcherMenu}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						) : null}
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						anchor={rtlActive ? "right" : "left"}
						variant="permanent"
						open
						classes={{
							paper: drawerPaper + " " + classes[bgColor + "Background"]
						}}
					>
						{brand}
						<SidebarWrapper
							className={sidebarWrapper}
							user={user}
							links={links}
							settings={settingsMenu}
							about={aboutCCC}
							themeSwitcherMenu={themeSwitcherMenu}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{ backgroundImage: "url(" + image + ")" }}
							/>
						) : null}
					</Drawer>
				</Hidden>
			</div>
		)
	}
}

Sidebar.defaultProps = {
	bgColor: "blue"
}

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(["white", "black", "blue"]),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf(["white", "red", "orange", "green", "blue", "purple", "rose"]),
	logo: PropTypes.string,
	logoText: PropTypes.string,
	image: PropTypes.string,
	avatar: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sidebarStyle)(Sidebar))
