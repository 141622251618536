import { get } from "lodash";

import apolloFetcher from "apolloFetcher";

let challengeServices = {}

challengeServices.getAllChallenges = (filterBy, dataField = null) => {
	let query = `
  query challenges($filterBy: FilteringCriteriaInput) {
    challenges(filterBy: $filterBy) {
      status
      message
      totalCount
      data {
        urlSlug
        challengeId
        permissions
        roles {
          role
          teamId
          evalutationTeamId
        }
        phaseLinks{
          urlSlug
          phaseTitle
          challengeId
          challengeStatus
        }
        challengeDetails {
          title
          sponsorsName
          challengeType
          prizeAmount
          challengeCategory
          shortDescription
          description
          phaseTitle
          startDate
          endDate
          evaluationCriteriaId
          prizes {
            firstPlace
            secondPlace
          }
          timeline
          data
          rules
          evaluators {
            userId
          }
          judges {
            userId
          }
          participants {
            userId
            teamId
            role
          }
          tempParticipants {
            name
            role
            email
            userId
          }
          status {
            numberOfTeams
            numberOfSolvers
            numberOfSubmissions
            currentPhase
            currentPhaseEndDate
          }
          tags {
            tag
          }
          phases {
            phaseId
            startDate
            endDate
            title
            description
            phaseIcon
          }
          currentPhase {
            phaseId
            phaseTitle
            startDate
            endDate
          }
          faqLastUpdate
          faq {
            faqId
            question
            answer
          }
          solverSelectionType{
            allUsers
            selectedUsers
          }
          challengeImage
          isFeatured
          leaderboard {
            solutionId
            submissionFileName
            submissionFilePath
            solverUserId
            userName
            solverTeamId
            teamName
            averageScore
            variance
            standing
            remarks
          }
          challengeStatus
        }
      }
    }
  }  
  `

	if (dataField) {
		query = `
        {
            challenges{
              status
              message
              totalCount
              data{
                ${dataField}
              }
            }
          }
        `
	}

	let variables = {
		filterBy: filterBy
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables }, true)
			.then((res) => {
				if (!res || !res.data || !res.data.challenges)
					throw new Error("Error occured while fetching challenges")
				if (!res.data.challenges.status) throw new Error(res.data.challenges.message)
				resolve(res.data.challenges.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getAllChallengesPaginated = (filterBy, pagination) => {
	let query = `
  query getAllChallengesPaginated(
    $filterBy: FilteringCriteriaInput
    $pagination: PaginationInput!
  ) {
    getAllChallengesPaginated(filterBy: $filterBy, pagination: $pagination) {
      status
      message
      totalCount
      data {
        availableChallengeCount
        fetchedChallengeCount
        challenges {
          urlSlug
          challengeId
          permissions
          roles {
            role
            teamId
            evalutationTeamId
          }
          phaseLinks{
            urlSlug
            phaseTitle
            challengeId
            challengeStatus
          }
          challengeDetails {
            title
            sponsorsName
            challengeType
            prizeAmount
            challengeCategory
            shortDescription
            description
            phaseTitle
            startDate
            endDate
            evaluationCriteriaId
            prizes {
              firstPlace
              secondPlace
            }
            timeline
            data
            rules
            evaluators {
              userId
            }
            judges {
              userId
            }
            participants {
              userId
              teamId
              role
            }
            tempParticipants {
              name
              role
              email
              userId
            }
            status {
              numberOfTeams
              numberOfSolvers
              numberOfSubmissions
              currentPhase
              currentPhaseEndDate
            }
            tags {
              tag
            }
            phases {
              phaseId
              startDate
              endDate
              title
              description
              phaseIcon
            }
            currentPhase {
              phaseId
              phaseTitle
              startDate
              endDate
            }
            faqLastUpdate
            faq {
              faqId
              question
              answer
            }
            solverSelectionType{
              allUsers
              selectedUsers
            }
            challengeImage
            isFeatured
            leaderboard {
              solutionId
              submissionFileName
              submissionFilePath
              solverUserId
              userName
              solverTeamId
              teamName
              averageScore
              variance
              standing
              remarks
            }
            challengeStatus
          }
        }
      }
    }
  }  
  `

	let variables = {
		filterBy: filterBy,
		pagination: pagination
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables }, true)
			.then((res) => {
				if (!res || !res.data || !res.data.getAllChallengesPaginated)
					throw new Error("Error occured while fetching challenges")
				if (!res.data.getAllChallengesPaginated.status)
					throw new Error(res.data.getAllChallengesPaginated.message)
				resolve(res.data.getAllChallengesPaginated.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getCompletedChallengesPaginated = (pagination) => {
	let query = `
  query getCompletedChallengesPaginated(
    $pagination: PaginationInput!
  ) {
    getCompletedChallengesPaginated(pagination: $pagination) {
      status
      message
      totalCount
      data {
        availableChallengeCount
        fetchedChallengeCount
        challenges {
          urlSlug
          challengeId
          permissions
          roles {
            role
            teamId
            evalutationTeamId
          }
          phaseLinks{
            urlSlug
            phaseTitle
            challengeId
            challengeStatus
          }
          challengeDetails {
            title
            sponsorsName
            challengeType
            prizeAmount
            challengeCategory
            shortDescription
            description
            phaseTitle
            startDate
            endDate
            evaluationCriteriaId
            prizes {
              firstPlace
              secondPlace
            }
            timeline
            data
            rules
            evaluators {
              userId
            }
            judges {
              userId
            }
            participants {
              userId
              teamId
              role
            }
            tempParticipants {
              name
              role
              email
              userId
            }
            status {
              numberOfTeams
              numberOfSolvers
              numberOfSubmissions
              currentPhase
              currentPhaseEndDate
            }
            tags {
              tag
            }
            phases {
              phaseId
              startDate
              endDate
              title
              description
              phaseIcon
            }
            currentPhase {
              phaseId
              phaseTitle
              startDate
              endDate
            }
            faqLastUpdate
            faq {
              faqId
              question
              answer
            }
            solverSelectionType{
              allUsers
              selectedUsers
            }
            challengeImage
            isFeatured
            leaderboard {
              solutionId
              submissionFileName
              submissionFilePath
              solverUserId
              userName
              solverTeamId
              teamName
              averageScore
              variance
              standing
              remarks
            }
            challengeStatus
          }
        }
      }
    }
  }  
  `

	let variables = {
		pagination: pagination
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables }, true)
			.then((res) => {
				if (!res || !res.data || !res.data.getCompletedChallengesPaginated)
					throw new Error("Error occurred while fetching completed challenges")
				if (!res.data.getCompletedChallengesPaginated.status)
					throw new Error(res.data.getCompletedChallengesPaginated.message)
				resolve(res.data.getCompletedChallengesPaginated.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getClosedChallengesPaginated = (pagination) => {
	let query = `
  query getClosedChallengesPaginated(
    $pagination: PaginationInput!
  ) {
    getClosedChallengesPaginated(pagination: $pagination) {
      status
      message
      totalCount
      data {
        availableChallengeCount
        fetchedChallengeCount
        challenges {
          urlSlug
          challengeId
          permissions
          roles {
            role
            teamId
            evalutationTeamId
          }
          phaseLinks{
            urlSlug
            phaseTitle
            challengeId
            challengeStatus
          }
          challengeDetails {
            title
            sponsorsName
            challengeType
            prizeAmount
            challengeCategory
            shortDescription
            description
            phaseTitle
            startDate
            endDate
            evaluationCriteriaId
            prizes {
              firstPlace
              secondPlace
            }
            timeline
            data
            rules
            evaluators {
              userId
            }
            judges {
              userId
            }
            participants {
              userId
              teamId
              role
            }
            tempParticipants {
              name
              role
              email
              userId
            }
            status {
              numberOfTeams
              numberOfSolvers
              numberOfSubmissions
              currentPhase
              currentPhaseEndDate
            }
            tags {
              tag
            }
            phases {
              phaseId
              startDate
              endDate
              title
              description
              phaseIcon
            }
            currentPhase {
              phaseId
              phaseTitle
              startDate
              endDate
            }
            faqLastUpdate
            faq {
              faqId
              question
              answer
            }
            solverSelectionType{
              allUsers
              selectedUsers
            }
            challengeImage
            isFeatured
            leaderboard {
              solutionId
              submissionFileName
              submissionFilePath
              solverUserId
              userName
              solverTeamId
              teamName
              averageScore
              variance
              standing
              remarks
            }
            challengeStatus
          }
        }
      }
    }
  }  
  `

	let variables = {
		pagination: pagination
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables }, true)
			.then((res) => {
				if (!res || !res.data || !res.data.getClosedChallengesPaginated)
					throw new Error("Error occurred while fetching closed challenges")
				if (!res.data.getClosedChallengesPaginated.status)
					throw new Error(res.data.getClosedChallengesPaginated.message)
				resolve(res.data.getClosedChallengesPaginated.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getMyChallenges = () => {
	let query = `
    {
      getMyChallenges{
          status
          message
          totalCount
          data{
            urlSlug
            challengeId
            permissions
            roles{
              role
              teamId
              evalutationTeamId
            }
            phaseLinks{
              urlSlug
              phaseTitle
              challengeId
              challengeStatus
            }
            challengeDetails{
              title
              sponsorsName
              challengeType
              prizeAmount
              challengeCategory
              shortDescription
              description
              phaseTitle
              startDate
              endDate
              evaluationCriteriaId
              prizes{
                firstPlace
                secondPlace
              }
              timeline
              data
              rules
              evaluators{
                userId
              }
              judges{
                userId
              }
              participants{
                userId
                teamId
                role
              }
              tempParticipants{
                name
                role
                email
                userId
              }
              status{
                numberOfTeams
                numberOfSolvers
                numberOfSubmissions
                currentPhase
                currentPhaseEndDate
              }
              tags{
                tag
              }
              phases{
                phaseId
                startDate
                endDate
                title
                description
                phaseIcon
              }
              currentPhase{
                phaseId
                phaseTitle
                startDate
                endDate
              }
              faqLastUpdate
              faq{
                faqId
                question
                answer
              }
              solverSelectionType{
                allUsers
                selectedUsers
              }
              challengeImage
              isFeatured
              leaderboard{
                solutionId
                submissionFileName
                submissionFilePath
                solverUserId
                userName
                solverTeamId
                teamName
                averageScore
                variance
                standing
                remarks
              }
              challengeStatus
            }
          }
        }
      }    
    `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.getMyChallenges)
					throw new Error("Error occured while fetching challenges")
				if (!res.data.getMyChallenges.status) throw new Error(res.data.getMyChallenges.message)
				resolve(res.data.getMyChallenges.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getMyDraftChallenges = () => {
	let query = `
    {
      getMyDraftChallenges{
          status
          message
          totalCount
          data{
            urlSlug
            challengeId
            permissions
            roles{
              role
              teamId
              evalutationTeamId
            }
            phaseLinks{
              urlSlug
              phaseTitle
              challengeId
              challengeStatus
            }
            challengeDetails{
              title
              sponsorsName
              challengeType
              prizeAmount
              challengeCategory
              shortDescription
              description
              phaseTitle
              startDate
              endDate
              evaluationCriteriaId
              prizes{
                firstPlace
                secondPlace
              }
              timeline
              data
              rules
              evaluators{
                userId
              }
              judges{
                userId
              }
              participants{
                userId
                teamId
                role
              }
              tempParticipants{
                name
                role
                email
                userId
              }
              status{
                numberOfTeams
                numberOfSolvers
                numberOfSubmissions
                currentPhase
                currentPhaseEndDate
              }
              tags{
                tag
              }
              phases{
                phaseId
                startDate
                endDate
                title
                description
                phaseIcon
              }
              currentPhase{
                phaseId
                phaseTitle
                startDate
                endDate
              }
              faqLastUpdate
              faq{
                faqId
                question
                answer
              }
              solverSelectionType{
                allUsers
                selectedUsers
              }
              challengeImage
              isFeatured
              leaderboard{
                solutionId
                submissionFileName
                submissionFilePath
                solverUserId
                userName
                solverTeamId
                teamName
                averageScore
                variance
                standing
                remarks
              }
              challengeStatus
            }
          }
        }
      }    
    `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.getMyDraftChallenges)
					throw new Error("Error occurred while fetching challenges")
				if (!res.data.getMyDraftChallenges.status)
					throw new Error(res.data.getMyDraftChallenges.message)
				resolve(res.data.getMyDraftChallenges.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getChallengesWithPendingInvitations = () => {
	let query = `
  query{
    getMyChallengesWithPendingInvitations {
    status
    message
    totalCount
    data {
      urlSlug
      challengeId
      permissions
      roles{
        role
        teamId
        evalutationTeamId
      }
      phaseLinks{
        urlSlug
        phaseTitle
        challengeId
        challengeStatus
      }
      challengeDetails {
        title
        sponsorsName
        challengeType
        prizeAmount
        challengeCategory
        shortDescription
        description
        phaseTitle
        startDate
        endDate
        evaluationCriteriaId
        prizes {
          firstPlace
          secondPlace
        }
        timeline
        data
        rules
        evaluators {
          userId
        }
        judges {
          userId
        }
        participants {
          userId
          teamId
          role
        }
        tempParticipants {
          name
          role
          email
          userId
        }
        status {
          numberOfTeams
          numberOfSolvers
          numberOfSubmissions
          currentPhase
          currentPhaseEndDate
        }
        tags {
          tag
        }
        phases {
          phaseId
          startDate
          endDate
          title
          description
          phaseIcon
        }
        currentPhase{
          phaseId
          phaseTitle
          startDate
          endDate
        }
        faqLastUpdate
        faq {
          faqId
          question
          answer
        }
        solverSelectionType{
          allUsers
          selectedUsers
        }
        challengeImage
        isFeatured
        leaderboard {
          solutionId
          submissionFileName
          submissionFilePath
          solverUserId
          userName
          solverTeamId
          teamName
          averageScore
          variance
          standing
          remarks
        }
        challengeStatus
      }
    }
  }
}
  `
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.getMyChallengesWithPendingInvitations)
					throw new Error("Error occured while fetching challenges with pending invitations")
				if (!res.data.getMyChallengesWithPendingInvitations.status)
					throw new Error(res.data.getMyChallengesWithPendingInvitations.message)
				resolve(res.data.getMyChallengesWithPendingInvitations.data)
			})
			.catch((err) => {
				console.log("Error fetching pending challenges, err =", err.message)
				reject(err)
			})
	})
}

challengeServices.adminUpdateChallengeStatus = (challengeId, challengeStatus) => {
	let query = `
  mutation adminChangeChallengeStatus(
    $challengeId: String!
    $challengeStatus: String!
  ) {
    adminChangeChallengeStatus(
      challengeId: $challengeId
      challengeStatus: $challengeStatus
    ) {
      status
      message
      totalCount
      data {
        urlSlug
        challengeId
        permissions
        roles{
          role
          teamId
          evalutationTeamId
        }
        phaseLinks{
          urlSlug
          phaseTitle
          challengeId
          challengeStatus
        }
        challengeDetails {
          title
          sponsorsName
          challengeType
          prizeAmount
          challengeCategory
          shortDescription
          description
          phaseTitle
          startDate
          endDate
          evaluationCriteriaId
          prizes {
            firstPlace
            secondPlace
          }
          timeline
          data
          rules
          evaluators {
            userId
          }
          judges {
            userId
          }
          participants {
            userId
            teamId
            role
          }
          tempParticipants {
            name
            role
            email
            userId
          }
          status {
            numberOfTeams
            numberOfSolvers
            numberOfSubmissions
            currentPhase
            currentPhaseEndDate
          }
          tags {
            tag
          }
          phases {
            phaseId
            startDate
            endDate
            title
            description
            phaseIcon
          }
          currentPhase{
            phaseId
            phaseTitle
            startDate
            endDate
          }
          faqLastUpdate
          faq {
            faqId
            question
            answer
          }
          solverSelectionType{
            allUsers
            selectedUsers
          }
          challengeImage
          isFeatured
          leaderboard {
            solutionId
            submissionFileName
            submissionFilePath
            solverUserId
            userName
            solverTeamId
            teamName
            averageScore
            variance
            standing
            remarks
          }
          challengeStatus
        }
      }
    }
  }  
  `
	let variables = {
		challengeId: challengeId,
		challengeStatus: challengeStatus
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.adminChangeChallengeStatus)
					throw new Error("Error occured while fetching challenges")
				if (!res.data.adminChangeChallengeStatus.status)
					throw new Error(res.data.adminChangeChallengeStatus.message)
				resolve(res.data.adminChangeChallengeStatus.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getChallengesFiltered = (categories, prizeAscending, solverCountAscending) => {
	let query = `
  query challengesFiltered( $categories: [String] $prizeAscending: Boolean $solverCountAscending: Boolean ){
    challengesFiltered(
      categories: $categories
      prizeAscending: $prizeAscending
      solverCountAscending: $solverCountAscending
    ) {
      status
      message
      totalCount
      data {
        urlSlug
        challengeId
        permissions
        roles{
          role
          teamId
          evalutationTeamId
        }
        phaseLinks{
          urlSlug
          phaseTitle
          challengeId
          challengeStatus
        }
        challengeDetails {
          title
          sponsorsName
          challengeType
          prizeAmount
          challengeCategory
          shortDescription
          description
          phaseTitle
          startDate
          endDate
          evaluationCriteriaId
          prizes {
            firstPlace
            secondPlace
          }
          timeline
          data
          rules
          evaluators {
            userId
          }
          judges {
            userId
          }
          participants {
            userId
            teamId
            role
          }
          tempParticipants {
            name
            role
            email
            userId
          }
          status {
            numberOfTeams
            numberOfSolvers
            numberOfSubmissions
            currentPhase
            currentPhaseEndDate
          }
          tags {
            tag
          }
          phases {
            phaseId
            startDate
            endDate
            title
            description
            phaseIcon
          }
          currentPhase{
            phaseId
            phaseTitle
            startDate
            endDate
          }
          faqLastUpdate
          faq {
            faqId
            question
            answer
          }
          solverSelectionType{
            allUsers
            selectedUsers
          }
          challengeImage
          isFeatured
          leaderboard {
            solutionId
            submissionFileName
            submissionFilePath
            solverUserId
            userName
            solverTeamId
            teamName
            averageScore
            variance
            standing
            remarks
          }
          challengeStatus
        }
      }
    }
  }  
  `
	let variables = {
		categories: categories != undefined ? categories : null,
		prizeAscending: prizeAscending != undefined ? prizeAscending : null,
		solverCountAscending: solverCountAscending != undefined ? solverCountAscending : null
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.challengesFiltered)
					throw new Error("Error occured while fetching challenges")
				if (!res.data.challengesFiltered.status)
					throw new Error(res.data.challengesFiltered.message)
				resolve(res.data.challengesFiltered.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.searchChallengesPaginated = (
	pageNo = 1,
	itemsPerPage = 1000000,
	searchKey = "",
	dataField = null
) => {
	let query = `
  query searchChallenges($pageNo: Int, $itemPerPage: Int, $searchKey: String) {
    searchChallenges(
      pageNo: $pageNo
      itemPerPage: $itemPerPage
      searchKey: $searchKey
    ) {
      status
      message
      totalCount
      data{
        challenges{
          urlSlug
          challengeId
          permissions
          roles{
            role
            teamId
            evalutationTeamId
          }
          phaseLinks{
            urlSlug
            phaseTitle
            challengeId
            challengeStatus
          }
          challengeDetails{
            title
            sponsorsName
            challengeType
            prizeAmount
            challengeCategory
            shortDescription
            description
            phaseTitle
            startDate
            endDate
            evaluationCriteriaId
            prizes{
              firstPlace
              secondPlace
            }
            timeline
            data
            rules
            evaluators{
              userId
            }
            judges{
              userId
            }
            participants{
              userId
              teamId
              role
            }
            tempParticipants{
              name
              role
              email
              userId
            }
            status{
              numberOfTeams
              numberOfSolvers
              numberOfSubmissions
              currentPhase
              currentPhaseEndDate
            }
            tags{
              tag
            }
            phases{
              phaseId
              startDate
              endDate
              title
              description
              phaseIcon
            }
            currentPhase{
              phaseId
              phaseTitle
              startDate
              endDate
            }
            faqLastUpdate
            faq{
              faqId
              question
              answer
            }
            solverSelectionType{
              allUsers
              selectedUsers
            }
            challengeImage
            isFeatured
          }
        }
        totalCount
      }
    }
  }
  `
	if (dataField) {
		query = `
    query searchChallenges($pageNo: Int, $itemPerPage: Int, $searchKey: String) {
      searchChallenges(
        pageNo: $pageNo
        itemPerPage: $itemPerPage
        searchKey: $searchKey
      ) {
        status
        message
        totalCount
        data{
          ${dataField}
        }
      }
    }
    `
	}
	let variables = {
		pageNo: pageNo,
		itemPerPage: itemsPerPage,
		searchKey: searchKey
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.searchChallenges)
					throw new Error("Error occured while fetching challenges")
				if (!res.data.searchChallenges.status) throw new Error(res.data.searchChallenges.message)
				resolve(res.data.searchChallenges.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getFeaturedChallenges = () => {
	let query = `
  {
    getFeaturedChallenges{
      status
      message
      totalCount
      data{
        challengeId
        urlSlug
        permissions
        roles{
          role
          teamId
          evalutationTeamId
        }
        phaseLinks{
          urlSlug
          phaseTitle
          challengeId
          challengeStatus
        }
        challengeDetails{
          title
          sponsorsName
          challengeType
          prizeAmount
          challengeCategory
          shortDescription
          description
          phaseTitle
          startDate
          endDate
          evaluationCriteriaId
          prizes{
            firstPlace
            secondPlace
          }
          timeline
          data
          rules
          evaluators{
            userId
          }
          judges{
            userId
          }
          participants{
            userId
            teamId
            role
          }
          tempParticipants{
            name
            role
            email
            userId
          }
          status{
            numberOfTeams
            numberOfSolvers
            numberOfSubmissions
            currentPhase
            currentPhaseEndDate
          }
          tags{
            tag
          }
          phases{
            phaseId
            startDate
            endDate
            title
            description
            phaseIcon
          }
          currentPhase{
            phaseId
            phaseTitle
            startDate
            endDate
          }
          faqLastUpdate
          faq{
            faqId
            question
            answer
          }
          solverSelectionType{
            allUsers
            selectedUsers
          }
          challengeImage
          isFeatured
          challengeStatus
        }
      }
    }
  }
  `
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken(
				{
					query
				},
				true
			)
			.then((res) => {
				if (!res || !res.data || !res.data.getFeaturedChallenges)
					throw new Error("Error occured while fetching challenges")
				if (!res.data.getFeaturedChallenges.status)
					throw new Error(res.data.getFeaturedChallenges.message)
				resolve(res.data.getFeaturedChallenges.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getChallengeById = (challengeIdOrUrlSlug, dataField = null) => {
	var query = `
    query challenge($challengeIdOrUrlSlug: String!){
      challenge(
        challengeIdOrUrlSlug: $challengeIdOrUrlSlug
      ){
        status
        message
        totalCount
        data{
          urlSlug
          challengeId
          permissions
          roles{
            role
            teamId
            evalutationTeamId
          }
          phaseLinks{
            urlSlug
            phaseTitle
            challengeId
            challengeStatus
          }
          challengeDetails{
            title
            sponsorsName
            challengeType
            prizeAmount
            challengeCategory
            shortDescription
            description
            externalUrl
            phaseTitle
            startDate
            endDate
            evaluationCriteriaId
            prizes{
              firstPlace
              secondPlace
            }
            leaderboard{
              solutionId
              submissionFileName
              submissionFilePath
              solverUserId
              userName
              solverTeamId
              teamName
              averageScore
              variance
              standing
              remarks
            }
            timeline
            data
            rules
            evaluators{
              userId
            }
            judges{
              userId
            }
            participants{
              userId
              teamId
              role
            }
            tempParticipants{
              name
              role
              email
              userId
            }
            status{
              numberOfTeams
              numberOfSolvers
              numberOfSubmissions
              currentPhase
              currentPhaseEndDate
            }
            tags{
              tag
            }
            phases{
              phaseId
              startDate
              endDate
              title
              description
              phaseIcon
            }
            currentPhase{
              phaseId
              phaseTitle
              startDate
              endDate
            }
            faqLastUpdate
            faq{
              faqId
              question
              answer
            }
            solverSelectionType{
              allUsers
              selectedUsers
            }
            challengeImage
            isFeatured
            challengeStatus
            submissionAccessStatus
          }
        }
      }
    }
  `
	if (dataField) {
		query = `
    query challenge($challengeIdOrUrlSlug: String!){
      challenge(
        challengeIdOrUrlSlug: $challengeIdOrUrlSlug
      ){
        status
        message
        totalCount
        data{
          ${dataField}
        }
      }
    }    
    `
	}

	let variables = {
		challengeIdOrUrlSlug: challengeIdOrUrlSlug
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.challenge)
					throw new Error("Error occured while fetching challenge with challenge Id")
				if (!res.data.challenge.status) throw new Error(res.data.challenge.message)
				resolve(res.data.challenge.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getPublicChallengeById = (challengeIdOrUrlSlug, dataField = null) => {
	var query = `
  query publicChallenge($challengeIdOrUrlSlug: String!){
    publicChallenge(
      challengeIdOrUrlSlug: $challengeIdOrUrlSlug
    ){
      status
      message
      data{
        urlSlug
        challengeId
        permissions
        roles{
          role
          teamId
          evalutationTeamId
        }
        phaseLinks{
          urlSlug
          phaseTitle
          challengeId
          challengeStatus
        }
        challengeDetails{
          title
          sponsorsName
          challengeType
          prizeAmount
          challengeCategory
          shortDescription
          description
          phaseTitle
          startDate
          endDate
          evaluationCriteriaId
          leaderboard{
            solutionId
            submissionFileName
            submissionFilePath
            solverUserId
            userName
            solverTeamId
            teamName
            averageScore
            variance
            standing
            remarks
          }
          evaluators{
            userId
          }
          judges{
            userId
          }
          participants{
            userId
            teamId
            role
          }
          tempParticipants{
            name
            role
            email
            userId
          }
          status{
            numberOfTeams
            numberOfSolvers
            numberOfSubmissions
            currentPhase
            currentPhaseEndDate
          }
          tags{
            tag
          }
          isFeatured
          challengeStatus
        }
      }
    }
  }
  `
	if (dataField) {
		query = `
    query publicChallenge($challengeIdOrUrlSlug: String!){
      publicChallenge(
        challengeIdOrUrlSlug: $challengeIdOrUrlSlug
      ){
        status
        message
        totalCount
        data{
          ${dataField}
        }
      }
    }    
    `
	}
	let variables = {
		challengeIdOrUrlSlug: challengeIdOrUrlSlug
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.publicChallenge)
					throw new Error("Error occured while fetching challenge with challenge Id")
				if (!res.data.publicChallenge.status) throw new Error(res.data.publicChallenge.message)
				resolve(res.data.publicChallenge.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.createChallenge = (variables) => {
	let query = `
    mutation createOrUpdateChallenge($prizeAmount: String, $shortDescription: String, $description: String, $title: String!, $sponsorsName: String, $challengeType: String!, $phaseTitle: String, $externalUrl: String ){
      createOrUpdateChallenge(
        input: {
          challengeDetails: {
              prizeAmount: $prizeAmount
              shortDescription: $shortDescription
              description: $description
              title: $title
              sponsorsName: $sponsorsName
              challengeType: $challengeType
              phaseTitle: $phaseTitle
              externalUrl: $externalUrl
          }
        }
      ){
        status
        message
        data{
          urlSlug
          challengeId
        }
      }
    }
  `
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.createOrUpdateChallenge)
					throw new Error("Error occured while updating challenge")
				if (!res.data.createOrUpdateChallenge.status)
					throw new Error(res.data.createOrUpdateChallenge.message)
				resolve(res.data.createOrUpdateChallenge.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.updateChallenge = (variables) => {
	let query = `
    mutation createOrUpdateChallenge($challengeId: String!, $externalUrl: String, $urlSlug: String, $tags: [tagsDataInput], $prizeAmount: String, $title: String, $sponsorsName: String, $shortDescription: String, $description: String, $challengeImage: String, $faq: [faqDataInput], $phases: [phaseDataInput], $evaluationCriteriaId: String, $rules: String, $leaderboard: [leaderboardDataInput] $currentPhase: CurrentPhaseInput $phaseTitle: String $solverSelectionType: SolverSelectionType $challengeType: String){
      createOrUpdateChallenge(
      input:{
          challengeId:$challengeId
          urlSlug: $urlSlug
          challengeDetails:{
              tags:$tags
              prizeAmount:$prizeAmount
              title: $title
              sponsorsName: $sponsorsName
              shortDescription:$shortDescription
              description:$description
              challengeImage:$challengeImage
              faq:$faq
              phases:$phases
              evaluationCriteriaId: $evaluationCriteriaId
              rules:$rules
              leaderboard: $leaderboard
              currentPhase: $currentPhase
              phaseTitle: $phaseTitle
              solverSelectionType: $solverSelectionType
              challengeType: $challengeType
              externalUrl: $externalUrl
          }
      }
      ){
      status
      message
        data{
          urlSlug
          challengeId
        }
      }
    }
  `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.createOrUpdateChallenge)
					throw new Error("Error occured while updating challenge")
				if (!res.data.createOrUpdateChallenge.status)
					throw new Error(res.data.createOrUpdateChallenge.message)
				resolve(res.data.createOrUpdateChallenge.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.createNewPhaseChallenge = (variables) => {
	let query = `
  mutation createNewPhaseFromChallenge(
    $parentChallengeId: String!
    $prizeAmount: String!
    $shortDescription: String
    $description: String
    $title: String!
    $sponsorsName: String!
    $challengeType: String!
    $phaseTitle: String!
    $solverSelectionType: SolverSelectionType!
  ) {
    createNewPhaseFromChallenge(
      parentChallengeId: $parentChallengeId
      phaseBuilderData: {
        prizeAmount: $prizeAmount
        shortDescription: $shortDescription
        description: $description
        title: $title
        sponsorsName: $sponsorsName
        challengeType: $challengeType
        phaseTitle: $phaseTitle
      }
      solverSelectionType: $solverSelectionType
    ) {
      status
      message
      totalCount
      data {
        urlSlug
        challengeId
        permissions
        roles {
          role
          teamId
          evalutationTeamId
        }
        phaseLinks {
          urlSlug
          phaseTitle
          challengeId
          challengeStatus
        }
        challengeDetails {
          title
          sponsorsName
          challengeType
          prizeAmount
          challengeCategory
          shortDescription
          description
          phaseTitle
          startDate
          endDate
          evaluationCriteriaId
          prizes {
            firstPlace
            secondPlace
          }
          leaderboard {
            solutionId
            submissionFileName
            submissionFilePath
            solverUserId
            userName
            solverTeamId
            teamName
            averageScore
            variance
            standing
            remarks
          }
          timeline
          data
          rules
          evaluators {
            userId
          }
          judges {
            userId
          }
          participants {
            userId
            teamId
            role
          }
          tempParticipants {
            name
            role
            email
            userId
          }
          status {
            numberOfTeams
            numberOfSolvers
            numberOfSubmissions
            currentPhase
            currentPhaseEndDate
          }
          tags {
            tag
          }
          phases {
            phaseId
            startDate
            endDate
            title
            description
            phaseIcon
          }
          currentPhase {
            phaseId
            phaseTitle
            startDate
            endDate
          }
          faqLastUpdate
          faq {
            faqId
            question
            answer
          }
          solverSelectionType{
            allUsers
            selectedUsers
          }
          challengeImage
          isFeatured
          challengeStatus
        }
      }
    }
  }  
  `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.createNewPhaseFromChallenge)
					throw new Error("Error occured while creating challenge phase")
				if (!res.data.createNewPhaseFromChallenge.status)
					throw new Error(res.data.createNewPhaseFromChallenge.message)
				resolve(res.data.createNewPhaseFromChallenge.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.updatePhaseById = (variables) => {
	let query = `
      mutation updatePhaseById($challengeId: String!, $phaseId: String!, $startDate: String, $endDate: String, $title: String, $description: String, $phaseIcon: String){
        updatePhaseById(
          challengeId:$challengeId
          phaseId:$phaseId
          startDate:$startDate
          endDate:$endDate
          title: $title
          description:$description
          phaseIcon: $phaseIcon
        ){
          message
          status
          data{
            urlSlug
            challengeDetails{
              phases{
                phaseId
                startDate
                endDate
                title
                description
                phaseIcon
              }
              currentPhase{
                phaseId
                phaseTitle
                startDate
                endDate
              }
            }
          }
        }
      }
  `
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.updatePhaseById)
					throw new Error("Error occured while updating phase by Id")
				if (!res.data.updatePhaseById.status) throw new Error(res.data.updatePhaseById.message)
				resolve(res.data.updatePhaseById.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.setResetFeaturedChallenges = (challengeId, isFeatured) => {
	let query = `
  mutation setResetAsFeatured($challengeId: String $isFeatured: Boolean){
    setResetAsFeatured(
      challengeId: $challengeId
      isFeatured: $isFeatured    
    ) {
      status
      message
      data
      totalCount
    }
  }
  `
	let variables = {
		challengeId: challengeId,
		isFeatured: isFeatured
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.setResetAsFeatured)
					throw new Error("Error occured while updating featured challenges")
				if (!res.data.setResetAsFeatured.status)
					throw new Error(res.data.setResetAsFeatured.message)
				resolve(res.data.setResetAsFeatured.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.joinChallenge = (challengeId, teamId) => {
	let query = `
  mutation joinChallenge($challengeId: String! $teamId: String) {
    joinChallenge(challengeId: $challengeId teamId: $teamId) {
      status
      message
      totalCount
      data {
        challengeId
        participants {
          userId
          role
          teamId
        }
      }
    }
  }
  `
	let variables = {
		challengeId: challengeId,
		teamId: teamId || null
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.joinChallenge)
					throw new Error("Error occured while updating challenge")
				if (!res.data.joinChallenge.status) throw new Error(res.data.joinChallenge.message)
				resolve(res.data.joinChallenge.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.convertSoloJoinToTeamJoin = (challengeId, teamData) => {
	let query = `
  mutation convertFromSoloToTeamJoin(
    $challengeId: String!
    $teamName: String
    $teamDescription: String
    $teamAvatar: String
    $teamAffiliation: String
    $teamMembers: [TeamMemberUserInput]
  ) {
    convertFromSoloToTeamJoin(
      challengeId: $challengeId
      teamData: {
        teamName: $teamName
        teamDescription: $teamDescription
        teamAffiliation: $teamAffiliation
        teamAvatar: $teamAvatar
        teamMembers: $teamMembers
      }
    ) {
      status
      message
      totalCount
      data {
        challengeId
        participants {
          userId
          teamId
          role
        }
      }
    }
  }
  `
	let variables = {
		challengeId: challengeId,
		teamName: teamData.teamName,
		teamDescription: teamData.teamDescription,
		teamAffiliation: teamData.teamAffiliation,
		teamAvatar: teamData.teamAvatar,
		teamMembers: teamData.teamMembers
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.convertFromSoloToTeamJoin)
					throw new Error("Error occured while converting the solo join into a team join")
				if (!res.data.convertFromSoloToTeamJoin.status)
					throw new Error(res.data.convertFromSoloToTeamJoin.message)
				resolve(res.data.convertFromSoloToTeamJoin.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.leaveChallenge = (challengeId) => {
	let query = `
  mutation leaveChallenge($challengeId: String!) {
    leaveChallenge(challengeId: $challengeId) {
      status
      message
    }
  }
  `
	let variables = {
		challengeId: challengeId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.leaveChallenge)
					throw new Error("Error occured while leaving the challenge")
				if (!res.data.leaveChallenge.status) throw new Error(res.data.leaveChallenge.message)
				resolve(res.data.leaveChallenge.status)
			})
			.catch((err) => {
				console.log("Error leaving the challenge, err =", err.message)
				reject(err)
			})
	})
}

challengeServices.removeSolverOrTeam = (challengeId, solverUserId, teamId) => {
	let query = `
  mutation removeSolverOrTeam(
    $challengeId: String!
    $solverUserId: String
    $teamId: String
  ) {
    removeSolverOrTeam(
      challengeId: $challengeId
      solverUserId: $solverUserId
      teamId: $teamId
    ) {
      status
      message
    }
  }  
  `

	let variables = {
		challengeId,
		solverUserId: solverUserId || "",
		teamId: teamId || ""
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.removeSolverOrTeam)
					throw new Error("Error occured while deleting the solver/team")
				if (!res.data.removeSolverOrTeam.status)
					throw new Error(`Error deleting the ${solverUserId ? "solver" : "team"}`)
				resolve(res.data.removeSolverOrTeam.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.deleteFaqById = (challengeId, faqId) => {
	let query = `
    mutation deleteFaqById($challengeId: String!, $faqId:String!){
      deleteFaqById(
        challengeId:$challengeId
        faqId:$faqId
      ){
        status
        message
        totalCount
        data{
          urlSlug
          challengeId
          challengeDetails{
            faqLastUpdate
            faq{
              faqId
              question
              answer
            }
          }
        }
      }
    }
  `
	let variables = {
		challengeId: challengeId,
		faqId: faqId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.deleteFaqById)
					throw new Error("Error occured while deleting faq")
				if (!res.data.deleteFaqById.status) throw new Error(res.data.deleteFaqById.message)
				resolve(res.data.deleteFaqById.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.deletePhaseById = (challengeId, phaseId) => {
	let query = `
    mutation deletePhaseById($challengeId: String!, $phaseId: String!){
      deletePhaseById(
        challengeId:$challengeId
        phaseId:$phaseId
      ){
        status
        message
        data{
          urlSlug
          challengeId
          challengeDetails{
            phases{
              phaseId
              startDate
              endDate
              title
              description
              phaseIcon
            }
            currentPhase{
              phaseId
              phaseTitle
              startDate
              endDate
            }
          }
        }
        totalCount
      }
    }
  `
	let variables = {
		challengeId: challengeId,
		phaseId: phaseId
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.deletePhaseById)
					throw new Error("Error occured while deleting phase")
				if (!res.data.deletePhaseById.status) throw new Error(res.data.deletePhaseById.message)
				resolve(res.data.deletePhaseById.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.deleteChallenge = (challengeId) => {
	let query = `
  mutation deleteChallenge($challengeId: String!) {
    deleteChallenge(challengeId: $challengeId) {
      status
      message
    }
  }
  `

	let variables = {
		challengeId: challengeId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.deleteChallenge)
					throw new Error("Error occured while deleting phase")
				if (!res.data.deleteChallenge.status) throw new Error(res.data.deleteChallenge.message)
				resolve(res.data.deleteChallenge.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.isChallengeTitleUnique = (challengeTitle) => {
	let query = `
  query isChallengeTitleUnique($challengeTitle: String!){
    isChallengeTitleUnique(challengeTitle: $challengeTitle){
      status
      message
      data
      totalCount
    }
  }
  `
	let variables = { challengeTitle: challengeTitle }
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.isChallengeTitleUnique)
					throw new Error("Error occured while deleting phase")
				if (!res.data.isChallengeTitleUnique.status)
					throw new Error(res.data.deletePhaseById.message)
				resolve(res.data.isChallengeTitleUnique.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getAllUsersOfChallenge = (challengeId, rolesArray) => {
	let query = `
  query getAllUsersOfChallenge($challengeId: String!, $roles: [String]) {
    getAllUsersOfChallenge(challengeId: $challengeId, roles: $roles) {
      status
      message
      data {
        challengeTitle
        challengeId
        challengeUrlSlug
        solvers {
          email
          name
          userId
        }
        evaluators {
          email
          name
          userId
        }
        judges {
          email
          name
          userId
        }
        challengeAdmins {
          email
          name
          userId
        }
      }
    }
  }  
  `

	let variables = {
		challengeId: challengeId,
		roles: rolesArray
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getAllUsersOfChallenge)
					throw new Error("Error occured while deleting phase")
				if (!res.data.getAllUsersOfChallenge.status)
					throw new Error(res.data.getAllUsersOfChallenge.message)
				resolve(res.data.getAllUsersOfChallenge.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getAllTeamsByChallenge = (
	challengeId,
	currentPage,
	itemsPerPage,
	searchText = ""
) => {
	let query = `
  query getAllTeamsByChallenge(
    $challengeId: String!
    $currentPage: Int!
    $itemsPerPage: Int!
    $searchText: String
  ) {
    getAllTeamsByChallenge(
      challengeId: $challengeId
      currentPage: $currentPage
      itemsPerPage: $itemsPerPage
      searchText: $searchText
    ) {
      status
      message
      data {
        data {
          id
          teamName
          teamDescription
          teamAvatar
          teamAffiliation
          teamMembers {
            userId
            email
            name
            username
            teamLead
            status
            avatarImagePath
            city
            state
            country
            organizationName
          }
        }
        totalCount
        totalFetched
      }
      totalCount
    }
  }
  `

	let variables = {
		challengeId: challengeId,
		currentPage: currentPage,
		itemsPerPage: itemsPerPage,
		searchText
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getAllTeamsByChallenge)
					throw new Error("Error getting teams")
				if (!res.data.getAllTeamsByChallenge.status)
					throw new Error(res.data.getAllTeamsByChallenge.message)
				resolve(res.data.getAllTeamsByChallenge.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.addSelectedSolversTeams = (variables) => {
	let query = `
  mutation addSelectedSolvers(
    $challengeId: String!
    $soloSolvers: [SolverDataInput!]!
    $teams: [String!]!
  ) {
    addSelectedSolvers(
      challengeId: $challengeId
      soloSolvers: $soloSolvers
      teams: $teams
    ) {
      status
      message
      data {
        urlSlug
        challengeId
        permissions
        roles {
          role
          teamId
          evalutationTeamId
        }
        phaseLinks {
          urlSlug
          phaseTitle
          challengeId
          challengeStatus
        }
        challengeDetails {
          title
          sponsorsName
          challengeType
          prizeAmount
          challengeCategory
          shortDescription
          description
          phaseTitle
          startDate
          endDate
          evaluationCriteriaId
          prizes {
            firstPlace
            secondPlace
          }
          leaderboard {
            solutionId
            submissionFileName
            submissionFilePath
            solverUserId
            userName
            solverTeamId
            teamName
            averageScore
            variance
            standing
            remarks
          }
          timeline
          data
          rules
          evaluators {
            userId
          }
          judges {
            userId
          }
          participants {
            userId
            teamId
            role
          }
          tempParticipants {
            name
            role
            email
            userId
          }
          status {
            numberOfTeams
            numberOfSolvers
            numberOfSubmissions
            currentPhase
            currentPhaseEndDate
          }
          tags {
            tag
          }
          phases {
            phaseId
            startDate
            endDate
            title
            description
            phaseIcon
          }
          currentPhase {
            phaseId
            phaseTitle
            startDate
            endDate
          }
          faqLastUpdate
          faq {
            faqId
            question
            answer
          }
          solverSelectionType{
            allUsers
            selectedUsers
          }
          challengeImage
          isFeatured
          challengeStatus
        }
      }
    }
  }
  `
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.addSelectedSolvers)
					throw new Error("Error occured while deleting phase")
				if (!res.data.addSelectedSolvers.status)
					throw new Error(res.data.addSelectedSolvers.message)
				resolve(res.data.addSelectedSolvers.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.getChallengeParticipantsPaginated = (challengeId, pageNo, itemsPerPage) => {
	let query = `
  query challengeParticipants(
    $pageNo: Int!
    $itemsPerPage: Int!
    $challengeId: String!
  ) {
    challengeParticipants(
      pageNo: $pageNo
      itemsPerPage: $itemsPerPage
      challengeId: $challengeId
    ) {
      status
      message
      data {
        totalCount
        totalFetched
        data {
          userId
          name
          email
          teamName
          teamId
          joinedAt
        }
      }
      totalCount
    }
  }  
  `
	let variables = {
		challengeId: challengeId,
		pageNo: pageNo,
		itemsPerPage: itemsPerPage
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.challengeParticipants)
					throw new Error("Error occured while fetching challenge participants data")
				if (!res.data.challengeParticipants.status)
					throw new Error(res.data.challengeParticipants.message)
				resolve(res.data.challengeParticipants.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

challengeServices.approveSolverByAdmin = async (solverId, adminId, challengeId) => {
	let query = `
  query approveSolverByAdmin(
    $solverId: String!
    $adminId: String!
    $challengeId: String!
  ) {
    approveSolverByAdmin(
      solverId: $solverId
      adminId: $adminId
      challengeId: $challengeId
    ) {
      message
    }
  }  
  `
	let variables = {
		challengeId,
		solverId,
		adminId,
	}

  try {
    const res = await apolloFetcher.fetchWithToken({ query, variables });

    if (!get(res, 'data.approveSolverByAdmin.message')) {
      throw new Error("Error occured while verifying solver");
    }
  } catch (error) {
    throw new Error(error.message);
  }
}

challengeServices.resetPasswordByAdmin = async (solverId, challengeId, email, password) => {
	let query = `
    query resetPasswordByAdmin(
      $solverId: String!
      $challengeId: String!
      $email: String!
      $password: String!
    ) {
      resetPasswordByAdmin(
        solverId: $solverId
        challengeId: $challengeId
        email: $email
        password: $password
      ) {
        message
      }
    }  
  `
	let variables = {
    solverId,
    challengeId,
    email,
    password,
	}

  try {
    await apolloFetcher.fetchWithToken({ query, variables });
  } catch (error) {
    throw new Error(error.message);
  }
}

export default challengeServices
