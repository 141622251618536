import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { Switch, Route } from "react-router-dom"
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar"
import "perfect-scrollbar/css/perfect-scrollbar.css"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx"
import Footer from "components/Footer/Footer.jsx"
import Sidebar from "components/Sidebar/Sidebar.jsx"
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import cccLogo from "assets/img/BIC_Logo.webp"

import routes from "homeRoutes.js"

import appStyle from "assets/jss/appStyle.jsx"
import combineStyles from "utils/combineStyle"
import backgroundImage from "assets/img/BIC-wallpaper.webp"
import image from "assets/img/sidebar-2.jpg"
import logo from "assets/img/logo-white.svg"
import constants from "constants.js"
import { NavLink } from "react-router-dom"
import cookiesHelper from "cookiesHelper"
import apolloFetcher from "../apolloFetcher"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

class Dashboard extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mobileOpen: false,
			miniActive: false,
			image: image,
			color: "blue",
			bgColor: "black",
			hasImage: true,
			fixedClasses: "dropdown"
		}
		this.resizeFunction = this.resizeFunction.bind(this)
		this.ps = null
	}

	componentDidMount() {
		if (navigator.platform.indexOf("Win") > -1) {
			this.ps = new PerfectScrollbar(this.refs.mainPanel, {
				suppressScrollX: true,
				suppressScrollY: false
			})
			document.body.style.overflow = "hidden"
			// setInterval(() => this.ps.update(), 100);
			// hack to solve scrollbar issue while sudden content length shrinks
		}
		window.addEventListener("resize", this.resizeFunction)
		let data = cookiesHelper.get(constants.Settings.LoggedInCookieName)
		if (data) {
			this.props.setLoggedInUserData(data)
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf("Win") > -1) {
			this.ps.destroy()
		}
		window.removeEventListener("resize", this.resizeFunction)
	}
	componentDidUpdate(e) {
		if (e.history.location.pathname !== e.location.pathname) {
			this.refs.mainPanel.scrollTop = 0
			if (this.state.mobileOpen) {
				this.setState({ mobileOpen: false })
			}
		}
	}

	handleImageClick = (image) => {
		this.setState({ image: image })
	}
	handleColorClick = (color) => {
		this.setState({ color: color })
	}
	handleBgColorClick = (bgColor) => {
		this.setState({ bgColor: bgColor })
	}
	handleFixedClick = () => {
		if (this.state.fixedClasses === "dropdown") {
			this.setState({ fixedClasses: "dropdown show" })
		} else {
			this.setState({ fixedClasses: "dropdown" })
		}
	}
	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen })
	}
	getRoute() {
		return this.props.location.pathname !== "/admin/full-screen-maps"
	}

	getLoginURL() {
		return constants.Settings.APIURL + "/login?api_key=" + constants.Settings.C_API_KEY
	}

	getActiveRoute = (routes) => {
		let activeRoute = "Default Brand Text"
		for (let i = 0; i < routes.length; i++) {
			if (routes[i].collapse) {
				let collapseActiveRoute = this.getActiveRoute(routes[i].views)
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute
				}
			} else {
				if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
					return routes[i].name
				}
			}
		}
		return activeRoute
	}

	getRoutes = (routes) => {
		return routes.map((prop, key) => {
			if (prop.collapse) {
				return this.getRoutes(prop.views)
			}
			if (prop.layout === "/dashboard") {
				return <Route exact path={prop.layout + prop.path} component={prop.component} key={key} />
			} else {
				return null
			}
		})
	}

	sidebarMinimize() {
		this.setState({ miniActive: !this.state.miniActive })
	}

	resizeFunction() {
		if (window.innerWidth >= 960) {
			this.setState({ mobileOpen: false })
		}
		this.ps.update()
	}

	render() {
		this.ps && this.ps.update();

		const { classes, ...rest } = this.props;
		const mainPanel =
			classes.mainPanel +
			" " +
			cx({
				[classes.mainPanelSidebarMini]: this.state.miniActive,
				[classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1
			});

		return (
			<div className={`${classes.wrapper} ${classes.dashboardLayoutBackgroundStyle}`}>
				<Sidebar
					routes={routes.filter((route) => route.renderOnSidebar)}
					logoText={"BestInCrowd"}
					logo={logo}
					handleDrawerToggle={this.handleDrawerToggle}
					open={this.state.mobileOpen}
					color={this.state.color}
					bgColor={this.state.bgColor}
					miniActive={this.state.miniActive}
					{...rest}
				/>

				<div className={mainPanel} ref="mainPanel">
					<AdminNavbar
						history={this.props.history}
						sidebarMinimize={this.sidebarMinimize.bind(this)}
						miniActive={this.state.miniActive}
						handleDrawerToggle={this.handleDrawerToggle}
					/>
					<div>
						{this.getRoute() ? (
							<div className={classes.contentDashboardPage}>
								<div className={classes.container}>
									<Switch>{this.getRoutes(routes)}</Switch>
								</div>
							</div>
						) : (
							<div className={classes.map}>
								<Switch>{this.getRoutes(routes)}</Switch>
							</div>
						)}
						<Footer fluid />
					</div>
				</div>
			</div>
		)
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	loggedInUserData: PropTypes.object,
	scrollUpdatedCount: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		scrollUpdatedCount: state.scrollUpdatedCount
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data })
	}
}

let extraStyle = (theme) => ({
	containerBackgroundImage: {
		backgroundImage: "url(" + backgroundImage + ")",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center center",
		backgroundSize: "cover",
		backgroundAttachment: "fixed"
	},
	loginButtonStyle: {
		zIndex: "1032"
	},
	dashboardLayoutBackgroundStyle: {
		backgroundColor: "#FFFFFF"
	}
})

let finalStyle = combineStyles(appStyle, extraStyle)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(finalStyle)(Dashboard))
