import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Button from "@material-ui/core/Button"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ErrorIcon from "@material-ui/icons/Error"
import InfoIcon from "@material-ui/icons/Info"
import CloseIcon from "@material-ui/icons/Close"
import { amber, green } from "@material-ui/core/colors"
import IconButton from "@material-ui/core/IconButton"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import WarningIcon from "@material-ui/icons/Warning"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import userServices from "../../dataServices/userServices"

const variantIcon = {
	success: CheckCircleIcon,
	warning: WarningIcon,
	error: ErrorIcon,
	info: InfoIcon
}

const useStyles1 = makeStyles(theme => ({
	success: {
		backgroundColor: green[600]
	},
	error: {
		backgroundColor: theme.palette.error.dark
	},
	info: {
		backgroundColor: theme.palette.primary.main
	},
	warning: {
		backgroundColor: amber[700]
	},
	icon: {
		fontSize: 20
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: theme.spacing(1)
	},
	message: {
		display: "flex",
		alignItems: "center"
	}
}))

function MySnackbarContentWrapper(props) {
	const classes = useStyles1()
	const { className, message, onClose, variant, ...other } = props
	const Icon = variantIcon[variant]

	return (
		<SnackbarContent
			className={clsx(classes[variant], className)}
			aria-describedby="client-snackbar"
			message={
				<span id="client-snackbar" className={classes.message}>
					<Icon className={clsx(classes.icon, classes.iconVariant)} />
					{message}
				</span>
			}
			action={[
				<Button
					variant="outlined"
					className={classes.margin}
					onClick={e => {
						props.history.push("/dashboard/edit-profile")
						onClose(e)
					}}
				>
					update now
				</Button>,
				<IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
					<CloseIcon className={classes.icon} />
				</IconButton>
			]}
			{...other}
		/>
	)
}

MySnackbarContentWrapper.propTypes = {
	className: PropTypes.string,
	message: PropTypes.string,
	onClose: PropTypes.func,
	history: PropTypes.object,
	variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired
}

const useStyles2 = theme => ({
	margin: {
		margin: theme.spacing(1)
	}
})

class CustomizedSnackbars extends React.Component {
	constructor(props) {
		super(props)
		this.state = { open: false }
	}

	componentDidMount() {
		console.log("Component Mounted")
		userServices
			.getCurrentUser()
			.then(user => {
				if (user.emailInvalid) this.setState({ open: true })
			})
			.catch(err => {
				console.log("Error getting current user, err=", err.message)
			})
	}

	handleClick = () => {
		this.setState({ open: true })
	}

	handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return
		}
		this.setState({ open: false })
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<Snackbar
					anchorOrigin={{
						vertical: "top",
						horizontal: "center"
					}}
					open={this.state.open}
					//autoHideDuration={6000}
					onClose={this.handleClose}
				>
					<MySnackbarContentWrapper
						history={this.props.history}
						onClose={this.handleClose}
						variant="error"
						message="Your email address is not valid. Please go to edit profile page and update your email."
					/>
				</Snackbar>
			</div>
		)
	}
}

export default withStyles(useStyles2)(CustomizedSnackbars)
