import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
//import { Button } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx"
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx"

import Paper from "@material-ui/core/Paper"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import ErrorPage from "views/Pages/ErrorPage"
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"
import ReactHtmlParser from 'html-react-parser'
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import authentication from "../../authentication"
import constant from "../../constants"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import ManageMembersComponent from "./ManageTeam/ManageTeamMembers"
import CardFooter from "components/Card/CardFooter.jsx"

import validator from "validator"
import { Mutation } from "@apollo/client/react/components"
import { gql } from '@apollo/client'

import teamServices from "../../dataServices/teamServices"
import FontOverridePatch from "utils/FontOverridePatch.jsx"

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	}
})

const uploadFileMutation = gql`
	mutation uploadFile($file: Upload!, $uploadType: String!) {
		uploadFile(file: $file, uploadType: $uploadType) {
			status
			message
			totalCount
			data {
				id
				filename
				path
				mimetype
				encoding
			}
		}
	}
`

class JoinChallengePage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: 0,
			challengeId: "",
			urlSlug: "",
			teamId: null,
			userProfile: null,
			challenge: null,
			showModeSelection: false,
			soloJoinSelected: false,
			teamJoinSelected: false,
			soloJoinSuccess: false,
			teamJoinSuccess: false,
			availableRoles: [
				{ name: "Member", value: "teamMember" },
				{ name: "Team Lead", value: "teamLead" }
			],
			teamName: "",
			teamAffiliation: "",
			teamAvatar: "",
			file: null,
			fileName: null
		}
	}

	joinChallenge = () => {
		this.props.setLoadingSpinner()
		authentication
			.hasPermission(constant.Functionalities.CanJoinChallenge)
			.then((res) => {
				if (res == constant.Functionalities.CanJoinChallenge) {
					challengeServices
						.joinChallenge(this.state.challengeId)
						.then((data) => {
							this.props.resetLoadingSpinner()
							this.fetchAllData()
							this.setState({
								soloJoinSuccess: true
							})
						})
						.catch((err) => {
							console.log("Error joining challenge", err.message)
							this.props.resetLoadingSpinner()
							this.props.showAlert("warning", "Oops!", err.message)
						})
				} else {
					this.props.showAlert(
						"warning",
						"Oops!",
						"You don't have the permission to join this challenge"
					)
					this.props.resetLoadingSpinner()
				}
			})
			.catch((err) => {
				console.log("Err =", err.message)
				this.props.showAlert(
					"warning",
					"Oops!",
					"You don't have the permission to join this challenge"
				)
				this.props.resetLoadingSpinner()
			})
	}

	handleOnClickCreateJoinTeam = () => {
		this.props.setLoadingSpinner()
		authentication
			.hasPermission(constant.Functionalities.CanJoinChallenge)
			.then((res) => {
				if (res == constant.Functionalities.CanJoinChallenge) {
					this.setState({ teamJoinSelected: true, showModeSelection: false }, () => {
						this.props.scrollUpdate()
					})
					this.props.resetLoadingSpinner()
				} else {
					this.props.showAlert(
						"warning",
						"Oops!",
						"You don't have the permission to join this challenge"
					)
					this.props.resetLoadingSpinner()
				}
			})
			.catch((err) => {
				console.log("Error = ", err.message)
				this.props.showAlert(
					"warning",
					"Oops!",
					"You don't have the permission to join this challenge"
				)
				this.props.resetLoadingSpinner()
			})
	}

	createTeamAndJoin = () => {
		let amITeamLead = true
		let teamData = {
			teamName: this.state.teamName,
			teamAffiliation: this.state.teamAffiliation,
			teamAvatar: this.state.teamAvatar,
			teamMembers: this.state.selectedUsers.map((user) => {
				if (user.role === "teamLead") amITeamLead = false
				return {
					userId: user.userId,
					email: user.email,
					name: user.userId ? user.label : "Invitee User",
					teamLead: user.role === "teamLead"
				}
			})
		}
		teamData.teamMembers.push({
			userId: this.state.userProfile.userId,
			email: this.state.userProfile.email,
			name: this.state.userProfile.name,
			teamLead: amITeamLead
		})

		teamServices
			.createOrUpdateTeam(teamData)
			.then((data) => {
				if (!data) throw new Error("Error creating team")
				this.setState({ teamId: data.id })
				return challengeServices.joinChallenge(this.state.challengeId, data.id)
			})
			.then((data) => {
				if (!data) throw new Error("Error joining challenge as team")
				this.props.history.push("/dashboard/challenge-details/" + this.state.urlSlug)
			})
			.catch((err) => {
				console.log("Error in create and join team", err.message)
				this.props.showAlert("error", "Oops!", err.message)
			})
	}

	fetchCurrentUserProfile = () => {
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log("Error getting current User", err.message)
				return null
			})
	}

	getChallengeById = (challengeIdOrUrlSlug) => {
		return challengeServices
			.getChallengeById(challengeIdOrUrlSlug)
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log("error getting challenge by challenge Id", err.message)
				return null
			})
	}

	fetchAllData = () => {
		let challengeIdOrUrlSlug = this.state.challengeId || this.state.urlSlug
		this.props.setLoadingSpinner()
		Promise.all([this.fetchCurrentUserProfile(), this.getChallengeById(challengeIdOrUrlSlug)]).then(
			(result) => {
				this.props.resetLoadingSpinner()
				let userProfile = result[0]
				let challenge = result[1]

				if (userProfile && challenge) {
					let soloJoinSuccess = false
					let teamJoinSuccess = false

					if (challenge.roles.find((r) => r.role == "solver")) {
						soloJoinSuccess = true
					}
					if (
						challenge.roles.find((r) => r.role == "solverForTeam") ||
						challenge.roles.find((r) => r.role == "teamLead")
					)
						teamJoinSuccess = true

					if (soloJoinSuccess || teamJoinSuccess) {
						this.props.history.push("/dashboard/challenge-details/" + challenge.urlSlug)
					} else if (challenge.challengeDetails.challengeStatus !== "active") {
						this.props.showAlert("warning", "Oops!", "This challenge is not active now")
						this.props.history.push("/dashboard/index")
					} else if (
						challenge.challengeDetails.solverSelectionType &&
						!challenge.challengeDetails.solverSelectionType.allUsers
					) {
						this.props.showAlert("warning", "Oops!", "This challenge is for selected users only")
						this.props.history.push("/dashboard/index")
					} else if (!challenge.permissions.includes("CanJoinChallenge")) {
						this.props.showAlert("warning", "Oops!", "You are not eligible to join this challenge")
						this.props.history.push("/dashboard/index")
					}

					this.setState({
						challenge: challenge,
						challengeId: challenge.challengeId,
						urlSlug: challenge.urlSlug,
						showModeSelection: true,
						userProfile: userProfile,
						soloJoinSuccess: soloJoinSuccess,
						teamJoinSuccess: teamJoinSuccess
					})
				} else {
					this.setState({
						challenge: null,
						showModeSelection: false
					})
					this.props.setRedirectOnError("/dashboard/index")
				}
			}
		)
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props

		if (validator.isUUID(params.challengeId)) {
			this.setState({ challengeId: params.challengeId }, () => {
				this.fetchAllData()
			})
		} else {
			this.setState({ urlSlug: params.challengeId }, () => {
				this.fetchAllData()
			})
		}
	}

	searchUserPromise = (searchText) => {
		//do relevant filtering here if required
		return userServices.searchUserForJoinTeam(searchText, this.state.challengeId)
	}

	setManagePeopleData = (data, onSaveDone) => {
		this.setState({ selectedUsers: data.selectedUsers })
		if (onSaveDone && typeof onSaveDone === "function") onSaveDone()
	}

	isAllDataValid = () => {
		const { teamName, selectedUsers } = this.state
		if (teamName && selectedUsers && selectedUsers.length > 0) return true
		return false
	}

	handleFileChange = (e) => {
		e.preventDefault()
		let reader = new FileReader()
		let file = e.target.files[0]
		if (!file) {
			this.setState({ file: null, fileName: "" })
			return
		}
		reader.onloadend = () => {
			this.setState({
				file: { file },
				fileName: file.name
			})
		}
		reader.readAsDataURL(file)
	}

	uploadFile = (mutation) => {
		if (this.state.file) {
			this.props.setLoadingSpinner()

			mutation({
				mutation: uploadFileMutation,
				variables: { ...this.state.file, uploadType: "image" },
				fetchPolicy: "no-cache",
				context: {
				  headers: {
					'x-apollo-operation-name': 'upload-file'
				  }
				},
			})
				.then((res) => {
					this.props.resetLoadingSpinner()
					if (res.data.uploadFile.status == true) {
						let filePath = res.data.uploadFile.data.path
						this.setState({ teamAvatar: filePath })
					} else {
						throw new Error(res.data.uploadFile.message)
					}
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					this.props.showAlert("error", "Error!", err.message)
					console.log("err = ", err.message)
				})
		} else {
			this.props.showAlert("error", "Error!", "No file is selected")
		}
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<div>
					{this.state.challenge && (
						<div>
							<SimpleBreadcrumbs
								breadLinkData={[
									{ name: "Dashboard", link: "/dashboard/index" },
									{ name: "Join Challenge", link: "#" }
								]}
							/>
							{this.showModeSelection()}
							{this.showTeamCreateUI()}
						</div>
					)}
				</div>
			</div>
		)
	}

	showModeSelection = () => {
		if (!this.state.showModeSelection) return null
		if (this.state.showModeSelection) {
			return (
				<GridContainer container direction="row" justify="center" alignItems="center">
					<GridItem xs={12} sm={12} md={8} lg={6}>
						<Card raised>
							<CardHeader>
								<h3>
									Welcome to <strong>{this.state.challenge.challengeDetails.title} </strong>
								</h3>
							</CardHeader>
							<CardBody>
								<div style={{ borderLeft: "5px solid blue", paddingLeft: "3px" }}>
									<strong> Summary: </strong>
									{this.state.challenge.challengeDetails.shortDescription}
								</div>
								<br />
								<div style={{ borderLeft: "5px solid orange", paddingLeft: "3px" }}>
									<strong> Description: </strong>
									<FontOverridePatch>
										{ReactHtmlParser(this.state.challenge.challengeDetails.description || "")}
									</FontOverridePatch>
								</div>
								<Button
									disabled={this.state.soloJoinSuccess || this.state.teamJoinSuccess}
									fullWidth
									color={this.state.soloJoinSuccess ? "success" : "youtube"}
									onClick={() => {
										this.joinChallenge()
									}}
								>
									{this.state.soloJoinSuccess ? "Already Joined" : "I want to join individually"}
								</Button>
								<Button
									disabled={this.state.soloJoinSuccess || this.state.teamJoinSuccess}
									fullWidth
									color="pinterest"
									onClick={() => this.handleOnClickCreateJoinTeam()}
								>
									I want to create my own Team
								</Button>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			)
		}
	}

	showTeamCreateUI = () => {
		if (!this.state.teamJoinSelected || this.state.showModeSelection) return null
		const { classes } = this.props
		return (
			<div>
				<GridContainer container direction="row" justify="center" alignItems="center">
					<GridItem xs={12} sm={12} md={10} lg={8}>
						<Card raised>
							<CardHeader>
								<h3>
									Create Team to join{" "}
									<strong> {this.state.challenge.challengeDetails.title} </strong>
								</h3>
							</CardHeader>
							<CardBody>
								<Paper>
									<div style={{ margin: "20px" }}>
										<CustomInput
											labelText="Team Name"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												name: "teamName",
												value: this.state.teamName,
												onChange: (e) => this.setState({ teamName: e.target.value })
											}}
										/>
										<CustomInput
											labelText="Team Affiliation"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												name: "teamAffiliation",
												value: this.state.teamAffiliation,
												onChange: (e) => this.setState({ teamAffiliation: e.target.value })
											}}
										/>
										<CustomInput
											inputProps={{
												type: "file",
												name: "teamAvatar",
												onChange: this.handleFileChange
											}}
										/>
										<Mutation mutation={uploadFileMutation} fetchPolicy="no-cache" context={{ headers: { 'x-apollo-operation-name': 'upload-file' } }}>
											{(mutation, { loading }) => (
												<Button color="rose" round onClick={() => this.uploadFile(mutation)}>
													Upload
												</Button>
											)}
										</Mutation>
									</div>
								</Paper>
								<ManageMembersComponent
									title={null}
									availableRoles={this.state.availableRoles}
									searchUserPromise={this.searchUserPromise}
									setManagePeopleData={this.setManagePeopleData}
								/>
							</CardBody>
							<CardFooter>
								<Button
									square
									color="youtube"
									onClick={() => {
										this.setState({ showModeSelection: true, teamJoinSelected: false })
									}}
								>
									Cancel
								</Button>
								<Button
									square
									color="success"
									onClick={this.createTeamAndJoin}
									disabled={!this.isAllDataValid()}
								>
									Save & Join
								</Button>
							</CardFooter>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

JoinChallengePage.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	scrollUpdate: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		showAlert: (alertType, title, description) =>
			dispatch({ type: actionTypes.SHOW_ALERT, payload: { alertType, title, description } }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		scrollUpdate: () => dispatch({ type: actionTypes.CONTENT_HEIGHT_UPDATED })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(JoinChallengePage))
