import apolloFetcher from "apolloFetcher"

let emailUnsubServices = {}

emailUnsubServices.unsubscribeChallengeEmailUpdates = (challengeId, email) => {
	let query = `
  mutation unsubscribeFromChallengeUpdate(
    $challengeId: String!
    $emailAddress: String!
  ) {
    unsubscribeFromChallengeUpdate(
      challengeId: $challengeId
      emailAddress: $emailAddress
    ) {
      status
      message
    }
  }
  `
	let variables = {
		challengeId: challengeId,
		emailAddress: email
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables }, true)
			.then(res => {
				if (!res || !res.data || !res.data.unsubscribeFromChallengeUpdate)
					throw new Error("Error occured while fetching challenges")
				if (!res.data.unsubscribeFromChallengeUpdate.status)
					throw new Error(res.data.challenges.message)
				resolve(res.data.unsubscribeFromChallengeUpdate.status)
			})
			.catch(err => {
				reject(err)
			})
	})
}

export default emailUnsubServices
