/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React from "react"
import PropTypes from "prop-types"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import Icon from "@material-ui/core/Icon"
import Tooltip from "@material-ui/core/Tooltip"

// @material-ui/icons
import Face from "@material-ui/icons/Face"
import Email from "@material-ui/icons/Email"
import Code from "@material-ui/icons/Code"
import Cloud from "@material-ui/icons/Cloud"
import Business from "@material-ui/icons/Business"
import Gavel from "@material-ui/icons/Gavel"
// import LockOutline from "@material-ui/icons/LockOutline";
import Warning from "@material-ui/icons/Warning"
import DateRange from "@material-ui/icons/DateRange"
import LocalOffer from "@material-ui/icons/LocalOffer"
import Update from "@material-ui/icons/Update"
import ArrowUpward from "@material-ui/icons/ArrowUpward"
import AccessTime from "@material-ui/icons/AccessTime"
import Refresh from "@material-ui/icons/Refresh"
import Edit from "@material-ui/icons/Edit"
import Place from "@material-ui/icons/Place"
import ArtTrack from "@material-ui/icons/ArtTrack"
import Language from "@material-ui/icons/Language"
import CircularProgress from "@material-ui/core/CircularProgress"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import NavPills from "components/NavPills/NavPills.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import cccLogo from "assets/img/BIC_Logo.webp"
import Table from "components/Table/Table.jsx"
import Danger from "components/Typography/Danger.jsx"
import CardIcon from "components/Card/CardIcon.jsx"

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx"
import commonAppStyle from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import defaultChallengeImage from "../../assets/img/defaultChallengeImage.webp"
import authentication from "../../authentication"
import priceImage1 from "assets/img/card-2.jpeg"
import priceImage2 from "assets/img/card-3.jpeg"
import priceImage3 from "assets/img/card-1.jpeg"
import constant from "../../constants"
import Accordion from "components/Accordion/Accordion"
import apolloFetcher from "../../apolloFetcher"
import combineStyles from "utils/combineStyle"
import cookiesHelper from "../../cookiesHelper"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import challengeServices from "../../../src/dataServices/challengeServices"
import ReactHtmlParser from 'html-react-parser'
import userServices from "dataServices/userServices.js"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

import { useTheme } from "@material-ui/core/styles"

import PhaseUtils from "utils/phaseUtils"
import challengeUtils from "utils/challengeUtils"
import FontOverridePatch from "utils/FontOverridePatch"

const flexContainer = {
	display: "flex",
	flexDirection: "row",
	padding: 0
}

let extraStyle = (theme) => {
	console.log("theme = ", theme)
	return {
		loginButtonStyle: {
			zIndex: "1032"
		},
		currentPhaseStyle: {
			borderTop: "3px solid #FF6859",
			borderBottom: "3px solid #FF6859",
			margin: "20px 20px 20px 20px",
			padding: "5px 10px 5px 10px",
			fontSize: "1.3em",
			display: "block",
			color: theme.palette.text.page, //"#FFFFFF",
			textAlign: "center",
			overflowWrap: "break-word",
			height: "55px",
			overflow: "hidden",
			textOverflow: "ellipsis",
			fontWeight: "400"
		},
		textOverflowEllipsisStyleForChallengeTitle: {
			overflowWrap: "break-word",
			height: "125px",
			overflow: "hidden",
			textOverflow: "ellipsis",
			fontSize: "1.4em",
			textAlign: "center"
		},
		landingHeadingStyle: {
			margin: "100px 0px 60px 0px",
			fontFamily: "Roboto Condensed",
			fontSize: "2em",
			fontWeight: "normal",
			color: theme.palette.text.heading, //"#1EB980",
			//color: "#FFFFFF",
			//fontWeight: "400",
			textShadow: `2px 10px 10px ${theme.palette.text.heading}`
		},
		joinOrStartChallengeHeader: {
			backgroundColor: "#72DEFF"
		},
		joinOrStartChallengeBody: {
			backgroundColor: theme.palette.background.cardBody //"#302F2F"
		},
		cardContentText: {
			color: theme.palette.text.cardBody, //"#FFFFFF",
			textAlign: "center",
			fontWeight: "400"
		},
		challengeCoverImage: {
			height: "250px",
			width: "100%",
			objectFit: "contain"
		}
	}
}

class LandingPage extends React.Component {
	constructor(props) {
		super(props)
		// we use this to make the card to appear after the page has been rendered
		this.state = {
			cardAnimaton: "",
			challenges: null,
			hasViewChallengePermission: false,
			userProfile: null
		}
	}

	fetchCurrentUserProfile = () => {
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				console.log("Current User", data)
				return data
			})
			.catch((err) => {
				console.log("Error getting current User", err)
				return null
			})
	}

	fetchFeatureChallenges = () => {
		return challengeServices
			.getFeaturedChallenges()
			.then((data) => {
				if (!data || data.length == 0) {
					throw new Error("No featured challenges found")
				}
				return data
			})
			.catch((err) => {
				console.log("error fetching featured challenges: ... ", err.message)
				return challengeServices
					.getAllChallenges({ [constant.CHALLENGE_STATUS.ACTIVE]: true })
					.then((challenges) => {
						if (!challenges || challenges.length == 0) throw new Error("Error fetching challenges")
						let offset = challenges.length > 3 ? challenges.length - 3 : 0
						let data = challenges.slice(offset)
						return data
					})
					.catch((err) => {
						console.log("error fetching challenges:", err.message)
						return null
					})
			})
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		Promise.all([this.fetchCurrentUserProfile(), this.fetchFeatureChallenges()])
			.then((result) => {
				console.log("multiple api calls ", result)
				console.log("Both APIs call okay")
				this.props.resetLoadingSpinner()
				let userProfile = result[0] ? result[0] : null
				let featureChallenges = result[1] ? (result[1] ? result[1] : []) : []
				this.setState({
					challenges: featureChallenges,
					userProfile: userProfile
				})
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error fetching all data: ... ", err.message)
			})
	}

	componentDidMount() {
		console.log("Component did mount of landing page")
		this.props.resetBreadCrumbStack()
		this.fetchAllData()
	}

	componentWillUnmount() {}

	handleOnClickLearnMore = (challengeId) => {
		if (!this.props.loggedInUserData) {
			this.props.history.push("/home/challenge-details/" + challengeId)
		} else {
			this.props.history.push("/dashboard/challenge-details/" + challengeId)
		}
	}

	handleOnClickJoinChallenge = (challengeId) => {
		if (!this.state.userProfile) {
			console.log("Login first to join a challenge!")
			this.props.setAfterLoginRedirectUrl("/dashboard/join-challenge/" + challengeId)
			this.props.openLoginModal()
			return
		}
		this.props.history.push("/dashboard/join-challenge/" + challengeId)
		return
	}

	getLoginURL() {
		return constant.Settings.APIURL + "/login?api_key=" + constant.Settings.C_API_KEY
	}

	displayChallenge() {
		const { classes } = this.props
		let cardColor = "gmgTheme"
		var challenges = this.state.challenges
		if (!challenges || !challenges.length) {
			return (
				<div style={{ margin: "30px auto" }}>
					<GridContainer justify="center" alignItems="flex-start">
						<GridItem>
							<h5> No Challenges Found </h5>
						</GridItem>
					</GridContainer>
				</div>
			)
		}
		if (challenges.length > 3) {
			challenges.splice(3)
		}
		var challenegesView = challenges.map((challenge) => {
			let isJoinedAlready = false
			let canJoinChallenge = true

			if (this.state.userProfile) {
				canJoinChallenge = false
				if (
					challenge.roles.find((r) => {
						return (
							r.role == "superAdmin" ||
							r.role == "licenseeAdmin" ||
							r.role == "challengeAdmin" ||
							r.role == "evaluator" ||
							r.role == "judge"
						)
					})
				) {
					canJoinChallenge = false
				} else if (
					challenge.permissions.find((item) => item == constant.Functionalities.CanJoinChallenge)
				) {
					canJoinChallenge = true
				} else {
					canJoinChallenge = false
				}

				let solvers = challenge.challengeDetails.participants
				if (canJoinChallenge && solvers) {
					solvers.map((user) => {
						if (user.userId === this.state.userProfile.userId) isJoinedAlready = true
					})
				}
			}

			let currentPhase = "" //PhaseUtils.getCurrentPhase(challenge.challengeDetails.phases)
			let startDate = "",
				endDate = ""
			if (challenge.challengeDetails.currentPhase) {
				currentPhase = challenge.challengeDetails.currentPhase.phaseTitle
				startDate = challenge.challengeDetails.currentPhase.startDate
				endDate = challenge.challengeDetails.currentPhase.endDate
			}
			return (
				<GridItem key={challenge.challengeId} xs={12} sm={6} md={4} lg={4}>
					<Card
						product
						raised={true}
						className={classes.joinOrStartChallengeBody}
						color={cardColor}
					>
						<CardHeader
							color={cardColor}
							className={classes.textOverflowEllipsisStyleForChallengeTitle}
						>
							{challenge.challengeDetails.title}
						</CardHeader>
						<CardBody>
							<img
								width={"100%"}
								className={classes.challengeCoverImage}
								src={constant.Settings.APIURL + challenge.challengeDetails.challengeImage}
								alt="Image of challenge cover"
								onError={(event) => {
									event.target.src = defaultChallengeImage
								}}
							/>

							<p className={classes.currentPhaseStyle}>{currentPhase}</p>

							<Accordion
								//landingPage={true}
								collapses={[
									{
										title: (
											<div
												style={{
													height: "23px",
													overflow: "hidden",
													textOverflow: "ellipsis"
												}}
											>
												{challenge.challengeDetails.shortDescription.substr(0, 30)}
											</div>
										),
										content: (
											<div
												style2={{
													paddingLeft: "5px",
													width: "100%",
													backgroundColor: "#302F2F",
													color: "#FFFFFF"
												}}
											>
												<p className={classes.cardProductDesciprion}>
													<FontOverridePatch>
														{ReactHtmlParser(challenge.challengeDetails.description || "")}
													</FontOverridePatch>
												</p>
												<p className={classes.cardProductDesciprion}>
													Prize Amount:{" "}
													{challengeUtils.getPrizeFormatted(challenge.challengeDetails.prizeAmount)}
												</p>
												{startDate && (
													<p className={classes.cardProductDesciprion}>Start Date: {startDate}</p>
												)}
												{endDate && (
													<p className={classes.cardProductDesciprion}>End Date: {endDate}</p>
												)}
												{challenge.challengeDetails.sponsorsName && (
													<p className={classes.cardProductDesciprion}>
														By{" "}
														{challenge.challengeDetails.sponsorsName
															? challenge.challengeDetails.sponsorsName
															: "Not available"}
													</p>
												)}
											</div>
										)
									}
								]}
							/>
						</CardBody>
						<CardFooter className={classes.justifyContentCenter}>
							<div className={classes.center} style={{ height: "70px", alignContent: "center" }}>
								<Button
									style={{ width: "100px", marginRight: "10px" }}
									color="info"
									size="sm"
									onClick={() => this.handleOnClickLearnMore(challenge.urlSlug)}
								>
									Learn more
								</Button>
								{(canJoinChallenge || !this.props.loggedInUserData) &&
								challenge.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.ACTIVE ? (
									<Button
										// style={{ backgroundColor: "#B15DFF", width: "100px" }}
										color="gmgGreen900"
										disabled={isJoinedAlready}
										size="sm"
										onClick={() => this.handleOnClickJoinChallenge(challenge.urlSlug)}
									>
										{isJoinedAlready ? "Already Joined" : "Join"}
									</Button>
								) : null}
							</div>
						</CardFooter>
					</Card>
				</GridItem>
			)
		})

		return (
			<GridContainer direction="row" justify="space-evenly" alignItems="flex-start">
				{challenegesView}
			</GridContainer>
		)
	}

	render() {
		const { classes } = this.props
		let cardColor = "gmgTheme"
		return (
			<div className={classes.landingContentArea}>
				<div className={classes.container}>
					<GridContainer justify="center">
						<GridItem xs={12} sm={4} md={4}>
							<Card
								login
								className={classes.joinOrStartChallengeBody}
								raised={true}
								color={cardColor}
							>
								<CardHeader className={`${classes.textCenter}`} color={cardColor}>
									<h3 className={classes.cardContentText}>Join Challenge</h3>
								</CardHeader>
								<CardBody style={{ height: "140px" }}>
									<h5 className={classes.cardContentText}>As the network for challenges</h5>
									<h5 className={classes.cardContentText}>find the one for you</h5>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<div className={classes.center}>
										<Button
											color={"gmgGreen900"}
											//style={{ backgroundColor: "#B15DFF" }}
											onClick={() => this.props.history.push("/home/challenges")}
										>
											Join Challenge
										</Button>
									</div>
								</CardFooter>
							</Card>
						</GridItem>

						<GridItem xs={12} sm={4} md={4}>
							<Card
								login
								className={classes.joinOrStartChallengeBody}
								raised={true}
								color={cardColor}
							>
								<CardHeader className={`${classes.textCenter}`} color={cardColor}>
									<h3 className={classes.cardContentText}>Start Challenge</h3>
								</CardHeader>
								<CardBody style={{ height: "140px" }}>
									<h5 className={classes.cardContentText}>Leverage our network of</h5>
									<h5 className={classes.cardContentText}>solvers</h5>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<div className={classes.center}>
										<Button
											color="gmgGreen900"
											//style={{ backgroundColor: "#B15DFF" }}
											onClick={() => this.props.history.push("/home/pricing")}
										>
											Start Challenge
										</Button>
									</div>
								</CardFooter>
							</Card>
						</GridItem>
					</GridContainer>

					<GridContainer direction="column" justify="center" alignItems="center">
						<GridItem xs={12} sm={12} md={12} justify="center">
							{/* <div style={{boxShadow: "0px 6px 30px #000000", borderRadius:"20px", padding: "0px 8px 0px 8px"}}>
                <h3 className={`${classes.landingHeadingStyle} `}>Featured Challenges</h3>
              </div> */}
							<h3 className={`${classes.landingHeadingStyle} `}>Featured Challenges</h3>
							<p style={{ marginBottom: "20px" }}> </p>
						</GridItem>
						<GridItem xs={12} sm={12} md={12}>
							<div>
								<div id="challengeList">{this.displayChallenge()}</div>
							</div>
						</GridItem>
						<GridItem xs={12} sm={4} md={4}>
							<div className={classes.center}>
								<Button
									style={{
										boxShadow: "0px 6px 30px #000000",
										backgroundColor: "#b15dff",
										marginBottom: "40px"
									}}
									onClick={() => {
										this.props.history.push("/home/challenges")
									}}
								>
									VIEW ALL
								</Button>
							</div>
						</GridItem>
					</GridContainer>
				</div>
			</div>
		)
	}
}

LandingPage.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	openLoginModal: PropTypes.func.isRequired,
	closeLoginModal: PropTypes.func.isRequired,
	setAfterLoginRedirectUrl: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		themeMode: state.theme
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE }),
		setAfterLoginRedirectUrl: (url) =>
			dispatch({ type: actionTypes.AFTER_LOGIN_REDIRECT_URL_SET, payload: url })
	}
}

let finalStyle = combineStyles(loginPageStyle, commonAppStyle, extraStyle)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(finalStyle)(LandingPage))
