import cookiesHelper from "./cookiesHelper"
import constant from "./constants"
import { createApolloFetch } from "apollo-fetch"
import userServices from "dataServices/userServices"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import constants from "./constants"

const fetchPublic = createApolloFetch({
	uri: `${constant.Settings.APIURL}/graphql/?api_key=${constant.Settings.C_API_KEY}`
})

const fetchWithToken = (operation, allowFallbackFetchPublic = false) => {
	try {
		let loggedInUserAccessToken = cookiesHelper.get(constants.Settings.AccessTokenCookieName)
		var access_token = loggedInUserAccessToken.access_token
	} catch (err) {
		console.log("Error getting access_token in cookies", err.message)
		access_token = null
	}

	if (access_token) {
		const fetch2 = createApolloFetch({
			uri: `${constant.Settings.APIURL}/graphql/?api_key=${constant.Settings.C_API_KEY}`
		})
		fetch2.use(({ request, options }, next) => {
			if (!options.headers) {
				options.headers = {
					access_token: access_token
				}
			}
			options.headers["authorization"] = "created token"
			next()
		})
		return fetch2(operation)
	} else {
		let loggedInUserData
		let refres_token
		try {
			loggedInUserData = cookiesHelper.get(constants.Settings.LoggedInCookieName)
			refres_token = loggedInUserData.refresh_token
		} catch (err) {
			refres_token = null
		}

		if (refres_token == null && allowFallbackFetchPublic) {
			console.log("AccessToken Issue! Try fallback public fetch")
			return fetchPublic(operation)
		} else if (refres_token == null) {
			throw new Error("You are not logged in")
		} else {
			return userServices
				.reAuthenticateUser(refres_token)
				.then((data) => {
					console.log(JSON.stringify({ access_token: data.access_token }))
					let expiresInHour = data.expires_in / 3600
					cookiesHelper.setAccessToken(
						constants.Settings.AccessTokenCookieName,
						{ access_token: data.access_token },
						"/",
						expiresInHour
					)
					const fetch2 = createApolloFetch({
						uri: `${constant.Settings.APIURL}/graphql/?api_key=${constant.Settings.C_API_KEY}`
					})
					fetch2.use(({ request, options }, next) => {
						if (!options.headers) {
							options.headers = {
								access_token: data.access_token
							}
						}
						options.headers["authorization"] = "created token"
						next()
					})
					return fetch2(operation)
				})
				.catch((err) => {
					throw new Error(err.message)
				})
		}
	}
}

function tokenValidator(access_token) {
	const fetch2 = createApolloFetch({
		uri: `${constant.Settings.APIURL}/graphql/?api_key=${constant.Settings.C_API_KEY}`
	})
	fetch2.use(({ request, options }, next) => {
		if (!options.headers) {
			options.headers = {
				access_token: access_token
			}
		}
		options.headers["authorization"] = "created token"
		next()
	})

	let query = `
        query{
            getCurrentUser{
                status
                message
                data{
                    userId
                    username
                }
                totalCount
            }
        }
    `
	return fetch2({ query })
		.then((res) => {
			if (!res || !res.data || !res.data.getCurrentUser || !res.data.getCurrentUser.status)
				throw new Error("Error getting current user")
			return true
		})
		.catch((err) => {
			return false
		})
}

export default { fetchPublic: fetchPublic, fetchWithToken: fetchWithToken }
