import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Card from "components/Card/Card"
import { Grid } from "@material-ui/core"
import Slider from "@material-ui/core/Slider"

import combineStyles from "utils/combineStyle"
import commonStyles from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../../helper"
import constants from "../../../constants"
import FormRenderReadOnly from "./FormRenderReadOnly"
import SetScoresSliderComments from "./SetScoreSliderComments"

function valuetext(value) {
	return `${value}`
}
/*
classes
evaluationCriteria
solutions
currentSolution
scores
submissionForm
maxScore
commentTexts
slidersValues
*/

class SetScoringUI extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			updatedCount: 0
		}
	}

	componentDidUpdate(prevProps) {
		if (
			JSON.stringify(this.props.currentSolution.formData) !=
			JSON.stringify(prevProps.currentSolution.formData)
		) {
			this.setState({ updatedCount: this.state.updatedCount + 1 })
		}
	}

	render() {
		if (!this.props.evaluationCriteria || !this.props.solutions || !this.props.solutions.length)
			return null
		const { classes } = this.props
		let scoringCompleted = true
		this.props.scores.map((s) => {
			if (!s || !s.id) scoringCompleted = false
		})

		let submissionTitle =
			this.props.currentSolution.solutionDetails.title ||
			helper.getSubmissionName(
				this.props.currentSolution.solutionDetails.userName,
				this.props.currentSolution.solutionDetails.teamName,
				this.props.currentSolution.solutionId
			)

		let isFormView = false
		let isFileView = false
		let isError = false
		if (this.props.currentSolution.formData && this.props.submissionForm) isFormView = true
		if (this.props.currentSolution.formData && !this.props.submissionForm) isError = true
		if (!this.props.currentSolution.formData) isFileView = true

		return (
			<div>
				<Card className={classes.card}>
					<CardContent>
						<Typography color="textPrimary" gutterBottom>
							{this.props.currentSolution.solutionDetails.teamName
								? "Team: " + this.props.currentSolution.solutionDetails.teamName
								: "Solver: " + this.props.currentSolution.solutionDetails.userName}
						</Typography>
						<Typography color="textPrimary" gutterBottom>
							Submission: {submissionTitle}
						</Typography>
						<Typography className={classes.pos} color="textSecondary">
							{isFormView && (
								<div>
									<span> Submitted solution form content/files: </span>
									<FormRenderReadOnly
										submissionForm={this.props.submissionForm}
										formData={this.props.currentSolution.formData}
									/>
								</div>
							)}

							{isFileView && (
								<div>
									<span>Submitted file/content can be found </span>
									<span>
										<a
											target="_blank"
											href={
												constants.Settings.APIURL +
												this.props.currentSolution.solutionDetails.submissionFiles
											}
										>
											{" "}
											here{" "}
										</a>
									</span>
								</div>
							)}

							{isError && (
								<div>Submission form is missing! You can't evaluate this solution right now</div>
							)}
						</Typography>
					</CardContent>
				</Card>
				{!isError && (
					<SetScoresSliderComments
						classes={classes}
						scoringCompleted={scoringCompleted}
						currentSolution={this.props.currentSolution}
						commentTexts={this.props.commentTexts}
						slidersValues={this.props.slidersValues}
						evaluationCriteria={this.props.evaluationCriteria}
						maxScore={this.props.maxScore}
						handleSubmitScore={this.props.handleSubmitScore}
						isSubmitting={this.props.isSubmitting}
						handleOnClickNotify={this.props.handleOnClickNotify}
					/>
				)}
			</div>
		)
	}
}

export default SetScoringUI
