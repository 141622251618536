import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Grid } from '@material-ui/core';

import RoleSidebar from './RoleSidebar';

import { getUserChallengesByAdmin } from 'dataServices/AdminPageServices/users';

import styles from "./styles.module.scss";
import actionTypes from 'reduxjs/actionTypes';
import { rolesName } from './constants';

const USER_ROLE_NOT_HAVE_CHALLENGE = ["generalUser", "licenseeAdmin", "admin", "superAdmin"];

const ShowUserRole = props => {
  const { userId, roles, setLoadingSpinner, resetLoadingSpinner } = props;

  const [selectedRole, setSelectedRole] = useState();
  const [challenges, setChallenges] = useState();
  const [loading, setLoading] = useState(false);

  const fetchUserChallenges = async () => {
    if (USER_ROLE_NOT_HAVE_CHALLENGE.includes(selectedRole) || !selectedRole) {
      return;
    }

    setLoading(true);
    setLoadingSpinner()

    try {
      const { status, data } = await getUserChallengesByAdmin(userId, selectedRole);
      
      setChallenges(data);
      setLoading(false)
      resetLoadingSpinner();
    } catch (error) {
      setLoading(false);
      resetLoadingSpinner();
    }
  };

  const renderNoChallenge = () => {
    return (
      <div className={styles.rolesShower}>
        No challenges found
      </div>
    );
  };

  const renderChallenge = challenge => {
    return (
      <Grid item xs>
        <div className={styles.challengeCard}>
          <h3>{challenge.title}</h3>
          <div style={{ marginTop: "8px" }}>{`Phase : ${challenge.phase}`}</div>
          <div style={{ marginTop: "8px" }}>{`Total participants : ${challenge.participantCount}`}</div>
          {challenge.sponsorsName && <div style={{ marginTop: "8px" }}>{`Sponsor : ${challenge.sponsorsName}`}</div>}
          <div style={{ marginTop: "8px" }}>{`Status : ${challenge.challengeStatus}`}</div>
          <div style={{ marginTop: "8px" }}>{`Prize amount : ${challenge.prizeAmount}`}</div>
        </div>
      </Grid>
    );
  }

  const renderChallenges = () => {
    return (
      <Grid container spacing={4} style={{ margin: "0px 5px 0px 7px", overflowY: "auto" }}>
        {(challenges || []).map((challenge, index) => renderChallenge(challenge))}
      </Grid>
    );
  };

  const renderRolesInfo = () => {
    if (loading) {
      return null;
    }

    if (USER_ROLE_NOT_HAVE_CHALLENGE.includes(selectedRole)) {
      return (
        <div className={styles.rolesShower}>
          Your are a {rolesName[selectedRole]}
        </div>
      );
    }

    return (challenges || []).length == 0 ? renderNoChallenge() : renderChallenges();
  };

  useEffect(() => {
    if (!USER_ROLE_NOT_HAVE_CHALLENGE.includes(selectedRole)) {
      fetchUserChallenges();
    }
  }, [selectedRole]);

  useEffect(() => {
    if ((roles || []).length >= 1) {
      setSelectedRole(roles[0]);
    }
  }, [roles]);

  return (
    <div
      className={`${styles.wrapper}`}
      style={
        (USER_ROLE_NOT_HAVE_CHALLENGE.includes(selectedRole) || (challenges ||[]).length==0)
          ? {alignItems:"center"}
          : {}
      }
    >
      <RoleSidebar
        roles={roles}
        onClickRole={role => setSelectedRole(role)}
      />
      {renderRolesInfo()}
    </div>
  );
};

ShowUserRole.propTypes = {
  userId: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

ShowUserRole.defaultProps = {};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
    setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShowUserRole);
