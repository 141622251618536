const CHALLENGE_ADMIN = 'challengeAdmin';
const ADMIN_ROLE_ARRAY = ['superAdmin', 'licenseeAdmin', 'admin'];

export const getIsUserChallengeAdmin = (roles, challengeId = null) => {
  let isAdmin = false;

  (roles || []).forEach(role => {
    if (CHALLENGE_ADMIN == role.RoleId) {
      if (challengeId) {
        if (challengeId == role.challengeId) {
          isAdmin = true;
        }
      } else {
        isAdmin = true;
      }
    }
  });

  return isAdmin;
};

export const getIsUserAdmin = (roles) => {
  let isAdmin = false;

  (roles || []).forEach(role => {
    if (ADMIN_ROLE_ARRAY.includes(role.RoleId)) {
      isAdmin = true;
    }
  });

  return isAdmin;
};