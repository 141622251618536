import apolloFetcher from "apolloFetcher"

let services = {}

services.saveNotification = (notificationData) => {
	let query = `
  mutation notification($notificationData: NotificationInput!) {
    notification(notificationData: $notificationData) {
      status
      message
      totalCount
      data {
        id
        notificationType
        notificationDetails {
          notificationText
          challengeId
          challengeUrlSlug
          teamId
          evaluationTeamId
        }
        to {
          userId
          name
          email
          username
        }
        from {
          userId
          name
          email
          username
        }
        isRead
        isDeleted
        createdAt
      }
    }
  }
  `

	let variables = {
		notificationData: {
			id: notificationData.id || null,
			notificationType: notificationData.notificationType,
			notificationDetails: {
				notificationText: notificationData.notificationDetails.notificationText,
				challengeId: notificationData.notificationDetails.challengeId || null,
				evaluationTeamId: notificationData.notificationDetails.evaluationTeamId || null,
				teamId: notificationData.notificationDetails.teamId || undefined
			},
			to: { userId: notificationData.to.userId },
			isRead: notificationData.isRead || false,
			isDeleted: notificationData.isDeleted || false
		}
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.notification)
					throw new Error("Error occured while saving the notification")
				if (!res.data.notification.status) throw new Error(res.data.notification.message)
				resolve(res.data.notification.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.fetchNotifications = (currentPage, itemsPerPage) => {
	let query = `
  query notificationsByUser($pagination: PaginationInput!) {
    notificationsByUser(pagination: $pagination) {
      status
      message
      totalCount
      data {
        notifications {
          id
          notificationType
          notificationDetails {
            notificationText
            challengeId
            challengeUrlSlug
            evaluationTeamId
            teamId
          }
          from {
            userId
            name
            email
            username
            avatarImagePath
          }
          to {
            userId
            name
            email
            username
            avatarImagePath
          }
          isRead
          isDeleted
          createdAt
        }
        totalCount
        totalFetched
      }
    }
  }
  `

	let variables = {
		pagination: {
			currentPage: currentPage,
			itemsPerPage: itemsPerPage
		}
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.notificationsByUser)
					throw new Error("Error occured while fetching notifications for current user")
				if (!res.data.notificationsByUser.status)
					throw new Error(res.data.notificationsByUser.message)
				if (!res.data.notificationsByUser.data)
					throw new Error("An error occured whil fetching the notifications")
				resolve(res.data.notificationsByUser.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.fetchUnreadNotifications = () => {
	let query = `
  query notificationsUnreadByUser{
    notificationsUnreadByUser {
      status
      message
      totalCount
      data {
        id
        notificationType
        notificationDetails {
          notificationText
          challengeId
          challengeUrlSlug
          teamId
          evaluationTeamId
        }
        to {
          userId
          name
          email
          username
        }
        from {
          userId
          name
          email
          username
        }
        isRead
        isDeleted
        createdAt
      }
    }
  }
  `
	let variables = {}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.notificationsUnreadByUser)
					throw new Error("Error occured while fetching notifications for current user")
				if (!res.data.notificationsUnreadByUser.status)
					throw new Error(res.data.notificationsUnreadByUser.message)
				resolve(res.data.notificationsUnreadByUser.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.deleteAllNotification = () => {
	let query = `
  mutation{
    deleteAllNotification{
      status
      message
    }
  }
  `
	let variables = {}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.deleteAllNotification)
					throw new Error("Error occured while deleting notifications")
				if (!res.data.deleteAllNotification.status)
					throw new Error(res.data.deleteAllNotification.message)
				resolve(res.data.deleteAllNotification.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.deleteNotificationById = (notificationId) => {
	let query = `
  mutation deleteNotificationById($notificationId: String!) {
    deleteNotificationById(notificationId: $notificationId) {
      status
      message
    }
  }
  `
	let variables = { notificationId: notificationId }

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.deleteNotificationById)
					throw new Error("Error occured while deleting the notification")
				if (!res.data.deleteNotificationById.status)
					throw new Error(res.data.deleteNotificationById.message)
				resolve(res.data.deleteNotificationById.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export default services
