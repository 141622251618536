import React from 'react';
import PropTypes from "prop-types";

import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Modal, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import cssStyles from './styles.module.scss';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '10px',
  },
  body: {
    padding: "15px 10px 15px 10px",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: "10px 0px 10px 10px",
    borderBottom: "1px solid #E6E6E6"
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    marginRight: "20px"
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
});

const CustomModal = (props) => {
  const {
    children,
    title,
    visible,
    onClose,
  } = props;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") { 
        return;
    }

    onClose();
  };

  const DialogTitle = withStyles(styles)((props) => {
    const {
      titleText,
      classes,
      onClose,
      ...other
    } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <div className={classes.container}>
          <Typography variant="h6">{ titleText }</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </MuiDialogTitle>
    );
  });

  return (
    <Modal
      open={visible}
      onClose={handleClose}
      aria-labelledby="custom-modal"
    >
      <div style={modalStyle} className={classes.paper}>
        <DialogTitle onClose={handleClose} titleText={title} />
        <div className={`${classes.body} ${cssStyles.modalContainer}`}>{ children }</div>
      </div>
    </Modal>
  );
}

CustomModal.propTypes = {
  title: PropTypes.string,
	visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

CustomModal.defaultProps = {
  title: "",
  visible: false,
};

export default CustomModal;
