import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import { withStyles, withTheme } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Input } from "@material-ui/core"
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx"
import EditIcon from "@material-ui/icons/EditRounded"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Accordion from "components/Accordion/Accordion.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Chip from "@material-ui/core/Chip"
import Paper from "@material-ui/core/Paper"
import CloseIcon from "@material-ui/icons/Close"

import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"

import ErrorPage from "views/Pages/ErrorPage"
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"
import CardFooter from "components/Card/CardFooter"
import commonStyles from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import combineStyles from "utils/combineStyle"

import Pagination from "material-ui-flat-pagination"
import { fade } from "@material-ui/core/styles/colorManipulator"

import moment from "moment"
import validator from "validator"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../helper"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import notificationServices from "dataServices/notificationServices"
import authentication from "authentication.js"
import constants from "constants.js"
import cookieHelper from "cookiesHelper"
import dummyAvatar from "assets/img/faces/dummy-avatar-300x300.webp"
import { NavLink } from "react-router-dom"

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

class NotificationsPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			notifications: [],
			totalCount: 0,
			totalFetched: 0,
			currentPage: 1,
			itemsPerPage: 10,
			isDataFetchDone: false,
			showErrorPage: false,
			errorMessage: "",
			isOpenConfirmClearAll: false,
			isConfirmClearAll: false
		}
	}

	fetchCurrentUserProfile = () => {
		this.props.setLoadingSpinner()
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				this.props.resetLoadingSpinner()
				console.log("current user ", data)
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting current User", err)
				throw err
			})
	}

	fetchNotifications = (currentPage, itemsPerPage) => {
		return notificationServices
			.fetchNotifications(currentPage, itemsPerPage)
			.then((data) => {
				console.log("notifications = ", data)
				this.setState({
					notifications: data.notifications,
					totalCount: data.totalCount,
					totalFetched: data.totalFetched
				})
				return data
			})
			.catch((err) => {
				console.log("Error fetching notifications, err =", err.message)
				this.setState({ notifications: [] })
				return []
			})
	}

	renderConfirmClearAllModal = () => {
		if (!this.state.isOpenConfirmClearAll) return null
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isOpenConfirmClearAll: false })}
			>
				<DialogTitle> Are you sure? </DialogTitle>
				<DialogContent>
					<DialogContentText>Do you really want to clear all the notifications?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.setState({ isOpenConfirmClearAll: false })} color="tumbler">
						No
					</Button>
					<Button
						onClick={() =>
							this.setState({ isOpenConfirmClearAll: false, isConfirmClearAll: true }, () => {
								this.deleteAllNotification()
							})
						}
						color="youtube"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	componentDidMount() {
		this.props.setLoadingSpinner()
		helper.setPageTitle("Notifications | BestInCrowd")
		this.fetchCurrentUserProfile()
			.then((user) => {
				if (!user) throw new Error("Please login to see this page")
				return this.fetchNotifications(this.state.currentPage, this.state.itemsPerPage)
			})
			.then((data) => {
				this.props.resetLoadingSpinner()
				this.setState({ isDataFetchDone: true })
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.setState({
					showErrorPage: true,
					errorMessage: err.message
				})
			})
	}

	onClickNotificationButton = (notification) => {
		let redirectTo =
			"/dashboard/challenge-details/" + notification.notificationDetails.challengeUrlSlug

		if (notification.notificationType === constants.NOTIFICATION_TYPE.EVALUATION_COMPLETED) {
			redirectTo = "/dashboard/submissions/" + notification.notificationDetails.challengeUrlSlug
		} else if (
			notification.notificationType === constants.NOTIFICATION_TYPE.SUBMISSIONS_EVALUATED
		) {
			redirectTo =
				"/dashboard/submission-scoring/" + notification.notificationDetails.challengeUrlSlug
		}

		let unreadNotifCount = this.props.notificationCount
		if (!notification.isRead) unreadNotifCount = Math.max(unreadNotifCount - 1, 0)
		let notificationData = { ...notification, isRead: true }
		notificationServices
			.saveNotification(notificationData)
			.then((data) => {
				console.log("data = ", data)
			})
			.catch((err) => {
				console.log("Error updating the notification is read status, err=", err.message)
			})
		this.props.setNotificationCount(unreadNotifCount)
		this.props.setRedirectOnError(redirectTo)
	}

	handleOnClickPaginateButton = (offset) => {
		this.setState({ currentPage: offset / this.state.itemsPerPage + 1 }, () => {
			this.props.setLoadingSpinner()
			this.fetchNotifications(this.state.currentPage, this.state.itemsPerPage)
				.then((data) => {
					this.props.resetLoadingSpinner()
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
				})
		})
	}

	deleteAllNotification = () => {
		if (!this.state.isConfirmClearAll) {
			this.setState({ isOpenConfirmClearAll: true })
			return
		}
		this.setState({ isConfirmClearAll: false })
		this.props.setLoadingSpinner()
		notificationServices
			.deleteAllNotification()
			.then((res) => {
				if (!res) throw new Error("Error occurred while clearing notifications")
				return this.fetchNotifications(this.state.currentPage, this.state.itemsPerPage)
			})
			.then((data) => {
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("err = ", err.message)
				this.props.showAlert("error", "Oops!", "Error occurred while clearing notifications")
			})
	}

	deleteNotificationById = (notifId) => {
		this.props.setLoadingSpinner()
		notificationServices
			.deleteNotificationById(notifId)
			.then((res) => {
				if (!res) throw new Error("Error occurred while deleting the notification")
				return this.fetchNotifications(this.state.currentPage, this.state.itemsPerPage)
			})
			.then((data) => {
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("Error deleting notifs = ", err.message)
				this.props.resetLoadingSpinner()
			})
	}

	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	render() {
		const { classes } = this.props
		if (!this.state.isDataFetchDone) return <div> Loading... </div>
		let limit = this.state.itemsPerPage
		let offset = Math.max(this.state.currentPage - 1, 0) * limit
		let total = this.state.totalCount
		const paginationClasses = {
			colorInheritCurrent: classes.colorInheritCurrent,
			colorInheritOther: classes.colorInheritOther
		}

		return (
			<div>
				{this.renderConfirmClearAllModal()}
				<Card color="gmgTheme">
					<CardHeader color="gmgTheme">
						<h4> Notifications </h4>
					</CardHeader>
					<CardBody color="gmgTheme">
						<div>
							{this.state.notifications.length == 0 && (
								<div className={classes.notificationZero}>
									<p> No notification yet </p>
								</div>
							)}
							{this.state.notifications.length != 0 && (
								<div>
									<GridContainer direction="row" justify="space-between" alignItems="center">
										<GridItem></GridItem>
										<GridItem>
											<Button color="danger" onClick={this.deleteAllNotification}>
												Clear All
											</Button>
										</GridItem>
									</GridContainer>
								</div>
							)}
							<div>
								{this.state.notifications.map((notification) => {
									return (
										<div
											className={classnames({
												[classes.notificationDiv]: true,
												[classes.unreadNotificationClass]: !notification.isRead
											})}
											onClick={() => {
												this.onClickNotificationButton(notification)
											}}
										>
											<GridContainer>
												<GridItem xs={4} sm={1} md={1} lg={1}>
													<img
														src={constants.Settings.APIURL + notification.from.avatarImagePath}
														style={{
															height: "50px",
															width: "50px",
															borderRadius: "50%"
														}}
														onError={(e) => (e.target.src = dummyAvatar)}
													/>
												</GridItem>
												<GridItem xs={8} sm={11} md={11} lg={11}>
													<GridContainer
														direction="row"
														alignItems="center"
														justify="space-between"
													>
														<GridItem lg={11} md={11} sm={10}>
															<p> {notification.notificationDetails.notificationText} </p>
															<p> </p>
															<span
																style={{
																	padding: "5px"
																}}
															>
																On {moment(notification.createdAt).toLocaleString()}
															</span>
														</GridItem>
														<GridItem lg={1} md={1} sm={2}>
															<Button
																color="danger"
																size="sm"
																onClick={(e) => {
																	e.stopPropagation()
																	this.deleteNotificationById(notification.id)
																}}
															>
																<CloseIcon />
															</Button>
														</GridItem>
													</GridContainer>
												</GridItem>
											</GridContainer>
										</div>
									)
								})}
							</div>
						</div>
					</CardBody>
					<CardFooter>
						{this.state.notifications.length != 0 && (
							<GridContainer direction="row" alignItems="center" justify="center">
								<GridItem sm={12} md={12} lg={12}>
									<div className={classes.paginationDiv}>
										<Pagination
											classes={paginationClasses}
											currentPageColor="inherit"
											otherPageColor="inherit"
											size={"large"}
											limit={limit}
											offset={offset}
											total={total}
											onClick={(e, offset) => this.handleOnClickPaginateButton(offset)}
										/>
									</div>
								</GridItem>
							</GridContainer>
						)}
					</CardFooter>
				</Card>
			</div>
		)
	}
}

NotificationsPage.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack,
		notificationCount: state.notificationCount,
		themeMode: state.theme
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: { alertType, title, description }
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		setNotificationCount: (count) =>
			dispatch({ type: actionTypes.SET_NOTIFICATION_COUNT, payload: count })
	}
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	notificationZero: {
		width: "100%",
		height: "auto",
		margin: "10px",
		padding: "10px",
		color: theme.palette.text.page,
		backgroundColor: theme.palette.background.page,
		border: "2px solid grey"
	},
	notificationDiv: {
		width: "100%",
		height: "auto",
		margin: "10px",
		padding: "10px",
		color: theme.palette.text.page,
		backgroundColor: theme.palette.background.page,
		border: "2px solid grey",
		"&:hover": {
			border: "2px solid green",
			cursor: "pointer"
		}
	},
	paginationDiv: {
		width: "100%",
		//border: `1px solid ${theme.palette.background.cardBody}`,
		color: theme.palette.text.page,
		backgroundColor: theme.palette.background.page
	},
	colorInheritCurrent: {
		margin: theme.spacing(0.5),
		color: theme.palette.text.page,
		backgroundColor: fade("#00FF00", 0.5),
		"&:hover": {
			backgroundColor: fade("#00FF00", theme.palette.action.hoverOpacity)
		}
	},
	colorInheritOther: {
		margin: theme.spacing(0.5),
		color: theme.palette.text.page,
		//backgroundColor: "#FF0000",
		"&:hover": {
			backgroundColor: fade("#FF0000", theme.palette.action.hoverOpacity)
		}
	},
	unreadNotificationClass: {
		//fontWeight: "bold !important",
		backgroundColor: `${fade(theme.palette.background.cardHeader, 0.1)} !important`
	}
})

let finalStyle = combineStyles(styles, commonStyles)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyle)(withTheme(NotificationsPage)))
