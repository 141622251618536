import React from "react"
import { Form } from "react-formio"

class FormRenderReadOnly extends React.Component {
	constructor(props) {
		console.log("props = ", props)
		super(props)
		this.state = {
			submissionForm: this.props.submissionForm,
			formData: this.props.formData
		}
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(this.props.formData) != JSON.stringify(prevProps.formData)) {
			this.setState({ formData: this.props.formData })
		}
	}

	render() {
		return (
			<div className="formio">
				<Form
					form={{
						display: this.state.submissionForm.formData.display,
						components: this.state.submissionForm.formData.components
					}}
					submission={{ ...this.state.formData }}
					options={{ readOnly: true, viewAsHtml: true }}
				/>
			</div>
		)
	}
}

export default FormRenderReadOnly
