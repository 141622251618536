import { blackColor, whiteColor } from "assets/jss/material-dashboard-pro-react.jsx"

import backgroundImage from "assets/img/BIC-wallpaper.webp"
//import backgroundImage from "assets/img/BIC-Logo-Tagline-Background-Web.png"

const commonAppStyle = (theme) => ({
	containerBackgroundImage: {
		backgroundImage: "url(" + backgroundImage + ")",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center center",
		backgroundSize: "cover",
		height: "600px",
		objectFit: "fill"
	},
	landingDesc_Content: {
		color: whiteColor
	},
	landingDesc_Title: {
		fontFamily: "Roboto Condensed",
		fontWeight: "bold",
		textAlign: "center",
		fontSize: "45px"
	},
	landingDesc_Is: {
		fontFamily: "Roboto Condensed",
		fontWeight: "bold",
		textAlign: "center",
		fontSize: "45px",
		margin: "-30px 0px 0px 0px"
	},
	landingDesc_Desc: {
		//fontFamily: 'Roboto Condensed',
		fontWeight: "normal",
		color: whiteColor,
		//fontSize: "20px",
		margin: "0px 0px 0px 0px"
	},
	landingDesc_Heading: {
		fontWeight: "bold",
		textAlign: "center"
	},
	marginTop100: {
		marginTop: "100px"
	},
	landingContentArea: {
		backgroundColor: theme.palette.background.page, //"#121212",
		paddingTop: "50px"
	},
	richTextEditorStyle: {
		minHeight: "400px",
		overflow: "auto"
	},
	cccEditorStyle: {
		border: "1px solid whitesmoke",
		padding: "5px"
	},
	chip: {
		margin: "5px"
	}
})

export default commonAppStyle
