import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import SweetAlert from "react-bootstrap-sweetalert"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import challengeServices from "dataServices/challengeServices"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import { Redirect, NavLink } from "react-router-dom"
import Constants from "../../constants.js"
import authentication from "authentication.js"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Build from "@material-ui/icons/Build"
import CircularProgress from "@material-ui/core/CircularProgress"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { Tooltip } from "@material-ui/core"
import CustomDropdownMenu from "components/CustomDropdown/CustomDropdownMenu.jsx"

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	)
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	bottomNavigationBarStyle: {
		backgroundColor: "#C3FDFF",
		color: "#101316"
	},
	hoverTwitterColor: {
		margin: "0px 5px 0px 5px",
		borderRadius: "3px",
		"&:hover,&:focus": {
			backgroundColor: "#3daeff"
		}
	}
})

class DropdownMenu extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			anchorEl: null
		}
	}

	componentDidMount() {}

	handleMenuClick = (event) => {
		if (this.state.anchorEl) {
			this.setState({
				anchorEl: null
			})
			event.target.style.backgroundColor = "#FFFFFF"
		} else {
			this.setState({
				anchorEl: event.currentTarget
			})
			event.target.style.backgroundColor = "#3daeff"
		}
	}

	handleMenuHover = (event) => {
		this.setState({
			anchorEl: event.currentTarget
		})
		event.target.style.backgroundColor = "#3daeff"
	}

	handleManuClose = () => {
		this.setState({
			anchorEl: null
		})
	}

	handleMenuOut = (event) => {
		this.setState({
			anchorEl: null
		})
		event.target.style.backgroundColor = "#FFFFFF"
	}

	handleClickOnItems = (event) => {
		// console.log("clicked on: ", event.target.textContent)
		if (!this.props.handleClickOnItems) {
			console.log("Please provide handleClickOnItems(itemName) function as props")
			return
		}
		this.props.handleClickOnItems(event.target.textContent)
	}

	render() {
		const { classes, dropdownList, buttonText, buttonIcon } = this.props
		if (!dropdownList || dropdownList.length == 0) {
			console.log("Please provide dropdownList array with dropdown item's names as props")
			return null
		}
		return (
			<div>
				<CustomDropdownMenu
					title={buttonText}
					handleMenuClick={this.handleMenuClick}
					handleMenuHover={this.handleMenuHover}
					handleMenuOut={this.handleMenuOut}
					challengeId={"challengeId"}
					handleClickOnItems={this.handleClickOnItems}
					hoverColor="warning"
					buttonIcon={buttonIcon}
					buttonText={buttonText}
					buttonProps={{
						round: true,
						style: { marginBottom: "0" },
						color: "twitter",
						title: buttonText
					}}
					dropdownList={dropdownList}
					onClick={(event) => {
						console.log("Click event = ", event)
					}}
				/>
			</div>
		)
	}
}

DropdownMenu.propTypes = {
	classes: PropTypes.object.isRequired,
	buttonText: PropTypes.string,
	buttonIcon: PropTypes.element,
	dropdownList: PropTypes.arrayOf(String).isRequired,
	handleClickOnItems: PropTypes.func.isRequired
}

export default withStyles(styles)(DropdownMenu)
