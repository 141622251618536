import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Assignment from "@material-ui/icons/Assignment"
import Person from "@material-ui/icons/Person"
import Edit from "@material-ui/icons/Edit"
import Close from "@material-ui/icons/Close"
import userServices from "dataServices/userServices"
import teamServices from "dataServices/teamServices"
import challengeServices from "dataServices/challengeServices"
import manageChallengeServices from "dataServices/manageChallengeServices"
import { withStyles } from "@material-ui/core/styles"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import combineStyles from "utils/combineStyle"
import Table from "components/Table/Table.jsx"
import SimpleBreadcrumbs from "components/SimpleBreadcumbs.jsx"
import Constants from "constants.js"
import authentication from "authentication.js"
import ErrorPage from "../ErrorPage"
import querystring from "querystring"
import cookieHelper from "cookiesHelper"
import { Grid } from "@material-ui/core"
import constants from "constants.js"

class AcceptRejectTeamInvitation extends Component {
	constructor(props) {
		super(props)
		this.state = {
			canViewThisPage: true,
			showErrorComponent: false,
			teamId: "",
			teamName: "",
			code: "",
			isLoading: true,
			errorMessage: null,
			isAccepted: false,
			isRejected: false,
			errorInAccepting: false,
			name: "",
			email: "",
			userId: "",
			role: "",
			teamLead: false,
			username: ""
		}
	}

	checkPermission = () => {
		let permissionsObject = [
			{ permissionName: Constants.Functionalities.CanAcceptOrRejectTeamInvitation, objectId: null }
		]
		authentication
			.hasMultiplePermissions(permissionsObject)
			.then((res) => {
				let permissionOkay = true
				if (res && res.length > 0) {
					res.map((object) => {
						permissionOkay = permissionOkay && object.permissionStatus
					})
				} else {
					permissionOkay = false
				}
				if (!permissionOkay) {
					this.setState({ showErrorComponent: true })
				}
			})
			.catch((err) => {
				this.setState({ showErrorComponent: true })
			})
	}

	getTeamInvitationStatus = () => {
		return teamServices
			.getTeamInvitationStatus(this.state.teamId, this.state.code)
			.then((data) => {
				console.log("team inv data = ", data)
				return data
			})
			.catch((err) => {
				console.log("Error fetching team invitation user, err =", err.message)
				return null
			})
	}

	fetchCurrentUserProfile = () => {
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				console.log("Current User", data)
				return data
			})
			.catch((err) => {
				console.log("Error getting current User", err.message)
				return null
			})
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		Promise.all([this.getTeamInvitationStatus(), this.fetchCurrentUserProfile()])
			.then((result) => {
				console.log("multiple api call response = ", result)
				if (!result[0] || !result[1]) {
					this.setState({
						showErrorComponent: true,
						isLoading: false,
						errorMessage: result[0] ? null : "Invalid invitation link"
					})
				}
				this.setState({
					teamId: result[0].teamId,
					teamName: result[0].teamName,
					status: result[0].status,
					teamLead: result[0].teamLead,
					userId: result[1].userId,
					email: result[1].email,
					name: result[1].name,
					username: result[1].username,
					isLoading: false
				})
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.setState({ isLoading: false, showErrorComponent: true })
				this.props.resetLoadingSpinner()
			})
	}

	acceptOrReject = (isAccepted) => {
		this.props.setLoadingSpinner()
		teamServices
			.acceptOrRejectTeamInvitation(this.state.teamId, this.state.code, isAccepted)
			.then((data) => {
				if (!data) throw new Error("Error accepting team invitation")
				this.setState({ isAccepted: isAccepted, status: isAccepted ? "ACTIVE" : "REJECTED" })
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("Error accept/reject(ing) team invitation, err = ", err.message)
				this.props.resetLoadingSpinner()
			})
	}

	componentDidMount() {
		let loggedInUserData = cookieHelper.get(constants.Settings.LoggedInCookieName)
		if (!loggedInUserData) {
			console.log("User is not logged in, So opening loginModal")
			this.setState({ isLoading: false })
			this.props.setAfterLoginRedirectUrl(window.location.href)
			this.props.openLoginModal()
			return
		}
		let qParams = querystring.parse(this.props.location.search.slice(1))
		this.setState({ teamId: qParams.team, code: qParams.code }, () => {
			this.fetchAllData()
			// this.checkPermission();
			console.log("state = ", this.state)
		})
	}

	render() {
		const { classes } = this.props
		if (this.state.showErrorComponent) {
			return (
				<ErrorPage
					errorCode={403}
					message={this.state.errorMessage || "Access Denied :("}
					description={
						this.state.errorMessage
							? "Invitation link is not valid or may be expired"
							: "You are not logged in, please login to see this page"
					}
				/>
			)
		}
		if (this.state.isLoading) {
			console.log("isLoading")
			return <div> </div>
		}

		return (
			<div>
				{/* <SimpleBreadcrumbs
                    breadLinkData={this.props.breadCrumbStack}
                /> */}
				<br />
				<br />
				<GridContainer direction="row" justify="center" alignItems="flex-start">
					<GridItem xs={12} sm={12} md={8} lg={6}>
						<Card raised>
							<CardHeader color="rose" icon>
								<CardIcon color="rose">
									<Assignment />
								</CardIcon>
								<h3 className={classes.cardIconTitle}>
									{" "}
									Invitation to join team <strong>{this.state.teamName}</strong> team{" "}
								</h3>
							</CardHeader>
							<CardBody>
								<GridContainer direction="row" justify="space-around" alignItems="center">
									<GridItem>{this.renderInvitationConfirmUI()}</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		)
	}

	renderInvitationConfirmUI = () => {
		const { classes } = this.props
		if (this.state.status == "PENDING") {
			return (
				<div>
					<h5>
						{" "}
						You have been invited to join the <strong>{this.state.teamName}</strong> team{" "}
					</h5>

					<Grid container direction="row" alignContent="space-around">
						<Grid item sm="6">
							<Button square color="success" onClick={() => this.acceptOrReject(true)}>
								Accept
							</Button>
						</Grid>
						<Grid item sm="6">
							<Button square color="danger" onClick={() => this.acceptOrReject(false)}>
								Reject
							</Button>
						</Grid>
					</Grid>
				</div>
			)
		} else if (this.state.status == "ACTIVE") {
			return (
				<div>
					<h5> Invitation accepted! </h5>
				</div>
			)
		} else if (this.state.status == "REJECTED") {
			return (
				<div>
					<h5> Invitation rejected! </h5>
				</div>
			)
		}
	}
}

AcceptRejectTeamInvitation.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE }),
		setAfterLoginRedirectUrl: (url) =>
			dispatch({ type: actionTypes.AFTER_LOGIN_REDIRECT_URL_SET, payload: url })
	}
}

let extraStyles = (theme) => ({})

let finalStyle = combineStyles(extraStyles, extendedTablesStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyle)(AcceptRejectTeamInvitation))
