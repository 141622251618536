import axios from "axios"
import constants from "../constants"
import cookiesHelper from "../cookiesHelper"

let services = {}

services.getSubmissionsScoreData = (challengeId) => {
	try {
		let loggedInUserAccessToken = cookiesHelper.get(constants.Settings.AccessTokenCookieName)
		var access_token = loggedInUserAccessToken.access_token
		console.log(JSON.stringify({ access_token: loggedInUserAccessToken.access_token }))
	} catch (err) {
		access_token = null
	}

	var data = JSON.stringify({ challengeId: challengeId })

	var config = {
		method: "post",
		url: `${constants.Settings.APIURL}/submissions-scores`,
		headers: {
			access_token: access_token,
			"Content-Type": "application/json"
		},
		data: data
	}

	return axios(config)
		.then((response) => {
			console.log(JSON.stringify(response.data))
			if (response.status == "error" && response.message) throw new Error(response.message)
			if (!response.data || !response.data.length)
				throw new Error("An Error occured while fetching submissions scores")
			return response.data
		})
		.catch((error) => {
			console.log(error)
			throw error
		})
}

export default services
