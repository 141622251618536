import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import ChallengeCard from './ChallengeCard';

import styles from "./styles.module.scss";

function ChallengeCards(props) {
  const {
    challenges,
    onDetailsButtonClick,
    canGetFinalLeaderboardForChallenge,
    title,
    onClickAddPhase,
    isLoading,
  } = props;

  const renderCards = () => {
    if ((challenges || []).length == 0 && !isLoading) {
      return (
        <div className={styles.notFoundSection}>
          <h2>No Challenges found</h2>
        </div>
      );
    }

    return (
      <Grid container spacing={4}>
        {challenges.map((challenge, index) => {
          return (
            <Grid item xs key={index} style={{ display: "flex", justifyContent: "center" }}>
              <ChallengeCard
                challenge={challenge}
                onDetailsButtonClick={onDetailsButtonClick}
                canGetFinalLeaderboardForChallenge={canGetFinalLeaderboardForChallenge}
                onClickAddPhase={onClickAddPhase}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.cardsWrapperTitle}>{title}</div>
      {renderCards()}
    </div>
  );
}

ChallengeCards.propTypes = {
  challenges: PropTypes.array,
  onDetailsButtonClick: PropTypes.func.isRequired,
  canGetFinalLeaderboardForChallenge: PropTypes.bool,
  title: PropTypes.string,
  onClickAddPhase: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

ChallengeCards.defaultProps = {
  challenges: [],
  canGetFinalLeaderboardForChallenge: false,
  title: "",
  isLoading: false,
};

export default ChallengeCards;
