import apolloFetcher from "apolloFetcher"

let services = {}

services.keywordBasedFTS = keywords => {
	let query = `
  query keywordBasedSearch($keyword: String!) {
    keywordBasedSearch(keyword: $keyword) {
      status
      message
      data {
        challengeCount
        challenges {
          challengeId
          urlSlug
          permissions
          roles{
            role
            teamId
            evalutationTeamId
          }
          challengeDetails {
            title
            sponsorsName
            challengeType
            prizeAmount
            shortDescription
            description
            challengeCategory
            startDate
            endDate
            prizes {
              firstPlace
              secondPlace
            }
            status {
              numberOfTeams
              numberOfSolvers
              numberOfSubmissions
              currentPhase
              currentPhaseEndDate
            }
            tags {
              tag
            }
            phases{
              phaseId
              startDate
              endDate
              title
              description
              phaseIcon
            }
            currentPhase{
              phaseId
              phaseTitle
              startDate
              endDate
            }
            challengeImage
            challengeStatus
          }
        }
        userCount
        users{
          userId
          name
          username
          email
          avatarImagePath
          aboutMe
          country
          city
          address
          state
          sectorOfOrganisation
          usersRoleInOrganisation
        }
      }
      totalCount
    }
  }
  `
	let variables = {
		keyword: keywords
	}

	console.log("start seraching, var =", variables)
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({
				query,
				variables
			})
			.then(res => {
				console.log("search response= ", res)
				if (!res || !res.data || !res.data.keywordBasedSearch)
					throw new Error("Error occured while searching!")
				if (!res.data.keywordBasedSearch.status)
					throw new Error(res.data.keywordBasedSearch.message)
				resolve(res.data.keywordBasedSearch.data)
			})
			.catch(err => {
				reject(err)
			})
	})
}

export default services
