import apollofetcher from "apolloFetcher"
let services = {}

services.createOrUpdateEvaluationTeam = (challengeId, teamName, invitationIds, solutions, id) => {
	let query = `
  mutation createOrUpdateEvaluationTeam(
    $id: String
    $challengeId: String!
    $members: [MemberInput!]
    $solutions: [String!]
    $teamName: String!
  ) {
    createOrUpdateEvaluationTeam(
      evaluationTeam: {
        id: $id
        evaluationTeamDetails: {
          teamName: $teamName
          challengeId: $challengeId
          members: $members
          solutions: $solutions
        }
      }
    ) {
      status
      message
      data {
        id
        evaluationTeamDetails {
          teamName
          challengeId
          members{
            invitationId
            userId
            email
            name
            isAccepted
          }
          solutions
        }
      }
      totalCount
    }
  }  
  `
	let variables = {
		id: id ? id : null,
		challengeId: challengeId ? challengeId : null,
		teamName: teamName,
		members: invitationIds ? invitationIds.map((m) => ({ invitationId: m })) : [],
		solutions: solutions ? solutions : []
	}

	return new Promise((resolve, reject) => {
		apollofetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.createOrUpdateEvaluationTeam)
					throw new Error("Error occured while fetching challenges")
				if (!res.data.createOrUpdateEvaluationTeam.status)
					throw new Error(res.data.createOrUpdateEvaluationTeam.message)
				resolve(res.data.createOrUpdateEvaluationTeam.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.getEvaluationTeamsForChallenge = (challengeId) => {
	let query = `
    query getEvaluationTeamsForChallenge($challengeId: String!){
        getEvaluationTeamsForChallenge(challengeId: $challengeId){
          status
          message
          data{
            id
            evaluationTeamDetails{
              teamName
              challengeId
              members{
                invitationId
                userId
                email
                name
                isAccepted
              }
              solutions
            }
          }
          totalCount
        }
      }`
	let variables = {
		challengeId: challengeId ? challengeId : null
	}
	return new Promise((resolve, reject) => {
		apollofetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getEvaluationTeamsForChallenge)
					throw new Error("Error occured while fetching challenges")
				if (!res.data.getEvaluationTeamsForChallenge.status)
					throw new Error(res.data.getEvaluationTeamsForChallenge.message)
				resolve(res.data.getEvaluationTeamsForChallenge.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.deleteEvaluationTeam = (id) => {
	let query = `
  mutation deleteEvaluationTeam($id: String!) {
    deleteEvaluationTeam(id: $id) {
      status
      message
    }
  }
  `
	let variables = {
		id: id
	}

	return new Promise((resolve, reject) => {
		apollofetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.deleteEvaluationTeam)
					throw new Error("Error occured while deleting evaluation team")
				if (!res.data.deleteEvaluationTeam.status)
					throw new Error(res.data.deleteEvaluationTeam.message)
				resolve(res.data.deleteEvaluationTeam.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export default services
