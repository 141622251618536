import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import SweetAlert from "react-bootstrap-sweetalert"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import challengeServices from "dataServices/challengeServices"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import { Redirect, NavLink } from "react-router-dom"
import Constants from "../../constants.js"
import authentication from "authentication.js"

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	)
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	bottomNavigationBarStyle: {
		backgroundColor: "#C3FDFF",
		color: "#101316"
	}
})

class ManageChallengeNavigationTab extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			manageChallengeTabValue: 0,
			CanCreateOrUpdateEvaluationTeam: false,
			CanCreateOrUpdateManageChallengeInvitation: false,
			CanCreateOrUpdateEvaluationScore: false,
			CanCreateOrUpdateEvaluationCriteria: false,
			permissionFetched: false
		}
	}

	handleSimple = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleChange = (event, manageChallengeTabValue) => {
		console.log(manageChallengeTabValue)
		this.setState({ manageChallengeTabValue })
		if (event.target.textContent == "Manage People") {
			this.props.history.push("/dashboard/manage-people/" + this.props.challengeId)
		} else if (event.target.textContent == "Submission Scoring") {
			this.props.history.push("/dashboard/submission-scoring/" + this.props.challengeId)
		} else if (event.target.textContent == "Manage Challenge") {
			this.props.history.push("/dashboard/challenge-details/" + this.props.challengeId)
		} else if (event.target.textContent == "Set Scoring Criteria") {
			this.props.history.push("/dashboard/scoring-criteria/" + this.props.challengeId)
		} else if (event.target.textContent == "Assign Evaluator") {
			this.props.history.push("/dashboard/challenge-evaluators/" + this.props.challengeId)
		}
	}

	checkPermissions = () => {
		let requiredPermissionsObjects = [
			{ permissionName: Constants.Functionalities.CanCreateOrUpdateEvaluationTeam, objectId: null },
			{
				permissionName: Constants.Functionalities.CanCreateOrUpdateManageChallengeInvitation,
				objectId: null
			},
			{
				permissionName: Constants.Functionalities.CanCreateOrUpdateEvaluationScore,
				objectId: null
			},
			{
				permissionName: Constants.Functionalities.CanCreateOrUpdateEvaluationCriteria,
				objectId: null
			}
		]
		authentication
			.hasMultiplePermissions(requiredPermissionsObjects)
			.then((permissionResponseObject) => {
				if (permissionResponseObject) {
					permissionResponseObject.map((object) => {
						if (
							object.permissionName == Constants.Functionalities.CanCreateOrUpdateEvaluationTeam &&
							object.permissionStatus
						)
							this.setState({ CanCreateOrUpdateEvaluationTeam: true })
						else if (
							object.permissionName ==
								Constants.Functionalities.CanCreateOrUpdateManageChallengeInvitation &&
							object.permissionStatus
						)
							this.setState({ CanCreateOrUpdateManageChallengeInvitation: true })
						else if (
							object.permissionName == Constants.Functionalities.CanCreateOrUpdateEvaluationScore &&
							object.permissionStatus
						)
							this.setState({ CanCreateOrUpdateEvaluationScore: true })
						else if (
							object.permissionName ==
								Constants.Functionalities.CanCreateOrUpdateEvaluationCriteria &&
							object.permissionStatus
						)
							this.setState({ CanCreateOrUpdateEvaluationCriteria: true })
					})
					this.setState({ permissionFetched: true })
				} else this.props.showAlert("warning", "Ooops!", "Error getting required permissions")
			})
			.catch((err) => {
				this.props.showAlert("warning", "Ooops!", "Error getting required permissions")
				console.log("Err =", err.message)
			})
	}

	componentDidMount() {
		console.log("Component did mount of manage challenge navigation tab", this.props.challengeId)
		this.checkPermissions()
	}

	calculateTabValue = () => {
		let visibleTabArray = []
		if (this.state.CanCreateOrUpdateManageChallengeInvitation) visibleTabArray.push("Manage People")
		if (this.state.CanCreateOrUpdateEvaluationScore) visibleTabArray.push("Submission Scoring")
		visibleTabArray.push("Manage Challenge")
		if (this.state.CanCreateOrUpdateEvaluationCriteria) visibleTabArray.push("Set Scoring Criteria")
		if (this.state.CanCreateOrUpdateEvaluationTeam) visibleTabArray.push("Assign Evaluator")
		let tabValue = visibleTabArray.findIndex((tab) => {
			return tab == this.props.manageChallengeTabValue
		})
		return tabValue
	}

	render() {
		const { classes } = this.props
		return (
			<Card raised profile>
				{this.state.permissionFetched ? (
					<AppBar position="static">
						<Tabs value={this.calculateTabValue()} onChange={this.handleChange} variant="fullWidth">
							{this.state.CanCreateOrUpdateManageChallengeInvitation ? (
								<Tab label="Manage People" />
							) : null}
							{this.state.CanCreateOrUpdateEvaluationScore ? (
								<Tab label="Submission Scoring" />
							) : null}
							<Tab label="Manage Challenge" />
							{this.state.CanCreateOrUpdateEvaluationCriteria ? (
								<Tab label="Set Scoring Criteria" />
							) : null}
							{this.state.CanCreateOrUpdateEvaluationTeam ? <Tab label="Assign Evaluator" /> : null}
						</Tabs>
					</AppBar>
				) : null}
			</Card>
		)
	}
}

ManageChallengeNavigationTab.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(ManageChallengeNavigationTab))
