import apolloFetcher from "apolloFetcher"

let solution = {}

solution.submitSolution = (variables) => {
	console.log("variables = ", variables)
	let query = `
    mutation createOrUpdateSolution(
        $solutionId: String,
        $userId: String!,
  			$teamId: String,
        $title: String,
        $challengeId: String!,
        $description: String,
        $officialSubmission: Boolean,
        $submissionFiles: String,
        $userName: String
        $formDataStringified: String
    ){
        createOrUpdateSolution(
        input:{
            solutionId: $solutionId
            solutionDetails:{
                userName: $userName
                title: $title
                description:$description
                officialSubmission:$officialSubmission
                submissionFiles:$submissionFiles
            }
            userId:$userId
          	teamId:$teamId
            challengeId:$challengeId
            formDataStringified:$formDataStringified
        }
        ){
        status
        message
        }
    }
    `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.createOrUpdateSolution)
					throw new Error("Error occured while submitting solution")
				if (!res.data.createOrUpdateSolution.status)
					throw new Error(res.data.createOrUpdateSolution.message)
				resolve(res.data.createOrUpdateSolution.status)
			})
			.catch((err) => {
				console.log("Error in createUpdateSOlution, err =", err.message)
				reject(err)
			})
	})
}

solution.getSolutionsByChallengeId = (challengeId) => {
	let query = `
    query getSolutionsByChallengeId($challengeId: String!){
        getSolutionsByChallengeId( challengeId: $challengeId ){
            status message
            data{ solutionId solutionDetails {
                        title userName teamName description submissionDate
                        officialSubmission status evaluationResult submissionFiles resubmittedAfterScoring
                    }
                userId teamId challengeId formDataStringified
            }
        }
    }
    `
	let variables = {
		challengeId: challengeId
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getSolutionsByChallengeId)
					throw new Error("Error occured while fetching solutions")
				if (!res.data.getSolutionsByChallengeId.status)
					throw new Error(res.data.getSolutionsByChallengeId.message)
				let data = res.data.getSolutionsByChallengeId.data
				data = data.map((sol) => {
					return { ...sol, formData: JSON.parse(sol.formDataStringified || null) }
				})
				resolve(data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

solution.getSolutionsByChallengeIdPaginated = (
	challengeId,
	currentPage = 1,
	itemsPerPage = 1000000
) => {
	let query = `
	query getSolutionsByChallengeIdPaginated(
		$challengeId: String!
		$currentPage: Int!
		$itemsPerPage: Int!
	) {
		getSolutionsByChallengeIdPaginated(
			challengeId: $challengeId
			currentPage: $currentPage
			itemsPerPage: $itemsPerPage
		) {
			status
			message
			data {
				totalCount
				totalFetched
				data {
					solutionId
					solutionDetails {
						title
						userName
						teamName
						description
						submissionDate
						officialSubmission
						status
						evaluationResult
						submissionFiles
						resubmittedAfterScoring
					}
					userId
					teamId
					challengeId
					formDataStringified
				}
			}
		}
	}	
  `
	let variables = {
		challengeId: challengeId,
		currentPage: currentPage,
		itemsPerPage: itemsPerPage
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getSolutionsByChallengeIdPaginated)
					throw new Error("Error occured while fetching solutions")
				if (!res.data.getSolutionsByChallengeIdPaginated.status)
					throw new Error(res.data.getSolutionsByChallengeIdPaginated.message)

				let data = res.data.getSolutionsByChallengeIdPaginated.data
				data.data = data.data.map((sol) => {
					return { ...sol, formData: JSON.parse(sol.formDataStringified || null) }
				})

				resolve(data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

solution.getSolversWhoSubmittedSolutionByChallengeId = (challengeId) => {
	let query = `
    query getSolversWhoSubmittedSolutionByChallengeId($challengeId: String!){
        getSolversWhoSubmittedSolutionByChallengeId(
            challengeId:$challengeId
        ){
            status
            message
            totalCount
            data{
                userId
								state
								teamId
            }
        }
    }
    `
	let variables = {
		challengeId: challengeId
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getSolversWhoSubmittedSolutionByChallengeId)
					throw new Error(
						"Error occured while fetching solvers who submitted solutions by challengeId"
					)
				if (!res.data.getSolversWhoSubmittedSolutionByChallengeId.status)
					throw new Error(res.data.getSolversWhoSubmittedSolutionByChallengeId.message)
				resolve(res.data.getSolversWhoSubmittedSolutionByChallengeId.data)
			})
			.catch((err) => {
				console.log("Error fetching solversWhoSubmittedSoln = ", err)
				reject(err)
			})
	})
}

solution.getSolutionById = (solutionId) => {
	let query = `
        query getSolutionById($solutionId: String!){
            getSolutionById(
            solutionId: $solutionId
            ){
                status
                message
                totalCount
                data{
                    solutionId
                    userId
                    teamId
                    challengeId
				    				formDataStringified
                    solutionDetails{
                        title
                        userName
                        teamName
                        description
                        submissionDate
                        officialSubmission
                        status
                        evaluationResult
												submissionFiles
												resubmittedAfterScoring
                    }
                }
            }
        }
    `
	const variables = {
		solutionId: solutionId
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getSolutionById)
					throw new Error("Error occured while fetching solution by Id")
				if (!res.data.getSolutionById.status) throw new Error(res.data.getSolutionById.message)
				let data = res.data.getSolutionById.data
				data.formData = JSON.parse(data.formDataStringified || null)
				resolve(data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

solution.deleteSolution = (solutionId) => {
	let query = `
        mutation deleteSolution($solutionId: String){
            deleteSolution(
                solutionId: $solutionId
            ){
                status
                message
            }
        }
    `
	let variables = {
		solutionId: solutionId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.deleteSolution)
					throw new Error("Error occured while deleting solutions")
				if (!res.data.deleteSolution.status) throw new Error(res.data.deleteSolution.message)
				resolve(res.data.deleteSolution.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

solution.getSolutionsAssignedToEvaluatorsByChallenge = (challengeId) => {
	let query = `
    query getSolutionsAssignedToEvaluatorsByChallenge($challengeId: String!){
        getSolutionsAssignedToEvaluatorsByChallenge(challengeId: $challengeId){
          status
          message
          data{
            solutionId
            userId
            teamId
            challengeId
            formDataStringified
            solutionDetails{
              title
              userName
              teamName
              description
              submissionDate
              officialSubmission
              status
              evaluationResult
							submissionFiles
							resubmittedAfterScoring
            }
          }
          totalCount
        }
      }
    `
	let variables = {
		challengeId: challengeId
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getSolutionsAssignedToEvaluatorsByChallenge)
					throw new Error("Error occured while fetching solutions")
				if (!res.data.getSolutionsAssignedToEvaluatorsByChallenge.status)
					throw new Error(res.data.getSolutionsAssignedToEvaluatorsByChallenge.message)
				let data = res.data.getSolutionsAssignedToEvaluatorsByChallenge.data
				if (data)
					data = data.map((sol) => {
						return { ...sol, formData: JSON.parse(sol.formDataStringified || null) }
					})
				resolve(data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export default solution
