import React from "react"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import { Select, MenuItem } from "@material-ui/core"

class ThemeSwitcher extends React.Component {
	constructor(props) {
		super(props)
	}

	handleChange = e => {
		this.props.setTheme(e.target.value)
	}

	render() {
		let themeMode = this.props.themeMode || "light"
		return (
			<Select
				style={{ color: "white", backgroundColor: "#004d40", padding: "2px 10px" }}
				value={themeMode}
				name={"theme"}
				onChange={this.handleChange}
			>
				<MenuItem value={"light"}>Light</MenuItem>
				<MenuItem value={"dark"}>Dark</MenuItem>
			</Select>
		)
	}
}

const mapStateToProps = state => ({ themeMode: state.theme })

const mapDispatchToStore = dispatch => ({
	setTheme: themeName => dispatch({ type: actionTypes.SWITCH_THEME, payload: themeName })
})

export default connect(mapStateToProps, mapDispatchToStore)(ThemeSwitcher)
