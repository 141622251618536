import React, { Component } from "react";
import Constants from "../../../constants";
import { FaAmazon as AmazonIcon } from 'react-icons/fa';

class LoginWithAmazon extends Component {
    constructor(props) {
        super(props);
        this.amazon = window.amazon;
    }

    handleLogin = () => {
        let options = { popup: true, scope: ["profile", "postal_code"], interactive: "auto" };
        this.amazon.Login.authorize(options, (response) => {
            if (response.error) {
                console.log('auth error ' + response.error);
                return;
            }
            console.log("auth response = ", response)
            this.amazon.Login.retrieveProfile(response.access_token, (data) => {
                if (data.error) {
                    console.log('auth error ' + response.error);
                    return;
                }
                let authData = {
                    id: data.profile.CustomerId,
                    email: data.profile.PrimaryEmail,
                    name: data.profile.Name,
                    profilePictureUrl: "https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_92x92.png",
                }
                this.props.handleAmazonLogin && this.props.handleAmazonLogin(authData);
            })
        });
    }

    render() {
        return (
            <div>
                <a href id="LoginWithAmazon" onClick={this.handleLogin}>
                    {/* <img border="0" alt="Login with Amazon"
                        src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                        width="156" height="32" /> */}
                    {/* <i className="fab fa-amazon" /> */}
                    <AmazonIcon color="white" size="2em" />
                </a>
            </div>
        )
    }
}

export default LoginWithAmazon;