import React from "react"
import { NavLink } from "react-router-dom"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import cx from "classnames"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import ReactTable from "react-table"
import Table from "components/Table/Table.jsx"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import Constants from "constants.js"
import solutionServices from "dataServices/solutionServices"
import userServices from "dataServices/userServices"
import Button from "components/CustomButtons/Button.jsx"
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx"
import Edit from "@material-ui/icons/Edit"
import Close from "@material-ui/icons/Close"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import SweetAlert from "react-bootstrap-sweetalert"
import timeUtils from "utils/timeUtils.js"
import authentication from "authentication.js"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"

import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Grid from "@material-ui/core/Grid"
import { Form } from "react-formio"

import { Mutation } from "@apollo/client/react/components"
import { gql } from '@apollo/client'
import moment from "moment"
import combineStyles from "utils/combineStyle"
import CardBody from "components/Card/CardBody"
import { Typography } from "@material-ui/core"
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle"
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle"
import FormRender from "../SubmissionScoringUI/FormRender"
import { prepareDataForSubmissionForm } from "./utilities"

const uploadFileMutation = gql`
	mutation uploadFile($file: Upload!, $uploadType: String!) {
		uploadFile(file: $file, uploadType: $uploadType) {
			status
			message
			totalCount
			data {
				id
				filename
				path
				mimetype
				encoding
			}
		}
	}
`

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	bottomNavigationBarStyle: {
		backgroundColor: "#C3FDFF",
		color: "#101316"
	},
	fileUploaderStyle: {
		backgroundColor: "#55ACEE",
		borderRadius: "20px"
	},
	submissionTableBackgroundStyle: {
		backgroundColor: theme.palette.background.page, //"#302F2F"
		color: theme.palette.text.page
	},
	submissionTableTextColorWhiteStyle: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page + " !important"
	},
	downloadLinkStyle: {
		// color: "#cfdb53",
		// "&:hover,&:focus": {
		//   color: "rgba(207, 219, 83, .45)"
		// }
	},
	withdrawAndReplaceButtonStyle: {
		marginLeft: "0px !important",
		paddingLeft: "0px !important",
		"&:hover,&:focus": {
			backgroundColor: "rgba(255,255,255,.3)"
		}
	},
	editButtonLeftAlign: {
		marginLeft: "0px !important"
	},
	...sweetAlertStyle
})

function Transition(props) {
	return <Slide direction="down" {...props} />
}

class Submission extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			alert: null,
			value: 0,
			isLoading: true,
			solutionsByChallengeId: [],
			tableData: [],
			canViewSubmissionList: false,
			canViewSubmissionListForSolver: false,
			fileName: "",
			file: null,
			fileUploadDone: false,
			uploadFileData: null,
			solutionIdToReplace: null,
			solutionIdToWithdraw: null,
			userProfile: null,
			formData: null,
			viewFormModalOpen: false,
			isEditSubmissionMode: false,
			editingSubmissionId: "",
			submittedFormData: null,
			canSeeActionColumn: false
		}
	}

	hideAlert = () => {
		this.setState({
			alert: null
		})
	}

	updateSolution = (solutionId) => {
		this.props.setLoadingSpinner()
		userServices
			.getUserProfileById(this.props.loggedInUserData.userId)
			.then((data) => {
				var i
				var canUpdateChallenge = false
				for (i in data.roles) {
					if (
						(data.roles[i].RoleId == "solver" || data.roles[i].RoleId == "solverForTeam") &&
						data.roles[i].challengeId == this.props.challengeId
					) {
						canUpdateChallenge = true
					}
				}
				if (canUpdateChallenge) {
					this.updateSolutionApi(solutionId).then((res) => {
						if (res == true) {
							this.props.showAlert(
								"success",
								"Solution Updated",
								"Your solution has been successfully updated"
							)
							this.setState({
								solutionIdToReplace: null,
								fileUploadDone: false,
								uploadFileData: null,
								file: null,
								fileName: "",
								editingSubmissionId: "",
								formData: null,
								submittedFormData: null,
								isEditSubmissionMode: false
							})
							this.renderSubmissionList()
							this.props.resetLoadingSpinner()
						} else {
							this.props.resetLoadingSpinner()
							this.props.showAlert(
								"error",
								"Oops!",
								"An error occurred while updating the submission"
							)
						}
					})
				} else {
					this.props.resetLoadingSpinner()
					this.props.showAlert("error", "Oops!", "Your don't have permission to update submission")
				}
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error in replacing submission file, err =", err.message)
				this.props.showAlert("error", "Oops!", "Error occurred while updating the submission")
			})
	}

	updateSolutionApi = (solutionId) => {
		this.props.setLoadingSpinner()
		let variables = {
			solutionId: solutionId,
			userId: this.state.userProfile.userId,
			challengeId: this.props.challengeId
		}

		if (!this.props.submissionForm && this.state.uploadFileData) {
			variables = {
				...variables,
				submissionFiles: this.state.uploadFileData.path,
				description: this.state.uploadFileData.filename
			}
		}

		if (this.props.submissionForm && this.state.submittedFormData) {
			variables = {
				...variables,
				formDataStringified: JSON.stringify(this.state.submittedFormData)
			}
		}

		return solutionServices
			.submitSolution(variables)
			.then((data) => {
				this.props.resetLoadingSpinner()
				return true
			})
			.catch((err) => {
				console.log("Error submitting solution: ", err.message)
				this.props.resetLoadingSpinner()
				return false
			})
	}

	onConfirmFunctionOfReplaceSubmission = (solutionId) => {
		if (this.state.fileUploadDone) {
			this.updateSolution(solutionId)
		}
	}

	onCancelFunction = () => {
		this.hideAlert()
		this.setState({
			solutionIdToReplace: null,
			solutionIdToWithdraw: null,
			fileUploadDone: false,
			uploadFileData: null,
			file: null,
			fileName: ""
		})
	}

	replaceSubmission(solutionId) {
		//this.replaceSubmissionWarningMessage(solutionId);
		this.setState({ solutionIdToReplace: solutionId, fileUploadDone: false })
	}

	handleChange = (event, value) => {
		this.setState({ value })
	}

	handleFileChange(e) {
		e.preventDefault()
		let reader = new FileReader()
		let file = e.target.files[0]
		if (!file) {
			this.setState({ file: null, fileName: "" })
			return
		}
		reader.onloadend = () => {
			this.setState({
				file: { file },
				fileName: file.name
			})
		}
		reader.readAsDataURL(file)
	}

	uploadFile(mutation) {
		if (this.state.file) {
			this.props.setLoadingSpinner()
			mutation({
				mutation: uploadFileMutation,
				variables: { ...this.state.file, uploadType: "solution" },
				fetchPolicy: "no-cache",
				context: {
				  headers: {
					'x-apollo-operation-name': 'upload-file'
				  }
				},
			})
				.then((res) => {
					this.props.resetLoadingSpinner()
					console.log("file upload response = ", res)
					if (res.data.uploadFile.status == true) {
						//this.props.showAlert("success", "Success", "You have successfully uploaded your file");
						this.setState({
							fileUploadDone: true,
							uploadFileData: res.data.uploadFile.data
						})
						//this.props.getValueFromUpload(res.data.uploadFile.data);
					} else {
						throw new Error(res.data.uploadFile.message)
					}
				})
				.catch((err) => {
					console.log("Error uploading file = ", err.message)
					this.props.resetLoadingSpinner()
					this.props.showAlert("warning", "Oops!", err.message)
				})
		} else {
			this.props.showAlert("error", "Oops!", "You haven't selected any file")
		}
	}

	getSolutionsCollapseView() {
		const { classes } = this.props
		let solutions = this.state.solutionsByChallengeId
		if (solutions) {
			return solutions.map((item, key) => {
				return {
					title: <div>{item.solutionDetails.description}</div>,
					content: (
						<div key={key}>
							<List>
								<ListItem>
									<ListItemText
										primary={<div>officialSubmission</div>}
										secondary={
											<div>
												{item.solutionDetails.officialSubmission ? (
													<div>Official</div>
												) : (
													<div>Draft</div>
												)}
											</div>
										}
									/>
								</ListItem>
							</List>
						</div>
					)
				}
			})
		} else {
			return []
		}
	}

	convertSubmissionDatesToCurrentTimeZone = (data) => {
		data.map((solution) => {
			solution.solutionDetails.submissionDate = timeUtils.convertUtcToLocalDateTime(
				solution.solutionDetails.submissionDate,
				"YYYY/MM/DD hh:mm:ss A Z"
			)
		})
		return data
	}

	sortSubmissionByDateTime = (data) => {
		data.sort(function (a, b) {
			a.solutionDetails.submissionDate = a.solutionDetails.submissionDate
				.split("/")
				.reverse()
				.join("")
			b.solutionDetails.submissionDate = b.solutionDetails.submissionDate
				.split("/")
				.reverse()
				.join("")
			return a.solutionDetails.submissionDate < b.solutionDetails.submissionDate
				? 1
				: a.solutionDetails.submissionDate > b.solutionDetails.submissionDate
				? -1
				: 0
		})
		return data
	}

	renderViewSolutionFormData = () => {
		if (!this.state.viewFormModalOpen) return null
		if (!this.state.formData) return null
		const { classes } = this.props
		return (
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modal
				}}
				open={true}
				fullWidth={true}
				maxWidth={"md"}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
					style={{
						margin: "0px 0px"
					}}
				>
					<Grid container direction="column">
						<Grid item>
							<Button
								justIcon
								className={classes.modalCloseButton}
								key="close"
								aria-label="Close"
								color="transparent"
								onClick={() => {
									this.setState({ formData: null, viewFormModalOpen: false })
								}}
							>
								<Tooltip title="Cancel" placement="top">
									<Close className={classes.modalClose} />
								</Tooltip>
							</Button>
						</Grid>
						<Grid item>
							<h4 className={cx(classes.modalTitle, classes.modalTitleStyle)}>
								Solution Form Details
							</h4>
						</Grid>
					</Grid>
				</DialogTitle>
				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody + " " + classes.modalBody2}
				>
					<div style={{ margin: "20px 0px", padding: "40px 0px" }} className="formio">
						<Form
							form={{ ...this.props.submissionForm.formData }}
							options={{ readOnly: true, viewAsHtml: true }}
							submission={prepareDataForSubmissionForm(this.state.formData)}
						/>
					</div>
				</DialogContent>
			</Dialog>
		)
	}

	renderReplaceSolutionDialog = () => {
		if (!this.state.solutionIdToReplace) return null
		const { classes } = this.props
		return (
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modal
				}}
				open={true}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
					style={{
						margin: "0px 0px"
					}}
				>
					<Grid container direction="column">
						<Grid item>
							<Button
								justIcon
								className={classes.modalCloseButton}
								key="close"
								aria-label="Close"
								color="transparent"
								onClick={() => {
									this.onCancelFunction()
								}}
							>
								<Tooltip title="Cancel" placement="top">
									<Close className={classes.modalClose} />
								</Tooltip>
							</Button>
						</Grid>
						<Grid item>
							<h4 className={cx(classes.modalTitle, classes.modalTitleStyle)}>
								Replace solution file
							</h4>
						</Grid>
					</Grid>
				</DialogTitle>

				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody + " " + classes.modalBody2}
				>
					<div style={{ margin: "20px 0px", padding: "40px 0px" }}>
						<h5>
							Choose the solution file and click upload. Then Click Replace button to replace the
							previous solution with recent one
						</h5>
						<br />
						<input
							type="file"
							onChange={(e) => this.handleFileChange(e)}
							className={classes.fileUploaderStyle}
						/>

						<Mutation mutation={uploadFileMutation} fetchPolicy="no-cache" context={{ headers: { 'x-apollo-operation-name': 'upload-file' } }}>
							{(mutation, { loading }) => (
								<Button
									color="twitter"
									onClick={() => {
										this.uploadFile(mutation)
									}}
								>
									Upload
								</Button>
							)}
						</Mutation>
					</div>
				</DialogContent>
				<DialogActions>
					<GridContainer direction="row" alignItems="space-around">
						<GridItem>
							<Button color="youtube" onClick={() => this.onCancelFunction()}>
								Cancel
							</Button>
						</GridItem>
						<GridItem>
							<Button
								disabled={!this.state.fileUploadDone}
								color="gmgGreen"
								onClick={() =>
									this.onConfirmFunctionOfReplaceSubmission(this.state.solutionIdToReplace)
								}
							>
								Replace
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</Dialog>
		)
	}

	renderSubmissionList = () => {
		this.setState({ isLoading: true })
		this.getSolutionsByChallengeId()
			.then((data) => {
				let canSeeActionColumn = false
				if (data) {
					data = data.filter((item) => item.solutionDetails.officialSubmission)
					data = this.sortSubmissionByDateTime(data)
					data = this.convertSubmissionDatesToCurrentTimeZone(data)
				} else data = []
				if (
					this.state.canViewSubmissionListForSolver == true &&
					this.state.canViewSubmissionList == false
				) {
					data = data.filter((i) => {
						return i.userId === this.props.loggedInUserData.userId
					})
				} else if (
					this.state.canViewSubmissionListForSolver == false &&
					this.state.canViewSubmissionList == false
				) {
					data = []
				}
				const { classes } = this.props
				this.setState({
					isLoading: false,
					solutionsByChallengeId: data,

					tableData: data.map((prop, key) => {
						let isFormSubmission = false
						let downloadLink = (
							<p className={classes.submissionTableTextColorWhiteStyle}>NOT AVAILABLE</p>
						)
						let submissionType = "Draft"
						let solutionId = prop.solutionId
						if (prop.solutionDetails.officialSubmission) {
							let fileUrl = Constants.Settings.APIURL + prop.solutionDetails.submissionFiles
							downloadLink = (
								<a href={fileUrl} target="_blank" className={classes.downloadLinkStyle}>
									Download
								</a>
							)
							submissionType = "Official"
							if (prop.formData) {
								isFormSubmission = true
								downloadLink = (
									<Button
										className={`${classes.actionButton} ${classes.withdrawAndReplaceButtonStyle}`}
										color="success"
										simple
										onClick={() => {
											this.setState({ formData: prop.formData })
											this.setState({ viewFormModalOpen: true })
										}}
									>
										View Solution
									</Button>
								)
							}
						}
						var actionDisabled = prop.userId != this.state.userProfile.userId
						canSeeActionColumn = !actionDisabled

						if (
							this.props.challenge.challengeDetails.challengeStatus !=
							Constants.CHALLENGE_STATUS.ACTIVE
						)
							actionDisabled = true

						let ret = [
							<p className={classes.submissionTableTextColorWhiteStyle}>
								{prop.solutionDetails.teamName || prop.solutionDetails.userName}
							</p>,
							<p className={classes.submissionTableTextColorWhiteStyle}>
								{prop.solutionDetails.submissionDate}
							</p>,
							<p className={classes.submissionTableTextColorWhiteStyle}>
								{prop.solutionDetails.title || "Form Submission"}
							</p>,
							downloadLink,
							<p className={classes.submissionTableTextColorWhiteStyle}>{submissionType}</p>
						]

						if (canSeeActionColumn) {
							ret.push(
								<p>
									<Button
										className={`${classes.actionButton} ${classes.editButtonLeftAlign}`}
										color="info"
										size="sm"
										disabled={actionDisabled}
										onClick={() => {
											if (isFormSubmission) {
												this.setState({
													formData: prop.formData,
													isEditSubmissionMode: true,
													editingSubmissionId: solutionId
												})
											} else {
												this.replaceSubmission(solutionId)
											}
										}}
									>
										Edit
									</Button>

									<span style={{ margin: "5px" }}></span>

									<Button
										className={`${classes.actionButton} ${classes.editButtonLeftAlign}`}
										color="danger"
										size="sm"
										disabled={actionDisabled}
										onClick={() => {
											this.withdrawSubmissionWarningMessage(solutionId)
										}}
									>
										Withdraw
									</Button>
								</p>
							)
						}
						return ret
					})
				})
				this.setState({ canSeeActionColumn: canSeeActionColumn })
			})
			.then(() => {
				console.log("state in submission step tab: ", this.state)
			})
	}

	getSolutionsByChallengeId() {
		this.props.setLoadingSpinner()
		return solutionServices
			.getSolutionsByChallengeId(this.props.challengeId)
			.then((data) => {
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting solutions by challenge Id: ", err.message)
				return []
			})
	}

	withdrawSubmissionWarningMessage = (solutionId) => {
		const { classes } = this.props
		this.setState({
			alert: (
				<SweetAlert
					warning
					style={{ display: "block", marginTop: "-100px" }}
					title="Withdraw Submission"
					onConfirm={() => this.onConfirmFunctionOfWithdrawSubmission(solutionId)}
					onCancel={() => this.onCancelFunction()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					cancelBtnCssClass={this.props.classes.button + " " + this.props.classes.danger}
					confirmBtnText="Withdraw"
					cancelBtnText="Cancel"
					showCancel
				>
					Are you sure ?
				</SweetAlert>
			)
		})
	}

	onConfirmFunctionOfWithdrawSubmission(solutionId) {
		this.props.setLoadingSpinner()
		solutionServices
			.deleteSolution(solutionId)
			.then((status) => {
				if (status) {
					this.props.showAlert(
						"success",
						"Success",
						"You have successfully withdrawn the submission"
					)
					this.renderSubmissionList()
				} else {
					this.props.resetLoadingSpinner()
					this.props.showAlert("warning", "Oops!", "Error deleting solution")
				}
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("warning", "Oops!", err.message)
			})
		this.hideAlert()
	}

	getCurrentUserProfile = () => {
		return userServices
			.getCurrentUser()
			.then((data) => {
				console.log("currentUserProfile = ", data)
				if (!data) throw new Error("Error fetching user profile")
				return data
			})
			.catch((err) => {
				return null
			})
	}

	componentDidMount() {
		let requiredPermissionsObjects = [
			{
				permissionName: Constants.Functionalities.CanGetAllSolutions,
				objectId: null
			},
			{
				permissionName: Constants.Functionalities.CanGetAllSolutionsByUserTeamChallenge,
				objectId: null
			}
		]
		this.props.setLoadingSpinner()
		authentication
			.hasMultiplePermissions(requiredPermissionsObjects)
			.then((permissionResponseObject) => {
				if (permissionResponseObject) {
					permissionResponseObject.map((object) => {
						if (
							object.permissionName == Constants.Functionalities.CanGetAllSolutions &&
							object.permissionStatus == true
						)
							this.setState({ canViewSubmissionList: true })
						else if (
							object.permissionName ==
								Constants.Functionalities.CanGetAllSolutionsByUserTeamChallenge &&
							object.permissionStatus == true
						)
							this.setState({ canViewSubmissionListForSolver: true })
					})
				} else {
					this.props.showAlert("warning", "Oops!", "Error getting required permissions")
				}
			})
			.then((res) => {
				this.props.setLoadingSpinner()
				return this.getCurrentUserProfile()
			})
			.then((data) => {
				if (!data) throw new Error("Error fetching user profile")
				this.setState({ userProfile: data }, () => {
					this.renderSubmissionList()
				})
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error fetching data, err= ", err.message)
			})
	}

	render() {
		const { classes } = this.props

		let tableHead = [
			<p>Team/Solver</p>,
			<p>Date</p>,
			<p>Title</p>,
			<p>Download Solution</p>,
			<p>Submission Type</p>
		]

		if (this.state.canSeeActionColumn) {
			tableHead.push(<p>Actions</p>)
		}

		return (
			<div className={classes.overviewPageBackgroundStyle}>
				{this.state.alert}
				{this.renderReplaceSolutionDialog()}
				{this.renderViewSolutionFormData()}
				{!this.state.isLoading &&
				!this.state.isEditSubmissionMode &&
				this.state.solutionsByChallengeId.length > 0 ? (
					<GridContainer>
						<GridItem xs={12} sm={12}>
							<Card color="gmgTheme" raised className={classes.submissionTableBackgroundStyle}>
								<CardBody>
									<Table
										customCellClasses={[classes.submissionTableTextColorWhiteStyle]}
										tableHead={tableHead}
										tableData={this.state.tableData}
									/>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				) : !this.state.isLoading && !this.state.isEditSubmissionMode ? (
					<GridContainer direction="row" justify="center" alignItems="center">
						{this.props.canEditChallenge ? (
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<Card>
									<CardBody className={classes.submissionTableBackgroundStyle}>
										<Typography
											align="center"
											className={classes.submissionTableTextColorWhiteStyle}
										>
											Let's{" "}
											<NavLink
												to="/dashboard/send-general-mail"
												className={classes.downloadLinkStyle}
											>
												send an email
											</NavLink>{" "}
											to our solvers to start submitting.
										</Typography>
									</CardBody>
								</Card>
							</GridItem>
						) : (
							<GridItem xs={12} sm={12} md={12} lg={12}>
								<Card>
									<CardBody className={classes.submissionTableBackgroundStyle}>
										<Typography
											align="center"
											className={classes.submissionTableTextColorWhiteStyle}
										>
											Please click on the button above to submit a solution
										</Typography>
									</CardBody>
								</Card>
							</GridItem>
						)}
					</GridContainer>
				) : null}

				{this.state.isEditSubmissionMode && (
					<div style={{ margin: "20px 20px", padding: "40px 0px" }}>
						<GridContainer>
							<GridItem xs={12}>
								<Card color="gmgTheme">
									<CardBody color="gmgTheme">
										<div style={{ marginBottom: "20px" }}>
											<h4> Edit Submission </h4>
										</div>

										<FormRender
											submissionForm={this.props.submissionForm}
											formData={prepareDataForSubmissionForm(this.state.formData)}
											onSubmit={(submittedData) => {
												this.setState({ submittedFormData: submittedData })
												this.updateSolution(this.state.editingSubmissionId)
											}}
										/>
										<div>
											<GridContainer direction="row" justify="flex-end" alignItems="center">
												<GridItem>
													<Button
														color="danger"
														onClick={(e) => {
															this.setState({
																isEditSubmissionMode: false,
																editingSubmissionId: "",
																formData: null,
																submittedFormData: null
															})
														}}
													>
														Cancel
													</Button>
												</GridItem>
											</GridContainer>
										</div>
									</CardBody>
								</Card>
							</GridItem>
						</GridContainer>
					</div>
				)}
			</div>
		)
	}
}

Submission.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

let extraStyles = (theme) => ({
	modalTitleStyle: {
		fontFamily: "Roboto Condensed",
		fontSize: "1.7em"
	},
	modalBody2: {
		margin: "-20px 20px -20px 20px !important",
		padding: "0px !important"
	}
})

let finalStyles = combineStyles(
	styles,
	extendedTablesStyle,
	notificationsStyle,
	extraStyles,
	modalStyle
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(finalStyles)(Submission))
