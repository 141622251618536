import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import Typography from "@material-ui/core/Typography"
import Constants from "../../constants.js"
import authentication from "authentication.js"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Build from "@material-ui/icons/Build"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Tooltip } from "@material-ui/core"

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	)
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	bottomNavigationBarStyle: {
		backgroundColor: "#C3FDFF",
		color: "#101316"
	},
	hoverTwitterColor: {
		margin: "0px 5px 0px 5px",
		borderRadius: "3px",
		"&:hover,&:focus": {
			backgroundColor: "#3daeff"
		}
	}
})

class ManageChallengeMenu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			manageChallengeTabValue: 0,
			CanCreateOrUpdateEvaluationTeam: false,
			CanCreateOrUpdateManageChallengeInvitation: false,
			CanCreateOrUpdateEvaluationScore: false,
			CanCreateOrUpdateEvaluationCriteria: false,
			CanManageForms: false,
			canSendEmailByRoles: false,
			CanAccessSubmissionsDashboard: false,
			permissionFetched: false,
			CanManageChallenge: false,
			CanGetReportingPage: false,
			anchorEl: null
		};
	}

	handleSimple = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	checkPermissions = () => {
		let requiredPermissionsObjects = [
			{
				permissionName: Constants.Functionalities.CanCreateOrUpdateEvaluationTeam,
				objectId: null
			},
			{
				permissionName: Constants.Functionalities.CanCreateOrUpdateManageChallengeInvitation,
				objectId: null
			},
			{
				permissionName: Constants.Functionalities.CanCreateOrUpdateEvaluationScore,
				objectId: null
			},
			{
				permissionName: Constants.Functionalities.CanCreateOrUpdateEvaluationCriteria,
				objectId: null
			},
			{
				permissionName: Constants.Functionalities.CanManageChallenge,
				objectId: null
			},
			{
				permissionName: Constants.Functionalities.CanGetReportingPage,
				objectId: null
			}
		];

		authentication
			.hasMultiplePermissions(requiredPermissionsObjects)
			.then((permissionResponseObject) => {
				const {
					CanCreateOrUpdateEvaluationTeam,
					CanCreateOrUpdateManageChallengeInvitation,
					CanCreateOrUpdateEvaluationScore,
					CanCreateOrUpdateEvaluationCriteria,
					CanManageChallenge,
					CanGetReportingPage
				} = Constants.Functionalities;

				if (permissionResponseObject) {
					permissionResponseObject.map((object) => {
						const { permissionName, permissionStatus } = object;

						if (!permissionStatus) {
							return;
						}

						if (permissionName == CanCreateOrUpdateEvaluationTeam){
							this.setState({ CanCreateOrUpdateEvaluationTeam: true });
						} else if (permissionName == CanCreateOrUpdateManageChallengeInvitation) {
							this.setState({ CanCreateOrUpdateManageChallengeInvitation: true });
						} else if (permissionName == CanCreateOrUpdateEvaluationScore) {
							this.setState({ CanCreateOrUpdateEvaluationScore: true });
						} else if (permissionName == CanCreateOrUpdateEvaluationCriteria) {
							this.setState({ CanCreateOrUpdateEvaluationCriteria: true });
						} else if (permissionName == CanManageChallenge) {
							this.setState({ CanManageChallenge: true });
						} else if (permissionName == CanGetReportingPage) {
							this.setState({ CanGetReportingPage: true });
						}
					});

					this.setState({ permissionFetched: true });
				} else {
					this.props.showAlert("warning", "Ooops!", "Error getting required permissions");
				}
			})
			.catch((err) => {
				this.props.showAlert("warning", "Ooops!", "Error getting required permissions");
			})
	}

	componentDidMount() {
		if (this.props.challenge && this.props.challenge.permissions && this.props.challenge.roles) {
			let permissions = this.props.challenge.permissions
			let roles = this.props.challenge.roles

			if (
				roles.find((r) => r.role == "challengeAdmin") ||
				roles.find((r) => r.role == "licenseeAdmin") ||
				roles.find((r) => r.role == "superAdmin")
			) {
				if (permissions.includes(Constants.Functionalities.CanCreateOrUpdateEvaluationTeam)) {
					this.setState({ CanCreateOrUpdateEvaluationTeam: true })
				}
				if (
					permissions.includes(Constants.Functionalities.CanCreateOrUpdateManageChallengeInvitation)
				) {
					this.setState({ CanCreateOrUpdateManageChallengeInvitation: true })
				}
				if (permissions.includes(Constants.Functionalities.CanCreateOrUpdateEvaluationCriteria)) {
					this.setState({ CanCreateOrUpdateEvaluationCriteria: true })
				}
				if (permissions.includes(Constants.Functionalities.CanGetReportingPage)) {
					this.setState({ CanGetReportingPage: true })
				}
				this.setState({ CanManageForms: true })
				this.setState({ canSendEmailByRoles: true })
				this.setState({ CanAccessSubmissionsDashboard: true })
			}

			if (
				permissions.includes(Constants.Functionalities.CanCreateOrUpdateEvaluationScore) &&
				roles.find((r) => r.role == "evaluator")
			) {
				this.setState({ CanCreateOrUpdateEvaluationScore: true })
			}

			if (
				(this.props.challenge.challengeDetails.challengeStatus ==
					Constants.CHALLENGE_STATUS.ACTIVE ||
					this.props.challenge.challengeDetails.challengeStatus ==
						Constants.CHALLENGE_STATUS.CLOSED) &&
				roles.find((r) => r.role == "judge")
			) {
				this.setState({ CanAccessSubmissionsDashboard: true })
			}

			if (
				permissions.includes(Constants.Functionalities.CanManageChallenge) &&
				(roles.find((r) => r.role == "challengeAdmin") ||
					roles.find((r) => r.role == "licenseeAdmin") ||
					roles.find((r) => r.role == "superAdmin") ||
					roles.find((r) => r.role == "evaluator") ||
					roles.find((r) => r.role == "judge"))
			) {
				this.setState({ CanManageChallenge: true })
			}

			this.setState({ permissionFetched: true })
		}
	}

	handleMenuClick = (event) => {
		if (this.state.anchorEl) {
			this.setState({
				anchorEl: null
			})
			event.target.style.backgroundColor = "#FFFFFF"
		} else {
			this.setState({
				anchorEl: event.currentTarget
			})
			event.target.style.backgroundColor = "#3daeff"
		}
	}

	handleMenuHover = (event) => {
		this.setState({
			anchorEl: event.currentTarget
		})
		event.target.style.backgroundColor = "#3daeff"
	}

	handleManuClose = () => {
		this.setState({ anchorEl: null });
	}

	handleMenuOut = (event) => {
		this.setState({ anchorEl: null });
		event.target.style.backgroundColor = "#FFFFFF";
	}

	makeDropdownList = (challengeId) => {
		const { classes } = this.props;
		let dropdownList = [];

		if (this.state.CanManageForms) {
			dropdownList.push(
				<Typography variant="body2" color="textPrimary">
					Manage Forms
				</Typography>
			);
		}

		if (this.state.CanCreateOrUpdateManageChallengeInvitation) {
			dropdownList.push(
				<Typography variant="body2" color="textPrimary">
					Manage People
				</Typography>
			);
		}

		if (this.state.CanAccessSubmissionsDashboard) {
			dropdownList.push(
				<Typography variant="body2" color="textPrimary">
					Submissions Dashboard
				</Typography>
			);
		}

		if (this.state.CanCreateOrUpdateEvaluationScore) {
			dropdownList.push(
				<Typography variant="body2" color="textPrimary">
					Submission Scoring
				</Typography>
			);
		}

		dropdownList.push(
			<Typography variant="body2" color="textPrimary">
				Manage Challenge
			</Typography>
		);

		if (this.state.CanGetReportingPage){
			dropdownList.push(
				<Typography id="reports" variant="body2" color="textPrimary">
					Reports
				</Typography>
			);
		}

		if (
			this.state.CanCreateOrUpdateEvaluationTeam ||
			this.state.CanCreateOrUpdateEvaluationCriteria
		) {
			dropdownList.push(
				<div id="manageEvaluation">
					<Typography id="manageEvaluation" variant="body2" color="textPrimary">
						{this.props.anchorPosition == "right" ? (
							<div id="manageEvaluation">Manage Evaluation &#8811;</div>
						) : (
							<div id="manageEvaluation"> &#8810; Manage Evaluation</div>
						)}
					</Typography>
					<Menu
						id="simple-menu"
						anchorEl={this.state.anchorEl}
						keepMounted
						open={Boolean(this.state.anchorEl)}
						onClose={this.handleManuClose}
						anchorOrigin={
							this.props.anchorPosition == "right"
								? { vertical: "bottom", horizontal: "right" }
								: { vertical: "bottom", horizontal: "left" }
						}
						transformOrigin={
							this.props.anchorPosition == "right"
								? { vertical: "top", horizontal: "left" }
								: { vertical: "top", horizontal: "right" }
						}
						disableAutoFocusItem={true}
					>
						{this.state.CanCreateOrUpdateEvaluationCriteria ? (
							<MenuItem
								onClick={() => {
									this.props.history.push("/dashboard/scoring-criteria/" + challengeId)
								}}
								className={classes.hoverTwitterColor}
							>
								<Typography variant="body2" color="textPrimary">
									{" "}
									Set Scoring Criteria{" "}
								</Typography>
							</MenuItem>
						) : null}
						{this.state.CanCreateOrUpdateEvaluationTeam ? (
							<MenuItem
								onClick={() => {
									this.props.history.push("/dashboard/challenge-evaluators/" + challengeId)
								}}
								className={classes.hoverTwitterColor}
							>
								<Typography variant="body2" color="textPrimary">
									{" "}
									Assign Evaluators{" "}
								</Typography>
							</MenuItem>
						) : null}
					</Menu>
				</div>
			);
		}

		if (this.state.canSendEmailByRoles) {
			dropdownList.push(
				<Typography id="reports" variant="body2" color="textPrimary">
					Email Messaging
				</Typography>
			);
		}

		if (this.state.CanAccessSubmissionsDashboard) {
			dropdownList.push(
				<Typography variant="body2" color="textPrimary">
					Leaderboard
				</Typography>
			);
		}

		return dropdownList;
	}

	handleClickOnItems = (event, challengeId) => {
		if (event.target.textContent == "Manage People"){
			this.props.history.push("/dashboard/manage-people/" + challengeId);
		} else if (event.target.textContent == "Submission Scoring"){
			this.props.history.push("/dashboard/submission-scoring/" + challengeId);
		} else if (event.target.textContent == "Manage Challenge") {
			this.props.history.push("/dashboard/challenge-details/" + challengeId);
		} else if (event.target.textContent == "Reports") {
			this.props.history.push("/dashboard/reports/" + challengeId);
		} else if (event.target.textContent == "Email Messaging") {
			this.props.history.push("/dashboard/email-messaging/" + challengeId);
		} else if (event.target.textContent == "Manage Forms") {
			this.props.history.push("/dashboard/manage-forms/" + challengeId);
		} else if (event.target.textContent == "Submissions Dashboard") {
			this.props.history.push("/dashboard/submissions/" + challengeId);
		} else if (event.target.textContent == "Leaderboard") {
			this.props.history.push("/dashboard/submissions/leaderboard/" + challengeId);
		}
	}

	renderManageWithCustomDropdownButton = (challengeId) => {
		const title = "Manage Challenge";

		if (!this.state.CanManageChallenge) {
			return null;
		}
		
		return (
			<Tooltip title={title} placement="left-start">
				<div>
					<CustomDropdown
						title={title}
						handleMenuClick={this.handleMenuClick}
						handleMenuHover={this.handleMenuHover}
						handleMenuOut={this.handleMenuOut}
						challengeId={challengeId}
						handleClickOnItems={this.handleClickOnItems}
						hoverColor="warning"
						buttonIcon={Build}
						buttonProps={{
							round: true,
							style: { marginBottom: "0" },
							color: "twitter",
							title: "Manage Challenge"
						}}
						dropdownList={this.makeDropdownList(challengeId)}
					/>
				</div>
			</Tooltip>
		)
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				{this.state.permissionFetched && this.props.urlSlug ? (
					this.renderManageWithCustomDropdownButton(this.props.urlSlug)
				) : (
					<CircularProgress className={classes.progress} />
				)}
			</div>
		)
	}
}

ManageChallengeMenu.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ManageChallengeMenu))
