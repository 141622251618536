import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { withStyles, withTheme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import { Button, Input } from "@material-ui/core"
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx"
import EditIcon from "@material-ui/icons/EditRounded"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Accordion from "components/Accordion/Accordion.jsx"
import CustomButton from "components/CustomButtons/Button.jsx"
import Chip from "@material-ui/core/Chip"
import Paper from "@material-ui/core/Paper"
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"

import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"

import OverviewStepTab from "../Pages/ChallengeDetailsPageSteps/OverviewStepTab.jsx"
import SubmissionStepTab from "../Pages/ChallengeDetailsPageSteps/SubmissionStepTab.jsx"
import PhaseStepTab from "../Pages/ChallengeDetailsPageSteps/PhaseStepTag.jsx"
import FaqStepTab from "../Pages/ChallengeDetailsPageSteps/FaqStepTab.jsx"
import RulesStepTab from "../Pages/ChallengeDetailsPageSteps/RulesStepTab.jsx"
import SolversStepTab from "../Pages/ChallengeDetailsPageSteps/SolversStepTab.jsx"
import DiscussionStepTab from "./ChallengeDetailsPageSteps/DiscussionTab/DiscussionStepTab.jsx"
import WinnerStepTab from "../Pages/ChallengeDetailsPageSteps/WinnerStepTab.jsx"

import UploadStepTab from "./SubmitSolutionPageSteps/UploadStepTab.jsx"
import SubmitStepTab from "./SubmitSolutionPageSteps/SubmitStepTab.jsx"

import ErrorPage from "views/Pages/ErrorPage"
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"
import CardFooter from "components/Card/CardFooter"
import commonStyles from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import combineStyles from "utils/combineStyle"

import validator from "validator"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../helper"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import solutionServices from "dataServices/solutionServices"
import formServices from "dataServices/formServices"
import authentication from "authentication.js"
import constant from "constants.js"
import ManageChallengeNavigationTab from "../Pages/ManageChallengeNavigationTab.jsx"
import ManageChallengeMenu from "../Pages/ManageChallengeMenu.jsx"

import cookieHelper from "cookiesHelper"

import ResponsiveTabs from "components/ResponsiveTabs/Tabs"

import DropDownMenu from "components/CustomDropdown/DropDownMenu"
import BookIcon from "@material-ui/icons/Book"
import ExportSubmissionScoringData from "./SubmissionScoringUI/ExportSubmissionScoringData.jsx"
import moment from "moment"
import SubmissionLeaderboard from 'components/Challenges/ChallengeDetails/SubmissionLeaderboard';
import { get } from "lodash"

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	)
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	topNavigationBarStyle: {
		backgroundColor: "#90CAF9",
		color: "#101316"
	},
	challengeViewerHeader: {
		backgroundColor: "#90CAF9"
	},
	acceptChallengeButton: {
		width: "90%"
	},
	challengeViewerBody: {
		minHeight: "500px"
	},
	challengeNavBody: {
		minHeight: "565px"
		// backgroundColor: "#302f2f"
	},
	editChallengeButtonStyle: {
		float: "right",
		marginTop: "12px"
	},
	challengeDetailsVerticalBar: {
		borderLeft: "5px solid #0D47A1",
		paddingLeft: "5px"
	},
	challengeTitleStyle: {
		fontWeight: "bold"
	},
	dividerColor: {
		backgroundColor: "#90CAF9",
		height: "4px",
		radius: "5px"
	},
	marginLeft: {
		paddingLeft: "30px"
	},
	expansionPanelStyle: {
		webkitBoxShadow: "none",
		mozBoxShadow: "none",
		boxShadow: "none",
		shadows: "none",
		paddingTop: "30px"
	},
	submitSolutionHeaderStyle: {
		backgroundColor: "#1EB980",
		color: "#FFFFFF"
	},
	submitSolutionSubHeaderStyle: {
		backgroundColor: "#2E7D32"
	},
	tabColorLight: {
		backgroundColor: "#00bcd4 !important"
	},
	tabColordark: {
		backgroundColor: "#212121 !important",
		color: "#FFFFFF !important"
	},
	textOverflowEllipsisStyle: {
		overflowWrap: "break-word",
		height: "75px",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	submitOrJoinButtonStyle: {
		backgroundColor: "#1eb980"
	},
	submitFooter: {
		margin: "0 !important",
		padding: "0 !important"
	}
})

const availableTabNames = [
	"Overview",
	"Submissions",
	"Leaderboard",
	"Phases",
	"FAQ",
	"Rules",
	"Solvers",
	"Discussion",
	"Winners"
]

class ChallengePageDetails extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: 0,
			submissionTabSelectionValue: 0,
			challengeId: "",
			urlSlug: "",
			challenge: null,
			isAdmin: false,
			roleName: "",
			isSoloSolver: false,
			isTeamLead: false,
			isTeamMember: false,
			teamId: "",
			showErrorComponent: true,
			showSolutionSubmissionComponent: false,
			submitAsValue: null,
			submissionTitle: "",
			uploadedFileValue: null,
			formData: null,
			alert: null,
			canSubmitSolution: false,
			alreadyJoinedThisChallenge: null,
			canUpdateChallenge: false,
			canGetAllSolutions: false,
			canJoinChallenge: false,
			canCreateOrUpdateSolution: false,
			userName: null,
			tabNames: [...availableTabNames],
			darkMode: false,
			submissionForm: null,
			tabSlug: "",
			selectedTabKey: 0,
			viewAs: ""
		}

		this.getChallengeById = this.getChallengeById.bind(this)
		this.getCollapseTags = this.getCollapseTags.bind(this)
		this.getValueFromSubmit = this.getValueFromSubmit.bind(this)
		this.getValueFromUpload = this.getValueFromUpload.bind(this)
		this.goToSubmitStep = this.goToSubmitStep.bind(this)
		this.submitSolution = this.submitSolution.bind(this)
	}

	getValueFromUpload(value) {
		this.setState({
			uploadedFileValue: value
		})
	}

	setSubmissionData = (submission) => {
		this.setState({ formData: submission })
	}

	getSubmissionTitle = (subTitle) => {
		this.setState({ submissionTitle: subTitle })
	}

	goToSubmitStep() {
		this.setState({
			submissionTabSelectionValue: 1
		})
	}

	getValueFromSubmit(value) {
		this.setState({
			submitAsValue: value
		})
	}

	submitSolution() {
		this.props.setLoadingSpinner()
		this.fetchCurrentUserProfile()
			.then((currentUser) => {
				var userId = currentUser.userId
				var canSubmitChallenge = false
				userServices.getUserProfileById(userId).then((data) => {
					let i
					for (i in data.roles) {
						if (
							(data.roles[i].RoleId == "solver" ||
								(data.roles[i].RoleId == "solverForTeam" && data.roles[i].teamLead)) &&
							data.roles[i].challengeId == this.state.challengeId
						) {
							canSubmitChallenge = true
						}
					}
					if (canSubmitChallenge) {
						var officialSubmission = false
						if (this.state.submitAsValue == "final") {
							officialSubmission = true
						}
						this.submitSolutionApi(
							userId,
							this.state.teamId,
							this.state.challengeId,
							this.state.uploadedFileValue
								? this.state.uploadedFileValue.filename
								: this.state.submissionTitle,
							officialSubmission,
							this.state.submissionTitle,
							this.state.uploadedFileValue ? this.state.uploadedFileValue.path : "form_submission",
							this.state.formData
						).then((res) => {
							if (res == true) {
								this.setState({
									showSolutionSubmissionComponent: false,
									submitAsValue: "",
									uploadedFileValue: null,
									formData: null,
									submissionTabSelectionValue: 0
								})
								this.switchTab(1)
							} else {
							}
						})
					} else {
						this.props.resetLoadingSpinner()
						this.props.showAlert(
							"error",
							"Submission Failed",
							"Your don't have permission to submit to this challenge"
						)
					}
				})
			})
			.catch((err) => {
				console.log("Error submitting solution", err)
				this.props.resetLoadingSpinner()
				this.props.showAlert("error", "Submission Failed", "Authorization Error")
			})
	}

	submitSolutionApi(
		userId,
		teamId,
		challengeId,
		description,
		officialSubmission,
		title,
		submissionFiles,
		formData
	) {
		this.props.setLoadingSpinner()

		const variables = {
			userId: userId,
			title: title,
			challengeId: challengeId,
			description: description,
			officialSubmission: officialSubmission,
			submissionFiles: submissionFiles,
			userName: this.state.userName,
			formDataStringified: JSON.stringify(formData || null)
		}

		if (teamId) variables.teamId = teamId
		return solutionServices
			.submitSolution(variables)
			.then((data) => {
				this.props.resetLoadingSpinner()
				return true
			})
			.catch((err) => {
				console.log("Error submitting solution: ", err.message)
				this.props.showAlert("error", "Submission Failed", err.message)
				this.props.resetLoadingSpinner()
				return false
			})
	}

	getUserProfile(userId) {
		this.props.setLoadingSpinner()
		userServices
			.getUserProfileById(userId, `userId name roles{ RoleId challengeId teamId }`)
			.then((data) => {
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting userProfile", err)
				return null
			})
	}

	fetchCurrentUserProfile = () => {
		this.props.setLoadingSpinner()
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				this.props.resetLoadingSpinner()

				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting current User", err)
				throw err
			})
	}

	getChallengeById(challengeId) {
		this.props.setLoadingSpinner()
		return challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				let joinAsTeamLead = data.roles.find((r) => r.role == "teamLead")
				let joinAsTeamMember = data.roles.find((r) => r.role == "solverForTeam")
				let teamId = ""
				if (joinAsTeamLead) teamId = joinAsTeamLead.teamId
				else if (joinAsTeamMember) teamId = joinAsTeamMember.teamId

				this.setState(
					{
						challenge: data,
						challengeId: data.challengeId,
						urlSlug: data.urlSlug,
						canJoinChallenge: data.permissions.includes("CanJoinChallenge"),
						canSubmitSolution: data.permissions.includes("CanCreateOrUpdateSolution"),
						canUpdateChallenge: data.permissions.includes("CanUpdateChallenge"),
						isSoloSolver: data.roles.find((r) => r.role == "solver") != undefined,
						isTeamLead: data.roles.find((r) => r.role == "teamLead") != undefined,
						isTeamMember: data.roles.find((r) => r.role == "solverForTeam") != undefined,
						isAdmin:
							data.roles.find(
								(r) =>
									r.role == "superAdmin" ||
									r.role == "licenseeAdmin" ||
									r.role == "challengeAdmin" ||
									r.role == "judge" ||
									r.role == "evaluator"
							) != undefined,
						teamId: teamId,
						alreadyJoinedThisChallenge:
							data.roles.find((r) => r.role == "solver") != undefined ||
							data.roles.find((r) => r.role == "teamLead") != undefined ||
							data.roles.find((r) => r.role == "solverForTeam") != undefined,
						canCreateOrUpdateSolution: data.permissions.includes("CanCreateOrUpdateSolution"),
						canEditChallenge: data.permissions.includes("CanUpdateChallenge")
					},
					() => {
						let name = data.challengeDetails.title
						if (name.length > 50) name = name.substring(0, 50) + " ..."
						this.props.pushBreadCrumbStack({
							name: name,
							link: "/dashboard/challenge-details/" + challengeId
						})
					}
				)
				this.props.resetLoadingSpinner()
				return true
			})
			.catch((err) => {
				console.log("error getting challenge by id, err =", err.message)
				this.props.resetLoadingSpinner()
				//this.props.showAlert("warning", "Failed to fetch challenge", err.message);
				this.props.setRedirectOnError("/dashboard/index")
				return false
			})
	}

	reloadChallengeData = () => {
		this.getChallengeById(this.state.challengeId).then((status) => {
			if (status) this.prepareTabs()
		})
	}

	getFormByChallengeId = (challengeId) => {
		this.props.setLoadingSpinner()
		return formServices
			.getFormByChallengeId(challengeId)
			.then((form) => {
				this.props.resetLoadingSpinner()
				if (form) this.setState({ submissionForm: form })
				return form
			})
			.catch((err) => {
				console.log("Error getting forms for challenge, err =", err.message)
				return null
			})
	}

	getCollapseTags() {
		const { classes } = this.props
		let tags = this.state.challenge.challengeDetails.tags
		if (tags) {
			return (
				<Paper>
					{tags.map((data, key) => {
						return <Chip size="small" label={data.tag} className={classes.chip} color="primary" />
					})}
				</Paper>
			)
		} else {
			return <p>No tags available</p>
		}
	}

	handleChange = (event, value) => {
		this.setState({ value })
	}

	goToJoinChallenge = (urlSlug) => {
		this.props.history.push("/dashboard/join-challenge/" + urlSlug)
		return
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props

		if (params.challengeId !== ":challengeId") {
			this.setState({
				showErrorComponent: false
			})
		} else {
			this.props.setRedirectOnError("/dashboard/index")
		}

		let tabSlug = ""
		let tabItem = this.state.tabNames.find((item) => item.toLowerCase() === params.tabName)
		if (tabItem) {
			tabSlug = params.tabName
		} else {
			tabSlug = "overview"
			let redirectToOverViewPath = `/dashboard/challenge-details/${params.challengeId}/overview`
			this.props.history.push(redirectToOverViewPath)
		}

		if (validator.isUUID(params.challengeId)) {
			this.setState({ challengeId: params.challengeId, tabSlug: tabSlug })
		} else {
			this.setState({ urlSlug: params.challengeId, tabSlug: tabSlug })
		}

		let darkMode = cookieHelper.get("darkMode")
		this.setState({ darkMode: darkMode === "true" ? "dark" : "light" })

		this.fetchCurrentUserProfile()
			.then((currentUser) => {
				this.getChallengeById(params.challengeId).then((status) => {
					if (status) {
						this.getFormByChallengeId(this.state.challengeId)
						this.prepareTabs()
					}
				})
				var userId = currentUser.userId
				userServices.getUserProfileById(userId).then((data) => {
					this.setState({
						userName: data.name
					})
				})
			})
			.catch((err) => {
				console.log("Err", err.message)
				this.props.setRedirectOnError("/home/challenge-details/" + params.challengeId)
			})
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.tabName != prevProps.match.params.tabName) {
			let tabName = this.props.match.params.tabName
			let key = this.state.tabNames.findIndex((tab) => tab.toLowerCase() === tabName)

			if (key >= 0) {
				this.setState({ tabSlug: tabName, selectedTabKey: key })
			} else {
				let redirectToOverViewPath = `/dashboard/challenge-details/${this.state.urlSlug}/overview`
				this.props.history.push(redirectToOverViewPath)
			}
		}
	}

	prepareTabs = () => {
		var viewAs = ""
		if (this.state.isAdmin) viewAs = "admin"
		if (this.state.isTeamLead || this.state.isTeamMember) viewAs = "team"
		if (this.state.isSoloSolver) viewAs = "solver"
		var isWinnerFound = false
		if (
			this.state.challenge.challengeDetails.leaderboard &&
			this.state.challenge.challengeDetails.leaderboard.length > 0
		) {
			isWinnerFound = true
		}

		let tabNames = availableTabNames.filter((tabname) => {
			if (tabname == "Solvers" && this.state.isAdmin) return true
			if (tabname == "Solvers" && (this.state.isTeamLead || this.state.isTeamMember)) return true
			if (tabname == "Solvers" && this.state.isSoloSolver) return true
			if (tabname == "Solvers") return false

			if (tabname == "Winners" && (viewAs == "admin" || isWinnerFound)) return true
			if (tabname == "Winners") return false

			if (tabname == "Leaderboard") {
				const isUserSolver = this.state.isSoloSolver || this.state.isTeamLead || this.state.isTeamMember;
				const hasAccess = get(this.state.challenge, "challengeDetails.submissionAccessStatus", false);

				return isUserSolver && hasAccess;
			}

			return true
		})

		let key = tabNames.findIndex((tab) => tab.toLowerCase() === this.state.tabSlug)
		if (key < 0) {
			let redirectToOverViewPath = `/dashboard/challenge-details/${this.state.urlSlug}/overview`
			this.props.history.push(redirectToOverViewPath)
			key = 0
		}

		this.setState({ tabNames: tabNames, viewAs: viewAs, selectedTabKey: key })
	}

	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	getTabs = () => {
		const { classes } = this.props
		var viewAs = this.state.viewAs

		return this.state.tabNames.map((tabname, index) => ({
			title: (
				<strong>
					{tabname == "Solvers"
						? viewAs == "team" || viewAs == "solver"
							? "My Team"
							: tabname
						: tabname}
				</strong>
			),
			getContent: () => {
				if (tabname === "Overview")
					return (
						<OverviewStepTab
							canEditChallenge={this.state.canUpdateChallenge}
							challengeId={this.state.challengeId}
							urlSlug={this.state.urlSlug}
							renderChallengeDetails={this.getChallengeById}
							history={this.props.history}
						/>
					)
				else if (tabname === "Submissions")
					return (
						<SubmissionStepTab
							challengeId={this.state.challengeId}
							challenge={this.state.challenge}
							canViewSubmissionList={this.state.canGetAllSolutions}
							canEditChallenge={this.state.canUpdateChallenge}
							submissionForm={this.state.submissionForm}
						/>
					)
				else if (tabname === "Leaderboard")
					return  <SubmissionLeaderboard challenge={this.state.challenge} />
				else if (tabname === "Phases")
					return (
						<PhaseStepTab
							canEditChallenge={this.state.canUpdateChallenge}
							challengeId={this.state.challengeId}
						/>
					)
				else if (tabname === "FAQ")
					return (
						<FaqStepTab
							canEditChallenge={this.state.canUpdateChallenge}
							challengeId={this.state.challengeId}
						/>
					)
				else if (tabname === "Rules")
					return (
						<RulesStepTab
							challengeId={this.state.challengeId}
							canEditChallenge={this.state.canUpdateChallenge}
						/>
					)
				else if (tabname === "Solvers") {
					return (
						<SolversStepTab
							challenge={this.state.challenge}
							challengeId={this.state.challengeId}
							viewAs={viewAs}
							teamId={this.state.teamId}
							reloadChallengeData={this.reloadChallengeData}
						/>
					)
				} else if (tabname === "Discussion")
					return (
						<DiscussionStepTab history={this.props.history} challengeId={this.state.challengeId} />
					)
				else if (tabname === "Winners")
					return <WinnerStepTab challengeId={this.state.challengeId} />
			},
			key: index
		}))
	}

	switchTab = (nextTabKey) => {
		let tabName = "overview"
		if (this.state.tabNames.length > nextTabKey) {
			tabName = this.state.tabNames[nextTabKey]
		}
		let redirectToPath = `/dashboard/challenge-details/${
			this.state.urlSlug
		}/${tabName.toLowerCase()}`
		this.props.history.push(redirectToPath)
	}

	render() {
		const { classes } = this.props
		var title = "Challenge Details | BestInCrowd"
		try {
			title = this.state.challenge.challengeDetails.title + " | BestInCrowd"
		} catch (err) {}
		helper.setPageTitle(title)

		if (!this.state.challenge) {
			return <div> Loading... </div>
		}

		let currentDateTimeString = moment().format("YYYY-MM-DD-HH-m-s")
		let submissionsScoresFileName = "submissions-scores-" + currentDateTimeString

		return (
			<div>
				{this.state.showErrorComponent ? (
					<div>
						<SimpleBreadcrumbs
							breadLinkData={[
								{ name: "Dashboard", link: "/dashboard/index" },
								{ name: "Error", link: "/dashboard/challenge-details" }
							]}
						/>
						<GridContainer direction="row" justify="space-evenly" alignItems="center">
							<GridItem>
								<ErrorPage
									errorCode={403}
									message={"Access Denied! :("}
									description={"You are not logged in, please login to see this page"}
								/>
							</GridItem>
						</GridContainer>
					</div>
				) : (
					<div>
						{this.state.challenge ? (
							<div>
								<GridContainer direction="row" justify="space-between">
									<GridItem xs={12} sm={6} md={6} lg={8}>
										<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
									</GridItem>
									<GridItem xs={12} sm={6} md={6} lg={4}>
										<GridContainer direction="row" justify="flex-end" alignItems="center">
											{this.state.challenge.phaseLinks &&
											this.state.challenge.phaseLinks.length > 1 ? (
												<GridItem>
													<DropDownMenu
														buttonText="Phases"
														buttonIcon={BookIcon}
														dropdownList={this.state.challenge.phaseLinks.map(
															(pl) => pl.phaseTitle
														)}
														handleClickOnItems={(selectedPhase) => {
															let phase = this.state.challenge.phaseLinks.find(
																(pl) => pl.phaseTitle == selectedPhase
															)
															if (phase) {
																window.location.href =
																	"/dashboard/challenge-details/" + phase.urlSlug
															} else {
																console.log("Invalid phase selection")
															}
														}}
													/>
												</GridItem>
											) : null}
											<GridItem className={classes.right}>
												<ManageChallengeMenu
													challengeId={this.state.challengeId}
													challenge={this.state.challenge}
													urlSlug={this.state.urlSlug}
													history={this.props.history}
												/>
											</GridItem>
										</GridContainer>
									</GridItem>
								</GridContainer>

								<div style={{ marginTop: "20px" }}> </div>
								<SnackbarContent
									message={this.state.challenge.challengeDetails.phaseTitle}
									color="success"
								/>

								<GridContainer direction="column" justify="center">
									{this.state.canUpdateChallenge && (
										<GridItem xs={12}>
											<ExportSubmissionScoringData
												challengeId={this.state.challengeId}
												fileName={submissionsScoresFileName}
											/>
										</GridItem>
									)}
									{this.state.challenge.challengeDetails.challengeStatus ==
										constant.CHALLENGE_STATUS.ACTIVE && (
										<GridItem xs={12}>
											{this.state.alreadyJoinedThisChallenge ? (
												this.state.isSoloSolver || this.state.isTeamLead ? (
													<CustomButton
														className={classes.submitOrJoinButtonStyle}
														onClick={() => {
															this.setState({
																showSolutionSubmissionComponent: true
															})
														}}
													>
														Submit Solution
													</CustomButton>
												) : (
													<CustomButton
														className={classes.submitOrJoinButtonStyle}
														onClick={() => {
															this.props.showAlert("info", "Info", "Implement later")
														}}
													>
														Ask Teamlead
													</CustomButton>
												)
											) : (
												<div>
													{this.state.canJoinChallenge ? (
														<CustomButton
															className={classes.submitOrJoinButtonStyle}
															onClick={() => {
																this.goToJoinChallenge(this.state.urlSlug)
															}}
														>
															Join Challenge
														</CustomButton>
													) : null}
												</div>
											)}
										</GridItem>
									)}
									{this.state.showSolutionSubmissionComponent ? (
										<GridItem xs={12}>
											<Card raised={true} color="gmgDark">
												<CardHeader className={classes.submitSolutionHeaderStyle}>
													<h4 className={classes.challengeTitleStyle}>Submission Builder</h4>
												</CardHeader>
												<CardBody style={{ backgroundColor: "#302f2f" }}>
													<div className={classes.root} style={{ backgroundColor: "#302F2F" }}>
														<UploadStepTab
															getSubmissionTitle={this.getSubmissionTitle}
															getValueFromUpload={this.getValueFromUpload}
															goToSubmitStep={this.goToSubmitStep}
															getValueFromSubmit={this.getValueFromSubmit}
															submissionForm={this.state.submissionForm}
															setSubmissionData={this.setSubmissionData}
															onClickSubmitForm={this.submitSolution}
														/>
													</div>
												</CardBody>
												<CardFooter className={classes.submitFooter}>
													<div
														style={{
															backgroundColor: "#302F2F",
															width: "100%",
															padding: "20px"
														}}
													>
														<GridContainer
															direction="row"
															justify="space-between"
															alignItems="center"
														>
															<GridItem sx>
																<div>
																	{this.state.submitAsValue && this.state.uploadedFileValue ? (
																		<CustomButton
																			color="twitter"
																			onClick={() => {
																				this.submitSolution()
																			}}
																		>
																			Submit
																		</CustomButton>
																	) : (
																		<div></div>
																	)}
																</div>
															</GridItem>
															<GridItem sx>
																<CustomButton
																	color="warning"
																	onClick={() => {
																		this.setState({
																			showSolutionSubmissionComponent: false,
																			submitAsValue: false,
																			uploadedFileValue: null,
																			formData: null,
																			submissionTabSelectionValue: 0
																		})
																	}}
																>
																	Cancel
																</CustomButton>
															</GridItem>
														</GridContainer>
													</div>
												</CardFooter>
											</Card>
										</GridItem>
									) : null}
									{!this.state.showSolutionSubmissionComponent && (
										<GridItem xs={12}>
											<div style={{ marginBottom: "-30px" }}> </div>
											<Card raised={true} color="gmgTheme">
												<CardBody color="gmgTheme" className={classes.challengeNavBody}>
													<div className={classes.root}>
														<ResponsiveTabs
															mode={this.state.darkMode}
															transform={false}
															showMore={false}
															selectedTabKey={this.state.selectedTabKey}
															onChange={this.switchTab}
															items={this.getTabs()}
														/>
													</div>
												</CardBody>
											</Card>
										</GridItem>
									)}
								</GridContainer>
							</div>
						) : (
							<div>
								<h3>&nbsp;</h3>
							</div>
						)}
					</div>
				)}
			</div>
		)
	}
}

ChallengePageDetails.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack,
		themeMode: state.theme
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: { alertType, title, description }
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

let finalStyle = combineStyles(styles, commonStyles)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyle)(withTheme(ChallengePageDetails)))
