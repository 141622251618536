import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SimpleBreadcumbs from 'components/SimpleBreadcumbs';
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import ManageChallengeMenu from 'views/Pages/ManageChallengeMenu';

import styles from './styles.module.scss';

export const BreadcrumbAndMenuSection = (props) => {
  const { challenge, urlSlug, breadCrumb, history } = props;

  return (
    <>
      <GridContainer className={styles.breadcrumbMenuContainer}>
        <GridItem xs={9} sm={10} md={10} lg={11}>
          <SimpleBreadcumbs breadLinkData={breadCrumb} />
        </GridItem>
        <GridItem xs={3} sm={2} md={2} lg={1}>
          {challenge && urlSlug && (
            <ManageChallengeMenu
              challengeId={challenge.challengeId}
              challenge={challenge}
              urlSlug={urlSlug}
              history={history}
            />
          )}
        </GridItem>
      </GridContainer>
      <br />
      <SnackbarContent
        message={get(challenge, 'challengeDetails.phaseTitle', '')}
        color="success"
      />
    </>
  );
}

BreadcrumbAndMenuSection.propTypes = {
  challenge: PropTypes.any,
  urlSlug: PropTypes.string.isRequired,
  breadCrumb: PropTypes.array,
  history: PropTypes.any,
}

BreadcrumbAndMenuSection.defaultProps = {
  breadCrumb: [],
};

export default BreadcrumbAndMenuSection;