import React from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Constants from "constants.js"
import authentication from "authentication.js"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import aboutPageServices from "dataServices/aboutPageServices.js"
import CardFooter from "components/Card/CardFooter"
import { Typography, ListItemIcon } from "@material-ui/core"
import userServices from "dataServices/userServices.js"
import { Redirect, NavLink } from "react-router-dom"
import CardAvatar from "components/Card/CardAvatar"
import emptyAvatar from "assets/img/faces/emptyProfilePicture.webp"
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx"
import LaunchIcon from "@material-ui/icons/Launch"
import SweetAlert from "react-bootstrap-sweetalert"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"

import queryString from "query-string"

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	...sweetAlertStyle
})

class UnsubscribeNewsletter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			email: "",
			alert: null
		}
	}

	verifyEmail = (email) => {
		var emailRex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		if (emailRex.test(email)) {
			return true
		}
		return false
	}

	goToLandingPage = () => {
		this.hideAlert()
		this.props.history.push("/")
	}

	subscribeConfirmMessage = () => {
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: "block", marginTop: "-100px" }}
					title="Unsubscribed"
					onConfirm={() => this.goToLandingPage()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
					confirmBtnText="Ok"
				>
					You will be redirected to the home page!
				</SweetAlert>
			)
		})
	}

	hideAlert = () => {
		this.setState({
			alert: null
		})
	}

	unsubscribeToNewsletter = () => {
		if (!this.state.email)
			this.props.showAlert("warning", "Ooops!", "You can not submit empty field")
		else if (!this.verifyEmail(this.state.email))
			this.props.showAlert("warning", "Ooops!", "Please enter a valid email address")
		else {
			let variables = { email: this.state.email }
			this.props.setLoadingSpinner()
			aboutPageServices
				.unsubscribeToNewsletter(variables)
				.then((status) => {
					this.props.resetLoadingSpinner()
					if (status) {
						this.setState({ email: "" })
						this.subscribeConfirmMessage()
					}
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					this.props.showAlert("warning", "Ooops!", err.message)
				})
		}
	}

	handleSimple = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	componentDidMount() {
		console.log("Component Did Mount of Newsletter Page: .. ")
		const values = queryString.parse(this.props.location.search)
		this.setState({ email: values.email })
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				{this.state.alert}
				<GridContainer justify="center">
					<GridItem sx={8} sm={6} md={6} lg={4}>
						<Card profile raised>
							<CardHeader profile>
								<h3>Unsubscribe Newsletter</h3>
							</CardHeader>
							<CardBody profile>
								<CustomInput
									labelText="Email"
									id="Email"
									formControlProps={{
										fullWidth: true
									}}
									inputProps={{
										value: this.state.email,
										//onChange: this.handleSimple,
										name: "email"
									}}
								/>
								<Button
									color="danger"
									disabled={!this.state.email}
									onClick={() => {
										this.unsubscribeToNewsletter()
									}}
								>
									Unsubscribe
								</Button>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

UnsubscribeNewsletter.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({ type: actionTypes.SHOW_ALERT, payload: { alertType, title, description } })
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(UnsubscribeNewsletter))
