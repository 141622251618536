import React, { Component } from "react"
import PropTypes from "prop-types"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Assignment from "@material-ui/icons/Assignment"
import Edit from "@material-ui/icons/Edit"
import Close from "@material-ui/icons/Close"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Input from "@material-ui/core/Input"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import { Divider } from "@material-ui/core"

import { withStyles, withTheme } from "@material-ui/core/styles"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import combineStyles from "utils/combineStyle"
import SimpleBreadcrumbs from "components/SimpleBreadcumbs.jsx"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

import userServices from "dataServices/userServices"

import Constants from "constants.js"
import authentication from "authentication.js"
import ErrorPage from "../Pages/ErrorPage"
import cookieHelper from "cookiesHelper"
import helper from "../../helper"

import Chip from "@material-ui/core/Chip"

import { Mutation } from "@apollo/client/react/components"
import { gql } from '@apollo/client'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"

import sendEmailDataServices from "../../dataServices/sendEmailDataServices"
import FontOverridePatch from "utils/FontOverridePatch"
import constants from "constants.js"

const uploadFileMutation = gql`
	mutation uploadFile($file: Upload!, $uploadType: String!) {
		uploadFile(file: $file, uploadType: $uploadType) {
			status
			message
			totalCount
			data {
				id
				filename
				path
				mimetype
				encoding
			}
		}
	}
`

function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium
	}
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
}

class SendGeneralBulkMail extends Component {
	constructor(props) {
		super(props)
		this.state = {
			canViewThisPage: true,
			showErrorComponent: false,
			isLoading: true,
			editorState: EditorState.createEmpty(),
			selectedRoles: [],
			availableRoles: [
				{ name: "Solver", value: "solver" },
				{ name: "Evaluator", value: "evaluator" },
				{ name: "Judge", value: "judge" },
				{ name: "Challenge Admin", value: "challengeAdmin" }
				//{ name: "Licensee Admin", value: "licenseeAdmin" }, //only for superAdmin, sol will be added after checking roles
			],
			emailSubject: ""
		}
	}

	componentDidMount() {
		let data = cookieHelper.get(constants.Settings.LoggedInCookieName)
		if (!data) {
			this.setState({ canViewThisPage: false, showErrorComponent: true })
			this.props.setRedirectOnError("/home/index")
			return
		}
		this.checkPermissions()
		this.props.resetBreadCrumbStack()
		this.props.pushBreadCrumbStack({ name: "Messages", link: this.props.location.pathname })
	}

	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	checkPermissions = () => {
		this.props.setLoadingSpinner()
		userServices
			.getCurrentUser()
			.then((data) => {
				let currentHighestRole =
					data.roles.filter((role) => role.RoleId === "admin" || role.RoleId === "superAdmin")
						.length > 0
						? "admin"
						: null
				if (!currentHighestRole)
					currentHighestRole =
						data.roles.filter((role) => role.RoleId === "licenseeAdmin").length > 0
							? "licenseeAdmin"
							: null
				if (!currentHighestRole)
					currentHighestRole =
						data.roles.filter((role) => role.RoleId === "challengeAdmin").length > 0
							? "challengeAdmin"
							: null

				if (!currentHighestRole) {
					this.setState({ canViewThisPage: false, showErrorComponent: true })
					this.props.setRedirectOnError("/home/index")
				} else {
					if (currentHighestRole == "admin") {
						let availRoles = this.state.availableRoles.map((role) => ({ ...role }))
						availRoles.push({ name: "Licensee Admin", value: "licenseeAdmin" })
						this.setState({ availableRoles: availRoles })
					}
					this.setState({ isLoading: false })
					this.props.resetLoadingSpinner()
				}
			})
			.catch((err) => {
				console.log("Error getting currentUserData, err =", err.message)
				this.setState({ canViewThisPage: false, showErrorComponent: true, isLoading: false })
				this.props.resetLoadingSpinner()
				this.props.setRedirectOnError("/home/index")
			})
	}

	handleOnChangeMultiSelect = (e) => {
		let filteredRoles = this.state.availableRoles.filter((r) => e.target.value.indexOf(r.name) > -1)
		this.setState({ selectedRoles: filteredRoles })
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			editorState
		})
	}

	uploadImageCallBack = (file, mutation) => {
		console.log("Lets upload...")
		return new Promise((resolve, reject) => {
			mutation({
				mutation: uploadFileMutation,
				variables: { file: file, uploadType: "image" },
				fetchPolicy: "no-cache",
				context: {
				  headers: {
					'x-apollo-operation-name': 'upload-file'
				  }
				},
			})
				.then((res) => {
					if (res.data.uploadFile.status == true) {
						console.log("File upload response", res.data.uploadFile.data)
						let filePath = res.data.uploadFile.data.path
						var uploadResponse = { data: { link: Constants.Settings.APIURL + filePath } }
						console.log("upload response = ", uploadResponse)
						resolve(uploadResponse)
					} else {
						throw new Error(res.data.uploadFile.message)
					}
				})
				.catch((err) => {
					reject(err.message)
				})
		})
	}

	handleOnClickSendEmail = () => {
		let htmlText = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
		let message = null

		let emailSubject = this.state.emailSubject || ""
		emailSubject = emailSubject.trim()

		if (!this.state.selectedRoles || !this.state.selectedRoles.length)
			message = "Role is not selected"
		else if (!emailSubject || !emailSubject.length) message = "Email subject is required"
		else if (!helper.isRTEHtmlStringValid(htmlText))
			message = "Please add some content on email body"
		if (message) {
			this.props.showAlert("warning", "Ooops!", message)
			return
		}

		let variables = {
			roles: this.state.selectedRoles.map((r) => r.value),
			emailSubject: emailSubject,
			emailBody: htmlText
		}

		this.props.setLoadingSpinner()
		sendEmailDataServices
			.sendBulkEmailToUsersByRoles(variables)
			.then((data) => {
				if (!data) throw new Error("Error sending bulk email to users with selected roles")
				this.props.resetLoadingSpinner()
				this.setState({
					editorState: EditorState.createEmpty(),
					selectedRoles: [],
					emailSubject: ""
				})
			})
			.catch((err) => {
				console.log("Error sending bulk email, err =", err.message)
				this.props.showAlert("error", "Ooops!", err.message)
				this.props.resetLoadingSpinner()
			})
	}

	render() {
		const { classes, theme } = this.props
		helper.setPageTitle("Messages | BestInCrowd")

		if (this.state.showErrorComponent) {
			return (
				<ErrorPage
					errorCode={403}
					message={"Access Denied! :("}
					description={"You are not logged in, please login to see this page"}
				/>
			)
		}
		if (this.state.isLoading) {
			console.log("isLoading")
			return <div> </div>
		}
		return (
			<div>
				<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
				<br />
				<br />

				<Card raised>
					<CardHeader color="rose" icon>
						<CardIcon color="rose">
							<Assignment />
						</CardIcon>
						<h3 className={classes.cardIconTitle}> Send Email </h3>
					</CardHeader>

					<CardBody>
						<br />

						<FormControl className={classes.formControl}>
							<InputLabel htmlFor="select-multiple-chip">Select Roles</InputLabel>
							<Select
								multiple
								value={this.state.selectedRoles.map((r) => r.name)}
								onChange={this.handleOnChangeMultiSelect}
								input={<Input id="select-multiple-chip" />}
								renderValue={(selected) => (
									<div className={classes.chips}>
										{selected.map((value) => (
											<Chip key={value} label={value} className={classes.chip} />
										))}
									</div>
								)}
								MenuProps={MenuProps}
							>
								{this.state.availableRoles.map((r) => (
									<MenuItem
										key={r.value}
										value={r.name}
										style={getStyles(r.value, this.state.selectedRoles, theme)}
									>
										{r.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<br />

						<div>
							<CustomInput
								labelText={
									<span>
										Email Subject <small>(required)</small>
									</span>
								}
								formControlProps={{ fullWidth: true }}
								inputProps={{
									onChange: (event) => this.setState({ emailSubject: event.target.value }),
									value: this.state.emailSubject
								}}
							/>
						</div>

						<br />

						<div style={{ minHeight: "180px" }}>
							<h4> Email Body: </h4>
							{this.renderRTE()}
						</div>

						<div>
							<Button
								disabled={
									!helper.isRTEHtmlStringValid(
										draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
									) ||
									!this.state.emailSubject ||
									!this.state.selectedRoles.length
								}
								color="primary"
								onClick={this.handleOnClickSendEmail}
							>
								Send Email
							</Button>
						</div>
					</CardBody>
				</Card>
			</div>
		)
	}

	renderRTE = () => {
		const { classes } = this.props
		return (
			<div style={{ border: "1px solid grey" }}>
				<FontOverridePatch isDraftEditor>
					<Mutation mutation={uploadFileMutation} fetchPolicy="no-cache" context={{ headers: { 'x-apollo-operation-name': 'upload-file' } }}>
						{(mutation, { loading }) => (
							<Editor
								editorState={this.state.editorState}
								toolbarClassName="toolbarClassName"
								wrapperClassName=""
								editorClassName={classes.cccEditorStyle}
								className={classes.editRulesStyle}
								toolbar={{
									options: [
										"inline",
										"blockType",
										"fontSize",
										"fontFamily",
										"list",
										"textAlign",
										"colorPicker",
										"link",
										"embedded",
										"remove",
										"history",
										"image"
									],
									image: {
										uploadCallback: (file) => {
											console.log("uploading...", file, mutation)
											return this.uploadImageCallBack(file, mutation)
										},
										previewImage: true,
										alt: { present: true, mandatory: true },
										defaultSize: {
											height: "auto",
											width: "100%"
										}
									}
								}}
								onEditorStateChange={this.onEditorStateChange}
							/>
						)}
					</Mutation>
				</FontOverridePatch>
			</div>
		)
	}
}

SendGeneralBulkMail.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

let extraStyles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		flexWrap: "wrap"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 300,
		maxWidth: 900
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: 2
	},
	noLabel: {
		marginTop: theme.spacing(3)
	}
})

let finalStyle = combineStyles(extraStyles, extendedTablesStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTheme(withStyles(finalStyle)(SendGeneralBulkMail)))
