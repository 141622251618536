import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Hidden from "@material-ui/core/Hidden"

// material-ui icons
import Menu from "@material-ui/icons/Menu"
import MoreVert from "@material-ui/icons/MoreVert"
import ViewList from "@material-ui/icons/ViewList"
import Tooltip from "@material-ui/core/Tooltip"
import Fade from "@material-ui/core/Fade"
// core components
import Button from "components/CustomButtons/Button.jsx"
import adminNavbarStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx"
import combineStyles from "utils/combineStyle"
import constants from "../../constants.js"
import cookiesHelper from "../../cookiesHelper"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import userServices from "dataServices/userServices"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { fade, makeStyles } from "@material-ui/core/styles"
import ThemeSwitcher from "../../ThemeSwitcher.js"
import querystring from "querystring"
import IconButton from "@material-ui/core/IconButton"
import Badge from "@material-ui/core/Badge"
import NotificationsIcon from "@material-ui/icons/Notifications"

const extraStyles = (theme) => ({
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: "#ededed",
		"&:hover": {
			backgroundColor: "#ffffff"
		},
		marginLeft: 0,
		marginRight: "20px",
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			width: "auto"
		}
	},
	searchIcon: {
		width: theme.spacing(7),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	inputRoot: {
		color: "inherit"
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: 120,
			"&:focus": {
				width: 200
			}
		}
	}
})

class AdminNavbar extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			toolTipOpen: false,
			searchText: ""
		}
		this.logout = this.logout.bind(this)
	}

	getLoginURL() {
		return constants.Settings.APIURL + "/login?api_key=" + constants.Settings.C_API_KEY
	}

	logout() {
		// For cognito logout use userServices.logout() method
		console.log("inside logout")
		this.props.setLoggedInUserData(null)
		cookiesHelper.remove(constants.Settings.LoggedInCookieName)
		cookiesHelper.remove(constants.Settings.AccessTokenCookieName)
		window.location.href = "/"
	}

	handleOnClickLoginButton = () => {
		let path = this.props.history.location.pathname
		//redirect rules after login for special pages; add more if required
		if (path.includes("/home/challenge-details/")) {
			let redirectPath = path.replace("/home/challenge-details/", "/dashboard/challenge-details/")
			console.log("redirect to =", redirectPath)
			this.props.setAfterLoginRedirectUrl(redirectPath)
		}
		this.props.openLoginModal()
	}

	componentDidMount() {
		let data = cookiesHelper.get(constants.Settings.LoggedInCookieName)
		if (data) {
			this.props.setLoggedInUserData(data)
		}
		if (this.props.history.location.pathname == "/home/search/") {
			let qParams = querystring.parse(this.props.history.location.search.slice(1))
			this.setState({ searchText: qParams.q })
			this.props.setKeywordSearchText(qParams.q)
		}
	}

	render() {
		const { classes, color, rtlActive, brandText } = this.props
		const appBarClasses = cx({
			[" " + classes[color]]: color
		})
		const sidebarMinimize =
			classes.sidebarMinimize +
			" " +
			cx({
				[classes.sidebarMinimizeRTL]: rtlActive
			})
		return (
			<AppBar className={classes.appBar + appBarClasses}>
				<Toolbar className={classes.container}>
					{/* <Hidden smDown implementation="css">
            <div className={sidebarMinimize}>
              {this.props.miniActive ? (
                <Tooltip title="Expand Sidebar" placement="right" TransitionComponent={Fade} TransitionProps={{ timeout: 0 }}>
                  <div>
                    <Button
                      justIcon
                      round
                      color="white"
                      onClick={this.props.sidebarMinimize}
                    >
                      <ViewList className={classes.sidebarMiniIcon} />
                    </Button>
                  </div>
                </Tooltip>
              ) : (
                  <Tooltip title="Minimize Sidebar" placement="right" TransitionComponent={Fade} TransitionProps={{ timeout: 0 }}>
                    <div>
                      <Button
                        justIcon
                        round
                        color="white"
                        onClick={this.props.sidebarMinimize}
                      >
                        <MoreVert className={classes.sidebarMiniIcon} />
                      </Button>
                    </div>
                  </Tooltip>
                )}
            </div>
          </Hidden> */}

					<ThemeSwitcher />

					<div className={classes.flex}>
						{/* <Button href="#" disabled className={classes.title} color="transparent">
							{brandText}
						</Button> */}
					</div>
					<div style={{ width: "5px", marginLeft: "5px", marginRight: "5px" }}> &nbsp; &nbsp; </div>

					{/* Use below code to show admin navbar links */}

					{/* <Hidden smDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden> */}

					<div className={classes.search}>
						<div className={classes.searchIcon}>
							<SearchIcon />
						</div>
						<InputBase
							id="keyword-search-input"
							value={this.props.keywordSearchText}
							placeholder="Search…"
							onChange={(e) => {
								this.props.history.push("/home/search/?q=" + e.target.value)
								this.props.setKeywordSearchText(e.target.value)
							}}
							classes={{
								root: classes.inputRoot,
								input: classes.inputInput
							}}
							inputProps={{ "aria-label": "Search" }}
						/>
					</div>

					{this.props.loggedInUserData && (
						<div>
							<IconButton
								style={{ marginRight: "10px" }}
								onClick={() => {
									this.props.history.push("/dashboard/notifications")
								}}
							>
								{this.props.notificationCount ? (
									<Badge badgeContent={this.props.notificationCount} color="error">
										<NotificationsIcon />
									</Badge>
								) : (
									<NotificationsIcon />
								)}
							</IconButton>
						</div>
					)}

					{this.props.loggedInUserData && (
						<div>
							<Button onClick={this.logout} square color="gmgDark">
								Logout
							</Button>
						</div>
					)}

					{!this.props.loggedInUserData && (
						<div>
							<Button
								square
								color="gmgGreen900"
								className={classes.loginButtonStyle}
								onClick={() => {
									console.log("OnClick Login, props", this.props)
									this.handleOnClickLoginButton()
								}}
							>
								Login
							</Button>
						</div>
					)}
					<Hidden mdUp implementation="css">
						<Button
							className={classes.appResponsive}
							color="github"
							justIcon
							aria-label="open drawer"
							onClick={this.props.handleDrawerToggle}
						>
							<Tooltip title="Menu" placement="top">
								<Menu />
							</Tooltip>
						</Button>
					</Hidden>
				</Toolbar>
			</AppBar>
		)
	}
}

AdminNavbar.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
	rtlActive: PropTypes.bool,
	brandText: PropTypes.string,
	openLoginModal: PropTypes.func.isRequired,
	closeLoginModal: PropTypes.func.isRequired,
	isLoginModalOpen: PropTypes.bool.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setAfterLoginRedirectUrl: PropTypes.func.isRequired,
	loggedInUserData: PropTypes.object
}

const mapStateToProps = (state) => {
	return {
		isLoginModalOpen: state.isLoginModalOpen,
		loggedInUserData: state.loggedInUserData,
		keywordSearchText: state.keywordSearchText,
		notificationCount: state.notificationCount
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setKeywordSearchText: (data) =>
			dispatch({ type: actionTypes.SET_KEYWORD_SEARCH_TEXT, payload: data }),
		setAfterLoginRedirectUrl: (url) =>
			dispatch({ type: actionTypes.AFTER_LOGIN_REDIRECT_URL_SET, payload: url })
	}
}

let finalStyle = combineStyles(extraStyles, adminNavbarStyle)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(finalStyle)(AdminNavbar))
