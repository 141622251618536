import React from "react"
import PropTypes from "prop-types"
import { withTheme } from "@material-ui/core/styles"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../helper"

class ChallengePageDetailsRoot extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			tabSlug: ""
		}
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props

		if (params.challengeId !== ":challengeId") {
			this.setState({
				showErrorComponent: false
			})
		} else {
			this.props.setRedirectOnError("/dashboard/index")
		}

		let tabSlug = ""
		if (!params.tabName) {
			tabSlug = "overview"
			let redirectToOverViewPath = `/dashboard/challenge-details/${params.challengeId}/overview`
			this.props.history.push(redirectToOverViewPath)
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.tabName != prevProps.match.params.tabName) {
			let tabName = this.props.match.params.tabName
			if (!tabName) tabName = "overview"
			let redirectToOverViewPath = `/dashboard/challenge-details/${this.state.urlSlug}/overview`
			this.props.history.push(redirectToOverViewPath)
		}
	}

	render() {
		var title = "Challenge Details | BestInCrowd"
		helper.setPageTitle(title)

		return <div> Loading... </div>
	}
}

ChallengePageDetailsRoot.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack,
		themeMode: state.theme
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: { alertType, title, description }
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ChallengePageDetailsRoot))
