import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import { Paper } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Accordion from "components/Accordion/Accordion.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Chip from "@material-ui/core/Chip"
import Divider from "@material-ui/core/Divider"
import CloseIcon from "@material-ui/icons/Close"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

import ErrorPage from "views/Pages/ErrorPage"
import SimpleBreadcrumbs from "../../../components/SimpleBreadcumbs.jsx"
import CardFooter from "components/Card/CardFooter"
import ReactHtmlParser from 'html-react-parser'
import commonStyles from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import combineStyles from "utils/combineStyle"

import { Editor } from "react-draft-wysiwyg"

import validator from "validator"

import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../../helper"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import evaluationCriteriaServices from "dataServices/evaluationCriteriaServices"
import solutionServices from "dataServices/solutionServices"
import evaluationScoreServices from "dataServices/evaluationScoreServices"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import ManageChallengeMenu from "../ManageChallengeMenu.jsx"
import { EditorState } from "draft-js"
import FontOverridePatch from "utils/FontOverridePatch.jsx"

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	minMaxScoreCard: {
		minWidth: "200px",
		maxWidth: "900px",
		display: "inline-block"
	},
	criteriaCard: {
		margin: "10px 5px",
		padding: "20px 15px",
		minWidth: "200px",
		maxWidth: "900px",
		display: "inline-block"
	},
	criteriaCardEdit: {
		margin: "2px 5px",
		padding: "0px 5px",
		minWidth: "200px",
		maxWidth: "900px",
		display: "inline-block",
		backgroundColor: "#FFFFFF"
	},
	criteriaCardHeader: {
		margin: "0px 0px",
		padding: "0px 0px"
	},
	cardHeaderCriteriaList: {
		margin: "0px 0px",
		padding: "10px 10px",
		backgroundColor: "#A4C2F4"
	},
	cardBodyCriteriaList: {
		margin: "0px 0px",
		padding: "10px 10px",
		backgroundColor: "#FFFFF"
	},
	cardDeleteButton: {
		margin: "3px 0px 0px 0px",
		padding: "5px 3px 5px 5px"
	}
})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

class ScoringCriteriaPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			challengeId: "",
			urlSlug: "",
			challenge: null,
			minScore: 0,
			maxScore: 100,
			challengeCriteriaId: "",
			challengeCriteria: [],
			challengeCriteriaEditable: [],
			showErrorComponent: true,
			canViewThisPage: false,
			userName: null,
			editMode: false,
			scoreEvaluated: false,
			isConfirmEditModalOpen: false
		}
	}

	checkPermission = () => {
		//this.props.setLoadingSpinner();
		return userServices
			.getCurrentUser()
			.then((data) => {
				let canViewThisPage = false
				for (let i in data.roles) {
					if (
						data.roles[i].RoleId == "challengeAdmin" ||
						data.roles[i].RoleId == "licenseeAdmin" ||
						data.roles[i].RoleId == "superAdmin"
					) {
						canViewThisPage = true
						break
					}
				}
				this.setState({
					userName: data.name,
					canViewThisPage: canViewThisPage,
					showErrorComponent: !canViewThisPage
				})
				if (!canViewThisPage) this.props.setRedirectOnError("/home/index")
			})
			.catch((err) => {
				//this.props.resetLoadingSpinner();
				console.log("Error getting current User", err)
				let canViewThisPage = false
				this.setState({
					canViewThisPage: canViewThisPage,
					showErrorComponent: !canViewThisPage
				})
				if (!canViewThisPage) this.props.setRedirectOnError("/home/index")
			})
	}

	storeCriteriaIntoState = (criteria) => {
		console.log(criteria)
		let cobj1 = criteria.evaluationCriteriaDetails.criteriaArray.map((c) => {
			return {
				evaluationCriterionName: c.evaluationCriterionName,
				weight: c.weight
			}
		})

		let cobj2 = criteria.evaluationCriteriaDetails.criteriaArray.map((c) => {
			try {
				var editorStateData = helper.getEditorStateFromHtmlString(c.evaluationCriterionName)
			} catch (err) {
				console.log("err = ", err.message)
				editorStateData = EditorState.createEmpty()
			}

			return {
				evaluationCriterionName: editorStateData,
				weight: c.weight
			}
		})

		let challengeCriteriaId = criteria.evaluationCriteriaId
		this.setState({
			challengeCriteriaId: challengeCriteriaId,
			minScore: criteria.evaluationCriteriaDetails.minScore || 0,
			maxScore: criteria.evaluationCriteriaDetails.maxScore || 100,
			challengeCriteria: cobj1,
			challengeCriteriaEditable: cobj2
		})
	}

	getChallengeById = (challengeIdOrSlug) => {
		return challengeServices
			.getChallengeById(challengeIdOrSlug)
			.then((data) => {
				if (!data) throw new Error("Challege not found")
				this.setState({
					challenge: data,
					challengeId: data.challengeId,
					urlSlug: data.urlSlug
				})
				return true
			})
			.catch((err) => {
				console.log("Error = ", err.message)
				this.props.setRedirectOnError("/dashboard/index")
				return false
			})
	}

	fetchEvaluationCriteria = () => {
		return Promise.resolve()
			.then(() => {
				if (this.state.challenge.challengeDetails.evaluationCriteriaId) {
					return evaluationCriteriaServices
						.getEvaluationCriteriaById(this.state.challenge.challengeDetails.evaluationCriteriaId)
						.then((criteria) => {
							this.storeCriteriaIntoState(criteria)
							return true
						})
				} else {
					return false
				}
			})
			.catch((err) => {
				return false
			})
	}

	fetchEvaluationScore = (solutionId) => {
		return evaluationScoreServices
			.getEvaluationScoresForSolutions(solutionId)
			.then((data) => {
				if (!data) throw new Error("No Evaluation score is found")
				return data
			})
			.catch((err) => {
				console.log("Error fetching evaluation score, err =", err.message)
				return null
			})
	}

	fetchAllEvaluationScore = (solutionIds) => {
		return Promise.all(solutionIds.map((sId) => this.fetchEvaluationScore(sId)))
			.then((result) => {
				return result
			})
			.catch((err) => {
				console.log("Error fetching all the evaluation score for all the submissions", err.message)
				return solutionIds.map((sId) => null)
			})
	}

	fetchAllSolutionsByChallenge = () => {
		return solutionServices
			.getSolutionsByChallengeId(this.state.challengeId)
			.then((solutions) => {
				if (!solutions) throw new Error("No solutions are submitted yet")
				return solutions
			})
			.catch((err) => {
				console.log("Error fetching solutions by challenge, err = ", err.message)
				return []
			})
	}

	fetchExistingSolutionScores = () => {
		return this.fetchAllSolutionsByChallenge()
			.then((solutions) => {
				if (!solutions) throw new Error("No solutions found")
				let solutionIds = solutions.map((solution) => solution.solutionId)
				return this.fetchAllEvaluationScore(solutionIds)
			})
			.then((scores) => {
				if (!scores) throw new Error("Scores is not found")
				let scoreEvaluated = false
				scores.map((score) => {
					if (score) scoreEvaluated = true
				})
				this.setState({ scoreEvaluated: scoreEvaluated })
				return true
			})
			.catch((err) => {
				console.log("Error fetching data, err =", err.message)
				return false
			})
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props
		if (params.challengeId !== ":challengeId") {
			this.setState({
				showErrorComponent: false
			})
		} else {
			this.props.setRedirectOnError("/home/index")
			return
		}
		this.props.pushBreadCrumbStack({
			name: "Challenge Scoring Criteria",
			link: this.props.location.pathname
		})

		if (validator.isUUID(params.challengeId)) {
			this.setState({ challengeId: params.challengeId })
		} else {
			this.setState({ urlSlug: params.challengeId })
		}

		this.checkPermission()
		this.getChallengeById(params.challengeId).then((res) => {
			console.log(this.state)
			this.props.setLoadingSpinner()
			return Promise.all([this.fetchEvaluationCriteria(), this.fetchExistingSolutionScores()])
				.then((result) => {
					this.props.resetLoadingSpinner()
					console.log("promise all result = ", result)
				})
				.catch((err) => {
					console.log("Error fetchinh data, err =", err.message)
					this.props.resetLoadingSpinner()
				})
		})
	}

	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	handleOnChange = (e) => {
		let value = e.target.value
		let name = e.target.name
		if (name === "minScore" || name === "maxScore") {
			if (isNaN(parseInt(value))) return
			value = parseInt(value)
		}
		this.setState({ [name]: value })
	}

	handleOnChangeInput = (index, name, value) => {
		if (name == "weight") {
			try {
				value = parseInt(value)
			} catch (err) {
				return
			}
			if (value < 1 || value > 100) return
		}
		let editCriteria = this.state.challengeCriteriaEditable.map((c) => ({
			...c
		}))
		editCriteria[index][name] = value
		this.setState({ challengeCriteriaEditable: editCriteria })
	}

	handleOnClickAdd = () => {
		let editCriteria = []
		if (this.state.editMode) {
			editCriteria = this.state.challengeCriteriaEditable.map((c) => ({
				...c
			}))
		} else {
			editCriteria = this.state.challengeCriteria.map((c) => ({ ...c }))
		}
		let currentTotalWeight = 0,
			weight = 0
		editCriteria.map((c) => {
			currentTotalWeight += isNaN(c.weight) ? 0 : c.weight
		})
		if (currentTotalWeight < 100) weight = 100 - currentTotalWeight
		editCriteria = [
			...editCriteria,
			{ evaluationCriterionName: helper.getEditorStateFromHtmlString(""), weight: weight }
		]
		this.setState({ editMode: true, challengeCriteriaEditable: editCriteria })
	}

	handleOnClickDelete = (criteriaIndex) => {
		let editCriteria = []
		this.state.challengeCriteriaEditable.map((c, index) => {
			if (index != criteriaIndex) editCriteria.push({ ...c })
		})
		this.setState({ challengeCriteriaEditable: editCriteria })
	}

	handleOnClickEdit = () => {
		if (this.state.scoreEvaluated) {
			this.setState({ isConfirmEditModalOpen: true })
			return
		} else {
			let editCriteria = this.state.challengeCriteria.map((c) => ({
				...c,
				evaluationCriterionName: helper.getEditorStateFromHtmlString(c.evaluationCriterionName)
			}))
			this.setState({ editMode: true, challengeCriteriaEditable: editCriteria })
		}
	}

	handleConfirmEdit = () => {
		let editCriteria = this.state.challengeCriteria.map((c) => ({
			...c,
			evaluationCriterionName: helper.getEditorStateFromHtmlString(c.evaluationCriterionName)
		}))
		this.setState({
			editMode: true,
			challengeCriteriaEditable: editCriteria,
			isConfirmEditModalOpen: false
		})
	}

	handleOnClickCancelEdit = () => {
		let editCriteria = this.state.challengeCriteria.map((c) => ({
			...c,
			evaluationCriterionName: helper.getEditorStateFromHtmlString(c.evaluationCriterionName)
		}))
		this.setState({ editMode: false, challengeCriteriaEditable: editCriteria })
	}

	handleOnClickSave = () => {
		let editCriteria = this.state.challengeCriteriaEditable.map((c) => ({
			...c,
			evaluationCriterionName: helper.getHtmlStringFromEditorState(c.evaluationCriterionName)
		}))
		let currentTotalWeight = 0
		editCriteria.map((c) => {
			currentTotalWeight += isNaN(c.weight) ? 0 : c.weight
		})
		if (currentTotalWeight != 100) {
			this.props.showAlert("warning", "Ooops!", "Total weights of criteria must be 100")
			return
		}
		let isValid = true
		let errorMessage = "An Error ocured while saving scoring criteria!"
		editCriteria.map((c) => {
			if (c.weight == "" || isNaN(c.weight) || c.weight == 0) {
				errorMessage = "One or more criteria weight is not valid"
				isValid = false
			}
			if (!c.evaluationCriterionName) {
				errorMessage = "One or more criteria name is empty"
				isValid = false
			}

			let tempEditorState = null
			try {
				tempEditorState = helper.getEditorStateFromHtmlString(c.evaluationCriterionName)
			} catch (err) {
				console.log("invalid criteria name, failed to create state from html")
				errorMessage = "One or more criteria name is empty"
				isValid = false
			}
		})
		if (!isValid) {
			this.props.showAlert("warning", "Ooops!", errorMessage)
			return
		}

		this.props.setLoadingSpinner()
		let criteriaId = null
		if (
			this.state.challengeCriteriaId &&
			this.state.challengeCriteria &&
			this.state.challengeCriteria.length > 0
		) {
			criteriaId = this.state.challengeCriteriaId
		}

		evaluationCriteriaServices
			.createOrUpdateEvaluationCriteria(
				criteriaId,
				this.state.minScore,
				this.state.maxScore,
				editCriteria
			)
			.then((criteria) => {
				let variables = {
					challengeId: this.state.challengeId,
					evaluationCriteriaId: criteria.evaluationCriteriaId
				}

				return challengeServices.updateChallenge(variables).then((updatedChallenge) => {
					console.log("challenge updated with criteriaId", updatedChallenge)

					this.props.resetLoadingSpinner()
					this.storeCriteriaIntoState(criteria)
					this.setState({ editMode: false })
				})
			})
			.catch((err) => {
				console.log("Error saving criteria", err.message)
				this.props.resetLoadingSpinner()
				this.props.showAlert("error", "Ooops!", err.message)
			})
	}

	confirmEditCriteriaModal = () => {
		if (!this.state.isConfirmEditModalOpen) return null
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isConfirmEditModalOpen: false })}
			>
				<DialogTitle> Are you sure? </DialogTitle>
				<DialogContent>
					<DialogContentText>
						Some submissions have already been evaluated. Editing the scoring criteria will damage
						the existing scorings.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.setState({ isConfirmEditModalOpen: false })} color="tumbler">
						No
					</Button>
					<Button onClick={this.handleConfirmEdit} color="youtube">
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	render() {
		const { classes } = this.props
		var title = "Challenge Scoring Criteria | BestInCrowd"
		helper.setPageTitle(title)

		return (
			<div>
				{this.renderError()}
				{this.confirmEditCriteriaModal()}
				{this.renderScoringCriteria()}
			</div>
		)
	}

	renderCriteriaCardNormal = (criteriaIndex) => {
		const { classes } = this.props

		return (
			<Card raised className={classes.criteriaCard}>
				<CardBody>
					<h4> Criterion Name: </h4>
					<div>
						<FontOverridePatch>
							{ReactHtmlParser(this.state.challengeCriteria[criteriaIndex].evaluationCriterionName || "")}
						</FontOverridePatch>
					</div>
					<p> Criterion Weight: {this.state.challengeCriteria[criteriaIndex].weight} </p>
				</CardBody>
			</Card>
		)
	}

	renderCriteriaCardEditable = (criteriaIndex) => {
		const { classes } = this.props
		return (
			<Card raised className={classes.criteriaCardEdit}>
				<CardHeader className={classes.criteriaCardHeader}>
					<GridContainer direction="row" justify="flex-end">
						<GridItem>
							<Button
								className={classes.cardDeleteButton}
								size="sm"
								color="danger"
								onClick={() => this.handleOnClickDelete(criteriaIndex)}
							>
								<Tooltip title="Delete Criteria" placement="top">
									<CloseIcon />
								</Tooltip>
							</Button>
						</GridItem>
					</GridContainer>
				</CardHeader>
				<CardBody>
					<div> Criterion Name: </div>
					<FontOverridePatch isDraftEditor>
						<Editor
							editorState={
								this.state.challengeCriteriaEditable[criteriaIndex].evaluationCriterionName
							}
							toolbarClassName={classes.toolbarClassName}
							wrapperClassName={classes.wrapperClassName}
							editorClassName={classes.cccEditorStyle}
							toolbar={{
								options: [
									"inline",
									"blockType",
									"fontSize",
									"fontFamily",
									"list",
									"textAlign",
									"history"
								]
							}}
							onEditorStateChange={(editorState) =>
								this.handleOnChangeInput(criteriaIndex, "evaluationCriterionName", editorState)
							}
						/>
					</FontOverridePatch>

					<CustomInput
						labelText="Criterion Weight"
						formControlProps={{
							fullWidth: true
						}}
						inputProps={{
							//error: (this.state.showInputErrorBasic && !this.state.name),
							name: "weight",
							type: "number",
							value: this.state.challengeCriteriaEditable[criteriaIndex].weight,
							onChange: (e) => this.handleOnChangeInput(criteriaIndex, "weight", e.target.value)
						}}
					/>
				</CardBody>
			</Card>
		)
	}

	renderMinMaxScoreSetupCard = () => {
		const { classes } = this.props
		if (!this.state.editMode && this.state.challengeCriteria.length == 0) return null
		return (
			<Card raised className={classes.minMaxScoreCard}>
				<CardBody>
					{this.state.editMode ? (
						<div style={{ margin: "5px auto", padding: "5px auto" }}>
							<GridContainer>
								<GridItem xs={6}>
									<CustomInput
										labelText="Minimum Score"
										formControlProps={{
											width: "50%"
										}}
										inputProps={{
											readOnly: true,
											name: "minScore",
											type: "number",
											value: this.state.minScore,
											onChange: this.handleOnChange
										}}
									/>
								</GridItem>
								<GridItem xs={6}>
									<CustomInput
										labelText="Maximum Score"
										formControlProps={{
											width: "50%"
										}}
										inputProps={{
											name: "maxScore",
											type: "number",
											value: this.state.maxScore,
											onChange: this.handleOnChange
										}}
									/>
								</GridItem>
							</GridContainer>
						</div>
					) : (
						<div>
							<GridContainer>
								<GridItem xs={6}>
									<h4> Minimum Score: {this.state.minScore} </h4>
								</GridItem>
								<GridItem xs={6}>
									<h4> Maximum Score: {this.state.maxScore} </h4>
								</GridItem>
							</GridContainer>
						</div>
					)}
				</CardBody>
			</Card>
		)
	}

	renderScoringCriteria = () => {
		const { classes } = this.props
		if (!this.state.challenge) return null
		if (!this.state.challengeCriteria) return null
		if (!this.state.canViewThisPage) return null
		let editCriteria = []
		if (this.state.editMode) {
			editCriteria = this.state.challengeCriteriaEditable.map((c) => ({ ...c }))
		} else {
			editCriteria = this.state.challengeCriteria.map((c) => ({ ...c }))
		}
		let currentTotalWeight = 0
		let colorIndicator = "red"
		editCriteria.map((c) => {
			currentTotalWeight += isNaN(c.weight) ? 0 : c.weight
		})
		if (currentTotalWeight == 100) colorIndicator = "green"

		return (
			<div>
				<GridContainer direction="row" justify="space-between">
					<GridItem xs={9} sm={10} md={10} lg={11}>
						<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
					</GridItem>
					<GridItem className={classes.right} xs={3} sm={2} md={2} lg={1}>
						<ManageChallengeMenu
							challengeId={this.state.challengeId}
							challenge={this.state.challenge}
							urlSlug={this.state.urlSlug}
							history={this.props.history}
						/>
					</GridItem>
				</GridContainer>
				<br />
				<SnackbarContent
					message={this.state.challenge.challengeDetails.phaseTitle}
					color="success"
				/>
				<div style={{ marginBottom: "-30px" }}> </div>
				<GridContainer container direction="row" justify="center" alignItems="flex-start">
					<GridItem sm={12} md={12} lg={10}>
						<Card raised>
							<CardHeader className={classes.cardHeaderCriteriaList}>
								<GridContainer justify="space-between" alignItems="center">
									<GridItem sm={12} md={12} lg={12}>
										<h3> Scoring Criteria </h3>
									</GridItem>
									<GridItem sm={9} md={9} lg={9}>
										<p>
											Current Total Weight:
											<span style={{ color: colorIndicator }}> {currentTotalWeight} </span>{" "}
										</p>
									</GridItem>
								</GridContainer>
							</CardHeader>
							<CardBody className={classes.cardBodyCriteriaList}>
								<div style={{ padding: "10px 20px" }}>
									<List>
										<ListItem>{this.renderMinMaxScoreSetupCard()}</ListItem>
									</List>
								</div>
								<Divider variant="fullWidth" />
								<div style={{ padding: "10px 20px" }}>
									<List>
										{this.state.editMode
											? this.state.challengeCriteriaEditable.map((criteriData, index) => {
													return (
														<ListItem key={index}>
															<GridContainer direction="row" justify="center" alignItems="center">
																<GridItem xs={12} sm={12} md={9} lg={9}>
																	{this.renderCriteriaCardEditable(index)}
																</GridItem>
																<GridItem xs={4} sm={4} md={3} lg={3}>
																	{this.state.challengeCriteriaEditable.length - 1 == index && (
																		<Card raised className={classes.criteriaCardEdit}>
																			<CardBody>
																				<GridContainer alignItems="center" justify="center">
																					<GridItem>
																						<Button color="info" onClick={this.handleOnClickAdd}>
																							+ Add criterion
																						</Button>
																					</GridItem>
																				</GridContainer>
																			</CardBody>
																		</Card>
																	)}
																</GridItem>
															</GridContainer>
														</ListItem>
													)
											  })
											: this.state.challengeCriteria.map((criteriData, index) => {
													return (
														<ListItem key={index}>{this.renderCriteriaCardNormal(index)}</ListItem>
													)
											  })}

										{this.state.challengeCriteriaEditable.length == 0 && (
											<ListItem>
												<Card raised className={classes.criteriaCardEdit}>
													<CardBody>
														<GridContainer alignItems="center" justify="center">
															<GridItem>
																<Button color="info" onClick={this.handleOnClickAdd}>
																	+ Add criterion
																</Button>
															</GridItem>
														</GridContainer>
													</CardBody>
												</Card>
											</ListItem>
										)}
									</List>
								</div>
							</CardBody>
							<CardFooter>
								<GridContainer direction="row" justify="space-between">
									{this.state.editMode && (
										<GridItem>
											<Button color="danger" onClick={this.handleOnClickCancelEdit}>
												Cancel
											</Button>
										</GridItem>
									)}
									{this.state.editMode && (
										<GridItem>
											<Button color="success" onClick={this.handleOnClickSave}>
												Save
											</Button>
										</GridItem>
									)}
									{!this.state.editMode && (
										<GridContainer direction="row" justify="flex-end">
											<GridItem style={{ marginRight: "15px" }}>
												<Button color="info" onClick={this.handleOnClickEdit}>
													Edit
												</Button>
											</GridItem>
										</GridContainer>
									)}
								</GridContainer>
							</CardFooter>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		)
	}

	renderError = () => {
		if (!this.state.showErrorComponent) return null
		return (
			<div>
				<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
				<GridContainer direction="row" justify="space-evenly" alignItems="center">
					<GridItem>
						<ErrorPage
							errorCode={403}
							message={"Access Denied! :("}
							description={"You don't have permission to see this page"}
						/>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

ScoringCriteriaPage.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

let finalStyle = combineStyles(styles, commonStyles)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyle)(ScoringCriteriaPage))
