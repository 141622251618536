import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter';
import { Typography } from '@material-ui/core';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { NavLink } from 'react-router-dom';
import DOMPurify from 'dompurify';

import dummyAvatar from 'assets/img/faces/dummy-avatar-300x300.webp';

import styles from './discussionStyles';
import constants from '../../../../constants';

import connectRedux from 'reduxjs/connectRedux';
import helper from 'helper';
import htmlToDraft from 'html-to-draftjs';
import FontOverridePatch from 'utils/FontOverridePatch';
import { Concast } from '@apollo/client/utilities';

class DiscussionView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discussion: this.props.discussion,
      canModerateDiscussion: this.props.canModerateDiscussion,
      moderateDiscussionMode: this.props.moderateDiscussionMode,
      currentDiscussionToEdit: this.props.currentDiscussionToEdit,
      newDiscussionTitle: '',
      editorState: EditorState.createEmpty()
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.moderateDiscussionMode != prevProps.moderateDiscussionMode) {
      this.setState({ moderateDiscussionMode: this.props.moderateDiscussionMode });
    }

    if (JSON.stringify(this.props.discussion) != JSON.stringify(prevProps.discussion)) {
      this.setState({ discussion: this.props.discussion });
    }

    if (JSON.stringify(this.props.currentDiscussionToEdit) != JSON.stringify(prevProps.currentDiscussionToEdit)) {
      this.setState({ currentDiscussionToEdit: this.props.currentDiscussionToEdit });
    }

    if (this.props.canModerateDiscussion != prevProps.canModerateDiscussion) {
      this.setState({ canModerateDiscussion: this.props.canModerateDiscussion });
    }
  }

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });
  };

  render() {
    const { classes } = this.props;
    let discussion = this.state.discussion;

    if (
      this.state.moderateDiscussionMode &&
      this.state.currentDiscussionToEdit.discussionId == discussion.discussionId
    ) {
      return (
        <Card profile color="gmgTheme">
          <CardHeader color="gmgTheme">
            <div style={{ textTransform: 'capitalize' }}>
              {this.state.canModerateDiscussion ? 'Moderate' : 'Update'} Discussion
            </div>
          </CardHeader>
          <CardBody>
            <TextField
              label="Discussion Title"
              className={classes.inputRoot}
              fullWidth
              InputLabelProps={{
                className: classes.inputLabel
              }}
              value={this.state.newDiscussionTitle || ''}
              onChange={this.handleSimple}
              name={'newDiscussionTitle'}
              inputProps={{
                className: classes.textField,
                overflow: 'auto'
              }}
            />
            <br />
            <br />
            <div style={{ border: '1px solid grey' }}>
              <FontOverridePatch isDraftEditor>
                <Editor
                  editorState={this.state.editorState}
                  toolbarClassName={classes.toolbarClassName}
                  wrapperClassName={classes.wrapperClassName}
                  editorClassName={classes.editorClassName}
                  className={classes.editRulesStyle}
                  toolbar={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'fontFamily',
                      'list',
                      'textAlign',
                      'colorPicker',
                      'link',
                      'embedded',
                      'remove',
                      'history'
                    ]
                  }}
                  onEditorStateChange={this.onEditorStateChange}
                />
              </FontOverridePatch>
            </div>
          </CardBody>

          <CardFooter>
            <GridContainer direction="row" justify="space-between">
              <GridItem>
                <Button
                  color="warning"
                  onClick={() => {
                    this.setState({
                      editorState: EditorState.createEmpty(),
                      newDiscussionTitle: ''
                    });
                    this.props.onClickCancelEditModerate();
                  }}>
                  Cancel
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  className={classes.buttonSuccessColor}
                  disabled={
                    !helper.isRTEHtmlStringValid(
                      draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
                    ) || !this.state.newDiscussionTitle
                  }
                  onClick={() => {
                    let rteContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()));
                    this.props.onClickUpdateDiscussion(
                      discussion.discussionId,
                      this.state.newDiscussionTitle,
                      rteContent
                    );
                  }}>
                  Update
                </Button>
              </GridItem>
            </GridContainer>
          </CardFooter>
        </Card>
      );
    }

    let avatarImageUrl = dummyAvatar;
    if (discussion.discussionDetails.createdBy.avatarImagePath) {
      if (discussion.discussionDetails.createdBy.avatarImagePath.includes('http')) {
        avatarImageUrl = discussion.discussionDetails.createdBy.avatarImagePath;
      } else {
        avatarImageUrl = constants.Settings.APIURL + discussion.discussionDetails.createdBy.avatarImagePath;
      }
    }

	const discussionContent = DOMPurify.sanitize(discussion.discussionDetails.discussionContent);

    return (
      <Card color="gmgTheme" raised>
        <CardBody>
          <GridContainer justify="space-between">
            <GridItem>
              <NavLink to={`#${discussion.discussionId}`}>
                <Typography className={classes.whiteTextColorStyle} variant="h6" color="textSecondary">
                  <div style={{ textTransform: 'capitalize' }}>{discussion.discussionDetails.title}</div>
                </Typography>
              </NavLink>
              <br />
              <Typography className={classes.whiteTextColorStyle} variant="body2" color="textSecondary" align="left">
                <GridContainer>
                  <GridItem>
                    <img
                      src={avatarImageUrl}
                      style={{
                        height: '50px',
                        width: '50px',
                        borderRadius: '50%'
                      }}
                      onError={(e) => (e.target.src = dummyAvatar)}
                    />
                  </GridItem>
                  <GridItem>
                    <br />
                    <div style={{ textTransform: 'capitalize', fontSize: '1.1em', fontWeight: 'bold' }}>
                      {discussion.discussionDetails.createdBy.userName}
                    </div>
                  </GridItem>
                </GridContainer>
              </Typography>
            </GridItem>
            <GridItem>
              {this.props.loggedInUserData.userId == discussion.discussionDetails.createdBy.userId ||
              this.state.canModerateDiscussion ? (
                <div>
                  <Button
                    justIcon
                    round
                    title="Edit Discussion"
                    className={classes.editButtonStyle}
                    onClick={() => {
                      const blocksFromHTML = htmlToDraft(discussion.discussionDetails.discussionContent);
                      const state = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap
                      );
                      var updatedEditorState = EditorState.createWithContent(state);

                      this.setState({
                        editorState: updatedEditorState,
                        newDiscussionTitle: discussion.discussionDetails.title
                      });

                      this.props.onClickEditDiscussion(discussion);
                    }}>
                    <Tooltip title="Edit Discussion" placement="top">
                      <EditIcon />
                    </Tooltip>
                  </Button>

                  <Button
                    title="Delete Discussion"
                    justIcon
                    round
                    className={classes.deleteButtonStyle}
                    onClick={() => {
                      this.props.onClickDeleteDiscussion(discussion.discussionId);
                    }}>
                    <Tooltip title="Delete Discussion" placement="top">
                      <DeleteIcon />
                    </Tooltip>
                  </Button>
                </div>
              ) : null}
            </GridItem>
          </GridContainer>

          <div
            style={{ marginTop: '20px' }}
            className={classes.textOverflowEllipsisStyle}
            dangerouslySetInnerHTML={{ __html: discussionContent }}></div>

          <div>{new Date(discussion.discussionDetails.createdAt).toLocaleString()}</div>
        </CardBody>

        <CardFooter>
          <GridContainer justify="space-between" alignItems="center">
            <GridItem>
              <NavLink to={`#${discussion.discussionId}`}>See details and reply to this thread</NavLink>
            </GridItem>
            <GridItem>
              <NavLink to={`#${discussion.discussionId}`}>
                <Typography className={classes.whiteTextColorStyle} variant="body2" color="textSecondary" align="right">
                  <div style={{ textTransform: 'capitalize' }}>
                    {discussion.discussionDetails.replies ? discussion.discussionDetails.replies.length : '0'} Replies
                  </div>
                </Typography>
              </NavLink>
            </GridItem>
          </GridContainer>
        </CardFooter>
      </Card>
    );
  }
}

export default connectRedux(withStyles(styles)(DiscussionView));
