import React from "react"
import PropTypes from "prop-types"
import Chip from "@material-ui/core/Chip"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
// import { Button } from "@material-ui/core";
import Button from "components/CustomButtons/Button.jsx"
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx"
import EditIcon from "@material-ui/icons/EditRounded"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"

import ErrorPage from "views/Pages/ErrorPage"
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"

import cookiesHelper from "cookiesHelper"
import helper from "../../helper"
import validator from "validator"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import ReactHtmlParser from 'html-react-parser'
import challengeServices from "dataServices/challengeServices"
import userServices from "dataServices/userServices"
import CardFooter from "components/Card/CardFooter.jsx"
import challengeUtils from "utils/challengeUtils"

import constants from "../../constants"
import FontOverridePatch from "utils/FontOverridePatch.jsx"

function TabContainer(props) {
	return (
		<Typography component="div" style={{ padding: 8 * 3 }}>
			{props.children}
		</Typography>
	)
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	topNavigationBarStyle: {
		backgroundColor: "#90CAF9",
		color: "#101316"
	},
	challengeViewerHeader: {
		backgroundColor: "#1EB980",
		color: "#FFFFFF"
	},
	acceptChallengeButton: {
		backgroundColor: "#FF6859",
		color: "#FFFFFF",
		width: "90%"
	},
	challengeViewerBody: {
		backgroundColor: "#302F2F",
		color: "#FFFFFF",
		//fontFamily: 'Roboto Condensed',
		fontSize: "1em",
		minHeight: "500px"
	},
	challengeNavBody: {
		minHeight: "565px"
	},
	editChallengeButtonStyle: {
		float: "right",
		marginTop: "12px"
	},
	challengeDetailsVerticalBar: {
		borderLeft: "5px solid #0D47A1",
		paddingLeft: "5px"
	},
	challengeTitleStyle: {
		//fontWeight: "bold"
	},
	dividerColor: {
		backgroundColor: "#90CAF9",
		height: "4px",
		radius: "5px"
	},
	marginLeft: {
		paddingLeft: "30px"
	},
	expansionPanelStyle: {
		webkitBoxShadow: "none",
		mozBoxShadow: "none",
		boxShadow: "none",
		shadows: "none",
		paddingTop: "30px"
	},
	submitSolutionHeaderStyle: {
		backgroundColor: "#F3EAEA"
	},
	submitSolutionSubHeaderStyle: {
		backgroundColor: "#2E7D32"
	},
	displayPrizeAmountStyle: {
		marginTop: "30px",
		marginBottom: "30px"
	},
	whiteTextColorStyle: {
		color: theme.palette.text.page
	},
	chip: {
		margin: "5px"
	}
})

class ChallengePageDetails extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: 0,
			challengeId: "",
			urlSlug: "",
			challenge: null,
			showErrorComponent: true
		}
		this.getChallengeById = this.getChallengeById.bind(this)
		this.displayState = this.displayState.bind(this)
		this.getCollapseTags = this.getCollapseTags.bind(this)
	}

	displayState() {
		console.log("current state: ", this.state)
	}

	getChallengeById(challengeId) {
		console.log("inside getChallengeById")
		this.props.setLoadingSpinner()
		challengeServices
			.getPublicChallengeById(challengeId)
			.then((data) => {
				console.log("fetched challenge: ", data)
				this.props.resetLoadingSpinner()
				if (!data) throw new Error("Challenge is not found")
				this.setState({ challenge: data, challengeId: data.challengeId, urlSlug: data.urlSlug })
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("erro getting challenge by challenge Id", err)
				this.props.setRedirectOnError("/home/index")
			})
	}

	getCollapseTags() {
		const { classes } = this.props
		let tags = this.state.challenge.challengeDetails.tags
		if (tags && tags.length != 0) {
			return tags.map((data, key) => {
				return (
					<Chip
						size="small"
						label={data.tag}
						className={`${classes.whiteTextColorStyle} ${classes.chip}`}
						color="primary"
					/>
				)
			})
		} else {
			return null
		}
	}

	handleChange = (event, value) => {
		this.setState({ value })
		console.log(this.state)
	}

	handleOnClickJoin = (urlSlug) => {
		if (!this.props.loggedInUserData) {
			console.log("Login first to join a challenge!")
			this.props.setAfterLoginRedirectUrl("/dashboard/join-challenge/" + urlSlug)
			this.props.openLoginModal()
			return
		}
		this.props.history.push("/dashboard/join-challenge/" + urlSlug)
		return
	}

	fetchCurrentUserProfile = () => {
		this.props.setLoadingSpinner()
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				this.props.resetLoadingSpinner()
				console.log("current user ", data)
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting current User", err)
				throw err
			})
	}

	componentDidMount() {
		console.log("Component Did Mount in public challenge details page!")
		const {
			match: { params }
		} = this.props
		console.log("challengeId inside params", params.challengeId)
		if (params.challengeId !== ":challengeId") {
			this.setState({
				showErrorComponent: false
			})
		} else {
			this.props.setRedirectOnError("/home/index")
			return
		}
		validator.isUUID(params.challengeId)
			? this.setState({ challengeId: params.challengeId })
			: this.setState({ urlSlug: params.challengeId })

		let data = cookiesHelper.get(constants.Settings.LoggedInCookieName)
		if (data) this.props.setLoggedInUserData(data)
		this.fetchCurrentUserProfile()
			.then((user) => {
				if (user) {
					this.props.setRedirectOnError("/dashboard/challenge-details/" + params.challengeId)
					return null
				} else throw new Error("No Authorized")
			})
			.catch((err) => {
				this.getChallengeById(params.challengeId)
			})
	}

	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	render() {
		const { classes } = this.props
		var title = "Challenge Details | BestInCrowd"
		try {
			title = this.state.challenge.challengeDetails.title + " | BestInCrowd"
		} catch (err) {}
		helper.setPageTitle(title)
		return (
			<div>
				{this.state.showErrorComponent ? (
					<div>
						<SimpleBreadcrumbs
							breadLinkData={[
								{ name: "Home", link: "/home/index" },
								{ name: "Challenges", link: "/home/challenges" },
								{ name: "Error", link: "#" }
							]}
						/>
						<GridContainer direction="row" justify="space-evenly" alignItems="center">
							<GridItem>
								<ErrorPage
									errorCode={403}
									message={"Access Denied! :("}
									description={"You are not logged in, please login to see this page"}
								/>
							</GridItem>
						</GridContainer>
					</div>
				) : (
					<div>
						{this.state.challenge && (
							<div>
								<SimpleBreadcrumbs
									breadLinkData={[
										{ name: "Home", link: "/home/index" },
										{ name: "Challenges", link: "/home/challenges" },
										{ name: "Challenge Details", link: "#" }
									]}
								/>
								<GridContainer justify="center" direction="row">
									<GridItem xs={12} sm={10} lg={8}>
										<Card color="gmgTheme" raised={true}>
											<CardHeader
												color="gmgTheme"
												//className={classes.cardHead}
												//className={classes.challengeViewerHeader}
											>
												<GridContainer justify="center" direction="row">
													<GridItem xs={12} sm={12}>
														<h3 className={classes.challengeTitleStyle}>
															{this.state.challenge.challengeDetails.title}
														</h3>
													</GridItem>
												</GridContainer>
											</CardHeader>
											<CardBody
												color="gmgTheme"
												//className={classes.cardBody + " " + classes.challengeViewerBody}
											>
												<p> Summary: </p>
												<p>
													<FontOverridePatch>
														{ReactHtmlParser(
															this.state.challenge.challengeDetails.shortDescription || ""
														)}
													</FontOverridePatch>
												</p>
												<br />
												<p> Description: </p>
												<p>
													<FontOverridePatch>
														{ReactHtmlParser(this.state.challenge.challengeDetails.description || "")}
													</FontOverridePatch>
												</p>
												<br />
												<GridContainer direction="column">
													<GridItem className={classes.displayPrizeAmountStyle}>
														<p> Prize Amount: </p>
														<p>
															<Chip
																label={challengeUtils.getPrizeFormatted(
																	this.state.challenge.challengeDetails.prizeAmount
																)}
																className={classes.chip}
																color="secondary"
															/>
														</p>
													</GridItem>
													{this.state.challenge.challengeDetails.tags &&
													this.state.challenge.challengeDetails.tags.length ? (
														<GridItem className={classes.displayPrizeAmountStyle}>
															<p> Tags: {this.getCollapseTags()}</p>
														</GridItem>
													) : null}
												</GridContainer>

												<div style={{ borderTop: "3px solid #72DEFF" }}>
													<GridContainer direction="row" justify="flex-end" alignItems="flex-end">
														<GridItem>
															{this.state.challenge.challengeDetails.challengeStatus ==
															constants.CHALLENGE_STATUS.ACTIVE ? (
																<Button
																	style={{ marginTop: "20px" }}
																	square
																	color="reddit"
																	onClick={() =>
																		this.handleOnClickJoin(this.state.challenge.urlSlug)
																	}
																>
																	Join Challenge
																</Button>
															) : null}
														</GridItem>
													</GridContainer>
												</div>
											</CardBody>
										</Card>
									</GridItem>
								</GridContainer>
							</div>
						)}
					</div>
				)}
			</div>
		)
	}
}

ChallengePageDetails.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	openLoginModal: PropTypes.func.isRequired,
	closeLoginModal: PropTypes.func.isRequired,
	setAfterLoginRedirectUrl: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack,
		themeMode: state.theme
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE }),
		setAfterLoginRedirectUrl: (url) =>
			dispatch({ type: actionTypes.AFTER_LOGIN_REDIRECT_URL_SET, payload: url }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET })
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(ChallengePageDetails))
