import React from "react"
import { withStyles } from "@material-ui/styles"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon.jsx"

import Button from "components/CustomButtons/Button.jsx"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import combineStyles from "utils/combineStyle"

import PropTypes from "prop-types"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

import querystring from "querystring"
import challengeServices from "dataServices/challengeServices"
import unsubscribeEmailFromChallengeUpdate from "dataServices/emailUnsubscribeServices"

class UnsubscribeEmailFromChallengeUpdatePage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isLoading: true,
			isSuccess: false,
			isError: false,
			errorMessage: "",
			challengeId: "",
			email: "",
			challenge: null
		}
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		challengeServices
			.getPublicChallengeById(this.state.challengeId)
			.then(data => {
				this.props.resetLoadingSpinner()
				if (!data) throw new Error("Challenge is not found")
				this.setState({ isLoading: false, challenge: data })
			})
			.catch(err => {
				this.props.resetLoadingSpinner()
				console.log("Error fetching the challenge information, err = ", err.message)
				this.props.showAlert("error", "Opps!", err.message)
			})
	}

	componentDidMount() {
		console.log("props = ", this.props)
		console.log("history = ", this.props.history)

		let qParams = querystring.parse(this.props.location.search.slice(1))
		this.setState({ challengeId: qParams.challengeId, email: qParams.email }, () => {
			console.log("state = ", this.state)
			this.fetchAllData()
		})
	}

	handleOnClickUnsubscribe = () => {
		console.log("Calling unsub api")
		this.props.setLoadingSpinner()
		unsubscribeEmailFromChallengeUpdate
			.unsubscribeChallengeEmailUpdates(this.state.challengeId, this.state.email)
			.then(status => {
				this.props.resetLoadingSpinner()
				console.log("unsubscribe status = ", status)
				if (!status) {
					throw new Error("An error occured while unsubscribing from challenge update email list")
				}
				this.setState({ isSuccess: true })
			})
			.catch(err => {
				this.props.resetLoadingSpinner()
				this.setState({
					isSuccess: false,
					isError: true,
					errorMessage: err.message
				})
			})
	}

	render() {
		console.log("render")
		return (
			<div>
				{this.state.challenge && (
					<div>
						<Card color="gmgTheme">
							<CardHeader color="gmgTheme">
								<h2> {this.state.challenge.challengeDetails.title} || Unsubscribe from updates </h2>
							</CardHeader>
							<CardBody color="gmgTheme">
								{!this.state.isSuccess && !this.state.isError ? (
									<div>
										<h4>
											Are you sure you want to unsubscribe from this challenge related updates?{" "}
										</h4>
										<Button
											color="orange"
											onClick={() => {
												this.props.setRedirectOnError("/dashboard/index")
											}}
										>
											Cancel
										</Button>
										<Button color="danger" onClick={this.handleOnClickUnsubscribe}>
											Yes
										</Button>
									</div>
								) : null}
								{this.state.isSuccess && (
									<div>
										<h4> You have successfully unscubscribed from this challenge updates </h4>
									</div>
								)}
								{this.state.isError && (
									<div>
										<h4> {this.state.errorMessage} </h4>
									</div>
								)}
							</CardBody>
						</Card>
					</div>
				)}
			</div>
		)
	}
}

UnsubscribeEmailFromChallengeUpdatePage.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = state => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setLoggedInUserData: data =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: data =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE }),
		setAfterLoginRedirectUrl: url =>
			dispatch({ type: actionTypes.AFTER_LOGIN_REDIRECT_URL_SET, payload: url })
	}
}

let extraStyles = theme => ({})

let finalStyle = combineStyles(extraStyles, extendedTablesStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyle)(UnsubscribeEmailFromChallengeUpdatePage))
