import React, { useState, useEffect } from "react"
import ReactHtmlParser from 'html-react-parser'
import { Comment, Form, Header } from "semantic-ui-react"
import dummyAvatar from "assets/img/faces/dummy-avatar-300x300.webp"
import constants from "../../../../constants.js"
import FontOverridePatch from "utils/FontOverridePatch.jsx"
import { Editor } from "react-draft-wysiwyg"
import { EditorState } from "draft-js"
import { makeStyles } from "@material-ui/core"
import discussionStyles from "./discussionStyles"
import helper from "helper.js"

import Button from "components/CustomButtons/Button"

const useStyle = makeStyles((theme) => {
	return {
		commentMain: {
			width: "100%",
			maxWidth: "unset !important"
		},
		...discussionStyles(theme)
	}
})

function CommentThread({
	replies,
	canEditDeleteModerateReply,
	parentId,
	onClickReplySetParentReplyId,
	replyingToParentId,
	onClickAddReply,
	editingReplyId,
	onClickEditReply,
	onClickUpdateReply,
	onClickDeleteReply
}) {
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const [openNewReplyEditor, setOpenNewReplyEditor] = useState(false)

	const classes = useStyle()

	let repliesOnCurrentLevel = replies.filter((reply) => reply.parentId === parentId)
	if (!repliesOnCurrentLevel || repliesOnCurrentLevel.length == 0) {
		return null
	}

	return (
		<Comment.Group className={classes.commentMain}>
			{repliesOnCurrentLevel.map((reply) => {
				let avatarImageUrl = dummyAvatar
				if (reply.createdBy.avatarImagePath) {
					if (reply.createdBy.avatarImagePath.includes("http")) {
						avatarImageUrl = reply.createdBy.avatarImagePath
					} else {
						avatarImageUrl = constants.Settings.APIURL + reply.createdBy.avatarImagePath
					}
				}

				return (
					<Comment>
						<Comment.Avatar src={avatarImageUrl} />
						<Comment.Content>
							<Comment.Author as="a"> {reply.createdBy.userName}</Comment.Author>
							<Comment.Metadata>
								<div>{new Date(reply.createdAt).toLocaleString()}</div>
							</Comment.Metadata>

							{editingReplyId === reply.replyId ? (
								<div
									style={{
										border: "1px solid grey",
										marginTop: "10px",
										marginBottom: "10px",
										minHeight: "130px",
										backgroundColor: "#FFF"
									}}
								>
									<FontOverridePatch isDraftEditor>
										<Editor
											editorState={editorState}
											toolbarClassName={classes.toolbarClassName}
											wrapperClassName={classes.wrapperClassName}
											editorClassName={classes.editorClassName}
											className={classes.editRulesStyle}
											toolbar={{
												options: [
													"inline",
													"blockType",
													"fontSize",
													"fontFamily",
													"list",
													"textAlign",
													"colorPicker",
													"link",
													"embedded",
													"remove",
													"history"
												]
											}}
											onEditorStateChange={setEditorState}
										/>
									</FontOverridePatch>
								</div>
							) : (
								<div>
									<Comment.Text>{ReactHtmlParser(reply.replyContent || "")}</Comment.Text>
									{openNewReplyEditor && replyingToParentId === reply.replyId && (
										<Comment.Group className={classes.commentMain}>
											<Comment>
												<Comment.Content>
													<div
														style={{
															border: "1px solid grey",
															marginTop: "10px",
															marginBottom: "10px",
															minHeight: "130px",
															backgroundColor: "#FFF"
														}}
													>
														<FontOverridePatch isDraftEditor>
															<Editor
																editorState={editorState}
																toolbarClassName={classes.toolbarClassName}
																wrapperClassName={classes.wrapperClassName}
																editorClassName={classes.editorClassName}
																className={classes.editRulesStyle}
																toolbar={{
																	options: [
																		"inline",
																		"blockType",
																		"fontSize",
																		"fontFamily",
																		"list",
																		"textAlign",
																		"colorPicker",
																		"link",
																		"embedded",
																		"remove",
																		"history"
																	]
																}}
																onEditorStateChange={setEditorState}
															/>
														</FontOverridePatch>
													</div>

													<Comment.Actions>
														<Comment.Action>
															<Button
																size="sm"
																color="success"
																onClick={(e) => {
																	let replyContentCurrent = helper.getHtmlStringFromEditorState(
																		editorState
																	)
																	onClickAddReply(replyContentCurrent)
																}}
															>
																Add Reply
															</Button>
														</Comment.Action>
														<Comment.Action>
															<Button
																size="sm"
																color="danger"
																onClick={(e) => {
																	setOpenNewReplyEditor(false)
																	onClickReplySetParentReplyId("")
																	setEditorState(EditorState.createEmpty())
																}}
															>
																Cancel
															</Button>
														</Comment.Action>
													</Comment.Actions>
												</Comment.Content>
											</Comment>
										</Comment.Group>
									)}
								</div>
							)}

							{editingReplyId === reply.replyId ? (
								<Comment.Actions>
									<Comment.Action>
										<Button
											size="sm"
											color="success"
											onClick={(e) => {
												let replyContentCurrent = helper.getHtmlStringFromEditorState(editorState)
												onClickUpdateReply(editingReplyId, replyContentCurrent)
											}}
										>
											Update
										</Button>
									</Comment.Action>
									<Comment.Action>
										<Button
											size="sm"
											color="danger"
											onClick={(e) => {
												onClickEditReply("")
												setOpenNewReplyEditor(false)
												setEditorState(EditorState.createEmpty())
											}}
										>
											Cancel
										</Button>
									</Comment.Action>
								</Comment.Actions>
							) : (
								!(openNewReplyEditor && replyingToParentId === reply.replyId) && (
									<Comment.Actions>
										<Comment.Action>
											<Button
												size="sm"
												color="info"
												onClick={(e) => {
													setOpenNewReplyEditor(true)
													onClickEditReply("")
													onClickReplySetParentReplyId(reply.replyId)
													setEditorState(EditorState.createEmpty())
												}}
											>
												Reply
											</Button>
										</Comment.Action>
										{canEditDeleteModerateReply(reply) && (
											<Comment.Action>
												<Button
													size="sm"
													color="info"
													onClick={(e) => {
														onClickEditReply(reply.replyId)
														setOpenNewReplyEditor(false)
														onClickReplySetParentReplyId("")
														let editorStateCurrent = helper.getEditorStateFromHtmlString(
															reply.replyContent
														)
														setEditorState(editorStateCurrent)
													}}
												>
													Edit
												</Button>
											</Comment.Action>
										)}
										{canEditDeleteModerateReply(reply) && (
											<Comment.Action>
												<Button
													size="sm"
													color="danger"
													onClick={(e) => {
														onClickDeleteReply(reply.replyId)
													}}
												>
													Delete
												</Button>
											</Comment.Action>
										)}
									</Comment.Actions>
								)
							)}

							<CommentThread
								replies={replies}
								parentId={reply.replyId}
								editingReplyId={editingReplyId}
								onClickEditReply={onClickEditReply}
								onClickUpdateReply={onClickUpdateReply}
								onClickDeleteReply={onClickDeleteReply}
								onClickReplySetParentReplyId={onClickReplySetParentReplyId}
								replyingToParentId={replyingToParentId}
								onClickAddReply={onClickAddReply}
								canEditDeleteModerateReply={canEditDeleteModerateReply}
							/>
						</Comment.Content>
					</Comment>
				)
			})}
		</Comment.Group>
	)
}

export default CommentThread
