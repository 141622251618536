import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
// @material-ui/icons
import Face from '@material-ui/icons/Face';
import Email from '@material-ui/icons/Email';
import PermIdentity from '@material-ui/icons/PermIdentity';
import EditIcon from '@material-ui/icons/Edit';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInputForChallengeBuilder';
import CustomInputForSector from 'components/CustomInput/CustomInputForSector.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardAvatar from 'components/Card/CardAvatar.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Mutation } from "@apollo/client/react/components";
import gql from 'graphql-tag';
import Constants from 'constants.js';
import ErrorPage from 'views/Pages/ErrorPage';
import SimpleBreadcrumbs from '../../components/SimpleBreadcumbs.jsx';

import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';
import avatar from 'assets/img/faces/dummy-avatar-300x300.webp';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';

import helper from '../../helper';
import cookiesHelper from 'cookiesHelper';
import combineStyles from 'utils/combineStyle';

import { connect } from 'react-redux';
import actionTypes from 'reduxjs/actionTypes';
import userServices from 'dataServices/userServices';
import { FormControl, Divider, Tooltip, IconButton } from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import PasswordUpdateComponent from './PasswordUpdateComponent.jsx';
import validator from 'validator';
import constants from 'constants.js';

const uploadFileMutation = gql`
  mutation uploadFile($file: Upload!, $uploadType: String!) {
    uploadFile(file: $file, uploadType: $uploadType) {
      status
      message
      totalCount
      data {
        id
        filename
        path
        mimetype
        encoding
      }
    }
  }
`;

let intervalId = 0;
class EditProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      address: '',
      city: '',
      country: '',
      state: '',
      postalCode: '',
      citizenship: '',
      aboutMe: '',
      sectorOfOrganisation: '',
      usersRoleInOrganisation: '',
      otherEmails: [],

      changeAvatarMode: false,
      file: null,
      fileName: '',

      avatarImagePath: '',
      showErrorComponent: false,
      isLoaded: false,
      showInputErrorBasic: false,
      showInputErrorOrg: false,
      showInputErrorExp: false,
      showInputErrorEmail: false,
      showInputErrorAddEmail: false,
      profileStrength: 0,
      email: '',
      isVerified: false,
      codeSent: false,
      verificationCode: '',

      isEditEmailMode: false,
      emailUpdated: '',

      isSocialAccount: undefined,
      oldPassword: '',
      newPassword: '',

      addNewEmailMode: false,
      newEmail: ''
    };
  }

  handleSimpleForSelect = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      showInputErrorBasic: false,
      showInputErrorOrg: false,
      showInputErrorExp: false
    });
  };

  handleOnChangeInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      showInputErrorBasic: false,
      showInputErrorOrg: false,
      showInputErrorExp: false,
      showInputErrorAddEmail: false
    });
  };

  verifyEmail = (email) => {
    var emailRex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(email)) {
      return true;
    }
    return false;
  };

  handleUpdateProfile = () => {
    let variables = {
      name: this.state.name,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country,
      postalCode: this.state.postalCode,
      citizenship: this.state.citizenship,
      aboutMe: this.state.aboutMe
    };

    if (
      variables.name &&
      variables.address &&
      variables.city &&
      variables.country &&
      variables.postalCode
    ) {
      this.handleUpdateUserProfile(variables);
    } else {
      this.setState({
        showInputErrorBasic: true
      });
    }
  };

  handleUpdateOrganisation = () => {
    let variables = {
      sectorOfOrganisation: this.state.sectorOfOrganisation,
      usersRoleInOrganisation: this.state.usersRoleInOrganisation
    };
    if (variables.sectorOfOrganisation && variables.usersRoleInOrganisation) {
      this.handleUpdateUserProfile(variables);
    } else {
      this.setState({
        showInputErrorOrg: true
      });
    }
  };

  handleAddNewEmail = () => {
    if (!validator.isEmail(this.state.newEmail)) {
      this.setState({ showInputErrorAddEmail: true });
      return;
    }

    this.props.setLoadingSpinner();
    userServices
      .addNewOptionalEmail(this.state.newEmail)
      .then((status) => {
        this.props.resetLoadingSpinner();
        this.fetchUserProfileData();
        this.props.showAlert(
          'success',
          'Success',
          'You have added a new email to your account, please check inbox and verify the email to log in using the new email'
        );
        this.setState({
          addNewEmailMode: false,
          newEmail: ''
        });
        this.setupReloadOnVerification();
      })
      .catch((err) => {
        console.log('Error in adding new optional email address, er=', err.message);
        this.props.resetLoadingSpinner();
        this.props.showAlert('error', 'Opps!', err.message);
      });
  };

  handleUpdateChallengeExperience = () => {
    let variables = {
      challengeExperienceTitle: this.state.challengeExperienceTitle
    };
    if (variables.challengeExperienceTitle) {
      this.handleUpdateUserProfile(variables);
    } else {
      this.setState({
        showInputErrorExp: true
      });
    }
  };

  handleDeleteOptionalEmail = (email) => {
    this.props.setLoadingSpinner();
    userServices
      .deleteOptionalEmail(email)
      .then((status) => {
        this.props.resetLoadingSpinner();
        if (status) {
          this.fetchUserProfileData();
        } else throw new Error('Error in deleting the optional email address');
      })
      .catch((err) => {
        console.log('err =', err.message);
        this.props.resetLoadingSpinner();
        this.props.showAlert('error', 'Opps!', err.message);
      });
  };

  handleGetVerificationLinkAgain = (email) => {
    this.props.setLoadingSpinner();
    userServices
      .sendEmailVeriicationLink(email)
      .then((status) => {
        this.props.resetLoadingSpinner();
        if (status) {
          this.props.showAlert(
            'success',
            'Email verification link is sent',
            'Please check your email inbox and verify the email'
          );
          this.setupReloadOnVerification();
        } else throw new Error('Error in sending email verification link');
      })
      .catch((err) => {
        console.log('err =', err.message);
        this.props.resetLoadingSpinner();
        this.props.showAlert('error', 'Opps!', err.message);
      });
  };

  setupReloadOnVerification() {
    localStorage.setItem('emailVerificationStatus', 'PENDING');
    if (intervalId) {
      clearInterval(intervalId);
    }
    intervalId = setInterval(() => {
      let status = localStorage.getItem('emailVerificationStatus');
      if (status === 'COMPLETED') {
        localStorage.setItem('emailVerificationStatus', 'RELOADED');
        clearInterval(intervalId);
        window.location.reload();
      }
    }, 1000);
  }

  componentDidMount() {
    let data = cookiesHelper.get(constants.Settings.LoggedInCookieName);
    if (data) {
      this.props.setLoggedInUserData(data);
      this.fetchUserProfileData();
      this.fetchEmailVerificationStatus();
      this.isSocialAccount();
    } else {
      this.setState({ showErrorComponent: true });
      this.props.setRedirectOnError('/home/index');
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
    helper.setPageTitle('BestInCrowd');
  }

  render() {
    const { classes } = this.props;
    helper.setPageTitle('Edit Profile | BestInCrowd');
    return (
      <div>
        {this.state.showErrorComponent && (
          <div>
            <SimpleBreadcrumbs
              breadLinkData={[
                { name: 'Dashboard', link: '/dashboard/index' },
                { name: 'Error', link: '/dashboard/edit-profile' }
              ]}
            />
            <GridContainer direction="row" justify="space-evenly" alignItems="center">
              <GridItem>
                <ErrorPage
                  errorCode={403}
                  message={'Access Denied! :('}
                  description={'You are not logged in, please login to see this page'}
                />
              </GridItem>
            </GridContainer>
          </div>
        )}
        {this.state.isLoaded && (
          <div>
            <SimpleBreadcrumbs
              breadLinkData={[
                { name: 'Dashboard', link: '/dashboard/index' },
                { name: 'Edit Profile', link: '/dashboard/edit-profile' }
              ]}
            />
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                {this.renderBasicInfoForm()}
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                {this.renderProfileEditProgress()}
                {this.renderOrganisationForm()}
                {this.renderChallengeExpForm()}
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                {this.renderProfileAvatarForm()}
                {this.renderEmailVerificationStatus()}
                {this.renderAddNewEmailSection()}
                {this.renderSetPasswordUISection()}
              </GridItem>
            </GridContainer>
          </div>
        )}
      </div>
    );
  }

  renderEmailVerificationStatus = () => {
    const { classes } = this.props;
    return (
      <Card className={classes.cardBGColor} icon>
        <CardHeader>
          <CardIcon color="success">
            <PermIdentity />
          </CardIcon>
          <h4 className={classes.cardIconTitleProfile}>Email Verification Status</h4>
        </CardHeader>
        <CardBody>
          <br />
          <GridContainer alignItems="center" justify="center">
            {this.state.otherEmails &&
              this.state.otherEmails.map((item) => {
                return (
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer wrap="wrap">
                      <GridItem xs>
                        <span style={{ fontSize: '1.3em', color: '#FFF' }}>{item.email}</span>
                      </GridItem>
                      <GridItem>
                        {item.isVerified ? (
                          <Tooltip title="Email Verified">
                            <span style={{ color: 'green' }}>
                              <VerifiedIcon variant="sm" />
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Pending Verification">
                            <span style={{ color: 'red' }}>
                              <VerifiedIcon variant="sm" />
                            </span>
                          </Tooltip>
                        )}
                      </GridItem>
                    </GridContainer>
                    <Divider style={{ color: '#FFFFFF', margin: '10px 0px 10px 0px' }} />
                  </GridItem>
                );
              })}
          </GridContainer>
        </CardBody>
      </Card>
    );
  };

  renderAddNewEmailSection = () => {
    if (this.state.isSocialAccount === true || this.state.isSocialAccount === undefined) return null;
    const { classes } = this.props;

    let hasPendingEmailVerification = false;
    let emailToBeVerified = '';
    this.state.otherEmails.map((emailItem) => {
      if (!emailItem.isVerified) {
        hasPendingEmailVerification = true;
        emailToBeVerified = emailItem.email;
      }
    });

    return (
      <Card className={classes.cardBGColor} icon>
        <CardHeader>
          <CardIcon color="success">
            <PermIdentity />
          </CardIcon>
          <h4 className={classes.cardIconTitleProfile}>Change Email</h4>
        </CardHeader>
        <CardBody>
          <br />
          <GridContainer alignItems="center" justify="center">
            {this.state.isSocialAccount === false && !hasPendingEmailVerification && !this.state.addNewEmailMode ? (
              <GridItem xs={12} lg={12}>
                <Button color="gmgGreen" onClick={() => this.setState({ addNewEmailMode: true })}>
                  Change Email
                </Button>
              </GridItem>
            ) : null}

            {this.state.isSocialAccount === false && hasPendingEmailVerification && !this.state.addNewEmailMode ? (
              <GridItem xs={12} lg={12}>
                <Button
                  color="gmgGreen"
                  onClick={() => {
                    this.handleGetVerificationLinkAgain(emailToBeVerified);
                  }}>
                  Get Verification Link Again
                </Button>
                <Button
                  color="danger"
                  onClick={() => {
                    this.handleDeleteOptionalEmail(emailToBeVerified);
                  }}>
                  Delete optional Email
                </Button>
              </GridItem>
            ) : null}

            {this.state.isSocialAccount === false && !hasPendingEmailVerification && this.state.addNewEmailMode ? (
              <GridItem xs={12} lg={12}>
                <GridContainer>
                  <GridItem xs={12} lg={12}>
                    <CustomInput
                      labelText="New Email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        error:
                          this.state.showInputErrorAddEmail &&
                          this.state.newEmail &&
                          !validator.isEmail(this.state.newEmail),
                        name: 'newEmail',
                        value: this.state.newEmail,
                        onChange: this.handleOnChangeInput
                      }}
                    />
                  </GridItem>
                  <GridItem xs>
                    <Button
                      color="gmgGreen"
                      onClick={() => {
                        this.handleAddNewEmail();
                      }}>
                      Update & Verify
                    </Button>
                    <Button
                      color="danger"
                      onClick={() => {
                        this.setState({
                          addNewEmailMode: false,
                          showInputErrorAddEmail: false,
                          newEmail: ''
                        });
                      }}>
                      Cancel
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            ) : null}
          </GridContainer>
        </CardBody>
      </Card>
    );
  };

  renderSetPasswordUISection = () => {
    const { classes } = this.props;
    return (
      <Card className={classes.cardBGColor} icon>
        <CardHeader>
          <CardIcon color="success">
            <PermIdentity />
          </CardIcon>
          <h4 className={classes.cardIconTitleProfile}>
            {this.state.isSocialAccount === true ? 'Set ' : 'Change '} Password
          </h4>
        </CardHeader>
        <CardBody>
          <br />
          <GridContainer alignItems="center" justify="center">
            {this.state.isSocialAccount === true && (
              <GridItem xs={12} sm={12} md={12}>
                <h5>
                  You have used social login for your account, If you want to change it to a password based account, set
                  a password from here. You won't be able to use social login anymore.
                </h5>
              </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem>
                  <PasswordUpdateComponent
                    hasCurrentPassword={!this.state.isSocialAccount}
                    onCancel={() => {
                      this.setState({ isEditEmailMode: false });
                    }}
                    onUpdatePassword={(res) => {
                      if (res) {
                        this.isSocialAccount();
                      } else {
                        console.log('Error setting password');
                        this.props.showAlert('error', 'Opps!', 'Error occured while setting the password');
                      }
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  };

  renderCitizenships = () => {
    const { classes } = this.props;

    return Constants.Country.map((citizenship, index) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={citizenship}>
          {citizenship}
        </MenuItem>
      );
    });
  };

  renderStates = () => {
    const { classes } = this.props;

    return Constants.States.map((state, index) => {
      return (
        <MenuItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={state}>
          {state}
        </MenuItem>
      );
    });
  };

  renderSectors = () => {
    const { classes } = this.props;
    return Constants.Sectors.map((sector, index) => {
      return (
        <ListItem
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={sector}>
          {sector}
        </ListItem>
      );
    });
  };

  renderBasicInfoForm = () => {
    const { classes } = this.props;
    return (
      <Card className={classes.cardBGColor} icon>
        <CardHeader>
          <CardIcon color="success">
            <PermIdentity />
          </CardIcon>
          <h4 className={classes.cardIconTitleProfile}>
            Edit Profile - <small style={{ color: '#FFFFFF' }}>Complete your profile</small>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Name"
                formControlProps={{
                  fullWidth: true,
                  color: '#FFF'
                }}
                inputProps={{
                  error: this.state.showInputErrorBasic && !this.state.name,
                  name: 'name',
                  value: this.state.name,
                  onChange: this.handleOnChangeInput
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Address"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  error: this.state.showInputErrorBasic && !this.state.address,
                  name: 'address',
                  value: this.state.address,
                  onChange: this.handleOnChangeInput
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="City"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  error: this.state.showInputErrorBasic && !this.state.city,
                  name: 'city',
                  value: this.state.city,
                  onChange: this.handleOnChangeInput
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={`${classes.citizenshipFontStyle}`}>
                  State (if any)
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.selectStyle
                  }}
                  value={this.state.state}
                  onChange={this.handleSimpleForSelect}
                  inputProps={{
                    name: 'state',
                    id: 'simple-select'
                  }}>
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}>
                    State
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="California">
                    California
                  </MenuItem>
                  <Divider style={{ margin: '5px 5px 5px 5px', backgroundColor: '#B7B8BC', height: '2px' }} />
                  {this.renderStates()}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
                error={this.state.showInputErrorBasic && !this.state.country}>
                <InputLabel htmlFor="simple-select" className={`${classes.citizenshipFontStyle}`}>
                  Country
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.selectStyle
                  }}
                  value={this.state.country}
                  onChange={this.handleSimpleForSelect}
                  inputProps={{
                    error: this.state.country ? false : true,
                    name: 'country',
                    id: 'simple-select'
                  }}>
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}>
                    Country
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="United States">
                    United States
                  </MenuItem>
                  <Divider style={{ margin: '5px 5px 5px 5px', backgroundColor: '#B7B8BC', height: '2px' }} />
                  {this.renderCitizenships()}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel htmlFor="simple-select" className={`${classes.citizenshipFontStyle}`}>
                  Citizenship (if different from above)
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.selectStyle
                  }}
                  value={this.state.citizenship}
                  onChange={this.handleSimpleForSelect}
                  inputProps={{
                    name: 'citizenship',
                    id: 'simple-select'
                  }}>
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem
                    }}>
                    Citizenship
                  </MenuItem>
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value="United States">
                    United States
                  </MenuItem>
                  <Divider style={{ margin: '5px 5px 5px 5px', backgroundColor: '#B7B8BC', height: '2px' }} />
                  {this.renderCitizenships()}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Postal Code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  error: this.state.showInputErrorBasic && !this.state.postalCode,
                  name: 'postalCode',
                  value: this.state.postalCode,
                  onChange: this.handleOnChangeInput
                }}
              />
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="About Me"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  error: this.state.showInputErrorBasic && !this.state.aboutMe,
                  name: 'aboutMe',
                  value: this.state.aboutMe,
                  multiline: true,
                  rows: 5,
                  onChange: this.handleOnChangeInput
                }}
              />
            </GridItem>
          </GridContainer>
          <Button color="gmgGreen" className={classes.updateProfileButton} onClick={this.handleUpdateProfile}>
            Update
          </Button>
          <Clearfix />
        </CardBody>
      </Card>
    );
  };

  renderProfileEditProgress = () => {
    const { classes } = this.props;
    return (
      <Card className={classes.cardBGColor} icon>
        <CardHeader>
          <CardIcon color="success">
            <PermIdentity />
          </CardIcon>
          <h4 className={classes.cardIconTitleProfile}> Your Profile Strength </h4>
        </CardHeader>
        <CardBody profile>
          <GridContainer container direction="row" justify="space-between" alignItems="center">
            <GridItem> 0% </GridItem>
            <GridItem> 100% </GridItem>
          </GridContainer>
          <LinearProgress variant="determinate" value={this.state.profileStrength} />
        </CardBody>
        <CardFooter>
          <br />
        </CardFooter>
      </Card>
    );
  };

  renderDefaultValue = () => {
    return this.state.sectorOfOrganisation;
  };

  renderOrganisationForm = () => {
    const { classes } = this.props;
    return (
      <Card className={classes.cardBGColor} icon>
        <CardHeader>
          <CardIcon color="success">
            <PermIdentity />
          </CardIcon>
          <h4 className={classes.cardIconTitleProfile}>Your Organization</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <FormControl
                fullWidth
                className={classes.selectFormControl}
                error={this.state.showInputErrorOrg && !this.state.sectorOfOrganisation}>
                <InputLabel htmlFor="simple-select" className={`${classes.citizenshipFontStyle}`}>
                  Sector
                </InputLabel>
                <Select
                  displayEmpty={true}
                  renderValue={this.renderDefaultValue}
                  MenuProps={{
                    className: classes.selectMenu
                  }}
                  classes={{
                    select: classes.selectStyle
                  }}
                  value={this.state.sectorOfOrganisation}
                  onChange={this.handleSimpleForSelect}
                  inputProps={{
                    error: !this.state.sectorOfOrganisation,
                    name: 'sectorOfOrganisation',
                    id: 'simple-select'
                  }}>
                  <CustomInputForSector
                    className={classes.sectorCustomInputStyle}
                    labelText="Other"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      error: this.state.showInputErrorOrg && !this.state.sectorOfOrganisation,
                      name: 'sectorOfOrganisation',
                      value: this.state.sectorOfOrganisation,
                      onChange: this.handleOnChangeInput
                    }}
                  />
                  <Divider style={{ margin: '5px 5px 5px 5px', backgroundColor: '#B7B8BC', height: '2px' }} />
                  {this.renderSectors()}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Your Role"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  error: this.state.showInputErrorOrg && !this.state.usersRoleInOrganisation,
                  name: 'usersRoleInOrganisation',
                  value: this.state.usersRoleInOrganisation,
                  onChange: this.handleOnChangeInput
                }}
              />
            </GridItem>
          </GridContainer>
          <Button color="gmgGreen" className={classes.updateProfileButton} onClick={this.handleUpdateOrganisation}>
            Update
          </Button>
          <Clearfix />
        </CardBody>
      </Card>
    );
  };

  renderChallengeExpForm = () => {
    const { classes } = this.props;
    return (
      <Card className={classes.cardBGColor} icon>
        <CardHeader>
          <CardIcon color="success">
            <PermIdentity />
          </CardIcon>
          <h4 className={classes.cardIconTitleProfile}>Your Challenge Experience</h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="Experience"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  error: this.state.showInputErrorExp && !this.state.challengeExperienceTitle,
                  name: 'challengeExperienceTitle',
                  value: this.state.challengeExperienceTitle,
                  onChange: this.handleOnChangeInput,
                  multiline: true,
                  rows: 5
                }}
              />
            </GridItem>
          </GridContainer>
          <Button
            color="gmgGreen"
            className={classes.updateProfileButton}
            onClick={this.handleUpdateChallengeExperience}>
            Update
          </Button>
          <Clearfix />
        </CardBody>
      </Card>
    );
  };

  renderProfileAvatarForm = () => {
    const { classes } = this.props;
    return (
      <Card className={classes.cardBGColor} icon>
        <CardHeader>
          <CardIcon color="success">
            <PermIdentity />
          </CardIcon>
          <h4 className={classes.cardIconTitleProfile}>Your Profile Avatar</h4>
        </CardHeader>
        <CardBody>
          <CardAvatar profile className={classes.cardAvatarCustomStyle}>
            <div className={classes.avatarSquare}>
              {this.state.avatarImagePath ? (
                <img
                  className={classes.avatarImgTagStyle}
                  src={
                    this.state.avatarImagePath.includes('http')
                      ? this.state.avatarImagePath
                      : Constants.Settings.APIURL + this.state.avatarImagePath
                  }
                  alt="Image of user's profile avatar"
                />
              ) : (
                <img className={classes.avatarImgTagStyle} src={avatar} alt="Image of user's profile avatar default" />
              )}
            </div>
          </CardAvatar>
          <br />
          {!this.state.changeAvatarMode && (
            <GridContainer row alignItems="center" justify="center">
              <GridItem lg={6} md={6}>
                <Button color="gmgGreen" onClick={() => this.setState({ changeAvatarMode: true })}>
                  Change Avatar
                </Button>
              </GridItem>

              <GridItem lg={6} md={6}>
                <Button color="danger" disabled={!this.state.avatarImagePath} onClick={this.handleRemoveProvieAvatar}>
                  Remove Avatar
                </Button>
              </GridItem>
            </GridContainer>
          )}

          {this.state.changeAvatarMode && (
            <div>
              <GridContainer row alignItems="center" justify="center">
                <GridItem lg={12} md={12}>
                  <CustomInput
                    inputProps={{
                      type: 'file',
                      name: 'profileAvatar',
                      onChange: this.handleFileChange
                    }}
                  />
                </GridItem>
                <GridItem lg={6} md={6} sm={12}>
                  <Mutation
                    mutation={uploadFileMutation}
                    fetchPolicy="no-cache"
                    context={{ headers: { 'x-apollo-operation-name': 'upload-file' } }}>
                    {(mutation, { loading }) => (
                      <Button color="gmgGreen" onClick={() => this.uploadFile(mutation)}>
                        Upload
                      </Button>
                    )}
                  </Mutation>
                </GridItem>
                <GridItem lg={6} md={6} sm={12}>
                  <Button color="danger" onClick={() => this.setState({ changeAvatarMode: false })}>
                    Cancel
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
          )}
        </CardBody>
      </Card>
    );
  };

  fetchUserProfileData = () => {
    this.props.setLoadingSpinner();
    userServices
      .getCurrentUser()
      .then((data) => {
        this.props.resetLoadingSpinner();
        let profileStrength = 0;
        if (data.name && data.address && data.city && data.country && data.postalCode && data.aboutMe) {
          profileStrength += 40;
        }
        if (data.sectorOfOrganisation && data.usersRoleInOrganisation) {
          profileStrength += 30;
        }
        if (data.challengeExperienceTitle) {
          profileStrength += 30;
        }
        this.setState({
          name: data.name,
          avatarImagePath: data.avatarImagePath,
          address: data.address,
          city: data.city,
          country: data.country,
          state: data.state,
          postalCode: data.postalCode,
          citizenship: data.citizenship,
          aboutMe: data.aboutMe,
          sectorOfOrganisation: data.sectorOfOrganisation,
          usersRoleInOrganisation: data.usersRoleInOrganisation,
          challengeExperienceTitle: data.challengeExperienceTitle,
          challengeExperienceProblemSolved: data.challengeExperienceProblemSolved,
          challengeExperienceMethodUsed: data.challengeExperienceMethodUsed,
          showErrorComponent: false,
          isLoaded: true,
          profileStrength: profileStrength,
          otherEmails: data.otherEmails
        });
      })
      .catch((err) => {
        console.log('data service updateUser err', err.message);
        this.props.resetLoadingSpinner();
        this.setState({
          showErrorComponent: true
        });
        this.props.setRedirectOnError('/home/index');
      });
  };

  isSocialAccount = () => {
    userServices
      .isSocialAccount()
      .then((res) => {
        if (res === true || res === false) {
          this.setState({ isSocialAccount: res });
        } else throw new Error('Error fetching account type information');
      })
      .catch((err) => {
        console.log('account info fetching error, err =', err.message);
        this.setState({ isSocialAccount: undefined });
      });
  };

  fetchEmailVerificationStatus = () => {
    userServices
      .isEmailVerified()
      .then((data) => {
        this.setState({ ...data, emailUpdated: data.email });
      })
      .catch((err) => {
        console.log('error in fetching the email vcerification status =', err.message);
      });
  };

  handleUpdateUserProfile = (variables) => {
    this.props.setLoadingSpinner();
    return userServices
      .updateUser(variables)
      .then((data) => {
        this.props.resetLoadingSpinner();
        this.props.showAlert('success', 'Profile Updated', 'Your profile has been updated successfully');
        let profileStrength = 0;
        if (
          data.name &&
          data.address &&
          data.city &&
          data.country &&
          data.postalCode &&
          data.citizenship &&
          data.aboutMe
        ) {
          profileStrength += 40;
        }
        if (data.sectorOfOrganisation && data.usersRoleInOrganisation) {
          profileStrength += 30;
        }
        if (data.challengeExperienceTitle) {
          profileStrength += 30;
        }
        this.setState({
          profileStrength: profileStrength,
          avatarImagePath: data.avatarImagePath,
          email: data.email,
          emailUpdated: data.email
        });
        let loggedInUserData = { ...this.props.loggedInUserData, userName: data.name };
        cookiesHelper.set(constants.Settings.LoggedInCookieName, loggedInUserData);
        this.props.setLoggedInUserData(loggedInUserData);
        return true;
      })
      .catch((err) => {
        console.log('data services userUpdate err', err.message);
        this.props.resetLoadingSpinner();
        this.props.showAlert('error', 'Error!', err.message);
        return false;
      });
  };

  handleFileChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) {
      this.setState({ file: null, fileName: '' });
      return;
    }
    reader.onloadend = () => {
      this.setState({
        file: { file },
        fileName: file.name
      });
    };
    reader.readAsDataURL(file);
  };

  uploadFile = (mutation) => {
    if (this.state.file) {
      this.props.setLoadingSpinner();

      mutation({
        mutation: uploadFileMutation,
        variables: { ...this.state.file, uploadType: 'image' },
        fetchPolicy: 'no-cache',
		context: {
		  headers: {
			'x-apollo-operation-name': 'upload-file'
		  }
		},
      })
        .then((res) => {
          if (res.data.uploadFile.status == true) {
            let filePath = res.data.uploadFile.data.path;
            this.handleUpdateUserProfile({ avatarImagePath: filePath }).then((res) => {
              if (res) {
                this.setState({ changeAvatarMode: false });
              }
            });
          } else {
            throw new Error(res.data.uploadFile.message);
          }
        })
        .catch((err) => {
          this.props.resetLoadingSpinner();
          this.props.showAlert('error', 'Error!', err.message);
          console.log('err = ', err.message);
        });
    } else {
      this.props.showAlert('error', 'Error!', 'No file is selected');
      console.log('No File is selected');
    }
  };

  handleRemoveProvieAvatar = () => {
    this.handleUpdateUserProfile({ removeProfileAvatar: true });
  };
}

EditProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedInUserData: PropTypes.object.isRequired,
  setLoggedInUserData: PropTypes.func.isRequired,
  showAlert: PropTypes.func.isRequired,
  setLoadingSpinner: PropTypes.func.isRequired,
  resetLoadingSpinner: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    loggedInUserData: state.loggedInUserData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setRedirectOnError: (redirectTo = '') => dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
    setLoggedInUserData: (data) => dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
    showAlert: (alertType, title, description) =>
      dispatch({
        type: actionTypes.SHOW_ALERT,
        payload: {
          alertType,
          title,
          description
        }
      }),
    setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
    resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET })
  };
};

let extraStyle = (theme) => ({
  messageError: {
    margin: '5px 2px 5px 2px',
    color: 'red',
    textAlign: 'center'
  },
  messageSuccess: {
    margin: '5px 2px 5px 2px',
    color: 'green',
    textAlign: 'center'
  },
  citizenshipFontStyle: {
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    color: '#B1B1B1'
  },
  selectStyle: {
    color: '#FFFFFF',
    fontWeight: '400',
    fontSize: '.9em'
  },
  cardAvatarCustomStyle: {
    marginTop: '10px'
  },
  sectorCustomInputStyle: {
    margin: '5px 5px 5px 5px',
    backgroundColor: '#000000'
  },
  cardBGColor: {
    backgroundColor: '#434343',
    color: '#FFFFFF'
  },
  cardIconTitleProfile: {
    color: '#FFFFFF !important'
  },
  ...extendedFormsStyle,

  avatarSquare: {
    position: 'relative',
    width: '100%',
    '&,&:after': {
      content: '',
      display: 'block',
      paddingBottom: '100%'
    }
  },

  avatarImgTagStyle: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
});

let finalStyle = combineStyles(userProfileStyles, extraStyle);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(finalStyle)(EditProfilePage)));
