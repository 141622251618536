import React from "react"
import { withStyles, withTheme } from "@material-ui/core/Styles"

import PropTypes from "prop-types"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"

import combineStyles from "utils/combineStyle"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

import helper from "../../helper"
import validator from "validator"

import querystring from "querystring"

import userServices from "dataServices/userServices"

let intervalId = 0
let intervalId2 = 0
class VerifyEmailPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			userId: "",
			code: "",
			isLoading: true,
			isVerified: false,
			isError: false,
			errorMessage: "",
			displayClosingMessage: false,
			closingTimer: 10
		}
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props
		let qParams = querystring.parse(this.props.location.search.slice(1))

		let uid = qParams.uid || ""
		let code = qParams.code || ""

		if (!validator.isUUID(code) || !validator.isUUID(uid)) {
			this.setState({ isError: true, errorMessage: "Invalid email verification link" })
			return
		}

		this.setState({
			userId: uid,
			code: code
		})

		this.markVerifyEmail(uid, code)
	}

	markVerifyEmail = (userId, code) => {
		userServices
			.markEmailVerified(userId, code)
			.then((res) => {
				if (res) {
					this.setState({
						isVerified: true,
						isLoading: false
					})

					this.setupCloseTabOnVerification()
				} else throw new Error("Error verifying email address, may be invalid link")
			})
			.catch((err) => {
				console.log("Error occured while verifying the email address, err=", err.message)
				this.setState({
					isVerified: false,
					isError: true,
					isLoading: false,
					errorMessage: err.message
				})
			})
	}

	setupCloseTabOnVerification() {
		localStorage.setItem("emailVerificationStatus", "COMPLETED")
		if (intervalId) {
			clearInterval(intervalId)
		}
		intervalId = setInterval(() => {
			let status = localStorage.getItem("emailVerificationStatus")
			if (status === "RELOADED") {
				localStorage.setItem("emailVerificationStatus", "")
				this.setState({ displayClosingMessage: true })
				clearInterval(intervalId)
				setTimeout(() => {
					clearTimeout(intervalId2)
					window.close()
				}, 10000)
				intervalId2 = setInterval(() => {
					this.setState({ closingTimer: Math.max(0, this.state.closingTimer - 1) })
				}, 1000)
			}
		}, 1000)
	}

	render() {
		helper.setPageTitle("Verify Email | BestInCrowd")

		if (this.state.isLoading) {
			console.log("isLoading")
			return (
				<div>
					<Card>
						<CardBody>
							<h4 style={{ textAlign: "center" }}> Loading... </h4>
						</CardBody>
					</Card>
				</div>
			)
		}

		if (this.state.isVerified) {
			return (
				<div>
					<Card>
						<CardBody>
							<h4 style={{ textAlign: "center" }}> Thank you! Your email is now verified! </h4>
							{this.state.displayClosingMessage && (
								<h4 style={{ textAlign: "center" }}>
									This tab will close automatically in {this.state.closingTimer} seconds.
								</h4>
							)}
						</CardBody>
					</Card>
				</div>
			)
		} else if (this.state.isError) {
			return (
				<div>
					<Card>
						<CardBody>
							<h4 style={{ textAlign: "center" }}> {this.state.errorMessage} </h4>
						</CardBody>
					</Card>
				</div>
			)
		}
	}
}

VerifyEmailPage.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPage)
