import apolloFetcher from "apolloFetcher";
import { get } from "lodash"

export const adminCreateUser = async (name, username, email, password) => {
	const query = `
    mutation adminCreateUser(
      $name: String
      $email: String
      $username: String
      $password: String
    ) {
      adminCreateUser(
        name: $name
        email: $email
        username: $username
        password: $password
      ) {
        status
        message
      }
    }	
  `;

  const variables = {
    name: name,
    email: email,
    username: username,
    password: password,
  };

  try {
    const response = await apolloFetcher.fetchWithToken({ query, variables });
    
    return get(response, "data.adminCreateUser");
  } catch (err) {
    return {
      status: 500,
      message: err.message || err,
    };
  }
};

export const adminEditUser = async (name, username, email) => {
	const query = `
    mutation adminEditUser(
      $name: String
      $username: String
      $email: String
    ) {
      adminEditUser(
        name: $name
        username: $username
        email: $email
      ) {
        status
        message
      }
    }	
  `;

  const variables = {
    name: name,
    username: username,
    email: email,
  };

  try {
    const response = await apolloFetcher.fetchWithToken({ query, variables });

    return get(response, "data.adminEditUser");
  } catch (err) {
    return {
      status: 500,
      message: err.message || err,
    };
  }
};

export const adminVerifyUserEmail = async (userId) => {
	const query = `
    mutation adminVerifyUserEmail($userId: String) {
      adminVerifyUserEmail(
        userId: $userId
      ) {
        status
        message
      }
    }	
  `;

  const variables = {
    userId: userId,
  };

  try {
    const response = await apolloFetcher.fetchWithToken({ query, variables });

    return get(response, "data.adminVerifyUserEmail");
  } catch (err) {
    return {
      status: 500,
      message: err.message || err,
    };
  }
};

export const getUserChallengesByAdmin = async (userId, roleType) => {
  const query = `
    query adminGetUserChallenges(
      $userId: String
      $roleType: String!
    ) {
      adminGetUserChallenges(
        userId: $userId
        roleType: $roleType
      ) {
        status
        message
        data {
          id
          title
          phase
          sponsorsName
          challengeStatus
          prizeAmount
          participantCount
        }
      }
    }
  `;

  const variables = {
    userId: userId,
    roleType: roleType,
  };

  try {
    const response = await apolloFetcher.fetchWithToken({ query, variables });

    return get(response, "data.adminGetUserChallenges");
  } catch (err) {
    return {
      status: 500,
      message: err.message || err,
    };
  }
};