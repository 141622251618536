import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CreateChallengeUI from "../../Pages/CreateChallengePage.jsx";
import ErrorPage from "views/Pages/ErrorPage";
import SimpleBreadcrumbs from "../../../components/SimpleBreadcumbs.jsx";
import CustomModal from "components/general/CustomModal";
import CustomButtons from "components/CustomButtons/Button.jsx";

import actionTypes from "reduxjs/actionTypes";

import userServices from "dataServices/userServices";
import challengeServices from "dataServices/challengeServices";
import ChallengeCards from "./ChallengeCards";

import PhaseUtils from "utils/phaseUtils";
import helper from "../../../helper";
import cookieHelper from "../../../cookiesHelper";
import backgroundImage from "assets/img/BIC-wallpaper.webp";
import authentication from "../../../authentication";

import constant from "../../../constants";
import constants from "../../../constants";
import { challengeTitle } from "./constants";

import image from "assets/img/sidebar-2.jpg";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import appStyle from "assets/jss/appStyle.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx";
import combineStyles from "utils/combineStyle";
import styles from "./styles.module.scss";

class DashboardPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			challengeId: "",
			mobileOpen: false,
			miniActive: false,
			image: image,
			color: "blue",
			bgColor: "black",
			hasImage: true,
			fixedClasses: "dropdown",
			showErrorComponent: false,
			userId: "",
			challenges: [],
			draftChallenges: [],
			challengesWithPendingInvitation: [],
			redirect: false,
			CanGetAllChallenge: false,
			CanCreateOrUpdateEvaluationTeam: false,
			CanCreateOrUpdateManageChallengeInvitation: false,
			CanCreateOrUpdateEvaluationScore: false,
			CanCreateOrUpdateEvaluationCriteria: false,
			CanCreateChallenge: false,
			showChallengeBuilder: false,
			selectedChallenge: null,
			CanManageChallenge: false,
			anchorEl: null,
			challengeCardTitle: "My Challenges",
			draftChallengeCardTitle: "My Draft Challenges",
			challengesWithPendingInvitationsCardTitle: "My Challenges with pending invitations",
			canGetFinalLeaderboardForChallenge: false,
			mostRecentChallenge: null,
			activeRow: null,
			isLoading: true,
		}
		
		this.getMyChallenges = this.getMyChallenges.bind(this)
	}

	fetchCurrentUserProfile = () => {
		this.props.setLoadingSpinner()
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				console.log("Current User", data)
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				console.log("Error getting current User", err)
				this.props.resetLoadingSpinner()
				return null
			})
	}

	getUserProfile(userId) {
		this.props.setLoadingSpinner()
		return userServices
			.getUserProfileById(userId, `userId name roles{ RoleId challengeId teamId }`)
			.then((data) => {
				console.log("data services user", data)
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting userProfile", err)
				return null
			})
	}

	fetchChallenges = () => {
		this.props.setLoadingSpinner()
		return challengeServices
			.getMyChallenges()
			.then((data) => {
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting challlenges", err)
				return null
			})
	}

	fetchDraftChallenges = () => {
		this.props.setLoadingSpinner()
		return challengeServices
			.getMyDraftChallenges()
			.then((data) => {
				console.log("Draft challenges = ", data)
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting draft challlenges", err)
				return null
			})
	}

	fetchChallengesWithPendingInvitations = () => {
		return challengeServices
			.getChallengesWithPendingInvitations()
			.then((data) => {
				console.log("Challenges with pending invitations = ", data)
				return data
			})
			.catch((err) => {
				console.log("Error getting challlenges with pending invitations, err =", err.message)
				return null
			})
	}

	processPhaseData = (challenges) => {
		let tempchallenges = challenges
		var today = new Date()
		var date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear()
		let i
		for (i in tempchallenges) {
			let nextPhase = PhaseUtils.getNextPhase(tempchallenges[i].challengeDetails.phases)
			let currentPhase = "Not Available" //PhaseUtils.getCurrentPhase(tempchallenges[i].challengeDetails.phases)
			let challenge = tempchallenges[i]

			if (challenge.challengeDetails.currentPhase) {
				currentPhase = challenge.challengeDetails.currentPhase.phaseTitle
				let remainingDays = PhaseUtils.getNextPhaseCountDownValue(
					challenge.challengeDetails.currentPhase
				)
				if (remainingDays == "") tempchallenges[i].remainingDays = Number.MAX_SAFE_INTEGER
				else tempchallenges[i].remainingDays = remainingDays
			} else {
				tempchallenges[i].remainingDays = Number.MAX_SAFE_INTEGER
			}
			if (nextPhase) {
				tempchallenges[i].nextPhase = nextPhase.title
			} else {
				tempchallenges[i].nextPhase = "Coming soon"
			}

			tempchallenges[i].currentPhase = currentPhase
		}
		if (tempchallenges) {
			tempchallenges.sort(function (a, b) {
				return a.remainingDays > b.remainingDays ? 1 : a.remainingDays < b.remainingDays ? -1 : 0
			})
		}

		return tempchallenges
	}

	fetchAllData = (userId) => {
		Promise.all([
			this.getUserProfile(userId),
			this.fetchChallenges(),
			this.fetchDraftChallenges(),
			this.fetchChallengesWithPendingInvitations()
		])
			.then((result) => {
				let draftChallenges = result[2] ? result[2] : []
				let challenges = result[1] ? result[1] : []
				let userProfile = result[0] ? result[0] : null
				let challengesWithPendingInvitation = result[3] ? result[3] : []
				let i
				for (i in userProfile.roles) {
					if (userProfile.roles[i].RoleId == "superAdmin") {
						this.setState({ challengeCardTitle: "All Challenges" })
					}
				}

				challenges = this.processPhaseData(challenges)
				draftChallenges = this.processPhaseData(draftChallenges)
				challengesWithPendingInvitation = this.processPhaseData(challengesWithPendingInvitation)

				this.setState(
					{
						challenges: challenges,
						draftChallenges: draftChallenges,
						challengesWithPendingInvitation: challengesWithPendingInvitation
					},
					() => {
						this.setActiveRow()
					}
				)
			})
			.catch((err) => {
				console.log("Error:", err.message)
			})
	}

	getMyChallenges() {
		this.fetchCurrentUserProfile().then((data) => {
			if (data) {
				this.setState(
					{
						userId: data.userId
					},
					() => {
						this.fetchAllData(data.userId)
					}
				)
			} else {
				this.setState({ showErrorComponent: true })
				this.props.setRedirectOnError("/home/index")
			}
		})
	}

	pushRoutes = (route) => {
		this.props.history.push(route)
	}

	onClickAddPhaseButton = challenge => {
		this.setState({
			showChallengeBuilder: true,
			selectedChallenge: challenge,
		});
	}

	setMostRecentChallenge = (mostRecentChallenge) => {
		this.setState({
			mostRecentChallenge: mostRecentChallenge
		})
	}

	componentDidMount() {
		this.props.resetBreadCrumbStack()
		this.props.pushBreadCrumbStack({ name: "Dashboard", link: "/dashboard/index" })
		console.log(this.props.breadCrumbStack)
		if (this.props.location.hash) {
			let decodedHash = decodeURI(this.props.location.hash)
			let hashValues = decodedHash.split("#")[1].split("&")
			let valueArray = new Array()
			var data = {}
			for (var i = 0; i < hashValues.length; i++) {
				let tempArray = hashValues[i].split("=")
				valueArray[tempArray[0]] = tempArray[1]
				data[tempArray[0]] = tempArray[1]
			}
			cookieHelper.set(constants.Settings.LoggedInCookieName, data)
			this.props.setLoggedInUserData(data)
			window.location.href = window.location.origin + window.location.pathname
		} else {
			let data = cookieHelper.get(constants.Settings.LoggedInCookieName)
			if (data) {
				this.props.setLoggedInUserData(data)

				let requiredPermissionsObjects = [
					{ permissionName: constant.Functionalities.CanGetAllChallenge, objectId: null },
					{ permissionName: constant.Functionalities.CanCreateChallenge, objectId: null },
					{
						permissionName: constant.Functionalities.CanCreateOrUpdateEvaluationTeam,
						objectId: null
					},
					{
						permissionName: constant.Functionalities.CanCreateOrUpdateManageChallengeInvitation,
						objectId: null
					},
					{
						permissionName: constant.Functionalities.CanCreateOrUpdateEvaluationScore,
						objectId: null
					},
					{
						permissionName: constant.Functionalities.CanCreateOrUpdateEvaluationCriteria,
						objectId: null
					},
					{ permissionName: constant.Functionalities.CanManageChallenge, objectId: null },
					{
						permissionName: constant.Functionalities.CanGetFinalLeaderboardForChallenge,
						objectId: null
					}
				]
				authentication
					.hasMultiplePermissions(requiredPermissionsObjects)
					.then((permissionResponseObject) => {
						if (permissionResponseObject) {
							permissionResponseObject.map((object) => {
								if (
									object.permissionName == constant.Functionalities.CanGetAllChallenge &&
									object.permissionStatus
								)
									this.setState({ CanGetAllChallenge: true })
								else if (
									object.permissionName ==
										constant.Functionalities.CanCreateOrUpdateEvaluationTeam &&
									object.permissionStatus
								)
									this.setState({ CanCreateOrUpdateEvaluationTeam: true })
								else if (
									object.permissionName == constant.Functionalities.CanCreateChallenge &&
									object.permissionStatus
								)
									this.setState({ CanCreateChallenge: true })
								else if (
									object.permissionName ==
										constant.Functionalities.CanCreateOrUpdateManageChallengeInvitation &&
									object.permissionStatus
								)
									this.setState({ CanCreateOrUpdateManageChallengeInvitation: true })
								else if (
									object.permissionName ==
										constant.Functionalities.CanCreateOrUpdateEvaluationScore &&
									object.permissionStatus
								)
									this.setState({ CanCreateOrUpdateEvaluationScore: true })
								else if (
									object.permissionName ==
										constant.Functionalities.CanCreateOrUpdateEvaluationCriteria &&
									object.permissionStatus
								)
									this.setState({ CanCreateOrUpdateEvaluationCriteria: true })
								else if (
									object.permissionName == constant.Functionalities.CanManageChallenge &&
									object.permissionStatus
								)
									this.setState({ CanManageChallenge: true })
								else if (
									object.permissionName ==
										constant.Functionalities.CanGetFinalLeaderboardForChallenge &&
									object.permissionStatus
								)
									this.setState({ canGetFinalLeaderboardForChallenge: true })
							})
						} else this.props.showAlert("warning", "Ooops!", "Error getting required permissions")
					})
					.catch((err) => {
						console.log("Err =", err.message)
					})
				this.getMyChallenges()
				this.setState({ isLoading: false });
			} else {
				this.props.setRedirectOnError("/home/index")
				this.setState({ showErrorComponent: true })
			}
		}
	}

	componentWillUnmount() {
		helper.setPageTitle("Home | BestInCrowd")
	}

	setActiveRow = () => {
		let activeRow
		let challenges = this.state.challenges
		if (this.state.mostRecentChallenge && this.state.mostRecentChallenge.challengeId) {
			let mostRecentChallenge = this.state.mostRecentChallenge.challengeId
			challenges.map((challenge, index) => {
				if (challenge.challengeId == mostRecentChallenge) activeRow = index
			})
		}
		this.setState({
			activeRow: activeRow
		})
	}

	renderChallengeBuilder = () => {
		return (
			<CustomModal
				title="Challenge Builder"
				visible={this.state.showChallengeBuilder}
				onClose={() => {
					this.setState({
						showChallengeBuilder: false,
						selectedChallenge: null
					})
				}}
			>
				<div className={styles.challengeBuilder}>
					<CreateChallengeUI
						updateChallenge={this.getMyChallenges}
						parentChallenge={this.state.selectedChallenge}
						hideChallengeBuilder={() => {
							this.setState({
								showChallengeBuilder: false,
								selectedChallenge: null
							})
						}}
						setMostRecentChallenge={this.setMostRecentChallenge}
					/>
				</div>
			</CustomModal>
		);
	};

	renderErrorComponent = () => {
		return (
			<div>
				<SimpleBreadcrumbs breadLinkData={[{ name: "Error", link: "/dashboard/index" }]} />
				<GridContainer direction="row" justify="space-evenly" alignItems="center">
					<GridItem>
						<ErrorPage
							errorCode={403}
							message={"Access Denied! :("}
							description={"You are not logged in, please login to see this page"}
						/>
					</GridItem>
				</GridContainer>
			</div>
		);
	}

	render() {
		const { classes, ...rest } = this.props;

		helper.setPageTitle("Dashboard | BestInCrowd");

		if (this.state.showErrorComponent) {
			return this.renderErrorComponent();
		}

		return (
			<>
				{this.state.CanGetAllChallenge ? (
					<div>
						<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
						<GridContainer>
							{this.renderChallengeBuilder()}

							<div className={styles.addChallengeButtonWrapper}>
								{this.state.CanCreateChallenge ? (
									<CustomButtons
										color="twitter"
										className={classes.addChallengeButton}
										onClick={() =>
											this.setState({
												showChallengeBuilder: true,
												selectedChallenge: null
											})
										}
									>
										Add Challenge
									</CustomButtons>
								) : null}
							</div>
							
							<ChallengeCards
								title={challengeTitle.ACTIVE}
								challenges={this.state.challenges}
								onDetailsButtonClick={this.pushRoutes}
								canGetFinalLeaderboardForChallenge={this.state.canGetFinalLeaderboardForChallenge}
								onClickAddPhase={this.onClickAddPhaseButton}
								isLoading={this.state.isLoading}
							/>
							
							{(this.state.draftChallenges || []).length > 0 && (
								<>
									<div style={{ marginTop: "30px", height: "10px", width: "100%" }}></div>
									<ChallengeCards
										title={challengeTitle.DRAFT}
										challenges={this.state.draftChallenges}
										onDetailsButtonClick={this.pushRoutes}
										canGetFinalLeaderboardForChallenge={this.state.canGetFinalLeaderboardForChallenge}
										onClickAddPhase={this.onClickAddPhaseButton}
										isLoading={this.state.isLoading}
									/>
								</>
							)}
							
							{(this.state.challengesWithPendingInvitation || []).length > 0 && (
								<>
									<div style={{ marginTop: "30px", height: "10px", width: "100%" }}></div>
									<ChallengeCards
										title={challengeTitle.PENDING}
										challenges={this.state.challengesWithPendingInvitation}
										onDetailsButtonClick={this.pushRoutes}
										canGetFinalLeaderboardForChallenge={this.state.canGetFinalLeaderboardForChallenge}
										onClickAddPhase={this.onClickAddPhaseButton}
										isLoading={this.state.isLoading}
									/>
								</>
							)}
						</GridContainer>
					</div>
				) : (
					<div></div>
				)}
			</>
		)
	}
}

DashboardPage.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo })
	}
}

let extraStyle = (theme) => ({
	containerBackgroundImage: {
		backgroundImage: "url(" + backgroundImage + ")",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center center",
		backgroundSize: "cover",
		backgroundAttachment: "fixed"
	},
	challengePropertiesTitle: {
		backgroundColor: "#7E7575",
		color: "#FFFFFF",
		paddingLeft: "5px"
	},
	challengeDescriptionBackground: {
		//backgroundColor: "#FFFFFF",
		backgroundColor: "#7E7575",
		color: "#FFFFFF",
		padding: "5px 10px 5px 10px",
		textAlign: "justify",
		borderRadius: "3px"
	},
	challengeDescriptionColorThemed: {
		color: theme.palette.card.color.card + " !important",
		padding: "5px 10px 5px 10px",
		textAlign: "justify",
		borderRadius: "3px"
	},
	challengePropertiseSubTitle: {
		backgroundColor: "#7E7575",
		color: "#FFFFFF",
		paddingLeft: "10px"
	},
	detailsVerticalBar: {
		borderLeft: "5px solid #E25009"
	},
	peopleVerticalBar: {
		borderLeft: "5px solid #E26223"
	},
	statusVerticalBar: {
		borderLeft: "5px solid #E27743"
	},
	cardHead: {
		backgroundColor: "#605959",
		color: theme.palette.text.page || "#FFFFFF",
		paddingLeft: "20px",
		fontSize: "1.4em"
	},
	cardHeadInner: {
		backgroundColor: "#7E7575",
		color: "#FFFFFF",
		paddingTop: "0px",
		paddingBottom: "0px",
		marginTop: "-15px",
		marginBottom: "0px"
	},
	cardBody: {
		backgroundColor: "#605959",
		color: "#FFFFFF"
	},
	cardBody2: {
		backgroundColor: "#7E7575",
		color: "#FFFFFF",
		//marginTop: "0px",
		//paddingTop: "0px",
		padding: "0px",
		margin: "0px"
	},
	addChallengeButton: {
		color: "#ffffff",
		marginTop: "12px"
	},
	cardAction: {
		display: "block",
		textAlign: "initial"
	},
	cardMarginReducer: {
		marginTop: "0px",
		paddingTop: "0px",
		marginBottom: "0px",
		paddingBottom: "0px"
	},
	itemLink2: {
		transition: "all 300ms linear",
		//position: "relative",
		borderRadius: "5px",
		display: "block",
		backgroundColor: "#2DC7FF",
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontSize: "1.2em",
		margin: "5px 0px",
		padding: "5px 5px",
		"&:hover": {
			outline: "none",
			backgroundColor: "#2D81FF",
			boxShadow: "none"
		},
		"&,&:hover,&:focus": {
			color: "inherit"
		}
	},
	manageEvaluationButtonStyle: {
		backgroundColor: "#7E7575"
	},
	collapseMenuStyle: {
		backgroundColor: "rgba(0,0,0,.5)"
	},
	hoverRoseColorStyle: {
		margin: "0px 5px 0px 5px",
		borderRadius: "3px",
		"&:hover,&:focus": {
			backgroundColor: "#e91e63"
		}
	}
})

let finalStyle = combineStyles(appStyle, extraStyle, sidebarStyle)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(finalStyle)(DashboardPage))
