import React from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'html-react-parser';
import { get } from 'lodash';

import { Grid, List, Divider, Chip } from '@material-ui/core';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import ReactButton from 'components/CustomButtons/Button.jsx';

import { getChallengeMenuItems } from 'utils/challengeMenuUtils';
import constant from '../../../../constants';
import Constants from "constants.js";
import { userRole } from 'constants/users/userRole';

import defaultChallengeImage from "assets/img/defaultChallengeImage.jpg";
import styles from './styles.module.scss';

function ChallengeCard(props) {
  const { challenge, onDetailsButtonClick, onClickAddPhase } = props;

  const renderImage = () => {
    return (
      <GridItem direction="row" style={{ width: "100%" }}>
        {challenge.challengeDetails.challengeImage ? (
          <img
            width={"100%"}
            src={Constants.Settings.APIURL + challenge.challengeDetails.challengeImage}
            alt="Image of challenge cover"
            onError={(event) => {
              event.target.src = defaultChallengeImage
            }}
          />
        ) : (
          <img
            width={"100%"}
            src={defaultChallengeImage}
            alt="Image of challenge cover default"
          />
        )}
      </GridItem>
    );
  }
  
  const renderTitle = (statusBGColor) => {
    return (
      <GridItem direction="row" style={{ width: "100%" }}>
        <div className={styles.cardTitle}>
          {challenge.challengeDetails.title.toUpperCase()}
          <Chip
            size="small"
            label={challenge.challengeDetails.challengeStatus}
            variant="outlined"
            style={{
              border: `1px solid ${statusBGColor}`,
              color: statusBGColor,
              marginTop: "3px",
            }}
          />
        </div>
      </GridItem>
    );
  };

  const renderPhase = () => {
    return (
      <GridItem direction="column" style={{ width: "100%", margin: "20px 0px 0px 0px" }}>
        <div className={styles.cardTitle} style={{ fontSize: "14px" }}>
          Phase: {challenge.challengeDetails.phaseTitle}
          {(challenge.phaseLinks || []).length > 1 &&
            <Chip
            size="small"
            label="multi-phase"
            variant="outlined"
            style={{
              border: "1px solid blue",
              color: "blue",
            }}
          />
          }
        </div>
        <div>
          Remaining:{" "}
          {challenge.remainingDays === Number.MAX_SAFE_INTEGER
            ? "Coming soon"
            : challenge.remainingDays
          }
        </div>
      </GridItem>
    );
  };

  const renderCardHeader = () => {
    let statusBGColor = "grey";

    if (challenge.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.ACTIVE) {
      statusBGColor = "blue";
    } else if (challenge.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.COMPLETED) {
      statusBGColor = "green";
    } else if (challenge.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.CLOSED) {
      statusBGColor = "red";
    }
    
    return (
      <div>
        <GridContainer direction="column" alignItems="flex-start">
          {renderImage()}
          {renderTitle(statusBGColor)}
          {renderPhase()}
        </GridContainer>
      </div>
    );
  };

  const renderLeaderBoard = () => {
    const { canGetFinalLeaderboardForChallenge } = props;

    if((challenge.challengeDetails.leaderboard || []).length <= 0 || !canGetFinalLeaderboardForChallenge) {
      return null;
    }

    return (
      <ReactButton
        color="info"
        size="sm"
        onClick={() =>
          onDetailsButtonClick(`/dashboard/leaderboard/${challenge.challengeId}`)
        }
      >
        Leaderboard
      </ReactButton>
    );
  };

  const renderCardBody = () => {
    return (
      <List>
        <div className={styles.summarySection}>
          <Divider style={{ flexGrow: 1, height: "2px" }}/>
          <div><b>SUMMARY</b></div>
          <Divider style={{ flexGrow: 1, height: "2px" }}/>
        </div>
        <div style={{ marginLeft: "10px" }}>
          <div className={styles.summary}>
            {ReactHtmlParser(challenge.challengeDetails.shortDescription || "")}
          </div>
        </div>
      </List>
    );
  };

  const renderChallengeMenuItemsAsButtons = () => {
    const challengeMenuItems = getChallengeMenuItems(challenge);
    const canAddPhase = get(challenge, "roles", []).find(r => {
      return r.role == userRole.CHALLENGE_ADMIN || r.role == userRole.LICENCE_ADMIN || r.role == userRole.SUPER_ADMIN;
    });

    return (
      <Grid container spacing={2} justifyContent='center' style={{ marginTop: "30px" }}>
        {challengeMenuItems.map((item, index) => {
          if (item.ableToAccess) {
            return (
              <ReactButton
                key={index}
                size='sm'
                color="twitter"
                style={{ margin: "4px" }}
                onClick={() => {
                  let route = `${item.route}${challenge.urlSlug}`;

                  if (item.extensionRoute) {
                    route = `${route}/${item.extensionRoute}`;
                  }

                  onDetailsButtonClick(route);
                }}
              >
                {item.title}
              </ReactButton>
            );
          }
        })}
        {canAddPhase && (
          <ReactButton
            size="sm"
            color="twitter"
            style={{ margin: "4px" }}
            onClick={() => onClickAddPhase(challenge)}
            >
              Add Phase
          </ReactButton>
        )}
        { renderLeaderBoard() }
      </Grid>
    );
  };

  return (
    <div className={styles.cardWrapper}>
      {renderCardHeader()}
      {renderCardBody()}
      {renderChallengeMenuItemsAsButtons()}
    </div>
  );
}

ChallengeCard.propTypes = {
  challenge: PropTypes.object.isRequired,
  onDetailsButtonClick: PropTypes.func,
  canGetFinalLeaderboardForChallenge: PropTypes.bool,
  onClickAddPhase: PropTypes.func.isRequired,
};

ChallengeCard.defaultProps = {
  canGetFinalLeaderboardForChallenge: false,
}

export default ChallengeCard;
