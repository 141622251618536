import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import actionTypes from "reduxjs/actionTypes";

import { get } from "lodash";

import { withStyles } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

import SearchBox from "views/Components/SearchBox";
import DropDownMenu from "components/CustomDropdown/DropDownMenu";
import CustomModal from "components/general/CustomModal";
import CardAvatar from "components/Card/CardAvatar";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Pagination from "material-ui-flat-pagination";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter";
import ResetPassword from "components/general/auth/ResetPassword";

import challengeServices from "dataServices/challengeServices";

import emptyAvatar from "assets/img/faces/emptyProfilePicture.webp";
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";
import Constants from "constants.js";

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	textOverflowEllipsisStyle: {
		overflowWrap: "break-word",
		height: "auto",
		overflow: "hidden",
		textOverflow: "ellipsis",
		color: theme.palette.text.page
		//backgroundColor: theme.palette.background.page,
		// whiteSpace: "nowrap",
		// MaxWidth: "inherit",
		// overflow: "hidden",
		// textOverflow: "ellipsis"
	},
	textOverflowEllipsisStyleForOrganizationNameOnly: {
		overflowWrap: "break-word",
		height: "45px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		color: theme.palette.text.page
		//backgroundColor: theme.palette.background.page,
		// whiteSpace: "nowrap",
		// MaxWidth: "inherit",
		// overflow: "hidden",
		// textOverflow: "ellipsis"
	},
	textOverflowEllipsisStyleForUserNameOnly: {
		overflowWrap: "break-word",
		height: "65px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		color: theme.palette.text.page
	},
	cardHeaderStyle: {},
	activeChallengesLinkStyle: {
		"&:hover,&:focus": {
			color: "#A45AB1"
		}
	},
	AccordianTextOverflowEllipsisStyle: {
		overflowWrap: "normal",
		MaxWidth: "inherit",
		overflow: "hidden",
		textOverflow: "ellipsis",
		"&:hover, &:focus": {}
	},
	activeChallengesListIconVerticalBar: {
		borderLeft: "5px solid cyan",
		padding: "5px 10px 5px 10px"
	},
	activeChallengesAccordionStyle: {
		width: "100%",
		margin: "0% 0% 0% 0%"
	},
	solversPageDarkBackgroundStyle: {
		//backgroundColor: "#302f2f"
	},
	buttonSuccessColor: {
		backgroundColor: "#1eb980",
		"&:hover,&:focus": {
			backgroundColor: "#1eb980",
			boxShadow: "0px 6px 14px rgba(30, 185, 128, .3)"
		}
	},
	whiteTextColorStyle: {
		color: theme.palette.text.page
	},
	...userProfileStyles,
	paginationDiv: {
		width: "100%",
		//border: `1px solid ${theme.palette.background.cardBody}`,
		color: theme.palette.text.cardBody
	},
	colorInheritCurrent: {
		margin: theme.spacing(0.5),
		color: theme.palette.text.page,
		backgroundColor: fade("#00FF00", 0.5),
		"&:hover": {
			backgroundColor: fade("#00FF00", theme.palette.action.hoverOpacity)
		}
	},
	colorInheritOther: {
		margin: theme.spacing(0.5),
		color: theme.palette.text.page,
		//backgroundColor: "#FF0000",
		"&:hover": {
			backgroundColor: fade("#FF0000", theme.palette.action.hoverOpacity)
		}
	}
})

const solverTabName = {
	APPROVED: "Other Solver",
	NOT_APPROVED: "Pending Solver"
};

class SolversListUI extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			visibleResetPasswordModal: false,
			email: '',
			password: '',
			solverId: '',
		}
	}

	closeResetPasswordModal = () => {
		this.setState({
			...this.state,
			visibleResetPasswordModal: false,
			email: '',
			password: '',
			solverId: '',
		});
	};

	openResetPasswordModal = (solver) => {
		this.setState({
			...this.state,
			visibleResetPasswordModal: true,
			email: solver.email,
			solverId: solver.userId,
		});
	}

	onPasswordChange = (password) => {
		this.setState({
			...this.state,
			password,
		});
	}

	onResetPassword = async () => {
		const {
			challengeId,
			setLoadingSpinner,
			resetLoadingSpinner
		} = this.props;
		const {
			solverId,
			email,
			password,
		} = this.state;

		setLoadingSpinner();
		await challengeServices.resetPasswordByAdmin(
			solverId,
			challengeId,
			email,
			password,
		);

		this.closeResetPasswordModal();
		resetLoadingSpinner();
	}

	renderResetPasswordModal = () => {
		const {
			visibleResetPasswordModal,
			email,
			password
		} = this.state;

		return (
			<CustomModal
				title="Reset Password"
				visible={visibleResetPasswordModal}
				onClose={this.closeResetPasswordModal}
			>
				<ResetPassword 
					email={email}
					password={password}
					onPasswordChange={this.onPasswordChange}
					onResetPassword={this.onResetPassword}
				/>
			</CustomModal>
		);
	}

	renderSolver = (solver, key) => {
		var xs = 12,
			sm = 6,
			md = 4,
			lg = 3;
		
		const { classes, onClickRemoveParticipant, loggedInUserData } = this.props;
		
		var avatarImagePath = null;
		const dropdownItems = {
			REMOVE_PARTICIPANT: "Remove Participant",
			RESET_PASSWORD: "Reset Password",
		};

		if (solver.avatarImagePath) {
			avatarImagePath = solver.avatarImagePath;

			if (!avatarImagePath.includes("http")) {
				avatarImagePath = Constants.Settings.APIURL + solver.avatarImagePath;
			}
		}

		const onDropdownItemsClick = async (dropdownItem) => {
			const { challengeId } = this.props;

			if (dropdownItem === dropdownItems.REMOVE_PARTICIPANT) {
				onClickRemoveParticipant(solver.userId)
			}

			if (dropdownItem === dropdownItems.RESET_PASSWORD) {
				this.openResetPasswordModal(solver);
			}
		};

		if (!solver) {
			return null;
		}

		return (
			<GridItem key={key} xs={xs} sm={sm} md={md} lg={lg}>
				<Card profile color="gmgTheme" raised={true}>
					<CardAvatar profile style={{ height: "130px", marginTop: "0px" }}>
						{avatarImagePath ? (
							<img
								style={{ zoom: "500%", objectFit: "cover" }}
								src={avatarImagePath}
								alt="Image of user's profile avatar"
								onError={(event) => {
									event.target.src = emptyAvatar
								}}
							/>
						) : (
							<img src={emptyAvatar} alt="Image of user's profile avatar default" />
						)}
					</CardAvatar>
					<CardBody profile>
						<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
							Username: {solver.username}
						</h6>
						<h3
							className={`${classes.cardTitle} ${classes.textOverflowEllipsisStyleForUserNameOnly}`}
						>
							{solver.name}
						</h3>
						<Divider variant="middle" />
						{solver.organizationName ? (
							<h6
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForOrganizationNameOnly}`}
							>
								Organization Name: {solver.organizationName}
							</h6>
						) : (
							<h6
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForOrganizationNameOnly}`}
							>
								Organization Name: Not Available
							</h6>
						)}
						<Divider variant="middle" />
						{solver.state ? (
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								State: {solver.state}
							</h6>
						) : (
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								State: Not Available
							</h6>
						)}
					</CardBody>
					<CardFooter style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<DropDownMenu
							buttonText="Action"
							dropdownList={Object.keys(dropdownItems).map((key) => dropdownItems[key])}
							handleClickOnItems={onDropdownItemsClick}
						/>
					</CardFooter>
				</Card>
			</GridItem>
		);
	};

	renderSolversList = (solverList) => {
		const { classes } = this.props;

		if (solverList.length == 0) {
			return (
				<div style={{ margin: "30px auto" }}>
					<GridContainer justify="center" alignItems="flex-start">
						<GridItem>
							<Typography className={classes.whiteTextColorStyle}>
								{" "}
								<div style={{ textTransform: "capitalize" }}>No Solver Found </div>
							</Typography>
						</GridItem>
					</GridContainer>
				</div>
			);
		}

		let view = solverList.map((solver, key) => {
			return this.renderSolver(solver, key)
		})
		return (
			<GridContainer
				container
				direction="row"
				justify="space-evenly"
				alignItems="flex-start"
				spacing={3}
			>
				{view}
			</GridContainer>
		)
	};

	renserSolverView = tabName => {
		const {
			classes,
			solversList,
			currentPage,
			itemsPerPage: limit,
			totalCount: total,
		} = this.props;
		const offset = Math.max(currentPage - 1, 0) * limit;
		const paginationClasses = {
			colorInheritCurrent: classes.colorInheritCurrent,
			colorInheritOther: classes.colorInheritOther
		};

		return (
			<Card raised color="gmgTheme">
				<CardHeader color="gmgTheme">
					<GridContainer alignItems="center" justify="space-between" direction="row">
						<GridItem xs={12} sm={6} md={6} lg={8}>
							<h4 className={classes.whiteTextColorStyle}>
								<strong>{tabName}</strong>
							</h4>
						</GridItem>
						<GridItem xs={12} sm={6} md={6} lg={4}>
							<SearchBox
								label="Search"
								placeholder="Search Solver"
								value={this.props.searchText}
								onChange={(text) => {
									this.props.onChangeSearchText(text)
								}}
							/>
						</GridItem>
					</GridContainer>
				</CardHeader>
				<CardBody>
					{this.renderSolversList(solversList)}
				</CardBody>
				<CardFooter>
					<GridContainer direction="row" alignItems="center" justify="center">
						<GridItem sm={12} md={8} lg={6}>
							<div className={classes.paginationDiv}>
								<Pagination
									classes={paginationClasses}
									currentPageColor="inherit"
									otherPageColor="inherit"
									size={"large"}
									limit={limit}
									offset={offset}
									total={total}
									onClick={(e, offset) => this.props.onClickPaginationButton(offset)}
								/>
							</div>
						</GridItem>
					</GridContainer>
				</CardFooter>
			</Card>
		);
	}

	render() {
		return (
			<GridContainer direction="row" alignItems="flex-start">
				{ this.renderResetPasswordModal() }
				<GridItem xs={12} sm={12}>
					{this.renserSolverView(solverTabName.APPROVED)}
				</GridItem>
			</GridContainer>
		)
	};
}

SolversListUI.propTypes = {
	classes: PropTypes.object.isRequired,
	solversList: PropTypes.arrayOf(PropTypes.object).isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	onClickRemoveParticipant: PropTypes.func,
	challengeId: PropTypes.string.isRequired,
	onClickApproveParticipant: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SolversListUI))
