import React from "react"
import { Form } from "react-formio"
import cloneDeep from "lodash/cloneDeep"

class FormRender extends React.Component {
	constructor(props) {
		console.log("props = ", props)
		super(props)
		this.state = {
			submissionForm: this.props.submissionForm,
			formData: cloneDeep(this.props.formData)
		}
	}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(this.props.formData) != JSON.stringify(prevProps.formData)) {
			this.setState({ formData: cloneDeep(this.props.formData) })
		}
	}

	render() {
		return (
			<div className="formio">
				<Form
					form={{ ...this.state.submissionForm.formData }}
					options={{ readOnly: false, viewAsHtml: true, display: "form" }}
					submission={{ ...this.state.formData }}
					onSubmit={(submittedData) => {
						console.log("submitted data =", submittedData)
						if (this.props.onSubmit) this.props.onSubmit(submittedData)
					}}
				/>
			</div>
		)
	}
}

export default FormRender
