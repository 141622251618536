import Cookies from 'universal-cookie';
import Constants from "./constants"

// var cookie = new Cookies();

function set(name, value, path = '/', expires_in_HOUR = 720) {
  let now = new Date();
  now.setHours(now.getHours() + expires_in_HOUR);
  const cookie = new Cookies();
  cookie.set(name, value, { path: path, expires: now, domain: Constants.Settings.CookieDomain });
}

function setAccessToken(name, value, path = '/', expires_in_HOUR = 1) {
  let now = new Date();
  now.setHours(now.getHours() + expires_in_HOUR);
  const cookie = new Cookies();
  cookie.set(name, value, { path: path, expires: now, domain: Constants.Settings.CookieDomain });
}

function get(name) {
  const cookie = new Cookies();
  return cookie.get(name);
}

function remove(name) {
  const cookie = new Cookies();
  cookie.remove(name, { path: '/', domain: Constants.Settings.CookieDomain });
}

export default { set, setAccessToken, get, remove };
