import React from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import SweetAlert from "react-bootstrap-sweetalert"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../../helper"
import userServices from "dataServices/userServices"
import { DndContext, DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Checkbox from "@material-ui/core/Checkbox"
import CardFooter from "components/Card/CardFooter"
import evaluationScoreServices from "dataServices/evaluationScoreServices"
import { Typography } from "@material-ui/core"
import { FormControl } from "@material-ui/core"
import Constants from "constants.js"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import solutionServices from "dataServices/solutionServices"
import authentication from "authentication"
import challengeServices from "dataServices/challengeServices"
import AddAlert from "@material-ui/icons/Audiotrack"
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import chartsStyle from "assets/jss/material-dashboard-pro-react/views/chartsStyle.jsx"
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx"

import firstPlaceLogo from "assets/img/faces/firstPlace.webp"
import secondPlaceLogo from "assets/img/faces/secondPlace.webp"
import challengeCompletedLogo from "assets/img/faces/challengeCompleted.webp"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import ReactHtmlParser from 'html-react-parser'
import htmlToDraft from "html-to-draftjs"
import FontOverridePatch from "utils/FontOverridePatch"

const PlacementLogo = [firstPlaceLogo, secondPlaceLogo]

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.card.background.card[1]
	},
	container: {
		margin: "0px 0px",
		padding: "40px 20px",
		backgroundColor: theme.palette.background.page
	},
	winnerCardHeight: {
		height: "inherit"
	},
	selectFormControlOverflowStyle: {
		overflow: "scroll"
	},
	textOverflowEllipsisStyle: {
		overflowWrap: "break-word",
		height: "auto",
		overflow: "hidden",
		textOverflow: "ellipsis"
		// whiteSpace: "nowrap",
		// MaxWidth: "inherit",
		// overflow: "hidden",
		// textOverflow: "ellipsis"
	},
	textOverflowEllipsisStyleForOrganizationNameOnly: {
		overflowWrap: "break-word",
		height: "45px",
		overflow: "hidden",
		textOverflow: "ellipsis"
		// whiteSpace: "nowrap",
		// MaxWidth: "inherit",
		// overflow: "hidden",
		// textOverflow: "ellipsis"
	},
	textOverflowEllipsisStyleForUserNameOnly: {
		overflowWrap: "break-word",
		height: "65px",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	cardHeaderStyle: {},
	activeChallengesLinkStyle: {
		"&:hover,&:focus": {
			color: "#A45AB1"
		}
	},
	AccordianTextOverflowEllipsisStyle: {
		overflowWrap: "normal",
		MaxWidth: "inherit",
		overflow: "hidden",
		textOverflow: "ellipsis",
		"&:hover, &:focus": {}
	},
	activeChallengesListIconVerticalBar: {
		borderLeft: "5px solid cyan",
		padding: "5px 10px 5px 10px"
	},
	activeChallengesAccordionStyle: {
		width: "100%",
		margin: "0% 0% 0% 0%"
	},
	buttonSuccessColor: {
		backgroundColor: "#1eb980",
		"&:hover,&:focus": {
			backgroundColor: "#1eb980",
			boxShadow: "0px 6px 14px rgba(30, 185, 128, .3)"
		}
	},
	whiteTextColorStyle: {
		color: theme.palette.text.page
	},
	winnerCardStyle: {
		//height: "300px",
		overflow: "hide"
	},
	toolbarClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	wrapperClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	editorClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	selectClass: {
		color: theme.palette.text.page
	},
	selectMenuItemClass: {
		color: theme.palette.text.page
	},
	...chartsStyle,
	...userProfileStyles
})

class Winner extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: 0,
			alert: null,
			checked: [],
			winnerTabPage: 1,
			internalLeaderboard: [],
			tempLeaderboard: [],
			selectedLeaderboard: [],
			canCreateStanding: false,
			challengeLeaderboard: null,
			editStandingMode: false,
			dataFetchDone: false,
			editorStateMapper: new Map()
		}
	}

	getEditorState = (solutionId) => {
		let editorStateMapper = this.state.editorStateMapper
		if (!editorStateMapper.has(solutionId)) {
			editorStateMapper.set(solutionId, EditorState.createEmpty())
			this.setState({ editorStateMapper: editorStateMapper })
		}
		return editorStateMapper.get(solutionId)
	}

	setEditorState = (solutionId, editorStateData) => {
		let editorStateMapper = this.state.editorStateMapper
		editorStateMapper.set(solutionId, editorStateData)
		this.setState({ editorStateMapper: editorStateMapper })
	}

	fetchChallengeLeaderboard = () => {
		return challengeServices
			.getChallengeById(this.props.challengeId)
			.then((data) => {
				return data ? data : null
			})
			.catch((err) => {
				console.log("Error fetching challenge leaderboard by challenge Id", err.message)
				return null
			})
	}

	fetchInternalLeaderboard = () => {
		return evaluationScoreServices
			.getInternalLeaderboard({ challengeId: this.props.challengeId })
			.then((leaderboardData) => {
				if (leaderboardData) {
					return Promise.all(
						leaderboardData.map((leaderboardRow) => {
							return solutionServices
								.getSolutionById(leaderboardRow.solutionId)
								.then((solutionData) => {
									leaderboardRow.submissionFileName = solutionData.solutionDetails.description
									leaderboardRow.submissionFilePath = solutionData.solutionDetails.submissionFiles
									leaderboardRow.userName = solutionData.solutionDetails.userName
									leaderboardRow.teamName = solutionData.solutionDetails.teamName
								})
								.catch((err) => {
									console.log("Error occured while fetching the soluion, err = ", err.message)
								})
						})
					).then((promiseAllResult) => {
						console.log("internal leaderboard after promise all: ... ", leaderboardData)
						return leaderboardData ? leaderboardData : []
					})
				}
			})
			.catch((err) => {
				console.log("Error getting internal leaderboard", err.message)
				return []
			})
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		Promise.all([this.fetchChallengeLeaderboard(), this.fetchInternalLeaderboard()])
			.then((result) => {
				console.log("Multiple API call result: ", result)
				this.props.resetLoadingSpinner()
				let challenge = result[0] ? result[0] : null
				let internalLeaderboard = result[1] ? result[1] : []
				let challengeLeaderboard = []
				if (challenge) challengeLeaderboard = challenge.challengeDetails.leaderboard || []

				//for existing leaderboard add the remarks to internal leaderboard so that existing remarks is shown before editing
				let remarksMapper = new Map()
				let standingMapper = new Map()
				challengeLeaderboard.map((lb) => {
					if (lb.remarks) remarksMapper.set(lb.solutionId, lb.remarks)
					if (lb.standing) standingMapper.set(lb.solutionId, lb.standing)
				})
				internalLeaderboard = internalLeaderboard.map((lb) => {
					if (remarksMapper.has(lb.solutionId)) {
						lb.remarks = remarksMapper.get(lb.solutionId)
					}
					if (standingMapper.has(lb.solutionId)) {
						lb.standing = standingMapper.get(lb.solutionId)
					}
					return lb
				})

				this.setState({
					challengeLeaderboard: challengeLeaderboard,
					tempLeaderboard: internalLeaderboard,
					dataFetchDone: true
				})
			})
			.catch((err) => {
				console.log("Error at multiple API call", err.message)
				this.resetLoadingSpinner()
			})
	}

	handleToggle = (value) => {
		const currentIndex = this.state.checked.indexOf(value)
		const newChecked = [...this.state.checked]
		const newSelectedLeaderboard = [...this.state.selectedLeaderboard]

		if (currentIndex === -1) {
			newChecked.push(value)
			newSelectedLeaderboard.push(this.state.tempLeaderboard[value])
		} else {
			newChecked.splice(currentIndex, 1)
			newSelectedLeaderboard.splice(currentIndex, 1)
		}

		newSelectedLeaderboard.map((lb) => {
			let editorState = null
			if (lb.remarks && lb.remarks != "<p></p>\n") {
				let blocksFromHTML = htmlToDraft(lb.remarks)
				let state = ContentState.createFromBlockArray(
					blocksFromHTML.contentBlocks,
					blocksFromHTML.entityMap
				)
				editorState = EditorState.createWithContent(state)
			} else editorState = EditorState.createEmpty()
			this.setEditorState(lb.solutionId, editorState)
		})

		this.setState({
			checked: newChecked,
			selectedLeaderboard: newSelectedLeaderboard
		})
	}

	checkPermissions = () => {
		let requiredPermissionsObject = [
			{
				permissionName: Constants.Functionalities.CanGetInternalLeaderboardForChallenge,
				objectId: null
			}
		]
		this.props.setLoadingSpinner()
		authentication
			.hasMultiplePermissions(requiredPermissionsObject)
			.then((permissionResponseObject) => {
				if (permissionResponseObject) {
					permissionResponseObject.map((object) => {
						if (
							object.permissionName ==
								Constants.Functionalities.CanGetInternalLeaderboardForChallenge &&
							object.permissionStatus
						)
							this.setState({ canCreateStanding: true })
					})
				} else
					this.showAlert(
						"warning",
						"Ooops!",
						"Error getting required permission. Pleaes reload page"
					)
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.showAlert("warning", "Ooops!", "Error getting required permission. Pleaes reload page")
				this.props.resetLoadingSpinner()
			})
	}

	componentDidMount() {
		console.log("Component Did Mount of WinnerStepTab: .. ", this.props.challengeId)
		this.checkPermissions()
		this.fetchAllData()
	}

	renderLeaderboardTable = () => {
		const { classes } = this.props
		return (
			<List dense className={classes.root}>
				<ListItem>
					<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
						<GridItem xs>
							<h6>
								<strong>UserName</strong>
							</h6>
						</GridItem>
						<GridItem xs>
							<h6>
								<strong>Submitted File</strong>
							</h6>
						</GridItem>
						<GridItem xs>
							<h6>
								<strong>Score</strong>
							</h6>
						</GridItem>
						<GridItem xs>
							<h6>
								<strong>Variance</strong>
							</h6>
						</GridItem>
						<GridItem xs={1}></GridItem>
					</GridContainer>
				</ListItem>
				<Divider />
				{this.state.tempLeaderboard.map((value, index) => {
					const labelId = `checkbox-list-secondary-label-${index}`
					return (
						<ListItem key={index} button divider>
							<GridContainer direction="row" justify="flex-start" alignItems="center">
								<GridItem xs>
									{value.teamName ? <h6> {value.teamName} </h6> : <h6> {value.userName} </h6>}
								</GridItem>
								<GridItem xs>
									<h6> {value.submissionFileName} </h6>
								</GridItem>
								<GridItem xs>{value.averageScore}</GridItem>
								<GridItem xs>{value.variance}</GridItem>
								<GridItem xs={1}>
									<Checkbox
										edge="end"
										onChange={() => {
											this.handleToggle(index)
										}}
										checked={this.state.checked.indexOf(index) !== -1}
										inputProps={{ "aria-labelledby": labelId }}
									/>
								</GridItem>
							</GridContainer>
						</ListItem>
					)
				})}
			</List>
		)
	}

	handleSimpleForSelect = (event) => {
		const newSelectedLeaderboard = this.state.selectedLeaderboard
		newSelectedLeaderboard.map((row) => {
			if (row.solutionId == event.target.name) {
				row.standing = event.target.value
			}
		})
		this.setState({ selectedLeaderboard: newSelectedLeaderboard })
	}

	getStandingBySolutionId = (solutionId) => {
		const newSelectedLeaderboard = this.state.selectedLeaderboard
		let standing = null
		newSelectedLeaderboard.map((row) => {
			if (row.solutionId == solutionId) {
				standing = row.standing
			}
		})
		return standing
	}

	handleSimpleForRemarks = (event) => {
		const newSelectedLeaderboard = this.state.selectedLeaderboard
		newSelectedLeaderboard.map((row) => {
			if (row.solutionId == event.target.name) {
				row.remarks = event.target.value
			}
		})
		this.setState({ selectedLeaderboard: newSelectedLeaderboard })
	}

	renderStandings = (standingLenth) => {
		const { classes } = this.props
		var standingList = Constants.Standings.slice(0, standingLenth)
		return standingList.map((standing, index) => {
			return (
				<MenuItem
					classes={
						{
							//root: classes.selectMenuItemClass,
							//selected: classes.selectMenuItemSelected
						}
					}
					value={index + 1}
				>
					{standing}
				</MenuItem>
			)
		})
	}

	renderSelectStandingPage = () => {
		const { classes } = this.props
		var selectStandingList = []
		var checkedList = this.state.checked
		checkedList.sort()
		checkedList.map((checkedItem) => {
			selectStandingList.push(this.state.tempLeaderboard[checkedItem])
		})
		return (
			<List dense className={classes.root}>
				{selectStandingList.map((value, index) => {
					const labelId = `checkbox-list-secondary-label-${index}`
					return (
						<ListItem key={index}>
							<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
								<GridItem md={2}>
									{value.teamName ? <h6> {value.teamName} </h6> : <h6> {value.userName} </h6>}
								</GridItem>
								<GridItem md={2}>
									<FormControl fullWidth className={`${classes.selectFormControl}`}>
										<InputLabel
											htmlFor="simple-select"
											className={`${classes.whiteTextColorStyle}`}
										>
											Standing
										</InputLabel>
										<Select
											MenuProps={
												{
													//className: classes.selectMenuItemClass
												}
											}
											classes={{
												root: classes.selectClass
												//select: classes.selectClass
											}}
											value={this.getStandingBySolutionId(value.solutionId)}
											onChange={this.handleSimpleForSelect}
											inputProps={{
												name: value.solutionId,
												id: "simple-select"
											}}
										>
											{this.renderStandings(selectStandingList.length)}
										</Select>
									</FormControl>
								</GridItem>
								<GridItem md={8}>
									<div style={{ marginLeft: "20px" }}>
										<InputLabel className={classes.whiteTextColorStyle}> Remarks: </InputLabel>
										<FontOverridePatch isDraftEditor>
											<Editor
												editorState={this.getEditorState(value.solutionId)}
												toolbarClassName={classes.toolbarClassName}
												wrapperClassName={classes.wrapperClassName}
												editorClassName={classes.cccEditorStyle}
												toolbar={{
													options: [
														"inline",
														"blockType",
														"fontSize",
														"fontFamily",
														"list",
														"textAlign",
														"link",
														"embedded",
														"remove",
														"history"
														// 'image'
													]
													// image: {
													//     uploadCallback: function (file) { return self.uploadImageCallBack(file, mutation) }, alt: { present: true, mandatory: true }, defaultSize: {
													//         height: 'auto',
													//         width: '100%',
													//     },
													// }
												}}
												onEditorStateChange={(editorState) =>
													this.setEditorState(value.solutionId, editorState)
												}
											/>
										</FontOverridePatch>
									</div>
								</GridItem>
							</GridContainer>
							<ListItemSecondaryAction>
								{/* <Checkbox
                                    edge="end"
                                    onChange={() => { this.handleToggle(index) }}
                                    checked={this.state.checked.indexOf(index) !== -1}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                /> */}
							</ListItemSecondaryAction>
						</ListItem>
					)
				})}
			</List>
		)
	}

	submitStanding = () => {
		console.log(this.state.selectedLeaderboard)
		let emptyField = false
		let selectedLeaderboard = this.state.selectedLeaderboard.map((leaderboardRow) => {
			leaderboardRow.remarks = draftToHtml(
				convertToRaw(this.getEditorState(leaderboardRow.solutionId).getCurrentContent())
			)
			!leaderboardRow.standing ? (emptyField = true) : (emptyField = false)
			//!leaderboardRow.remarks ? emptyField = true : emptyField = false
			if (
				!leaderboardRow.remarks ||
				leaderboardRow.remarks === "" ||
				leaderboardRow.remarks === "<p></p>\n"
			) {
				emptyField = true
			}
			return leaderboardRow
		})
		this.setState({ selectedLeaderboard: selectedLeaderboard })

		if (emptyField) this.props.showAlert("warning", "Ooops!", "You can not submit empty value")
		else {
			var newSelectedLeaderboard = []
			selectedLeaderboard.map((leaderboardRow) => {
				newSelectedLeaderboard.push({
					solutionId: leaderboardRow.solutionId,
					submissionFileName: leaderboardRow.submissionFileName,
					submissionFilePath: leaderboardRow.submissionFilePath,
					solverUserId: leaderboardRow.solverUserId,
					userName: leaderboardRow.userName,
					solverTeamId: leaderboardRow.solverTeamId,
					teamName: leaderboardRow.teamName,
					averageScore: leaderboardRow.averageScore,
					variance: leaderboardRow.variance,
					standing: leaderboardRow.standing,
					remarks: leaderboardRow.remarks
				})
			})

			let variables = {
				challengeId: this.props.challengeId,
				leaderboard: newSelectedLeaderboard
			}
			this.props.setLoadingSpinner()
			challengeServices
				.updateChallenge(variables)
				.then((res) => {
					this.props.resetLoadingSpinner()
					this.setState({ editStandingMode: false }, () => {
						this.fetchAllData()
					})
				})
				.catch((err) => {
					console.log("Error updating leaderboard = ", err.message)
					this.props.resetLoadingSpinner()
					this.props.showAlert("warning", "Ooops!", err.message)
				})
		}
	}

	renderChallengeLeaderboard = () => {
		const { classes } = this.props
		let challengeLeaderboard = this.state.challengeLeaderboard
			? this.state.challengeLeaderboard
			: []
		challengeLeaderboard.sort((a, b) => {
			return a.standing - b.standing
		})
		return challengeLeaderboard.map((leaderboardSolver) => {
			return (
				<GridItem>
					<Card raised color="gmgTheme" className={classes.winnerCardStyle}>
						<CardHeader color="gmgTheme" icon>
							{PlacementLogo[leaderboardSolver.standing - 1] ? (
								<CardIcon color="info">
									<img
										src={PlacementLogo[leaderboardSolver.standing - 1]}
										alt="Image of standing badge"
									/>
								</CardIcon>
							) : (
								<CardIcon color="info">
									<img src={challengeCompletedLogo} alt="Image of standing badge" />
								</CardIcon>
							)}
						</CardHeader>
						<CardBody profile>
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								UserName: {leaderboardSolver.userName}
							</h6>
							<h4 className={`${classes.cardCategory} `}>standing: {leaderboardSolver.standing}</h4>
							<h4
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForUserNameOnly}`}
							>
								Solution Name: {leaderboardSolver.submissionFileName}
							</h4>
							<div style={{ marginBottom: "20px" }}>
								<FontOverridePatch>{ReactHtmlParser(leaderboardSolver.remarks || "")} </FontOverridePatch>
							</div>
						</CardBody>
					</Card>
				</GridItem>
			)
		})
	}

	render() {
		const { classes } = this.props
		return (
			<DndProvider backend={HTML5Backend}>
			<div className={classes.container}>
				{this.state.editStandingMode && this.state.dataFetchDone ? (
					<Typography className={classes.whiteTextColorStyle} align="center">
						Select solutions to designate winners
					</Typography>
				) : (!this.state.challengeLeaderboard || !this.state.challengeLeaderboard.length) &&
				  this.state.dataFetchDone ? (
					<Typography className={classes.whiteTextColorStyle} align="center">
						No winners found
					</Typography>
				) : this.state.dataFetchDone ? (
					<Typography className={classes.whiteTextColorStyle} variant="h5" align="center">
						Winners
					</Typography>
				) : null}

				{this.state.editStandingMode ? (
					<Card color="gmgTheme" raised className={classes.winnerCardHeight}>
						<CardBody>
							{this.state.winnerTabPage == 1
								? this.renderLeaderboardTable()
								: this.state.winnerTabPage == 2
								? this.renderSelectStandingPage()
								: null}
						</CardBody>
						<CardFooter>
							<GridContainer direction="row" justify="space-between" allignItems="center">
								<GridItem>
									{this.state.winnerTabPage == 2 ? (
										<Button
											color="info"
											onClick={() => {
												this.setState({ winnerTabPage: 1 })
											}}
										>
											Previous
										</Button>
									) : null}
								</GridItem>
								<GridItem>
									{this.state.winnerTabPage == 1 ? (
										<Button
											className={classes.buttonSuccessColor}
											disabled={!this.state.selectedLeaderboard.length}
											onClick={() => {
												if (this.state.selectedLeaderboard.length == 0)
													this.props.showAlert(
														"warning",
														"Ooops!",
														"Please select a row to got to next page"
													)
												else this.setState({ winnerTabPage: 2 })
											}}
										>
											Next
										</Button>
									) : this.state.winnerTabPage == 2 ? (
										<Button
											color="success"
											onClick={() => {
												this.submitStanding()
											}}
										>
											Submit
										</Button>
									) : null}
								</GridItem>
							</GridContainer>
						</CardFooter>
					</Card>
				) : (
					<GridContainer>{this.renderChallengeLeaderboard()}</GridContainer>
				)}
				<GridContainer direnction="row" justify="space-between" allignItems="center">
					<GridItem>
						{this.state.editStandingMode ? (
							<Button
								color="warning"
								onClick={() => {
									this.setState({
										editStandingMode: false,
										winnerTabPage: 1
									})
								}}
							>
								Cancel
							</Button>
						) : null}
					</GridItem>
					<GridItem>
						{!this.state.editStandingMode && this.state.canCreateStanding ? (
							<Button
								className={classes.buttonSuccessColor}
								onClick={() => {
									this.setState({
										editStandingMode: true,
										winnerTabPage: 1
									})
								}}
							>
								Edit standing
							</Button>
						) : null}
					</GridItem>
				</GridContainer>
			</div>
    </DndProvider>
		)
	}
}

Winner.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Winner))
