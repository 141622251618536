import React, { Component } from 'react';
import { FacebookProvider, LoginButton } from 'react-facebook';
import { withStyles } from "@material-ui/styles";
import { FaFacebook as FacebookIcon } from 'react-icons/fa';

import Constants from "../../../constants";
import styles from './styles.module.scss';

class FBLogin extends Component {
    handleResponse = (data) => {
        console.log(data);
        let authData = {
            id: data.profile.id,
            email: data.profile.email,
            name: data.profile.name,
            profilePictureUrl: data.profile.picture.data.url,
        }
        this.props.handleFacebookLogin && this.props.handleFacebookLogin(authData);
    }

    handleError = (error) => {
        console.log("Error =", error);
        this.props.handleFacebookLogin && this.props.handleFacebookLogin(error);
    }

    render() {
        const { classes } = this.props;
        return (
            <FacebookProvider appId={Constants.FB_APP_ID}>
                <LoginButton
                    scope="email, public_profile"
                    onSuccess={this.handleResponse}
                    onError={this.handleError}
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none'
                    }}
                >
                    <div className={styles.iconButtons}>
                        <FacebookIcon color="blue" size="2em" />
                    </div>
                </LoginButton>
            </FacebookProvider>
        );
    }
}

const style = theme => ({
    loginButton: {
        margin: "0px",
        padding: "0px",
        color: "#FFFFFF",
    }
})

export default withStyles(style)(FBLogin);