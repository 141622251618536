import timeUtils from "./timeUtils"

let PhaseUtils = {}
function getDateFromString(dateString) {
	let ds = dateString.split(",")
	return new Date(ds[0] + ds[1])
}

PhaseUtils.getCurrentPhase = (phases) => {
	if (!phases) return null
	let now = new Date()
	let currentPhase = null
	phases.map((phase) => {
		let phaseStart = getDateFromString(phase.startDate)
		let phaseEnd = getDateFromString(phase.endDate)
		if (!currentPhase && phaseStart < now && phaseEnd > now) {
			currentPhase = phase
		}
	})
	return currentPhase
}

PhaseUtils.getNextPhase = (phases) => {
	if (!phases) return null
	let now = new Date()
	let nextPhase = null
	phases.map((phase) => {
		let phaseStart = getDateFromString(phase.startDate)
		if (!nextPhase && phaseStart > now) {
			nextPhase = phase
		}
	})
	return nextPhase
}

PhaseUtils.getNextPhaseCountDownValue = (phase) => {
	let now = new Date()
	let phaseEndDate = getDateFromString(phase.endDate)
	return timeUtils.getTimeUntil(now, phaseEndDate)
}

export default PhaseUtils
