import React, { useState } from "react"
import PropTypes from "prop-types"
import InlineInput from "react-trello/dist/widgets/InlineInput"
import { Title, LaneHeader, RightContent } from "react-trello/dist/styles/Base"
import LaneMenu from "react-trello/dist/components/Lane/LaneHeader/LaneMenu"
import DeleteIcon from "@material-ui/icons/Delete"
import { Grid, IconButton, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import PopupModal from "views/Components/PopupModal"

const useStyle = makeStyles((theme) => ({
	deleteButton: {
		width: "20px",
		height: "20px",
		marginTop: "0px",
		marginRight: "0px"
	}
}))

const LaneHeaderComponent = ({
	updateTitle,
	canAddLanes,
	onDelete,
	onDoubleClick,
	editLaneTitle,
	label,
	title,
	titleStyle,
	labelStyle,
	t,
	laneDraggable
}) => {
	const [isOpenModal, setIsOpenModal] = useState(false)
	const classes = useStyle()

	return (
		<LaneHeader onDoubleClick={onDoubleClick} editLaneTitle={editLaneTitle}>
			<Title draggable={laneDraggable} style={titleStyle}>
				{editLaneTitle ? (
					<InlineInput
						value={title}
						border
						placeholder={t("placeholder.title")}
						resize="vertical"
						onSave={updateTitle}
					/>
				) : (
					title
				)}
			</Title>
			{label && (
				<RightContent>
					<span style={labelStyle}>{label}</span>
				</RightContent>
			)}

			{canAddLanes && editLaneTitle ? (
				<div style={{ width: "30%", marginTop: "-10px" }}>
					<Grid container direction="row" justify="flex-end" alignItems="flex-start">
						<Grid item>
							<Tooltip title="Delete Evaluation Team">
								<IconButton onClick={() => setIsOpenModal(true)}>
									<DeleteIcon color="error" className={classes.deleteButton} />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</div>
			) : null}

			{isOpenModal && (
				<PopupModal
					popupHeaderText="Delete Evaluation Team?"
					popupMessageText="Are sure you want to delete this Evaluation Team?"
					onCancel={() => {
						setIsOpenModal(false)
					}}
					onSubmit={() => {
						onDelete()
						setIsOpenModal(false)
					}}
					yesButtonText="Yes"
					cancelButtonText="No"
				/>
			)}
		</LaneHeader>
	)
}

LaneHeaderComponent.propTypes = {
	updateTitle: PropTypes.func,
	editLaneTitle: PropTypes.bool,
	canAddLanes: PropTypes.bool,
	laneDraggable: PropTypes.bool,
	label: PropTypes.string,
	title: PropTypes.string,
	onDelete: PropTypes.func,
	onDoubleClick: PropTypes.func,
	t: PropTypes.func.isRequired
}

LaneHeaderComponent.defaultProps = {
	updateTitle: () => {},
	editLaneTitle: false,
	canAddLanes: false
}

export default LaneHeaderComponent
