import React from "react"
import { withStyles, withTheme } from "@material-ui/core/Styles"

import PropTypes from "prop-types"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Assignment from "@material-ui/icons/Assignment"
import Edit from "@material-ui/icons/Edit"
import Delete from "@material-ui/icons/Delete"
import Close from "@material-ui/icons/Close"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Input from "@material-ui/core/Input"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import TextField from "@material-ui/core/TextField"
import Table from "components/Table/Table"
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"

import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"

import combineStyles from "utils/combineStyle"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import SimpleBreadcrumbs from "components/SimpleBreadcumbs.jsx"
import ManageChallengeMenu from "./ManageChallengeMenu.jsx"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

import constants from "constants.js"
import ErrorPage from "../Pages/ErrorPage"
import helper from "../../helper"
import validator from "validator"

import { Form, FormBuilder } from "react-formio"

import Chip from "@material-ui/core/Chip"

import challengeServices from "dataServices/challengeServices"
import { FormEdit } from "react-formio/lib/components"
import formServices from "dataServices/formServices.js"
import ReactTables from "views/Tables/ReactTables.jsx"
import solutionServices from "dataServices/solutionServices.js"

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

class ManageForms extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			canViewThisPage: true,
			showErrorComponent: false,
			challengeId: "",
			urlSlug: "",
			challenge: null,
			isLoading: true,
			forms: [], //list of all the forms for this challenge for different phases
			formId: "",
			formName: "",
			formData: {
				display: "form",
				components: []
			},
			editFormMode: false,
			createFormMode: false,
			submission: {}, //for rendering test form
			submittedSolutionsCount: 0,
			isEditFormConfirmModalOpen: false,
			toEditFormId: "",
			isConfirmDeleteModalOpen: false,
			toDeleteFormId: ""
		}
	}

	fetchChallenge = (challengeId) => {
		return challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log("Error fetching challenge", err.message)
				return null
			})
	}

	fetchSolutionsByChallengeId = (challengeId) => {
		return solutionServices
			.getSolutionsByChallengeId(challengeId)
			.then((solutions) => {
				this.setState({ submittedSolutionsCount: solutions.length })
				return solutions
			})
			.catch((err) => {
				console.log("Error fetching the solutions, err =", err.message)
				return []
			})
	}

	fetchFormByChallengeId = (challengeId) => {
		return formServices
			.getFormByChallengeId(challengeId)
			.then((data) => {
				if (!data) throw new Error("Error occurred while fetching the form by challengeId")
				return data
			})
			.catch((err) => {
				console.log("Err , ", err.message)
				return null
			})
	}

	fetchAllFormsByChallengeId = (challengeId) => {
		return formServices
			.getAllFormsByChallengeId(challengeId)
			.then((data) => {
				if (!data) throw new Error("Error occurred while fetching the forms for the challenges")
				this.setState({ forms: data || [] })
				return data
			})
			.catch((err) => {
				console.log("Error fetching forms =", err.message)
				this.setState({ forms: [] })
				return []
			})
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props
		if (params.challengeId !== ":challengeId") {
			this.setState({
				showErrorComponent: false
			})
		} else {
			this.props.setRedirectOnError("/home/index")
			return
		}

		this.props.pushBreadCrumbStack({
			name: "Manage Forms",
			link: this.props.location.pathname
		})

		if (validator.isUUID(params.challengeId)) {
			this.setState({ challengeId: params.challengeId }, () => {})
		} else {
			this.setState({ urlSlug: params.challengeId }, () => {})
		}

		this.props.setLoadingSpinner()

		this.fetchChallenge(params.challengeId)
			.then((challenge) => {
				this.props.resetLoadingSpinner()
				if (!challenge) {
					this.props.setRedirectOnError("/dashboard/index")
					return null
				}
				this.setState({
					challengeId: challenge.challengeId,
					challenge: challenge,
					urlSlug: challenge.urlSlug
				})
				let roles = challenge.roles
				if (
					roles.find((r) => r.role == "challengeAdmin") ||
					roles.find((r) => r.role == "licenseeAdmin") ||
					roles.find((r) => r.role == "superAdmin")
				) {
					this.props.setLoadingSpinner()
					return Promise.all([
						this.fetchAllFormsByChallengeId(challenge.challengeId),
						this.fetchSolutionsByChallengeId(challenge.challengeId)
					])
						.then((result) => {
							this.setState({ isLoading: false })
							this.props.resetLoadingSpinner()
							return null
						})
						.catch((err) => {
							console.log("Error in promise all, err =", err.message)
							return null
						})
				} else {
					this.props.setRedirectOnError("/dashboard/index")
				}
				return null
			})
			.catch((err) => {
				console.log("Error fetching challenge, err =", err.message)
				this.props.resetLoadingSpinner()
				this.setState({ showErrorComponent: true })
				this.props.setRedirectOnError("/dashboard/index")
			})
	}

	handleSaveForm = () => {
		this.props.setLoadingSpinner()
		formServices
			.createOrUpdate(
				this.state.formId,
				this.state.challengeId,
				this.state.formData,
				"",
				this.state.formName
			)
			.then((data) => {
				this.props.resetLoadingSpinner()
				if (!data) throw new Error("Error occurred while saving the form")
				this.props.setLoadingSpinner()
				this.fetchAllFormsByChallengeId(this.state.challengeId).then((data) => {
					this.props.resetLoadingSpinner()
					this.setState({
						editFormMode: false,
						createFormMode: false,
						formId: "",
						formName: "",
						formData: { display: "form", components: [] }
					})
				})
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("err saving form, err =", err.message)
				this.props.showAlert("error", "Oops!", err.message)
			})
	}

	handleDeleteForm = (formId) => {
		this.props.setLoadingSpinner()
		formServices
			.deleteById(formId)
			.then((res) => {
				if (!res) {
					throw new Error("Error occurred while deleting the form")
				}
				this.fetchAllFormsByChallengeId(this.state.challengeId).then((data) => {
					this.props.resetLoadingSpinner()
					this.setState({
						editFormMode: false,
						createFormMode: false,
						formId: "",
						formName: "",
						toDeleteFormId: "",
						formData: { display: "form", components: [] }
					})
				})
			})
			.catch((err) => {
				console.log("Error deleting the form, err =", err.message)
				this.props.showAlert("warning", "Oops!", err.message)
				this.props.resetLoadingSpinner()
			})
	}

	render() {
		const { classes, theme } = this.props
		helper.setPageTitle("Manage Forms | BestInCrowd")

		if (this.state.isLoading) {
			return <div> </div>
		}

		return (
			<div>
				<GridContainer direction="row" justify="space-between">
					<GridItem xs={9} sm={10} md={10} lg={11}>
						<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
					</GridItem>
					<GridItem className={classes.right} xs={3} sm={2} md={2} lg={1}>
						<ManageChallengeMenu
							challengeId={this.state.challengeId}
							challenge={this.state.challenge}
							urlSlug={this.state.urlSlug}
							history={this.props.history}
						/>
					</GridItem>
				</GridContainer>
				<br />
				<SnackbarContent
					message={this.state.challenge.challengeDetails.phaseTitle}
					color="success"
				/>
				<div style={{ margin: "10px" }}>
					<h4> Submission Forms | {this.state.challenge.challengeDetails.title} </h4>
				</div>
				{this.renderEditFormConfirmModal()}
				{this.renderConfirmRemoveModal()}
				{this.renderFormsList()}
				{this.renderFormBuilderUI()}
				<br />
				<br />
				{/* <div style={{ border: "3px solid green" }}> {this.renderFormUI()} </div> */}
			</div>
		)
	}

	renderFormsList = () => {
		const { classes } = this.props
		if (this.state.isLoading || this.state.createFormMode || this.state.editFormMode)
			return <div></div>
		let showList = this.state.forms && this.state.forms.length > 0
		return (
			<Card color="gmgTheme">
				<CardHeader color="gmgTheme">
					<GridContainer direction="row">
						<GridItem sm={12} md={8} lg={10}>
							Available Forms
						</GridItem>
						<GridItem sm={12} md={4} lg={2}>
							{this.state.forms.length == 0 && (
								<Button
									color="primary"
									onClick={() => {
										this.setState({
											formId: "",
											createFormMode: true,
											formData: constants.defaultForm
										})
									}}
								>
									Add Form
								</Button>
							)}
						</GridItem>
					</GridContainer>
				</CardHeader>
				<CardBody color="gmgTheme">
					<div>
						<GridContainer direction="row">
							<GridItem sm={12} md={12} lg={12}>
								{!showList && <div> No forms available for this challenge </div>}
								{showList && (
									<div>
										<Table
											tableHead={["Sl. No.", "Form Name", "Submitted Solutions", "Actions"]}
											tableData={this.state.forms.map((f, index) => {
												return [
													index + 1,
													f.formName,
													this.state.submittedSolutionsCount
														? `Yes (${this.state.submittedSolutionsCount})`
														: `No (${this.state.submittedSolutionsCount})`,
													<div>
														<Button
															color="success"
															onClick={() => {
																this.setState({
																	isEditFormConfirmModalOpen: true,
																	toEditFormId: f.formId
																})
															}}
														>
															<Edit />
														</Button>
														<Button
															color="danger"
															onClick={() => {
																this.setState({
																	isConfirmDeleteModalOpen: true,
																	toDeleteFormId: f.formId
																})
															}}
														>
															<Delete />
														</Button>
													</div>
												]
											})}
										/>
									</div>
								)}
							</GridItem>
						</GridContainer>
					</div>
				</CardBody>
			</Card>
		)
	}

	renderFormBuilderUI = () => {
		const { classes, theme } = this.props
		if (this.state.isLoading || !(this.state.editFormMode || this.state.createFormMode))
			return <div></div>

		return (
			<div>
				<Card color="gmgTheme">
					<CardHeader color="gmgTheme">
						{this.state.createFormMode && "Create Form"}
						{this.state.editFormMode && "Update existing Form"}
					</CardHeader>
					<CardBody color="gmgTheme">
						<div
							style={{
								backgroundColor: "#ffffff",
								color: "#333",
								padding: "10px",
								border: "2px solid grey"
							}}
						>
							<GridContainer direction="row">
								<GridItem sm={12} md={12} lg={12}>
									<GridContainer direction="row" justify="center" alignItems="center">
										<GridItem sm={12} md={2} lg={2}></GridItem>
										<GridItem sm={12} md={10} lg={10}>
											<TextField
												label="Form Name"
												placeholder="Enter the name (at least 3 characters) of the form to save as"
												className={classes.inputRoot}
												fullWidth
												InputLabelProps={{
													className: classes.inputLabel
												}}
												value={this.state.formName}
												onChange={(e) => this.setState({ formName: e.target.value })}
												name={"formName"}
												inputProps={{
													className: classes.textField,
													overflow: "auto"
												}}
											/>
										</GridItem>
									</GridContainer>

									<br />
									<br />
									<br />
									<FormBuilder
										form={{ display: "form", components: this.state.formData.components }}
										onChange={(schema) => {
											this.setState({ formData: schema }, () => {})
										}}
									/>
								</GridItem>
								<GridItem sm={12} md={12} lg={12}>
									<GridContainer direction="row" justify="flex-end" alignItems="right">
										<GridItem lg={3} md={12} sm={12} xs={12}>
											<Button
												color="danger"
												onClick={() => {
													this.props.setLoadingSpinner()
													this.fetchAllFormsByChallengeId(this.state.challengeId).then((data) => {
														this.props.resetLoadingSpinner()
														this.setState({
															formData: { display: "form", components: [] },
															createFormMode: false,
															editFormMode: false,
															formId: "",
															formName: ""
														})
													})
												}}
											>
												Cancel
											</Button>
											<Button
												disabled={
													!this.state.formData ||
													!this.state.formData.components ||
													this.state.formData.components.length <= 1 ||
													!this.state.formName ||
													this.state.formName.length <= 3
												}
												color="primary"
												onClick={() => {
													this.handleSaveForm()
												}}
											>
												{this.state.editFormMode && "Update Form"}
												{this.state.createFormMode && "Save Form"}
											</Button>
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
					</CardBody>
				</Card>
			</div>
		)
	}

	renderFormUI = () => {
		const { classes, theme } = this.props
		return (
			<div>
				<Card color="gmgTheme">
					<CardBody color="gmgTheme">
						<GridContainer>
							<GridItem sm={12} md={12} lg={12}>
								<div
									style={{
										backgroundColor: "#ffffff",
										color: "#333",
										padding: "10px",
										border: "2px solid grey",
										marginBottom: "5px"
									}}
									className="formio"
								>
									<Form
										form={{
											display: this.state.formData.display,
											components: this.state.formData.components
										}}
										onChange={(data, k, v) => {}}
										onSubmit={(data) => {
											this.setState({ submission: data })
										}}
									/>
								</div>
							</GridItem>
							<GridItem sm={12} md={12} lg={12}>
								<div
									style={{
										backgroundColor: "#ffffff",
										color: "#333",
										padding: "10px",
										border: "2px solid grey",
										marginTop: "5px"
									}}
									className="formio"
								>
									<Form
										form={{
											display: this.state.formData.display,
											components: this.state.formData.components
										}}
										options={{ readOnly: true, viewAsHtml: true }}
										submission={this.state.submission}
									/>
								</div>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</div>
		)
	}

	renderConfirmRemoveModal = (formId) => {
		if (!this.state.isConfirmDeleteModalOpen) return null
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isConfirmDeleteModalOpen: false })}
			>
				<DialogTitle> Are you sure? </DialogTitle>
				<DialogContent>
					<DialogContentText>
						{this.state.submittedSolutionsCount
							? "There are some solvers who has already submitted solutions using this form, if you delete this form their submissions will not be valid anymore, they have to submit again"
							: "If you delete this form users will not be able to submit solution, You can also create a new one."}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => this.setState({ isConfirmDeleteModalOpen: false, toDeleteFormId: "" })}
						color="tumbler"
					>
						No
					</Button>
					<Button
						onClick={() => {
							this.handleDeleteForm(this.state.toDeleteFormId)
							this.setState({ isConfirmDeleteModalOpen: false })
						}}
						color="youtube"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderEditFormConfirmModal = () => {
		if (!this.state.isEditFormConfirmModalOpen) return null
		const { classes } = this.props
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isEditFormConfirmModalOpen: false })}
			>
				<DialogTitle> Modify form? </DialogTitle>
				<DialogContent>
					{this.state.submittedSolutionsCount
						? "There are some users who have already submitted solutions using this form, if you modify this form their submissions may not be valid anymore. They have to submit again"
						: "Modify this solutions before submission is started"}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => this.setState({ isEditFormConfirmModalOpen: false })}
						color="secondary"
					>
						No
					</Button>
					<Button
						onClick={() => {
							let f = this.state.forms.find((form) => form.formId == this.state.toEditFormId)
							this.setState({
								isEditFormConfirmModalOpen: false,
								formId: f.formId,
								formData: f.formData,
								formName: f.formName,
								editFormMode: true
							})
						}}
						color="success"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

ManageForms.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

let extraStyles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		flexWrap: "wrap"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 300,
		maxWidth: 900
	}
})

let finalStyles = combineStyles(extraStyles, extendedTablesStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTheme(withStyles(finalStyles)(ManageForms)))
