// require("dotenv").config()

const Settings = {
	BaseURL: process.env.REACT_APP_BASE_URL,
	APIURL: process.env.REACT_APP_API_URL,
	C_API_KEY: process.env.REACT_APP_CLIENT_API_KEY,

	GA_ID: process.env.REACT_APP_GA_ID,

	CookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
	LoggedInCookieName: process.env.REACT_APP_LOGGED_IN_COOKIE_NAME,
	AccessTokenCookieName: process.env.REACT_APP_ACCESS_TOKEN_COOKIE_NAME,

	challengeCardMaxCharacter: 100
}

const formData = {
	components: [
		{
			label: "Panel",
			title: "First Responder UAS Endurance Challenge Concept Paper",
			collapsible: false,
			mask: false,
			tableView: false,
			alwaysEnabled: false,
			type: "panel",
			input: false,
			key: "panel",
			components: [
				{
					label: " ",
					className: "",
					attrs: [],
					content:
						"This collection of information contains Paperwork Reduction Act (PRA) requirements approved by the Office of Management and Budget (OMB).  Notwithstanding any other provisions of the law, no person is required to respond to, nor shall any person be subject to a penalty for failure to comply with, a collection of information subject to the requirements of the PRA unless that collection of information displays a currently valid OMB control number.  Public reporting burden for this collection is estimated to be 90 minutes per response, including the time for reviewing instructions, searching existing data sources, gathering and maintaining the data needed and completing and reviewing the collection of information.  Send comments regarding this burden estimate or any aspect of this collection of information, including suggestions for reducing this burden, to the National Institute of Standards and Technology, Attn: psprizes@nist.gov. <br/> <br/> OMB Control #0690-0031 <br/> Expiration Date: 07/31/2020",
					refreshOnChange: false,
					mask: false,
					tableView: true,
					alwaysEnabled: false,
					type: "htmlelement",
					input: false,
					key: ""
				}
			]
		},
		{
			label: "Panel",
			title: "Cover Page and Abstract",
			collapsible: false,
			mask: false,
			tableView: false,
			alwaysEnabled: false,
			type: "panel",
			input: false,
			key: "panel",
			components: [
				{
					label: "Description (optional)",
					showWordCount: false,
					showCharCount: false,
					tableView: true,
					alwaysEnabled: false,
					type: "textarea",
					input: true,
					key: "descriptionOptional",
					validate: { unique: false, customMessage: "", json: "" }
				},
				{
					label: "Columns",
					columns: [
						{
							components: [
								{
									label: "Last Name, First Name",
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "lastNameFirstName",
									validate: { required: true, unique: false, customMessage: "", json: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						},
						{
							components: [
								{
									label: "Team Name",
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "teamName",
									widget: { type: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						}
					],
					mask: false,
					tableView: false,
					alwaysEnabled: false,
					type: "columns",
					input: false,
					key: "columns"
				},
				{
					input: false,
					key: "columns",
					tableView: false,
					label: "Columns",
					type: "columns",
					columns: [
						{
							components: [
								{
									label: "Company/Organization",
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "companyOrganization",
									widget: { type: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						},
						{
							components: [
								{
									label: "Application Title",
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "applicationTitle",
									validate: { required: true, unique: false, customMessage: "", json: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						}
					]
				},
				{
					label: "Columns",
					columns: [
						{
							components: [
								{
									label: "Technical Point of Contact",
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "technicalPointOfContact",
									validate: { required: true, unique: false, customMessage: "", json: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						},
						{
							components: [
								{
									label: "Business Point of Contact",
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "businessPointOfContact",
									validate: { required: true, unique: false, customMessage: "", json: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						}
					],
					mask: false,
					tableView: false,
					alwaysEnabled: false,
					type: "columns",
					input: false,
					key: "columns"
				},
				{
					label: "Columns",
					columns: [
						{
							components: [
								{
									label: "Esimate Endurance (in minutes)",
									allowMultipleMasks: false,
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "esimateEnduranceInMinutes",
									validate: { required: true, unique: false, customMessage: "", json: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						},
						{
							components: [
								{
									label: "Fuel Type",
									optionsLabelPosition: "right",
									values: [
										{ label: "Battery-powered", value: "batteryPowered", shortcut: "" },
										{ label: "Hydrocarbon", value: "hydrocarbon", shortcut: "" }
									],
									inline: true,
									mask: false,
									tableView: true,
									alwaysEnabled: false,
									type: "selectboxes",
									input: true,
									key: "fuelType",
									validate: { required: true, unique: false, customMessage: "", json: "" },
									inputType: "checkbox"
								},
								{
									label: "Other, Please Specify",
									allowMultipleMasks: false,
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "otherPleaseSpecify",
									widget: { type: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						}
					],
					mask: false,
					tableView: false,
					alwaysEnabled: false,
					type: "columns",
					input: false,
					key: "columns"
				},
				{
					label:
						"Describe succinctly (500-word MAXIMUM):  Conceptual Description of the UAS; The unique aspects of the Contestant’s’ approach and the potential impact that the proposed approach could have in achieving the goals of the challenge.",
					showWordCount: false,
					showCharCount: false,
					tableView: true,
					alwaysEnabled: false,
					type: "textarea",
					input: true,
					key:
						"describeSuccinctly500WordMaximumConceptualDescriptionOfTheUasTheUniqueAspectsOfTheContestantsApproachAndThePotentialImpactThatTheProposedApproachCouldHaveInAchievingTheGoalsOfTheChallenge"
				}
			]
		},
		{
			label: "Panel",
			title: "Project Description",
			collapsible: false,
			mask: false,
			tableView: false,
			alwaysEnabled: false,
			type: "panel",
			input: false,
			key: "panel",
			components: [
				{
					label:
						"Addressing the requirements should be your primary objective, therefore, create your concept paper to addressthe evaluation criteria. Following are a few options to consider: 1) The competitive advantage offered by the Contestant’s approach or solution; 2) The current state-of-the-art in the relevant field andapplication, including key shortcomings, limitations, costs, and challenges and how the proposed project will overcome the shortcomings, limitations, and challenges relevant to the goals of the challenge; 3) High-level vision for performance metrics. Describe what will be produced, when it will be produced, and how it will be verified. Start by defining the baseline capability and end project performance metric in quantifiable and verifiable terms. Include interim performance metrics which will be closely reviewed for flight feasibility and endurance.  (2,000 words MAXIMUM)",
					showWordCount: false,
					showCharCount: false,
					tableView: true,
					alwaysEnabled: false,
					type: "textarea",
					input: true,
					key:
						"addressingTheRequirementsShouldBeYourPrimaryObjectiveThereforeCreateYourConceptPaperToAddresstheEvaluationCriteriaFollowingAreAFewOptionsToConsider1TheCompetitiveAdvantageOfferedByTheContestantsApproachOrSolution2TheCurrentStateOfTheArtInTheRelevantFieldAndapplicationIncludingKeyShortcomingsLimitationsCostsAndChallengesAndHowTheProposedProjectWillOvercomeTheShortcomingsLimitationsAndChallengesRelevantToTheGoalsOfTheChallenge3HighLevelVisionForPerformanceMetricsDescribeWhatWillBeProducedWhenItWillBeProducedAndHowItWillBeVerifiedStartByDefiningTheBaselineCapabilityAndEndProjectPerformanceMetricInQuantifiableAndVerifiableTermsIncludeInterimPerformanceMetricsWhichWillBeCloselyReviewedForFlightFeasibilityAndEndurance2000WordsMaximum",
					validate: { required: true, unique: false, customMessage: "", json: "" }
				},
				{
					label: "Columns",
					columns: [
						{
							components: [
								{
									label: "Endurance in Minutes ",
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "enduranceInMinutes",
									validate: { required: true, unique: false, customMessage: "", json: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						},
						{
							components: [
								{
									label: "System weight in pounds (to include a 10-pound payload)",
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "systemWeightInPoundsToIncludeA10PoundPayload",
									widget: { type: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						}
					],
					mask: false,
					tableView: false,
					alwaysEnabled: false,
					type: "columns",
					input: false,
					key: "columns"
				},
				{
					label: "Columns",
					columns: [
						{
							components: [
								{
									label: "Drone Weight in Pounds",
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key: "droneWeightInPounds",
									validate: { required: true, unique: false, customMessage: "", json: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						},
						{
							components: [
								{
									label:
										"Estimated set up time in minutes (from unpacking the drone from its storage configuration to take off)",
									showWordCount: false,
									showCharCount: false,
									tableView: true,
									alwaysEnabled: false,
									type: "textfield",
									input: true,
									key:
										"estimatedSetUpTimeInMinutesFromUnpackingTheDroneFromItsStorageConfigurationToTakeOff",
									validate: { required: true, unique: false, customMessage: "", json: "" }
								}
							],
							width: 6,
							offset: 0,
							push: 0,
							pull: 0,
							type: "column",
							hideOnChildrenHidden: false,
							input: true,
							key: "",
							tableView: true,
							label: ""
						}
					],
					mask: false,
					tableView: false,
					alwaysEnabled: false,
					type: "columns",
					input: false,
					key: "columns"
				},
				{
					label: "Total volume of system in storage configuration in cubic feet",
					showWordCount: false,
					showCharCount: false,
					tableView: true,
					alwaysEnabled: false,
					type: "textfield",
					input: true,
					key: "totalVolumeOfSystemInStorageConfigurationInCubicFeet",
					validate: { required: true, unique: false, customMessage: "", json: "" }
				}
			]
		},
		{
			label: "Panel",
			title: "Resume Information for Key Members",
			collapsible: false,
			mask: false,
			tableView: false,
			alwaysEnabled: false,
			type: "panel",
			input: false,
			key: "panel",
			components: [
				{
					label:
						"The key team members and why they are well suited to accomplish the project, with resume information to support their qualifications (1,000 words MAXIMUM)",
					showWordCount: false,
					showCharCount: false,
					tableView: true,
					alwaysEnabled: false,
					type: "textarea",
					input: true,
					key:
						"theKeyTeamMembersAndWhyTheyAreWellSuitedToAccomplishTheProjectWithResumeInformationToSupportTheirQualifications1000WordsMaximum",
					validate: { required: true, unique: false, customMessage: "", json: "" }
				}
			]
		},
		{
			label: "Panel",
			title: "Summary Slide",
			collapsible: false,
			mask: false,
			tableView: false,
			alwaysEnabled: false,
			type: "panel",
			input: false,
			key: "panel",
			components: [
				{
					label: "Description (Optional)",
					showWordCount: false,
					showCharCount: false,
					tableView: true,
					alwaysEnabled: false,
					type: "textarea",
					input: true,
					key: "descriptionOptional2"
				},
				{
					label:
						"Contestants are encouraged to provide a single slide summarizing the proposed project. The recommended format can be found at www.firstresponderuaschallenge.org.",
					mask: false,
					tableView: true,
					alwaysEnabled: false,
					type: "file",
					input: true,
					key:
						"contestantsAreEncouragedToProvideASingleSlideSummarizingTheProposedProjectTheRecommendedFormatCanBeFoundAtWwwFirstresponderuaschallengeOrg",
					defaultValue: [],
					validate: { required: true, unique: false, customMessage: "", json: "" },
					encrypted: false,
					storage: "base64",
					dir: "",
					webcam: false,
					data: { fileTypes: [{ label: "", value: "" }] }
				}
			]
		},
		{
			label: "Panel",
			title: "Letters of Support",
			collapsible: false,
			mask: false,
			tableView: false,
			alwaysEnabled: false,
			type: "panel",
			input: false,
			key: "panel",
			components: [
				{
					label: "3 letters maximum",
					showWordCount: false,
					showCharCount: false,
					tableView: true,
					alwaysEnabled: false,
					type: "textfield",
					input: true,
					key: "3LettersMaximum",
					widget: { type: "" }
				},
				{
					label:
						"You may attach up to 3 letters of support from external entities (1-page maximum per letter). Multi-page Letters of Support are not allowed, any extra pages will not be reviewed.",
					mask: false,
					tableView: true,
					alwaysEnabled: false,
					type: "file",
					input: true,
					key:
						"youMayAttachUpTo3LettersOfSupportFromExternalEntities1PageMaximumPerLetterMultiPageLettersOfSupportAreNotAllowedAnyExtraPagesWillNotBeReviewed",
					validate: { required: true, unique: false, customMessage: "", json: "" },
					storage: "base64",
					dir: "",
					webcam: false,
					data: { fileTypes: [{ label: "", value: "" }] }
				}
			]
		},
		{
			label: "Panel",
			title: "Bill of Materials",
			collapsible: false,
			mask: false,
			tableView: false,
			alwaysEnabled: false,
			type: "panel",
			input: false,
			key: "panel",
			components: [
				{
					label: "Description (optional)",
					showWordCount: false,
					showCharCount: false,
					tableView: true,
					alwaysEnabled: false,
					type: "textarea",
					input: true,
					key: "descriptionOptional3"
				},
				{
					label:
						"Submissions shall include a Bill of Materials (BOM) that represents the key components of the drone.",
					mask: false,
					tableView: true,
					alwaysEnabled: false,
					type: "file",
					input: true,
					key: "submissionsShallIncludeABillOfMaterialsBomThatRepresentsTheKeyComponentsOfTheDrone",
					storage: "base64",
					dir: "",
					webcam: false,
					data: { fileTypes: [{ label: "", value: "" }] }
				}
			]
		},
		{
			label: "Panel",
			title: "Technical Performance Measure Calculation",
			collapsible: false,
			mask: false,
			tableView: false,
			alwaysEnabled: false,
			type: "panel",
			input: false,
			key: "panel",
			components: [
				{
					label: "Description (optional)",
					showWordCount: false,
					showCharCount: false,
					tableView: true,
					alwaysEnabled: false,
					type: "textarea",
					input: true,
					key: "descriptionOptional4"
				},
				{
					label:
						"Submissions should include the estimation process used to establish estimates for Technical Performance Measures (Maximum 1-page).",
					mask: false,
					tableView: true,
					alwaysEnabled: false,
					type: "file",
					input: true,
					key:
						"submissionsShouldIncludeTheEstimationProcessUsedToEstablishEstimatesForTechnicalPerformanceMeasuresMaximum1Page",
					storage: "base64",
					dir: "",
					webcam: false,
					data: { fileTypes: [{ label: "", value: "" }] }
				}
			]
		},
		{
			type: "button",
			label: "Submit",
			key: "submit",
			disableOnInvalid: true,
			theme: "primary",
			input: true,
			tableView: true
		}
	]
}

const defaultForm = {
	display: "form",
	components: [
		{
			id: "e907ve",
			dir: "",
			key: "uploadSolutionFiles",
			tags: [],
			type: "file",
			image: false,
			input: true,
			label: "Upload solution files",
			logic: [],
			hidden: false,
			prefix: "",
			suffix: "",
			unique: false,
			webcam: false,
			widget: null,
			dbIndex: false,
			overlay: { top: "", left: "", page: "", style: "", width: "", height: "" },
			storage: "base64",
			tooltip: "",
			disabled: false,
			multiple: true,
			redrawOn: "",
			tabindex: "",
			validate: {
				json: "",
				custom: "",
				unique: false,
				multiple: true,
				required: true,
				customMessage: "",
				customPrivate: false,
				strictDateValidation: false
			},
			autofocus: false,
			encrypted: false,
			fileTypes: [{ label: "", value: "" }],
			hideLabel: false,
			imageSize: "200",
			modalEdit: false,
			protected: false,
			refreshOn: "",
			tableView: false,
			attributes: {},
			errorLabel: "",
			persistent: true,
			properties: {},
			uploadOnly: false,
			validateOn: "change",
			clearOnHide: true,
			conditional: { eq: "", json: "", show: null, when: null },
			customClass: "",
			description: "",
			fileMaxSize: "1GB",
			fileMinSize: "0KB",
			filePattern: "*",
			placeholder: "",
			defaultValue: null,
			labelPosition: "top",
			showCharCount: false,
			showWordCount: false,
			calculateValue: "",
			privateDownload: false,
			fileNameTemplate: "",
			customConditional: "",
			allowMultipleMasks: false,
			customDefaultValue: "",
			allowCalculateOverride: false
		},
		{
			id: "e46nee7",
			key: "submit",
			size: "md",
			type: "button",
			block: false,
			input: true,
			label: "Submit",
			theme: "primary",
			action: "submit",
			hidden: false,
			prefix: "",
			suffix: "",
			unique: false,
			widget: { type: "input" },
			dbIndex: false,
			overlay: { top: "", left: "", style: "", width: "", height: "" },
			tooltip: "",
			disabled: false,
			leftIcon: "",
			multiple: false,
			redrawOn: "",
			tabindex: "",
			validate: {
				custom: "",
				unique: false,
				multiple: false,
				required: false,
				customPrivate: false,
				strictDateValidation: false
			},
			autofocus: false,
			encrypted: false,
			hideLabel: false,
			modalEdit: false,
			protected: false,
			refreshOn: "",
			rightIcon: "",
			tableView: false,
			attributes: {},
			errorLabel: "",
			persistent: false,
			properties: {},
			validateOn: "change",
			clearOnHide: true,
			conditional: { eq: "", show: null, when: null },
			customClass: "",
			description: "",
			placeholder: "",
			defaultValue: null,
			dataGridLabel: true,
			labelPosition: "top",
			showCharCount: false,
			showWordCount: false,
			calculateValue: "",
			disableOnInvalid: true,
			allowMultipleMasks: false,
			customDefaultValue: "",
			allowCalculateOverride: false
		}
	]
}

const Functionalities = {
	CanGetAllCustomer: "CanGetAllCustomer",
	CanGetCustomerById: "CanGetCustomerById",
	CanGetCustomerByApiKey: "CanGetCustomerByApiKey",
	CanCreateOrUpdateCustomer: "CanCreateOrUpdateCustomer",
	CanDeleteCustomer: "CanDeleteCustomer",

	CanGetAllOrganization: "CanGetAllOrganization",
	CanGetOrganizationById: "CanGetOrganizationById",
	CanCreateOrUpdateOrganization: "CanCreateOrUpdateOrganization",
	CanDeleteOrganization: "CanDeleteOrganization",

	CanGetAllRole: "CanGetAllRole",
	CanGetRoleById: "CanGetRoleById",
	CanCreateOrUpdateRole: "CanCreateOrUpdateRole",
	CanDeleteRole: "CanDeleteRole",

	CanGetAllSolutions: "CanGetAllSolutions",
	CanGetSolutionById: "CanGetSolutionById",
	CanGetAllSolutionsByUserTeamChallenge: "CanGetAllSolutionsByUserTeamChallenge",
	CanGetOfficialSolutionByUserTeamChallenge: "CanGetOfficialSolutionByUserTeamChallenge",
	CanCreateOrUpdateSolution: "CanCreateOrUpdateSolution",
	CanDeleteSolution: "CanDeleteSolution",
	CanGetSolutionsByChallengeId: "CanGetSolutionsByChallengeId",

	CanGetAllUser: "CanGetAllUser",
	CanGetUserById: "CanGetUserById",
	CanGetUserByChallengeId: "CanGetUserByChallengeId",
	CanSearchUser: "CanSearchUser",
	CanCreateOrUpdateUser: "CanCreateOrUpdateUser",
	CanDeleteUser: "CanDeleteUser",

	CanGetAllTeam: "CanGetAllTeam",
	CanGetTeamById: "CanGetTeamById",
	CanGetTeamMembers: "CanGetTeamMembers",
	CanGetPendingInvitationForTeam: "CanGetPendingInvitationForTeam",
	CanGetPendingInvitationsForUser: "CanGetPendingInvitationsForUser",
	CanGetTeamsForUser: "CanGetTeamsForUser",
	CanCreateOrUpdateTeam: "CanCreateOrUpdateTeam",
	CanDeleteTeam: "CanDeleteTeam",
	CanInviteUsersInTeam: "CanInviteUsersInTeam",
	CanAcceptOrRejectTeamInvitation: "CanAcceptOrRejectTeamInvitation",
	CanRemoveUsersFromTeam: "CanRemoveUsersFromTeam",

	CanGetAllChallenge: "CanGetAllChallenge",
	CanGetChallengeById: "CanGetChallengeById",
	CanCreateChallenge: "CanCreateChallenge",
	CanUpdateChallenge: "CanUpdateChallenge",
	CanDeleteChallenge: "CanDeleteChallenge",
	CanAssignEvaluationCriteriaForChallenge: "CanAssignEvaluationCriteriaForChallenge",
	CanGetEvaluationCriteriaForChallenge: "CanGetEvaluationCriteriaForChallenge",
	CanassignEvaluatorsJudges: "CanassignEvaluatorsJudges",
	CanGetEvaluatorsJudgesForChallenge: "CanGetEvaluatorsJudgesForChallenge",
	CanJoinChallenge: "CanJoinChallenge",
	CanSendEmailToChallengeParticipants: "CanSendEmailToChallengeParticipants",
	CanSetFeaturedChallenge: "CanSetFeaturedChallenge",

	CanGetAllEvaluationCriteria: "CanGetAllEvaluationCriteria",
	CanGetEvaluationCriteriaById: "CanGetEvaluationCriteriaById",
	CanCreateOrUpdateEvaluationCriteria: "CanCreateOrUpdateEvaluationCriteria",
	CanDeleteEvaluationCriteria: "CanDeleteEvaluationCriteria",

	CanGetEvaluationScore: "CanGetEvaluationScore",
	CanCreateOrUpdateEvaluationScore: "CanCreateOrUpdateEvaluationScore",
	CanGetInternalLeaderboardForChallenge: "CanGetInternalLeaderboardForChallenge",
	CanGetFinalLeaderboardForChallenge: "CanGetFinalLeaderboardForChallenge",
	CanCreateOrUpdateEvaluationTeam: "CanCreateOrUpdateEvaluationTeam",
	CanGetEvaluationTeamForChallenge: "CanGetEvaluationTeamForChallenge",
	CanCreateOrUpdateManageChallengeInvitation: "CanCreateOrUpdateManageChallengeInvitation",
	CanGetManageChallengeInvitation: "CanGetManageChallengeInvitation",

	CanManageChallenge: "CanManageChallenge",

	CanEditAboutPage: "CanEditAboutPage",

	CanCreateStanding: "CanCreateStanding",

	CanGetReportingPage: "CanGetReportingPage"
}

const States = [
	"Alaska",
	"Alabama",
	"Arkansas",
	"American Samoa",
	"Arizona",
	"Colorado",
	"Connecticut",
	"District of Columbia",
	"Delaware",
	"Florida",
	"Georgia",
	"Guam",
	"Hawaii",
	"Iowa",
	"Idaho",
	"Illinois",
	"Indiana",
	"Kansas",
	"Kentucky",
	"Louisiana",
	"Massachusetts",
	"Maryland",
	"Maine",
	"Michigan",
	"Minnesota",
	"Missouri",
	"Mississippi",
	"Montana",
	"North Carolina",
	"North Dakota",
	"Nebraska",
	"New Hampshire",
	"New Jersey",
	"New Mexico",
	"Nevada",
	"New York",
	"Ohio",
	"Oklahoma",
	"Oregon",
	"Pennsylvania",
	"Puerto Rico",
	"Rhode Island",
	"South Carolina",
	"South Dakota",
	"Tennessee",
	"Texas",
	"Utah",
	"Virginia",
	"Virgin Islands",
	"Vermont",
	"Washington",
	"Wisconsin",
	"West Virginia",
	"Wyoming"
]

const Citizenship = [
	"Afghan",
	"Albanian",
	"Algerian",
	"American",
	"Andorran",
	"Angolan",
	"Antiguans",
	"Argentinean",
	"Armenian",
	"Australian",
	"Austrian",
	"Azerbaijani",
	"Bahamian",
	"Bahraini",
	"Bangladeshi",
	"Barbadian",
	"Barbudans",
	"Batswana",
	"Belarusian",
	"Belgian",
	"Belizean",
	"Beninese",
	"Bhutanese",
	"Bolivian",
	"Bosnian",
	"Brazilian",
	"British",
	"Bruneian",
	"Bulgarian",
	"Burkinabe",
	"Burmese",
	"Burundian",
	"Cambodian",
	"Cameroonian",
	"Canadian",
	"Cape Verdean",
	"Central African",
	"Chadian",
	"Chilean",
	"Chinese",
	"Colombian",
	"Comoran",
	"Congolese",
	"Costa Rican",
	"Croatian",
	"Cuban",
	"Cypriot",
	"Czech",
	"Danish",
	"Djibouti",
	"Dominican",
	"Dutch",
	"East Timorese",
	"Ecuadorean",
	"Egyptian",
	"Emirian",
	"Equatorial Guinean",
	"Eritrean",
	"Estonian",
	"Ethiopian",
	"Fijian",
	"Filipino",
	"Finnish",
	"French",
	"Gabonese",
	"Gambian",
	"Georgian",
	"German",
	"Ghanaian",
	"Greek",
	"Grenadian",
	"Guatemalan",
	"Guinea-Bissauan",
	"Guinean",
	"Guyanese",
	"Haitian",
	"Herzegovinian",
	"Honduran",
	"Hungarian",
	"I-Kiribati",
	"Icelander",
	"Indian",
	"Indonesian",
	"Iranian",
	"Iraqi",
	"Irish",
	"Israeli",
	"Italian",
	"Ivorian",
	"Jamaican",
	"Japanese",
	"Jordanian",
	"Kazakhstani",
	"Kenyan",
	"Kittian and Nevisian",
	"Kuwaiti",
	"Kyrgyz",
	"Laotian",
	"Latvian",
	"Lebanese",
	"Liberian",
	"Libyan",
	"Liechtensteiner",
	"Lithuanian",
	"Luxembourger",
	"Macedonian",
	"Malagasy",
	"Malawian",
	"Malaysian",
	"Maldivian",
	"Malian",
	"Maltese",
	"Marshallese",
	"Mauritanian",
	"Mauritian",
	"Mexican",
	"Micronesian",
	"Moldovan",
	"Monacan",
	"Mongolian",
	"Moroccan",
	"Mosotho",
	"Motswana",
	"Mozambican",
	"Namibian",
	"Nauruan",
	"Nepalese",
	"New Zealander",
	"Ni-Vanuatu",
	"Nicaraguan",
	"Nigerian",
	"Nigerien",
	"North Korean",
	"Northern Irish",
	"Norwegian",
	"Omani",
	"Pakistani",
	"Palauan",
	"Panamanian",
	"Papua New Guinean",
	"Paraguayan",
	"Peruvian",
	"Polish",
	"Portuguese",
	"Qatari",
	"Romanian",
	"Russian",
	"Rwandan",
	"Saint Lucian",
	"Salvadoran",
	"Samoan",
	"San Marinese",
	"Sao Tomean",
	"Saudi",
	"Scottish",
	"Senegalese",
	"Serbian",
	"Seychellois",
	"Sierra Leonean",
	"Singaporean",
	"Slovakian",
	"Slovenian",
	"Solomon Islander",
	"Somali",
	"South African",
	"South Korean",
	"Spanish",
	"Sri Lankan",
	"Sudanese",
	"Surinamer",
	"Swazi",
	"Swedish",
	"Swiss",
	"Syrian",
	"Taiwanese",
	"Tajik",
	"Tanzanian",
	"Thai",
	"Togolese",
	"Tongan",
	"Trinidadian or Tobagonian",
	"Tunisian",
	"Turkish",
	"Tuvaluan",
	"Ugandan",
	"Ukrainian",
	"Uruguayan",
	"Uzbekistani",
	"Venezuelan",
	"Vietnamese",
	"Welsh",
	"Yemenite",
	"Zambian",
	"Zimbabwean"
]

const Country = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"American Samoa",
	"Andorra",
	"Angola",
	"Anguilla",
	"Antarctica",
	"Antigua and/or Barbuda",
	"Argentina",
	"Armenia",
	"Aruba",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bermuda",
	"Bhutan",
	"Bolivia",
	"Bosnia and Herzegovina",
	"Botswana",
	"Bouvet Island",
	"Brazil",
	"British Indian Ocean Territory",
	"Brunei Darussalam",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Cape Verde",
	"Cayman Islands",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Christmas Island",
	"Cocos (Keeling) Islands",
	"Colombia",
	"Comoros",
	"Congo",
	"Cook Islands",
	"Costa Rica",
	"Croatia (Hrvatska)",
	"Cuba",
	"Cyprus",
	"Czech Republic",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"East Timor",
	"Ecudaor",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Ethiopia",
	"Falkland Islands (Malvinas)",
	"Faroe Islands",
	"Fiji",
	"Finland",
	"France",
	"France, Metropolitan",
	"French Guiana",
	"French Polynesia",
	"French Southern Territories",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Gibraltar",
	"Greece",
	"Greenland",
	"Grenada",
	"Guadeloupe",
	"Guam",
	"Guatemala",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Heard and Mc Donald Islands",
	"Honduras",
	"Hong Kong",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran (Islamic Republic of)",
	"Iraq",
	"Ireland",
	"Israel",
	"Italy",
	"Ivory Coast",
	"Jamaica",
	"Japan",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Korea, Democratic People's Republic of",
	"Korea, Republic of",
	"Kosovo",
	"Kuwait",
	"Kyrgyzstan",
	"Lao People's Democratic Republic",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libyan Arab Jamahiriya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Macau",
	"Macedonia",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Martinique",
	"Mauritania",
	"Mauritius",
	"Mayotte",
	"Mexico",
	"Micronesia, Federated States of",
	"Moldova, Republic of",
	"Monaco",
	"Mongolia",
	"Montserrat",
	"Morocco",
	"Mozambique",
	"Myanmar",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"Netherlands Antilles",
	"New Caledonia",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"Niue",
	"Norfork Island",
	"Northern Mariana Islands",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Pitcairn",
	"Poland",
	"Portugal",
	"Puerto Rico",
	"Qatar",
	"Reunion",
	"Romania",
	"Russian Federation",
	"Rwanda",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Georgia South Sandwich Islands",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"St. Helena",
	"St. Pierre and Miquelon",
	"Sudan",
	"Suriname",
	"Svalbarn and Jan Mayen Islands",
	"Swaziland",
	"Sweden",
	"Switzerland",
	"Syrian Arab Republic",
	"Taiwan",
	"Tajikistan",
	"Tanzania, United Republic of",
	"Thailand",
	"Togo",
	"Tokelau",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Turks and Caicos Islands",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates",
	"United Kingdom",
	"United States",
	"United States minor outlying islands",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Vatican City State",
	"Venezuela",
	"Vietnam",
	"Virigan Islands (British)",
	"Virgin Islands (U.S.)",
	"Wallis and Futuna Islands",
	"Western Sahara",
	"Yemen",
	"Yugoslavia",
	"Zaire",
	"Zambia",
	"Zimbabwe"
]

const Standings = [
	"First",
	"Second",
	"Third",
	"Fourth",
	"Fifth",
	"Sixth",
	"Seventh",
	"Eighth",
	"Ninth",
	"Tenth"
]

const Awards = [
	"1st Prize",
	"2nd Prize",
	"3rd Prize",
	"4th Prize",
	"5th Prize",
	"6th Prize",
	"7th Prize",
	"8th Prize",
	"9th Prize",
	"10 Prize"
]

const Sectors = [
	"Accounting",
	"Airlines/Aviation",
	"Alternative Dispute Resolution",
	"Alternative Medicine",
	"Animation",
	"Apparel & Fashion",
	"Architecture & Planning",
	"Arts & Crafts",
	"Automotive",
	"Aviation & Aerospace",
	"Banking",
	"Biotechnology",
	"Broadcast Media",
	"Building Materials",
	"Business Supplies & Equipment",
	"Capital Markets",
	"Chemicals",
	"Civic & Social Organization",
	"Civil Engineering",
	"Commercial Real Estate",
	"Computer & Network Security",
	"Computer Games",
	"Computer Hardware",
	"Computer Networking",
	"Computer Software",
	"Construction",
	"Consumer Electronics",
	"Consumer Goods",
	"Consumer Services",
	"Cosmetics",
	"Dairy",
	"Defense & Space",
	"Design",
	"Education Management",
	"E-learning",
	"Electrical & Electronic Manufacturing",
	"Entertainment",
	"Environmental Services",
	"Events Services",
	"Executive Office",
	"Facilities Services",
	"Farming",
	"Financial Services",
	"Fine Art",
	"Fishery",
	"Food & Beverages",
	"Food Production",
	"Fundraising",
	"Furniture",
	"Gambling & Casinos",
	"Glass, Ceramics & Concrete",
	"Government Administration",
	"Government Relations",
	"Graphic Design",
	"Health, Wellness & Fitness",
	"Higher Education",
	"Hospital & Health Care",
	"Hospitality",
	"Human Resources",
	"Import & Export",
	"Individual & Family Services",
	"Industrial Automation",
	"Information Services",
	"Information Technology & Services",
	"Insurance",
	"International Affairs",
	"International Trade & Development",
	"Internet",
	"Investment Banking/Venture",
	"Investment Management",
	"Judiciary",
	"Law Enforcement",
	"Law Practice",
	"Legal Services",
	"Legislative Office",
	"Leisure & Travel",
	"Libraries",
	"Logistics & Supply Chain",
	"Luxury Goods & Jewelry",
	"Machinery",
	"Management Consulting",
	"Maritime",
	"Marketing & Advertising",
	"Market Research",
	"Mechanical or Industrial Engineering",
	"Media Production",
	"Medical Device",
	"Medical Practice",
	"Mental Health Care",
	"Military",
	"Mining & Metals",
	"Motion Pictures & Film",
	"Museums & Institutions",
	"Music",
	"Nanotechnology",
	"Newspapers",
	"Nonprofit Organization Management",
	"Oil & Energy",
	"Online Publishing",
	"Outsourcing/Offshoring",
	"Package/Freight Delivery",
	"Packaging & Containers",
	"Paper & Forest Products",
	"Performing Arts",
	"Pharmaceuticals",
	"Philanthropy",
	"Photography",
	"Plastics",
	"Political Organization",
	"Primary/Secondary",
	"Printing",
	"Professional Training",
	"Program Development",
	"Public Policy",
	"Public Relations",
	"Public Safety",
	"Publishing",
	"Railroad Manufacture",
	"Ranching",
	"Real Estate",
	"Recreational",
	"Facilities & Services",
	"Religious Institutions",
	"Renewables & Environment",
	"Research",
	"Restaurants",
	"Retail",
	"Security & Investigations",
	"Semiconductors",
	"Shipbuilding",
	"Sporting Goods",
	"Sports",
	"Staffing & Recruiting",
	"Supermarkets",
	"Telecommunications",
	"Textiles",
	"Think Tanks",
	"Tobacco",
	"Translation & Localization",
	"Transportation/Trucking/Railroad",
	"Utilities",
	"Venture Capital",
	"Veterinary",
	"Warehousing",
	"Wholesale",
	"Wine & Spirits",
	"Wireless",
	"Writing & Editing"
]

const CHALLENGE_STATUS = {
	ACTIVE: "active",
	DRAFT: "draft",
	CLOSED: "closed",
	COMPLETED: "completed"
}

const NOTIFICATION_TYPE = {
	EVALUATION_COMPLETED: "EVALUATION_COMPLETED",
	SUBMISSIONS_EVALUATED: "SUBMISSIONS_EVALUATED"
}

const constants =  {
	Settings: Settings,
	formData: formData,
	defaultForm: defaultForm,
	Functionalities: Functionalities,
	Citizenship: Citizenship,
	Country: Country,
	States: States,
	Standings: Standings,
	Awards: Awards,
	Sectors: Sectors,
	CHALLENGE_STATUS: CHALLENGE_STATUS,
	NOTIFICATION_TYPE: NOTIFICATION_TYPE,
	FB_APP_ID: "553408151828440",
	GAPI_CLIENT_ID: "373297660608-0p90g6dkngldlopfneddstuc9ikvpus0.apps.googleusercontent.com",
	AMAZON_CLIENT_ID: "amzn1.application-oa2-client.980c6a4a37924da3aa3ab852499853ae",
	//LINKEDIN_APP_ID: "962LjaORSg4zhTq_Zt1SmMTSKLI",
	LINKEDIN_APP_ID: "r1658KuarxvjK_JA1B5WPuJg_m0"
}

export default constants;