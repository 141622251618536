export const rolesName = {
  generalUser: "General User",
  solver: "Solver",
  judge: "Judge",
  challengeAdmin: "Challenge Admin",
  licenseeAdmin: "Licensee Admin",
  evaluator: "Evaluator",
  admin: "Admin",
  solverForTeam: "Solver For Team",
  teamLead: "Team Lead",
  superAdmin: "Super Admin",
}