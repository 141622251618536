import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Pagination from 'material-ui-flat-pagination';
import { withTheme, withStyles, createStyles } from '@material-ui/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CustomTable from 'components/Table/Table';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import LoadingSpinner from 'views/Components/LoadingSpinner';
import CustomModal from 'components/general/CustomModal';
import EvaluatorScoreDetails from '../EvaluatorScoreDetails';

import solutionServices from 'dataServices/solutionServices';
import evaluationScoreServices from 'dataServices/evaluationScoreServices';

import combineStyles from 'utils/combineStyle';
import actionTypes from 'reduxjs/actionTypes';

import { getScores } from './utilities';
import { LEADERBOARD_TABLE_COLUMN, style } from './constants';
import styles from './styles.module.scss';

const ITEM_PER_PAGE = 10;

const SubmissionLeaderboard = props => {
  const { challenge, classes, setLoadingSpinner, resetLoadingSpinner } = props;

  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  
  const [isEvaluatorScoreDetailsModalOpen, setIsEvaluatorScoreDetailsModalOpen] = useState(false);
  const [selectedEvaluationScore, setSelectedEvaluationScore] = useState(null); 
  
  const [hasAccess, setHasAccess] = useState(true);
  const [solutions, setSolutions] = useState([]);
  const [totalSolutionCount, setTotalSolutionCount] = useState(0);
  const [evaluationScores, setEvaluationScores] = useState([]);

  const getSeeEvaluatorsButton = (index) => {
    return (
      <Button
        color="success"
        onClick={() => {
          setIsEvaluatorScoreDetailsModalOpen(true);
          setSelectedEvaluationScore(evaluationScores[index]);
        }}
      >
        See Details
      </Button>
    );
  };

  const prepareTableData = () => {
		const offset = Math.max(currentPage - 1, 0) * ITEM_PER_PAGE + 1;
		const solutionsTableData = solutions.map((solution, index) => {
      const { teamName, userName, title } = solution.solutionDetails;

      const { averageScore, scoreVariance } = getScores(evaluationScores || [], index);
			const evaluatorsSeeDetailsButton = getSeeEvaluatorsButton(index);

			return [
				offset + index,
				teamName || userName,
				title || "Form Submission",
				averageScore,
        scoreVariance,
				evaluatorsSeeDetailsButton,
			]
		});

		return solutionsTableData;
	}

  const fetchEvaluationScoreById = async (solutionId) => {
    try {
      return await evaluationScoreServices.getEvaluationScoresForSolutions(solutionId);
    } catch (err) {
      return [];
    }
	}

  const fetchEvaluationScores = async (solutionIds) => {
    try {
      const evaluationScoresData = await Promise.all(
        solutionIds.map((sId) => fetchEvaluationScoreById(sId))
      );

      return evaluationScoresData;
    } catch (err) {
      return [];
    }
	}

  const fetchSolutionsByChallenge = async () => {
    setIsLoading(true);
    setLoadingSpinner();

		try {
      const solutionData = await solutionServices.getSolutionsByChallengeIdPaginated(
        challenge.challengeId,
        currentPage,
        ITEM_PER_PAGE,
      );
      
      if (!solutionData) {
        throw new Error("No solutions are submitted yet");
      }

      const officialSolutions = solutionData.data.filter(
        (solution) => solution.solutionDetails.officialSubmission
      );
      const officialSolutionIdx = officialSolutions.map((solution) => solution.solutionId);
      const evaluationScoresData = await fetchEvaluationScores(officialSolutionIdx);

      setHasAccess(true);
      setSolutions(officialSolutions);
      setTotalSolutionCount(solutionData.totalCount);
      setEvaluationScores(evaluationScoresData);
      setIsLoading(false);

      resetLoadingSpinner();
    } catch(err) {
      setHasAccess(false);
      setIsLoading(false);

      resetLoadingSpinner();
    };
	}

  const onClickPaginationButton = async (offset) => {
		const newCurrentPage = offset / ITEM_PER_PAGE + 1;

    setCurrentPage(newCurrentPage);
    await fetchSolutionsByChallenge();
	}

  const renderEvaluatorScoreDetailsModal = () => {
    return (
      <CustomModal
        visible={isEvaluatorScoreDetailsModalOpen}
        title='Evaluation Details'
        onClose={() => {
          setIsEvaluatorScoreDetailsModalOpen(false);
          setSelectedEvaluationScore(null);
        }}
      >
        <EvaluatorScoreDetails currentScoreData={selectedEvaluationScore} />
      </CustomModal>
    );
  };

  const renderLeaderboardTable = () => {
    if (!hasAccess) {
      return (
        <div className={styles.noAccessSection}>
          Access for this challenge is off now
        </div>
      );
    }

    if ((solutions || []).length == 0) {
      return <h4> There are no submissions made yet </h4>;
    }

    const tableData = prepareTableData();

    return (
      <>
        <CustomTable
          tableHead={LEADERBOARD_TABLE_COLUMN}
          tableData={tableData}
        />
        {renderLeaderboardTablePagination()}
      </>
    );
  };

  const renderLeaderboardTablePagination = () => {
    const offset = Math.max(currentPage - 1, 0) * ITEM_PER_PAGE;
    const paginationClasses = {
			colorInheritCurrent: classes.colorInheritCurrent,
			colorInheritOther: classes.colorInheritOther
		}

    return (
      <GridContainer direction="row" alignItems="center" justify="center">
        <GridItem sm={12} md={8} lg={6}>
          <div className={styles.paginationContainer}>
            <Pagination
              classes={paginationClasses}
              currentPageColor="inherit"
              otherPageColor="inherit"
              size={"large"}
              limit={ITEM_PER_PAGE}
              offset={offset}
              total={totalSolutionCount}
              onClick={(e, offset) => onClickPaginationButton(offset)}
            />
          </div>
        </GridItem>
      </GridContainer>
    );
  };

  useEffect(() => {
    fetchSolutionsByChallenge();
  }, []);

  return (
    <>
      {renderEvaluatorScoreDetailsModal()}
      <Card color="gmgTheme">
        <CardHeader color="gmgTheme">
          <h4> Leaderboard {" | " + challenge.challengeDetails.title}</h4>
        </CardHeader>
        <CardBody color="gmgTheme">
          {isLoading ? <LoadingSpinner /> :  renderLeaderboardTable()}
        </CardBody>
      </Card>
    </>
  );
}

const reduxProps = {
  setLoadingSpinner: PropTypes.func.isRequired,
  resetLoadingSpinner: PropTypes.func.isRequired,
};

SubmissionLeaderboard.propTypes = {
  ...reduxProps,
  challenge: PropTypes.any,
};

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
	};
};

const paginationStyles = (theme) =>
	createStyles({
		paperRoot: {
			margin: theme.spacing(2),
			padding: theme.spacing(2)
		},
		colorInheritCurrent: {
			margin: theme.spacing(0.5),
			color: theme.palette.text.page,
			backgroundColor: fade("#00FF00", 0.5),
			"&:hover": {
				backgroundColor: fade("#00FF00", theme.palette.action.hoverOpacity)
			}
		},
		colorInheritOther: {
			margin: theme.spacing(0.5),
			color: theme.palette.text.page,
			"&:hover": {
				backgroundColor: fade("#FF0000", theme.palette.action.hoverOpacity)
			}
		},
		paginationDiv: {
			width: "100%",
			color: theme.palette.text.cardBody
		}
});

const finalStyles = combineStyles(paginationStyles);

const mapDispatchToProps = (dispatch) => {
  const {
    LOADING_SPINNER_SET,
    LOADING_SPINNER_RESET,
  } = actionTypes;

	return {
    setLoadingSpinner: () => dispatch({ type: LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: LOADING_SPINNER_RESET }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(withStyles(finalStyles)(withTheme(SubmissionLeaderboard)));