import React from "react"
import PropTypes from "prop-types"
import Tooltip from "@material-ui/core/Tooltip"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardFooter from "components/Card/CardFooter"
import CardIcon from "components/Card/CardIcon.jsx"
import Assignment from "@material-ui/icons/Assignment"
import Close from "@material-ui/icons/Close"
import Slider from "@material-ui/core/Slider"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import validator from "validator"
import { NavLink } from "react-router-dom"

import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"

import SimpleBreadcrumbs from "../../../components/SimpleBreadcumbs.jsx"
import ErrorPage from "views/Pages/ErrorPage"

import combineStyles from "utils/combineStyle"
import commonStyles from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx"
import ReactHtmlParser from 'html-react-parser'

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../../helper"
import constants from "../../../constants"
import authentication from "authentication"

import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import evaluationCriteriaServices from "dataServices/evaluationCriteriaServices"
import formServices from "dataServices/formServices"
import { List, ListItem, Grid, Divider, Checkbox } from "@material-ui/core"
import solutionService from "dataServices/solutionServices.js"
import evaluationScoreServices from "dataServices/evaluationScoreServices"
import notificationServices from "dataServices/notificationServices"
import { cardHeader } from "assets/jss/material-dashboard-pro-react.jsx"
import ManageChallengeMenu from "../ManageChallengeMenu.jsx"

import AssignedSolutionsList from "./AssignedSolutionsList"
import SetScoringUI from "./SetScoringUI"
import FontOverridePatch from "utils/FontOverridePatch.jsx"

class SubmissionScoringUI extends React.Component {
	constructor(props) {
		super(props)
		this.testProp = true
		this.state = {
			challengeId: "",
			challenge: null,
			evaluationCriteriaId: "",
			evaluationCriteria: null,
			solutions: [],
			activeSolutionIndex: 0,
			currentSolution: null,
			scores: [],
			slidersValues: [],
			commentTexts: [],
			showErrorComponent: false,
			isScoreModalOpen: false,
			selectedSolutionScoreIndex: 0,
			isLoading: true,
			dataFetchDone: false,
			isSubmitting: false,
			submissionForm: null,
			currentUserProfile: null,
			challengeAdmins: [],
			showConfirmFinalScorePopup: false,
			minScore: 0,
			maxScore: 0
		}
	}

	storeCriteriaIntoState = (criteria) => {
		let slidersValues = []
		let commentTexts = []
		let cobj1 = criteria.evaluationCriteriaDetails.criteriaArray.map((c) => {
			slidersValues.push(0)
			commentTexts.push("")
			return {
				evaluationCriterionName: c.evaluationCriterionName,
				weight: c.weight
			}
		})
		let evaluationCriteriaId = criteria.evaluationCriteriaId
		this.setState({
			evaluationCriteriaId: evaluationCriteriaId,
			evaluationCriteria: cobj1,
			slidersValues: slidersValues,
			commentTexts: commentTexts,
			maxScore: criteria.evaluationCriteriaDetails.maxScore,
			minScore: criteria.evaluationCriteriaDetails.minScore
		})
	}

	onSelectInitSolutionToScore = (index) => {
		let slidersValues = [],
			commentTexts = []
		if (!this.state.scores[index] || !this.state.scores[index].id) {
			this.state.evaluationCriteria.map((v) => {
				slidersValues.push(0)
				commentTexts.push("")
			})
		} else {
			this.state.scores[index].evaluationScoreDetails.criteriaBasedScore.map((c) => {
				slidersValues.push(parseInt(c.scoreValue))
				commentTexts.push(c.commentText)
			})
		}
		this.setState({ slidersValues: slidersValues, commentTexts: commentTexts })
	}

	fetchSubmissionForm = (challengeId) => {
		return formServices
			.getFormByChallengeId(challengeId)
			.then((form) => {
				return form
			})
			.catch((err) => {
				console.log("Error fetching submission form, err =", err.message)
				return null
			})
	}

	fetchEvaluationCriteria = (challengeId) => {
		return challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				if (data.challengeDetails.evaluationCriteriaId) {
					return evaluationCriteriaServices
						.getEvaluationCriteriaById(data.challengeDetails.evaluationCriteriaId)
						.then((criteria) => {
							if (!criteria) throw new Error("Evaluation criteria is not set")
							return criteria
						})
				} else {
					throw new Error("Evaluation criteria is not set")
				}
			})
			.catch((err) => {
				console.log("Error fetching evaluation criteria, err =", err.message)
				return null
			})
	}

	fetchAssignedSolutions = (challengeId) => {
		return solutionService
			.getSolutionsAssignedToEvaluatorsByChallenge(challengeId)
			.then((solutions) => {
				return solutions
			})
			.catch((err) => {
				console.log("Error fetching assigned solutions, err =", err.message)
				return null
			})
	}

	fetchEvaluationScore = (solutionId) => {
		return evaluationScoreServices
			.getEvaluationScoreBySolutionByEvaluator(null, solutionId)
			.then((data) => {
				if (!data) throw new Error("No Evaluation score is found")
				return data
			})
			.catch((err) => {
				console.log("Error fetching evaluation score, err =", err.message)
				return null
			})
	}

	fetchAllEvaluationScore = (solutionIds) => {
		return Promise.all(solutionIds.map((sId) => this.fetchEvaluationScore(sId)))
			.then((result) => {
				return result
			})
			.catch((err) => {
				console.log("Error fetching all the evaluation score for all the submissions", err.message)
				return solutionIds.map((sId) => null)
			})
	}

	fetchAllChallengeAdmins = (challengeId) => {
		return challengeServices
			.getAllUsersOfChallenge(this.state.challengeId, ["challengeAdmin"])
			.then((data) => {
				if (data && data.challengeAdmins) return data.challengeAdmins
				else throw new Error("Error fetching challenge admin data")
			})
			.catch((err) => {
				console.log("Error fetching challenge admin data, err =", err.message)
				return null
			})
	}

	fetchAllData = (challengeId) => {
		this.props.setLoadingSpinner()
		return Promise.all([
			challengeServices.getChallengeById(challengeId),
			userServices.getCurrentUser()
		])
			.then((result) => {
				let challenge = result[0]
				let userProfile = result[1]
				if (!challenge) throw new Error("Challenge is not found")
				if (!userProfile) throw new Error("You are not logged in, Please login to see this")
				this.setState({
					challengeId: challenge.challengeId,
					urlSlug: challenge.urlSlug,
					challenge: challenge,
					currentUserProfile: userProfile
				})
				return Promise.all([
					this.fetchEvaluationCriteria(challenge.challengeId),
					this.fetchAssignedSolutions(challenge.challengeId),
					this.fetchSubmissionForm(challenge.challengeId),
					this.fetchAllChallengeAdmins(challenge.challengeId)
				])
			})
			.then((result) => {
				console.log("Fetch criteria and assigned solutions done", result)
				if (!result[0]) throw new Error("Evaluation Criteria is not set")
				this.storeCriteriaIntoState(result[0])
				if (!result[1] || !result[1].length) throw new Error("No Submission is assigned to you yet")
				this.setState({ solutions: result[1], currentSolution: result[1][0] })
				if (result[2]) this.setState({ submissionForm: result[2] })
				let solutionIds = result[1].map((soln) => soln.solutionId)
				if (result[3]) this.setState({ challengeAdmins: result[3] })
				return this.fetchAllEvaluationScore(solutionIds)
			})
			.then((scores) => {
				console.log("evaluation scores existing = ", scores)
				this.setState({ scores: scores, isLoading: false })
				this.onSelectInitSolutionToScore(0)
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("Error fetching component's data, err =", err.message)
				this.setState({ isLoading: false })
				this.props.resetLoadingSpinner()
			})
	}

	handleOnClickSubmissionTitle = (event, solutionIndex) => {
		event.preventDefault()
		this.setState({
			activeSolutionIndex: solutionIndex,
			currentSolution: this.state.solutions[solutionIndex]
		})
		this.onSelectInitSolutionToScore(solutionIndex)
		helper.scrollTo("#submission-scoring-div")
	}

	handleSubmitScore = (slidersValues, commentTexts) => {
		if (this.state.isSubmitting) {
			return
		}
		let id = this.state.scores[this.state.activeSolutionIndex]
			? this.state.scores[this.state.activeSolutionIndex].id
			: null
		let challengeId = this.state.challengeId
		let solutionId = this.state.solutions[this.state.activeSolutionIndex].solutionId
		let evaluatorUserId = this.props.loggedInUserData.userId
		let solverUserId = this.state.solutions[this.state.activeSolutionIndex].userId
		let solverTeamId = this.state.solutions[this.state.activeSolutionIndex].teamId || null
		let criteriaBasedScore = this.state.evaluationCriteria.map((c) => ({
			criteriaName: c.evaluationCriterionName
		}))
		criteriaBasedScore = criteriaBasedScore.map((criterion, index) => {
			return {
				...criterion,
				scoreValue: parseInt(slidersValues[index]),
				commentText: commentTexts[index]
			}
		})

		this.setState({ isSubmitting: true })
		this.props.setLoadingSpinner()
		evaluationScoreServices
			.createOrUpdate(
				id,
				challengeId,
				solutionId,
				evaluatorUserId,
				null,
				solverUserId,
				solverTeamId,
				criteriaBasedScore
			)
			.then((data) => {
				if (!data) throw new Error("Error submitting evaluation score")
				return this.fetchAssignedSolutions(this.state.challengeId)
			})
			.then((data) => {
				if (!data) {
					throw new Error(
						"Error fetching updated solutions after submitting the score, please reload the page to see latest data"
					)
				}
				this.setState({ solutions: data })
				let solutionIds = data.map((solution) => solution.solutionId)
				return this.fetchAllEvaluationScore(solutionIds)
			})
			.then((scores) => {
				this.setState({ scores: scores, isSubmitting: false })
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("Error submitting scores, err =", err.message)
				this.props.showAlert("error", "Oops!", err.message)
				this.props.resetLoadingSpinner()
				this.setState({ isSubmitting: false })

				setTimeout(() => {
					window.location.reload()
				}, 5000)
			})
	}

	handleSendEmail = () => {
		evaluationScoreServices
			.sendEmailToAdminOnScoringComplete(this.state.challengeId)
			.then((data) => {
				if (data && data == "OK") {
					this.props.showAlert(
						"info",
						"Mail Sent!",
						"You have sent email to admin about the scoring completion"
					)
				} else throw new Error("Error sending email to admins")
			})
			.catch((err) => {
				console.log("Error sending mail, err =", err.message)
				this.props.showAlert("error", "Oops!", err.message)
			})
	}

	handleOnClickNotify = () => {
		if (!this.state.showConfirmFinalScorePopup) {
			this.setState({ showConfirmFinalScorePopup: true })
			return
		}

		let notificationData = {
			notificationType: constants.NOTIFICATION_TYPE.EVALUATION_COMPLETED,
			notificationDetails: {
				notificationText: "Assigned submissions has been evaluated",
				challengeId: this.state.challengeId
			},
			to: {}
		}

		this.props.setLoadingSpinner()
		Promise.all(
			this.state.challengeAdmins.map((user) => {
				notificationData.to = { userId: user.userId }
				return notificationServices
					.saveNotification(notificationData)
					.then((data) => {
						if (!data) throw new Error("Error in sending the notification")
						return data
					})
					.catch((err) => {
						console.log("Error = ", err.message)
						return null
					})
			})
		)
			.then((result) => {
				let notificationData = {
					notificationType: constants.NOTIFICATION_TYPE.SUBMISSIONS_EVALUATED,
					notificationDetails: {
						notificationText: "Evaluation completed for the assigned submissions",
						challengeId: this.state.challengeId
					},
					to: { userId: this.state.currentUserProfile.userId }
				}
				return notificationServices.saveNotification(notificationData)
			})
			.then((data) => {
				if (!data) throw new Error("Error occurred while sending notification")
				return true
			})
			.then((result) => {
				console.log("result = ", result)
				this.setState({ showConfirmFinalScorePopup: false })
				this.props.resetLoadingSpinner()
				this.props.showAlert(
					"success",
					"Success!",
					"You have finalized all the scores and notified the admin"
				)
			})
			.catch((err) => {
				console.log("Errr = ", err.message)
				this.setState({ showConfirmFinalScorePopup: false })
				this.props.resetLoadingSpinner()
				this.props.showAlert("error", "Oops!", "Error sending notification")
			})
	}

	checkPermission = () => {
		let permissionsObject = [
			{
				permissionName: constants.Functionalities.CanGetEvaluationScore,
				objectId: this.state.challengeId
			},
			{
				permissionName: constants.Functionalities.CanCreateOrUpdateEvaluationScore,
				objectId: this.state.challengeId
			}
		]
		authentication
			.hasMultiplePermissions(permissionsObject)
			.then((res) => {
				let permissionOkay = true
				if (res && res.length > 0) {
					res.map((object) => {
						permissionOkay = permissionOkay && object.permissionStatus
					})
				} else {
					permissionOkay = false
				}
				if (!permissionOkay) {
					this.setState({ showErrorComponent: true })
					this.props.setRedirectOnError("/home/index")
				}
			})
			.catch((err) => {
				console.log("Err = ", err.message)
				this.setState({ showErrorComponent: true })
				this.props.setRedirectOnError("/home/index")
			})
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props
		this.props.pushBreadCrumbStack({
			name: "Submission Scoring",
			link: this.props.location.pathname
		})
		if (validator.isUUID(params.challengeId)) {
			this.setState({ challengeId: params.challengeId })
		} else {
			this.setState({ slugUrl: params.challengeId })
		}
		this.checkPermission()
		this.fetchAllData(params.challengeId)
	}

	onClickSeeScore = (rowIndex) => {
		this.setState({
			selectedSolutionScoreIndex: rowIndex,
			isScoreModalOpen: true
		})
	}

	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	render() {
		const { classes } = this.props
		if (this.state.showErrorComponent || this.state.isLoading) return null
		let errorMessage = null
		if (!this.state.evaluationCriteria) errorMessage = "Evaluation criteria is not set yet"
		else if (!this.state.solutions || !this.state.solutions.length)
			errorMessage = "No Submission is assigned to you yet"

		console.log("props = ", this.props)
		return (
			<div>
				{this.renderError()}
				{this.renderConfirmFinalScoreModal()}
				{this.renderDetailedScoreModal()}
				<GridContainer direction="row" justify="space-between">
					<GridItem xs={9} sm={10} md={10} lg={11}>
						<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
					</GridItem>
					<GridItem className={classes.right} xs={3} sm={2} md={2} lg={1}>
						{this.state.challenge && this.state.challengeId && (
							<ManageChallengeMenu
								challengeId={this.state.challengeId}
								challenge={this.state.challenge}
								urlSlug={this.state.urlSlug}
								history={this.props.history}
							/>
						)}
					</GridItem>
				</GridContainer>

				<br />
				<SnackbarContent
					message={this.state.challenge.challengeDetails.phaseTitle}
					color="success"
				/>

				<Card raised>
					<CardHeader icon>
						<CardIcon color="rose">
							<Assignment />
						</CardIcon>
						<h3 className={classes.cardIconTitle}>
							Submission Scoring
							{this.state.challenge ? " | " + this.state.challenge.challengeDetails.title : ""}
						</h3>
					</CardHeader>
					<CardBody className={classes.layout}>
						{errorMessage && (
							<div
								style={{
									margin: "auto",
									marginTop: "50px",
									marginBottom: "50px"
								}}
							>
								<GridContainer alignItems="center" justify="center" direction="row">
									<GridItem lg={6} sm={12} md={8} xs={12}>
										<h5> {errorMessage} </h5>
									</GridItem>
								</GridContainer>
							</div>
						)}

						<AssignedSolutionsList
							classes={this.props.classes}
							solutions={this.state.solutions}
							scores={this.state.scores}
							onClickSeeScore={this.onClickSeeScore}
							handleOnClickSubmissionTitle={this.handleOnClickSubmissionTitle}
						/>

						<div id="submission-scoring-div"></div>

						<SetScoringUI
							classes={classes}
							evaluationCriteria={this.state.evaluationCriteria}
							solutions={this.state.solutions}
							currentSolution={this.state.currentSolution}
							scores={this.state.scores}
							submissionForm={this.state.submissionForm}
							maxScore={this.state.maxScore}
							commentTexts={this.state.commentTexts}
							slidersValues={this.state.slidersValues}
							handleSubmitScore={this.handleSubmitScore}
							isSubmitting={this.state.isSubmitting}
							handleOnClickNotify={this.handleOnClickNotify}
						/>
					</CardBody>
				</Card>
			</div>
		)
	}

	renderDetailedScoreModal = () => {
		const { classes } = this.props
		let selectedIndex = this.state.selectedSolutionScoreIndex
		if (
			!this.state.isScoreModalOpen ||
			this.state.scores.length <= selectedIndex ||
			!this.state.scores[selectedIndex]
		)
			return null
		let submissionName = this.state.solutions[selectedIndex].solutionDetails.title
		let currentScoreData = this.state.scores[selectedIndex]
		let totalScore = 0
		currentScoreData.evaluationScoreDetails.criteriaBasedScore.map((criterionScore) => {
			totalScore += criterionScore.scoreValue
		})

		return (
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modal
				}}
				open={true}
				keepMounted
				onClose={() => {
					this.setState({ isScoreModalOpen: false })
				}}
			>
				<DialogTitle
					disableTypography
					className={classes.modalHeader}
					style={{
						margin: "0px 0px"
					}}
				>
					<Button
						justIcon
						className={classes.modalCloseButton}
						color="transparent"
						onClick={() => {
							this.setState({ isScoreModalOpen: false })
						}}
					>
						<Tooltip title="Close" placement="top">
							<Close />
						</Tooltip>
					</Button>
					<h4 className={classes.modalTitle}>Detailed Scores</h4>
				</DialogTitle>

				<DialogContent className={classes.modalBody}>
					<div style={{ minWidth: "500px" }}> </div>
					<Card>
						<CardBody>
							<Grid container direction="column" justify="flex-start" alignItems="flex-start">
								<Grid item lg={12} xs={12}>
									<h4> Submission: {submissionName || "View Submission"} </h4>
								</Grid>
								<Grid item lg={12} xs={12}>
									<h5>
										Review Status: <span style={{ color: "green" }}> Review Completed </span>
									</h5>
								</Grid>
								<Grid item>
									<Divider variant="middle" />
								</Grid>
								<Grid item lg={12} xs={12}>
									<h5> Criteria based scores details: </h5>
								</Grid>
								{currentScoreData.evaluationScoreDetails.criteriaBasedScore.map((criterion) => {
									return (
										<Grid item lg={12} xs={12}>
											<h5>
												<FontOverridePatch>
													{ReactHtmlParser(criterion.criteriaName || "")}: {criterion.scoreValue}
												</FontOverridePatch>
											</h5>
										</Grid>
									)
								})}
								<Grid item lg={12} xs={12}>
									<h5>Total Score: {totalScore}</h5>
								</Grid>
							</Grid>
						</CardBody>
					</Card>
				</DialogContent>
			</Dialog>
		)
	}

	renderConfirmFinalScoreModal = () => {
		const { classes } = this.props
		if (!this.state.showConfirmFinalScorePopup) return null

		return (
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modal
				}}
				open={true}
				keepMounted
				onClose={() => {
					this.setState({ showConfirmFinalScorePopup: false })
				}}
			>
				<DialogTitle
					disableTypography
					className={classes.modalHeader}
					style={{
						margin: "0px 0px"
					}}
				>
					<Button
						justIcon
						className={classes.modalCloseButton}
						color="transparent"
						onClick={() => {
							this.setState({ showConfirmFinalScorePopup: false })
						}}
					>
						<Tooltip title="Close" placement="top">
							<Close />
						</Tooltip>
					</Button>
					<h4 className={classes.modalTitle}>Are you sure?</h4>
				</DialogTitle>

				<DialogContent className={classes.modalBody}>
					You are about to finalize all the scores and notify admin. Are you sure?
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						variant="contained"
						onClick={(e) => {
							this.setState({ showConfirmFinalScorePopup: false })
						}}
					>
						No
					</Button>
					<Button color="secondary" variant="contained" onClick={this.handleOnClickNotify}>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderError = () => {
		if (!this.state.showErrorComponent) return null
		const { classes } = this.props
		return (
			<div>
				<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
				<GridContainer direction="row" justify="space-evenly" alignItems="center">
					<GridItem>
						<ErrorPage
							errorCode={403}
							message={"Access Denied! :("}
							description={"You don't have permission to see this page"}
						/>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

const extraStyles = (theme) => ({
	layout: {
		width: "auto",
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(1200 + theme.spacing(2) * 2)]: {
			width: 1200,
			marginLeft: "auto",
			marginRight: "auto"
		}
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(1200 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3)
		}
	},
	root: {
		width: "100%",
		marginTop: theme.spacing(3),
		overflowX: "auto"
	},
	table: {
		//minWidth: 650,
	},
	card: {
		//minWidth: 275,
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)"
	},
	title: {
		fontSize: 14
	},
	pos: {
		marginBottom: 12
	},
	margin: {
		height: theme.spacing(3)
	},
	criteriaPaper: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(3, 3),
		border: "1px solid grey"
	}
})

SubmissionScoringUI.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({ type: actionTypes.SHOW_ALERT, payload: { alertType, title, description } }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

let finalStyles = combineStyles(extendedTablesStyle, commonStyles, notificationsStyle, extraStyles)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyles)(SubmissionScoringUI))
