import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";

import pricingPageStyle from "assets/jss/material-dashboard-pro-react/views/pricingPageStyle.jsx";
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx";
import helper from "../../helper";

class ComingSoonPage extends React.Component {
    componentWillUnmount() {
        helper.setPageTitle("BestInCrowd");
    }
    render() {
        const { classes } = this.props;
        helper.setPageTitle("Coming Soon! | BestInCrowd");
        return (
            <div>
                <SimpleBreadcrumbs
                    breadLinkData={[
                        { name: "Home", link: "/home/index" },
                        { name: "Coming Soon", link: "/home/comming-soon" }
                    ]}
                />
                <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={6}>
                        <div>
                            <CardHeader>
                                <h2 style={{ color: "#1b5e20" }}>Coming Soon Page</h2>
                            </CardHeader>
                            <CardBody>
                                <h5 className={""}> This feature is coming soon! </h5>
                            </CardBody>
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

ComingSoonPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(pricingPageStyle)(ComingSoonPage);
