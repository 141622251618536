import React from "react"
import InfoIcon from "@material-ui/icons/InfoOutlined"
import FlightTakeOffIcon from "@material-ui/icons/FlightTakeoffOutlined"
import LabelIcon from "@material-ui/icons/LabelOutlined"
import CloudUploadIcon from "@material-ui/icons/CloudUploadOutlined"
import CloseIcon from "@material-ui/icons/CloseOutlined"
import PeopleIcon from "@material-ui/icons/PeopleOutlined"
import GradeIcon from "@material-ui/icons/GradeOutlined"
import FlightLandingIcon from "@material-ui/icons/FlightLandOutlined"
import Constants from "./constants"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"

const fileUploadApiUrl = Constants.Settings.APIURL + "/upload"

const configureFileUploadApiOnForm = (formComponentsJsonArray) => {
	let compos = formComponentsJsonArray.map((comp) => {
		if (comp.input == true && comp.type == "file") {
			comp.storage = "url"
			comp.url = fileUploadApiUrl
		}
		return comp
	})
	return compos
}

function scrollTo(elementId) {
	let path = window.location.pathname
	path = path.split("#")[0] + elementId
	window.location.href = path
}

function getSubmissionName(userName, teamName, solutionId) {
	let name = ""
	let idString = solutionId.slice(-4)
	if (teamName) {
		name = teamName + "_" + idString
	} else if (userName) {
		name = userName + "_" + idString
	} else name = idString
	return name.replace(" ", "_").toLowerCase()
}

const phaseIcons = [
	"InfoIcon",
	"FlightTakeOffIcon",
	"CloudUploadIcon",
	"CloseIcon",
	"PeopleIcon",
	"GradeIcon",
	"FlightLandingIcon",
	"LabelIcon"
]

function numberRoundToDecimal(num, digit = 2) {
	return Number(num).toFixed(digit)
}

function getHtmlStringFromEditorState(editorState) {
	if (!editorState) editorState = EditorState.createEmpty()
	let ret = draftToHtml(convertToRaw(editorState.getCurrentContent()))

	return ret
}

function getEditorStateFromHtmlString(htmlString) {
	let ret = null
	if (htmlString) {
		const contentBlock = htmlToDraft(htmlString)
		const contentState = ContentState.createFromBlockArray(
			contentBlock.contentBlocks,
			contentBlock.entityMap
		)
		ret = EditorState.createWithContent(contentState)
	} else {
		ret = EditorState.createEmpty()
	}

	return ret
}

function isRTEHtmlStringValid(RTEString) {
	return (
		/<.+? (src=".+?").+?>/gim.test(RTEString) ||
		/\w/gim.test(RTEString.replace(/(<.+?>)|(&nbsp;)/gim, ""))
	)
}

function getOffsetFromPagination(pagination) {
	let offset = Math.max(pagination.currentPage - 1, 0) * pagination.itemsPerPage
	return offset
}

function getCurrentPageFromOffset(offset, itemsPerPage) {
	let currentPage = offset / itemsPerPage + 1
	return currentPage
}

export default {
	setPageTitle: (title) => (document.title = title),
	renderPhaseIcon: (phaseIconString) => {
		if (phaseIconString == "InfoIcon") return <InfoIcon />
		else if (phaseIconString == "FlightTakeOffIcon") return <FlightTakeOffIcon />
		else if (phaseIconString == "LabelIcon") return <LabelIcon />
		else if (phaseIconString == "CloudUploadIcon") return <CloudUploadIcon />
		else if (phaseIconString == "CloseIcon") return <CloseIcon />
		else if (phaseIconString == "PeopleIcon") return <PeopleIcon />
		else if (phaseIconString == "GradeIcon") return <GradeIcon />
		else if (phaseIconString == "FlightLandingIcon") return <FlightLandingIcon />
		else return null
	},
	convertPhaseIcon: (phaseIconString) => {
		if (phaseIconString == "InfoIcon") return InfoIcon
		else if (phaseIconString == "FlightTakeOffIcon") return FlightTakeOffIcon
		else if (phaseIconString == "LabelIcon") return LabelIcon
		else if (phaseIconString == "CloudUploadIcon") return CloudUploadIcon
		else if (phaseIconString == "CloseIcon") return CloseIcon
		else if (phaseIconString == "PeopleIcon") return PeopleIcon
		else if (phaseIconString == "GradeIcon") return GradeIcon
		else if (phaseIconString == "FlightLandingIcon") return FlightLandingIcon
		else return null
	},
	phaseIcons: phaseIcons,
	configureFileUploadApiOnForm: configureFileUploadApiOnForm,
	scrollTo: scrollTo,
	getSubmissionName: getSubmissionName,
	numberRoundToDecimal: numberRoundToDecimal,
	getHtmlStringFromEditorState: getHtmlStringFromEditorState,
	getEditorStateFromHtmlString: getEditorStateFromHtmlString,
	isRTEHtmlStringValid: isRTEHtmlStringValid,
	getOffsetFromPagination: getOffsetFromPagination,
	getCurrentPageFromOffset: getCurrentPageFromOffset
}
