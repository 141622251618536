export default {
	LOGIN_MODAL_OPEN: "LOGIN_MODAL_OPEN",
	LOGIN_MODAL_CLOSE: "LOGIN_MODAL_CLOSE",
	LOGGEDIN_USER_DATA_SET: "LOGGEDIN_USER_DATA_SET",
	LOADING_SPINNER_SET: "LOADING_SPINNER_SET",
	LOADING_SPINNER_RESET: "LOADING_SPINNER_RESET",
	SHOW_ALERT: "SHOW_ALERT",
	HIDE_ALERT: "HIDE_ALERT",
	AFTER_LOGIN_REDIRECT_URL_SET: "AFTER_LOGIN_REDIRECT_URL_SET",
	RESET_BREAD_CRUMB_STACK: "RESET_BREAD_CRUMB_STACK",
	PUSH_BREAD_CRUMB_STACK: "PUSH_BREAD_CRUMB_STACK",
	POP_BREAD_CRUMB_STACK: "POP_BREAD_CRUMB_STACK",
	TOGGLE_DARK_MODE: "TOGGLE_DARK_MODE",
	CONTENT_HEIGHT_UPDATED: "CONTENT_HEIGHT_UPDATED",
	REDIRECT_ON_ERROR: "REDIRECT_ON_ERROR",
	SWITCH_THEME: "SWITCH_THEME",
	SET_KEYWORD_SEARCH_TEXT: "SET_KEYWORD_SEARCH_TEXT",
	SET_NOTIFICATION_COUNT: "SET_NOTIFICATION_COUNT"
}
