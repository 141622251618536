import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import SolversPageComponent from "../Dashboard/Solvers"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import userServices from "dataServices/userServices.js"
import teamServices from "dataServices/teamServices"
import TeamUI from "./TeamUI.jsx"
import SolversListUI from "./SolversListUI.jsx"
import TeamsListUI from "./TeamsListUI.jsx"
import challengeServices from "dataServices/challengeServices.js"

import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Card from "components/Card/Card"
import CardBody from "components/Card/CardBody"
import { Paper } from "@material-ui/core"
import Button from "components/CustomButtons/Button"
import CardHeader from "components/Card/CardHeader.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import { Mutation } from "@apollo/client/react/components"
import Pagination from "material-ui-flat-pagination"

import ManageMembersComponent from "../ManageTeam/ManageTeamMembers"
import cookiesHelper from "cookiesHelper.js"
import EditTeamMembers from "../ManageTeam/EditTeamMembers.jsx"

import { uploadFileMutation, uploadFileHandler } from "dataServices/uploadFileServices"

import Constants from "../../../constants"
import { debounce } from "lodash-es"
import DebouncedActionPerformer from "views/Components/DebouncedActionPerformer.jsx"
import constants from "../../../constants"

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	container: {
		margin: "0px 0px",
		padding: "40px 20px",
		backgroundColor: theme.palette.background.page
	}
})

class Solvers extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			challengeId: "",
			teamName: "",
			teamDescription: "",
			teamAvatar: "",
			teamAffiliation: "",
			teamMembers: null,
			isTeamLead: false,
			isDataFetched: false,
			solversList: null,
			solversTotalCount: 0,
			solversTotalFetched: 0,
			currentPage: 1,
			itemsPerPage: 10,
			teams: [],
			teamsCurrentPage: 1,
			teamsItemsPerPage: 10,
			teamsTotalCount: 0,
			viewAs: "admin",
			editTeamMode: false,
			selectedUsers: [],
			file: null,
			fileName: "",
			availableRoles: [
				{ name: "Member", value: "teamMember" },
				{ name: "Team Lead", value: "teamLead" }
			],
			isConfirmDeleteModalOpen: false,
			currentUserProfile: null,
			solverSearchText: "",
			teamSearchText: ""
		}
	}

	fetchSolvers = () => {
		return userServices
			.solvers(
				this.state.currentPage,
				this.state.itemsPerPage,
				this.props.challengeId,
				this.state.solverSearchText
			)
			.then((data) => {
				return data ? data : null
			})
			.catch((err) => {
				return null
			})
	}

	onClickPaginationButtonTeams = (offset) => {
		let currentPage = offset / this.state.teamsItemsPerPage + 1
		this.setState({ teamsCurrentPage: currentPage }, () => {
			this.props.setLoadingSpinner()
			this.fetchTeams()
				.then((data) => {
					this.props.resetLoadingSpinner()
					if (!data || !data.data) throw new Error("No solvers found")
					this.setState({
						teams: data.data,
						teamsTotalCount: data.totalCount
					})
				})
				.catch((err) => {
					console.log("Error fetching solvers", err.message)
					this.setState({ teams: [], teamsTotalCount: 0 })
					this.props.resetLoadingSpinner()
				})
		})
	}

	onClickPaginationButtonSolvers = (offset) => {
		let currentPage = offset / this.state.itemsPerPage + 1
		this.setState({ currentPage: currentPage }, () => {
			this.props.setLoadingSpinner()
			this.fetchSolvers()
				.then((data) => {
					this.props.resetLoadingSpinner()
					if (!data) throw new Error("No solvers found")
					this.setState({
						solversList: data.data,
						solversTotalCount: data.totalCount,
						solversTotalFetched: data.data.length
					})
				})
				.catch((err) => {
					console.log("Error fetching solvers", err.message)
					this.setState({ solversList: [], solversTotalCount: 0, solversTotalFetched: 0 })
					this.props.resetLoadingSpinner()
				})
		})
	}

	onClickRemoveParticipant = (userId) => {
		this.props.setLoadingSpinner()
		challengeServices
			.removeSolverOrTeam(this.state.challengeId, userId, "")
			.then((status) => {
				return this.fetchSolvers()
			})
			.then((data) => {
				if (!data || !data.data) {
					throw new Error("No solvers found")
				}
				this.setState({
					solversList: data.data,
					solversTotalCount: data.totalCount,
					solversTotalFetched: data.data.length
				})
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error in deleting the solver, err =", err.message)
				this.props.showAlert("error", "Oops!", err.message)
			})
	}

	onClickApproveParticipant = () => {
		this.props.setLoadingSpinner();

		this.fetchSolvers().then((data) => {
			this.props.resetLoadingSpinner();

			if (!data) {
				throw new Error("No solvers found");
			}

			this.setState({
				solversList: data.data,
				solversTotalCount: data.totalCount,
				solversTotalFetched: data.data.length,
			});
		}).catch((err) => {
			console.log("Error fetching solvers", err.message)
			this.setState({ solversList: [], solversTotalCount: 0, solversTotalFetched: 0 })
			this.props.resetLoadingSpinner()
		});
	}

	onClickRemoveTeam = (teamId) => {
		this.props.setLoadingSpinner()
		challengeServices
			.removeSolverOrTeam(this.state.challengeId, "", teamId)
			.then((status) => {
				return this.fetchTeams()
			})
			.then((data) => {
				if (!data || !data.data) {
					throw new Error("No teams found")
				}
				this.setState({
					teams: data.data,
					teamsTotalCount: data.totalCount
				})
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error in deleting the team, err =", err.message)
				this.props.showAlert("error", "Oops!", err.message)
			})
	}

	fetchTeams = () => {
		return challengeServices
			.getAllTeamsByChallenge(
				this.props.challengeId,
				this.state.teamsCurrentPage,
				this.state.teamsItemsPerPage,
				this.state.teamSearchText
			)
			.then((teamData) => {
				if (!teamData || !teamData.data)
					throw new Error("Error occurred while fetching teams list for challenge")
				return teamData
			})
			.catch((err) => {
				console.log("Err = ", err.message)
				return null
			})
	}

	fetchTeamMembers = (teamId) => {
		return teamServices
			.getTeam(teamId)
			.then((data) => {
				if (!data || !data.teamMembers) throw new Error("Error getting team information")
				let users = data.teamMembers.filter((tm) => tm.status != "PENDING")

				return Promise.all(
					users.map((user) => {
						return userServices
							.getUserProfileById(user.userId)
							.then((userData) => {
								if (!userData) throw new Error("User not found")
								userData.isTeamLead = user.teamLead //carrying the teamLead flag with detailed user
								return userData
							})
							.catch((err) => {
								console.log("Error getting user, err =", err.message)
								return null
							})
					})
				).then((result) => {
					result = result.filter((r) => r != null)
					return { ...data, teamMembers: result }
				})
			})
			.catch((err) => {
				console.log("Error fetching team, err= ", err.message)
				return null
			})
	}

	loadInitialData = () => {
		if (this.props.viewAs == "team" && this.props.teamId) {
			this.setState({ viewAs: "team" })
			this.props.setLoadingSpinner()
			this.fetchTeamMembers(this.props.teamId).then((data) => {
				this.props.resetLoadingSpinner()
				if (data) {
					this.setState({
						teamName: data.teamName,
						teamAvatar: data.teamAvatar,
						teamDescription: data.teamDescription,
						teamAffiliation: data.teamAffiliation
					})
					this.setState({ teamMembers: data.teamMembers, isDataFetched: true })
					let loggedInUserData = cookiesHelper.get(constants.Settings.LoggedInCookieName)
					let amITeamLead =
						data.teamMembers.filter((u) => u.isTeamLead && u.userId == loggedInUserData.userId)
							.length > 0
					this.setState({ isTeamLead: amITeamLead })
				}
			})
		} else if (this.props.viewAs == "admin" && this.props.challengeId && !this.props.teamId) {
			this.setState({ viewAs: "admin" })
			this.props.setLoadingSpinner()
			Promise.all([this.fetchSolvers(), this.fetchTeams()]).then((result) => {
				let data = result[0],
					teamData = result[1]
				if (teamData) {
					this.setState({ teams: teamData.data, teamsTotalCount: teamData.totalCount })
				}
				if (data) {
					this.setState({
						solversList: data.data,
						solversTotalCount: data.totalCount,
						solversTotalFetched: data.data.length
					})
				}
				this.setState({ isDataFetched: true })
				this.props.resetLoadingSpinner()
			})
		} else if (this.props.viewAs == "solver") {
			this.setState({ viewAs: "solver" })
		}
		userServices.getCurrentUser().then((user) => {
			this.setState({ currentUserProfile: user })
		})
	}

	fetchSolversAndSave = () => {
		this.props.setLoadingSpinner()
		this.fetchSolvers()
			.then((data) => {
				if (!data) {
					throw new Error("Error fetching the solvers")
				}

				this.setState({
					solversList: data.data,
					solversTotalCount: data.totalCount,
					solversTotalFetched: data.data.length
				})
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("Err =", err.message)
			})
	}

	fetchTeamsAndSave = () => {
		this.props.setLoadingSpinner()
		this.fetchTeams()
			.then((data) => {
				if (!data) {
					throw new Error("Error fetching the teams")
				}
				this.setState({
					teams: data.data,
					teamsTotalCount: data.totalCount
				})
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("Err =", err.message)
			})
	}

	handleSolverSearchTextChange = (text) => {
		if (this.state.currentPage !== 1) {
			this.setState({ currentPage: 1 })
		}
		this.setState({ solverSearchText: text })
	}

	handleTeamSearchTextChange = (text) => {
		if (this.state.teamsCurrentPage !== 1) {
			this.setState({ teamsCurrentPage: 1 })
		}
		this.setState({ teamSearchText: text })
	}

	searchUserPromise = (searchText) => {
		//do relevent filtering here if required
		return userServices.searchUserForJoinTeam(
			searchText,
			this.state.challengeId,
			`userId username email name avatarImagePath`
		)
	}

	setManagePeopleData = (data, onSaveDone) => {
		this.setState({ selectedUsers: data.selectedUsers }, () => {
			this.createTeamAndJoin(onSaveDone)
		})
	}

	isAllDataValid = () => {
		const { teamName, selectedUsers } = this.state
		if (teamName && selectedUsers && selectedUsers.length > 0) return true
		return false
	}

	handleFileChange = (e) => {
		e.preventDefault()
		let reader = new FileReader()
		let file = e.target.files[0]
		if (!file) {
			this.setState({ file: null, fileName: "" })
			return
		}
		reader.onloadend = () => {
			this.setState({
				file: { file },
				fileName: file.name
			})
		}
		reader.readAsDataURL(file)
	}

	uploadFile = (mutation) => {
		this.props.setLoadingSpinner()
		uploadFileHandler(mutation, this.state.file, "image")
			.then((data) => {
				let imagePath = data.path
				this.setState({ teamAvatar: imagePath })
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("Error uploading image =", err.message)
				this.props.resetLoadingSpinner()
			})
	}

	createTeamAndJoin = (onSaveDone) => {
		if (!this.isAllDataValid()) {
			this.props.showAlert("error", "Opps!", "Some required fields are missing")
		}
		let amITeamLead = true
		let teamData = {
			teamName: this.state.teamName,
			teamAffiliation: this.state.teamAffiliation,
			teamAvatar: this.state.teamAvatar,
			teamMembers: this.state.selectedUsers.map((user) => {
				if (user.role === "teamLead") amITeamLead = false
				return {
					userId: user.userId,
					email: user.email,
					name: user.userId ? user.name : "Invitee User",
					teamLead: user.role === "teamLead"
				}
			})
		}
		teamData.teamMembers.push({
			userId: this.state.currentUserProfile.userId,
			name: this.state.currentUserProfile.name,
			email: this.state.currentUserProfile.email,
			teamLead: amITeamLead
		})
		challengeServices
			.convertSoloJoinToTeamJoin(this.state.challengeId, teamData)
			.then((data) => {
				if (!data) throw new Error("Error in create team and join as a team")
				if (onSaveDone && typeof onSaveDone === "function") onSaveDone()
				window.location.reload()
			})
			.catch((err) => {
				console.log("Error in create and join team", err.message)
				this.props.showAlert("error", "Oops!", err.message)
			})
	}

	handleLeaveChallenge = () => {
		this.setState({ isConfirmDeleteModalOpen: true })
	}

	handleConfirmDelete = () => {
		this.props.setLoadingSpinner()
		challengeServices
			.leaveChallenge(this.state.challengeId)
			.then((status) => {
				if (!status) throw new Error("Error occurred while leaving the challenge")
				this.props.resetLoadingSpinner()
				this.setState({ isConfirmDeleteModalOpen: false })
				window.location.reload()
			})
			.catch((err) => {
				console.log("Error leaving the challenge, err=", err.message)
				this.props.resetLoadingSpinner()
				this.setState({ isConfirmDeleteModalOpen: false })
				this.props.showAlert("error", "Opps!", err.message)
			})
	}

	componentDidMount() {
		this.setState({ challengeId: this.props.challengeId, teamId: this.props.teamId })
		this.loadInitialData()
	}

	render() {
		const { classes } = this.props
		let canLeaveChallenge = this.state.viewAs == "solver" || this.state.viewAs == "team"
		var actionDisabled =
			this.props.challenge.challengeDetails.challengeStatus != Constants.CHALLENGE_STATUS.ACTIVE
		return (
			<div>
				{this.renderConfirmRemoveModal()}
				<GridContainer>
					<GridItem xs={12} xl={12}>
						{this.renderSolversUI()}
					</GridItem>
					{canLeaveChallenge && (
						<GridItem xs={12} xl={12}>
							<Button disabled={actionDisabled} color="danger" onClick={this.handleLeaveChallenge}>
								Leave Challenge
							</Button>
						</GridItem>
					)}
				</GridContainer>
			</div>
		)
	}

	renderSolversUI() {
		const { classes } = this.props

		if (
			this.state.isDataFetched &&
			!this.state.teamMembers &&
			!this.state.teams &&
			!this.state.solversList
		) {
			return (
				<div className={classes.container}>
					<Card color="gmgTheme" raised>
						<CardBody>
							<GridContainer direction="row" alignItems="center" justify="space-between">
								<GridItem xs={12} lg={12}>
									<h5 style={{ color: "#FFF" }}> No solvers/teams found </h5>
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
				</div>
			)
		}
		if (this.state.viewAs == "admin") {
			return (
				<div className={classes.container}>
					<DebouncedActionPerformer
						action={(currentValue) => {
							this.fetchSolversAndSave()
						}}
						delay={700}
						sourceValue={this.state.solverSearchText}
					/>

					<DebouncedActionPerformer
						action={(currentValue) => {
							this.fetchTeamsAndSave()
						}}
						delay={700}
						sourceValue={this.state.teamSearchText}
					/>

					{this.state.teams && (
						<TeamsListUI
							searchText={this.state.teamSearchText}
							onChangeSearchText={this.handleTeamSearchTextChange}
							teamsList={this.state.teams}
							currentPage={this.state.teamsCurrentPage}
							itemsPerPage={this.state.teamsItemsPerPage}
							totalCount={this.state.teamsTotalCount}
							onClickRemoveTeam={this.onClickRemoveTeam}
							onClickPaginationButton={this.onClickPaginationButtonTeams}
						/>
					)}
					{this.state.solversList && (
						<SolversListUI
							searchText={this.state.solverSearchText}
							onChangeSearchText={this.handleSolverSearchTextChange}
							solversList={this.state.solversList}
							challengeId={this.props.challengeId}
							currentPage={this.state.currentPage}
							itemsPerPage={this.state.itemsPerPage}
							totalCount={this.state.solversTotalCount}
							onClickRemoveParticipant={this.onClickRemoveParticipant}
							onClickPaginationButton={this.onClickPaginationButtonSolvers}
							onClickApproveParticipant={this.onClickApproveParticipant}
						/>
					)}
				</div>
			)
		} else if (this.state.viewAs == "team") {
			var actionDisabled = false
			if (
				this.props.challenge.challengeDetails.challengeStatus != Constants.CHALLENGE_STATUS.ACTIVE
			)
				actionDisabled = true
			return (
				<div className={classes.container}>
					{this.state.teamMembers && !this.state.editTeamMode && (
						<TeamUI
							teamName={this.state.teamName}
							teamMembers={this.state.teamMembers}
							showEditButton={this.state.isTeamLead}
							disableTeamEditMode={actionDisabled}
							onClickEditTeam={() => {
								this.setState({ editTeamMode: true })
							}}
						/>
					)}
					{this.state.teamMembers && this.state.editTeamMode && (
						<EditTeamMembers
							teamId={this.props.teamId}
							challengeId={this.props.challengeId}
							onCancelEdit={() => {
								this.setState({ editTeamMode: false })
								this.loadInitialData()
							}}
							reloadChallengeData={this.props.reloadChallengeData}
						/>
					)}
				</div>
			)
		} else if (this.state.viewAs == "solver") {
			actionDisabled = false
			if (
				this.props.challenge.challengeDetails.challengeStatus != Constants.CHALLENGE_STATUS.ACTIVE
			)
				actionDisabled = true
			if (!this.state.TeamCreateMode) {
				return (
					<div style={{ marginLeft: "10px", marginRight: "10px" }}>
						<Card color="gmgTheme">
							<CardBody color="gmgTheme">
								<GridContainer>
									<GridItem lg={12}>
										<h4>
											You have joined the challenge as a solo solver, But you can create your team
											by inviting your friends now.
										</h4>
									</GridItem>
									<GridItem lg={12}>
										<Button
											color="success"
											disabled={actionDisabled}
											onClick={() => {
												this.setState({ TeamCreateMode: true })
											}}
										>
											Create My Team
										</Button>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					</div>
				)
			} else if (this.state.TeamCreateMode) {
				return this.showTeamCreateUI()
			}
		} else return null
	}

	showTeamCreateUI = () => {
		const { classes } = this.props
		return (
			<div>
				<GridContainer container direction="row" justify="center" alignItems="center">
					<GridItem xs={12} sm={12} md={10} lg={8}>
						<Card raised>
							<CardHeader>
								<h3>You have joined this challenge alone but you can make your own team now</h3>
							</CardHeader>
							<CardBody>
								<Paper>
									<div style={{ margin: "20px" }}>
										<CustomInput
											labelText="Team Name"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												name: "teamName",
												value: this.state.teamName,
												onChange: (e) => this.setState({ teamName: e.target.value })
											}}
										/>
										<CustomInput
											labelText="Team Affiliation"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												name: "teamAffiliation",
												value: this.state.teamAffiliation,
												onChange: (e) => this.setState({ teamAffiliation: e.target.value })
											}}
										/>
										<CustomInput
											inputProps={{
												type: "file",
												name: "teamAvatar",
												onChange: this.handleFileChange
											}}
										/>
										<Mutation mutation={uploadFileMutation} fetchPolicy="no-cache">
											{(mutation, { loading }) => (
												<Button color="rose" round onClick={() => this.uploadFile(mutation)}>
													Upload
												</Button>
											)}
										</Mutation>
									</div>
								</Paper>
								<ManageMembersComponent
									title={null}
									availableRoles={this.state.availableRoles}
									searchUserPromise={this.searchUserPromise}
									setManagePeopleData={this.setManagePeopleData}
								/>
							</CardBody>
							<CardFooter>
								<Button
									square
									color="youtube"
									onClick={() => {
										this.setState({
											teamName: "",
											teamDescription: "",
											teamAffiliation: "",
											teamAvatar: "",
											teamMembers: null,
											TeamCreateMode: false
										})
									}}
								>
									Cancel
								</Button>
								{/* <Button
									square
									color="success"
									onClick={this.createTeamAndJoin}
									disabled={!this.isAllDataValid()}
								>
									Save team
								</Button> */}
							</CardFooter>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		)
	}

	renderConfirmRemoveModal = () => {
		if (!this.state.isConfirmDeleteModalOpen) return null
		let text = "Are sure you want to leave the challenge?"
		if (this.state.viewAs == "team" && this.state.isTeamLead) {
			text =
				"Are you sure you want to leave the challenge? All your team members will also be removed!"
		} else if (this.state.viewAs == "team") {
			text =
				"Are you sure you want to leave the challenge? Your teammates will be still in the team without you!"
		}
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isConfirmDeleteModalOpen: false })}
			>
				<DialogTitle> Are you sure? </DialogTitle>
				<DialogContent>
					<DialogContentText>{text}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => this.setState({ isConfirmDeleteModalOpen: false })}
						color="tumbler"
					>
						No
					</Button>
					<Button onClick={this.handleConfirmDelete} color="youtube">
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Solvers))
