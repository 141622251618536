import { get } from "lodash";

import { challengePermissionForUser } from "constants/challenges/challengeRole";
import Constants from "../constants";
import { userRole } from "constants/users/userRole";

export const getChallengePermissionForSpecificUser = challenge => {
  const permissions = new Set();
  const challengePermission = get(challenge, "permissions", []);
  const roles = get(challenge, "roles", []);

  const adminArray = [userRole.CHALLENGE_ADMIN, userRole.LICENCE_ADMIN, userRole.SUPER_ADMIN];
  const manageChallengeArray = [...adminArray, userRole.JUDGE];

  if (roles.find(r => adminArray.includes(r.role))) {
    if (challengePermission.includes(Constants.Functionalities.CanCreateOrUpdateEvaluationTeam)) {
      permissions.add(challengePermissionForUser.CREATE_UPDATE_EVALUATION_TEAM);
    }

    if (
      challengePermission.includes(Constants.Functionalities.CanCreateOrUpdateManageChallengeInvitation)
    ) {
      permissions.add(challengePermissionForUser.CREATE_UPDATE_MANAGE_CHALLENGE_INVITATION);
    }

    if (challengePermission.includes(Constants.Functionalities.CanCreateOrUpdateEvaluationCriteria)) {
      permissions.add(challengePermissionForUser.CREATE_UPDATE_EVALUATION_CRITERIA);
    }

    if (challengePermission.includes(Constants.Functionalities.CanGetReportingPage)) {
      permissions.add(challengePermissionForUser.GET_REPORTING_PAGE);
    }
    
    permissions.add(challengePermissionForUser.MANAGE_FORM);
    permissions.add(challengePermissionForUser.SEND_EMAIL_BY_ROLES);
    permissions.add(challengePermissionForUser.ACCESS_SUBMISSION_DASHBOARD);
  }

  if (
    challengePermission.includes(Constants.Functionalities.CanCreateOrUpdateEvaluationScore) ||
    roles.find((r) => r.role == "evaluator")
  ) {
      permissions.add(challengePermissionForUser.CREATE_UPDATE_EVALUATION_SCORE);
  }

  if (
    (
      challenge.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.ACTIVE ||
      challenge.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.CLOSED
    ) &&
    roles.find(r => r.role == "judge")
  ) { 
    permissions.add(challengePermissionForUser.ACCESS_SUBMISSION_DASHBOARD);
  }

  if (
    challengePermission.includes(Constants.Functionalities.CanManageChallenge) &&
    roles.find(r => manageChallengeArray.includes(r.role))
  ) {
      permissions.add(challengePermissionForUser.MANAGE_CHALLENGE);
  }

  return permissions;
};

export const getChallengeMenuItems = challenge => {
  const permissions = getChallengePermissionForSpecificUser(challenge);

  const challengeMenuItems = [
    {
      title: "Manage Challenge",
      route: "/dashboard/challenge-details/",
      ableToAccess: permissions.has(challengePermissionForUser.MANAGE_CHALLENGE),
      extensionRoute: 'overview',
    },
    {
      title: "Manage People",
      route: "/dashboard/manage-people/",
      ableToAccess: permissions.has(challengePermissionForUser.CREATE_UPDATE_MANAGE_CHALLENGE_INVITATION),
    },
    {
      title: "Set Scoring Criteria",
      route: "/dashboard/scoring-criteria/",
      ableToAccess: (
        permissions.has(challengePermissionForUser.CREATE_UPDATE_EVALUATION_TEAM) ||
        permissions.has(challengePermissionForUser.CREATE_UPDATE_EVALUATION_CRITERIA)
      ),
    },
    {
      title: "Manage Forms",
      route: "/dashboard/manage-forms/",
      ableToAccess: permissions.has(challengePermissionForUser.MANAGE_FORM),
    },
    {
      title: "Email Messaging",
      route: "/dashboard/email-messaging/",
      ableToAccess: permissions.has(challengePermissionForUser.SEND_EMAIL_BY_ROLES),
    },
    {
      title: "Assign Evaluators",
      route: "/dashboard/challenge-evaluators/",
      ableToAccess: (
        permissions.has(challengePermissionForUser.CREATE_UPDATE_EVALUATION_TEAM) ||
        permissions.has(challengePermissionForUser.CREATE_UPDATE_EVALUATION_CRITERIA)
      )
    },
    {
      title: "Submissions Dashboard",
      route: "/dashboard/submissions/",
      ableToAccess: permissions.has(challengePermissionForUser.ACCESS_SUBMISSION_DASHBOARD),
    },
    {
      title: "Reports",
      route: "/dashboard/reports/",
      ableToAccess: permissions.has(challengePermissionForUser.GET_REPORTING_PAGE),
    },
    {
      title: "Submission Scoring",
      route: "/dashboard/submission-scoring/",  //needed to be replaced
      ableToAccess: permissions.has(challengePermissionForUser.CREATE_UPDATE_EVALUATION_SCORE),
    },
  ];

  return challengeMenuItems;
}