import React from "react"
// react component for creating dynamic tables
import ReactTable from "react-table"
import PropTypes from "prop-types"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import createStyles from "@material-ui/core/styles/createStyles"
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment"
import Dvr from "@material-ui/icons/Dvr"
import Favorite from "@material-ui/icons/Favorite"
import Close from "@material-ui/icons/Close"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import Accordion from "components/Accordion/Accordion"
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx"
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"
import Pagination from "material-ui-flat-pagination"

import Constants from "constants.js"
import ReactHtmlParser from 'html-react-parser'

import defaultChallengeImage from "assets/img/defaultChallengeImage.webp"

import helper from "../../helper"
import combineStyles from "utils/combineStyle"
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx"
import commonAppStyle from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import authentication from "authentication.js"
import challengeCompletedLogo from "assets/img/faces/challengeCompleted.webp"

import phaseUtils from "utils/phaseUtils"
import challengeUtils from "utils/challengeUtils"
import { withTheme } from "@material-ui/styles"
import { fade } from "@material-ui/core/styles/colorManipulator"
import FontOverridePatch from "utils/FontOverridePatch.jsx"

const styles = (theme) => ({
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px",
		fontFamily: "Helvetica Neue",
		color: theme.palette.text.heading //"#1b5e20",
	}
})

class ChallengesListPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			challengeId: "",
			activeChallenges: [],
			pastChallenges: [],
			closedChallenges: [],
			userProfile: null,
			dataFetched: false,
			canJoinChallenge: false,
			canGetFinalLeaderboardForChallenge: false,
			pagination: {
				currentPage: 1,
				itemsPerPage: 4
			},
			totalPastChallengeCount: 0,
			paginationClosedChallenges: {
				currentPage: 1,
				itemsPerPage: 4
			},
			totalClosedChallengeCount: 0,
			showClosedSection: false
		}
	}

	handleOnClickJoin = (urlSlug) => {
		if (!this.state.userProfile) {
			this.props.setAfterLoginRedirectUrl("/dashboard/join-challenge/" + urlSlug)
			this.props.openLoginModal()
			return
		}
		this.props.history.push("/dashboard/join-challenge/" + urlSlug)
		return
	}

	handleOnClickLearnMore = (urlSlug) => {
		if (!this.state.userProfile) {
			this.props.history.push("/home/challenge-details/" + urlSlug)
		} else {
			this.props.history.push("/dashboard/challenge-details/" + urlSlug)
		}
	}

	handleOnClickPaginateButton = (offset) => {
		this.setState(
			{
				pagination: {
					...this.state.pagination,
					currentPage: helper.getCurrentPageFromOffset(offset, this.state.pagination.itemsPerPage)
				}
			},
			() => {
				this.props.setLoadingSpinner()
				this.fetchCompletedChallenges()
					.then((data) => {
						this.props.resetLoadingSpinner()
						if (data) {
							if (data.challenges) {
								this.setState({ pastChallenges: data.challenges })
							} else this.setState({ pastChallenges: [] })
							this.setState({ totalPastChallengeCount: data.availableChallengeCount })
						} else throw new Error("Error occurred while fetching past challenges")
					})
					.catch((err) => {
						this.props.resetLoadingSpinner()
						console.log("Error in fetching past challenges, err =", err.message)
						this.setState({
							pastChallenges: [],
							totalPastChallengeCount: 0
						})
					})
			}
		)
	}

	handleOnClickPaginateButtonForClosedSection = (offset) => {
		this.setState(
			{
				paginationClosedChallenges: {
					...this.state.paginationClosedChallenges,
					currentPage: helper.getCurrentPageFromOffset(
						offset,
						this.state.paginationClosedChallenges.itemsPerPage
					)
				}
			},
			() => {
				this.props.setLoadingSpinner()
				this.fetchClosedChallenges()
					.then((data) => {
						this.props.resetLoadingSpinner()
						if (data) {
							if (data.challenges) {
								this.setState({ closedChallenges: data.challenges })
							} else this.setState({ closedChallenges: [] })
							this.setState({ totalClosedChallengeCount: data.availableChallengeCount })
						} else throw new Error("Error occurred while fetching closed challenges")
					})
					.catch((err) => {
						this.props.resetLoadingSpinner()
						console.log("Error in fetching past challenges, err =", err.message)
						this.setState({
							closedChallenges: [],
							totalClosedChallengeCount: 0
						})
					})
			}
		)
	}

	fetchChallengesActive = () => {
		return challengeServices
			.getAllChallenges({ [Constants.CHALLENGE_STATUS.ACTIVE]: true })
			.then((data) => {
				return data ? data : []
			})
			.catch((err) => {
				console.log("Error getting challenges", err.message)
				return []
			})
	}

	fetchCompletedChallenges = () => {
		return challengeServices
			.getCompletedChallengesPaginated(this.state.pagination)
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log("Error , = ", err.message)
				return null
			})
	}

	fetchClosedChallenges = () => {
		return challengeServices
			.getClosedChallengesPaginated(this.state.paginationClosedChallenges)
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log("Error , = ", err.message)
				return null
			})
	}

	fetchCurrentUserProfile = () => {
		return userServices
			.getCurrentUser()
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log("Error getting current User", err.message)
				return null
			})
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		Promise.all([
			this.fetchCurrentUserProfile(),
			this.fetchChallengesActive(),
			this.fetchCompletedChallenges(),
			this.fetchClosedChallenges()
		])
			.then((result) => {
				this.props.resetLoadingSpinner()
				let activeChallenges = result[1] ? (result[1] ? result[1] : []) : []
				let pastChallenges = result[2] ? (result[2].challenges ? result[2].challenges : []) : []
				let closedChallenges = result[3] ? (result[3].challenges ? result[3].challenges : []) : []
				let userProfile = result[0] ? result[0] : null

				let showClosedSection = false
				if (closedChallenges.length && userProfile) {
					showClosedSection = true
				} else if (userProfile) {
					let roles = userProfile.roles || []
					let isAdmin = roles.find(
						(role) => role.RoleId === "superAdmin" || role.RoleId === "licenseeAdmin"
					)
					if (isAdmin) {
						showClosedSection = true
					}
				}

				this.setState({
					activeChallenges: activeChallenges,
					pastChallenges: pastChallenges,
					closedChallenges: closedChallenges,
					totalPastChallengeCount: result[2] ? result[2].availableChallengeCount : 0,
					totalClosedChallengeCount: result[3] ? result[3].availableChallengeCount : 0,
					userProfile: userProfile,
					dataFetched: true,
					showClosedSection: showClosedSection
				})
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error:", err.message)
			})
	}

	componentDidMount() {
		this.props.resetBreadCrumbStack()
		this.props.pushBreadCrumbStack({ name: "Home", link: "/home/index" })
		this.props.pushBreadCrumbStack({
			name: "Challenges",
			link: "/home/challenges"
		})
		this.fetchAllData()

		let requiredPermissionsObjects = [
			{
				permissionName: Constants.Functionalities.CanJoinChallenge,
				objectId: null
			},
			{
				permissionName: Constants.Functionalities.CanGetFinalLeaderboardForChallenge,
				objectId: null
			}
		]
		authentication
			.hasMultiplePermissions(requiredPermissionsObjects)
			.then((permissionResponseObject) => {
				if (permissionResponseObject) {
					permissionResponseObject.map((object) => {
						if (
							object.permissionName == Constants.Functionalities.CanJoinChallenge &&
							object.permissionStatus
						) {
							//this.setState({ canJoinChallenge: true })
						} else if (
							object.permissionName ==
								Constants.Functionalities.CanGetFinalLeaderboardForChallenge &&
							object.permissionStatus
						)
							this.setState({ canGetFinalLeaderboardForChallenge: true })
					})
				}
			})
			.catch((err) => {
				console.log("Error = ", err.message)
			})
	}

	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	render() {
		const { classes } = this.props
		helper.setPageTitle("Challenges | BestInCrowd")

		return (
			<div style={{ backgroundColor: "#FFFFFF" }}>
				<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
				<br />
				<GridContainer>
					{this.state.dataFetched && (
						<GridItem xs={12}>
							<div className={classes.challengeContainer}>
								<CardHeader>
									<GridContainer direction="row" alignItems="center" justify="center">
										<GridItem>
											<h2 className={classes.cardIconTitle}> Active Challenges</h2>
										</GridItem>
									</GridContainer>
								</CardHeader>
								<CardBody>{this.renderActiveChallengesView()}</CardBody>
							</div>
						</GridItem>
					)}

					<br />
					<br />

					{this.state.dataFetched && this.state.showClosedSection ? (
						<GridItem xs={12}>
							<div className={classes.challengeContainer}>
								<CardHeader>
									<GridContainer direction="row" alignItems="center" justify="center">
										<GridItem>
											<h2 className={classes.cardIconTitle}> Closed Challenges</h2>
										</GridItem>
									</GridContainer>
								</CardHeader>
								<CardBody>{this.renderClosedChallengesView()}</CardBody>
							</div>
						</GridItem>
					) : null}

					<br />
					<br />
					{this.state.dataFetched && (
						<GridItem xs={12}>
							<div className={classes.challengeContainer}>
								<CardHeader>
									<GridContainer direction="row" alignItems="center" justify="center">
										<GridItem>
											<h2 className={classes.cardIconTitle}> Completed Challenges</h2>
										</GridItem>
									</GridContainer>
								</CardHeader>
								<CardBody>{this.renderPastChallengesView()}</CardBody>
							</div>
						</GridItem>
					)}
				</GridContainer>
			</div>
		)
	}

	renderActiveChallengesView = () => {
		const { classes } = this.props
		let cardColor = "gmgTheme"
		if (this.state.activeChallenges) {
			let challenges = this.state.activeChallenges
			if (!challenges || !challenges.length) {
				return (
					<div style={{ margin: "30px auto" }}>
						<GridContainer justify="center" alignItems="flex-start">
							<GridItem>
								<h5 className={classes.noChallengeFound}> No Active Challenges Found </h5>
							</GridItem>
						</GridContainer>
					</div>
				)
			}
			let view = challenges.map((challenge) => {
				return this.renderChallenge(challenge)
			})
			return (
				<GridContainer container direction="row" justify="flex-start" alignItems="flex-start">
					{view}
				</GridContainer>
			)
		}
		return null
	}

	renderPastChallengesView = () => {
		const { classes } = this.props

		if (this.state.pastChallenges) {
			let challenges = this.state.pastChallenges
			if (!challenges || !challenges.length) {
				return (
					<div style={{ margin: "30px auto" }}>
						<GridContainer justify="center" alignItems="flex-start">
							<GridItem>
								<h5 className={classes.noChallengeFound}> No Past Challenges Found </h5>
							</GridItem>
						</GridContainer>
					</div>
				)
			}
			let view = challenges.map((challenge) => {
				return this.renderChallenge(challenge)
			})

			let limit = this.state.pagination.itemsPerPage
			let offset = helper.getOffsetFromPagination(this.state.pagination)
			let total = this.state.totalPastChallengeCount
			const paginationClasses = {
				colorInheritCurrent: classes.colorInheritCurrent,
				colorInheritOther: classes.colorInheritOther
			}

			return (
				<GridContainer container direction="row" justify="flex-start" alignItems="flex-start">
					{view}
					<GridItem xs={12} lg={12}>
						<GridContainer direction="row" alignItems="center" justify="center">
							<GridItem sm={12} md={8} lg={6}>
								<div className={classes.paginationDiv}>
									<Pagination
										classes={paginationClasses}
										currentPageColor="inherit"
										otherPageColor="inherit"
										size={"large"}
										limit={limit}
										offset={offset}
										total={total}
										onClick={(e, offset) => this.handleOnClickPaginateButton(offset)}
									/>
								</div>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			)
		}
		return null
	}

	renderClosedChallengesView = () => {
		const { classes } = this.props

		if (this.state.closedChallenges) {
			let challenges = this.state.closedChallenges

			if (!challenges || !challenges.length) {
				return (
					<div style={{ margin: "30px auto" }}>
						<GridContainer justify="center" alignItems="flex-start">
							<GridItem>
								<h5 className={classes.noChallengeFound}> No Closed Challenges Found </h5>
							</GridItem>
						</GridContainer>
					</div>
				)
			}

			let view = challenges.map((challenge) => {
				return this.renderChallenge(challenge)
			})

			let limit = this.state.paginationClosedChallenges.itemsPerPage
			let offset = helper.getOffsetFromPagination(this.state.paginationClosedChallenges)
			let total = this.state.totalClosedChallengeCount

			const paginationClasses = {
				colorInheritCurrent: classes.colorInheritCurrent,
				colorInheritOther: classes.colorInheritOther
			}

			return (
				<GridContainer container direction="row" justify="flex-start" alignItems="flex-start">
					{view}
					<GridItem xs={12} lg={12}>
						<GridContainer direction="row" alignItems="center" justify="center">
							<GridItem sm={12} md={8} lg={6}>
								<div className={classes.paginationDiv}>
									<Pagination
										classes={paginationClasses}
										currentPageColor="inherit"
										otherPageColor="inherit"
										size={"large"}
										limit={limit}
										offset={offset}
										total={total}
										onClick={(e, offset) =>
											this.handleOnClickPaginateButtonForClosedSection(offset)
										}
									/>
								</div>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			)
		}
		return null
	}

	renderChallenge = (challenge) => {
		const { classes } = this.props
		let cardColor = "gmgTheme"
		if (!challenge) return null
		let isJoinedAlready =
			challenge.permissions.includes("CanCreateOrUpdateSolution") ||
			challenge.roles.find((r) => r.role == "solverForTeam" || r.role == "solver") != null
		let canJoinChallenge = challenge.permissions.includes("CanJoinChallenge") //this.state.canJoinChallenge

		let currentPhase = "" //phaseUtils.getCurrentPhase(challenge.challengeDetails.phases)
		let startDate = "",
			endDate = ""
		if (challenge.challengeDetails.currentPhase) {
			currentPhase = challenge.challengeDetails.currentPhase.phaseTitle
			startDate = challenge.challengeDetails.currentPhase.startDate
			endDate = challenge.challengeDetails.currentPhase.endDate
		}
		let winnerUserName = null
		if (challenge.challengeDetails.leaderboard) {
			challenge.challengeDetails.leaderboard.map((row) => {
				if (row.standing == 1) winnerUserName = row.userName
			})
		}
		let statusBGColor = "grey"
		if (challenge.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.ACTIVE)
			statusBGColor = "blue"
		if (challenge.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.COMPLETED)
			statusBGColor = "green"
		if (challenge.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.CLOSED)
			statusBGColor = "red"
		let showChallengeStatus = false
		let managerRoles = challenge.roles.find(
			(r) => r.role == "challengeAdmin" || r.role == "evaluator" || r.role == "judge"
		)
		if (managerRoles && managerRoles.length != 0) showChallengeStatus = true

		return (
			<GridItem key={challenge.challengeId} xs={12} sm={6} md={4} lg={3}>
				<Card raised={true} color={cardColor}>
					<CardHeader
						color={cardColor}
						className={classes.textOverflowEllipsisStyleForChallengeTitle}
					>
						{challenge.challengeDetails.leaderboard &&
						challenge.challengeDetails.leaderboard.length ? (
							<CardIcon color="success">
								<img src={challengeCompletedLogo} alt="Image of challenge completed logo" />
							</CardIcon>
						) : null}

						{challenge.challengeDetails.title}
					</CardHeader>

					<CardBody>
						<img
							width={"100%"}
							className={classes.challengeCoverImage}
							src={Constants.Settings.APIURL + challenge.challengeDetails.challengeImage}
							alt="Image of challenge cover"
							onError={(event) => {
								event.target.src = defaultChallengeImage
							}}
						/>

						<p className={classes.currentPhaseStyle}>{currentPhase}</p>

						{showChallengeStatus ? (
							<div style={{ height: "40px", marginTop: "15px" }}>
								<GridContainer direction="row" alignItems="center" justify="center">
									<GridItem>
										<span
											style={{
												padding: "5px 10px",
												borderRadius: "50px",
												backgroundColor: statusBGColor,
												color: "#FFF"
											}}
										>
											{challenge.challengeDetails.challengeStatus}
										</span>
									</GridItem>
								</GridContainer>
							</div>
						) : (
							<div style={{ height: "40px", marginTop: "15px" }}></div>
						)}
						<Accordion
							collapses={[
								{
									title: (
										<div
											style={{
												height: "23px",
												overflow: "hidden",
												textOverflow: "ellipsis"
											}}
										>
											{challenge.challengeDetails.shortDescription.substr(0, 30)}
										</div>
									),
									content: (
										<div style={{ paddingLeft: "5px", width: "100%" }}>
											<p className={classes.cardProductDescription}>
												<FontOverridePatch>
													{ReactHtmlParser(challenge.challengeDetails.description || "")}
												</FontOverridePatch>
											</p>
											<p className={classes.cardProductDescription}>
												Prize Amount:{" "}
												{challengeUtils.getPrizeFormatted(challenge.challengeDetails.prizeAmount)}
											</p>
											{startDate && (
												<p className={classes.cardProductDescription}>Start Date: {startDate}</p>
											)}
											{endDate && (
												<p className={classes.cardProductDescription}>End Date: {endDate}</p>
											)}
											{challenge.challengeDetails.sponsorsName && (
												<p className={classes.cardProductDescription}>
													By{" "}
													{challenge.challengeDetails.sponsorsName
														? challenge.challengeDetails.sponsorsName
														: "Not available"}
												</p>
											)}
										</div>
									)
								}
							]}
						/>
					</CardBody>

					<CardFooter className={classes.justifyContentCenter}>
						<div className={classes.center} style={{ height: "70px", alignContent: "center" }}>
							<Button
								square
								color="info"
								size="sm"
								onClick={() => this.handleOnClickLearnMore(challenge.urlSlug)}
							>
								Learn more
							</Button>
							{challenge.challengeDetails.leaderboard &&
							challenge.challengeDetails.leaderboard.length &&
							this.state.userProfile &&
							this.state.canGetFinalLeaderboardForChallenge ? (
								<Button
									square
									color="primary"
									size="sm"
									onClick={() =>
										this.props.history.push("/dashboard/leaderboard/" + challenge.urlSlug)
									}
								>
									Leaderboard
								</Button>
							) : null}
							{challenge.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.ACTIVE &&
							(canJoinChallenge || !this.props.loggedInUserData) ? (
								<Button
									square
									disabled={isJoinedAlready}
									color={isJoinedAlready ? "gmgGreen900" : "gmgGreen900"}
									size="sm"
									onClick={() => this.handleOnClickJoin(challenge.urlSlug)}
								>
									{isJoinedAlready ? "Already Joined" : "Join"}
								</Button>
							) : null}
						</div>
					</CardFooter>
				</Card>
			</GridItem>
		)
	}
}

let extraStyle = (theme) => ({
	challengeContainer: {
		backgroundColor: theme.palette.background.page
	},
	cardProductDescription: {
		padding: "5px 5px 5px 5px"
	},
	currentPhaseStyle: {
		borderTop: "3px solid #FF6859",
		borderBottom: "3px solid #FF6859",
		margin: "20px 20px 0px 20px",
		padding: "5px 10px 5px 10px",
		fontSize: "1.3em",
		display: "block",
		color: theme.palette.text.cardBody, //"#FFFFFF",
		textAlign: "center",
		overflowWrap: "break-word",
		height: "55px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		fontWeight: "400"
	},
	textOverflowEllipsisStyleForChallengeTitle: {
		overflowWrap: "break-word",
		height: "125px",
		fontSize: "1.4em",
		textAlign: "center",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	textOverflowEllipsisStyleForPhase: {
		overflowWrap: "break-word",
		height: "100px",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	joinOrStartChallengeBody: {
		//backgroundColor: "#302F2F"
	},
	noChallengeFound: {
		color: theme.palette.text.page
	},
	cardContentText: {
		color: theme.palette.text.cardBody, //"#FFFFFF",
		textAlign: "center",
		fontWeight: "400"
	},
	challengeFilterUIDiv: {
		border: `2px solid ${theme.palette.text.heading}`,
		padding: "5px",
		color: theme.palette.text.page
	},
	challengeCoverImage: {
		height: "250px",
		width: "100%",
		objectFit: "contain"
	},
	paginationDiv: {
		width: "100%",
		//border: `1px solid ${theme.palette.background.cardBody}`,
		color: theme.palette.text.cardBody
	}
})

const paginationStyles = (theme) =>
	createStyles({
		paperRoot: {
			margin: theme.spacing(2),
			padding: theme.spacing(2)
		},
		colorInheritCurrent: {
			margin: theme.spacing(0.5),
			color: theme.palette.text.page,
			backgroundColor: fade("#00FF00", 0.5),
			"&:hover": {
				backgroundColor: fade("#00FF00", theme.palette.action.hoverOpacity)
			}
		},
		colorInheritOther: {
			margin: theme.spacing(0.5),
			color: theme.palette.text.page,
			"&:hover": {
				backgroundColor: fade("#FF0000", theme.palette.action.hoverOpacity)
			}
		}
	})

let finalStyles = combineStyles(
	loginPageStyle,
	commonAppStyle,
	styles,
	extraStyle,
	paginationStyles
)

ChallengesListPage.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	openLoginModal: PropTypes.func.isRequired,
	closeLoginModal: PropTypes.func.isRequired,
	setAfterLoginRedirectUrl: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack,
		themeMode: state.theme
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE }),
		setAfterLoginRedirectUrl: (url) =>
			dispatch({
				type: actionTypes.AFTER_LOGIN_REDIRECT_URL_SET,
				payload: url
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyles)(withTheme(ChallengesListPage)))
