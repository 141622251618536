import React from "react"
import { connect } from "react-redux"
import actionTypes from "../../reduxjs/actionTypes"

class ThemeCheck extends React.Component {
	constructor(props) {
		super()
	}

	render() {
		return <></>
	}
}

const mapStateToProps = (state) => {
	return {
		themeMode: state.theme
	}
}

const mapDispatchToStore = (dispatch) => {
	return {
		setTheme: (themeName) => dispatch({ type: actionTypes.SWITCH_THEME, payload: themeName })
	}
}

ThemeCheck = connect(mapStateToProps, mapDispatchToStore)(ThemeCheck)

export default ThemeCheck
