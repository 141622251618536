import React from "react"
import SearchIcon from "@material-ui/icons/Search"
import InputBase from "@material-ui/core/InputBase"
import { makeStyles } from "@material-ui/styles"

const useStyle = makeStyles((theme) => ({
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: "#ededed",
		"&:hover": {
			backgroundColor: "#ffffff"
		},
		marginLeft: 0,
		marginRight: "20px",
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			width: "auto"
		}
	},
	searchIcon: {
		width: theme.spacing(7),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	inputRoot: {
		color: "inherit"
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: 120,
			"&:focus": {
				width: 200
			}
		}
	}
}))

export default function SearchBox({ label, value, onChange, placeholder }) {
	const classes = useStyle()

	return (
		<div className={classes.search}>
			<div className={classes.searchIcon}>
				<SearchIcon />
			</div>
			<InputBase
				id={`search-input-${label}`}
				value={value}
				placeholder={placeholder}
				onChange={(e) => {
					onChange && onChange(e.target.value)
				}}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput
				}}
				inputProps={{ "aria-label": label }}
			/>
		</div>
	)
}
