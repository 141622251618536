import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  ...customSelectStyle
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title:"",
      shorDescription:""
    };
    this.updateState = this.updateState.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  
  updateState() {
    console.log("inside step details", this.state);
    this.props.handleDetails(this.state);
  };

  handleInputChange = event =>{
    this.setState({
      [event.target.name]: event.target.value
    }, this.updateState);
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center" >
        <GridItem xs={12} sm={12}>
          <h4 className={classes.infoText}>Add details for your challenge</h4>
        </GridItem>
        <GridItem xs>
          <CustomInput
              labelText="Title"
              inputProps={{
                onChange: this.handleInputChange,
                name:"title" 
              }}           
          />
        </GridItem>
        <GridItem xs>
          <CustomInput
              labelText="Short Description"
              multiline={true}
              rows={2}
              rowsMax={4}
              inputProps={{
                onChange: this.handleInputChange,
                name:"shorDescription"
              }}           
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(Step3);
