import apollofetcher from "apolloFetcher";

let services = {};

services.getEvaluationCriterias = () => {
  let query = `
  query{
    evaluationCriterias{
      status
      message
      totalCount
      data{
        evaluationCriteriaId
        evaluationCriteriaDetails{
          minScore
          maxScore
          criteriaArray{
            evaluationCriterionName
            weight
          }
        }
      }
    }
  }`;

  return new Promise((resolve, reject) => {
    apollofetcher.fetchWithToken({ query }).then(res => {
      if (!res || !res.data || !res.data.evaluationCriterias) throw new Error("Error occured while fetching challenges");
      if (!res.data.evaluationCriterias.status) throw new Error(res.data.evaluationCriterias.message);
      resolve(res.data.evaluationCriterias.data);
    }).catch(err => {
      reject(err);
    });
  });
}

services.getEvaluationCriteriaById = (evalCriteriaId) => {
  let query = `
    query evaluationCriteria($evaluationCriteriaId: String){
        evaluationCriteria(evaluationCriteriaId: $evaluationCriteriaId){
          status
          message
          totalCount
          data{
            evaluationCriteriaId
            evaluationCriteriaDetails{
              minScore
              maxScore
              criteriaArray{
                evaluationCriterionName
                weight
              }
            }
          }
        }
      }
    `;
  let variables = {
    evaluationCriteriaId: evalCriteriaId
  }
  return new Promise((resolve, reject) => {
    apollofetcher.fetchWithToken({ query, variables }).then(res => {
      if (!res || !res.data || !res.data.evaluationCriteria) throw new Error("Error occured while fetching challenges");
      if (!res.data.evaluationCriteria.status) throw new Error(res.data.evaluationCriteria.message);
      resolve(res.data.evaluationCriteria.data);
    }).catch(err => {
      reject(err);
    });
  });
}

services.createOrUpdateEvaluationCriteria = (evaluationCriteriaId = null, minScore, maxScore, criteriaDataArray) => {
  let query = `
  mutation createOrUpdateEvaluationCriteria(
    $evaluationCriteriaId: String
    $minScore: Int!
    $maxScore: Int!
    $criteriaArray: [CriteriaWithWeightInput!]!
  ) {
    createOrUpdateEvaluationCriteria(
      input: {
        evaluationCriteriaId: $evaluationCriteriaId
        evaluationCriteriaDetails: {
          criteriaArray: $criteriaArray
          minScore: $minScore
          maxScore: $maxScore
        }
      }
    ) {
      status
      message
      data {
        evaluationCriteriaId
        evaluationCriteriaDetails {
          minScore
          maxScore
          criteriaArray {
            evaluationCriterionName
            weight
          }
        }
      }
    }
  }
  `;
  let variables = {
    evaluationCriteriaId: evaluationCriteriaId ? evaluationCriteriaId : null,
    criteriaArray: criteriaDataArray,
    minScore: minScore,
    maxScore: maxScore
  }

  return new Promise((resolve, reject) => {
    apollofetcher.fetchWithToken({ query, variables }).then(res => {
      if (!res || !res.data || !res.data.createOrUpdateEvaluationCriteria) throw new Error("Error occured while fetching challenges");
      if (!res.data.createOrUpdateEvaluationCriteria.status) throw new Error(res.data.createOrUpdateEvaluationCriteria.message);
      resolve(res.data.createOrUpdateEvaluationCriteria.data);
    }).catch(err => {
      console.log("criteria create Err = ", err.message);
      reject(err);
    });
  });
}

export default services;