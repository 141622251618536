import React from "react"
import PropTypes from "prop-types"
import deburr from "lodash/deburr"
import Downshift from "downshift"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import Paper from "@material-ui/core/Paper"
import MenuItem from "@material-ui/core/MenuItem"
import Chip from "@material-ui/core/Chip"
import CircularProgress from "@material-ui/core/CircularProgress"
import validator from "validator"

function renderInput(inputProps) {
	const { InputProps, classes, ref, ...other } = inputProps

	return (
		<TextField
			InputProps={{
				inputRef: ref,
				classes: {
					root: classes.inputRoot,
					input: classes.inputInput
				},
				...InputProps
			}}
			{...other}
		/>
	)
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
	const isHighlighted = highlightedIndex === index
	const isSelected = (selectedItem || []).map((item) => item.id).indexOf(suggestion.id) > -1
	let menuLabel = suggestion.label + " (" + suggestion.email + ")"
	return (
		<MenuItem
			{...itemProps}
			key={suggestion.id}
			selected={isHighlighted}
			component="div"
			style={{
				display: "block",
				fontWeight: isSelected ? 500 : 400
			}}
		>
			<Grid container direction="row" justify="space-between" alignItems="center">
				<Grid item>{menuLabel}</Grid>

				{suggestion.isAvailable === true || suggestion.isAvailable === false ? (
					<Grid item>{suggestion.isAvailable ? "Available" : "Not Available"} </Grid>
				) : null}
			</Grid>
		</MenuItem>
	)
}

renderSuggestion.propTypes = {
	highlightedIndex: PropTypes.number,
	index: PropTypes.number,
	itemProps: PropTypes.object,
	selectedItem: PropTypes.string,
	suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired
}

class DownshiftMultiple extends React.Component {
	state = {
		inputValue: "",
		isSearching: false,
		suggestions: [],
		errorMessage: "",
		selectedItem: this.props.selectedUsers ? this.props.selectedUsers : [],
		nextItemId: 1
	}

	handleKeyDown = (event) => {
		const { inputValue, selectedItem } = this.state
		if (selectedItem.length && !inputValue.length && event.key === "Backspace") {
			let selectedItemTemp = selectedItem.slice(0, selectedItem.length - 1)
			this.setState({
				selectedItem: selectedItemTemp
			})
			this.props.setSelectedUsers(selectedItemTemp)
		}
		this.setState({ errorMessage: "" })
	}

	getSuggestions = (value) => {
		const inputValue = deburr(value.trim()).toLowerCase()
		const inputLength = inputValue.length
		let data = inputLength === 0 ? [] : this.state.suggestions.slice(0, 5)
		return data
	}

	searchUserAndProcessData = (searchText) => {
		if (!searchText) return
		this.setState({ isSearching: true })
		this.props.searchUserPromise(searchText).then(
			(data) => {
				console.log("found users len = ", data.users.length)
				let suggestions = data.users.map((user) => {
					return {
						...user,
						label: user.name,
						id: user.userId
					}
				})
				console.log("sugestions = ", suggestions)
				let errorMessage = ""
				if (suggestions.length == 0 && validator.isEmail(searchText)) {
					if (data.email == searchText && data.safeForEmailInvite) {
						suggestions.push({
							label: `Invite '${searchText}' by Email`,
							id: searchText,
							email: searchText,
							inviteByEmail: true
						})
					}
				}
				if (suggestions.length == 0) {
					if (data.email == searchText && !data.safeForEmailInvite) {
						errorMessage =
							"You can not invite by this email because there is an existing user with some different role with this email"
					} else errorMessage = "No matching user is found to invite"
				}
				this.setState({ suggestions: suggestions, errorMessage: errorMessage, isSearching: false })
			},
			(err) => {
				console.log("Error in search, err =", err.message)
				let errorMessage = "An unknown error occured while searching for users"
				this.setState({ suggestions: [], errorMessage: errorMessage, isSearching: false })
			}
		)
	}

	handleInputChange = (event) => {
		let value = event.target.value
		value = value.toLowerCase()
		if (!this.state.inputValue)
			this.setState({ suggestions: [], inputValue: value, errorMessage: "" })
		else this.setState({ inputValue: value, errorMessage: "" })
		this.searchUserAndProcessData(value)
	}

	handleChange = (item) => {
		console.log("item = ", item)
		let { selectedItem } = this.state
		if (
			item &&
			item.id &&
			selectedItem.map((tmp) => tmp.id).indexOf(item.id) === -1 &&
			item.isAvailable !== false
		) {
			console.log("item is being added into the selected items array")
			selectedItem = [...selectedItem, item]
		}
		this.setState({
			errorMessage: "",
			inputValue: "",
			selectedItem
		})
		this.props.setSelectedUsers(selectedItem)
	}

	handleDelete = (item) => () => {
		const selectedItem = [...this.state.selectedItem]
		selectedItem.splice(selectedItem.map((tmp) => tmp.id).indexOf(item.id), 1)
		this.setState({ errorMessage: "", selectedItem })
		this.props.setSelectedUsers(selectedItem)
	}

	render() {
		const { classes } = this.props
		const { inputValue, selectedItem } = this.state

		return (
			<Downshift
				id="downshift-multiple"
				inputValue={inputValue}
				itemToString={(item) => (item ? item.label : "")}
				onChange={this.handleChange}
				selectedItem={selectedItem}
			>
				{({
					getInputProps,
					getItemProps,
					isOpen,
					inputValue: inputValue2,
					selectedItem: selectedItem2,
					highlightedIndex
				}) => (
					<div className={classes.container}>
						{renderInput({
							fullWidth: true,
							classes,
							InputProps: getInputProps({
								startAdornment: selectedItem.map((item) => (
									<Chip
										key={item.id}
										tabIndex={-1}
										label={item.label}
										className={classes.chip}
										onDelete={this.handleDelete(item)}
									/>
								)),
								endAdornment: this.state.isSearching ? <CircularProgress size={20} /> : null,
								onBlur: () => {
									console.log("OnBlur")
									this.setState({ inputValue: "", errorMessage: "" })
								},
								onChange: this.handleInputChange,
								onKeyDown: this.handleKeyDown,
								placeholder: "Type to select multiple users"
							}),
							label: "Select Users"
						})}
						{isOpen ? (
							<Paper className={classes.paper} square>
								{this.getSuggestions(inputValue2).map((suggestion, index) => {
									return renderSuggestion({
										suggestion,
										index,
										itemProps: getItemProps({ item: suggestion }),
										highlightedIndex,
										selectedItem: selectedItem2
									})
								})}
							</Paper>
						) : null}
						<br />
						<br />
						{this.state.errorMessage && (
							<div
								style={{
									backgroundColor: "#FFE5CC",
									color: "#FF0000",
									width: "100%",
									padding: "10px",
									fontSize: "1.1em"
								}}
							>
								{this.state.errorMessage}
							</div>
						)}
					</div>
				)}
			</Downshift>
		)
	}
}

DownshiftMultiple.propTypes = {
	classes: PropTypes.object.isRequired,
	setSelectedUsers: PropTypes.func.isRequired
}

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		height: "100%"
	},
	container: {
		flexGrow: 1,
		height: "100%",
		position: "relative"
	},
	paper: {
		position: "absolute",
		zIndex: 1,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	chip: {
		margin: theme.spacing(0.5, 0.25)
	},
	inputRoot: {
		flexWrap: "wrap"
	},
	inputInput: {
		width: "auto",
		flexGrow: 1,
		color: theme.palette.text.page
	},
	divider: {
		height: theme.spacing(2)
	}
})

function IntegrationDownshift(props) {
	const { classes } = props

	return (
		<div className={classes.root}>
			<DownshiftMultiple {...props} />
		</div>
	)
}

IntegrationDownshift.propTypes = {
	classes: PropTypes.object.isRequired
}

export default withStyles(styles)(IntegrationDownshift)
