export const challengePermissionForUser = {
  CREATE_UPDATE_EVALUATION_TEAM: 'CanCreateOrUpdateEvaluationTeam',
  CREATE_UPDATE_MANAGE_CHALLENGE_INVITATION: 'CanCreateOrUpdateManageChallengeInvitation',
  CREATE_UPDATE_EVALUATION_SCORE: 'CanCreateOrUpdateEvaluationScore',
  CREATE_UPDATE_EVALUATION_CRITERIA: 'CanCreateOrUpdateEvaluationCriteria',
  MANAGE_FORM: 'CanManageForms',
  SEND_EMAIL_BY_ROLES: 'canSendEmailByRoles',
  ACCESS_SUBMISSION_DASHBOARD: 'CanAccessSubmissionsDashboard',
  MANAGE_CHALLENGE: 'CanManageChallenge',
	GET_REPORTING_PAGE: 'CanGetReportingPage',
};
