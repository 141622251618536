import React from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Typography from "@material-ui/core/Typography"
import { Mutation } from "@apollo/client/react/components"
import { gql } from '@apollo/client'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import ReactHtmlParser from 'html-react-parser'
import challengeServices from "dataServices/challengeServices"
import Constants from "constants.js"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import CardFooter from "components/Card/CardFooter"
import htmlToDraft from "html-to-draftjs"
import FontOverridePatch from "utils/FontOverridePatch"

const uploadFileMutation = gql`
	mutation uploadFile($file: Upload!, $uploadType: String!) {
		uploadFile(file: $file, uploadType: $uploadType) {
			status
			message
			totalCount
			data {
				id
				filename
				path
				mimetype
				encoding
			}
		}
	}
`

var self = this

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	container: {
		margin: "0px 0px",
		padding: "40px 20px",
		backgroundColor: theme.palette.background.page
	},
	bottomNavigationBarStyle: {
		backgroundColor: "#C3FDFF",
		color: "#101316"
	},
	updateDescriptionButtonStyle: {
		marginTop: "20px",
		marginBottom: "20px",
		minWidth: "200px"
	},
	editRulesStyle: {
		minHeight: "600px"
	},
	toolbarClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	wrapperClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	editorClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	rulesPageBackgroundColor: {
		//backgroundColor: theme.palette.background.page,//"#302f2f"
	},
	buttonSuccessColor: {
		backgroundColor: "#1eb980",
		"&:hover,&:focus": {
			backgroundColor: "#1eb980",
			boxShadow: "0px 6px 14px rgba(30, 185, 128, .3)"
		}
	},
	whiteTextColorStyle: {
		color: theme.palette.text.page //"#FFFFFF"
	}
})

class Rules extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: 0,
			alert: null,
			canEditRules: false,
			file: null,
			fileName: null,
			editRuleMode: false,
			editorState: EditorState.createEmpty(),
			pageContent: null,
			dataFetchDone: false
		}
		self = this
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			editorState
		})
	}

	handleSimple = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	handleChange = (event, value) => {
		this.setState({ value })
		console.log(this.state)
	}

	uploadImageCallBack = (file, mutation) => {
		console.log(file)
		console.log(mutation)
		return new Promise((resolve, reject) => {
			mutation({
				mutation: uploadFileMutation,
				variables: { file: file, uploadType: "image" },
				fetchPolicy: "no-cache",
				context: {
				  headers: {
					'x-apollo-operation-name': 'upload-file'
				  }
				},
			})
				.then((res) => {
					if (res.data.uploadFile.status == true) {
						console.log("File upload response", res.data.uploadFile.data)
						let filePath = res.data.uploadFile.data.path
						var uploadResponse = { data: { link: Constants.Settings.APIURL + filePath } }
						resolve(uploadResponse)
					} else {
						throw new Error(res.data.uploadFile.message)
					}
				})
				.catch((err) => {
					reject(err.message)
				})
		})
	}

	fetchChallenge(challengeId) {
		this.props.setLoadingSpinner()
		challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				var des = data.challengeDetails.rules
				if (des != "<p></p>\n" && des) {
					const blocksFromHTML = htmlToDraft(des)
					const state = ContentState.createFromBlockArray(
						blocksFromHTML.contentBlocks,
						blocksFromHTML.entityMap
					)
					var updatedEditorState = EditorState.createWithContent(state)
					this.setState({
						editorState: updatedEditorState
					})
				}
				this.setState({
					pageContent: data.challengeDetails.rules,
					dataFetchDone: true
				})
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("warning", "Opps!", "No challenge found")
			})
	}

	updateRules() {
		this.props.setLoadingSpinner()
		var variables = {
			challengeId: this.props.challengeId,
			rules: draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
		}

		challengeServices
			.updateChallenge(variables)
			.then((status) => {
				this.props.resetLoadingSpinner()
				if (status) {
					this.fetchChallenge(this.props.challengeId)
					this.setState({ editRuleMode: false })
				}
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("warning", "Opps!", "Error updating challenge rules")
			})
	}

	componentWillReceiveProps(newProps) {
		this.setState({ canEditRules: newProps.canEditChallenge })
	}

	componentDidMount() {
		console.log("Component Did Mount of RulesStepTab: .. ", this.props.challengeId)
		if (this.props.canEditChallenge) {
			this.setState({ canEditRules: true })
		}
		this.fetchChallenge(this.props.challengeId)
	}

	render() {
		const { classes } = this.props
		return (
			<div className={classes.rulesPageBackgroundColor + " " + classes.container}>
				{this.state.alert}
				<Card color="gmgTheme" raised className={classes.rulesPageBackgroundColor}>
					<CardBody>
						{this.state.editRuleMode ? (
							<Card color="gmgTheme">
								<CardBody>
									<FontOverridePatch isDraftEditor>
										<Mutation mutation={uploadFileMutation} fetchPolicy="no-cache" context={{ headers: { 'x-apollo-operation-name': 'upload-file' } }}>
											{(mutation, { loading }) => (
												<Editor
													editorState={this.state.editorState}
													toolbarClassName={classes.toolbarClassName}
													wrapperClassName={classes.wrapperClassName}
													editorClassName={classes.cccEditorStyle}
													className={classes.editRulesStyle}
													toolbar={{
														options: [
															"inline",
															"blockType",
															"fontSize",
															"fontFamily",
															"list",
															"textAlign",
															"link",
															"embedded",
															"remove",
															"history",
															"image"
														],
														image: {
															uploadCallback: function (file) {
																return self.uploadImageCallBack(file, mutation)
															},
															previewImage: true,
															alt: { present: true, mandatory: true },
															defaultSize: {
																height: "auto",
																width: "100%"
															}
														}
													}}
													onEditorStateChange={this.onEditorStateChange}
												/>
											)}
										</Mutation>
									</FontOverridePatch>
								</CardBody>
							</Card>
						) : (
							<div>
								{(this.state.pageContent == null || this.state.pageContent == "<p></p>\n") &&
								this.state.dataFetchDone ? (
									<Typography className={classes.whiteTextColorStyle} align="center">
										{this.state.canEditRules ? (
											<div style={{ textTransform: "capitalize" }}>
												Please add rules to this challenge
											</div>
										) : (
											<div style={{ textTransform: "capitalize" }}>No rules have been set yet</div>
										)}
									</Typography>
								) : (
									<div className={classes.whiteTextColorStyle}>
										<FontOverridePatch>{ReactHtmlParser(this.state.pageContent || "")}</FontOverridePatch>
									</div>
								)}
							</div>
						)}
					</CardBody>
					<CardFooter>
						<GridContainer direction="row" justify="space-between" alignItems="flex-end">
							<GridItem>
								{this.state.editRuleMode ? (
									<Button
										color="warning"
										onClick={() => {
											this.setState({ editRuleMode: false })
										}}
									>
										Cancel
									</Button>
								) : null}
							</GridItem>
							<GridItem>
								{!this.state.editRuleMode && this.state.canEditRules ? (
									<Button
										className={classes.buttonSuccessColor}
										onClick={() => {
											this.setState({ editRuleMode: true })
										}}
									>
										Edit
									</Button>
								) : this.state.editRuleMode ? (
									<Button
										className={classes.buttonSuccessColor}
										disabled={
											draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) ==
											"<p></p>\n"
										}
										onClick={() => {
											this.updateRules()
										}}
									>
										Update Rules
									</Button>
								) : null}
							</GridItem>
						</GridContainer>
					</CardFooter>
				</Card>
			</div>
		)
	}
}

Rules.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Rules))
