import React from "react"
import PropTypes from "prop-types"
import { get } from "lodash"

import { fade } from "@material-ui/core/styles/colorManipulator"
import { withStyles, withTheme, createStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { Button } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"

import Divider from "@material-ui/core/Divider"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "views/Components/TableCellStyled"

import CustomTable from "components/Table/Table.jsx"
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"
import commonStyles from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import combineStyles from "utils/combineStyle"
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle"
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"

import cx from "classnames"
import Tooltip from "@material-ui/core/Tooltip"
import Close from "@material-ui/icons/Close"

import validator from "validator"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../helper"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import solutionServices from "dataServices/solutionServices"
import formServices from "dataServices/formServices"
import evaluationCriteriaServices from "dataServices/evaluationCriteriaServices"
import evaluationTeamServices from "dataServices/evaluationTeamServices"
import evaluationScoreServices from "dataServices/evaluationScoreServices"
import constant from "constants.js"
import constants from "constants.js"
import ManageChallengeMenu from "../Pages/ManageChallengeMenu.jsx"
import SetScoresSliderComments from "./SubmissionScoringUI/SetScoreSliderComments"

import CardContent from "@material-ui/core/CardContent"
import ReactHtmlParser from 'html-react-parser'

import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import Grid from "@material-ui/core/Grid"
import { Form } from "react-formio"
import Pagination from "material-ui-flat-pagination"

import moment from "moment"
import tableStyle from "assets/jss/material-dashboard-pro-react/components/tableStyle.jsx"
import FontOverridePatch from "utils/FontOverridePatch.jsx"
import { removePortNumberFromURL } from "utils/urls.js"

function Transition(props) {
	return <Slide direction="down" {...props} />
}

class SubmissionsDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			challengeId: "",
			urlSlug: "",
			challenge: null,
			showErrorComponent: false,
			errorMessage: "",
			formData: null,
			alert: null,
			userName: null,
			darkMode: false,
			form: null,
			solutions: [],
			evaluationCriteriaId: "",
			evaluationCriteria: null,
			minScore: 0,
			maxScore: 0,
			evaluationTeams: [],
			scores: [],
			isDataFetchDone: false,
			solutionsTableData: [],
			viewFormModalOpen: false,
			isScoreModalOpen: false,
			isEvaluatorsDetailsModalOpen: false,
			canSetEvaluationScoring: false,
			selectedSolutionScoreIndex: 0,
			showScoringUI: false,
			activeSolutionIndex: 0,
			currentSolution: null,
			totalCount: 0,
			totalFetched: 0,
			currentPage: 1,
			itemsPerPage: 10
		};
	}

	getChallengeById = (challengeId) => {
		this.props.setLoadingSpinner()
		return challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				console.log("challenge = ", data)
				this.setState(
					{
						challenge: data,
						challengeId: data.challengeId,
						urlSlug: data.urlSlug
					},
					() => {
						let name = data.challengeDetails.title
						if (name.length > 50) name = name.substring(0, 50) + " ..."
						this.props.pushBreadCrumbStack({
							name: name,
							link: "/dashboard/challenge-details/" + challengeId
						})
					}
				)
				this.props.resetLoadingSpinner()
				return true
			})
			.catch((err) => {
				console.log("error getting challenge by id, err =", err.message)
				this.props.resetLoadingSpinner()
				this.props.setRedirectOnError("/home/index")
				return false
			})
	}

	getFormByChallengeId = (challengeId) => {
		return formServices
			.getFormByChallengeId(challengeId)
			.then((form) => {
				if (!form) throw new Error("No form found for this challenge")
				return form
			})
			.catch((err) => {
				console.log("Error getting forms for challenge, err =", err.message)
				return null
			})
	}

	getEvaluationTeamsForChallenge = () => {
		return evaluationTeamServices
			.getEvaluationTeamsForChallenge(this.state.challengeId)
			.then((data) => {
				if (!data) throw new Error("Error fetching evaluation team data")
				return data
			})
			.catch((err) => {
				console.log("Error fetching evaluationTeam for challenge", err.message)
				return []
			})
	}

	fetchEvaluationScore = (solutionId) => {
		return evaluationScoreServices
			.getEvaluationScoresForSolutions(solutionId)
			.then((data) => {
				if (!data) throw new Error("No Evaluation score is found")
				return data
			})
			.catch((err) => {
				console.log("Error fetching evaluation score, err =", err.message)
				return null
			})
	}

	fetchAllEvaluationScore = (solutionIds) => {
		return Promise.all(solutionIds.map((sId) => this.fetchEvaluationScore(sId)))
			.then((result) => {
				return result
			})
			.catch((err) => {
				console.log("Error fetching all the evaluation score for all the submissions", err.message)
				return solutionIds.map((sId) => null)
			})
	}

	fetchEvaluationCriteria = () => {
		return evaluationCriteriaServices
			.getEvaluationCriteriaById(this.state.challenge.challengeDetails.evaluationCriteriaId)
			.then((criteria) => {
				if (!criteria) throw new Error("Evaluation criteria is not set")
				return criteria
			})
			.catch((err) => {
				console.log("Error fetching evaluation criteria, err =", err.message)
				return null
			})
	}

	fetchAllSolutionsByChallenge = () => {
		return solutionServices
			.getSolutionsByChallengeIdPaginated(
				this.state.challengeId,
				this.state.currentPage,
				this.state.itemsPerPage
			)
			.then((solutions) => {
				if (!solutions) throw new Error("No solutions are submitted yet")
				return solutions
			})
			.catch((err) => {
				console.log("Error fetching solutions by challenge, err = ", err.message)
				return { totalCount: 0, totalFetched: 0, data: [] }
			})
	}

	storeCriteriaIntoState = (criteria) => {
		let slidersValues = []
		let commentTexts = []

		let criteriaScoreObject = criteria.evaluationCriteriaDetails.criteriaArray.map(
			(criteriaItem) => {
				slidersValues.push(0)
				commentTexts.push("")
				return {
					evaluationCriterionName: criteriaItem.evaluationCriterionName,
					weight: criteriaItem.weight
				}
			}
		)

		let evaluationCriteriaId = criteria.evaluationCriteriaId

		this.setState({
			evaluationCriteriaId: evaluationCriteriaId,
			evaluationCriteria: criteriaScoreObject,
			slidersValues: slidersValues,
			commentTexts: commentTexts,
			maxScore: criteria.evaluationCriteriaDetails.maxScore,
			minScore: criteria.evaluationCriteriaDetails.minScore
		})
	}

	onClickPaginationButton = (offset) => {
		let currentPage = offset / this.state.itemsPerPage + 1
		this.setState({ currentPage: currentPage }, () => {
			this.props.setLoadingSpinner()
			this.fetchAllSolutionsByChallenge()
				.then((result) => {
					let solutions = result.data.filter(
						(solution) => solution.solutionDetails.officialSubmission
					)
					this.setState({
						solutions: solutions,
						totalCount: result.totalCount,
						totalFetched: result.totalFetched
					})
					let solutionIds = solutions.map((solution) => solution.solutionId)
					return this.fetchAllEvaluationScore(solutionIds)
				})
				.then((scores) => {
					this.setState({
						scores: scores
					})
					let tableData = this.prepareTableData()
					this.setState({
						solutionsTableData: tableData
					})
					this.props.resetLoadingSpinner()
					console.log("state = ", this.state)
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					console.log("Error fetching data, err =", err.message)
				})
		})
	}

	fetchAllData = () => {
		return Promise.all([
			this.fetchEvaluationCriteria(),
			this.getFormByChallengeId(this.state.challengeId),
			this.fetchAllSolutionsByChallenge(),
			this.getEvaluationTeamsForChallenge()
		])
			.then((result) => {
				const solutions = result[2].data.filter((solution) => solution.solutionDetails.officialSubmission);
				const solutionIds = solutions.map((solution) => solution.solutionId);

				if (result[0]) {
					this.storeCriteriaIntoState(result[0]);
				}

				this.setState({
					form: result[1],
					solutions: solutions,
					totalCount: result[2].totalCount,
					totalFetched: result[2].totalFetched,
					evaluationTeams: result[3],
				});

				return this.fetchAllEvaluationScore(solutionIds);
			})
			.then((scores) => {
				this.setState({
					scores: scores
				})
				let tableData = this.prepareTableData()
				this.setState({
					solutionsTableData: tableData
				})
				this.props.resetLoadingSpinner()
				console.log("state = ", this.state)
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error fetching data, err =", err.message)
			})
	}

	fetchInitialData = (challengeUrlSlug) => {
		this.props.setLoadingSpinner()
		return challengeServices
			.getChallengeById(challengeUrlSlug)
			.then((challenge) => {
				this.setState({
					challenge: challenge,
					challengeId: challenge.challengeId,
					urlSlug: challenge.urlSlug
				})

				var title = "Submissions | Challenge | BestInCrowd"
				try {
					title = "Submissions | " + challenge.challengeDetails.title + " | BestInCrowd"
				} catch (err) {}
				helper.setPageTitle(title)
				return userServices.getCurrentUser()
			})
			.then((user) => {
				let adminRole = false
				user.roles.map((r) => {
					if (
						r.RoleId == "superAdmin" ||
						r.RoleId == "licenseeAdmin" ||
						(r.RoleId == "challengeAdmin" && r.challengeId == this.state.challengeId) ||
						(r.RoleId == "judge" && r.challengeId == this.state.challengeId)
					) {
						adminRole = true
						if (
							r.RoleId == "superAdmin" ||
							r.RoleId == "licenseeAdmin" ||
							(r.RoleId == "challengeAdmin" && r.challengeId == this.state.challengeId)
						) {
							this.setState({ canSetEvaluationScoring: true })
						}
					}
				})

				if (!adminRole) {
					throw new Error("You don't have permission to view this page")
				}
				console.log("All okay lets fetch actual data")
				this.fetchAllData().then(() => {
					this.props.resetLoadingSpinner()
					this.setState({ isDataFetchDone: true })
				})
			})
			.catch((err) => {
				console.log("Error occurred, err = ", err.message)
				this.setState({
					showErrorComponent: true,
					errorMessage: err.message,
					isDataFetchDone: true
				})
				this.props.resetLoadingSpinner()
				this.props.setRedirectOnError("/dashboard/index")
			})
	}

	prepareTableData = () => {
		let solutions = this.state.solutions
		let offset = Math.max(this.state.currentPage - 1, 0) * this.state.itemsPerPage + 1
		let solutionsTableData = solutions.map((solution, index) => {
			let status = ""
			let scoreSum = 0
			var averageScore = "-"
			var scoreVariance = "-"

			if (this.state.scores[index]) {
				status = "Score evaluated"
				var viewOrSetScoreButton = (
					<Button
						color="success"
						onClick={() =>
							this.setState({
								selectedSolutionScoreIndex: index,
								isScoreModalOpen: true,
								viewFormModalOpen: false,
								showScoringUI: false,
								isEvaluatorsDetailsModalOpen: false
							})
						}
					>
						View Scores
					</Button>
				)
				let currentScoreData = this.state.scores[index]
				let totalScoresArray = []
				currentScoreData.map((evalScore) => {
					let totalScore = 0
					evalScore.evaluationScoreDetails.criteriaBasedScore.map((criteriaBasedScore) => {
						totalScore += criteriaBasedScore.scoreValue
					})
					scoreSum += totalScore
					totalScoresArray.push(totalScore)
				})
				if (currentScoreData.length != 0) {
					averageScore = scoreSum / currentScoreData.length
					let squaredSum = 0
					totalScoresArray.map((score) => {
						squaredSum += (score - averageScore) * (score - averageScore)
					})
					scoreVariance = squaredSum / currentScoreData.length
				}
			} else {
				viewOrSetScoreButton = (
					<Button
						color="success"
						onClick={() => {
							this.setState({
								showScoringUI: true,
								viewFormModalOpen: false,
								isScoreModalOpen: false,
								isEvaluatorsDetailsModalOpen: false
							})
							this.handleOnClickSetScore(index)
						}}
					>
						Set Score
					</Button>
				)
				let assigned = false
				this.state.evaluationTeams.map((evalTeam) => {
					if (
						evalTeam.evaluationTeamDetails.solutions.find(
							(solutionId) => solutionId == solution.solutionId
						)
					) {
						assigned = true
					}
				})
				if (assigned) {
					status = "Evaluator assigned; Not Evaluated yet"
				} else {
					status = "No evaluator assigned"
				}
			}
			if (solution.formData) {
				var viewSubmission = (
					<Button
						color="success"
						onClick={() =>
							this.setState({
								viewFormModalOpen: true,
								isScoreModalOpen: false,
								showScoringUI: false,
								isEvaluatorsDetailsModalOpen: false,
								formData: solution.formData
							})
						}
					>
						View Submission
					</Button>
				)
			} else {
				let link = constant.Settings.APIURL + solution.solutionDetails.submissionFiles
				viewSubmission = (
					<a target="_blank" href={link}>
						View Submitted File
					</a>
				)
			}

			let evaluatorsSeeDetailsButton = (
				<Button
					color="success"
					onClick={(e) => {
						this.setState({
							isEvaluatorsDetailsModalOpen: true,
							showScoringUI: false,
							viewFormModalOpen: false,
							isScoreModalOpen: false,
							selectedSolutionScoreIndex: index
						})
					}}
				>
					See Details
				</Button>
			)

			if (!isNaN(averageScore)) {
				averageScore = helper.numberRoundToDecimal(averageScore, 2)
			} else averageScore = "-"
			if (!isNaN(scoreVariance)) {
				scoreVariance = helper.numberRoundToDecimal(scoreVariance, 2)
			} else scoreVariance = "-"

			return [
				offset + index,
				solution.solutionDetails.teamName || solution.solutionDetails.userName,
				solution.solutionDetails.title || "Form Submission",
				status,
				averageScore,
				scoreVariance,
				viewSubmission,
				evaluatorsSeeDetailsButton,
				moment(solution.solutionDetails.submissionDate).toLocaleString()
			]
		})
		return solutionsTableData
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props

		this.props.pushBreadCrumbStack({
			name: "Submissions Dashboard",
			link: this.props.location.pathname
		})
		if (validator.isUUID(params.challengeId)) {
			this.setState({ challengeId: params.challengeId })
		} else {
			this.setState({ urlSlug: params.challengeId })
		}
		this.fetchInitialData(params.challengeId)
	}

	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	onSelectInitSolutionToScore = (index) => {
		let slidersValues = [],
			commentTexts = []
		if (!this.state.scores[index] || !this.state.scores[index].id) {
			this.state.evaluationCriteria.map((v) => {
				slidersValues.push(0)
				commentTexts.push("")
			})
		} else {
			this.state.scores[index].evaluationScoreDetails.criteriaBasedScore.map((c) => {
				slidersValues.push(parseInt(c.scoreValue))
				commentTexts.push(c.commentText)
			})
		}
		this.setState({ slidersValues: slidersValues, commentTexts: commentTexts })
	}

	handleOnClickSetScore = (solutionIndex) => {
		this.setState({
			activeSolutionIndex: solutionIndex,
			currentSolution: this.state.solutions[solutionIndex]
		})
		this.onSelectInitSolutionToScore(solutionIndex)
	}

	handleSubmitScore = (slidersValues, commentTexts) => {
		let id = this.state.scores[this.state.activeSolutionIndex]
			? this.state.scores[this.state.activeSolutionIndex].id
			: null
		let challengeId = this.state.challengeId
		let solutionId = this.state.solutions[this.state.activeSolutionIndex].solutionId
		let evaluatorUserId = this.props.loggedInUserData.userId
		let solverUserId = this.state.solutions[this.state.activeSolutionIndex].userId
		let solverTeamId = this.state.solutions[this.state.activeSolutionIndex].teamId || null
		let criteriaBasedScore = this.state.evaluationCriteria.map((c) => ({
			criteriaName: c.evaluationCriterionName
		}))

		criteriaBasedScore = criteriaBasedScore.map((criterion, index) => {
			return {
				...criterion,
				scoreValue: parseInt(slidersValues[index]),
				commentText: commentTexts[index]
			}
		})

		this.props.setLoadingSpinner()
		evaluationScoreServices
			.createOrUpdate(
				id,
				challengeId,
				solutionId,
				evaluatorUserId,
				null,
				solverUserId,
				solverTeamId,
				criteriaBasedScore
			)
			.then((data) => {
				if (!data) {
					throw new Error("Error submitting evaluation score")
				}

				let scores = this.state.scores.map((s, i) => {
					if (this.state.activeSolutionIndex == i) {
						return [data]
					}
					return s
				})

				this.setState(
					{
						showScoringUI: false,
						isScoreModalOpen: false,
						viewFormModalOpen: false,
						isEvaluatorsDetailsModalOpen: false,
						currentSolution: null,
						scores: scores
					},
					() => {
						let tableData = this.prepareTableData()
						this.setState({ solutionsTableData: tableData })
					}
				)
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("Error submitting scores, err =", err.message)
				this.props.showAlert("error", "Oops!", err.message)
				this.props.resetLoadingSpinner()
			})
	}

	removePortNumberFromSolutionFileUrl = () => {
		const { formData } = this.state;
		const updatedFormData = JSON.parse(JSON.stringify(formData));
		
		const { data } = updatedFormData;
		const objectKeys = Object.keys(data || {});

		if (!data || objectKeys.length == 0) {
			return updatedFormData;
		}

		objectKeys.forEach(key => {
			if (typeof(data[key]) == "object" && data[key].length) {
				data[key].forEach(formComponent => {
					if (formComponent.url) {
						formComponent.url = removePortNumberFromURL(formComponent.url);
					}

					if (get(formComponent, "data.url")) {
						formComponent.data.url = removePortNumberFromURL(formComponent.data.url);
					}
				});
			}
		});

		return updatedFormData;
	}

	renderDetailedScoreModal = () => {
		const { classes } = this.props
		let selectedIndex = this.state.selectedSolutionScoreIndex
		if (
			!this.state.isScoreModalOpen ||
			this.state.scores.length <= selectedIndex ||
			!this.state.scores[selectedIndex]
		)
			return null
		let submissionName = this.state.solutions[selectedIndex].solutionDetails.title
		let currentScoreData = this.state.scores[selectedIndex]
		return (
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modal
				}}
				open={true}
				keepMounted
				onClose={() => {
					this.setState({ isScoreModalOpen: false })
				}}
			>
				<DialogTitle
					disableTypography
					className={classes.modalHeader}
					style={{
						margin: "0px 0px"
					}}
				>
					<Button
						justIcon
						className={classes.modalCloseButton}
						color="transparent"
						onClick={() => {
							this.setState({ isScoreModalOpen: false })
						}}
					>
						<Tooltip title="Close" placement="top">
							<Close />
						</Tooltip>
					</Button>
					<h4 className={classes.modalTitle}>Detailed Scores</h4>
				</DialogTitle>

				<DialogContent className={classes.modalBody}>
					<div style={{ minWidth: "500px" }}> </div>
					<Card>
						<CardBody>
							<Grid container direction="column" justify="flex-start" alignItems="flex-start">
								<Grid item lg={12} xs={12}>
									<h4> Submission Title: {submissionName} </h4>
								</Grid>
								<Grid item lg={12} xs={12}>
									<h5>
										Review Status: <span style={{ color: "green" }}> Review Completed </span>{" "}
									</h5>
								</Grid>
								<Grid item>
									<Divider variant="middle" />
								</Grid>
								<Paper style={{ width: "100%" }}>
									<Grid>
										{currentScoreData.map((data) => {
											return (
												<div>
													<Grid item lg={12} xs={12}>
														<h5> Criteria based scores details: </h5>
													</Grid>
													{data.evaluationScoreDetails.criteriaBasedScore.map((criterion) => {
														return (
															<Grid item lg={12} xs={12}>
																<h5>
																	<FontOverridePatch>
																		{ReactHtmlParser(criterion.criteriaName || "")}:{" "}
																		{criterion.scoreValue}
																	</FontOverridePatch>
																</h5>
															</Grid>
														)
													})}
												</div>
											)
										})}
									</Grid>
								</Paper>
							</Grid>
						</CardBody>
					</Card>
				</DialogContent>
			</Dialog>
		)
	}

	renderViewSolutionFormData = () => {
		const { classes } = this.props;

		if (!this.state.viewFormModalOpen || !this.state.formData) {
			return null;
		}

		const updatedFormData = this.removePortNumberFromSolutionFileUrl();

		return (
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modal
				}}
				open={true}
				fullWidth={true}
				maxWidth={"lg"}
				TransitionComponent={Transition}
				keepMounted
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
				onClose={() => {
					this.setState({ formData: null, viewFormModalOpen: false })
				}}
			>
				<DialogTitle
					id="classic-modal-slide-title"
					disableTypography
					className={classes.modalHeader}
					style={{
						margin: "0px 0px"
					}}
				>
					<Grid container justifyContent="space-between"  alignItems="center">
						<Grid item>
						</Grid>
						<Grid item>
							<h4 className={cx(classes.modalTitle, classes.modalTitleStyle)}>
								Solution Form Details
							</h4>
						</Grid>
						<Grid item>
							<Button
								justIcon
								key="close"
								aria-label="Close"
								color="transparent"
								onClick={() => {
									this.setState({ formData: null, viewFormModalOpen: false })
								}}
							>
								<Tooltip title="Cancel" placement="top">
									<Close className={classes.modalClose} style={{ height: "30px", width: "25px" }}/>
								</Tooltip>
							</Button>
						</Grid>
					</Grid>
				</DialogTitle>

				<DialogContent
					id="classic-modal-slide-description"
					className={classes.modalBody + " " + classes.modalBody2}
				>
					<div style={{ margin: "20px 0px", padding: "40px 0px" }} className="formio">
						<Form
							form={{ ...this.state.form.formData }}
							options={{ readOnly: true, viewAsHtml: true }}
							submission={updatedFormData}
						/>
					</div>
				</DialogContent>
			</Dialog>
		)
	}

	renderSetScoringDetails = () => {
		if (
			!this.state.showScoringUI ||
			!this.state.evaluationCriteria ||
			!this.state.solutions ||
			!this.state.solutions.length
		) {
			return null
		}

		const { classes } = this.props
		let currentSolution = this.state.currentSolution

		let scoringCompleted = true
		this.state.scores.map((s) => {
			if (!s || !s.id) {
				scoringCompleted = false
			}
		})

		let submissionTitle = currentSolution.solutionDetails.title
		if (!submissionTitle || submissionTitle == "") {
			submissionTitle = "Form Submission"
		}

		return (
			<Card className={classes.card}>
				<CardContent>
					<Typography color="textPrimary" gutterBottom>
						Submission: {submissionTitle}
					</Typography>

					<Typography className={classes.pos} color="textSecondary">
						{currentSolution.formData && this.state.form ? (
							<div>
								<span> Submitted solution form content/files: </span>

								<Form
									form={{ ...this.state.form.formData }}
									options={{ readOnly: true, viewAsHtml: true }}
									submission={currentSolution.formData}
								/>
							</div>
						) : (
							<div>
								<span>Submitted file/content can be found </span>
								<span>
									<a
										target="_blank"
										href={
											constants.Settings.APIURL + currentSolution.solutionDetails.submissionFiles
										}
									>
										{" "}
										here{" "}
									</a>
								</span>
							</div>
						)}
					</Typography>

					<SetScoresSliderComments
						classes={classes}
						scoringCompleted={false}
						currentSolution={this.state.currentSolution}
						commentTexts={this.state.commentTexts}
						slidersValues={this.state.slidersValues}
						evaluationCriteria={this.state.evaluationCriteria}
						maxScore={this.state.maxScore}
						handleSubmitScore={this.handleSubmitScore}
						onCancel={() => {
							this.setState({ showScoringUI: false, isScoreModalOpen: false })
						}}
					/>
				</CardContent>
			</Card>
		)
	}

	renderEvaluatorsDetailsModal = () => {
		const { classes } = this.props
		let selectedIndex = this.state.selectedSolutionScoreIndex
		if (!this.state.isEvaluatorsDetailsModalOpen || this.state.scores.length <= selectedIndex)
			return null
		let submissionName = this.state.solutions[selectedIndex].solutionDetails.title
		let currentScoreData = this.state.scores[selectedIndex]
		return (
			<Dialog
				classes={{
					root: classes.center + " " + classes.modalRoot,
					paper: classes.modalFullWith
				}}
				open={true}
				keepMounted
				fullWidth
				maxWidth={"lg"}
				onClose={() => {
					this.setState({ isEvaluatorsDetailsModalOpen: false })
				}}
			>
				<DialogTitle
					disableTypography
					className={classes.modalHeader}
					style={{
						margin: "0px 0px"
					}}
				>
					<Button
						justIcon
						className={classes.modalCloseButton}
						color="transparent"
						onClick={() => {
							this.setState({ isEvaluatorsDetailsModalOpen: false })
						}}
					>
						<Tooltip title="Close" placement="top">
							<Close />
						</Tooltip>
					</Button>
					<h4 className={classes.modalTitle}>Evaluator and Score Details</h4>
				</DialogTitle>

				<DialogContent className={classes.modalBody}>
					<div style={{ minWidth: "500px" }}> </div>
					<Card>
						<CardBody>
							{currentScoreData ? (
								<Table className={classes.table}>
									<TableHead className={classes.tableHead}>
										<TableRow>
											<TableCell align="left" style={{ width: "20%" }}>
												Evaluator's Name
											</TableCell>
											<TableCell align="left"> Criterion Name </TableCell>
											<TableCell align="left"> Criterion Score </TableCell>
											<TableCell align="left"> Remarks </TableCell>
										</TableRow>
									</TableHead>
									<TableBody className={classes.tableBody}>
										{currentScoreData.map((score) => {
											let rowSpanCount = score.evaluationScoreDetails.criteriaBasedScore.length
											let totalScore = 0
											score.evaluationScoreDetails.criteriaBasedScore.map((criteriaScore) => {
												totalScore += criteriaScore.scoreValue
											})
											let columns = score.evaluationScoreDetails.criteriaBasedScore.map(
												(criteriaScore, index) => {
													let nameColumn = (
														<TableCell align="left" rowSpan={rowSpanCount + 1}>
															{score.evaluationScoreDetails.evaluatorUser.name}
														</TableCell>
													)
													let othersColumn = [
														<TableCell align="left">
															<FontOverridePatch>
																{ReactHtmlParser(criteriaScore.criteriaName || "")}
															</FontOverridePatch>
														</TableCell>,
														<TableCell align="left"> {criteriaScore.scoreValue} </TableCell>,
														<TableCell align="left"> {criteriaScore.commentText} </TableCell>
													]
													if (index == 0) return [nameColumn, ...othersColumn]
													return othersColumn
												}
											)
											columns.push([
												<TableCell align="left"> Total </TableCell>,
												<TableCell align="left" colSpan={2}>
													{totalScore}
												</TableCell>
											])
											return columns.map((column) => {
												return <TableRow> {column.map((col) => col)} </TableRow>
											})
										})}
									</TableBody>
								</Table>
							) : (
								<div>
									<h4> Solution is not evaluated yet </h4>
									{this.state.canSetEvaluationScoring && (
										<Button
											color="success"
											onClick={() => {
												if (!this.state.evaluationCriteria) {
													this.props.showAlert(
														"error",
														"Oops!",
														"evaluation criteria is not set yet"
													)
													return
												}
												this.onSelectInitSolutionToScore(selectedIndex)
												this.setState({
													showScoringUI: true,
													isScoreModalOpen: false,
													viewFormModalOpen: false,
													isEvaluatorsDetailsModalOpen: false,
													activeSolutionIndex: selectedIndex,
													currentSolution: this.state.solutions[selectedIndex]
												})
											}}
										>
											Set Scores Now
										</Button>
									)}
								</div>
							)}
						</CardBody>
					</Card>
				</DialogContent>
			</Dialog>
		)
	}

	render() {
		const { classes } = this.props
		if (!this.state.isDataFetchDone) return <div> Loading... </div>
		let limit = this.state.itemsPerPage
		let offset = Math.max(this.state.currentPage - 1, 0) * limit
		let total = this.state.totalCount
		const paginationClasses = {
			colorInheritCurrent: classes.colorInheritCurrent,
			colorInheritOther: classes.colorInheritOther
		}

		return (
			<div>
				{this.renderViewSolutionFormData()}
				{this.renderDetailedScoreModal()}
				{this.renderEvaluatorsDetailsModal()}
				<GridContainer direction="row" justify="space-between">
					<GridItem xs={9} sm={10} md={10} lg={11}>
						<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
					</GridItem>
					<GridItem className={classes.right} xs={3} sm={2} md={2} lg={1}>
						{this.state.challenge && this.state.challengeId && (
							<ManageChallengeMenu
								challengeId={this.state.challengeId}
								challenge={this.state.challenge}
								urlSlug={this.state.urlSlug}
								history={this.props.history}
							/>
						)}
					</GridItem>
				</GridContainer>
				<br />
				<SnackbarContent
					message={this.state.challenge.challengeDetails.phaseTitle}
					color="success"
				/>

				{this.renderSetScoringDetails()}

				<Card color="gmgTheme">
					<CardHeader color="gmgTheme">
						<h4> Submissions {" | " + this.state.challenge.challengeDetails.title}</h4>
					</CardHeader>
					<CardBody color="gmgTheme">
						{this.state.solutions && this.state.solutions.length != 0 ? (
							<CustomTable
								tableHead={[
									"#",
									"Name",
									"Title",
									"Status",
									"Average Score",
									"Score Variance",
									"View",
									"Evaluators",
									"Date"
								]}
								tableData={this.state.solutionsTableData}
							/>
						) : (
							<h4> There are no submissions made yet </h4>
						)}
						<GridContainer direction="row" alignItems="center" justify="center">
							<GridItem sm={12} md={8} lg={6}>
								<div className={classes.paginationDiv}>
									<Pagination
										classes={paginationClasses}
										currentPageColor="inherit"
										otherPageColor="inherit"
										size={"large"}
										limit={limit}
										offset={offset}
										total={total}
										onClick={(e, offset) => this.onClickPaginationButton(offset)}
									/>
								</div>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</div>
		)
	}
}

SubmissionsDashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack,
		themeMode: state.theme
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: { alertType, title, description }
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

let extraStyles = (theme) => ({
	modalTitleStyle: {
		fontFamily: "Roboto Condensed",
		fontSize: "1.7em"
	},
	modalBody2: {
		margin: "-20px 20px -20px 20px !important",
		padding: "0px !important"
	},
	modalFullWith: {
		[theme.breakpoints.up("sm")]: {
			maxWidth: "1500px",
			margin: "auto"
		},
		borderRadius: "6px",
		marginTop: "100px !important",
		overflow: "visible",
		maxHeight: "unset",
		position: "relative",
		height: "fit-content"
	},
	tableHead: {
		backgroundColor: `${theme.palette.background.cardHeader} !important`
	},
	criteriaPaper: {
		margin: theme.spacing(2, 0),
		padding: theme.spacing(3, 3),
		border: "1px solid grey"
	}
})

const paginationStyles = (theme) =>
	createStyles({
		paperRoot: {
			margin: theme.spacing(2),
			padding: theme.spacing(2)
		},
		colorInheritCurrent: {
			margin: theme.spacing(0.5),
			color: theme.palette.text.page,
			backgroundColor: fade("#00FF00", 0.5),
			"&:hover": {
				backgroundColor: fade("#00FF00", theme.palette.action.hoverOpacity)
			}
		},
		colorInheritOther: {
			margin: theme.spacing(0.5),
			color: theme.palette.text.page,
			"&:hover": {
				backgroundColor: fade("#FF0000", theme.palette.action.hoverOpacity)
			}
		},
		paginationDiv: {
			width: "100%",
			color: theme.palette.text.cardBody
		}
	})

function valueText(value) {
	return `${value}`
}

let finalStyles = combineStyles(
	commonStyles,
	extendedTablesStyle,
	notificationsStyle,
	extraStyles,
	modalStyle,
	tableStyle,
	paginationStyles
)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyles)(withTheme(SubmissionsDashboard)))
