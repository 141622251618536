import React from "react"
import TableCell from "@material-ui/core/TableCell"
import { withStyles, withTheme } from "@material-ui/styles"

function TableCellStyled(props) {
	console.log("props = ", props)
	return (
		<TableCell {...props} className={props.className + " " + props.classes.tableCellStyle}>
			{props.children}
		</TableCell>
	)
}

let styles = (theme) => ({
	tableCellStyle: {
		border: "2px solid black"
	}
})

export default withStyles(styles)(TableCellStyled)
