import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import Icon from "@material-ui/core/Icon"
import ListItem from "@material-ui/core/ListItem"
// @material-ui/icons
import Face from "@material-ui/icons/Face"
import Email from "@material-ui/icons/Email"
import PermIdentity from "@material-ui/icons/PermIdentity"
import EditIcon from "@material-ui/icons/Edit"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInputForChallengeBuilder"
import CustomInputForSector from "components/CustomInput/CustomInputForSector.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import CardAvatar from "components/Card/CardAvatar.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import LockIcon from "@material-ui/icons/LockOutlined"
import Constants from "constants.js"
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx"

import helper from "../../helper"
import cookiesHelper from "cookiesHelper"
import combineStyles from "utils/combineStyle"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import userServices from "dataServices/userServices"
import { FormControl, Divider, Tooltip, IconButton } from "@material-ui/core"

import PasswordUpdateUI from "views/Pages/PasswordUpdateUI"

class PasswordUpdateComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hasCurrentPassword: props.hasCurrentPassword
		}
	}

	componentDidMount() {
		this.setState({ hasCurrentPassword: this.props.hasCurrentPassword })
	}

	componentDidUpdate(prevProps) {
		if (this.props.hasCurrentPassword != prevProps.hasCurrentPassword) {
			this.setState({ hasCurrentPassword: this.props.hasCurrentPassword })
		}
	}

	onClickUpdatePassword = (data, onSuccess) => {
		console.log("data = ", data)
		this.props.setLoadingSpinner()
		userServices
			.changePassword(data.currentPassword, data.newPassword)
			.then((res) => {
				this.props.resetLoadingSpinner()
				if (res) {
					if (this.props.onUpdatePassword) this.props.onUpdatePassword(res)
					if (onSuccess) onSuccess()
					console.log("password changed!")
				} else {
					throw new Error("Error changing the password")
				}
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("error = ", err.message)
				if (this.props.onUpdatePassword) this.props.onUpdatePassword(false)
			})
		// if (this.props.onUpdatePassword) this.props.onUpdatePassword()
	}

	onClickCancel = () => {
		if (this.props.onCancel) this.props.onCancel()
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<PasswordUpdateUI
					hasCurrentPassword={this.state.hasCurrentPassword}
					onCancel={this.onClickCancel}
					onUpdatePassword={this.onClickUpdatePassword}
				/>
			</div>
		)
	}
}

PasswordUpdateComponent.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	hasCurrentPassword: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onUpdatePassword: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET })
	}
}

let extraStyle = (theme) => ({
	messageError: {
		margin: "5px 2px 5px 2px",
		color: "red",
		textAlign: "center"
	},
	messageSuccess: {
		margin: "5px 2px 5px 2px",
		color: "green",
		textAlign: "center"
	},
	sectorCustomInputStyle: {
		margin: "5px 5px 5px 5px",
		backgroundColor: "#000000"
	}
})

let finalStyle = combineStyles(userProfileStyles, extraStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(finalStyle)(PasswordUpdateComponent)))
