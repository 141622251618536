import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"

import CustomButton from "components/CustomButtons/Button.jsx"
import solutionServices from "dataServices/solutionServices"
import evaluationTeamServices from "dataServices/evaluationTeamServices"
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"

import _ from "lodash"

import ErrorPage from "views/Pages/ErrorPage"
import SimpleBreadcrumbs from "../../../components/SimpleBreadcumbs.jsx"

import commonStyles from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import combineStyles from "utils/combineStyle"

import validator from "validator"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../../helper"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import manageChallengeServices from "dataServices/manageChallengeServices"
import ManageChallengeMenu from "../ManageChallengeMenu.jsx"
import BeforeUnloadComponent from "react-beforeunload-component"
import PopupModal from "views/Components/PopupModal"

import { v4 as uuidv4 } from "uuid"
import Board, { createTranslate } from "react-trello"
import LaneHeaderCustom from "./KanbanBoardComponents/LaneHeaderCustom"
import "./react-trello.css"

const TEXTS = {
	"Add another lane": "Add new Team",
	"Click to add card": "Click to add card",
	"Delete lane": "Delete Team",
	"Lane actions": "Options/Actions",
	button: {
		"Add lane": "Add Team",
		"Add card": "Add Card",
		Cancel: "Cancel"
	},
	placeholder: {
		title: "title",
		description: "description",
		label: "label"
	}
}

const tagStyleSubmissions = {
	fontSize: "12px",
	width: "70px",
	height: "20px",
	color: "yellow",
	backgroundColor: "green"
}

const tagStyleEvaluators = {
	fontSize: "12px",
	width: "60px",
	height: "20px",
	color: "green",
	backgroundColor: "yellow"
}

const cardTypeOrderMap = {
	evaluator: 1,
	divider: 2,
	submission: 3
}

class ChallengeEvaluatorsPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			challengeId: "",
			urlSlug: "",
			challenge: null,
			canViewThisPage: false,
			currentUser: null,

			boardData: { lanes: [] },
			submissionsLaneId: "",
			evaluatorsLaneId: "",
			isApiCallInProgress: false,

			allDataFetched: false,
			haveSubmissions: false,
			haveEvaluators: false,
			isChanged: false
		}
	}

	fetchCurrentUserProfile = () => {
		return userServices
			.getCurrentUser()
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log("Error getting current User", err)
				return null
			})
	}

	getSolutionsByChallengeId(challengeId) {
		return solutionServices
			.getSolutionsByChallengeId(challengeId)
			.then((solutions) => {
				if (!solutions) {
					throw new Error("Error getting solutions")
				}
				solutions = solutions.filter((item) => item.solutionDetails.officialSubmission)
				solutions = solutions.map((solution) => {
					let submissionCard = {
						id: uuidv4(),
						solutionId: solution.solutionId,
						type: "submission",
						title: solution.solutionDetails.title || "Form Submission",
						description: solution.solutionDetails.teamName || solution.solutionDetails.userName,
						label: "",
						tags: [
							{
								title: "submission",
								style: tagStyleSubmissions
							}
						]
					}
					return submissionCard
				})
				return solutions
			})
			.catch((err) => {
				console.log("Error getting solutions", err.message)
				return []
			})
	}

	fetchEvaluators = (challengeId) => {
		return manageChallengeServices
			.fetchInvitations(challengeId)
			.then((res) => {
				if (!res || !res.invitations) {
					throw new Error("Error in getting evaluators")
				}
				let evaluators = res.invitations.filter((invitation) => invitation.role === "evaluator")
				evaluators = evaluators.map((evaluator) => {
					let evaluatorCard = {
						id: uuidv4(),
						invitationId: evaluator.invitationId,
						userId: evaluator.userId,
						type: "evaluator",
						title: evaluator.name,
						description: evaluator.email,
						label: "",
						tags: [
							{
								title: "evaluator",
								style: tagStyleEvaluators
							}
						]
					}
					return evaluatorCard
				})
				return evaluators
			})
			.catch((err) => {
				console.log("Error fetching evaluators, err =", err.message)
				return []
			})
	}

	getChallengeById = (challengeIdOrUrlSlug) => {
		return challengeServices
			.getChallengeById(challengeIdOrUrlSlug)
			.then((data) => {
				if (!data) {
					throw new Error("Challenge is not found")
				}
				return data
			})
			.catch((err) => {
				console.log("err = ", err.message)
				return null
			})
	}

	getEvaluationTeamsForChallenge = (challengeId) => {
		return evaluationTeamServices
			.getEvaluationTeamsForChallenge(challengeId)
			.then((evaluationTeams) => {
				if (!evaluationTeams) {
					throw new Error("Error fetching evaluation teams")
				}
				return evaluationTeams
			})
			.catch((err) => {
				console.log("Error fetching evaluationTeams, err =", err.message)
				return []
			})
	}

	fetchAllData = (challengeId) => {
		return Promise.all([
			this.fetchEvaluators(challengeId),
			this.getSolutionsByChallengeId(challengeId),
			this.getEvaluationTeamsForChallenge(challengeId)
		])
			.then((result) => {
				let evaluators = result[0]
				let submissions = result[1]
				let evaluationTeams = result[2]

				if (!evaluators || !evaluators.length) {
					this.setState({ haveEvaluators: false })
					throw new Error("No Evaluators are invited to the challenge")
				} else {
					this.setState({ haveEvaluators: true })
				}

				if (!submissions || !submissions.length) {
					this.setState({ haveSubmissions: false })
					throw new Error("No solvers/teams has made any submissions")
				} else {
					this.setState({ haveSubmissions: true })
				}

				if (!evaluationTeams) {
					evaluationTeams = []
				}

				let submissionIdMap = new Map()
				let invitationIdMap = new Map()
				submissions.map((submission) => {
					submissionIdMap.set(submission.solutionId, true)
				})
				evaluators.map((evaluator) => {
					invitationIdMap.set(evaluator.invitationId, true)
				})

				let teamLanes = []
				evaluationTeams.map((evaluationTeam) => {
					let teamLane = {
						id: uuidv4(),
						teamId: evaluationTeam.id,
						title: evaluationTeam.evaluationTeamDetails.teamName,
						label: "",
						editLaneTitle: true,
						disallowAddingCard: true,
						style: {
							width: 280
						},
						cards: []
					}
					let cards = []

					evaluationTeam.evaluationTeamDetails.solutions.map((solutionId) => {
						if (submissionIdMap.has(solutionId) && submissionIdMap.get(solutionId)) {
							cards.push(submissions.find((submission) => submission.solutionId === solutionId))
							submissionIdMap.delete(solutionId)
						}
					})

					evaluationTeam.evaluationTeamDetails.members.map((member) => {
						if (
							invitationIdMap.has(member.invitationId) &&
							invitationIdMap.get(member.invitationId)
						) {
							cards.push(
								evaluators.find((evaluator) => evaluator.invitationId == member.invitationId)
							)
							invitationIdMap.delete(member.invitationId)
						}
					})

					teamLane.cards = [...cards]
					teamLanes.push(teamLane)
				})

				let submissionsLaneId = uuidv4()
				let evaluatorsLaneId = uuidv4()
				let submissionsLane = {
					id: submissionsLaneId,
					title: "Submissions",
					label: "",
					editLaneTitle: false,
					disallowAddingCard: true,
					style: {
						width: 280
					},
					cards: []
				}
				let evaluatorsLane = {
					id: evaluatorsLaneId,
					title: "Evaluators",
					editLaneTitle: false,
					disallowAddingCard: true,
					label: "",
					style: {
						width: 280
					},
					cards: []
				}

				submissions.map((submission) => {
					if (
						submissionIdMap.has(submission.solutionId) &&
						submissionIdMap.get(submission.solutionId)
					) {
						submissionsLane.cards.push(submission)
						submissionIdMap.delete(submission.solutionId)
					}
				})

				evaluators.map((evaluator) => {
					if (
						invitationIdMap.has(evaluator.invitationId) &&
						invitationIdMap.get(evaluator.invitationId)
					) {
						evaluatorsLane.cards.push(evaluator)
						submissionIdMap.delete(evaluator.invitationId)
					}
				})

				let boardData = {
					lanes: [submissionsLane, evaluatorsLane, ...teamLanes]
				}

				this.setState({
					boardData: boardData,
					submissionsLaneId: submissionsLaneId,
					evaluatorsLaneId: evaluatorsLaneId
				})
				return true
			})
			.catch((err) => {
				console.log("Error getting all data, err =", err.message)
				return false
			})
	}

	componentDidMount() {
		this.props.setLoadingSpinner()
		const {
			match: { params }
		} = this.props

		this.props.pushBreadCrumbStack({
			name: "Challenge Evaluators",
			link: this.props.location.pathname
		})

		if (validator.isUUID(params.challengeId)) {
			this.setState({ challengeId: params.challengeId })
		}

		this.props.setLoadingSpinner()
		Promise.all([this.getChallengeById(params.challengeId), this.fetchCurrentUserProfile()])
			.then((result) => {
				let challenge = result[0]
				let user = result[1]

				if (!user) {
					throw new Error("You are not authorized, please login to contunue")
				}

				if (!challenge) {
					throw new Error("Challenge is not found")
				}

				this.setState({
					challenge: challenge,
					challengeId: challenge.challengeId,
					urlSlug: challenge.urlSlug,
					currentUser: user
				})

				let adminRole = challenge.roles.find(
					(role) =>
						role.role === "superAdmin" ||
						role.role === "licenseeAdmin" ||
						role.role === "challengeAdmin"
				)
				if (!adminRole) {
					throw new Error("You do not have permissions to access this page")
				}

				this.setState({ canViewThisPage: true })
				return challenge.challengeId
			})
			.then((challengeId) => {
				return this.fetchAllData(challengeId)
			})
			.then((status) => {
				this.setState({ allDataFetched: true })
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error: ", err.message)
				this.props.setRedirectOnError("/dashboard/index")
			})
	}

	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	isTeamsValid = () => {
		let valid = true

		let boardData = this.state.boardData
		boardData.lanes.map((lane) => {
			if (!(lane.id === this.state.submissionsLaneId || lane.id === this.state.evaluatorsLaneId)) {
				let submissionsCount = 0
				let evaluatorsCount = 0

				let cards = lane.cards || []
				cards.map((card) => {
					if (card.type === "submission") {
						submissionsCount++
					} else if (card.type === "evaluator") {
						evaluatorsCount++
					}
				})

				if (!(submissionsCount && evaluatorsCount)) {
					valid = false
				}

				let teamName = lane.title || ""
				teamName = teamName.trim()
				if (!teamName || teamName === "") {
					valid = false
				}
			}
		})

		return valid
	}

	assignEvaluators = () => {
		let evaluationTeams = this.state.boardData.lanes.filter(
			(lane) => lane.id !== this.state.submissionsLaneId && lane.id !== this.state.evaluatorsLaneId
		)

		this.props.setLoadingSpinner()
		this.setState({ isApiCallInProgress: true })

		Promise.all(
			evaluationTeams.map((evaluationTeam) => {
				let invitationIds = []
				let submissionsIds = []
				let cards = evaluationTeam.cards || []
				cards.map((card) => {
					if (card.type === "evaluator") {
						invitationIds.push(card.invitationId)
					} else if (card.type === "submission") {
						submissionsIds.push(card.solutionId)
					}
				})

				return evaluationTeamServices
					.createOrUpdateEvaluationTeam(
						this.state.challengeId,
						evaluationTeam.title,
						invitationIds,
						submissionsIds,
						evaluationTeam.teamId || null
					)
					.then((responseData) => {
						return true
					})
					.catch((err) => {
						console.log("Error creating evaluation team: ", err.message)
						return false
					})
			})
		)
			.then((result) => {
				return this.fetchAllData(this.state.challengeId)
			})
			.then((status) => {
				this.setState({ isChanged: false, isApiCallInProgress: false })
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("error occurred saving evaluation teams, err =", err.message)
				this.props.resetLoadingSpinner()
				this.setState({ isApiCallInProgress: false })
				this.props.showAlert("error", "Oops!", err.message)
			})
	}

	deleteEvaluationTeam = (id) => {
		let lanePosition = this.state.boardData.lanes.length - 1

		let deletingTeam = this.state.boardData.lanes.find((lane, index) => {
			if (lane.id === id) {
				lanePosition = index
				return true
			}
			return false
		})

		if (!deletingTeam.teamId || !deletingTeam.teamId === "") {
			this.placeCardsToRespectiveLaneAfterDeletingTeam(deletingTeam)
		} else {
			this.setState({ isApiCallInProgress: true })
			this.props.setLoadingSpinner()
			evaluationTeamServices
				.deleteEvaluationTeam(deletingTeam.teamId)
				.then((res) => {
					this.props.resetLoadingSpinner()
					this.placeCardsToRespectiveLaneAfterDeletingTeam(deletingTeam)
					this.setState({ isApiCallInProgress: false })
				})
				.catch((err) => {
					console.log("Error deleting the team, restoring the team, err =", err.message)
					this.props.resetLoadingSpinner()
					this.props.showAlert("error", "Oops!", err.message)
					this.restoreTeamAfterFailToDelete(deletingTeam, lanePosition)
					this.setState(
						{
							isApiCallInProgress: false,
							canViewThisPage: false //force re-render on after restoring the team
						},
						() => {
							this.setState({ canViewThisPage: true }) //force re-render on after restoring the team
						}
					)
				})
		}
	}

	placeCardsToRespectiveLaneAfterDeletingTeam = (deletingTeam) => {
		let boardData = this.state.boardData
		let submissionsLane = boardData.lanes.find((lane) => lane.id === this.state.submissionsLaneId)
		let evaluatorsLane = boardData.lanes.find((lane) => lane.id === this.state.evaluatorsLaneId)

		let cards = deletingTeam.cards || []
		cards.map((card) => {
			if (card.type === "evaluator") {
				evaluatorsLane.cards.push(card)
			} else if (card.type === "submission") {
				submissionsLane.cards.push(card)
			}
		})

		boardData.lanes = boardData.lanes.map((lane) => {
			if (lane.id === this.state.submissionsLaneId) {
				return submissionsLane
			} else if (lane.id === this.state.evaluatorsLaneId) {
				return evaluatorsLane
			} else {
				return lane
			}
		})

		boardData = this.applyCommonPropertiesAndCardSorting(boardData)
		this.setState({ boardData: boardData })
	}

	restoreTeamAfterFailToDelete = (deletingTeam, previousPosition) => {
		let boardData = this.state.boardData
		let lanes = boardData.lanes.filter((lane) => lane.id !== deletingTeam.id)
		let newLanes = [
			...lanes.slice(0, previousPosition),
			deletingTeam,
			...lanes.slice(previousPosition)
		]

		boardData.lanes = [...newLanes]
		boardData = this.applyCommonPropertiesAndCardSorting(boardData)

		this.setState({ boardData: boardData })
	}

	handleOnTeamUpdate = (id, data) => {
		this.setState({ isChanged: true })
	}

	handleDragEnd = (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
		if (this.state.isApiCallInProgress) {
			return false
		}

		if (cardDetails.type === "evaluator" && targetLaneId === this.state.submissionsLaneId) {
			return false
		} else if (cardDetails.type === "submission" && targetLaneId === this.state.evaluatorsLaneId) {
			return false
		} else if (cardDetails.type === "divider") {
			return false
		}
		if (sourceLaneId !== targetLaneId) {
			this.setState({ isChanged: true })
		}
		return true
	}

	sortingCompareFunc = (card1, card2) => {
		if (cardTypeOrderMap[card1.type] < cardTypeOrderMap[card2.type]) {
			return -1
		} else if (cardTypeOrderMap[card1.type] > cardTypeOrderMap[card2.type]) {
			return 1
		} else {
			if (card1.title < card2.title) {
				return -1
			} else {
				return 1
			}
		}
	}

	onDataChange = (newData) => {
		let boardData = this.applyCommonPropertiesAndCardSorting(newData)
		this.setState({ boardData: boardData })
	}

	applyCommonPropertiesAndCardSorting = (boardData) => {
		let lanes = [...boardData.lanes]
		lanes = lanes.map((lane, index) => {
			lane.disallowAddingCard = true
			if (lane.id === this.state.submissionsLaneId || lane.id === this.state.evaluatorsLaneId) {
				lane.editLaneTitle = false
			} else {
				lane.editLaneTitle = true
			}
			let cards = lane.cards || []
			cards.sort(this.sortingCompareFunc)
			lane.cards = [...cards]
			return lane
		})
		return { lanes: lanes }
	}

	render() {
		const { classes } = this.props
		var title = "Challenge Evaluators | BestInCrowd"
		helper.setPageTitle(title)

		return (
			<div>
				{this.state.allDataFetched && this.state.canViewThisPage ? (
					<div>
						<GridContainer direction="row" justify="space-between">
							<GridItem xs={9} sm={10} md={10} lg={11}>
								<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
							</GridItem>
							<GridItem className={classes.right} xs={3} sm={2} md={2} lg={1}>
								<ManageChallengeMenu
									challengeId={this.state.challengeId}
									challenge={this.state.challenge}
									urlSlug={this.state.urlSlug}
									history={this.props.history}
								/>
							</GridItem>
						</GridContainer>
						<br />
						<SnackbarContent
							message={this.state.challenge.challengeDetails.phaseTitle}
							color="success"
						/>

						<div>
							{this.state.haveEvaluators && this.state.haveSubmissions ? (
								<GridContainer direction="row" justify="flex-end" alignItems="center">
									<GridItem>
										<CustomButton
											size="lg"
											color="info"
											disabled={!(this.isTeamsValid() && this.state.isChanged)}
											onClick={() => {
												this.assignEvaluators()
											}}
										>
											Save
										</CustomButton>
									</GridItem>
								</GridContainer>
							) : null}

							{this.state.haveEvaluators && this.state.haveSubmissions ? (
								<div style={{ margin: "10px 0px 10px 0px", padding: "5px 0px" }}>
									<BeforeUnloadComponent
										blockRoute={this.state.isChanged}
										ignoreChildrenLinks={true}
										modalComponentHandler={({ handleModalLeave, handleModalCancel }) => {
											return (
												<PopupModal
													popupHeaderText="Leaving the page?"
													popupMessageText="Changes you made may not be saved."
													onCancel={handleModalCancel}
													onSubmit={handleModalLeave}
													yesButtonText="Leave"
													cancelButtonText="Cancel"
												/>
											)
										}}
									>
										<Board
											t={createTranslate(TEXTS)}
											editable
											data={this.state.boardData}
											hideCardDeleteIcon
											canAddLanes
											disallowAddingCard={false}
											draggable
											addLaneTitle="New team"
											handleDragEnd={this.handleDragEnd}
											onDataChange={this.onDataChange}
											onLaneUpdate={this.handleOnTeamUpdate}
											components={{
												LaneHeader: LaneHeaderCustom
											}}
											onLaneDelete={this.deleteEvaluationTeam}
										/>
									</BeforeUnloadComponent>
								</div>
							) : (
								<div>
									{this.state.haveEvaluators
										? "No submissions are made yet. Please come back later."
										: "No evaluators are invited yet. Please invite evaluators from Manage People page to see this page in action"}
								</div>
							)}
						</div>
					</div>
				) : null}
			</div>
		)
	}
}

ChallengeEvaluatorsPage.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({ type: actionTypes.SHOW_ALERT, payload: { alertType, title, description } }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(commonStyles)(ChallengeEvaluatorsPage))
