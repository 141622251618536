import React from "react"
import { withStyles } from "@material-ui/core/styles"
import PropTypes from "prop-types"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"
import ReactTable from "react-table"
import Accordion from "components/Accordion/Accordion"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Constants from "constants.js"
import authentication from "authentication.js"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import aboutPageServices from "dataServices/aboutPageServices.js"
import CardFooter from "components/Card/CardFooter"
import { Typography, ListItemIcon } from "@material-ui/core"
import userServices from "dataServices/userServices.js"
import { Redirect, NavLink } from "react-router-dom"
import CardAvatar from "components/Card/CardAvatar"
import emptyAvatar from "assets/img/faces/emptyProfilePicture.webp"
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx"
import LaunchIcon from "@material-ui/icons/Launch"
import teamServices from "dataServices/teamServices"

let solversPerPageLimit = 10

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	textOverflowEllipsisStyle: {
		overflowWrap: "break-word",
		height: "auto",
		overflow: "hidden",
		textOverflow: "ellipsis"
		// whiteSpace: "nowrap",
		// MaxWidth: "inherit",
		// overflow: "hidden",
		// textOverflow: "ellipsis"
	},
	textOverflowEllipsisStyleForOrganizationNameOnly: {
		overflowWrap: "break-word",
		height: "45px",
		overflow: "hidden",
		textOverflow: "ellipsis"
		// whiteSpace: "nowrap",
		// MaxWidth: "inherit",
		// overflow: "hidden",
		// textOverflow: "ellipsis"
	},
	textOverflowEllipsisStyleForUserNameOnly: {
		overflowWrap: "break-word",
		height: "65px",
		overflow: "hidden",
		textOverflow: "ellipsis"
	},
	cardHeaderStyle: {},
	activeChallengesLinkStyle: {
		"&:hover,&:focus": {
			color: "#A45AB1"
		}
	},
	AccordianTextOverflowEllipsisStyle: {
		overflowWrap: "normal",
		MaxWidth: "inherit",
		overflow: "hidden",
		textOverflow: "ellipsis",
		"&:hover, &:focus": {}
	},
	activeChallengesListIconVerticalBar: {
		borderLeft: "5px solid cyan",
		padding: "5px 10px 5px 10px"
	},
	activeChallengesAccordionStyle: {
		width: "100%",
		margin: "0% 0% 0% 0%"
	},
	solversPageDarkBackgroundStyle: {
		backgroundColor: "#302f2f"
	},
	buttonSuccessColor: {
		backgroundColor: "#1eb980",
		"&:hover,&:focus": {
			backgroundColor: "#1eb980",
			boxShadow: "0px 6px 14px rgba(30, 185, 128, .3)"
		}
	},
	whiteTextColorStyle: {
		color: "#FFFFFF"
	},
	...userProfileStyles
})

class Solvers extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			solverList: [],
			loadMore: true,
			solversLimit: solversPerPageLimit,
			totalSolver: 0,
			dataFetchDone: false
		}
	}

	loadMoreSolvers = () => {
		this.setState(
			{
				solversLimit: this.state.solversLimit + solversPerPageLimit
			},
			() => {
				this.fetchSolvers().then((solversData) => {
					let loadMore = true
					if (solversData.data.length == solversData.totalCount) {
						loadMore = false
					}
					let totalSolver = solversData.data.length
					this.setState({
						solverList: solversData.data,
						totalSolver: totalSolver,
						loadMore: loadMore
					})
				})
			}
		)
	}

	fetchSolvers = () => {
		this.props.setLoadingSpinner()
		return userServices
			.solvers(1, this.state.solversLimit, this.props.challengeId)
			.then((data) => {
				this.props.resetLoadingSpinner()
				return data ? data : []
			})
			.catch((err) => {
				console.log("Error = ", err.message)
				this.props.resetLoadingSpinner()
				this.props.setRedirectOnError("/dashboard/index")
				return []
			})
	}

	fetchTeamMembers = (teamId) => {
		console.log("Lets fetch the team members, teamId =", teamId)
		this.props.setLoadingSpinner()
		return teamServices
			.getTeam(teamId)
			.then((data) => {
				console.log("team data = ", data)
				if (!data || !data.teamMembers) throw new Error("Error getting team information")
				this.setState({
					teamName: data.teamName,
					teamAvatar: data.teamAvatar,
					teamDescription: data.teamDescription,
					teamAffiliation: data.teamAffiliation
				})
				let users = data.teamMembers.filter((tm) => tm.status != "PENDING").map((tm) => tm.userId)
				console.log("user ids in team = ", users)
				return Promise.all(
					users.map((user) => {
						return userServices
							.getUserProfileById(user)
							.then((userData) => {
								if (!userData) throw new Error("User not found")
								return userData
							})
							.catch((err) => {
								console.log("Error getting user, err =", err.message)
								return null
							})
					})
				).then((result) => {
					console.log("promise all result = ", result)
					result = result.filter((r) => r != null)
					this.props.resetLoadingSpinner()
					return result
				})
			})
			.catch((err) => {
				console.log("Error fetting team, err= ", err.message)
				this.props.resetLoadingSpinner()
				return []
			})
	}

	componentDidMount() {
		console.log("Component Did Mount of Solvers Page: .. ", this.props.challengeId)
		if (this.props.viewAs == "team" && this.props.teamId) {
			this.setState({ teamId: this.props.teamId })
			this.fetchTeamMembers(this.props.teamId).then((data) => {
				console.log("team members data fetched, data= ", data)
				this.setState({
					solverList: data,
					totalSolver: data.length,
					dataFetchDone: true
				})
			})
		} else {
			console.log("fetch solvers")
			this.fetchSolvers().then((data) => {
				console.log("solvers data = ", data)
				if (!data || !data.data) {
					this.setState({ solverList: [], totalSolver: 0, dataFetchDone: true, loadMore: false })
				} else {
					let loadMore = true
					if (data.data.length == data.totalCount) loadMore = false
					this.setState({
						solverList: data.data,
						totalSolver: data.data.length,
						dataFetchDone: true,
						loadMore: loadMore
					})
				}
			})
		}
	}

	renderActiveChallenges = (activeChallenges) => {
		const { classes } = this.props
		if (activeChallenges) {
			return activeChallenges.map((challenge) => {
				return (
					<ListItem>
						<ListItemIcon>
							<LaunchIcon />
						</ListItemIcon>
						<ListItemText
							primary={
								this.props.loggedInUserData ? (
									<NavLink to={"/dashboard/challenge-details/" + challenge.urlSlug}>
										<Typography
											className={classes.activeChallengesLinkStyle}
											variant="body2"
											color="textPrimary"
											align="left"
										>
											{challenge.title}
										</Typography>
									</NavLink>
								) : (
									<NavLink to={"/home/challenge-details/" + challenge.urlSlug}>
										<Typography
											className={classes.activeChallengesLinkStyle}
											variant="body2"
											color="textPrimary"
											align="left"
										>
											{challenge.title}
										</Typography>
									</NavLink>
								)
							}
						/>
					</ListItem>
				)
			})
		} else return <li key={1}> Not available </li>
	}

	renderSolver = (solver, key) => {
		var xs = 12,
			sm = 6,
			md = 4,
			lg = 3
		const { classes } = this.props
		if (!solver) return null
		return (
			<GridItem key={key} xs={xs} sm={sm} md={md} lg={lg}>
				<Card profile raised={true} color="gmgTheme">
					<CardAvatar profile style={{ height: "130px" }}>
						<a href="#pablo" onClick={(e) => e.preventDefault()}>
							{solver.avatarImagePath ? (
								<img
									src={Constants.Settings.APIURL + solver.avatarImagePath}
									alt="Image of user's profile avatar"
									onError={(event) => {
										event.target.src = emptyAvatar
									}}
								/>
							) : (
								<img src={emptyAvatar} alt="Image of user's profile avatar default" />
							)}
						</a>
					</CardAvatar>
					<CardBody profile>
						<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
							Username: {solver.username}
						</h6>
						<h3
							className={`${classes.cardTitle} ${classes.textOverflowEllipsisStyleForUserNameOnly}`}
						>
							{solver.name}
						</h3>
						<Divider variant="middle" />
						{solver.organizationName ? (
							<h6
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForOrganizationNameOnly}`}
							>
								Organization Name: {solver.organizationName}
							</h6>
						) : (
							<h6
								className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyleForOrganizationNameOnly}`}
							>
								Organization Name: Not Available
							</h6>
						)}
						<Divider variant="middle" />
						{solver.state ? (
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								State: {solver.state}
							</h6>
						) : (
							<h6 className={`${classes.cardCategory} ${classes.textOverflowEllipsisStyle}`}>
								State: Not Available
							</h6>
						)}
						{!this.props.challengeId ? (
							<div className={`${classes.AccordianTextOverflowEllipsisStyle}`}>
								<Accordion
									collapses={[
										{
											title: (
												<Typography variant="body2" color="default">
													Active Challenges
												</Typography>
											),
											content: (
												<div
													style={{
														backgroundColor: "#ccc1c1",
														borderRadius: "5px",
														width: "100%"
													}}
												>
													<List>{this.renderActiveChallenges(solver.activeChallenges)}</List>
												</div>
											)
										}
									]}
								/>
							</div>
						) : null}
					</CardBody>
				</Card>
			</GridItem>
		)
	}

	renderSolversView = (solverList) => {
		const { classes } = this.props
		if (solverList) {
			if ((!solverList || !solverList.length) && this.state.dataFetchDone) {
				return (
					<div style={{ margin: "30px auto" }}>
						<GridContainer justify="center" alignItems="flex-start">
							<GridItem>
								{this.props.challengeId ? (
									<Typography className={classes.whiteTextColorStyle}>
										<div style={{ textTransform: "capitalize" }}>No solver Found </div>
									</Typography>
								) : (
									<Typography>
										<div style={{ textTransform: "capitalize" }}>No solver Found </div>
									</Typography>
								)}
							</GridItem>
						</GridContainer>
					</div>
				)
			}
			let view = solverList.map((solver, key) => {
				return this.renderSolver(solver, key)
			})
			return (
				<GridContainer
					container
					direction="row"
					justify="space-evenly"
					alignItems="flex-start"
					spacing={3}
				>
					{view}
				</GridContainer>
			)
		}
	}

	render() {
		var tabName = "Solvers"
		if (this.props.viewAs == "team" && this.state.teamName) tabName = "Team: " + this.state.teamName
		const { classes } = this.props

		return (
			<div style={{ marginTop: "30px" }}>
				<GridContainer direction="row" alignItems="flex-start">
					<GridItem xs={12} sm={12}>
						<GridContainer alignItems="center" justify="space-between" direction="row">
							{this.state.solverList && this.state.solverList.length && this.props.challengeId ? (
								<GridItem xs={12} sm={6}>
									<h4 className={classes.whiteTextColorStyle}>
										<strong>{tabName}</strong>
									</h4>
								</GridItem>
							) : this.state.solverList && this.state.solverList.length ? (
								<GridItem xs={12} sm={6}>
									<h4>
										<strong>{tabName}</strong>
									</h4>
								</GridItem>
							) : null}

							<GridItem xs={12} sm={6}>
								{this.state.solverList && this.state.solverList.length && this.props.challengeId ? (
									<div
										style={{
											textAlign: "right",
											fontWeight: "bold",
											color: "#FFFFFF"
										}}
									>
										Currently showing: {1} - {this.state.solverList.length}
									</div>
								) : this.state.solverList && this.state.solverList.length ? (
									<div style={{ textAlign: "right", fontWeight: "bold", marginBottom: "40px" }}>
										Currently showing: {1} - {this.state.solverList.length}
									</div>
								) : null}
							</GridItem>
						</GridContainer>

						{this.renderSolversView(this.state.solverList)}

						<GridContainer container alignItems="center" justify="center" direction="row">
							{this.state.loadMore && (
								<GridItem>
									{this.state.dataFetchDone && (
										<Button
											className={classes.buttonSuccessColor}
											disabled={!this.state.loadMore}
											onClick={this.loadMoreSolvers}
										>
											Load more
										</Button>
									)}
								</GridItem>
							)}
						</GridContainer>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

Solvers.propTypes = {
	classes: PropTypes.object.isRequired,
	viewAs: PropTypes.string.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Solvers))
