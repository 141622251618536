import apolloFetcher from "apolloFetcher";
import { get } from "lodash";

let users = {}

users.getUsers = (filters, page=0, itemPerPage=20) => {
  let query = `query users($page: Int, $limit: Int, $name: String!, $email: String!){
      users(
        page: $page
        limit: $limit
        name: $name
        email: $email
      ){
        message
        data{
          name
          username
          userId
          email
          avatarImagePath
          roles {
            RoleId
            challengeId
            teamId
            teamLead
          }
        }
        totalCount
      }
    }
  `
	const variables = {
		page: page,
		limit: itemPerPage,
    name: filters.name,
    email: filters.email,
	};

  return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!get(res, "data.users", null)){
					throw new Error("Error occurred while fetching solvers");
        }

				resolve(get(res, "data.users"));
			})
			.catch((err) => {
				reject(err);
			})
	})
};

users.getCurrentUser = (dataField) => {
	let query = `
    query{
      getCurrentUser{
        status
        message
        data{
          userId
          username
          email
          emailInvalid
          otherEmails{
            email
            isVerified
            isPrimary
          }
          name
          avatarImagePath
          roles {
            RoleId
            challengeId
            teamId
            teamLead
          }
          address
          city
          state
          country
          postalCode
          citizenship
          aboutMe
          sectorOfOrganisation
          usersRoleInOrganisation
          challengeExperienceTitle
          challengeExperienceProblemSolved
          challengeExperienceMethodUsed
        }
        totalCount
      }
    }
  `

	if (dataField) {
		query = `query{
      getCurrentUser{
        status
        message
        data{
          ${dataField}
        }
        totalCount
      }
    }
    `
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.getCurrentUser)
					throw new Error("Error occured while fetching current user")
				if (!res.data.getCurrentUser.status) throw new Error(res.data.getCurrentUser.message)
				resolve(res.data.getCurrentUser.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.solvers = (pageNo = 1, itemPerPage = 10, challengeId, searchText = "") => {
	let query = `
    query solvers($pageNo: Int, $itemPerPage: Int, $challengeId: String, $searchText: String){
      solvers(
        pageNo: $pageNo
        itemPerPage: $itemPerPage
        challengeId: $challengeId
        searchText: $searchText
      ){
        status
        message
        totalCount
        data{
          data{
            name
            username
            userId
            email
            avatarImagePath
            activeChallenges{
              challengeId
              urlSlug
              title
            }
            organizationName
            state
            isApproved
          }
          totalCount
        }
      }
    }
  `

	let variables = {
		pageNo: pageNo,
		itemPerPage: itemPerPage,
		challengeId: challengeId,
		searchText
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.solvers)
					throw new Error("Error occurred while fetching solvers")
				if (!res.data.solvers.status) throw new Error(res.data.solvers.message)
				resolve(res.data.solvers.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.getAllsolversByChallengeId = (challengeId) => {
	let query = `
    query getAllsolversByChallengeId($challengeId: String){
      getAllsolversByChallengeId(
        challengeId: $challengeId
      ){
        status
        message
        totalCount
        data{
          data{
            name
            username
            avatarImagePath
            activeChallenges{
              challengeId
              urlSlug
              title
            }
            organizationName
            state
          }
          totalCount
        }
      }
    }
  `

	let variables = {
		challengeId: challengeId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getAllsolversByChallengeId)
					throw new Error("Error occured while fetching getAllsolversByChallengeId")
				if (!res.data.getAllsolversByChallengeId.status)
					throw new Error(res.data.getAllsolversByChallengeId.message)
				resolve(res.data.getAllsolversByChallengeId.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.updateUser = (variables) => {
	let query = `
    mutation updateUser(
            $name: String
            $email: String
            $avatarImagePath: String
            $address: String
            $city: String
            $state: String
            $country: String
            $postalCode: String
            $citizenship: String
            $aboutMe: String
            $sectorOfOrganisation: String
            $usersRoleInOrganisation: String
            $challengeExperienceTitle: String
            $challengeExperienceProblemSolved: String
            $challengeExperienceMethodUsed: String
            $removeProfileAvatar: Boolean
          ) {
            updateUser(
              userData: {
                name: $name
                email: $email
                avatarImagePath: $avatarImagePath
                address: $address
                city: $city
                state: $state
                country: $country
                postalCode: $postalCode
                citizenship: $citizenship
                aboutMe: $aboutMe
                sectorOfOrganisation: $sectorOfOrganisation
                usersRoleInOrganisation: $usersRoleInOrganisation
                challengeExperienceTitle: $challengeExperienceTitle
                challengeExperienceProblemSolved: $challengeExperienceProblemSolved 
                challengeExperienceMethodUsed: $challengeExperienceMethodUsed
                removeProfileAvatar: $removeProfileAvatar
              }
            ) {
              status
              message
              data {
                userId
                name
                username
                email
                avatarImagePath
                address
                city
                state
                country
                postalCode
                citizenship
                aboutMe
                sectorOfOrganisation
                usersRoleInOrganisation
                challengeExperienceTitle
                challengeExperienceProblemSolved
                challengeExperienceMethodUsed
              }
            }
          }
        `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.updateUser)
					throw new Error("Error occured while updating user profile")
				if (!res.data.updateUser.status) throw new Error(res.data.updateUser.message)
				resolve(res.data.updateUser.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.getUserProfileById = (userId, dataField) => {
	var query = `
    query user($userId: String!){
      user(
        userId: $userId
      ){
        status message
        data{ userId username email name avatarImagePath
          roles { RoleId challengeId teamId teamLead }
          address city state country postalCode citizenship aboutMe sectorOfOrganisation usersRoleInOrganisation
          challengeExperienceTitle challengeExperienceProblemSolved challengeExperienceMethodUsed
        }
        totalCount
      }
    }`

	if (dataField) {
		query = `query user($userId: String!){
      user(
        userId: $userId
      ){
        status message data{ ${dataField} } totalCount
      }
    }`
	}
	const variables = {
		userId: userId
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.user)
					throw new Error("Error occured while fetching current user")
				if (!res.data.user.status) throw new Error(res.data.user.message)
				resolve(res.data.user.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.searchUser = (searchText, dataField) => {
	var query = `
  query{
    userSearch(searchKey: "${searchText}"){
      status
      message
      data{ userId username email name avatarImagePath
        roles { RoleId challengeId teamId teamLead }
        address city state country postalCode citizenship aboutMe sectorOfOrganisation usersRoleInOrganisation
        challengeExperienceTitle challengeExperienceProblemSolved challengeExperienceMethodUsed
      }
      totalCount
    }
  }  
  `

	if (dataField) {
		query = `
    query{
      userSearch(searchKey: "${searchText}"){
        status
        message
        data{ ${dataField} }
        totalCount
      }
    }
    `
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.userSearch)
					throw new Error("Error occured while searching for user")
				if (!res.data.userSearch.status) throw new Error(res.data.userSearch.message)
				resolve(res.data.userSearch.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.searchUserForJoinTeam = (searchText, challengeId) => {
	var query = `
  query{
    searchUserForJoinTeam(searchKey: "${searchText}" challengeId: "${challengeId}"){
      status
      message
      data {
        users {
          userId
          username
          email
          name
          avatarImagePath
          roles {
            RoleId
            challengeId
            teamId
            teamLead
          }
          address
          city
          state
          country
          postalCode
          citizenship
          aboutMe
          sectorOfOrganisation
          usersRoleInOrganisation
          challengeExperienceTitle
          challengeExperienceProblemSolved
          challengeExperienceMethodUsed
          isAvailable
        }
        email
        safeForEmailInvite
      }
      totalCount
    }
  }  
  `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.searchUserForJoinTeam)
					throw new Error("Error occured while searching for user")
				if (!res.data.searchUserForJoinTeam.status)
					throw new Error(res.data.searchUserForJoinTeam.message)
				resolve(res.data.searchUserForJoinTeam.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.searchUserForManageChallenge = (searchText, challengeId) => {
	var query = `
  query{
    userSearchForManageChallenge(searchKey: "${searchText}" challengeId: "${challengeId}"){
      status
      message
      data {
        users {
          userId
          username
          email
          name
          avatarImagePath
          roles {
            RoleId
            challengeId
            teamId
            teamLead
          }
          address
          city
          state
          country
          postalCode
          citizenship
          aboutMe
          sectorOfOrganisation
          usersRoleInOrganisation
          challengeExperienceTitle
          challengeExperienceProblemSolved
          challengeExperienceMethodUsed
        }
        email
        safeForEmailInvite
      }
      totalCount
    }
  }  
  `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.userSearchForManageChallenge)
					throw new Error("Error occured while searching for user")
				if (!res.data.userSearchForManageChallenge.status)
					throw new Error(res.data.userSearchForManageChallenge.message)
				resolve(res.data.userSearchForManageChallenge.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.registerUser = (variables) => {
	let query = `
  mutation registerUser(
    $username: String!
    $password: String!
    $name: String!
    $email: String!
  ) {
    registerUser(
      username: $username
      password: $password
      name: $name
      email: $email
    ) {
      status
      message
      totalCount
      data {
        userId
        name
        username
        email
        roles {
          RoleId
        }
      }
    }
  }
  `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.registerUser)
					throw new Error("Error occured while registering user")
				if (!res.data.registerUser.status) throw new Error(res.data.registerUser.message)
				resolve(res.data.registerUser.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.authenticateUser = (variables) => {
	let query = `
  mutation authenticateUser($usernameOrEmail: String!, $password: String!) {
    authenticateUser(usernameOrEmail: $usernameOrEmail, password: $password) {
      status
      message
      totalCount
      data {
        access_token
        refresh_token
        id_token
        expires_in
        userName
        token_type
        api_key
        firstTimeLogin
        userId
      }
    }
  }
  `
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.authenticateUser)
					throw new Error("Error occured while registering user")
				if (!res.data.authenticateUser.status) throw new Error(res.data.authenticateUser.message)
				resolve(res.data.authenticateUser.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.login3rdPartySocial = (variables) => {
	let query = `
  mutation login3rdPartySocial($id: String! $email: String! $name: String! $pictureLink: String! $loginProvider: String!) {
    login3rdPartySocial(id: $id email: $email name: $name pictureLink: $pictureLink loginProvider: $loginProvider){
      status
      message
      data{
        access_token
        refresh_token
        id_token
        expires_in
        userName
        token_type
        api_key
        firstTimeLogin
        userId
      }
      totalCount
    }
  }
  `
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.login3rdPartySocial)
					throw new Error("Error occured while registering user")
				if (!res.data.login3rdPartySocial.status)
					throw new Error(res.data.login3rdPartySocial.message)
				resolve(res.data.login3rdPartySocial.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.reAuthenticateUser = (refresh_token) => {
	let query = `
    mutation reAuthenticateUser($refresh_token: String!){
      reAuthenticateUser(
        refresh_token:$refresh_token
      ){
        status
        message
        data{
          access_token
          id_token
          expires_in
        }
        totalCount
      }
    }
  `
	let variables = {
		refresh_token: refresh_token
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.reAuthenticateUser)
					throw new Error("Error occured while reauthenticating user")
				if (!res.data.reAuthenticateUser.status)
					throw new Error(res.data.reAuthenticateUser.message)
				resolve(res.data.reAuthenticateUser.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.logout = () => {
	let query = `
  query{
    logout{
      status
      message
      totalCount
      data
    }
  }
  `
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.logout)
					throw new Error("Error occured while registering user")
				if (!res.data.logout.status) throw new Error(res.data.logout.message)
				resolve(res.data.logout.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.hasPermission = (permissionName, resourceId) => {
	let query = `
  query hasPermission($permissionName: String!, $objectId: String){
    hasPermission(permissionName: $permissionName, objectId: $objectId){
      status
      message
      data
      totalCount
    }
  }
  `
	let variables = {
		permissionName: permissionName,
		objectId: resourceId
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.hasPermission)
					throw new Error("Error occured while checking permissions")
				if (!res.data.hasPermission.status) throw new Error(res.data.hasPermission.message)
				resolve(res.data.hasPermission.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.isEmailVerified = () => {
	let query = `
  query{
    isEmailVerified{
      status
      message
      data{
        email
        isVerified
      }
      totalCount
    }
  }
  `
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.isEmailVerified)
					throw new Error("Error occured while fetching email verification status")
				if (!res.data.isEmailVerified.status) throw new Error(res.data.isEmailVerified.message)
				resolve(res.data.isEmailVerified.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.sendEmailVerificationCode = () => {
	let query = `
  query {
    sendEmailVerificationCode {
      status
      message
      data {
        email
      }
      totalCount
    }
  }`
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.sendEmailVerificationCode)
					throw new Error("Error occured while sending email verification code")
				if (!res.data.sendEmailVerificationCode.status)
					throw new Error(res.data.sendEmailVerificationCode.message)
				resolve(res.data.sendEmailVerificationCode.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.verifyEmail = (code) => {
	let query = `
  mutation verifyEmail($code: String!) {
    verifyEmail(code: $code) {
      status
      message
    }
  }
  `
	let variables = { code: code }
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.verifyEmail)
					throw new Error("Error occured while verifying email")
				if (!res.data.verifyEmail.status) throw new Error(res.data.verifyEmail.message)
				resolve(res.data.verifyEmail.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.forgotPassword = (emailOrUsername) => {
	let query = `
  query  forgotPassword($emailOrUsername: String!){
    forgotPassword(emailOrUsername: $emailOrUsername) {
      status
      message
      data {
        email
        username
      }
      totalCount
    }
  }
  `
	let variables = { emailOrUsername: emailOrUsername }
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.forgotPassword)
					throw new Error("Error occured while verifying email")
				if (!res.data.forgotPassword.status) throw new Error(res.data.forgotPassword.message)
				resolve(res.data.forgotPassword.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.confirmForgotResetPassword = (resetCode, username, password) => {
	let query = `
  mutation confirmForgotResetPassword(
    $username: String!
    $password: String!
    $resetCode: String!
  ) {
    confirmForgotResetPassword(
      username: $username
      password: $password
      resetCode: $resetCode
    ) {
      status
      message
    }
  }
  `
	let variables = {
		resetCode: resetCode,
		username: username,
		password: password
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.confirmForgotResetPassword)
					throw new Error("Error occured reseting password")
				if (!res.data.confirmForgotResetPassword.status)
					throw new Error(res.data.confirmForgotResetPassword.message)
				resolve(res.data.confirmForgotResetPassword.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.changePassword = (currentPassword, newPassword) => {
	let query = `
  mutation changePassword($oldPassword: String, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      status
      message
    }
  }
  `
	let variables = {
		oldPassword: currentPassword,
		newPassword: newPassword
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.changePassword)
					throw new Error("Error occured changing password")
				if (!res.data.changePassword.status) throw new Error(res.data.changePassword.message)
				resolve(res.data.changePassword.status)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.isSocialAccount = () => {
	let query = `
  query {
    isSocialAccount {
      status
      message
      data
      totalCount
    }
  }  
  `
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query })
			.then((res) => {
				if (!res || !res.data || !res.data.isSocialAccount)
					throw new Error("Error occured changing password")
				if (!res.data.isSocialAccount.status)
					throw new Error("Error occured while fetching account information")
				resolve(res.data.isSocialAccount.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

users.markEmailVerified = (userId, code) => {
	let query = `
  mutation markEmailVerified($uid: String!, $code: String!) {
    markEmailVerified(uid: $uid, code: $code) {
      status
      message
    }
  }
  `

	let variables = {
		uid: userId,
		code: code
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.markEmailVerified)
					throw new Error("Error occured while verifying email address")
				if (!res.data.markEmailVerified.status) throw new Error(res.data.markEmailVerified.message)
				resolve(res.data.markEmailVerified.status)
			})
			.catch((err) => {
				console.log("Error in verifying email address, err=", err.message)
				reject(err)
			})
	})
}

users.getEmailVerificationStatus = (email) => {
	let query = `
  query getEmailVerificationStatus($email: String!) {
    getEmailVerificationStatus(email: $email) {
      status
      message
      totalCount
      data {
        email
        isVerified
      }
    }
  }
  `
	let variables = {
		email: email
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getEmailVerificationStatus)
					throw new Error("Error occured while getting email verification status")
				if (!res.data.getEmailVerificationStatus.status)
					throw new Error(res.data.getEmailVerificationStatus.message)
				resolve(res.data.getEmailVerificationStatus.data)
			})
			.catch((err) => {
				console.log("Error in getting email verification status, err =", err.message)
				reject(err)
			})
	})
}

users.sendEmailVeriicationLink = (email) => {
	let query = `
  mutation sendEmailVeriicationLink($email: String!) {
    sendEmailVeriicationLink(email: $email) {
      status
      message
    }
  }
  `
	let variables = {
		email: email
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchPublic({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.sendEmailVeriicationLink)
					throw new Error("Error occured while sending email verification link")
				if (!res.data.sendEmailVeriicationLink.status)
					throw new Error(res.data.sendEmailVeriicationLink.message)
				resolve(res.data.sendEmailVeriicationLink.status)
			})
			.catch((err) => {
				console.log("Error in sending email verification link, err =", err.message)
				reject(err)
			})
	})
}

users.addNewOptionalEmail = (email) => {
	let query = `
  mutation addNewOptionalEmail($email: String!) {
    addNewOptionalEmail(email: $email) {
      status
      message
    }
  }
  `
	let variables = {
		email: email
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.addNewOptionalEmail)
					throw new Error("Error occured while adding new optional email address")
				if (!res.data.addNewOptionalEmail.status)
					throw new Error(res.data.addNewOptionalEmail.message)
				resolve(res.data.addNewOptionalEmail.status)
			})
			.catch((err) => {
				console.log("Error in adding optional email address, err =", err.message)
				reject(err)
			})
	})
}

users.deleteOptionalEmail = (email) => {
	let query = `
  mutation deleteOptionalEmail($email: String!) {
    deleteOptionalEmail(email: $email) {
      status
      message
    }
  }
  `
	let variables = {
		email: email
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.deleteOptionalEmail)
					throw new Error("Error occured while deleting the optional email address")
				if (!res.data.deleteOptionalEmail.status)
					throw new Error(res.data.deleteOptionalEmail.message)
				resolve(res.data.deleteOptionalEmail.status)
			})
			.catch((err) => {
				console.log("Error in deleting the optional email address, err =", err.message)
				reject(err)
			})
	})
}

users.changePrimaryEmail = (email) => {
	let query = `
  mutation changePrimaryEmail($email: String!) {
    changePrimaryEmail(email: $email) {
      status
      message
      data {
        email
        isVerified
        isPrimary
      }
    }
  }
  `
	let variables = {
		email: email
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.changePrimaryEmail)
					throw new Error("Error occured while changing the primary email")
				if (!res.data.changePrimaryEmail.status)
					throw new Error(res.data.changePrimaryEmail.message)
				resolve(res.data.changePrimaryEmail.data)
			})
			.catch((err) => {
				console.log("Error in changing the primary email, err =", err.message)
				reject(err)
			})
	})
}

export default users
