import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Grid, TextField, Typography, Button } from '@material-ui/core';

import CustomModal from 'components/general/CustomModal';

import { adminCreateUser } from 'dataServices/AdminPageServices/users';

import styles from "./styles.module.scss";
import actionTypes from 'reduxjs/actionTypes';

const AdminAddUser = props => {
  const { isModalOpen, closeModal, setLoadingSpinner, resetLoadingSpinner } = props;
  
  const [name, setName] = useState("");
  const [userName, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [apiError, setApiError] = useState(null);

  const emailIsValid = () => {
    return email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  };

  const isPasswordStrong = () => {
    return password.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()])/);
  };

  const resetStates = () => {
    setName("");
    setUsername("");
    setEmail("");
    setPassword("");
    setError(false);
    setApiError(null);
  };

  const onModalClose = (shouldRefresh=false) => {
    resetStates();
    closeModal(shouldRefresh);
  };

  const onInputValueChange = e => {
    if (e.target.name == "name") {
      setName(e.target.value);
    } else if (e.target.name == "username") {
      setUsername(e.target.value.trim().replace(" ", ""));
    } else if (e.target.name == "email") {
      setEmail(e.target.value.trim());
    } else if (e.target.name == "password") {
      setPassword(e.target.value);
    }

    setError(false);
    setApiError(null);
  };

  const onCreateUser = async () => {
    if (!emailIsValid() || !isPasswordStrong() || (name.trim() || userName || '').length == 0) {
      setError(true);
      return;
    }

    try {
      setLoadingSpinner();
      const response = await adminCreateUser(name, userName.toLowerCase(), email.toLowerCase(), password);
      
      if (response.status == 200) {
        onModalClose(true);
      } else {
        setApiError(response.message);
      }
      resetLoadingSpinner();
    } catch (err) {
      setApiError(err.message || err);
      resetLoadingSpinner();
    }
  };

  const renderAddUserSection = () => {
    return (
      <Grid container spacing={2} className={styles.wrapper}>
        <Grid item xs={12} sm={12} spacing={2}>
          <Typography variant={'subtitle2'}>
            Enter your name
          </Typography>
          <div style={{ height: "10px" }}></div>
          <TextField
            label="Name *"
            variant="outlined"
            name={'name'}
            fullWidth
            value={name}
            onChange={onInputValueChange}
            error={error && (name || '').length == 0}
            helperText={error ? "This field is required" : ""}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
            Enter your username
          </Typography>
          <div style={{ height: "10px" }}></div>
          <TextField
            label="Username *"
            variant="outlined"
            name={'username'}
            fullWidth
            className={styles.input}
            value={userName}
            onChange={onInputValueChange}
            error={error && (userName || '').length == 0}
            helperText={error ? "This field is required" : ""}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
            Enter your email
          </Typography>
          <div style={{ height: "10px" }}></div>
          <TextField
            label="Email *"
            variant="outlined"
            name={'email'}
            fullWidth
            className={styles.input}
            value={email}
            onChange={onInputValueChange}
            error={error && !emailIsValid()}
            helperText={error ? "This field is required" : ""}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant={'subtitle2'} sx={{ marginBottom: 1 }}>
            Enter your password
          </Typography>
          <div style={{ height: "10px" }}></div>
          <TextField
            label="Password *"
            variant="outlined"
            name={'password'}
            type={'password'}
            fullWidth
            className={styles.input}
            value={password}
            onChange={onInputValueChange}
            error={error && !isPasswordStrong()}
            helperText="Password must contains uppercase, lowercase, digits, special characters"
          />
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent={apiError ? "space-between" : "flex-end"}
        >
          { apiError && (
            <div style={{ color: "red" }}>{apiError}</div>
          )}
          <Button
            size={'large'}
            variant={'contained'}
            style={{ backgroundColor: "#3daeff", color: "white" }}
            onClick={onCreateUser}
          >
            Add User
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <CustomModal
      title="Create User"
      visible={isModalOpen}
      onClose={onModalClose}
    >
      {renderAddUserSection()}
    </CustomModal>
  );
};

AdminAddUser.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
};

AdminAddUser.defaultProps = {
  isModalOpen: false,
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
    setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminAddUser);
