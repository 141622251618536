import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Card from "components/Card/Card"
import { Grid } from "@material-ui/core"
import Slider from "@material-ui/core/Slider"

import combineStyles from "utils/combineStyle"
import commonStyles from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx"
import ReactHtmlParser from 'html-react-parser'

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import helper from "../../../helper"
import constants from "../../../constants"
import FormRenderReadOnly from "./FormRenderReadOnly"
import FontOverridePatch from "utils/FontOverridePatch"

function valueText(value) {
	return `${value}`
}

class SetScoreSliderComments extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			slidersValues: props.slidersValues,
			commentTexts: props.commentTexts,
			scoringCompleted: props.scoringCompleted
		}
	}

	componentDidUpdate(prevProps) {
		if (
			JSON.stringify({
				currentSolutionId: prevProps.currentSolution.solutionId,
				commentTexts: prevProps.commentTexts,
				slidersValues: prevProps.slidersValues
			}) !=
			JSON.stringify({
				currentSolutionId: this.props.currentSolution.solutionId,
				commentTexts: this.props.commentTexts,
				slidersValues: this.props.slidersValues
			})
		) {
			this.setState({
				slidersValues: this.props.slidersValues,
				commentTexts: this.props.commentTexts
			})
		}
		if (this.props.scoringCompleted != prevProps.scoringCompleted) {
			this.setState({ scoringCompleted: this.props.scoringCompleted })
		}
	}

	render() {
		console.log(this.state)
		const { classes } = this.props
		return (
			<Card>
				<CardContent>
					<Typography color="textPrimary" gutterBottom>
						Add criterion based score and your remarks below:
					</Typography>

					<Grid container direction="column">
						{this.props.evaluationCriteria.map((criterion, index) => {
							let minScore = 0
							let maxScore = Math.round((this.props.maxScore * criterion.weight) / 100)

							return (
								<Grid item lg={12} key={index}>
									<Paper className={classes.criteriaPaper}>
										<Typography variant="body2" component="p" gutterBottom>
											Criteria {index + 1}:{" "}
											<FontOverridePatch>
												{ReactHtmlParser(criterion.evaluationCriterionName || "")}
											</FontOverridePatch>
											<br />
										</Typography>
										<Typography gutterBottom>
											<div className={classes.margin} />
											<Slider
												onChange={(e, val) => {
													let newSlideVal = this.state.slidersValues.map((v, i) =>
														i == index ? val : v
													)
													this.setState({ slidersValues: newSlideVal })
												}}
												value={this.state.slidersValues[index]}
												getAriavalueText={valueText}
												step={1}
												min={minScore}
												max={maxScore}
												marks={[minScore, maxScore].map((score) => ({
													value: score,
													label: `${score}`
												}))}
												valueLabelDisplay="auto"
											/>
										</Typography>

										<Typography variant="body2" component="p" gutterBottom>
											<br />
										</Typography>
										<form className={classes.container} noValidate autoComplete="off">
											<TextField
												onChange={(e) => {
													let val = e.target.value
													let newCommentTexts = this.state.commentTexts.map((v, i) =>
														i == index ? val : v
													)
													this.setState({ commentTexts: newCommentTexts })
												}}
												value={this.state.commentTexts[index]}
												label="Remarks"
												margin="normal"
												variant="outlined"
												fullWidth
												multiline
												rows="3"
											/>
										</form>
									</Paper>
								</Grid>
							)
						})}
					</Grid>
				</CardContent>
				<CardActions>
					<Button
						disabled={this.props.isSubmitting}
						onClick={() => {
							this.props.handleSubmitScore(this.state.slidersValues, this.state.commentTexts)
						}}
						variant="contained"
						color="primary"
						size="small"
					>
						SUBMIT SCORE
					</Button>

					{this.props.scoringCompleted && (
						<Button
							onClick={this.props.handleOnClickNotify}
							variant="contained"
							color="secondary"
							size="small"
						>
							Finalize All the scores
						</Button>
					)}

					{this.props.onCancel && (
						<Button color="danger" onClick={this.props.onCancel}>
							Cancel
						</Button>
					)}
				</CardActions>
			</Card>
		)
	}
}

export default SetScoreSliderComments
