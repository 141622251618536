import moment from 'moment';
let timeUtils = {}

// timeUtils.getTimeSince = (date) => {
//     date = moment.utc(date);
//     var currentDate = getCurrentDate();

//     var seconds = Math.floor((currentDate - date) / 1000);

//     var interval = Math.floor(seconds / 31536000);

//     if (interval > 1) {
//         return interval + " years";
//     }
//     else if (interval == 1) {
//         return interval + " year";
//     }
//     interval = Math.floor(seconds / 2592000);
//     if (interval > 1) {
//         return interval + " months";
//     }
//     else if (interval == 1) {
//         return interval + " month";
//     }
//     interval = Math.floor(seconds / 86400);
//     if (interval > 1) {
//         return interval + " days";
//     }
//     else if (interval == 1) {
//         return interval + " day";
//     }
//     interval = Math.floor(seconds / 3600);
//     if (interval > 1) {
//         return interval + " hours";
//     }
//     else if (interval == 1) {
//         return interval + " hour";
//     }
//     interval = Math.floor(seconds / 60);
//     if (interval > 1) {
//         return interval + " minutes";
//     }
//     else if (interval == 1) {
//         return interval + " minute";
//     }
//     interval = Math.floor(seconds);
//     if (interval > 1) {
//         return interval + " seconds";
//     }
//     else {
//         return "1 second";
//     }
// }

timeUtils.getTimeUntil = (currentDate, nextDate) => {
    var timeString = '';

    var seconds = Math.floor((nextDate - currentDate) / 1000);
    if (seconds > 0) {
        var interval = Math.floor(seconds / 31536000);

        if (interval > 1) {
            timeString += interval + " years ";
            seconds -= interval * 31536000;
        }
        else if (interval == 1) {
            timeString += interval + " year ";
            seconds -= 31536000;
        }
        interval = Math.floor(seconds / 2592000);
        if (interval > 1) {
            timeString += interval + " months ";
            seconds -= interval * 2592000;
        }
        else if (interval == 1) {
            timeString += interval + " month ";
            seconds -= 2592000;
        }
        interval = Math.floor(seconds / 86400);
        if (interval > 1) {
            timeString += interval + " days ";
            seconds -= interval * 86400;
        }
        else if (interval == 1) {
            timeString += interval + " day ";
            seconds -= 86400;
        }
        interval = Math.floor(seconds / 3600);
        if (interval > 1) {
            timeString += interval + " hours ";
            seconds -= interval * 3600;
        }
        else if (interval == 1) {
            timeString += interval + " hour ";
            seconds -= 3600;
        }
        interval = Math.floor(seconds / 60);
        if (interval > 1) {
            timeString += interval + " minutes ";
            seconds -= interval * 60;
        }
        if (interval == 1) {
            timeString += interval + " minute ";
            seconds -= 60;
        }
    }
    return timeString;
}

timeUtils.isDateExpired = (date) => {
    if (moment(date).diff(moment()) > 0) {
        return false;
    }
    return true;
}

timeUtils.isValidDate = (date) => {
    var dateTime = new Date(date);
    if (!date || !dateTime.getTime()) {
        return false;
    }
    else {
        return true;
    }
}

timeUtils.convertUtcToLocalDateTime = (dateTime, format) => {
    var localDateTime = moment(dateTime).local().format(format);
    return localDateTime;
}

export default timeUtils;