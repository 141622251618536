import apolloFetcher from "apolloFetcher"

let services = {}

services.saveInvitations = (challengeId, users) => {
	let query = `
    mutation manageChallengeInvitation($challengeId: String! $users: [UserWithRoleInput] ){
        manageChallengeInvitation(
          challengeId: $challengeId
          users: $users
        ) {
          status
          message
          data {
            challengeId
            invitations {
							invitationId
              userId
              name
              email
              role
              isAccepted
            }
          }
          totalCount
        }
      }      
    `
	let variables = {
		challengeId: challengeId,
		users: users.map((user) => {
			let u = {
				userId: user.userId,
				name: user.name,
				email: user.email,
				role: user.role
			}
			if (user.invitationId) u.invitationId = user.invitationId
			return u
		})
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.manageChallengeInvitation)
					throw new Error("Error occured while saving invitations")
				if (!res.data.manageChallengeInvitation.status)
					throw new Error(res.data.manageChallengeInvitation.message)
				resolve(res.data.manageChallengeInvitation.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.fetchInvitations = (challengeId) => {
	let query = `
  query getManageChallengeInvitation($challengeId: String!){
    getManageChallengeInvitation(challengeId: $challengeId){
      status
      message
      data{
        challengeId
        invitations{
					invitationId
          userId
          name
          email
          role
          isAccepted
        }
      }
      totalCount
    }
  }
  `
	let variables = {
		challengeId: challengeId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				if (!res || !res.data || !res.data.getManageChallengeInvitation)
					throw new Error("Error occured while saving invitations")
				if (!res.data.getManageChallengeInvitation.status)
					throw new Error(res.data.getManageChallengeInvitation.message)
				resolve(res.data.getManageChallengeInvitation.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.acceptManageChallengeInvitation = (challengeId, code) => {
	let query = `
  mutation acceptManageChallengeInvitation($challengeId: String! $code: String!) {
    acceptManageChallengeInvitation(challengeId: $challengeId code: $code){
      status
      message
      data{
				invitationId
        userId
        name
        email
        role
				isAccepted
				challengeId
				urlSlug
				challengeTitle
      }
      totalCount
    }
  }
  `
	let variables = {
		challengeId: challengeId,
		code: code
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.acceptManageChallengeInvitation)
					throw new Error("Error occured while saving invitations")
				if (!res.data.acceptManageChallengeInvitation.status)
					throw new Error(res.data.acceptManageChallengeInvitation.message)
				resolve(res.data.acceptManageChallengeInvitation.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.confirmInvitationManually = (challengeId, invitationId) => {
	let query = `
	mutation confirmInvitationManually(
		$challengeId: String!
		$invitationId: String!
	) {
		confirmInvitationManually(
			challengeId: $challengeId
			invitationId: $invitationId
		) {
			status
			message
			totalCount
			data {
				invitationId
				userId
				name
				email
				role
				isAccepted
				challengeId
				urlSlug
				challengeTitle
			}
		}
	}
	`

	let variables = {
		challengeId: challengeId,
		invitationId: invitationId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.confirmInvitationManually)
					throw new Error("Error occured while saving invitations")
				if (!res.data.confirmInvitationManually.status)
					throw new Error(res.data.confirmInvitationManually.message)
				resolve(res.data.confirmInvitationManually.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.deleteManageChallengeInvitation = (challengeId, users) => {
	let query = `
  mutation deleteManageChallengeInvitation($challengeId: String! $users: [UserWithRoleInput] ){
    deleteManageChallengeInvitation(
      challengeId: $challengeId
      users: $users
    ) {
      status
      message
      data {
        challengeId
        invitations {
					invitationId
          userId
          name
          email
          role
          isAccepted
        }
      }
      totalCount
    }
  }
	`

	let variables = {
		challengeId: challengeId,
		users: users.map((user) => {
			let u = {
				userId: user.userId,
				name: user.name,
				email: user.email,
				role: user.role
			}
			if (user.invitationId) u.invitationId = user.invitationId
			return u
		})
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.deleteManageChallengeInvitation)
					throw new Error("Error occured while deleting invitations")
				if (!res.data.deleteManageChallengeInvitation.status)
					throw new Error(res.data.deleteManageChallengeInvitation.message)
				resolve(res.data.deleteManageChallengeInvitation.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export default services
