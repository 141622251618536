import apolloFetcher from "apolloFetcher";
import { get } from "lodash";

export const adminUpdateSolverAccessForSubmission = async (accessText, challengeId) => {
	const query = `
    mutation adminUpdateSolverAccessForSubmission(
      $accessText: Boolean
      $challengeId: String
    ) {
      adminUpdateSolverAccessForSubmission(
        accessText: $accessText
        challengeId: $challengeId
      ) {
        status
        message
      }
    }	
  `;

  const variables = {
    accessText: accessText,
    challengeId: challengeId,
  };

  try {
    const response = await apolloFetcher.fetchWithToken({ query, variables });
    
    return get(response, "data.adminUpdateSolverAccessForSubmission");
  } catch (err) {
    return {
      status: 500,
      message: err.message || err,
    };
  }
};