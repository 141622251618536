import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"
import commonStyles from "assets/jss/material-dashboard-pro-react/views/commonStyle.jsx"
import combineStyles from "utils/combineStyle"
import Chip from "@material-ui/core/Chip"
import Paper from "@material-ui/core/Paper"
import InputAdornment from "@material-ui/core/InputAdornment"
import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"

import SweetAlert from "react-bootstrap-sweetalert"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Button from "components/CustomButtons/Button.jsx"
import CustomInput from "components/CustomInput/CustomInputForChallengeBuilder.jsx"
import apolloFetcher from "apolloFetcher"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardMedia from "components/Card/CardAvatar"
import CardContent from "components/Card/CardBody"
import CardActions from "@material-ui/core/CardActions"
import Constants from "constants.js"
import { Mutation } from "@apollo/client/react/components"
import { gql } from '@apollo/client'

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertToRaw } from "draft-js"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import ReactHtmlParser from 'html-react-parser'

import defaultChallengeImage from "../../../assets/img/defaultChallengeImage.webp"

import challengeTagServices from "dataServices/challengeTagServices"
import challengeServices from "dataServices/challengeServices"
import userServices from "dataServices/userServices"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import CardBody from "components/Card/CardBody"
import { TextField, Tooltip } from "@material-ui/core"
import { Select, MenuItem } from "@material-ui/core"

import Datetime, { moment } from "react-datetime"
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormHelperText from "@material-ui/core/FormHelperText"

import SearchAndSelectMultipleItem from "../../Components/SearchAndSelectMultipleItem"
import challengeUtils from "utils/challengeUtils"
import UsersTeamsListSelection from "views/Components/UsersTeamsListSelection"
import htmlToDraft from "html-to-draftjs"
import FontOverridePatch from "utils/FontOverridePatch"

const uploadFileMutation = gql`
	mutation uploadFile($file: Upload!, $uploadType: String!) {
		uploadFile(file: $file, uploadType: $uploadType) {
			status
			message
			totalCount
			data {
				id
				filename
				path
				mimetype
				encoding
			}
		}
	}
`

var self = this

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	},
	overviewDiv: {
		padding: "0px 0px",
		backgroundColor: "transparent" //theme.palette.card.background.card[0],
	},
	updatePriceStyle: {
		marginTop: "20px",
		marginBottom: "20px"
	},
	buttonMinWidthStyle: {
		minWidth: "200px"
	},
	displayPrizeAmountStyle: {
		marginTop: "30px",
		marginBottom: "30px"
	},
	editChallengeButtonStyle: {
		marginTop: "30px",
		marginBottom: "10px"
	},
	prizeAmountChipStyle: {
		backgroundColor: "#1EB980",
		color: "#FFFFFF"
	},
	overviewPageBackgroundStyle: {
		padding: "5px",
		margin: "0px 0px"
	},
	buttonSuccessColor: {
		backgroundColor: "#1eb980",
		"&:hover,&:focus": {
			backgroundColor: "#1eb980",
			boxShadow: "0px 6px 14px rgba(30, 185, 128, .3)"
		}
	},
	whiteTextColorStyle: {
		color: theme.palette.text.page
	},
	inputRoot: {
		backgroundColor: "transparent"
	},
	inputLabel: {
		color: "green"
	},
	textField: {
		color: theme.palette.text.page
	},
	toolbarClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	wrapperClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	editorClassName: {
		backgroundColor: theme.palette.background.page,
		color: theme.palette.text.page
	},
	...sweetAlertStyle
})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

class Overview extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			value: 0,
			overview: null,
			alert: null,
			challengeId: props.challengeId,
			title: null,
			titleChanged: false,
			externalUrlChanged: false,
			urlSlug: "",
			urlSlugChanged: false,
			shortDescription: null,
			shortDescriptionChanged: false,
			description: null,
			descriptionChanged: false,
			sponsorsName: null,
			sponsorsNameChanged: false,
			prizeAmount: null,
			prizeAmountChanged: false,
			tags: [],
			updatedTags: null,
			updatedTagChanged: false,
			editMode: false,
			showSolverSelectionUI: false,
			canEditChallenge: false,
			challengeImagePath: null,
			updatedChallengeImagePath: null,
			challengeImagePathChanged: false,
			file: null,
			fileName: null,
			fileChanged: false,
			dataFetchDone: false,
			isConfirmDialogueOpen: false,
			confirmDialogText: "",
			updatedStatus: "",
			currentPhaseChanged: false,
			startDate: "",
			endDate: "",
			phaseId: "",
			phaseTitle: "",
			phaseTitleForMultiPhaseChallenge: "",
			phaseTitleForMultiPhaseChallengeChanged: false,
			otherThanPhaseTitle: "",
			phaseLinks: [],
			externalUrl: "",
			solverSelectionTypeText: "allUsers",
			challengeType: "",
			challengeTypeChanged: false,
			solverSelectionTypeTextChanged: false,
			canUpdateSolverSelectionType: false,
			isBaseChallenge: true,
			isConfirmDialogueOpenSolverSelection: false,
			tempSST: "",
			parentChallengeId: "",
			participants: [],
			isConfirmDialogueOpenDeleteChallenge: false,
			editorState: EditorState.createEmpty()
		}
		self = this
	}

	handleSimple = (event) => {
		let fieldName = event.target.name
		let fieldValue = event.target.value

		if (
			fieldName == "title" ||
			fieldName == "phaseTitleForMultiPhaseChallenge" ||
			fieldName == "urlSlug"
		) {
			fieldValue = fieldValue.replace(/\//g, "")
		}

		if (fieldName == "urlSlug") {
			fieldValue = fieldValue.replace(" ", "-")
		}

		this.setState({
			[fieldName]: fieldValue,
			[fieldName + "Changed"]: true
		})
	}

	handleSimpleForPrize = (event) => {
		console.log(event.target.value)
		let tempPrizeamount = event.target.value
		//remove existing comas from the string
		if (tempPrizeamount) {
			tempPrizeamount = tempPrizeamount.replace(/,/g, "")
			tempPrizeamount = tempPrizeamount.replace(/ /g, "")
		}
		if (isNaN(tempPrizeamount)) {
			return
		}
		while (tempPrizeamount.length > 0 && tempPrizeamount[0] == "0") {
			tempPrizeamount = tempPrizeamount.substr(1, tempPrizeamount.length)
		}
		//Convert string into an array
		let newPrizeAmount = tempPrizeamount.split("").reverse()
		let finalPrizeAmount = []
		//Update array with commas in the right place
		if (newPrizeAmount) {
			for (var i = 0; i < newPrizeAmount.length; i += 3) {
				for (var j = i; j < i + 3; j++) {
					finalPrizeAmount.push(newPrizeAmount[j])
				}
				finalPrizeAmount.push(",")
			}
			if (finalPrizeAmount[finalPrizeAmount.length - 1] == ",") finalPrizeAmount.pop()
		}
		//Convert array into a string
		tempPrizeamount = finalPrizeAmount.reverse().join("")
		this.setState(
			{
				[event.target.name]: tempPrizeamount,
				[event.target.name + "Changed"]: true
			},
			() => {
				console.log("state now = ", this.state.prizeAmount, this.state.prizeAmountChanged)
			}
		)
	}

	verifyPrizeAmount = (value) => {
		if (value) {
			value = value.replace(/,/g, "")
		}
		var prizeAmountRex = /^[0-9]*[.]?[0-9]+$/
		if (prizeAmountRex.test(value)) {
			return true
		}
		return false
	}

	onEditorStateChange = (editorState) => {
		this.setState({
			editorState,
			descriptionChanged: true
		})
	}

	handleOnClickUpdateChallenge = () => {
		let externalUrl = this.state.externalUrl.trim()
		let variables = { challengeId: this.props.challengeId, externalUrl: externalUrl }
		let title = this.state.title.trim()
		let shortDescription = this.state.shortDescription.trim()
		let phaseTitle = this.state.phaseTitleForMultiPhaseChallenge.trim()
		let sponsorsName = this.state.sponsorsName.trim()
		
		if (this.state.titleChanged && title && title != "") {
			variables.title = title
		}

		if (this.state.phaseTitleForMultiPhaseChallengeChanged) {
			if (
				this.state.phaseTitleForMultiPhaseChallenge &&
				this.state.phaseTitleForMultiPhaseChallenge.trim() != ""
			) {
				variables.phaseTitle = this.state.phaseTitleForMultiPhaseChallenge.trim()
				let isDuplicate = false
				if (this.state.phaseLinks && this.state.phaseLinks.length) {
					this.state.phaseLinks.map((pl) => {
						if (
							pl.phaseTitle.toLowerCase() == variables.phaseTitle.toLowerCase() &&
							pl.challengeId != this.props.challengeId
						) {
							isDuplicate = true
						}
					})
				}
				if (isDuplicate) {
					this.props.showAlert(
						"warning",
						"Opps!",
						"Please enter a valid phase name if you really want to change the phase name, there is already another phase available with same phase title"
					)
					return
				}
			} else {
				this.props.showAlert(
					"warning",
					"Opps!",
					"Please enter a valid phase name if you really want to change the phase name, otherwise leave it unedited"
				)
				return
			}
		}

		if (this.state.shortDescriptionChanged && shortDescription && shortDescription != "") {
			variables.shortDescription = shortDescription
		}
		if (this.state.sponsorsNameChanged && sponsorsName && sponsorsName != "") {
			variables.sponsorsName = sponsorsName
		}
		let description = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
		if (this.state.descriptionChanged && description && description.length > 7) {
			variables.description = description
		}
		if (this.state.challengeImagePathChanged && this.state.updatedChallengeImagePath) {
			variables.challengeImage = this.state.updatedChallengeImagePath
		}
		if (this.state.prizeAmountChanged && this.state.prizeAmount) {
			console.log("lets check prize amount")
			if (this.verifyPrizeAmount(this.state.prizeAmount)) {
				console.log("prize amount is valid")
				variables.prizeAmount = this.state.prizeAmount
			} else {
				this.props.showAlert("warning", "Ooops!", "Please enter numeric value as prize amount")
				return
			}
		}

		if (this.state.updatedTagChanged && this.state.updatedTags) {
			variables.tags = this.state.updatedTags
		}

		if (this.state.currentPhaseChanged) {
			if (this.state.phaseId && this.state.phaseId != "other") {
				variables.currentPhase = {
					phaseId: this.state.phaseId
				}
			} else if (this.state.phaseId && this.state.phaseId == "other" && phaseTitle) {
				variables.currentPhase = {
					phaseId: this.state.phaseId,
					phaseTitle: phaseTitle
				}
			} else {
				this.props.showAlert("warning", "Ooops!", "Please enter current phase data properly")
				return
			}

			if (this.state.startDate && this.state.endDate) {
				variables.currentPhase = {
					...variables.currentPhase,
					startDate: this.state.startDate.format("MM-DD-YYYY, h:mm a"),
					endDate: this.state.endDate.format("MM-DD-YYYY, h:mm a")
				}
			} else {
				this.props.showAlert(
					"warning",
					"Ooops!",
					"Please enter current phase start date / end date properly"
				)
				return
			}
		}

		if (this.state.challengeType && this.state.challengeTypeChanged)
			variables.challengeType = this.state.challengeType

		if (
			this.state.canUpdateSolverSelectionType &&
			this.state.solverSelectionTypeTextChanged &&
			this.state.solverSelectionTypeText
		) {
			let solverSelectionType = { allUsers: false, selectedUsers: false }
			if (this.state.solverSelectionTypeText == "both") {
				solverSelectionType.allUsers = true
				solverSelectionType.selectedUsers = true
				variables.solverSelectionType = solverSelectionType
			} else if (this.state.solverSelectionTypeText == "allUsers") {
				solverSelectionType.allUsers = true
				variables.solverSelectionType = solverSelectionType
			} else if (this.state.solverSelectionTypeText == "selectedUsers") {
				solverSelectionType.selectedUsers = true
				variables.solverSelectionType = solverSelectionType
			}
		}

		if (this.state.urlSlugChanged && this.state.urlSlug && this.state.urlSlug.trim())
			variables.urlSlug = this.state.urlSlug

		this.props.setLoadingSpinner()
		challengeServices
			.updateChallenge(variables)
			.then((data) => {
				this.props.resetLoadingSpinner()
				if (data) {
					this.setState({
						title: null,
						titleChanged: false,
						externalUrlChanged: false,
						shortDescription: null,
						shortDescriptionChanged: false,
						description: null,
						descriptionChanged: false,
						sponsorsName: null,
						sponsorsNameChanged: false,
						updatedChallengeImagePath: null,
						challengeImagePathChanged: false,
						prizeAmount: null,
						prizeAmountChanged: false,
						updatedTags: null,
						updatedTagChanged: false,
						currentPhaseChanged: false,
						startDate: "",
						endDate: "",
						phaseId: "",
						otherThanPhaseTitle: "",
						editMode: false,
						showSolverSelectionUI: false,
						phaseTitleForMultiPhaseChallenge: "",
						phaseTitleForMultiPhaseChallengeChanged: false,
						challengeType: "",
						solverSelectionTypeText: "allUsers",
						challengeTypeChanged: false,
						solverSelectionTypeTextChanged: false
					})

					if (this.props.urlSlug != data.urlSlug) {
						let newPath = window.location.pathname.replace(this.props.urlSlug, data.urlSlug)
						this.props.history.push(newPath)
					}

					this.fetchChallenge(this.props.challengeId)
					this.props.renderChallengeDetails(this.props.challengeId)
				}
			})
			.catch((err) => {
				console.log("Err =", err.message)
				this.props.resetLoadingSpinner()
				this.props.showAlert("error", "Opps!", "Error updating challenge")
			})
	}

	isUpdateChallengeDataChanged = () => {
		if (this.state.titleChanged && this.state.title && this.state.title.trim() != "") {
			return true
		}
		if (
			this.state.shortDescriptionChanged &&
			this.state.shortDescription &&
			this.state.shortDescription.trim() != ""
		) {
			return true
		}
		if (
			this.state.sponsorsNameChanged &&
			this.state.sponsorsName &&
			this.state.sponsorsName.trim() != ""
		) {
			return true
		}
		let description = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
		if (this.state.descriptionChanged && description && description.length > 7) {
			return true
		}
		if (this.state.challengeImagePathChanged && this.state.updatedChallengeImagePath) {
			return true
		}
		if (
			this.state.prizeAmountChanged &&
			this.state.prizeAmount &&
			this.state.prizeAmount.trim() != "" &&
			!isNaN(this.state.prizeAmount.trim().replace(/,/g, ""))
		) {
			return true
		}
		if (this.state.updatedTagChanged && this.state.updatedTags) {
			return true
		}

		if (
			this.state.currentPhaseChanged &&
			this.state.phaseId &&
			(this.state.phaseId != "other" || this.state.otherThanPhaseTitle.trim() != "")
		) {
			return true
		}

		if (
			this.state.phaseTitleForMultiPhaseChallengeChanged &&
			this.state.phaseTitleForMultiPhaseChallenge &&
			this.state.phaseTitleForMultiPhaseChallenge.trim() != ""
		)
			return true

		if (this.state.urlSlugChanged && this.state.urlSlug && this.state.urlSlug.trim() != "")
			return true

		if (this.state.challengeTypeChanged && this.state.challengeType) return true
		if (
			this.state.canUpdateSolverSelectionType &&
			this.state.solverSelectionTypeTextChanged &&
			this.state.solverSelectionTypeText
		)
			return true

		if (this.state.externalUrlChanged) {
			return true
		}

		return false
	}

	addSelectedUsersTeams = (participants) => {
		console.log("Selected participants = ", participants)
		let soloSolvers = [],
			teams = []
		participants.map((p) => {
			if (p.teamId) {
				teams.push(p.teamId)
			} else {
				soloSolvers.push({
					userId: p.userId,
					name: p.userName,
					email: p.email
				})
			}
		})

		let variables = {
			challengeId: this.props.challengeId,
			soloSolvers: soloSolvers,
			teams: teams
		}

		this.props.setLoadingSpinner()
		challengeServices
			.addSelectedSolversTeams(variables)
			.then((data) => {
				console.log("Add Selected solver API response = ", data)
				this.props.resetLoadingSpinner()
				this.setState({ editMode: false, showSolverSelectionUI: false })
				if (
					!this.state.isBaseChallenge &&
					this.state.canUpdateSolverSelectionType &&
					this.state.parentChallengeId
				) {
					this.setState({ participants: [] })
					this.fetchAndAdjustParticipants(
						this.props.challengeId,
						this.state.parentChallengeId
					).then((participants) => {
						this.setState({ participants: participants })
					})
				}
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error adding solvers/teams, err=", err.message)
				this.props.showAlert("error", "Opps!", err.message)
			})
	}

	//UNUSED
	updateTag() {
		if (!this.state.updatedTagChanged) {
			this.props.showAlert("warning", "Ooops!", "You can not submit an empty value")
		} else {
			this.props.setLoadingSpinner()
			var variables = {
				challengeId: this.props.challengeId,
				tags: this.state.updatedTags
			}
			challengeServices
				.updateChallenge(variables)
				.then((status) => {
					this.props.resetLoadingSpinner()
					if (status) {
						this.setState({
							updatedTags: null,
							editMode: false,
							showSolverSelectionUI: false,
							updatedTagChanged: false
						})
						this.fetchChallenge(this.props.challengeId)
						this.props.renderChallengeDetails(this.props.challengeId)
					}
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					this.props.showAlert("error", "Opps!", "Error updating challenge tags")
				})
		}
	}

	//UNUSED
	updatePrizeAmount() {
		if (!this.state.prizeAmount) {
			this.props.showAlert("warning", "Ooops!", "You can not submit an empty value")
		} else if (!this.verifyPrizeAmount(this.state.prizeAmount)) {
			this.props.showAlert("warning", "Ooops!", "Please enter numeric value as prize amount")
		} else {
			this.props.setLoadingSpinner()
			var variables = {
				challengeId: this.props.challengeId,
				prizeAmount: this.state.prizeAmount
			}
			challengeServices
				.updateChallenge(variables)
				.then((status) => {
					this.props.resetLoadingSpinner()
					if (status) {
						this.setState({
							prizeAmount: null,
							editMode: false,
							showSolverSelectionUI: false,
							prizeAmountChanged: false
						})
						this.fetchChallenge(this.props.challengeId)
						this.props.renderChallengeDetails(this.props.challengeId)
					}
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					this.props.showAlert("error", "Opps!", "Error updating challenge prize amount")
				})
		}
	}

	renderTags() {
		const { classes } = this.props
		let tags = this.state.tags
		if (tags && tags.length != 0) {
			return tags.map((data, key) => {
				return (
					<Chip
						className={`${classes.whiteTextColorStyle} ${classes.chip}`}
						size="small"
						label={data.tag}
						color="primary"
					/>
				)
			})
		} else {
			return null //<p>No tags available</p>
		}
	}

	goToEditOverview() {
		this.setState({
			editMode: true,
			showSolverSelectionUI: false
		})
	}

	handleOnClickGoBack = () => {
		this.setState({
			editMode: false,
			showSolverSelectionUI: false,
			titleChanged: false,
			externalUrlChanged: false,
			shortDescriptionChanged: false,
			descriptionChanged: false,
			sponsorsNameChanged: false,
			prizeAmountChanged: false,
			updatedTagChanged: false,
			fileChanged: false,
			challengeImagePathChanged: false,
			currentPhaseChanged: false,
			startDate: "",
			endDate: "",
			phaseId: "",
			otherThanPhaseTitle: "",
			phaseTitleForMultiPhaseChallenge: "",
			phaseTitleForMultiPhaseChallengeChanged: false,
			challengeType: "",
			solverSelectionTypeText: "allUsers",
			challengeTypeChanged: false,
			solverSelectionTypeTextChanged: false
		})
		this.fetchChallenge(this.props.challengeId)
	}

	componentDidUpdate(prevProps) {
		if (this.props.canEditChallenge != prevProps.canEditChallenge)
			this.setState({ canEditChallenge: prevProps.canEditChallenge })

		if (this.props.challengeId != prevProps.challengeId)
			this.setState({ challengeId: prevProps.challengeId })
	}

	componentDidMount() {
		if (this.props.canEditChallenge) {
			this.setState({
				canEditChallenge: true
			})
		}
		this.fetchChallenge(this.props.challengeId)
	}

	fetchChallenge(challengeId) {
		this.props.setLoadingSpinner()
		challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				console.log("challenge = ", data)
				var des = data.challengeDetails.description
				if (!des || des == "") des = "<p></p>\n"
				if (des != "<p></p>\n") {
					const blocksFromHTML = htmlToDraft(des)
					const state = ContentState.createFromBlockArray(
						blocksFromHTML.contentBlocks,
						blocksFromHTML.entityMap
					)
					let updatedEditorState = EditorState.createWithContent(state)
					this.setState({ editorState: updatedEditorState })
				}
				let currentPhaseDatas = {}
				if (data.challengeDetails.currentPhase) {
					currentPhaseDatas = {
						phaseId: data.challengeDetails.currentPhase.phaseId,
						phaseTitle: data.challengeDetails.currentPhase.phaseTitle,
						otherThanPhaseTitle: data.challengeDetails.currentPhase.phaseTitle,
						startDate: moment(data.challengeDetails.currentPhase.startDate),
						endDate: moment(data.challengeDetails.currentPhase.endDate)
					}
				}

				let solverSelectionTypeText = "allUsers"
				if (data.challengeDetails.solverSelectionType) {
					if (
						data.challengeDetails.solverSelectionType.allUsers &&
						data.challengeDetails.solverSelectionType.selectedUsers
					)
						solverSelectionTypeText = "both"
					else if (data.challengeDetails.solverSelectionType.selectedUsers)
						solverSelectionTypeText = "selectedUsers"
				}

				let canEditSolverSelection = false,
					isBaseChallenge = true,
					parentChallengeId = ""

				if (data.phaseLinks && data.challengeDetails.phaseTitle) {
					data.phaseLinks.map((p, index) => {
						if (p.challengeId == this.props.challengeId && index > 0) isBaseChallenge = false
						if (isBaseChallenge && p.challengeStatus != Constants.CHALLENGE_STATUS.DRAFT) {
							parentChallengeId = p.challengeId
						}
					})

					parentChallengeId = isBaseChallenge ? "" : parentChallengeId

					if (
						data.challengeDetails.challengeStatus == Constants.CHALLENGE_STATUS.DRAFT &&
						!isBaseChallenge
					) {
						canEditSolverSelection = true
					}
				}

				this.setState(
					{
						title: data.challengeDetails.title,
						externalUrl: data.challengeDetails.externalUrl,
						urlSlug: data.urlSlug,
						sponsorsName: data.challengeDetails.sponsorsName,
						shortDescription: data.challengeDetails.shortDescription,
						description: ReactHtmlParser(des || ""),
						prizeAmount: data.challengeDetails.prizeAmount,
						tags: data.challengeDetails.tags,
						challengeImagePath: data.challengeDetails.challengeImage,
						challengeStatus: data.challengeDetails.challengeStatus,
						phases: data.challengeDetails.phases,
						currentPhase: data.challengeDetails.currentPhase,
						...currentPhaseDatas,
						dataFetchDone: true,
						phaseTitleForMultiPhaseChallenge: data.challengeDetails.phaseTitle,
						phaseLinks: data.phaseLinks,
						challengeType: data.challengeDetails.challengeType,
						solverSelectionTypeText: solverSelectionTypeText,
						canUpdateSolverSelectionType: canEditSolverSelection,
						isBaseChallenge: isBaseChallenge,
						parentChallengeId: parentChallengeId
					},
					() => console.log("state = ", this.state)
				)

				this.props.resetLoadingSpinner()

				if (!isBaseChallenge && canEditSolverSelection && parentChallengeId) {
					this.setState({ participants: [] })
					this.fetchAndAdjustParticipants(this.props.challengeId, parentChallengeId).then(
						(participants) => {
							this.setState({ participants: participants })
						}
					)
				}
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				this.props.showAlert("error", "Opps!", err.message)
			})
	}

	fetchSolvers = (challengeId) => {
		return userServices
			.solvers(1, 1000000, challengeId)
			.then((solvers) => {
				console.log("solvers = ", solvers)
				if (!solvers) throw new Error("Error getting solo solvers")
				return solvers
			})
			.catch((err) => {
				console.log("Error fetching the solo solvers information, err =", err.message)
				return []
			})
	}

	fetchTeams = (challengeId) => {
		console.log("challengeId = ", challengeId)
		return challengeServices
			.getAllTeamsByChallenge(challengeId, 1, 1000000)
			.then((teams) => {
				console.log("teams = ", teams)
				if (!teams) throw new Error("Error getting teams")
				return teams
			})
			.catch((err) => {
				console.log("Error = ", err.message)
				return []
			})
	}

	fetchParticipants = (challengeId) => {
		console.log("challengeId = ", challengeId)
		let participants = []
		return Promise.all([this.fetchSolvers(challengeId), this.fetchTeams(challengeId)])
			.then((result) => {
				console.log("res = ", result)
				result[0].data.map((user) => {
					participants.push({
						userName: user.name,
						userId: user.userId,
						email: user.email
					})
				})
				result[1].data.map((team) => {
					participants.push({
						teamName: team.teamName,
						teamId: team.id
					})
				})

				return participants
			})
			.catch((err) => {
				console.log("Error getting participants list, err=", err.message)
				return participants
			})
	}

	fetchAndAdjustParticipants = (challengeId, parentChallengeId) => {
		return Promise.all([
			this.fetchParticipants(challengeId),
			this.fetchParticipants(parentChallengeId)
		])
			.then((res) => {
				let existingUserIdMapper = new Map(),
					existingTeamNameMapper = new Map()
				res[0].map((p) => {
					if (p.teamId) {
						existingTeamNameMapper.set(p.teamName, true)
					} else {
						existingUserIdMapper.set(p.userId, true)
					}
				})
				let participants = res[1].map((p) => {
					if (p.teamId) {
						if (existingTeamNameMapper.has(p.teamName)) return { ...p, checked: true }
						return { ...p }
					} else {
						if (existingUserIdMapper.has(p.userId)) return { ...p, checked: true }
						return { ...p }
					}
				})
				return participants
			})
			.catch((err) => {
				console.log(
					"Error fetching and adjusting participants of current phase with parent phase, err=",
					err.message
				)
				return []
			})
	}

	searchChallengeTags = (searchText) => {
		console.log("serachText =", searchText)
		return challengeTagServices
			.searchChallengeTags(searchText)
			.then((tags) => {
				console.log("tags = ", tags)
				if (!tags) throw new Error("No tags found")
				return tags.map((tag) => ({ label: tag, value: tag }))
			})
			.catch((err) => {
				console.log("error searching tags, err =", err.message)
				return []
			})
	}

	handleFileChange = (e) => {
		e.preventDefault()
		let reader = new FileReader()
		if (e.target.files.length == 0) {
			this.setState({
				file: null,
				fileName: null,
				fileChanged: false
			})
			return
		}
		let file = e.target.files[0]
		if (!file) {
			this.setState({ file: null, fileName: "" })
			return
		}
		reader.onloadend = () => {
			this.setState({
				file: { file },
				fileName: file.name,
				fileChanged: true
			})
			console.log("state: ", this.state)
		}
		reader.readAsDataURL(file)
	}

	uploadFile = (mutation) => {
		if (!this.state.file) {
			this.props.showAlert("warning", "Ooops!", "Please choose an image first")
		}
		if (this.state.file) {
			//console.log("File valid. lets call graphQL api!", this.state.file);
			mutation({
				mutation: uploadFileMutation,
				variables: { ...this.state.file, uploadType: "image" },
				fetchPolicy: "no-cache",
				context: {
				  headers: {
					'x-apollo-operation-name': 'upload-file'
				  }
				},
			})
				.then((res) => {
					if (res.data.uploadFile.status == true) {
						console.log("File upload response", res.data.uploadFile.data)
						let filePath = res.data.uploadFile.data.path
						this.setState({
							updatedChallengeImagePath: filePath,
							fileChanged: false,
							challengeImagePathChanged: true
						})
						this.props.showAlert("success", "Image uploaded", "Image uploaded sucessfully")
					} else {
						throw new Error(res.data.uploadFile.message)
					}
				})
				.catch((err) => {
					console.log("err = ", err.message)
					this.props.showAlert("error", "Error uploading image", err.message)
				})
		}
	}

	uploadImageCallBack = (file, mutation) => {
		console.log(file)
		console.log(mutation)
		return new Promise((resolve, reject) => {
			mutation({
				mutation: uploadFileMutation,
				variables: { file: file, uploadType: "image" },
				fetchPolicy: "no-cache",
				context: {
				  headers: {
					'x-apollo-operation-name': 'upload-file'
				  }
				},
			})
				.then((res) => {
					if (res.data.uploadFile.status == true) {
						console.log("File upload response", res.data.uploadFile.data)
						let filePath = res.data.uploadFile.data.path
						var uploadResponse = {
							data: { link: Constants.Settings.APIURL + filePath }
						}
						resolve(uploadResponse)
					} else {
						throw new Error(res.data.uploadFile.message)
					}
				})
				.catch((err) => {
					reject(err.message)
				})
		})
	}

	handleConfirmStatusChange = (status) => {
		console.log("About to status change = ", status)
		this.props.setLoadingSpinner()
		challengeServices
			.adminUpdateChallengeStatus(this.props.challengeId, status)
			.then((data) => {
				console.log("updated challenge status data = ", data)
				this.props.resetLoadingSpinner()
				this.fetchChallenge(this.props.challengeId)
				this.setState({
					isConfirmDialogueOpen: false,
					challengeStatus: data.challengeDetails.challengeStatus
				})
			})
			.catch((err) => {
				console.log("Error updating challenge status, err = ", err.message)
				this.props.resetLoadingSpinner()
				this.setState({ isConfirmDialogueOpen: false })
				this.props.showAlert("error", "Opps!", err.message)
			})
	}

	renderConfirmDialogue = () => {
		if (!this.state.isConfirmDialogueOpen) return null
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isConfirmDialogueOpen: false })}
			>
				<DialogTitle> Are you sure? </DialogTitle>
				<DialogContent>
					<DialogContentText>{this.state.confirmDialogText}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.setState({ isConfirmDialogueOpen: false })} color="tumbler">
						No
					</Button>
					<Button
						onClick={() => this.handleConfirmStatusChange(this.state.updatedStatus)}
						color={
							this.state.updatedStatus == Constants.CHALLENGE_STATUS.CLOSED ? "youtube" : "success"
						}
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderConfirmDialogueForChangeSolverSelection = () => {
		if (!this.state.isConfirmDialogueOpenSolverSelection) return null
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isConfirmDialogueOpenSolverSelection: false })}
			>
				<DialogTitle> Are you sure? </DialogTitle>
				<DialogContent>
					<DialogContentText>
						You can change Solver Selection Type as many times as you want only before publishing
						the challenge. Sure you want to change?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() =>
							this.setState({ isConfirmDialogueOpenSolverSelection: false, tempSST: "" })
						}
						color="tumbler"
					>
						No
					</Button>
					<Button
						onClick={() =>
							this.setState({
								isConfirmDialogueOpenSolverSelection: false,
								solverSelectionTypeText: this.state.tempSST,
								solverSelectionTypeTextChanged: true,
								tempSST: ""
							})
						}
						color="success"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderConfirmDialogueForDeleteChallenge = () => {
		if (!this.state.isConfirmDialogueOpenDeleteChallenge) return null
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isConfirmDialogueOpenDeleteChallenge: false })}
			>
				<DialogTitle> Are you sure? </DialogTitle>
				<DialogContent>
					<DialogContentText>Do you really want to delete this challenge?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => this.setState({ isConfirmDialogueOpenDeleteChallenge: false })}
						color="tumbler"
					>
						No
					</Button>
					<Button
						onClick={() => {
							this.setState({
								isConfirmDialogueOpenDeleteChallenge: false
							})
							this.deleteChallenge()
						}}
						color="danger"
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	isStartDateValid = (currentDate, selectedDate) => {
		if (this.state.endDate) return currentDate.isBefore(this.state.endDate)
		return true
	}

	isEndDateValid = (currentDate, selectedDate) => {
		if (this.state.startDate) return currentDate.isAfter(this.state.startDate)
		return true
	}

	deleteChallenge = () => {
		this.props.setLoadingSpinner()
		challengeServices
			.deleteChallenge(this.state.challengeId)
			.then((res) => {
				if (!res) throw new Error("Error occured while deleteing the challenge")
				console.log("Challenge is deleted")
				this.props.resetLoadingSpinner()
				this.props.history.push("/dashboard/index")
			})
			.catch((err) => {
				console.log("Error deleting the challenge, err=", err.message)
				this.props.resetLoadingSpinner()
				this.props.showAlert("error", "Opps!", err.message)
			})
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				{this.state.alert}
				{this.renderConfirmDialogueForChangeSolverSelection()}
				{this.renderConfirmDialogueForDeleteChallenge()}
				{this.state.dataFetchDone && !this.state.showSolverSelectionUI
					? this.state.editMode
						? this.renderEditModeUI()
						: this.renderOverViewModeUI()
					: this.state.dataFetchDone && (
							<div>
								<UsersTeamsListSelection
									participants={this.state.participants}
									allowSelectNone={true}
									onCancel={(e) => {
										this.setState({ editMode: false, showSolverSelectionUI: false })
									}}
									onAddSelected={(data) => {
										console.log("Here is the list of selected solvers/teams, data =", data)
										this.addSelectedUsersTeams(data)
									}}
								/>
							</div>
					  )}
			</div>
		)
	}

	renderEditModeUI = () => {
		const { classes } = this.props

		let phases = this.state.phases || []
		let phaseSelectMenu = phases.map((p) => {
			return {
				value: p.phaseId,
				label: p.title,
				startDate: p.startDate,
				endDate: p.endDate
			}
		})
		phaseSelectMenu.push({ value: "other", label: "Other", startDate: "", endDate: "" })
		let currentPhaseSelectValue = this.state.phaseId
		let otherThanPhaseTitle = this.state.phaseTitle

		return (
			<Card raised color="gmgTheme" className={classes.overviewPageBackgroundStyle}>
				<CardBody>
					<GridContainer direction="column" justify="center" allignment="flex-start">
						<GridItem>
							<GridContainer direction="row" justify="flex-start">
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<TextField
										label="Title:"
										className={classes.inputRoot}
										fullWidth
										InputLabelProps={{
											className: classes.inputLabel
										}}
										inputProps={{
											className: classes.textField,
											value: this.state.title,
											onChange: this.handleSimple,
											name: "title",
											overflow: "auto"
										}}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>

						<GridItem className={classes.updatePriceStyle}>
							<GridContainer direction="row" justify="flex-start">
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<TextField
										label="Phase Title:"
										className={classes.inputRoot}
										fullWidth
										InputLabelProps={{
											className: classes.inputLabel
										}}
										inputProps={{
											className: classes.textField,
											value: this.state.phaseTitleForMultiPhaseChallenge,
											onChange: this.handleSimple,
											name: "phaseTitleForMultiPhaseChallenge",
											overflow: "auto"
										}}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>

						<GridItem className={classes.updatePriceStyle}>
							<GridContainer direction="row" justify="flex-start">
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<TextField
										label="URL Slug:"
										className={classes.inputRoot}
										fullWidth
										InputLabelProps={{
											className: classes.inputLabel
										}}
										inputProps={{
											className: classes.textField,
											value: this.state.urlSlug,
											onChange: this.handleSimple,
											name: "urlSlug",
											overflow: "auto"
										}}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>

						<GridItem className={classes.updatePriceStyle}>
							<GridContainer direction="row" justify="flex-start">
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<TextField
										label="Summary:"
										className={classes.inputRoot}
										fullWidth
										multiline
										//rows="5"
										InputLabelProps={{
											className: classes.inputLabel
										}}
										inputProps={{
											className: classes.textField,
											value: this.state.shortDescription,
											onChange: this.handleSimple,
											name: "shortDescription"
										}}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>

						<GridItem className={classes.updatePriceStyle}>
							<GridContainer direction="row" justify="flex-start">
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<TextField
										label="Sponsor's Name:"
										className={classes.inputRoot}
										fullWidth
										//multiline
										//rows="5"
										InputLabelProps={{
											className: classes.inputLabel
										}}
										inputProps={{
											className: classes.textField,
											value: this.state.sponsorsName,
											onChange: this.handleSimple,
											name: "sponsorsName"
										}}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>

						<GridItem className={classes.updatePriceStyle}>
							<GridContainer direction="row" justify="flex-start">
								<GridItem xs={12} sm={12} md={12} lg={12}>
									<TextField
										label="External URL:"
										className={classes.inputRoot}
										fullWidth
										InputLabelProps={{
											className: classes.inputLabel
										}}
										inputProps={{
											className: classes.textField,
											value: this.state.externalUrl,
											onChange: this.handleSimple,
											name: "externalUrl"
										}}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>

						<GridItem className={classes.updatePriceStyle}>
							<div>
								<p style={{ color: "green" }}> Challenge Description: </p>
								<div style={{ border: "1px solid grey" }}>
									<FontOverridePatch isDraftEditor>
										<Mutation mutation={uploadFileMutation} fetchPolicy="no-cache" context={{ headers: { 'x-apollo-operation-name': 'upload-file' } }}>
											{(mutation, { loading }) => (
												<Editor
													editorState={this.state.editorState}
													toolbarClassName={classes.toolbarClassName}
													wrapperClassName={classes.wrapperClassName}
													editorClassName={classes.cccEditorStyle}
													toolbar={{
														options: [
															"inline",
															"blockType",
															"fontSize",
															"fontFamily",
															"list",
															"textAlign",
															"link",
															"embedded",
															"remove",
															"history",
															"image"
														],
														image: {
															uploadCallback: function (file) {
																return self.uploadImageCallBack(file, mutation)
															},
															previewImage: true,
															alt: { present: true, mandatory: true },
															defaultSize: {
																height: "auto",
																width: "100%"
															}
														}
													}}
													onEditorStateChange={this.onEditorStateChange}
												/>
											)}
										</Mutation>
									</FontOverridePatch>
								</div>
							</div>
						</GridItem>
						<GridItem>
							<GridContainer direction="row">
								<GridItem xs={12} sm={4}>
									<TextField
										label="Challenge Cover:"
										className={classes.inputRoot}
										fullWidth
										InputLabelProps={{
											className: classes.inputLabel,
											shrink: true
										}}
										inputProps={{
											className: classes.textField,
											type: "file",
											name: "profileAvatar",
											onChange: this.handleFileChange
										}}
									/>
								</GridItem>
								<GridItem xs={12} sm={8}>
									<Mutation mutation={uploadFileMutation} fetchPolicy="no-cache" context={{ headers: { 'x-apollo-operation-name': 'upload-file' } }}>
										{(mutation, { loading }) => (
											<Button
												className={`${classes.buttonSuccessColor} ${classes.buttonMinWidthStyle}`}
												disabled={!this.state.fileChanged}
												onClick={() => this.uploadFile(mutation)}
											>
												Upload Image
											</Button>
										)}
									</Mutation>
								</GridItem>
							</GridContainer>
						</GridItem>

						<GridItem className={classes.updatePriceStyle}>
							<GridContainer direction="row">
								<GridItem xs={12} sm={4}>
									<TextField
										label="Prize Amount:"
										className={classes.inputRoot}
										fullWidth
										InputLabelProps={{
											className: classes.inputLabel
										}}
										inputProps={{
											className: classes.textField,
											value: this.state.prizeAmount,
											onChange: this.handleSimpleForPrize,
											name: "prizeAmount",
											startAdornment: (
												<InputAdornment position="start">
													<Typography
														className={`${classes.whiteTextColorStyle}`}
														variant="title"
														component="p"
													>
														${" "}
													</Typography>
												</InputAdornment>
											),
											overflow: "auto"
										}}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>

						<GridItem>
							<GridContainer direction="row">
								<GridItem sx={12} sm={12}>
									<SearchAndSelectMultipleItem
										inputLabel={"Select Tags"}
										placeholder={"Type to select tags"}
										allowCreateNew
										textTransformToLower
										selectedItems={this.state.tags.map((t) => ({
											label: t.tag,
											value: t.tag
										}))}
										setSelectedItems={(data) => {
											let updatedTags = data.map((t) => ({ tag: t.value }))
											this.setState({
												updatedTags: updatedTags,
												updatedTagChanged: true
											})
										}}
										searchItemsPromise={this.searchChallengeTags}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>

						<GridItem>
							{!this.state.isBaseChallenge && (
								<React.Fragment>
									<h4 className={classes.infoText}>Solver Selection Type</h4>
									<FormControl component="fieldset" className={classes.formControl}>
										<RadioGroup
											aria-label="solverSelectionTypeText"
											name="solverSelectionTypeText"
											className={classes.group}
											value={this.state.solverSelectionTypeText}
											onChange={(e) => {
												this.setState({
													isConfirmDialogueOpenSolverSelection: true,
													tempSST: e.target.value
												})
											}}
											row={true}
										>
											<FormControlLabel
												value="allUsers"
												disabled={!this.state.canUpdateSolverSelectionType}
												control={<Radio />}
												label="All Users"
											/>

											<FormControlLabel
												value="selectedUsers"
												disabled={!this.state.canUpdateSolverSelectionType}
												control={<Radio />}
												label="Selected Users"
											/>
											<FormControlLabel
												value="both"
												disabled={!this.state.canUpdateSolverSelectionType}
												control={<Radio />}
												label="Both"
											/>
										</RadioGroup>
										{!this.state.canUpdateSolverSelectionType && (
											<FormHelperText>
												<span style={{ color: "red" }}>
													* You can not change the Solver Selection Type once the challenge is
													published
												</span>
											</FormHelperText>
										)}
									</FormControl>
									<div style={{ height: "30px" }}> </div>
								</React.Fragment>
							)}
						</GridItem>

						<GridItem>
							<h4 className={classes.infoText}> Challenge Type</h4>
							<FormControl component="fieldset" className={classes.formControl}>
								<RadioGroup
									aria-label="challengeType"
									name="challengeType"
									className={classes.group}
									value={this.state.challengeType}
									onChange={this.handleSimple}
									row={true}
								>
									<FormControlLabel value="video" control={<Radio />} label="Video" />
									<FormControlLabel value="subjective" control={<Radio />} label="Subjective" />
									<FormControlLabel value="data" control={<Radio />} label="Data" />
									<FormControlLabel value="algorithm" control={<Radio />} label="Algorithm" />
								</RadioGroup>
							</FormControl>
						</GridItem>

						<Card color="gmgTheme">
							<CardHeader>
								<h4 className={classes.whiteTextColorStyle}> Select current phase information </h4>
							</CardHeader>
							<CardBody color="gmgTheme">
								<GridItem>
									<div style={{ marginBottom: "20px" }}>
										<GridContainer direction="row">
											<GridItem xs={12} md={6}>
												<div style={{ marginBottom: "20px" }}>
													<InputLabel className={classes.whiteTextColorStyle}>
														Select Current Phase:{" "}
													</InputLabel>

													<Select
														fullWidth
														style={{ padding: "2px 10px" }}
														value={currentPhaseSelectValue}
														name={"Select Current Phase"}
														onChange={(e) => {
															console.log("Select onChange =", e.target.value)
															let p = phaseSelectMenu.find((item) => item.value == e.target.value)
															let state = {}
															if (p && p.startDate) state.startDate = moment(Date(p.startDate))
															if (p && p.endDate) state.endDate = moment(p.endDate)
															this.setState({
																phaseId: e.target.value,
																phaseTitle: "",
																otherThanPhaseTitle: "",
																currentPhaseChanged: true,
																...state
															})
														}}
													>
														{phaseSelectMenu.map((p) => {
															return <MenuItem value={p.value}> {p.label} </MenuItem>
														})}
													</Select>
												</div>
											</GridItem>

											{currentPhaseSelectValue == "other" && (
												<GridItem xs={12} md={6}>
													<div style={{ marginBottom: "20px" }}>
														<InputLabel className={classes.whiteTextColorStyle}>
															Phase name to show:{" "}
														</InputLabel>
														<TextField
															fullWidth
															//label="Phase name to show:"
															className={classes.inputRoot}
															InputLabelProps={{
																className: classes.inputLabel
															}}
															value={this.state.otherThanPhaseTitle}
															onChange={(e) => {
																this.setState({
																	otherThanPhaseTitle: e.target.value,
																	currentPhaseChanged: true
																})
															}}
															name={"otherThanPhaseTitle"}
															inputProps={{
																className: classes.textField,
																overflow: "auto"
															}}
														/>
													</div>
												</GridItem>
											)}
										</GridContainer>

										<GridContainer direction="row">
											<GridItem xs={12} md={6}>
												<div style={{ marginBottom: "20px" }}>
													<InputLabel className={classes.whiteTextColorStyle}>
														Select Start Date:{" "}
													</InputLabel>
													<FormControl FormHelperText="Select Start Date" fullWidth>
														<Datetime
															isValidDate={this.isStartDateValid}
															value={this.state.startDate}
															closeOnSelect={true}
															timeFormat={true}
															onChange={(value) =>
																this.setState({ startDate: value, currentPhaseChanged: true })
															}
															inputProps={{
																onKeyDown: (event) => {
																	return event.preventDefault()
																}
															}}
														/>
													</FormControl>
												</div>
											</GridItem>
											<GridItem xs={12} md={6}>
												<div style={{ marginBottom: "20px" }}>
													<InputLabel className={classes.whiteTextColorStyle}>
														Select End Date:{" "}
													</InputLabel>
													<FormControl FormHelperText="Select End Date" fullWidth>
														<Datetime
															isValidDate={this.isEndDateValid}
															value={this.state.endDate}
															closeOnSelect={true}
															timeFormat={true}
															onChange={(value) =>
																this.setState({ endDate: value, currentPhaseChanged: true })
															}
															inputProps={{
																onKeyDown: (event) => {
																	return event.preventDefault()
																}
															}}
														/>
													</FormControl>
												</div>
											</GridItem>
										</GridContainer>
									</div>
								</GridItem>
							</CardBody>
						</Card>
						<GridItem>
							<GridContainer direction="row" justify="flex-start" allignment="flex-end">
								<GridItem>
									<Button
										disabled={!this.isUpdateChallengeDataChanged()}
										className={`${classes.buttonMinWidthStyle} ${classes.buttonSuccessColor}`}
										onClick={this.handleOnClickUpdateChallenge}
									>
										Update
									</Button>
								</GridItem>
								<GridItem>
									<Button color="warning" onClick={this.handleOnClickGoBack}>
										Cancel
									</Button>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</CardBody>
			</Card>
		)
	}

	renderOverViewModeUI = () => {
		const { classes } = this.props
		return (
			<div className={classes.overviewDiv}>
				{this.renderConfirmDialogue()}
				<Card raised color="gmgTheme" className={classes.overviewPageBackgroundStyle}>
					<CardMedia>
						{this.state.challengeImagePath ? (
							<img
								width={"400px"}
								src={Constants.Settings.APIURL + this.state.challengeImagePath}
								alt="Image of challenge cover"
								onError={(event) => {
									event.target.src = defaultChallengeImage
								}}
							/>
						) : (
							<img
								width={"400px"}
								src={defaultChallengeImage}
								alt="Image of challenge cover default"
							/>
						)}
					</CardMedia>
					<CardContent>
						<Typography
							className={`${classes.displayPrizeAmountStyle} ${classes.whiteTextColorStyle}`}
							variant="body2"
							component="p"
						>
							<Typography
								className={`${classes.whiteTextColorStyle}`}
								variant="title"
								component="p"
							>
								Title:
							</Typography>
							{this.state.title}
						</Typography>
						<Typography
							className={`${classes.displayPrizeAmountStyle} ${classes.whiteTextColorStyle}`}
							variant="body2"
							component="p"
						>
							<Typography
								className={`${classes.whiteTextColorStyle}`}
								variant="title"
								component="p"
							>
								Summary:
							</Typography>
							{this.state.shortDescription}
						</Typography>

						<Typography
							className={`${classes.displayPrizeAmountStyle} ${classes.whiteTextColorStyle}`}
							variant="body2"
							component="p"
						>
							<Typography
								className={`${classes.whiteTextColorStyle}`}
								variant="title"
								component="p"
							>
								Description:
							</Typography>
							<FontOverridePatch>{this.state.description}</FontOverridePatch>
						</Typography>
						
						<Typography
							className={`${classes.displayPrizeAmountStyle} ${classes.whiteTextColorStyle}`}
							variant="body2"
							component="p"
						>
							<Typography
								className={`${classes.whiteTextColorStyle}`}
								variant="title"
								component="p"
							>
								External URL:
							</Typography>
							<FontOverridePatch>{this.state.externalUrl}</FontOverridePatch>
						</Typography>

						<GridContainer direction="row" className={classes.displayPrizeAmountStyle}>
							<GridItem>
								<Typography
									variant="title"
									component="p"
									className={`${classes.whiteTextColorStyle}`}
								>
									Prize Amount:&nbsp;&nbsp;
									<Chip
										label={challengeUtils.getPrizeFormatted(this.state.prizeAmount)}
										className={classes.prizeAmountChipStyle}
									/>
								</Typography>
							</GridItem>
						</GridContainer>
						{this.state.tags && this.state.tags.length != 0 ? (
							<GridContainer direction="row" className={classes.displayPrizeAmountStyle}>
								<GridItem>
									<Typography
										variant="title"
										component="p"
										className={`${classes.whiteTextColorStyle}`}
									>
										Tags: {this.renderTags()}
									</Typography>
								</GridItem>
							</GridContainer>
						) : null}
						{this.renderActionButtons()}
					</CardContent>
				</Card>
			</div>
		)
	}

	renderActionButtons = () => {
		const { classes } = this.props
		if (!this.state.canEditChallenge) return null
		return (
			<GridContainer direction="row" justify="flex-end" allignment="center">
				{this.state.challengeStatus == Constants.CHALLENGE_STATUS.DRAFT &&
				this.state.canUpdateSolverSelectionType &&
				(this.state.solverSelectionTypeText == "selectedUsers" ||
					this.state.solverSelectionTypeText == "both") ? (
					<GridItem>
						<Tooltip title="Select Solvers/Teams from existing phase to current phase">
							<div>
								<Button
									color="gmgGreen"
									//className={`${classes.buttonSuccessColor} ${classes.editChallengeButtonStyle}`}
									onClick={() => {
										this.setState({
											editMode: false,
											showSolverSelectionUI: true
										})
									}}
								>
									Select Solvers/Teams
								</Button>
							</div>
						</Tooltip>
					</GridItem>
				) : null}

				{this.state.challengeStatus == Constants.CHALLENGE_STATUS.DRAFT ? (
					<GridItem>
						<Tooltip title="Delete this draft Challenge">
							<div>
								<Button
									color="danger"
									//className={`${classes.buttonSuccessColor} ${classes.editChallengeButtonStyle}`}
									onClick={() => {
										this.setState({
											editMode: false,
											isConfirmDialogueOpenDeleteChallenge: true
										})
									}}
								>
									Delete Draft {this.state.phaseLinks.length > 1 ? "Phase" : "Challenge"}
								</Button>
							</div>
						</Tooltip>
					</GridItem>
				) : null}

				<GridItem>
					<Tooltip title="Edit challenge details">
						<div>
							<Button
								color="gmgGreen"
								//className={`${classes.buttonSuccessColor} ${classes.editChallengeButtonStyle}`}
								onClick={() => {
									this.goToEditOverview()
								}}
							>
								Edit
							</Button>
						</div>
					</Tooltip>
				</GridItem>
				{this.state.challengeStatus == Constants.CHALLENGE_STATUS.DRAFT ? (
					<GridItem>
						<Tooltip title="Publish">
							<div>
								<Button
									color="success"
									//className={`${classes.editChallengeButtonStyle}`}
									onClick={() => {
										this.setState({
											isConfirmDialogueOpen: true,
											confirmDialogText: "Are you sure you want to publish this challenge?",
											updatedStatus: Constants.CHALLENGE_STATUS.ACTIVE
										})
									}}
								>
									Publish
								</Button>
							</div>
						</Tooltip>
					</GridItem>
				) : null}

				{this.state.challengeStatus == Constants.CHALLENGE_STATUS.CLOSED ? (
					<GridItem>
						<Tooltip title="Resume/Restart this challenge again">
							<div>
								<Button
									color="success"
									//className={`${classes.editChallengeButtonStyle}`}
									onClick={() => {
										this.setState({
											isConfirmDialogueOpen: true,
											confirmDialogText: "Are you sure you want to resume/restart this challenge?",
											updatedStatus: Constants.CHALLENGE_STATUS.ACTIVE
										})
									}}
								>
									Restart
								</Button>
							</div>
						</Tooltip>
					</GridItem>
				) : null}

				{this.state.challengeStatus == Constants.CHALLENGE_STATUS.ACTIVE ? (
					<GridItem>
						<Tooltip title="Complete this challenge means that all phases of this challenge have been completed successfully">
							<div>
								<Button
									color="success"
									//className={`${classes.editChallengeButtonStyle}`}
									onClick={() => {
										this.setState({
											isConfirmDialogueOpen: true,
											confirmDialogText:
												"Are you sure you want to complete this challenge? Completing this challenge concludes the challenge and is irreversible.",
											updatedStatus: Constants.CHALLENGE_STATUS.COMPLETED
										})
									}}
								>
									Complete
								</Button>
							</div>
						</Tooltip>
					</GridItem>
				) : null}
				{this.state.challengeStatus == Constants.CHALLENGE_STATUS.ACTIVE ? (
					<GridItem>
						<Tooltip title="Close this challenge means this challenge will no longer be public, and admin level users can reopen this challenge">
							<div>
								<Button
									color="danger"
									//className={`${classes.editChallengeButtonStyle}`}
									onClick={() => {
										this.setState({
											isConfirmDialogueOpen: true,
											confirmDialogText: "Are you sure you want to close this challenge?",
											updatedStatus: Constants.CHALLENGE_STATUS.CLOSED
										})
									}}
								>
									Close
								</Button>
							</div>
						</Tooltip>
					</GridItem>
				) : null}
			</GridContainer>
		)
	}
}

Overview.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

let finalStyle = combineStyles(styles, commonStyles)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(finalStyle)(Overview))
