import userServices from "dataServices/userServices"
import apolloFetcher from "apolloFetcher.js"

function hasPermission(permissionName, objectId) {
	var query = `
        query hasPermission($permissionName: String!, $objectId: String){
            hasPermission(
                permissionName:$permissionName
                objectId:$objectId
            ){
                status
                message
                data
                totalCount
            }
        }
    `
	const variables = {
		permissionName: permissionName,
		objectId: objectId
	}

	return new Promise(function (resolve, reject) {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				console.log("inside hasPermission function: ", res.data.hasPermission.data)
				resolve(res.data.hasPermission.data)
			})
			.catch((err) => {
				console.log("error getting user by userId", err)
				reject(err)
			})
	})
}

function hasMultiplePermissions(requiredPermissionList) {
	//requiredPermissionList is an array of object like { permissionName: "CanJoinChallenge" objectId: "" }
	var query = `
        query hasMultiplePermissions($multiplePermissionInput: [permissionDataInput]){
            hasMultiplePermissions(
                input:$multiplePermissionInput
            ){
                status
                message
                data{
                    permissionName
                    permissionStatus
                }
                totalCount
            }
        }
    `

	const variables = {
		multiplePermissionInput: requiredPermissionList
	}

	return new Promise(function (resolve, reject) {
		return apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				resolve(res.data.hasMultiplePermissions.data) // returns an array of { permissionName: "" , permissionStatus: "" }
			})
			.catch((err) => {
				console.log("Error getting multiple permission response data: ", err.message)
				reject(err)
			})
	})
}

function isSuperAdmin() {
	return userServices
		.getCurrentUser(`roles{ RoleId }`)
		.then((data) => {
			let roles = data.roles
			console.log("user roles inside authentication: ", roles)
			let i
			for (i in roles) {
				if (roles[i].RoleId == "superAdmin") {
					return true
				}
			}
			return false
		})
		.catch((err) => {
			console.log("Err = ", err.message)
			return false
		})
}

export default { isSuperAdmin, hasPermission, hasMultiplePermissions }
