import apolloFetcher from "apolloFetcher"

let discussionServices = {}

discussionServices.getDiscussionById = (discussionId) => {
	let query = `
        query getDiscussionById($discussionId: String!){
            getDiscussionById(
            discussionId:$discussionId
            ){
            status
            message
            data{
                discussionId
                challengeId
                discussionDetails{
                title
                discussionContent
                favourateBy
                createdBy{
                    userId
                    username
                    userName
                    avatarImagePath
                }
                createdAt
                replies{
                    replyId
                    parentId
                    replyContent
                    createdBy{
                        userId
                        username
                        userName
                        avatarImagePath
                    }
                    createdAt
                }
                }
            }
            totalCount
            }
        }
    `
	let variables = {
		discussionId: discussionId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.getDiscussionById)
					throw new Error("Error occurred while fetching discussion by Id")
				if (!res.data.getDiscussionById.status) throw new Error(res.data.getDiscussionById.message)
				resolve(res.data.getDiscussionById.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

discussionServices.getDiscussionByChallengeId = (pageNo = 0, itemPerPage = 10, challengeId) => {
	let query = `
        query getDiscussionByChallengeId($pageNo: Int, $itemPerPage: Int, $challengeId: String!){
            getDiscussionByChallengeId(
            pageNo:$pageNo
            itemPerPage:$itemPerPage
            challengeId:$challengeId
            ){
            status
            message
            totalCount
            data{
                discussionId
                challengeId
                discussionDetails{
                title
                discussionContent
                favourateBy
                createdBy{
                    userId
                    username
                    userName
                    avatarImagePath
                }
                createdAt
                replies{
                    replyId
                    parentId
                    replyContent
                    createdBy{
                        userId
                        username
                        userName
                        avatarImagePath
                    }
                    createdAt
                }
                }
            }
            }
        }
    `
	let variables = {
		pageNo: pageNo,
		itemPerPage: itemPerPage,
		challengeId: challengeId
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.getDiscussionByChallengeId)
					throw new Error("Error occurred while fetching discussion list by challenge Id")
				if (!res.data.getDiscussionByChallengeId.status)
					throw new Error(res.data.getDiscussionByChallengeId.message)
				resolve(res.data.getDiscussionByChallengeId.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

discussionServices.createOrUpdateDiscussion = (variables) => {
	let query = `
        mutation createOrUpdateDiscussion(
            $discussionId: String, 
            $challengeId: String!, 
            $title: String,
            $discussionContent: String,
            $favourateBy:[String],
            $replies:[replyDataInput]
        ){
            createOrUpdateDiscussion(
            input:{
                discussionId: $discussionId
                challengeId:$challengeId
                discussionDetails:{
                title:$title
                discussionContent: $discussionContent
                favourateBy:$favourateBy
                replies:$replies
                }
            }
            ){
            status
            message
            totalCount
            data{
                discussionId
                challengeId
                discussionDetails{
                title
                discussionContent
                favourateBy
                createdBy{
                    userId
                    username
                    userName
                    avatarImagePath
                }
                createdAt
                replies{
                    replyId
                    parentId
                    replyContent
                    createdBy{
                        userId
                        username
                        userName
                        avatarImagePath
                    }
                    createdAt
                }
                }
            }
            }
        }
    `

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.createOrUpdateDiscussion)
					throw new Error("Error occurred while creating or updating discussion")
				if (!res.data.createOrUpdateDiscussion.status)
					throw new Error(res.data.createOrUpdateDiscussion.message)
				resolve(res.data.createOrUpdateDiscussion.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

discussionServices.deleteDiscussionById = (discussionId) => {
	let query = `
        mutation deleteDiscussionById($discussionId: String!){
            deleteDiscussionById(
            discussionId:$discussionId
            ){
            status
            message
            data
            totalCount
            }
        }
    `
	let variables = {
		discussionId: discussionId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.deleteDiscussionById)
					throw new Error("Error occurred while deleting discussion by Id")
				if (!res.data.deleteDiscussionById.status)
					throw new Error(res.data.deleteDiscussionById.message)
				resolve(res.data.deleteDiscussionById.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

discussionServices.deleteDiscussionByChallengeId = (challengeId) => {
	let query = `
        mutation deleteDiscussionByChallengeId($challengeId: String!){
            deleteDiscussionByChallengeId(
            challengeId:$challengeId
        ){
            status
            message
            data
            totalCount
            }
        }
    `

	let variables = {
		challengeId: challengeId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.deleteDiscussionByChallengeId)
					throw new Error("Error occurred while deleting discussion list by challenge Id")
				if (!res.data.deleteDiscussionByChallengeId.status)
					throw new Error(res.data.deleteDiscussionByChallengeId.message)
				resolve(res.data.deleteDiscussionByChallengeId.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

discussionServices.deleteReplyById = (discussionId, replyId) => {
	let query = `
        mutation deleteReplyById($discussionId: String!, $replyId: String!){
            deleteReplyById(
            discussionId:$discussionId
            replyId:$replyId
            ){
            status
                message
            data{
                discussionId
                challengeId
                discussionDetails{
                title
                discussionContent
                favourateBy
                createdBy{
                    userId
                    username
                    userName
                    avatarImagePath
                }
                createdAt
                replies{
                    replyId
                    parentId
                    replyContent
                    createdBy{
                        userId
                        username
                        userName
                        avatarImagePath
                    }
                    createdAt
                }
                }
            }
            totalCount
            }
        }
    `
	let variables = {
		discussionId: discussionId,
		replyId: replyId
	}

	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({
				query,
				variables
			})
			.then((res) => {
				if (!res || !res.data || !res.data.deleteReplyById)
					throw new Error("Error occurred while deleting reply by discussion Id and reply Id")
				if (!res.data.deleteReplyById.status) throw new Error(res.data.deleteReplyById.message)
				resolve(res.data.deleteReplyById.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export default discussionServices
