import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import apolloFetcher from "../../../apolloFetcher";
import combineStyles from "utils/combineStyle";
import cookiesHelper from "../../../cookiesHelper";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

let extraStyle = theme => ({
    ...sweetAlertStyle
})

class SubmitStepTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            submitAsValue: null,
            alert: null
        }
    }

    warningWithConfirmMessage = (value) => {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", color: "black" }}
                    title="Final Submission"
                    onConfirm={() => this.onConfirmFunction(value)}
                    onCancel={() => this.onCancelFunction()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Acknowledge"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    It looks like you are submitting a final. Choose
                    acknowledge if you have
                    read and understand the
                    “Terms of Use” instructions
                    and “Challenge Rules”.
            </SweetAlert>
            )
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    onConfirmFunction = (value) => {
        console.log(value);
        this.setState({ submitAsValue: value });
        this.props.getValueFromSubmit(value);
        this.hideAlert();
    }

    onCancelFunction = () => {
        this.setState({
            submitAsValue: null
        })
        this.hideAlert();
    }

    componentDidMount() {
        console.log("SubmitStepTab componentDidMount");
    }

    handleChange = event => {
        if (event.target.value === "final") {
            this.warningWithConfirmMessage(event.target.value);
        }
        else {
            this.setState({ submitAsValue: event.target.value });
            this.props.getValueFromSubmit(event.target.value);
        }
    };

    sendState() {
        return this.state;
    }

    render() {
        const { classes } = this.props;
        return (
            <div style={{ backgroundColor: "#302F2F", color: "#FFF" }}>
                {this.state.alert}
                <GridContainer direction="row" justify="center">
                    <GridItem xs={12} sm={12}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <RadioGroup
                                aria-label="Ideation"
                                name="Ideation1"
                                className={classes.group}
                                value={this.state.submitAsValue}
                                onChange={this.handleChange}
                            >
                                <FormControlLabel value="draft" control={<Radio />} label="Submit as draft" />
                                <FormControlLabel value="final" control={<Radio />} label="Submit as final" />
                            </RadioGroup>
                        </FormControl>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

export default withStyles(extraStyle)(SubmitStepTab);