import React from 'react';

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core';
import ReactHtmlParser from 'html-react-parser';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import TableCell from 'views/Components/TableCellStyled';

import FontOverridePatch from 'utils/FontOverridePatch';
import combineStyles from 'utils/combineStyle';
import tableStyle from 'assets/jss/material-dashboard-pro-react/components/tableStyle';

import { evaluationScoreDetailsTableRow } from './constants';

const EvaluatorScoreDetails = props => {
  const { currentScoreData, classes } = props;

  const renderTableHeader = () => {
    return (
      <TableHead className={classes.tableHead}>
        <TableRow>
          {evaluationScoreDetailsTableRow.map((value, index) => {
            return (
              <TableCell style={value.style} key={`table-header-${index}`}>
                {value.title}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  };

  const renderTableBodyRow = (score, scoreIndex) => {
    const { criteriaBasedScore, evaluatorUser } = score.evaluationScoreDetails;
    const rowSpanCount = (criteriaBasedScore || []).length;
    let totalScore = 0;
    criteriaBasedScore.forEach((criteriaScore) => totalScore += criteriaScore.scoreValue);

    const columns = criteriaBasedScore.map((criteriaScore, index) => {
        const nameColumn = (
          <TableCell align="left" rowSpan={rowSpanCount + 1}>
            {`Evaluator ${scoreIndex}`}
          </TableCell>
        );

        const othersColumn = [
          <TableCell align="left">
            <FontOverridePatch>
              {ReactHtmlParser(criteriaScore.criteriaName || "")}
            </FontOverridePatch>
          </TableCell>,
          <TableCell align="left"> {criteriaScore.scoreValue} </TableCell>,
          <TableCell align="left"> {criteriaScore.commentText} </TableCell>
        ];

        if (index == 0) {
          return [nameColumn, ...othersColumn];
        }

        return othersColumn;
      }
    );

    columns.push([
      <TableCell align="left"> Total </TableCell>,
      <TableCell align="left" colSpan={2}>
        {totalScore}
      </TableCell>
    ]);

    return columns.map((column) => {
      return (
        <TableRow>
          {column.map((col) => col)}
        </TableRow>
      );
    });
  };

  const renderTableBody = () => {
    return (
      <TableBody className={classes.tableBody}>
        {currentScoreData.map((score, index) => renderTableBodyRow(score, index + 1))}
      </TableBody>
    );
  };

  return (
    <div style={{ width: '75vw' }}>
      <Card>
        <CardBody>
          {(currentScoreData || []).length >= 1 ? (
            <Table className={classes.table}>
              {renderTableHeader()}
              {renderTableBody()}
            </Table>
          ) : (
            <div className={classes.notEvaluatedSection}>
              <h4> Solution is not evaluated yet </h4>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

const extraStyles = (theme) => ({
	tableHead: {
		backgroundColor: `${theme.palette.background.cardHeader} !important`,
	},
  notEvaluatedSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
});

const finalStyles = combineStyles(extraStyles, tableStyle);

export default (withStyles(finalStyles)(withTheme(EvaluatorScoreDetails)));