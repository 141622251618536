import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { withRouter, NavLink } from "react-router-dom"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import InfoIcon from "@material-ui/icons/Info"
import ToolTip from "@material-ui/core/Tooltip"

// @material-ui/icons
import Face from "@material-ui/icons/Face"
import Email from "@material-ui/icons/Email"
import LockIcon from "@material-ui/icons/LockOutlined"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx"

import FBLogin from "./SocialLogin/FacebookLogin"
import GoogleLogin from "./SocialLogin/GoogleLogin"
import AmazonLogin from "./SocialLogin/AmazonLogin"
import LinkedInLogin from "./SocialLogin/LinkedInLogin"

import cookiesHelper from "cookiesHelper"
import combineStyles from "utils/combineStyle"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import userServices from "dataServices/userServices"
import { ButtonBase } from "@material-ui/core"
import validator from "validator"
import constants from "constants"

const redirectUrlAfterLogin = "/dashboard/index"

let intervalId = 0
class AccountPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			cardAnimaton: "cardHidden",
			username: "",
			password: "",
			password2: "",
			name: "",
			email: "",
			emailOrUsername: "",
			foundUsername: "",
			codeSent: false,
			isLogin: true,
			isRegister: false,
			isForgot: false,
			isResendVerificationLink: false,
			formInfo: {
				message: "",
				type: ""
			}
		}
	}

	setCardAnimationTimeOut = () => {
		// we add a hidden class to the card and after 300 ms we delete it and the transition appears
		this.timeOutFunction = setTimeout(
			function () {
				this.setState({ cardAnimaton: "" })
			}.bind(this),
			300
		)
	}

	handleOnChangeInput = (e) => {
		let fieldName = e.target.name
		let fieldValue = e.target.value
		if (fieldName == "email" || fieldName == "emailOrUsername" || fieldName == "username") {
			fieldValue = fieldValue.split(" ").join("")
		}

		this.setState({
			[fieldName]: fieldValue,
			formInfo: {
				message: "",
				type: ""
			}
		})
	}

	componentDidMount() {
		this.setCardAnimationTimeOut()
	}

	componentWillUnmount() {
		clearTimeout(this.timeOutFunction)
		this.timeOutFunction = null
	}

	isStrongPassword = (password) => {
		var regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
		var validPassword = regExp.test(password)
		return validPassword
	}

	handleSignUp = () => {
		if (!this.state.username || !this.state.password || !this.state.name || !this.state.email) {
			this.setState({
				formInfo: {
					message: "Some required fields are missing",
					type: "error"
				}
			})
			return
		}
		let fullName = this.state.name.trim()
		if (!fullName || fullName == "") {
			this.setState({
				formInfo: {
					message: "Name can not be blank",
					type: "error"
				},
				name: fullName
			})
			return
		}
		if (!this.isStrongPassword(this.state.password)) {
			this.setState({
				formInfo: {
					message:
						"Password must contains uppercase, lowercase, digits, special characters. Length must be at least 8 characters.",
					type: "error"
				}
			})
			return
		}
		console.log("Signing up...")
		let variables = {
			username: this.state.username,
			password: this.state.password,
			name: fullName,
			email: this.state.email
		}
		userServices
			.registerUser(variables)
			.then((data) => {
				console.log("Sign up successful")
				console.log("User Signup data", data)

				this.setState({
					password2: "",
					name: "",
					email: "",
					formInfo: {
						message:
							"You have successfully signed up, Please check your email inbox and verify the email",
						type: "success"
					},
					isLogin: true,
					isRegister: false,
					isForgot: false
				})
				this.setupReloadOnVerification()
			})
			.catch((err) => {
				console.log("err = ", err.message)
				this.setState({
					password: "",
					password2: "",
					formInfo: {
						message: err.message,
						type: "error"
					}
				})
			})
	}

	handleLogin = () => {
		const {
			setLoadingSpinner,
			resetLoadingSpinner,
			setLoggedInUserData,
			setAfterLoginRedirectUrl,
			redirectAfterLogin,
			closeLoginModal,
		} = this.props;

		if (!this.state.username || !this.state.password) {
			this.setState({
				formInfo: {
					message: "Some required fields are missing",
					type: "error",
				}
			});

			return;
		}

		setLoadingSpinner();

		const variables = {
			usernameOrEmail: this.state.username,
			password: this.state.password
		};
		let loggedInUserData = null;

		return userServices
			.authenticateUser(variables)
			.then((data) => {
				loggedInUserData = {
					firstTimeLogin: data.firstTimeLogin,
					refresh_token: data.refresh_token,
					userId: data.userId,
					userName: data.userName
				}
				let expiresInHour = data.expires_in / 3600;

				cookiesHelper.set(constants.Settings.LoggedInCookieName, loggedInUserData);
				cookiesHelper.setAccessToken(
					constants.Settings.AccessTokenCookieName,
					{ access_token: data.access_token },
					"/",
					expiresInHour,
				);

				setLoggedInUserData(loggedInUserData);
				return this.handleDarkMode();
			})
			.then((res) => {
				resetLoadingSpinner();

				if (redirectAfterLogin) {
					const tempRedirect = redirectAfterLogin;
					setAfterLoginRedirectUrl(null);
					window.location.href = tempRedirect;
				} else if (loggedInUserData.firstTimeLogin) {
					window.location.href = "/dashboard/index";
				} else {
					window.location.href = redirectUrlAfterLogin;
				}

				this.setState({
					username: "",
					password: "",
					password2: "",
					name: "",
					email: "",
					formInfo: {
						message: "You have successfully logged in",
						type: "success"
					}
				});

				closeLoginModal();
				return true;
			})
			.catch((err) => {
				resetLoadingSpinner();
				
				this.setState({
					password: "",
					password2: "",
					formInfo: {
						message: err.message,
						type: "error",
					},
				});

				return false;
			});
	}

	handleSocialLogin = (data, provider) => {
		const {
			setLoadingSpinner,
			resetLoadingSpinner,
			setLoggedInUserData,
			setAfterLoginRedirectUrl,
			redirectAfterLogin,
			closeLoginModal,
		} = this.props;

		if (typeof data === Error) {
			this.setState({
				formInfo: {
					message: data.message,
					type: "error"
				},
			});

			return;
		}

		let loggedInUserData = null;
		const variables = {
			id: data.id,
			email: data.email,
			name: data.name,
			pictureLink: data.profilePictureUrl,
			loginProvider: provider
		};

		setLoadingSpinner();

		userServices
			.login3rdPartySocial(variables)
			.then((data) => {
				loggedInUserData = {
					firstTimeLogin: data.firstTimeLogin,
					refresh_token: data.refresh_token,
					userId: data.userId,
					userName: data.userName
				};
				const expiresInHour = data.expires_in / 3600;

				cookiesHelper.set(constants.Settings.LoggedInCookieName, loggedInUserData);
				cookiesHelper.setAccessToken(
					constants.Settings.AccessTokenCookieName,
					{ access_token: data.access_token },
					"/",
					expiresInHour
				);
				
				setLoggedInUserData(loggedInUserData);
				return this.handleDarkMode();
			})
			.then((res) => {
				if (redirectAfterLogin) {
					const tempRedirect = redirectAfterLogin;
					setAfterLoginRedirectUrl(null);
					window.location.href = tempRedirect;
				} else if (loggedInUserData.firstTimeLogin) {
					window.location.href = "/dashboard/index"
				} else {
					window.location.href = redirectUrlAfterLogin;
				}
				this.setState({
					formInfo: {
						message: "You have successfully logged in",
						type: "success",
					},
				});

				closeLoginModal();
			})
			.catch((err) => {
				resetLoadingSpinner();
				
				this.setState({
					formInfo: {
						message: err.message,
						type: "error",
					},
				});
			});
	}

	handleDarkMode = () => {
		return userServices
			.getCurrentUser()
			.then((data) => {
				let currentHighestRole =
					data.roles.filter((role) => role.RoleId === "admin" || role.RoleId === "superAdmin")
						.length > 0
						? "admin"
						: null
				if (!currentHighestRole)
					currentHighestRole =
						data.roles.filter((role) => role.RoleId === "licenseeAdmin").length > 0
							? "licenseeAdmin"
							: null
				if (!currentHighestRole)
					currentHighestRole =
						data.roles.filter((role) => role.RoleId === "challengeAdmin").length > 0
							? "challengeAdmin"
							: null

				if (!currentHighestRole)
					currentHighestRole =
						data.roles.filter((role) => role.RoleId === "judge").length > 0 ? "judge" : null

				if (!currentHighestRole)
					currentHighestRole =
						data.roles.filter((role) => role.RoleId === "evaluator").length > 0 ? "evaluator" : null

				if (currentHighestRole) {
					this.props.toggleDarkMode(true)
					cookiesHelper.set("darkMode", true, "/")
					return true
				} else {
					this.props.toggleDarkMode(false)
					cookiesHelper.set("darkMode", false, "/")
					return false
				}
			})
			.catch((err) => {
				console.log("Error getting user roles", err.message)
				return false
			})
	}

	handleGetResetCode = () => {
		if (!this.state.emailOrUsername) {
			this.setState({ formInfo: { message: "Please enter your email or username", type: "error" } })
			return
		}
		this.props.setLoadingSpinner()
		userServices
			.forgotPassword(this.state.emailOrUsername)
			.then((data) => {
				this.setState({
					foundUsername: data.username,
					codeSent: true,
					formInfo: {
						message: "Password reset code is sent to your email",
						type: "success"
					}
				})
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.setState({ formInfo: { message: err.message, type: "error" } })
				this.props.resetLoadingSpinner()
			})
	}

	handleResetPassword = () => {
		if (!this.state.resetCode) {
			this.setState({ formInfo: { message: "Please enter the reset code", type: "error" } })
			return
		}
		if (!this.state.password) {
			this.setState({ formInfo: { message: "Please enter the new password", type: "error" } })
			return
		}
		if (this.state.password != this.state.password2) {
			this.setState({ formInfo: { message: "Confirm password did not matched", type: "error" } })
			return
		}
		if (!this.isStrongPassword(this.state.password)) {
			this.setState({
				formInfo: {
					message:
						"Password must contains uppercase, lowercase, digits, special characters. Length must be at least 8 characters.",
					type: "error"
				}
			})
			return
		}
		this.props.setLoadingSpinner()
		userServices
			.confirmForgotResetPassword(
				this.state.resetCode,
				this.state.foundUsername,
				this.state.password
			)
			.then((status) => {
				this.setState({
					username: "",
					password: "",
					password2: "",
					name: "",
					email: "",
					isLogin: true,
					isRegister: false,
					isForgot: false,
					codeSent: false,
					foundUsername: "",
					cardAnimaton: "cardHidden",
					formInfo: {
						message: "You have successfuly reseted your password",
						type: "success"
					}
				})
				this.setCardAnimationTimeOut()
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				this.setState({
					password: "",
					password2: "",
					formInfo: {
						message: err.message,
						type: "error"
					}
				})
				this.props.resetLoadingSpinner()
			})
	}

	sendEmailVeriicationLink = () => {
		if (!validator.isEmail(this.state.username)) {
			this.setState({
				formInfo: {
					message: "Please enter your valid email address",
					type: "error"
				}
			})
			return
		}
		userServices
			.sendEmailVeriicationLink(this.state.username)
			.then((status) => {
				if (status) {
					this.setState({
						isLogin: true,
						isResendVerificationLink: false,
						formInfo: {
							message:
								"Email verification link is sent in you email, please check inbox and click the link to verify the email address",
							type: "success"
						}
					})
					this.setupReloadOnVerification()
				} else throw new Error("An unknown error occured while sending email verification link")
			})
			.catch((err) => {
				console.log("Error in sending email verification link, err =", err.message)
				this.setState({
					formInfo: {
						message: err.message,
						type: "error"
					}
				})
			})
	}

	setupReloadOnVerification() {
		localStorage.setItem("emailVerificationStatus", "PENDING")
		if (intervalId) {
			clearInterval(intervalId)
		}
		intervalId = setInterval(() => {
			let status = localStorage.getItem("emailVerificationStatus")
			if (status === "COMPLETED") {
				localStorage.setItem("emailVerificationStatus", "RELOADED")
				this.setState({
					formInfo: {
						message: "Email is verified, Let's login",
						type: "success"
					}
				})

				if (this.state.username && this.isStrongPassword(this.state.password)) {
					this.handleLogin()
				}

				clearInterval(intervalId)
			}
		}, 1000)
	}

	handleEnterKeyPress = (event) => {
		if (event.key == "Enter") {
			if (event.target.id == "loginUserName") document.getElementById("LoginPassword").focus()
			else if (event.target.id == "LoginPassword") this.handleLogin()
			else if (event.target.id == "signUpName") document.getElementById("signUpEmail").focus()
			else if (event.target.id == "signUpEmail") document.getElementById("signUpUserName").focus()
			else if (event.target.id == "signUpUserName")
				document.getElementById("signUpPassword").focus()
			else if (event.target.id == "signUpPassword") this.handleSignUp()
		}
	}

	renderLogin = () => {
		const { classes } = this.props
		if (this.state.isLogin) {
			return (
				<GridContainer justify="center">
					<GridItem xs={12} sm={12} md={12}>
						<form>
							<Card login className={classes[this.state.cardAnimaton]}>
								<CardHeader color="gmgDark" className={cx(classes.cardHeader, classes.textCenter)}>
									<h4 className={classes.cardTitle}>Log in</h4>
									<div className={classes.socialLine}>
										{["fab fa-facebook", "fab fa-google", "fab fa-amazon", "fab fa-linkedin"].map(
											(prop, key) => {
												return (
													<ButtonBase
														color="transparent"
														justIcon
														key={key}
														className={classes.customButtonClass}
													>
														{prop == "fab fa-facebook" && (
															<FBLogin
																handleFacebookLogin={(data) =>
																	this.handleSocialLogin(data, "facebook")
																}
															/>
														)}
														{prop == "fab fa-google" && (
															<GoogleLogin
																handleGoogleLogin={(data) => this.handleSocialLogin(data, "google")}
															/>
														)}
														{/* We will implement this later */}
														{/* {prop == "fab fa-amazon" && (
															<AmazonLogin
																handleAmazonLogin={(data) => this.handleSocialLogin(data, "amazon")}
															/>
														)}
														{prop == "fab fa-linkedin" && (
															<LinkedInLogin
																handleLinkedInLogin={(data) =>
																	this.handleSocialLogin(data, "linekdIn")
																}
															/>
														)} */}
													</ButtonBase>
												)
											}
										)}
									</div>
								</CardHeader>

								<CardBody>
									{this.showFormInfo()}
									<CustomInput
										id="loginUserName"
										labelText="Username or Email"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Face className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											type: "text",
											name: "username",
											value: this.state.username,
											onChange: this.handleOnChangeInput,
											onKeyPress: this.handleEnterKeyPress
										}}
									/>
									<CustomInput
										id="LoginPassword"
										labelText="Password"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<LockIcon className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											type: "password",
											name: "password",
											value: this.state.password,
											onChange: this.handleOnChangeInput,
											onKeyPress: this.handleEnterKeyPress
										}}
									/>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<div className={classes.center}>
										<GridContainer
											container
											direction="column"
											justify="center"
											alignItems="center"
										>
											<GridItem xs={12} sm={12} md={12}>
												<Button
													square
													color="gmgGreen"
													onClick={() => {
														this.handleLogin()
													}}
												>
													Login
												</Button>
											</GridItem>

											<GridItem xs={12} sm={12} md={12}>
												<div style={{ marginTop: "10px", marginBottom: "0px" }}>
													<NavLink
														to="#"
														onClick={(event) => {
															event.preventDefault()
															this.setState({
																password: "",
																password2: "",
																name: "",
																email: "",
																isLogin: false,
																isRegister: false,
																isForgot: false,
																isResendVerificationLink: true,
																cardAnimaton: "cardHidden",
																formInfo: {
																	message: "",
																	type: ""
																}
															})
															this.setCardAnimationTimeOut()
														}}
													>
														Resend email verification link
													</NavLink>
												</div>
											</GridItem>

											<GridItem xs={12} sm={12} md={12}>
												<div style={{ marginTop: "10px", marginBottom: "0px" }}>
													<NavLink
														to="#"
														onClick={(event) => {
															event.preventDefault()
															this.setState({
																username: "",
																password: "",
																password2: "",
																name: "",
																email: "",
																isLogin: false,
																isRegister: false,
																isForgot: true,
																cardAnimaton: "cardHidden",
																formInfo: {
																	message: "",
																	type: ""
																}
															})
															this.setCardAnimationTimeOut()
														}}
													>
														Forgot Password?
													</NavLink>
												</div>
											</GridItem>

											<GridItem xs={12} sm={12} md={12}>
												<div style={{ marginTop: "10px", marginBottom: "0px" }}>
													Don't have an account?{" "}
													<NavLink
														to="#"
														onClick={() => {
															this.setState({
																username: "",
																password: "",
																password2: "",
																name: "",
																email: "",
																isLogin: false,
																isRegister: true,
																isForgot: false,
																cardAnimaton: "cardHidden",
																formInfo: {
																	message: "",
																	type: ""
																}
															})
															this.setCardAnimationTimeOut()
														}}
													>
														Sign up
													</NavLink>
												</div>
											</GridItem>
										</GridContainer>
									</div>
								</CardFooter>
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			)
		}
		return null
	}

	renderRegister = () => {
		const { classes } = this.props
		if (this.state.isRegister) {
			return (
				<GridContainer justify="center">
					<GridItem xs={12} sm={12} md={12}>
						<form>
							<Card login className={classes[this.state.cardAnimaton]}>
								<CardHeader color="gmgDark" className={cx(classes.cardHeader, classes.textCenter)}>
									<h4 className={classes.cardTitle}>Sign up</h4>
								</CardHeader>

								<CardBody>
									{this.showFormInfo()}
									<CustomInput
										id="signUpName"
										labelText="Name"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Face className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											type: "text",
											name: "name",
											value: this.state.name,
											onChange: this.handleOnChangeInput,
											onKeyPress: this.handleEnterKeyPress
										}}
									/>
									<CustomInput
										id="signUpEmail"
										labelText="Email"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Email className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											type: "text",
											name: "email",
											value: this.state.email,
											onChange: this.handleOnChangeInput,
											onKeyPress: this.handleEnterKeyPress
										}}
									/>

									<CustomInput
										id="signUpUserName"
										labelText="Username"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Face className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											type: "text",
											name: "username",
											value: this.state.username,
											onChange: this.handleOnChangeInput,
											onKeyPress: this.handleEnterKeyPress
										}}
									/>
									<CustomInput
										id="signUpPassword"
										labelText="Password"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<ToolTip
														title={
															<span style={{ color: "yellow", fontSize: "1.5em" }}>
																Password must contains uppercase, lowercase letters, digits, special
																characters; Length atleast 8 character.
															</span>
														}
														placement="left-start"
													>
														<InfoIcon />
													</ToolTip>
													<LockIcon className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											error: this.state.password && !this.isStrongPassword(this.state.password),
											type: "password",
											name: "password",
											value: this.state.password,
											onChange: this.handleOnChangeInput,
											onKeyPress: this.handleEnterKeyPress
										}}
									/>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<div className={classes.center}>
										<GridContainer
											container
											direction="column"
											justify="center"
											alignItems="center"
										>
											<GridItem xs={12} sm={12} md={12}>
												<Button
													square
													color="gmgGreen"
													onClick={() => {
														this.handleSignUp()
													}}
												>
													Sign up
												</Button>
											</GridItem>

											<GridItem xs={12} sm={12} md={12}>
												<div style={{ marginTop: "10px", marginBottom: "0px" }}>
													Already have an account?{" "}
													<NavLink
														to="#"
														onClick={() => {
															this.setState({
																username: "",
																password: "",
																password2: "",
																name: "",
																email: "",
																isLogin: true,
																isRegister: false,
																isForgot: false,
																cardAnimaton: "cardHidden",
																formInfo: {
																	message: "",
																	type: ""
																}
															})
															this.setCardAnimationTimeOut()
														}}
													>
														Login
													</NavLink>
												</div>
											</GridItem>
										</GridContainer>
									</div>
								</CardFooter>
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			)
		}
		return null
	}

	renderForgotPassword = () => {
		const { classes } = this.props
		if (this.state.isForgot) {
			return (
				<GridContainer justify="center">
					<GridItem xs={12} sm={12} md={12}>
						<form>
							<Card login className={classes[this.state.cardAnimaton]}>
								<CardHeader color="gmgDark" className={cx(classes.cardHeader, classes.textCenter)}>
									<h4 className={classes.cardTitle}>Reset Password</h4>
								</CardHeader>

								<CardBody>
									{this.showFormInfo()}
									{!this.state.codeSent ? (
										<CustomInput
											id="loginEmailOrUserName"
											labelText="Email or Username"
											formControlProps={{
												fullWidth: true
											}}
											inputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<Face className={classes.inputAdornmentIcon} />
													</InputAdornment>
												),
												type: "text",
												name: "emailOrUsername",
												value: this.state.emailOrUsername,
												onChange: this.handleOnChangeInput
												//onKeyPress: this.handleEnterKeyPress
											}}
										/>
									) : (
										<div>
											<CustomInput
												id="resetCode"
												labelText="Reset Code"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<Face className={classes.inputAdornmentIcon} />
														</InputAdornment>
													),
													type: "text",
													name: "resetCode",
													value: this.state.resetCode,
													onChange: this.handleOnChangeInput,
													onKeyPress: this.handleEnterKeyPress
												}}
											/>
											<CustomInput
												id="LoginPassword"
												labelText="Password"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<LockIcon className={classes.inputAdornmentIcon} />
														</InputAdornment>
													),
													type: "password",
													name: "password",
													value: this.state.password,
													onChange: this.handleOnChangeInput,
													onKeyPress: this.handleEnterKeyPress
												}}
											/>
											<CustomInput
												id="LoginPassword2"
												labelText="Confirm Password"
												formControlProps={{
													fullWidth: true
												}}
												inputProps={{
													error:
														this.state.password2 && this.state.password != this.state.password2,
													endAdornment: (
														<InputAdornment position="end">
															<LockIcon className={classes.inputAdornmentIcon} />
														</InputAdornment>
													),
													type: "password",
													name: "password2",
													value: this.state.password2,
													onChange: this.handleOnChangeInput,
													onKeyPress: this.handleEnterKeyPress
												}}
											/>
										</div>
									)}
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<div className={classes.center}>
										<GridContainer
											container
											direction="column"
											justify="center"
											alignItems="center"
										>
											{!this.state.codeSent ? (
												<GridItem xs={12} sm={12} md={12}>
													<Button square color="gmgGreen" onClick={this.handleGetResetCode}>
														Submit
													</Button>
												</GridItem>
											) : (
												<GridItem xs={12} sm={12} md={12}>
													<Button square color="gmgGreen" onClick={this.handleResetPassword}>
														Reset Password
													</Button>
												</GridItem>
											)}
										</GridContainer>
									</div>
								</CardFooter>
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			)
		}
		return null
	}

	renderResendVerificationLinkUI = () => {
		const { classes } = this.props
		if (this.state.isResendVerificationLink) {
			return (
				<GridContainer justify="center">
					<GridItem xs={12} sm={12} md={12}>
						<form>
							<Card login className={classes[this.state.cardAnimaton]}>
								<CardHeader color="gmgDark" className={cx(classes.cardHeader, classes.textCenter)}>
									<h4 className={classes.cardTitle}>Get email verification link</h4>
								</CardHeader>

								<CardBody>
									{this.showFormInfo()}

									<CustomInput
										id="loginEmailOrUserName"
										labelText="Email"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Face className={classes.inputAdornmentIcon} />
												</InputAdornment>
											),
											type: "text",
											name: "username",
											value: this.state.username,
											onChange: this.handleOnChangeInput
											//onKeyPress: this.handleEnterKeyPress
										}}
									/>
								</CardBody>
								<CardFooter className={classes.justifyContentCenter}>
									<div className={classes.center}>
										<GridContainer direction="row" justify="space-between" alignItems="center">
											<GridItem xs={6} sm={6} md={6}>
												<Button
													square
													color="gmgGreen"
													onClick={(e) => {
														this.sendEmailVeriicationLink()
													}}
												>
													Get link
												</Button>
											</GridItem>

											<GridItem xs={6} sm={6} md={6}>
												<Button
													square
													color="danger"
													onClick={(e) => {
														this.setState({
															isLogin: true,
															isRegister: false,
															isForgot: false,
															isResendVerificationLink: false,
															cardAnimaton: "cardHidden",
															formInfo: {
																message: "",
																type: ""
															}
														})
														this.setCardAnimationTimeOut()
													}}
												>
													Cancel
												</Button>
											</GridItem>
										</GridContainer>
									</div>
								</CardFooter>
							</Card>
						</form>
					</GridItem>
				</GridContainer>
			)
		}
		return null
	}

	showFormInfo = () => {
		const { classes } = this.props
		if (this.state.formInfo.message && this.state.formInfo.type) {
			let className = ""
			if (this.state.formInfo.type == "error") className = "messageError"
			else if (this.state.formInfo.type == "success") className = "messageSuccess"
			return <div className={classes[className]}> {this.state.formInfo.message} </div>
		}
	}

	render() {
		return (
			<div>
				{this.renderLogin()}
				{this.renderRegister()}
				{this.renderForgotPassword()}
				{this.renderResendVerificationLinkUI()}
			</div>
		)
	}
}

AccountPage.propTypes = {
	classes: PropTypes.object.isRequired,
	onLoginClose: PropTypes.func.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	closeLoginModal: PropTypes.func.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	redirectAfterLogin: PropTypes.string.isRequired,
	setAfterLoginRedirectUrl: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		redirectAfterLogin: state.redirectAfterLogin
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		setAfterLoginRedirectUrl: (url) =>
			dispatch({ type: actionTypes.AFTER_LOGIN_REDIRECT_URL_SET, payload: url }),
		toggleDarkMode: (isDark) => dispatch({ type: actionTypes.TOGGLE_DARK_MODE, payload: isDark })
	}
}

let extraStyle = (theme) => ({
	messageError: {
		margin: "5px 2px 5px 2px",
		color: "red",
		textAlign: "center"
	},
	messageSuccess: {
		margin: "5px 2px 5px 2px",
		color: "green",
		textAlign: "center"
	}
})

let finalStyle = combineStyles(loginPageStyle, extraStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(finalStyle)(AccountPage)))
