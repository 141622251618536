import React from "react"
import { withStyles, withTheme } from "@material-ui/core/Styles"

import PropTypes from "prop-types"

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Assignment from "@material-ui/icons/Assignment"
import Edit from "@material-ui/icons/Edit"
import Close from "@material-ui/icons/Close"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Input from "@material-ui/core/Input"
import CustomInput from "components/CustomInput/CustomInput.jsx"

import combineStyles from "utils/combineStyle"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import SimpleBreadcrumbs from "components/SimpleBreadcumbs.jsx"
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"

import ManageChallengeMenu from "./ManageChallengeMenu.jsx"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

import Constants from "constants.js"
import ErrorPage from "../Pages/ErrorPage"
import helper from "../../helper"
import validator from "validator"
import { saveAs } from "file-saver"

import Chip from "@material-ui/core/Chip"

import challengeServices from "dataServices/challengeServices"
import moment from "moment"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250
		}
	}
}
function getStyles(name, personName, theme) {
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium
	}
}

class EmailMessagingByChallengePage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			canViewThisPage: true,
			showErrorComponent: false,
			challengeId: "",
			urlSlug: "",
			challenge: null,
			isLoading: true,
			selectedRoles: [],
			availableRoles: [
				{ name: "Solver", value: "solver" },
				{ name: "Evaluator", value: "evaluator" },
				{ name: "Judge", value: "judge" },
				{ name: "Challenge Admin", value: "challengeAdmin" }
				//{ name: "Licensee Admin", value: "licenseeAdmin" }, //only for superAdmin, sol will be added after checking roles
			]
		}
	}

	fetchChallenge = (challengeId) => {
		return challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log("Error fetching challenge", err.message)
				return null
			})
	}

	onClickHandleGetData = () => {
		if (!this.state.selectedRoles || this.state.selectedRoles.length == 0) {
			this.props.showAlert(
				"warning",
				"Opps!",
				"Please select atleast one role to get challenge users data"
			)
			return
		}
		this.props.setLoadingSpinner()
		let rolesArray = this.state.selectedRoles.map((r) => r.value)
		challengeServices
			.getAllUsersOfChallenge(this.state.challengeId, rolesArray)
			.then((data) => {
				this.props.resetLoadingSpinner()
				if (!data)
					throw new Error(
						"Unknown error occcured while getting the users of the challenges by their role"
					)
				let csvData = `"SL. No.","Name","Email","Role"\n`
				let slNo = 1
				if (data.challengeAdmins && data.challengeAdmins.length > 0) {
					data.challengeAdmins.map((user) => {
						csvData += `${slNo},"${user.name}","${user.email}","challengeAdmin"\n`
						slNo++
					})
				}
				if (data.judges && data.judges.length > 0) {
					data.judges.map((user) => {
						csvData += `${slNo},"${user.name}","${user.email}","judge"\n`
						slNo++
					})
				}
				if (data.evaluators && data.evaluators.length > 0) {
					data.evaluators.map((user) => {
						csvData += `${slNo},"${user.name}","${user.email}","evaluator"\n`
						slNo++
					})
				}
				if (data.solvers && data.solvers.length > 0) {
					data.solvers.map((user) => {
						csvData += `${slNo},"${user.name}","${user.email}","solver"\n`
						slNo++
					})
				}
				let currentDateTimeString = moment().format("MM-DD-YYYY-HH-m-s")
				let blob = new Blob([csvData], { type: "text/plain" })
				let fileName = `challenge-users-by-roles-${currentDateTimeString}.csv`
				saveAs(blob, fileName)
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting users of challenge, err=", err.message)
				this.props.showAlert("error", "Opps!", err.message)
			})
	}

	componentDidMount() {
		console.log("Email Messaging Page")
		const {
			match: { params }
		} = this.props

		this.props.pushBreadCrumbStack({
			name: "Email Messaging",
			link: this.props.location.pathname
		})

		if (validator.isUUID(params.challengeId)) {
			this.setState({ challengeId: params.challengeId }, () => {})
		} else if (params.challengeId) {
			this.setState({ urlSlug: params.challengeId }, () => {})
		} else {
			console.log("wrong url")
			this.props.setRedirectOnError("/dashboard/index")
		}

		console.log("slug = ", params.challengeId)
		this.props.setLoadingSpinner()
		this.fetchChallenge(params.challengeId).then((challenge) => {
			this.props.resetLoadingSpinner()
			if (!challenge) {
				console.log("Challenge not found")
				this.props.setRedirectOnError("/dashboard/index")
			}
			console.log("Challenge = ", challenge)
			this.setState({ challengeId: challenge.challengeId, challenge: challenge, isLoading: false })
			let roles = challenge.roles
			if (
				roles.find((r) => r.role == "challengeAdmin") ||
				roles.find((r) => r.role == "licenseeAdmin") ||
				roles.find((r) => r.role == "superAdmin")
			) {
			} else {
				this.props.setRedirectOnError("/dashboard/index")
			}
		})
	}

	handleOnChangeMultiSelect = (e) => {
		let filteredRoles = this.state.availableRoles.filter((r) => e.target.value.indexOf(r.name) > -1)
		this.setState({ selectedRoles: filteredRoles })
	}

	render() {
		const { classes, theme } = this.props
		helper.setPageTitle("Email Messaging | BestInCrowd")
		console.log("history - ", this.props.history)
		if (this.state.showErrorComponent) {
			return (
				<ErrorPage
					errorCode={403}
					message={"Access Denied! :("}
					description={"You are not logged in, please login to see this page"}
				/>
			)
		}
		if (this.state.isLoading) {
			console.log("isLoading")
			return <div> </div>
		}
		return (
			<div>
				<GridContainer direction="row" justify="space-between">
					<GridItem xs={9} sm={10} md={10} lg={11}>
						<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
					</GridItem>
					<GridItem className={classes.right} xs={3} sm={2} md={2} lg={1}>
						<ManageChallengeMenu
							challengeId={this.state.challengeId}
							challenge={this.state.challenge}
							urlSlug={this.state.urlSlug}
							history={this.props.history}
						/>
					</GridItem>
				</GridContainer>
				<br />

				<SnackbarContent
					message={this.state.challenge.challengeDetails.phaseTitle}
					color="success"
				/>
				<Card raised>
					<CardHeader color="rose" icon>
						<CardIcon color="rose">
							<Assignment />
						</CardIcon>
						<h3 className={classes.cardIconTitle}>{this.state.challenge.challengeDetails.title}</h3>
						<h5 className={classes.cardIconTitle}>
							Export email addresses of all the users of current challenge
						</h5>
					</CardHeader>

					<CardBody>
						<br />
						<FormControl className={classes.formControl}>
							<InputLabel htmlFor="select-multiple-chip">Select Roles</InputLabel>
							<Select
								multiple
								value={this.state.selectedRoles.map((r) => r.name)}
								onChange={this.handleOnChangeMultiSelect}
								input={<Input id="select-multiple-chip" />}
								renderValue={(selected) => (
									<div className={classes.chips}>
										{selected.map((value) => (
											<Chip key={value} label={value} className={classes.chip} />
										))}
									</div>
								)}
								MenuProps={MenuProps}
							>
								{this.state.availableRoles.map((r) => (
									<MenuItem
										key={r.value}
										value={r.name}
										style={getStyles(r.value, this.state.selectedRoles, theme)}
									>
										{r.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<br />
						<br />

						<div>
							<Button color="primary" onClick={this.onClickHandleGetData}>
								Export user's email Addresses
							</Button>
						</div>
					</CardBody>
				</Card>
			</div>
		)
	}
}

EmailMessagingByChallengePage.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

let extraStyles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
		display: "flex",
		flexWrap: "wrap"
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 300,
		maxWidth: 900
	},
	chips: {
		display: "flex",
		flexWrap: "wrap"
	},
	chip: {
		margin: 2
	},
	noLabel: {
		marginTop: theme.spacing(3)
	}
})

let finalStyles = combineStyles(extraStyles, extendedTablesStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withTheme(withStyles(finalStyles)(EmailMessagingByChallengePage)))
