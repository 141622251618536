import React from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import { dataTable } from "variables/general.jsx";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";

import cookiesHelper from "../../cookiesHelper";
import constant from "../../constants";

import Wizard from "components/Wizard/Wizard.jsx";
import StepSelectType from "../Forms/WizardSteps/StepSelectType.jsx"; //edit here to create the pages
import StepAddPeople from "../Forms/WizardSteps/StepAddPeople.jsx"; //edit here to create the pages
import StepDetails from "../Forms/WizardSteps/StepDetails.jsx"; //edit here to create the pages
import apolloFetcher from "../../apolloFetcher";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  }
};

class ReactTables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    console.log("Component Did Mount!");
    apolloFetcher
      .fetchWithToken({
        query: `
			{
			  challenges{
				status
				message
				totalCount
				data{
				  challengeId
				  challengeDetails{
					description
					startDate
					endDate
					status
					evaluationCriteria
					prizes{
					  firstPlace
					  secondPlace
					}
					timeline
					data
					rules
					discussionBoard
					leaderboard{
					  place
					  userId
					}
					evaluators{
					  userId
					}
					judges{
					  userId
					}
					participants{
					  userId
					  teamId
					}
				  }
				}
			  }
			}`
      })
      .then(res => {
        if (!res.data.challenges.status)
          throw new Error("Error occured while fetching challenges");
        console.log(res.data.challenges.data);
        this.setState({
          data: res.data.challenges.data.map((prop, key) => {
            return {
              id: key + 1,
              challengeId: prop.challengeId,
              endDate: prop.challengeDetails.endDate,
              status: prop.challengeDetails.status,
              description: prop.challengeDetails.description,
              data: prop.challengeDetails.data,
              rules: prop.challengeDetails.rules,
              prizesFirstPlace: prop.challengeDetails.prizes.firstPlace,
              prizesSecondPlace: prop.challengeDetails.prizes.secondPlace,
              actions: (
                // we've added some custom button actions
                <div className="actions-right">
                  {/* use this button to add a like kind of action */}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      let obj = this.state.data.find(o => o.id === key);
                      alert(
                        "You've clicked LIKE button on \n{ \nName: " +
                          obj.challengeId +
                          ", \nposition: " +
                          obj.status +
                          ", \noffice: " +
                          obj.endDate +
                          ", \nage: " +
                          obj.description +
                          "\n}."
                      );
                    }}
                    color="info"
                    className="like"
                  >
                    <Favorite />
                  </Button>{" "}
                  {/* use this button to add a edit kind of action */}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      let obj = this.state.data.find(o => o.id === key);
                      alert(
                        "You've clicked EDIT button on \n{ \nName: " +
                          obj.challengeId
                      );
                    }}
                    color="warning"
                    className="edit"
                  >
                    <Dvr />
                  </Button>{" "}
                  {/* use this button to remove the data row */}
                  <Button
                    justIcon
                    round
                    simple
                    onClick={() => {
                      var data = this.state.data;
                      data.find((o, i) => {
                        if (o.id === key + 1) {
                          // here you should add some custom code so you can delete the data
                          // from this component and from your server as well
                          data.splice(i, 1);
                          return true;
                        }
                        return false;
                      });
                      this.setState({ data: data });
                    }}
                    color="danger"
                    className="remove"
                  >
                    <Close />
                  </Button>{" "}
                </div>
              )
            };
          })
        });
      })
      .catch(err => {
        console.log("Error fetching Challenges!", err);
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer direction="row">
        <GridItem xs={12} sm={6}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>React Table</h4>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.state.data}
                filterable
                columns={[
                  {
                    Header: "Id",
                    accessor: "id"
                  },
                  {
                    Header: "Description",
                    accessor: "description"
                  },
                  {
                    Header: "First Prize",
                    accessor: "prizesFirstPlace"
                  },
                  {
                    Header: "Second Place",
                    accessor: "prizesSecondPlace"
                  },
                  {
                    Header: "Status",
                    accessor: "status"
                  },
                  {
                    Header: "Deadline",
                    accessor: "endDate"
                  }
                  // {
                  // 	Header: "Actions",
                  // 	accessor: "actions",
                  // 	sortable: false,
                  // 	filterable: false
                  // }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <Wizard
            validate
            steps={[
              {
                stepName: "Select Type",
                stepComponent: StepSelectType,
                stepId: "selectType"
              },
              {
                stepName: "Add people",
                stepComponent: StepAddPeople,
                stepId: "addPeople"
              },
              {
                stepName: "Details",
                stepComponent: StepDetails,
                stepId: "details"
              }
            ]}
            title="Challenge Builder"
            subtitle="Please enter necessary information to create a challenge"
            finishButtonClick={e => console.log(e)}
          />
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(ReactTables);
