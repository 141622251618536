import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Chip from "@material-ui/core/Chip";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import FaceIcon from '@material-ui/icons/Face';
import SearchAndSelectUserMultiple from "./SearchAndSelectUserMultiple";

const userStyle = theme => ({
  button: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
});

class AddUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      role: (this.props.usersRole ? this.props.usersRole : ""),
    }
  }

  handleChange = (event) => {
    this.setState({ role: event.target.value });
    this.props.setUsersRole(event.target.value)
  }

  handleClose = () => {
    this.setState({ open: false });
  }

  handleOpen = () => {
    this.setState({ open: true });
  }


  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          Find User
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <SearchAndSelectUserMultiple
              selectedUsers={this.props.selectedUsers}
              setSelectedUsers={this.props.setSelectedUsers}
              searchUserPromise={this.props.searchUserPromise}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" gutterBottom>
              Set Role
            </Typography>
            <form autoComplete="off">
              {/* <Button className={classes.button} onClick={this.handleOpen}>
              </Button> */}
              <FormControl className={classes.formControl}>
                {/* <InputLabel htmlFor="demo-controlled-open-select">Role</InputLabel> */}
                <InputLabel>Role</InputLabel>
                <Select
                  open={this.state.open}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  value={this.state.role}
                  onChange={this.handleChange}
                  inputProps={{
                    name: 'role',
                    //id: 'demo-controlled-open-select',
                  }}
                >
                  {this.props.availableRoles && this.props.availableRoles.map(role => {
                    return <MenuItem value={role.value}>{role.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </form>
          </Grid>
        </Grid>
        <br />
        {/* <Typography variant="h6" gutterBottom>
          Bulk Upload
        </Typography>
        <Grid>
          <Typography variant="h7" gutterBottom>
            For bulk invites, please upload your .csv file with first name, last name, and email addrress only.
          </Typography>
        </Grid>
        <Button
          variant="contained"
          color="warn">
          UPLOAD .csv
        </Button> */}
      </React.Fragment>
    );
  }
}

export default withStyles(userStyle)(AddUser);