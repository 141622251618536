import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CustomModal from 'components/general/CustomModal';
import ResetPassword from '../ResetPassword';

import challengeServices from 'dataServices/challengeServices';
import actionTypes from 'reduxjs/actionTypes';

const ResetPasswordByAdmin = props => {
  const {
    open,
    email,
    userId,
    challengeId,
    closeModal,
    setLoadingSpinner,
    resetLoadingSpinner,
  } = props;

  const [password, setPassword] = useState("");

  const onModalClose = () => {
    closeModal();
    setPassword("");
  };

  const onPasswordChange = pass => {
    setPassword(pass);
  };

  const resetPassword = async () => {
		setLoadingSpinner();

    await challengeServices.resetPasswordByAdmin(
			userId,
			challengeId,
			email,
			password,
		);

    closeModal();
    setPassword("");

    resetLoadingSpinner();
  }

  return (
    <CustomModal
			title="Reset Password"
      visible={open}
      onClose={onModalClose}
    >
      <ResetPassword
        email={email}
        password={password}
        onPasswordChange={onPasswordChange}
        onResetPassword={resetPassword}
      />
    </CustomModal>
  );
};

ResetPasswordByAdmin.propTypes = {
  open: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  challengeId: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
};

ResetPasswordByAdmin.defaultProps = {
  open: false,
  challengeId: "",
}

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
    setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordByAdmin);
