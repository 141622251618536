import React from "react"
import { withStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import Button from "components/CustomButtons/Button.jsx"
import { Typography } from "@material-ui/core"

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState } from "draft-js"

import styles from "./discussionStyles"
import DiscussionView from "./DiscussionView"

import connectRedux from "reduxjs/connectRedux"

class DiscussionListView extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			dataFetchDone: this.props.dataFetchDone,
			loadMore: this.props.loadMore,
			discussionList: this.props.discussionList,
			canModerateDiscussion: this.props.canModerateDiscussion,
			moderateDiscussionMode: this.props.moderateDiscussionMode,
			currentDiscussionToEdit: this.props.currentDiscussionToEdit,
			newDiscussionTitle: "",
			editorState: EditorState.createEmpty()
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.dataFetchDone != prevProps.dataFetchDone) {
			this.setState({ dataFetchDone: this.props.dataFetchDone })
		}

		if (JSON.stringify(this.props.discussionList) != JSON.stringify(prevProps.discussionList)) {
			this.setState({ discussionList: this.props.discussionList })
		}

		if (this.props.loadMore != prevProps.loadMore) {
			this.setState({ loadMore: this.props.loadMore })
		}

		if (this.props.moderateDiscussionMode != prevProps.moderateDiscussionMode) {
			this.setState({ moderateDiscussionMode: this.props.moderateDiscussionMode })
		}

		if (
			JSON.stringify(this.props.currentDiscussionToEdit) !=
			JSON.stringify(prevProps.currentDiscussionToEdit)
		) {
			this.setState({ currentDiscussionToEdit: this.props.currentDiscussionToEdit })
		}

		if (this.props.canModerateDiscussion != prevProps.canModerateDiscussion) {
			this.setState({ canModerateDiscussion: this.props.canModerateDiscussion })
		}
	}

	render() {
		const { classes } = this.props

		let discussionList = this.state.discussionList

		if ((!discussionList || !discussionList.length) && this.state.dataFetchDone) {
			return (
				<div className={classes.center}>
					<Typography className={classes.whiteTextColorStyle} align="center">
						<div style={{ textTransform: "capitalize" }}>No Discussion Found</div>
					</Typography>
				</div>
			)
		}
		let view = discussionList.map((discussion, key) => {
			return (
				<DiscussionView
					key={key}
					history={this.props.history}
					discussion={discussion}
					canModerateDiscussion={this.props.canModerateDiscussion}
					currentDiscussionToEdit={this.state.currentDiscussionToEdit}
					moderateDiscussionMode={this.state.moderateDiscussionMode}
					onClickCancelEditModerate={this.props.onClickCancelEditModerate}
					onClickUpdateDiscussion={this.props.onClickUpdateDiscussion}
					onClickEditDiscussion={this.props.onClickEditDiscussion}
					onClickDeleteDiscussion={this.props.onClickDeleteDiscussion}
				/>
			)
		})

		return (
			<div>
				{this.state.dataFetchDone ? (
					<Typography className={classes.whiteTextColorStyle} align="left">
						<div style={{ textTransform: "capitalize" }}>
							Now Showing 1 - {this.state.discussionList.length}
						</div>
					</Typography>
				) : null}
				<List dense={true}>{view}</List>
				<div
					style={{
						display: "grid",
						justifyContent: "center",
						alignContent: "center"
					}}
				>
					{this.state.dataFetchDone ? (
						<Button
							className={classes.buttonSuccessColor}
							disabled={!this.state.loadMore}
							onClick={this.props.onClickLoadMore}
						>
							Load More
						</Button>
					) : null}
				</div>
			</div>
		)
	}
}

export default connectRedux(withStyles(styles)(DiscussionListView))
