import helper from '../../../../helper';

export const getScores = (evaluationScores, index) => {
  let scoreSum = 0;
  let averageScore = "-";
  let scoreVariance = "-";

  if (evaluationScores[index]) {
    const currentScoreData = evaluationScores[index];
    const totalScoresArray = [];

    currentScoreData.map((evalScore) => {
      let totalScore = 0;
      evalScore.evaluationScoreDetails.criteriaBasedScore.map((criteriaBasedScore) => {
        totalScore += criteriaBasedScore.scoreValue;
      });

      scoreSum += totalScore;
      totalScoresArray.push(totalScore);
    });

    if ((currentScoreData || []).length != 0) {
      averageScore = scoreSum / (currentScoreData || []).length;
      let squaredSum = 0;
      totalScoresArray.map((score) => {
        squaredSum += (score - averageScore) * (score - averageScore)
      });

      scoreVariance = squaredSum / (currentScoreData || []).length;
    }
  }

  averageScore = isNaN(averageScore) ? '-' : helper.numberRoundToDecimal(averageScore, 2);
  scoreVariance = isNaN(scoreVariance) ? '-' : helper.numberRoundToDecimal(scoreVariance, 2);

  return {
    averageScore,
    scoreVariance,
  }  
};