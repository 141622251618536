import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Divider } from '@material-ui/core';

import styles from "./styles.module.scss";
import actionTypes from 'reduxjs/actionTypes';
import { rolesName } from './constants';

const RoleSidebar = props => {
  const { roles, onClickRole } = props;

  const [selectedRole, setSelectedRole] = useState();

  const onSidebarItemClick = role => {
    onClickRole(role);
    setSelectedRole(role);
  };

  useEffect(() => {
    if ((roles || []).length >= 1) {
      setSelectedRole(roles[0]);
    }
  }, [roles]);

  return (
    <div className={styles.roleSidebarWrapper}>
      {(roles || []).map((role, index) => {
        return (
          <>
            <div
              className={`${styles.rolesSidebarItem} ${role == selectedRole ? styles.rolesSidebarItemSelected : ""}`}
              onClick={() => onSidebarItemClick(role)}
            >
              {rolesName[role]}
            </div>
            {index < ((roles || []).length - 1) && <Divider className={styles.rolesSidebarItemDivider} />}
          </>
        );
      })}
    </div>
  );
};

RoleSidebar.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClickRole: PropTypes.func.isRequired,
};

RoleSidebar.defaultProps = {};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
    setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleSidebar);
