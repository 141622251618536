import apolloFetcher from "apolloFetcher"

let services = {}

services.createOrUpdateTeam = (teamData) => {
	let query = `
	mutation createOrUpdateTeam(
		$id: String
		$teamName: String
		$teamDescription: String
		$teamAvatar: String
		$teamAffiliation: String
		$teamMembers: [TeamMemberUserInput]
	) {
		createOrUpdateTeam(
			input: {
				id: $id
				teamName: $teamName
				teamDescription: $teamDescription
				teamAvatar: $teamAvatar
				teamAffiliation: $teamAffiliation
				teamMembers: $teamMembers
			}
		) {
			status
			message
			data {
				id
				teamName
				teamDescription
				teamAvatar
				teamAffiliation
				teamMembers {
					userId
					email
					name
					status
					teamLead
				}
			}
		}
	}
	`
	let variables = {
		...teamData
	}

	console.log("variables = ", variables)
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				console.log("teamCreate response = ", res)
				if (!res || !res.data || !res.data.createOrUpdateTeam)
					throw new Error("Error occured while creating team")
				if (!res.data.createOrUpdateTeam.status)
					throw new Error(res.data.createOrUpdateTeam.message)
				resolve(res.data.createOrUpdateTeam.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.getTeam = (teamId) => {
	let query = `
	query getTeam($teamId: String!){
		getTeam(teamId: $teamId){
		status
		message
		data{
		  id
		  teamName
		  teamDescription
		  teamAvatar
		  teamAffiliation
		  teamMembers{
			userId
			email
			name
			status
			teamLead
		  }
		}
		totalCount
	  }
	}
	`
	let variables = { teamId }
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				console.log("getTeam response = ", res)
				if (!res || !res.data || !res.data.getTeam)
					throw new Error("Error occured while fetching team")
				if (!res.data.getTeam.status) throw new Error(res.data.getTeam.message)
				resolve(res.data.getTeam.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.getTeamInvitationStatus = (teamId, code) => {
	let query = `
	query getTeamInvitationStatus($teamId: String! $code: String!){
		getTeamInvitationStatus(teamId: $teamId code: $code){
		status
		message
		data{
		  teamId
		  teamName
		  userId
		  status
		  teamLead
		}
		totalCount
	  }
	}
	`
	let variables = { teamId: teamId, code: code }
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				console.log("getTeamInvitationStatus response = ", res)
				if (!res || !res.data || !res.data.getTeamInvitationStatus)
					throw new Error("Error occured while fetching current user")
				if (!res.data.getTeamInvitationStatus.status)
					throw new Error(res.data.getTeamInvitationStatus.message)
				resolve(res.data.getTeamInvitationStatus.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.acceptOrRejectTeamInvitation = (teamId, code, accept) => {
	let query = `
	mutation acceptOrRejectTeamInvitation( $teamId: String! $code: String! $accept: Boolean! ) {
		acceptOrRejectTeamInvitation( teamId: $teamId code: $code accept: $accept ){
		  status
		  message
		  data{
			id
			teamName
			teamDescription
			teamAvatar
			teamAffiliation
			teamMembers{
				userId
				email
				name
			  status
			  teamLead
			}
		  }
		  totalCount
		}
	  }
	`
	let variables = { teamId: teamId, code: code, accept: accept }
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				console.log("acceptOrRejectTeamInvitation response = ", res)
				if (!res || !res.data || !res.data.acceptOrRejectTeamInvitation)
					throw new Error("Error occured while fetching current user")
				if (!res.data.acceptOrRejectTeamInvitation.status)
					throw new Error(res.data.acceptOrRejectTeamInvitation.message)
				resolve(res.data.acceptOrRejectTeamInvitation.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.removeUsersFromTeam = (teamId, userIds) => {
	let query = `
	mutation removeUsersFromTeam($teamId: String! $userIds: [String]){
		removeUsersFromTeam(teamId: $teamId input: {
		  userIds: $userIds
		}){
		  status
		  message
		  data{
			id
			teamName
			teamDescription
			teamAvatar
			teamAffiliation
			teamMembers{
				userId
				email
				name
			  status
			  teamLead
			}
		  }
		  totalCount
		}
	  }
	`
	let variables = {
		teamId: teamId,
		userIds: userIds
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				console.log("removeUserFromTeam response =", res)
				if (!res || !res.data || !res.data.removeUsersFromTeam)
					throw new Error("Error occured while removing users from team")
				if (!res.data.removeUsersFromTeam.status)
					throw new Error(res.data.removeUsersFromTeam.message)
				resolve(res.data.removeUsersFromTeam.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

services.removeEmailInvitationFromTeam = (teamId, email) => {
	let query = `
	mutation removeEmailInvitationFromTeam($teamId: String! $email: String!){
		removeEmailInvitationFromTeam(teamId: $teamId email: $email){
		  status
		  message
		  data{
			id
			teamName
			teamDescription
			teamAvatar
			teamAffiliation
			teamMembers{
				userId
				email
				name
			  status
			  teamLead
			}
		  }
		  totalCount
		}
	  }
	`
	let variables = {
		teamId: teamId,
		email: email
	}
	return new Promise((resolve, reject) => {
		apolloFetcher
			.fetchWithToken({ query, variables })
			.then((res) => {
				console.log("removeEmailInvitationFromTeam response =", res)
				if (!res || !res.data || !res.data.removeEmailInvitationFromTeam)
					throw new Error("Error occured while removing users from team")
				if (!res.data.removeEmailInvitationFromTeam.status)
					throw new Error(res.data.removeEmailInvitationFromTeam.message)
				resolve(res.data.removeEmailInvitationFromTeam.data)
			})
			.catch((err) => {
				console.log("Error deleting the meail invitation from the team", err.message)
				reject(err)
			})
	})
}

export default services
