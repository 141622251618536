import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import Button from "components/CustomButtons/Button.jsx"
import Grid from "@material-ui/core/Grid"
import Tooltip from "@material-ui/core/Tooltip"
// @material-ui/icons
import AddAlert from "@material-ui/icons/AddAlert"
import Close from "@material-ui/icons/Close"
import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx"
import combineStyle from "../../utils/combineStyle"

import { connect } from "react-redux"
import AccountPage from "./AccountPage"
import actionTypes from "reduxjs/actionTypes"

function Transition(props) {
	return <Slide direction="down" {...props} />
}

class LoginSignupModal extends React.Component {
	constructor(props) {
		super(props)
	}

	handleCloseLoginModal = () => {
		this.props.closeLoginModal()
	}

	render() {
		let { classes } = this.props
		if (!this.props.isLoginModalOpen) return null
		else
			return (
				<div>
					<Dialog
						classes={{
							root: classes.center + " " + classes.modalRoot,
							paper: classes.modal
						}}
						fullWidth={true}
						maxWidth={"lg"}
						open={true}
						TransitionComponent={Transition}
						keepMounted
						//onClose={() => { this.handleCloseLoginModal() }}
						aria-labelledby="classic-modal-slide-title"
						aria-describedby="classic-modal-slide-description"
					>
						<DialogTitle
							id="classic-modal-slide-title"
							disableTypography
							//className={classes.modalHeader}
							style={{
								margin: "0px 20px"
							}}
						>
							<Grid container direction="row" justify="space-between" alignItems="center">
								<Grid item xs={10} sm={11}>
									<Grid container direction="row" justify="space-around" alignItems="center">
										<Grid item>
											<h4 className={cx(classes.modalTitle, classes.modalTitleStyle)}>
												Login or Sign up
											</h4>
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={2} sm={1}>
									<Button
										justIcon
										className={classes.modalCloseButton}
										key="close"
										aria-label="Close"
										color="transparent"
										onClick={() => {
											this.handleCloseLoginModal()
										}}
									>
										<Tooltip title="Cancel" placement="top">
											<Close className={classes.modalClose} />
										</Tooltip>
									</Button>
								</Grid>
							</Grid>
							<div style={{ marginTop: "20px" }}> </div>
						</DialogTitle>

						<DialogContent
							id="classic-modal-slide-description"
							className={classes.modalBody + " " + classes.modalBody2}
						>
							<AccountPage history={this.props.history} onLoginClose={this.handleCloseLoginModal} />
						</DialogContent>
					</Dialog>
				</div>
			)
	}
}

LoginSignupModal.propTypes = {
	openLoginModal: PropTypes.func.isRequired,
	closeLoginModal: PropTypes.func.isRequired,
	isLoginModalOpen: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
	return {
		isLoginModalOpen: state.isLoginModalOpen
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE })
	}
}

let extraStyles = (theme) => ({
	modalTitleStyle: {
		fontFamily: "Roboto Condensed",
		fontSize: "1.7em"
		// marginBottom: "20px"
	},
	modalBody2: {
		margin: "-20px 20px -20px 20px !important",
		padding: "0px !important"
	}
})

let finalStyles = combineStyle(notificationsStyle, extraStyles)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyles)(LoginSignupModal))
