import React, { Component } from "react"
import Constants from "../../../constants"
import { FaLinkedin as LinkedinIcon } from "react-icons/fa"

class LoginWithLinkedIn extends Component {
	constructor(props) {
		super(props)
		this.OAuth = window.OAuth
	}

	handleLogin = () => {
		console.log("OAuth = ", this.OAuth)
		this.OAuth.initialize(Constants.LINKEDIN_APP_ID)
		let linkedin
		let authData = {}
		this.OAuth.popup("linkedin2")
			.then(res => {
				console.log("linkedin:", res)
				linkedin = res
				return linkedin.me()
				// linkedin.get('/v2/me').then(data => {
				//     console.log('self data:', data);
				// })
			})
			.then(data => {
				console.log("me data:", data)
				authData = {
					id: "LinkedIn_" + data.id,
					email: data.email,
					name: data.name,
					profilePictureUrl: data.avatar
				}
				this.props.handleLinkedInLogin && this.props.handleLinkedInLogin(authData)
			})
			.catch(err => {
				console.log("Err auth using linkedIn = ", err.message)
			})
	}

	render() {
		return (
			<div>
				<a href id="LoginWithLinkedIn" onClick={this.handleLogin}>
					{/* <img border="0" alt="Login with Amazon"
                        src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                        width="156" height="32" /> */}
					{/* <i className="fab fa-linkedin" /> */}
					<LinkedinIcon color="white" size="2em" />
				</a>
			</div>
		)
	}
}

export default LoginWithLinkedIn
