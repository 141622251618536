import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from "prop-types";
 
import { Button, InputBase } from '@material-ui/core';
import Pagination from "material-ui-flat-pagination";

import AdminAddUser from 'components/Admin/Users/AddUsers';
import UserTable from './UserTable';

import userServices from "dataServices/userServices";
import actionTypes from "reduxjs/actionTypes";

import styles from "./styles.module.scss";

const PAGINATION_LIMIT = 10;
const ADMIN_ROLE_ARRAY = ["superAdmin", "licenseeAdmin", "challengeAdmin", "admin"];

const Users = (props) => {
  const {
    setLoadingSpinner,
    resetLoadingSpinner,
    showAlert,
    setRedirectOnError,
  } = props;

  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  
  const [nameSearchText, setNameSearchText] = useState("");
  const [emailText, setEmailText] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);

  const fetchUsers = async () => {
    const filters = {
      name: nameSearchText,
      email: emailText,
    };
    const offset = Math.max(currentPage - 1, 0) * PAGINATION_LIMIT;

    try {
      setLoadingSpinner();

      const tmpUsers = await userServices.getUsers(filters, offset, PAGINATION_LIMIT);
      setUsers(get(tmpUsers, "data"));
      setTotalUsers(get(tmpUsers, "totalCount", 0));

      resetLoadingSpinner();
    } catch (error) {
      showAlert('error', 'Opps!', error.message || error);
    }
  };

  const fetchCurrentUser = () => {
    userServices.getCurrentUser()
			.then((data) => {
        let isAdmin = false;

        get(data, "roles", []).forEach(role => {
          if (ADMIN_ROLE_ARRAY.includes(role.RoleId)) {
            isAdmin = true;
          }
        });

        if (isAdmin) {
          fetchUsers();
        } else {
          setRedirectOnError("/dashboard/index");
        }
      })
			.catch((err) => {
        setRedirectOnError("/dashboard/index");
			});
  };

  const onNameTextChange = e => {
    setNameSearchText(e.target.value);
  };

  const onEmailTextChange = e => {
    setEmailText(e.target.value);
  };

  const renderAddUserModal = () => {
    return (
      <AdminAddUser
        isModalOpen={isAddUserModalOpen}
        closeModal={async (shouldRefresh=false) => {
          setIsAddUserModalOpen(false);

          if (shouldRefresh) {
            await fetchUsers();
          }
        }}
      />
    );
  };

  const renderFilters = () => {
    return (
      <div className={styles.filterWrapper}>
        <InputBase
          className={styles.filterInput}
          value={nameSearchText}
          onChange={onNameTextChange}
          placeholder="Enter name or username"
        />
        <InputBase
          className={styles.filterInput}
          value={emailText}
          onChange={onEmailTextChange}
          placeholder="Enter email"
        />
      </div>
    )
  }

  const renderAddUserButton = () => {
    return (
      <div style={{ width: "100%" }} className="left">
        <Button
          className={styles.addUserButton}
          onClick={() => setIsAddUserModalOpen(true)}
        >
            Add User
        </Button>
      </div>
    );
  };

  const renderPagination = () => {
    return (
      <div className='center'>
        <Pagination
          size={"large"}
          limit={PAGINATION_LIMIT}
          offset={Math.max(currentPage - 1, 0) * PAGINATION_LIMIT}
          total={totalUsers}
          onClick={(e, offset) => setCurrentPage((offset / PAGINATION_LIMIT) + 1)}
        />
      </div>
    );
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage]);

  useEffect(() => {
    if (currentPage == 1) {
      fetchUsers();
    } else {
      setCurrentPage(1);
    }
  }, [nameSearchText, emailText]);

  useEffect(() => {
    fetchCurrentUser();
  }, [])

  return (
    <div style={{ height: "100%", overflowY: "auto" }}>
      {renderAddUserModal()}
      {renderAddUserButton()}
      {renderFilters()}
      <UserTable
        users={users}
        totalUsers={totalUsers}
        reFetchUser={fetchUsers}
      />
      {renderPagination()}
    </div>
  );
}

Users.propTypes = {
  setLoadingSpinner: PropTypes.func.isRequired,
  resetLoadingSpinner: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	return {
    setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
    showAlert: (alertType, title, description) =>
      dispatch({
        type: actionTypes.SHOW_ALERT,
        payload: {
          alertType,
          title,
          description
        }
      }),
    setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
