import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { NavLink as Link } from "react-router-dom";

class SimpleBreadcrumbs extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { classes, breadLinkData } = this.props;
        if (!breadLinkData) return null;
        return (
            <div className={classes.root}>
                <Breadcrumbs aria-label="Breadcrumb" separator={<span style={{ color: "grey" }}>  {"/"}  </span>}>
                    {
                        breadLinkData.map((linkObject, index) => {
                            return (
                                <Link className={classes.linkStyle}
                                    key={index}
                                    color="grey"
                                    to={linkObject.link}
                                >
                                    <span style={{ color: "grey" }}>{linkObject.name}</span>
                                </Link>
                            )
                        })
                    }
                </Breadcrumbs>
            </div>
        );
    }
}

SimpleBreadcrumbs.propTypes = {
    classes: PropTypes.object.isRequired,
    breadLinkData: PropTypes.array,
};

const styles = theme => ({
    root: {
        justifyContent: "center",
        flexWrap: "wrap",
    },
    paper: {
        padding: theme.spacing(1, 2)
    },
    linkStyle: {
        color: "#1EB980",
        //paddingLeft: "50px"
    }
});

export default withStyles(styles)(SimpleBreadcrumbs);
