import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import InputAdornment from "@material-ui/core/InputAdornment"
import Icon from "@material-ui/core/Icon"
import ListItem from "@material-ui/core/ListItem"
// @material-ui/icons
import Face from "@material-ui/icons/Face"
import Email from "@material-ui/icons/Email"
import PermIdentity from "@material-ui/icons/PermIdentity"
import EditIcon from "@material-ui/icons/Edit"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInputForChallengeBuilder"
import CustomInputForSector from "components/CustomInput/CustomInputForSector.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import CardAvatar from "components/Card/CardAvatar.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import LockIcon from "@material-ui/icons/LockOutlined"
import Constants from "constants.js"
import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx"

import helper from "../../helper"
import cookiesHelper from "cookiesHelper"
import combineStyles from "utils/combineStyle"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import userServices from "dataServices/userServices"
import { FormControl, Divider, Tooltip, IconButton } from "@material-ui/core"

class PasswordUpdateUI extends React.Component {
	constructor(props) {
		console.log("constructor!")
		super(props)
		this.state = {
			hasCurrentPassword: props.hasCurrentPassword,
			currentPassword: "",
			newPassword: "",
			newPasswordRepeat: ""
		}
	}

	componentDidMount() {
		console.log("component mounted!")
		this.setState({ hasCurrentPassword: this.props.hasCurrentPassword })
	}

	componentDidUpdate(prevProps) {
		console.log("props updated! ? =", this.props.hasCurrentPassword, prevProps.hasCurrentPassword)
		if (this.props.hasCurrentPassword != prevProps.hasCurrentPassword) {
			console.log("hasCurrentPassword =", this.props.hasCurrentPassword)
			this.setState({ hasCurrentPassword: this.props.hasCurrentPassword })
		}
	}

	isValidPassword = () => {
		if (
			!this.state.newPassword ||
			this.state.newPassword.length < 6 ||
			this.state.newPassword != this.state.newPasswordRepeat
		)
			return false
		if (this.state.hasCurrentPassword) {
			if (!this.state.currentPassword || this.state.currentPassword.length < 6) return false
		}
		return true
	}

	onClickUpdatePassword = () => {
		if (this.props.onUpdatePassword)
			this.props.onUpdatePassword({ ...this.state }, () => {
				this.setState({
					currentPassword: "",
					newPassword: "",
					newPasswordRepeat: ""
				})
			})
	}

	onClickCancel = () => {
		if (this.props.onCancel) this.props.onCancel()
		this.setState({
			currentPassword: "",
			newPassword: "",
			newPasswordRepeat: ""
		})
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<GridContainer>
					{this.state.hasCurrentPassword && (
						<GridItem xs={12}>
							<CustomInput
								labelText="Current Password"
								formControlProps={{
									fullWidth: true
								}}
								inputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<LockIcon className={classes.inputAdornmentIcon} />
										</InputAdornment>
									),
									error: this.state.currentPassword && this.state.currentPassword.length < 6,
									type: "password",
									name: "currentPassword",
									value: this.state.currentPassword,
									onChange: (e) => this.setState({ currentPassword: e.target.value })
								}}
							/>
						</GridItem>
					)}
					<GridItem xs={12}>
						<CustomInput
							labelText="New Password"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<LockIcon className={classes.inputAdornmentIcon} />
									</InputAdornment>
								),
								error: this.state.newPassword && this.state.newPassword.length < 6,
								type: "password",
								name: "newPassword",
								value: this.state.newPassword,
								onChange: (e) => this.setState({ newPassword: e.target.value })
							}}
						/>
					</GridItem>
					<GridItem xs={12}>
						<CustomInput
							labelText="Re-type New Password"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<LockIcon className={classes.inputAdornmentIcon} />
									</InputAdornment>
								),
								error:
									this.state.newPasswordRepeat &&
									(this.state.newPassword != this.state.newPasswordRepeat ||
										this.state.newPasswordRepeat.length < 6),
								type: "password",
								name: "newPasswordRepeat",
								value: this.state.newPasswordRepeat,
								onChange: (e) => this.setState({ newPasswordRepeat: e.target.value })
							}}
						/>
					</GridItem>
					<GridItem xs={12}>
						<Button
							variant="square"
							color="success"
							disabled={!this.isValidPassword()}
							onClick={this.onClickUpdatePassword}
						>
							{this.state.hasCurrentPassword ? "Change Password" : "Set Password"}
						</Button>
						<Button variant="square" color="danger" onClick={this.onClickCancel}>
							Cancel
						</Button>
					</GridItem>
				</GridContainer>
			</div>
		)
	}

	fetchEmailVerificationStatus = () => {
		userServices
			.isEmailVerified()
			.then((data) => {
				console.log("email verfication data =", data)
				this.setState({ ...data, emailUpdated: data.email })
			})
			.catch((err) => {
				console.log(err.message)
			})
	}
}

PasswordUpdateUI.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	hasCurrentPassword: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired,
	onUpdatePassword: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET })
	}
}

let extraStyle = (theme) => ({
	messageError: {
		margin: "5px 2px 5px 2px",
		color: "red",
		textAlign: "center"
	},
	messageSuccess: {
		margin: "5px 2px 5px 2px",
		color: "green",
		textAlign: "center"
	},
	sectorCustomInputStyle: {
		margin: "5px 5px 5px 5px",
		backgroundColor: "#000000"
	}
})

let finalStyle = combineStyles(userProfileStyles, extraStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(withStyles(finalStyle)(PasswordUpdateUI)))
