import React, { Component } from "react"
import PropTypes from "prop-types"
import ManagePeopleComponent from "./ManagePeopleComponent"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem"
import Card from "components/Card/Card"
import CardHeader from "components/Card/CardHeader"
import CardBody from "components/Card/CardBody"
import CardIcon from "components/Card/CardIcon.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Assignment from "@material-ui/icons/Assignment"
import Person from "@material-ui/icons/Person"
import Edit from "@material-ui/icons/Edit"
import Close from "@material-ui/icons/Close"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import manageChallengeServices from "dataServices/manageChallengeServices"
import { withStyles } from "@material-ui/core/styles"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"
import combineStyles from "utils/combineStyle"
import Table from "components/Table/Table.jsx"
import SimpleBreadcrumbs from "components/SimpleBreadcumbs.jsx"
import Constants from "constants.js"
import authentication from "authentication.js"
import ErrorPage from "../ErrorPage"
import Slide from "@material-ui/core/Slide"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { Divider } from "@material-ui/core"
import Tooltip from "@material-ui/core/Tooltip"
import ManageChallengeMenu from "../ManageChallengeMenu.jsx"
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx"

import validator from "validator"
import { Check } from "@material-ui/icons"

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

class ManagePeoplePage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			canViewThisPage: true,
			showErrorComponent: false,
			challengeId: "",
			urlSlug: "",
			challenge: null,
			availableRoles: [
				{ name: "Judge", value: "judge" },
				{ name: "Evaluator", value: "evaluator" },
				{ name: "ChallengeAdmin", value: "challengeAdmin" }
			],
			invitations: [],
			isLoading: true,
			isConfirmDeleteModalOpen: false,
			isConfirmManualAcceptModalOpen: false,
			invitationIdToConfirm: "",
			itemIndexToDelete: null,
			isUpdateRoleModalOpen: false,
			itemIndexToEdit: null,
			roleSelector: false,
			updatedRole: null
		}
	}

	searchUserPromise = (searchText) => {
		//do relevent filtering here if required
		return userServices.searchUserForManageChallenge(
			searchText,
			this.state.challengeId,
			`userId username email name avatarImagePath`
		)
	}

	setManagePeopleData = (data, onSaveDone) => {
		console.log("All data =", data)
		this.props.setLoadingSpinner()
		manageChallengeServices
			.saveInvitations(this.state.challengeId, data.selectedUsers)
			.then((data) => {
				this.setState({ invitations: data.invitations })
				this.props.resetLoadingSpinner()
				if (onSaveDone && typeof onSaveDone === "function") onSaveDone()
			})
			.catch((err) => {
				console.log("Error saving invitation, err =", err.message)
				this.props.showAlert("error", "Ooops!", err.message)
				this.props.resetLoadingSpinner()
			})
	}

	getChallengeById = (challengeId) => {
		return challengeServices
			.getChallengeById(challengeId)
			.then((challenge) => {
				if (!challenge) throw new Error("Challenge not found")
				this.setState({ challenge: challenge })
				return true
			})
			.catch((err) => {
				console.log("Error fetching challenge, err =", err.message)
				return true
			})
	}

	fetchChallengeManageInvitations = (challengeId) => {
		return manageChallengeServices
			.fetchInvitations(challengeId)
			.then((data) => {
				this.setState({ invitations: data.invitations })
				return true
			})
			.catch((err) => {
				console.log("Error fetching challenge manage invitations, err=", err.message)
				return true
			})
	}

	checkPermission = () => {
		let permissionsObject = [
			{
				permissionName: Constants.Functionalities.CanCreateOrUpdateManageChallengeInvitation,
				objectId: null
			},
			{
				permissionName: Constants.Functionalities.CanGetManageChallengeInvitation,
				objectId: null
			}
		]

		authentication
			.hasMultiplePermissions(permissionsObject)
			.then((res) => {
				let permissionOkay = true
				if (res && res.length > 0) {
					res.map((object) => {
						permissionOkay = permissionOkay && object.permissionStatus
					})
				} else {
					permissionOkay = false
				}
				if (!permissionOkay) {
					this.setState({ showErrorComponent: true })
					this.props.setRedirectOnError("/home/index")
				}
			})
			.catch((err) => {
				console.log("Err = ", err.message)
				this.setState({ showErrorComponent: true })
				this.props.setRedirectOnError("/home/index")
			})
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		Promise.resolve()
			.then(() => {
				if (this.state.challengeId) return this.state.challengeId
				return challengeServices.getChallengeById(this.state.urlSlug).then((challenge) => {
					this.setState({ challengeId: challenge.challengeId, challenge: challenge })
					return challenge.challengeId
				})
			})
			.then((challengeId) => {
				return this.fetchChallengeManageInvitations(challengeId)
			})
			.then((data) => {
				this.setState({ isLoading: false })
				this.props.resetLoadingSpinner()
				console.log("manage invitation data fetched, status =", data)
			})
			.catch((err) => {
				this.setState({ isLoading: false })
				this.props.resetLoadingSpinner()
				console.log("Error fetching all data, err =", err.message)
			})
	}

	handleOnClickDelete = (itemIndex) => {
		this.setState({
			isConfirmDeleteModalOpen: true,
			itemIndexToDelete: itemIndex
		})
	}

	handleOnClickEdit = (itemIndex) => {
		this.setState({
			isUpdateRoleModalOpen: true,
			itemIndexToEdit: itemIndex,
			updatedRole: this.state.invitations[itemIndex].role
		})
	}

	handleConfirmDelete = () => {
		let itemIndex = this.state.itemIndexToDelete
		if (itemIndex == null || itemIndex == undefined) {
			console.error("itemIndex is not provided to delete!")
			return
		}
		this.props.setLoadingSpinner()
		manageChallengeServices
			.deleteManageChallengeInvitation(this.state.challengeId, [this.state.invitations[itemIndex]])
			.then((data) => {
				this.setState({
					invitations: data.invitations,
					isConfirmDeleteModalOpen: false
				})
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("Error deleting invitation, err =", err.message)
				this.props.showAlert("error", "Ooops!", "Error deleting invitation")
				this.setState({ isConfirmDeleteModalOpen: false })
				this.props.resetLoadingSpinner()
			})
	}

	handleUpdateUserRole = () => {
		let users = [
			{
				...this.state.invitations[this.state.itemIndexToEdit],
				role: this.state.updatedRole
			}
		]
		this.props.setLoadingSpinner()
		manageChallengeServices
			.saveInvitations(this.state.challengeId, users)
			.then((data) => {
				this.setState({ invitations: data.invitations })
				this.props.resetLoadingSpinner()
				this.setState({
					isUpdateRoleModalOpen: false,
					itemIndexToEdit: null,
					updatedRole: null
				})
			})
			.catch((err) => {
				console.log("Error saving invitation, err =", err.message)
				this.props.resetLoadingSpinner()
				this.setState({
					isUpdateRoleModalOpen: false,
					itemIndexToEdit: null,
					updatedRole: null
				})
			})
	}

	handleConfirmInvitationManually = () => {
		this.props.setLoadingSpinner()
		manageChallengeServices
			.confirmInvitationManually(this.state.challengeId, this.state.invitationIdToConfirm)
			.then((data) => {
				if (!data) throw new Error("Error occured while confirming invitation manually")
				return manageChallengeServices.fetchInvitations(this.state.challengeId)
			})
			.then((data) => {
				this.setState({
					invitations: data.invitations,
					isConfirmManualAcceptModalOpen: false,
					invitationIdToConfirm: ""
				})
				this.props.resetLoadingSpinner()
			})
			.catch((err) => {
				console.log("Error confirming invitation manually, err =", err.message)
				this.props.resetLoadingSpinner()
				this.setState({
					isConfirmManualAcceptModalOpen: false,
					invitationIdToConfirm: ""
				})
				this.props.showAlert("error", "Oops!", err.message)
			})
	}

	componentDidMount() {
		const {
			match: { params }
		} = this.props
		if (params.challengeId !== ":challengeId") {
			this.setState({
				showErrorComponent: false
			})
		} else {
			this.props.setRedirectOnError("/home/index")
			return
		}
		this.props.pushBreadCrumbStack({
			name: "Manage People",
			link: this.props.location.pathname
		})
		if (validator.isUUID(params.challengeId)) {
			this.setState({ challengeId: params.challengeId }, () => {
				this.fetchAllData()
				this.checkPermission()
			})
		} else {
			this.setState({ urlSlug: params.challengeId }, () => {
				this.fetchAllData()
				this.checkPermission()
			})
		}
	}

	renderInvitations = () => {
		const { classes } = this.props

		if (!this.state.invitations || this.state.invitations.length == 0) {
			return (
				<div>
					<GridContainer direction="row" justify="space-around" alignItems="center">
						<GridItem>
							<h5> Please add admin users to the challenge </h5>
						</GridItem>
					</GridContainer>
				</div>
			)
		}
		let tableData = this.state.invitations.map((user, index) => {
			let roleName = user.role
			this.state.availableRoles.map((role) => {
				if (role.value === user.role) roleName = role.name
			})
			return [
				user.name ? user.name : "Invitee User",
				user.email,
				roleName,
				user.isAccepted ? "Accepted" : "Pending",
				<div>
					<Button
						color="youtube"
						className={classes.actionButton}
						onClick={(e) => this.handleOnClickDelete(index)}
					>
						<Tooltip title="Delete Invitation" placement="top">
							<Close className={classes.icon} />
						</Tooltip>
					</Button>

					<Button
						color="success"
						className={classes.actionButton}
						onClick={(e) => this.handleOnClickEdit(index)}
					>
						<Tooltip title="Edit Invitation" placement="top">
							<Edit className={classes.icon} />
						</Tooltip>
					</Button>

					{!user.isAccepted && (
						<Button
							color="success"
							className={classes.actionButton}
							onClick={(e) => {
								this.setState({
									isConfirmManualAcceptModalOpen: true,
									invitationIdToConfirm: user.invitationId
								})
							}}
						>
							<Tooltip title="Confirm Invitation Manually" placement="top">
								<Check className={classes.icon} />
							</Tooltip>
						</Button>
					)}
				</div>
			]
		})
		return (
			<Table tableHead={["Name", "Email", "Role", "Status", "Actions"]} tableData={tableData} />
		)
	}

	renderConfirmRemoveModal = () => {
		if (!this.state.isConfirmDeleteModalOpen) return null
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isConfirmDeleteModalOpen: false })}
			>
				<DialogTitle> Are you sure? </DialogTitle>
				<DialogContent>
					<DialogContentText>
						Do you really want to delete this user from challenge management roles?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => this.setState({ isConfirmDeleteModalOpen: false })}
						color="tumbler"
					>
						No
					</Button>
					<Button onClick={this.handleConfirmDelete} color="youtube">
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderUpdateUserRoleModal = () => {
		if (!this.state.isUpdateRoleModalOpen) return null
		const { classes } = this.props
		let isUpdateButton = false
		if (
			this.state.updatedRole &&
			this.state.invitations[this.state.itemIndexToEdit].role != this.state.updatedRole
		)
			isUpdateButton = true
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isUpdateRoleModalOpen: false })}
			>
				<DialogTitle> Alter user role? </DialogTitle>
				<DialogContent>
					<div>
						<h5> Are you sure you want to alter the following user role? </h5>
						<br />
						<GridContainer direction="column" justify="space-around" alignItems="stretch">
							<GridItem xs={6} lg={6}>
								Name:
							</GridItem>
							<GridItem xs={6} lg={6}>
								{this.state.invitations[this.state.itemIndexToEdit].name || "Invitee User"}
							</GridItem>
							<Divider variant="fullWidth" />
							<GridItem xs={6} lg={6}>
								Email:
							</GridItem>
							<GridItem xs={6} lg={6}>
								{this.state.invitations[this.state.itemIndexToEdit].email}
							</GridItem>
							<Divider variant="fullWidth" />
							<GridItem xs={6} lg={6}>
								Status:
							</GridItem>
							<GridItem xs={6} lg={6}>
								{this.state.invitations[this.state.itemIndexToEdit].isAccepted
									? "ACCEPTED"
									: "PENDING"}
							</GridItem>
							<Divider variant="fullWidth" />
						</GridContainer>
						<br />
					</div>

					<form autoComplete="off">
						<FormControl className={classes.formControl}>
							<InputLabel>Role</InputLabel>
							<Select
								open={this.state.roleSelector}
								onClose={() => this.setState({ roleSelector: false })}
								onOpen={() => this.setState({ roleSelector: true })}
								value={this.state.updatedRole}
								onChange={(e) => this.setState({ updatedRole: e.target.value })}
								inputProps={{
									name: "updatedRole",
									fullWidth: true
								}}
							>
								{this.state.availableRoles &&
									this.state.availableRoles.map((role) => {
										return <MenuItem value={role.value}>{role.name}</MenuItem>
									})}
							</Select>
						</FormControl>
					</form>
					<br />
				</DialogContent>
				<DialogActions>
					<Button onClick={() => this.setState({ isUpdateRoleModalOpen: false })} color="secondary">
						Cancel
					</Button>
					<Button onClick={this.handleUpdateUserRole} color="success" disabled={!isUpdateButton}>
						Update
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	renderConfirmManualAcceptModal = () => {
		if (!this.state.isConfirmManualAcceptModalOpen) return null
		return (
			<Dialog
				open={true}
				TransitionComponent={Transition}
				keepMounted
				onClose={() => this.setState({ isConfirmManualAcceptModalOpen: false })}
			>
				<DialogTitle> Are you sure? </DialogTitle>
				<DialogContent>
					<DialogContentText>
						Do you really want to confirm this invitation manually?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => this.setState({ isConfirmManualAcceptModalOpen: false })}
						color="tumbler"
					>
						No
					</Button>
					<Button onClick={this.handleConfirmInvitationManually} color="youtube">
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	render() {
		const { classes } = this.props
		let title = this.state.challenge ? this.state.challenge.challengeDetails.title : null
		if (this.state.showErrorComponent) {
			return (
				<ErrorPage
					errorCode={403}
					message={"Access Denied! :("}
					description={"You are not logged in, please login to see this page"}
				/>
			)
		}
		if (this.state.isLoading) {
			console.log("isLoading")
			return <div> </div>
		}
		return (
			<div>
				<GridContainer direction="row" justify="space-between">
					<GridItem xs={9} sm={10} md={10} lg={11}>
						<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
					</GridItem>
					<GridItem className={classes.right} xs={3} sm={2} md={2} lg={1}>
						<ManageChallengeMenu
							challengeId={this.state.challengeId}
							challenge={this.state.challenge}
							urlSlug={this.state.urlSlug}
							history={this.props.history}
						/>
					</GridItem>
				</GridContainer>
				<br />
				<SnackbarContent
					message={this.state.challenge.challengeDetails.phaseTitle}
					color="success"
				/>

				{this.renderConfirmRemoveModal()}
				{this.renderUpdateUserRoleModal()}
				{this.renderConfirmManualAcceptModal()}
				<GridContainer direction="row" justify="center" alignItems="flex-start">
					<GridItem xs={12} sm={12} md={8} lg={6}>
						<Card raised color="gmgTheme">
							<CardHeader color="gmgTheme">
								{/* <CardIcon color="gmgTheme">
									<Assignment />
								</CardIcon> */}
								<h3 className={classes.cardIconTitle + "asda"}>
									<strong>{title}</strong> Users
								</h3>
							</CardHeader>
							<CardBody>{this.renderInvitations()}</CardBody>
						</Card>
					</GridItem>

					<GridItem xs={12} sm={12} md={8} lg={6}>
						<Card raised color="gmgTheme">
							<CardHeader color="gmgTheme">
								{/* <CardIcon color="gmgTheme">
									<Assignment />
								</CardIcon> */}
								<h3 className={classes.cardIconTitle + "dasda"}>
									Invite people to manage <strong>{title}</strong> challenge
								</h3>
							</CardHeader>
							<CardBody>
								<ManagePeopleComponent
									title={null}
									availableRoles={this.state.availableRoles}
									searchUserPromise={this.searchUserPromise}
									setManagePeopleData={this.setManagePeopleData}
								/>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

ManagePeoplePage.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

let extraStyles = (theme) => ({})

let finalStyle = combineStyles(extraStyles, extendedTablesStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyle)(ManagePeoplePage))
