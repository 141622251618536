import { removePortNumberFromURL } from "utils/urls";

export const prepareDataForSubmissionForm = (formData) => {
  const updatedData = JSON.parse(JSON.stringify(formData));
  const { data } = updatedData;

  Object.keys(data).forEach(value => {
    const files = data[value];

    if (Array.isArray(files)) {
      files.forEach(file => {
        file.url = removePortNumberFromURL(file.url);
      });
    }
  });

  return updatedData;
};