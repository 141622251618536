const renderActionColumnHeader = () => {
  return (
    <div className="center">Action</div>
  );
}

export const getUsersTableColumns = () => {
  return [
    "Name",
    "Email",
    "Role",
    renderActionColumnHeader(),
  ];
};