import apolloFetcher from "apolloFetcher";

let services = {};

services.sendBulkEmailToUsersByRoles = (variables) => {
  let query = `
    mutation sendBulkEmailByRole($roles: [String!]! $emailSubject: String! $emailBody: String!){
        sendBulkEmailByRole(
          roles: $roles
          emailSubject: $emailSubject
          emailBody: $emailBody
        ){
          status
          message
        }
      }
    `;

  return new Promise((resolve, reject) => {
    apolloFetcher.fetchWithToken({ query, variables }).then(res => {
      if (!res || !res.data || !res.data.sendBulkEmailByRole) throw new Error("Error occured while fetching solutions");
      if (!res.data.sendBulkEmailByRole.status) throw new Error(res.data.sendBulkEmailByRole.message);
      resolve(res.data.sendBulkEmailByRole.status);
    }).catch(err => {
      reject(err);
    })
  })
}

export default services;