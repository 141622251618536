import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import Button from "components/CustomButtons/Button.jsx"
import Paper from "@material-ui/core/Paper"
import Tooltip from "@material-ui/core/Tooltip"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import CustomInput from "components/CustomInput/CustomInput.jsx"
import ManageMembersComponent from "./ManageTeamMembers"
import CardFooter from "components/Card/CardFooter.jsx"

import Close from "@material-ui/icons/Close"

import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"

import { Mutation } from "@apollo/client/react/components"
import { gql } from '@apollo/client'

import constant from "../../../constants"

import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import teamServices from "dataServices/teamServices"
import Table from "components/Table/Table"
import combineStyles from "utils/combineStyle"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle"

const styles = (theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper
	}
})

const uploadFileMutation = gql`
	mutation uploadFile($file: Upload!, $uploadType: String!) {
		uploadFile(file: $file, uploadType: $uploadType) {
			status
			message
			totalCount
			data {
				id
				filename
				path
				mimetype
				encoding
			}
		}
	}
`

class EditTeamMembers extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			challengeId: "",
			teamId: null,
			userProfile: null,
			challenge: null,
			showErrorComponent: true,
			availableRoles: [
				{ name: "Member", value: "teamMember" },
				{ name: "Team Lead", value: "teamLead" }
			],
			teamName: "",
			teamAffiliation: "",
			teamAvatar: "",
			file: null,
			fileName: null,
			team: null
		}
	}

	fetchTeamMembers = (teamId) => {
		console.log("Lets fetch the team members, teamId =", teamId)
		return teamServices
			.getTeam(teamId)
			.then((data) => {
				//console.log("team data = ", data);
				if (!data || !data.teamMembers) throw new Error("Error getting team information")
				let users = data.teamMembers //.filter(tm => (tm.status != "PENDING"));
				console.log("users in team = ", users)
				// return Promise.all(
				// 	users.map(user => {
				// 		return userServices
				// 			.getUserProfileById(user.userId)
				// 			.then(userData => {
				// 				if (!userData) throw new Error("User not found")
				// 				userData.isTeamLead = user.teamLead //carring the teamLead flag with detailed user
				// 				userData.status = user.status
				// 				return userData
				// 			})
				// 			.catch(err => {
				// 				console.log("Error getting user, err =", err.message)
				// 				return null
				// 			})
				// 	})
				// ).then(result => {
				// 	//console.log("promise all result = ", result);
				// 	result = result.filter(r => r != null)
				// 	return { ...data, teamMembers: result }
				// })
				return data
			})
			.catch((err) => {
				console.log("Error fetting team, err= ", err.message)
				return null
			})
	}

	fetchAllData = () => {
		this.props.setLoadingSpinner()
		this.fetchTeamMembers(this.props.teamId).then((data) => {
			console.log("team data = ", data)
			if (!data) {
				console.log("Team data null")
			}
			this.props.resetLoadingSpinner()
			this.setState({
				team: data,
				teamName: data.teamName,
				teamAffiliation: data.teamAffiliation,
				teamDescription: data.teamDescription,
				teamMembers: data.teamMembers,
				teamAvatar: data.teamAvatar
			})
		})
	}

	fetchCurrentUserProfile = () => {
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				console.log("Current User", data)
				return data
			})
			.catch((err) => {
				console.log("Error getting current User", err)
				return null
			})
	}

	getChallengeById = () => {
		let challengeId = this.state.challengeId
		return challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				console.log("challenge: ", data)
				return data
			})
			.catch((err) => {
				console.log("error getting challenge by challenge Id", err)
				return null
			})
	}

	isAllDataValid = () => {
		const { teamName, selectedUsers } = this.state
		if (teamName && selectedUsers && selectedUsers.length > 0) return true
		return false
	}

	isBasicInfoChanged = () => {
		return (
			this.state.teamName != this.state.team.teamName ||
			this.state.teamAffiliation != this.state.team.teamAffiliation ||
			this.state.teamAvatar != this.state.team.teamAvatar
		)
	}

	isExistingMembersChanged = () => {
		let oldTeamLeadIndex = -1,
			newTeamLeadIndex = -1
		this.state.team.teamMembers.map((tm, i) => {
			if (tm.teamLead) oldTeamLeadIndex = i
		})
		this.state.teamMembers.map((tm, i) => {
			if (tm.teamLead) newTeamLeadIndex = i
		})
		console.log("old vs new teamLead indices", oldTeamLeadIndex, newTeamLeadIndex)
		return oldTeamLeadIndex != newTeamLeadIndex
	}

	handleUpdateBasicInfo = () => {
		console.log("Updating the team basic information")
		this.props.setLoadingSpinner()
		let teamData = {
			id: this.state.teamId,
			teamName: this.state.teamName,
			teamAffiliation: this.state.teamAffiliation,
			teamAvatar: this.state.teamAvatar
		}
		teamServices
			.createOrUpdateTeam(teamData)
			.then((data) => {
				this.props.resetLoadingSpinner()
				if (!data) throw new Error("Error updating team basic information")
				console.log("after team update data = ", data)
				this.setState({ team: data })
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error updating team basic information", err.message)
				this.props.showAlert("error", "Ooops!", err.message)
			})
	}

	handleInviteNewMembers = () => {
		console.log("Inviting new users in team")
		this.props.setLoadingSpinner()

		let teamMembers = []
		this.state.selectedUsers.map((user) => {
			teamMembers.push({
				userId: user.userId,
				email: user.email,
				name: user.userId ? user.name : "Invitee User",
				teamLead: user.role === "teamLead"
			})
		})

		let teamData = {
			id: this.state.teamId,
			teamName: this.state.team.teamName,
			teamAffiliation: this.state.team.teamAffiliation,
			teamAvatar: this.state.team.teamAvatar,
			teamMembers: teamMembers
		}

		return teamServices
			.createOrUpdateTeam(teamData)
			.then((data) => {
				this.props.resetLoadingSpinner()
				if (!data) throw new Error("Error inviting new users in team")
				console.log("after team update data = ", data)
				this.setState({ team: data })
				this.fetchAllData()
				return true
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error updating", err.message)
				this.props.showAlert("error", "Ooops!", err.message)
				return false
			})
	}

	handleUpdateExistingMembers = () => {
		console.log("Updating the existing members roles")
		let isTeamLeadExist = false
		let teamData = {
			id: this.state.teamId,
			teamName: this.state.team.teamName,
			teamAffiliation: this.state.team.teamAffiliation,
			teamAvatar: this.state.team.teamAvatar,
			teamMembers: this.state.teamMembers.map((user) => {
				if (user.teamLead) isTeamLeadExist = true
				return {
					userId: user.userId,
					email: user.email,
					name: user.name,
					teamLead: user.teamLead
				}
			})
		}

		console.log("Team Data = ", teamData)
		if (!isTeamLeadExist) {
			this.props.showAlert("error", "Opps!", "No TeamLead is selected")
			return
		}

		this.props.setLoadingSpinner()
		teamServices
			.createOrUpdateTeam(teamData)
			.then((data) => {
				console.log("team update data = ", data)
				this.props.resetLoadingSpinner()
				if (!data) throw new Error("Error updating team")
				this.setState({ team: data })
				this.props.onCancelEdit()
				if (this.props.reloadChallengeData) this.props.reloadChallengeData()
			})
			.catch((err) => {
				console.log("Error in create and join team", err.message)
				this.props.resetLoadingSpinner()
				this.props.showAlert("error", "Ooops!", err.message)
			})
	}

	handleOnClickDelete = (index) => {
		if (this.state.teamMembers[index].teamLead) {
			this.props.showAlert("warning", "Ooops!", "Teamlead can not be deleted")
			return
		}
		this.props.setLoadingSpinner()
		if (!this.state.teamMembers[index].userId) {
			//delete oinvitation; its non existing user
			teamServices
				.removeEmailInvitationFromTeam(this.state.teamId, this.state.teamMembers[index].email)
				.then((data) => {
					console.log("After deleting team email invitation, data", data)
					this.fetchAllData()
					if (this.props.reloadChallengeData) this.props.reloadChallengeData()
				})
				.catch((err) => {
					console.log("Error deleting the team email invitation", err.message)
					this.props.showAlert("error", "Ooops!", err.message)
					this.props.resetLoadingSpinner()
				})
		} else {
			teamServices
				.removeUsersFromTeam(this.state.teamId, [this.state.teamMembers[index].userId])
				.then((data) => {
					console.log("After deleting team member, data", data)
					this.fetchAllData()
					if (this.props.reloadChallengeData) this.props.reloadChallengeData()
				})
				.catch((err) => {
					console.log("Error deleting the user", err.message)
					this.props.showAlert("error", "Ooops!", err.message)
					this.props.resetLoadingSpinner()
				})
		}
	}

	handleFileChange = (e) => {
		e.preventDefault()
		let reader = new FileReader()
		let file = e.target.files[0]
		if (!file) {
			this.setState({ file: null, fileName: "" })
			return
		}
		reader.onloadend = () => {
			this.setState({
				file: { file },
				fileName: file.name
			})
			console.log("state: ", this.state)
		}
		reader.readAsDataURL(file)
	}

	handleChangeRole = (index, value) => {
		let users = this.state.teamMembers
		console.log("index, value = ", index, value)
		if (value == "teamLead") {
			users = users.map((u, i) => {
				if (i == index) return { ...u, isTeamLead: true, teamLead: true }
				else return { ...u, isTeamLead: false, teamLead: false }
			})
		} else {
			users = users.map((u, i) => {
				if (i == index) return { ...u, isTeamLead: false, teamLead: false }
				else return { ...u }
			})
		}

		this.setState({ teamMembers: users })
	}

	uploadFile = (mutation) => {
		if (this.state.file) {
			this.props.setLoadingSpinner()
			console.log("File valid. lets call graphQL api!", this.state.file)
			mutation({
				mutation: uploadFileMutation,
				variables: { ...this.state.file, uploadType: "image" },
				fetchPolicy: "no-cache",
				context: {
				  headers: {
					'x-apollo-operation-name': 'upload-file'
				  }
				},
			})
				.then((res) => {
					this.props.resetLoadingSpinner()
					if (res.data.uploadFile.status == true) {
						console.log("File upload response", res.data.uploadFile.data)
						let filePath = res.data.uploadFile.data.path
						this.setState({ teamAvatar: filePath })
					} else {
						throw new Error(res.data.uploadFile.message)
					}
				})
				.catch((err) => {
					this.props.resetLoadingSpinner()
					this.props.showAlert("error", "Error!", err.message)
					console.log("err = ", err.message)
				})
		} else {
			this.props.showAlert("error", "Error!", "No file is selected")
			console.log("No File is selected")
		}
	}

	componentDidMount() {
		console.log("props =", this.props)
		this.setState({
			challengeId: this.props.challengeId,
			teamId: this.props.teamId
		})
		this.fetchAllData()
	}

	searchUserPromise = (searchText) => {
		//do relevent filtering here if required
		return userServices.searchUserForJoinTeam(searchText, this.state.challengeId)
	}

	setManagePeopleData = (data, onSaveDone) => {
		console.log("All data =", data)
		this.setState({ selectedUsers: data.selectedUsers }, () => {
			this.handleInviteNewMembers().then((res) => {
				console.log("team update status =", res)
				if (res && onSaveDone && typeof onSaveDone === "function") onSaveDone()
			})
		})
	}

	render() {
		const { classes } = this.props
		return this.showTeamEditUI()
	}

	showTeamEditUI = () => {
		if (!this.state.team) return null
		const { classes } = this.props
		return (
			<div>
				<Card raised style={{ backgroundColor: "#302f2f", color: "#FFF" }}>
					<CardHeader>
						<h3> Manage Team </h3>
					</CardHeader>
					<CardBody>
						<GridContainer container direction="row" justify="center" alignItems="center">
							<GridItem xs={12} sm={12} md={12} lg={12}>
								{this.renderBasicInfoUI()}
							</GridItem>

							<GridItem xs={12} sm={12} md={12} lg={12}>
								{this.renderEditExistingUserUI()}
							</GridItem>

							<GridItem xs={12} sm={12} md={12} lg={12}>
								<ManageMembersComponent
									title={null}
									availableRoles={this.state.availableRoles}
									searchUserPromise={this.searchUserPromise}
									setManagePeopleData={this.setManagePeopleData}
								/>
							</GridItem>

							<GridItem xs={12} sm={12} md={12} lg={12}>
								<Button color="danger" onClick={() => this.props.onCancelEdit()}>
									Cancel Editing
								</Button>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
			</div>
		)
	}

	renderBasicInfoUI = () => {
		const { classes } = this.props
		return (
			<Card raised>
				<CardHeader>
					<h4> Basic information </h4>
				</CardHeader>
				<CardBody>
					<div style={{ margin: "20px" }}>
						<CustomInput
							labelText="Team Name"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								name: "teamName",
								value: this.state.teamName,
								onChange: (e) => this.setState({ teamName: e.target.value })
							}}
						/>
						<CustomInput
							labelText="Team Affiliation"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								name: "teamAffiliation",
								value: this.state.teamAffiliation,
								onChange: (e) => this.setState({ teamAffiliation: e.target.value })
							}}
						/>
						<CustomInput
							inputProps={{
								type: "file",
								name: "teamAvatar",
								onChange: this.handleFileChange
							}}
						/>
						<Mutation mutation={uploadFileMutation} fetchPolicy="no-cache" context={{ headers: { 'x-apollo-operation-name': 'upload-file' } }}>
							{(mutation, { loading }) => (
								<Button color="rose" round onClick={() => this.uploadFile(mutation)}>
									Upload
								</Button>
							)}
						</Mutation>
					</div>
				</CardBody>
				<CardFooter>
					<GridContainer direction="row" justify="flex-end" alignItems="flex-end">
						<GridItem>
							<Button
								square
								color="youtube"
								disabled={!this.isBasicInfoChanged()}
								onClick={() => {
									this.setState({
										teamName: this.state.team.teamName,
										teamAffiliation: this.state.team.teamAffiliation,
										teamAvatar: this.state.team.teamAvatar
									})
								}}
							>
								Cancel
							</Button>
						</GridItem>
						<GridItem>
							<Button
								square
								color="gmgGreen900"
								disabled={!this.isBasicInfoChanged()}
								onClick={this.handleUpdateBasicInfo}
							>
								Update
							</Button>
						</GridItem>
					</GridContainer>
				</CardFooter>
			</Card>
		)
	}

	renderEditExistingUserUI = () => {
		const { classes } = this.props
		let selectedUsers = this.state.teamMembers
		let tableData = selectedUsers.map((user, index) => {
			return [
				user.name ? user.name : "Invitee User",
				user.email,
				user.status,
				<div>
					<form autoComplete="off">
						<FormControl className={classes.formControl}>
							<InputLabel> Role </InputLabel>
							<Select
								value={user.teamLead ? "teamLead" : "teamMember"}
								onChange={(e) => this.handleChangeRole(index, e.target.value)}
							>
								{this.state.availableRoles &&
									this.state.availableRoles.map((role, jndex) => {
										return (
											<MenuItem value={role.value} key={jndex}>
												{role.name}
											</MenuItem>
										)
									})}
							</Select>
						</FormControl>
					</form>
				</div>,
				<Button
					color="youtube"
					className={classes.actionButton}
					onClick={(e) => this.handleOnClickDelete(index)}
				>
					<Tooltip title="Remove team member" placement="top">
						<Close className={classes.icon} />
					</Tooltip>
				</Button>
			]
		})

		return (
			<Card raised>
				<CardHeader>
					<h4> Update team members </h4>
				</CardHeader>
				<CardBody>
					<Table tableHead={["Name", "Email", "Status", "Role", "Action"]} tableData={tableData} />
				</CardBody>
				<CardFooter>
					<GridContainer direction="row" justify="flex-end" alignItems="flex-end">
						<GridItem>
							<Button
								square
								color="youtube"
								disabled={!this.isExistingMembersChanged()}
								onClick={() => {
									this.setState({
										teamMembers: this.state.team.teamMembers
									})
								}}
							>
								Cancel
							</Button>
						</GridItem>
						<GridItem>
							<Button
								square
								color="gmgGreen900"
								disabled={!this.isExistingMembersChanged()}
								onClick={this.handleUpdateExistingMembers}
							>
								Update
							</Button>
						</GridItem>
					</GridContainer>
				</CardFooter>
			</Card>
		)
	}
}

EditTeamMembers.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	scrollUpdate: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		scrollUpdate: () => dispatch({ type: actionTypes.CONTENT_HEIGHT_UPDATED })
	}
}

let finalStyles = combineStyles(styles, extendedTablesStyle)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(finalStyles)(EditTeamMembers))
