import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import InvalidEmailSnackBar from "../Components/InvalidEmailSnackBar"
import notificationServices from "dataServices/notificationServices"

class GlobalActionHandler extends Component {
	componentDidMount() {
		this.fetchUnreadNotificationCount().then((notifsCount) => {
			this.props.setNotificationCount(notifsCount)
		})
		setInterval(() => {
			this.fetchUnreadNotificationCount().then((notifsCount) => {
				this.props.setNotificationCount(notifsCount)
			})
		}, 30000)
	}

	fetchUnreadNotificationCount = () => {
		return notificationServices
			.fetchUnreadNotifications()
			.then((data) => {
				if (!data) throw new Error("Error occured while fetching notifications")
				return data.length
			})
			.catch((err) => {
				console.log("Error fetching unread notifs, err=", err.message)
				return 0
			})
	}

	render() {
		if (this.props.redirectOnError && this.props.redirectOnError != "") {
			this.props.history.push(this.props.redirectOnError)
			this.props.setRedirectOnError("")
		}
		return (
			<div>
				<InvalidEmailSnackBar history={this.props.history} />
			</div>
		)
	}
}

GlobalActionHandler.propTypes = {
	classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
	return {
		redirectOnError: state.redirectOnError,
		loggedInUserData: state.loggedInUserData
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		hideAlert: () => dispatch({ type: actionTypes.HIDE_ALERT }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setNotificationCount: (count) =>
			dispatch({ type: actionTypes.SET_NOTIFICATION_COUNT, payload: count }),
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalActionHandler)
