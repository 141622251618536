import React from "react"
// react component for creating dynamic tables
import ReactTable from "react-table"
import PropTypes from "prop-types"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import TextField from "@material-ui/core/TextField"
import Input from "@material-ui/core/Input"
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment"
import Dvr from "@material-ui/icons/Dvr"
import Favorite from "@material-ui/icons/Favorite"
import CloseIcon from "@material-ui/icons/Close"
import SearchIcon from "@material-ui/icons/Search"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardIcon from "components/Card/CardIcon.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardFooter from "components/Card/CardFooter.jsx"
import Accordion from "components/Accordion/Accordion"
import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx"
import Table from "components/Table/Table.jsx"
import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx"

import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"
import ErrorPage from "views/Pages/ErrorPage"

import Constants from "constants.js"
import helper from "../../helper"
import combineStyles from "utils/combineStyle"
import validator from "validator"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import evaluationScoreServices from "dataServices/evaluationScoreServices"

import firstPlaceLogo from "assets/img/faces/firstPlace.webp"
import secondPlaceLogo from "assets/img/faces/secondPlace.webp"

const PlacementLogo = [firstPlaceLogo, secondPlaceLogo]

class LeaderBoardPage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			challengeId: "",
			challenge: null,
			userProfile: null,
			hasPermission: false,
			isLoading: true,
			errorMessage: "",
			leaderboard: []
		}
	}

	fetchCurrentUserProfile = () => {
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				console.log("Current User", data)
				return data
			})
			.catch((err) => {
				console.log("Error getting current User", err.message)
				return null
			})
	}

	fetchChallenge = (challengeId) => {
		return challengeServices
			.getChallengeById(challengeId)
			.then((data) => {
				return data
			})
			.catch((err) => {
				console.log("Error fetching challenge", err.message)
				return null
			})
	}

	fetchLeaderBoard = (challengeId) => {
		return evaluationScoreServices
			.getLeaderboardFinal({ challengeId: challengeId })
			.then((data) => {
				if (!data) throw new Error("Error occured while fetching leaderboard")
				return data
			})
			.catch((err) => {
				console.log("Erro fetching leaderboard, err = ", err.message)
				return null
			})
	}

	fetchAllData = (challengeIdOrUrlSlug) => {
		this.props.setLoadingSpinner()
		Promise.resolve()
			.then(() => {
				return challengeServices.getPublicChallengeById(challengeIdOrUrlSlug)
			})
			.then((challenge) => {
				this.setState({ challengeId: challenge.challengeId, urlSlug: challenge.urlSlug })
				return challenge.challengeId
			})
			.then((challengeId) => {
				return Promise.all([
					this.fetchCurrentUserProfile(),
					this.fetchChallenge(challengeId),
					this.fetchLeaderBoard(challengeId)
				])
			})
			.then((result) => {
				console.log("multiple api calls ", result)
				this.props.resetLoadingSpinner()
				let userProfile = result[0]
				let challenge = result[1]
				let leaderboard = result[2] || []
				if (!userProfile)
					this.setState({
						errorMessage: "You are not logged in",
						showErrorComponent: true
					})
				else if (!challenge)
					this.setState({
						errorMessage: "Challenge doesn't exist",
						showErrorComponent: true
					})
				this.setState({
					challenge: challenge,
					userProfile: userProfile,
					leaderboard: leaderboard,
					isLoading: false
				})
				if (!userProfile || !challenge) {
					this.props.setRedirectOnError("/home/index")
				}
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error:", err)
			})
	}

	componentDidMount() {
		console.log("Component Did Mount!")
		const {
			match: { params }
		} = this.props
		this.props.pushBreadCrumbStack({
			name: "Leaderboard",
			link: this.props.location.pathname
		})
		validator.isUUID(params.challengeId)
			? this.setState({ challengeId: params.challengeId })
			: this.setState({ urlSlug: params.challengeId })
		this.fetchAllData(params.challengeId)
	}

	componentWillUnmount() {
		helper.setPageTitle("BestInCrowd")
	}

	render() {
		const { classes } = this.props
		helper.setPageTitle("Leaderboard | BestInCrowd")
		let title = this.state.challenge
			? "Leaderboard | " + this.state.challenge.challengeDetails.title
			: "Leaderboard"
		if (this.state.showErrorComponent) {
			return (
				<div>
					<ErrorPage
						errorCode={403}
						message={"Access Denied! :("}
						description={this.state.errorMessage || "Error showing this page"}
					/>
				</div>
			)
		}
		if (this.state.isLoading) {
			return null
		}
		let tableData = this.state.leaderboard.map((item, i) => {
			return [
				<div>
					{1 + i}
					{item.standing && PlacementLogo[i] ? (
						<img src={PlacementLogo[i]} alt="Image of standing badge" />
					) : null}
				</div>,
				item.teamName || item.userName || item.username,
				item.averageScore,
				item.variance
			]
		})
		return (
			<div>
				<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
				<br />
				<br />

				<Card raised>
					<CardHeader icon>
						<CardIcon color="rose">
							<Assignment />
						</CardIcon>
						<h3 className={classes.cardIconTitle}> {title} </h3>
					</CardHeader>
					<CardBody>
						{this.state.leaderboard.length ? (
							<Table
								tableHead={["Standing", "Solver/Team Name", "Score", "Variance"]}
								tableData={tableData}
							/>
						) : (
							<h4> Solutions are not evaluated/judged yet! </h4>
						)}
					</CardBody>
				</Card>
			</div>
		)
	}
}

let extraStyle = (theme) => ({
	cardProductDesciprion: {
		borderLeft: "5px solid cyan",
		padding: "5px 10px 5px 10px"
	},
	cardIconTitle: {
		...cardTitle,
		marginTop: "15px",
		marginBottom: "0px"
	},
	featuredCardHead: {
		backgroundColor: "#CBCAF9",
		height: "105px"
	},
	challengesCardHead: {
		backgroundColor: "#CBCAF9",
		height: "105px"
	}
})

LeaderBoardPage.propTypes = {
	classes: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
	openLoginModal: PropTypes.func.isRequired,
	closeLoginModal: PropTypes.func.isRequired,
	setAfterLoginRedirectUrl: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo }),
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		openLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_OPEN }),
		closeLoginModal: () => dispatch({ type: actionTypes.LOGIN_MODAL_CLOSE }),
		setAfterLoginRedirectUrl: (url) =>
			dispatch({
				type: actionTypes.AFTER_LOGIN_REDIRECT_URL_SET,
				payload: url
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

let finalStyle = combineStyles(extraStyle, extendedTablesStyle)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(finalStyle)(LeaderBoardPage))
