import React from "react"
import { NavLink } from "react-router-dom"
import PropTypes from "prop-types"
import cx from "classnames"

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

import footerStyle from "assets/jss/material-dashboard-pro-react/components/footerStyle"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Pagination from "components/Pagination/Pagination.jsx"
import Button from "components/CustomButtons/Button.jsx"
import IconButton from "@material-ui/core/IconButton"

import { FaFacebookF as FacebookIcon } from "react-icons/fa"
import { FaTwitterSquare as TwitterIcon } from "react-icons/fa"
import { FaGithubSquare as GithubIcon } from "react-icons/fa"
import cccLogo from "assets/img/BIC_Logo.webp"
import FacebookLogoImage from "assets/img/FacebookLogoCirlce.png"

function Footer({ ...props }) {
	const { classes, fluid, white, rtlActive } = props
	var container = cx({
		[classes.container]: !fluid,
		[classes.containerFluid]: fluid,
		[classes.whiteColor]: white
	})
	var anchor =
		classes.a +
		cx({
			[" " + classes.whiteColor]: white
		})
	var block = cx({
		[classes.block]: true,
		[classes.whiteColor]: white
	})
	return (
		<footer className={classes.footer}>
			<div className={container}>
				<div className={classes.left}>
					<List className={classes.list}>
						<ListItem className={classes.inlineBlock}>
							<NavLink to={"/home/about-ccc"}>About CCC</NavLink>
						</ListItem>

						<ListItem className={classes.inlineBlock}>
							<NavLink to={"/home/privacy"}>Privacy</NavLink>
						</ListItem>

						<ListItem className={classes.inlineBlock}>
							<NavLink to={"/home/newsletter-signup"}>Newsletter</NavLink>
						</ListItem>
					</List>
				</div>
				&nbsp;
				<a
					href="https://twitter.com/capital_ccc"
					className="twitter-follow-button"
					data-show-count="false"
				>
					Follow @capital_ccc
				</a>
				&nbsp;
				<IconButton
					justIcon
					color="facebook"
					simple
					style={{ marginTop: "-15px" }}
					onClick={() => {
						window.open(
							"https://www.facebook.com/Capital-Consulting-Corporation-1109502325726480/?view_public_for=1109502325726480"
						)
					}}
				>
					{/* <FacebookIcon color="#3b5998" size="3em" /> */}
					<img src={FacebookLogoImage} alt="Facebook Logo" height="20px" width="20px" />
				</IconButton>
				<p className={classes.right}>
					&copy; {1900 + new Date().getYear()} <NavLink to={"/"}>CCC</NavLink>
				</p>
			</div>
		</footer>
	)
}

Footer.propTypes = {
	classes: PropTypes.object.isRequired,
	fluid: PropTypes.bool,
	white: PropTypes.bool,
	rtlActive: PropTypes.bool
}

export default withStyles(footerStyle)(Footer)
