import {
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.jsx";

const accordionStyle = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: "20px",
    backgroundColor: "transparent !important",
  },
  expansionPanel: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
    "&:before": {
      display: "none !important"
    }
  },
  expansionPanelExpanded: {
    margin: "0"
  },
  expansionPanelSummary: {
    minHeight: "auto !important",
    backgroundColor: theme.palette.accordion.summary.backgroundColor, //"transparent",
    borderBottom: "1px solid " + grayColor[5],
    padding: "5px 10px 5px 10px",
    //borderTopLeftRadius: "5px",
    //borderTopRightRadius: "5px",
    color: theme.palette.accordion.summary.color,//grayColor[2],
    "&:hover": {
      //color: primaryColor[0]
    }
  },
  expansionPanelSummaryForLandingPage: {
    minHeight: "auto !important",
    backgroundColor: "#302f2f",
    borderBottom: "1px solid " + grayColor[5],
    padding: "25px 10px 5px 0px",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    color: grayColor[2],
    "&:hover": {
      //color: primaryColor[0]
    }
  },
  expansionPanelSummaryExpaned: {
    //color: primaryColor[0],
    "& $expansionPanelSummaryExpandIcon": {
      [theme.breakpoints.up("md")]: {
        top: "auto !important"
      },
      transform: "rotate(180deg)",
      [theme.breakpoints.down("sm")]: {
        top: "10px !important"
      }
    }
  },
  expansionPanelSummaryContent: {
    margin: "0 !important",
  },
  expansionPanelSummaryExpandIcon: {
    [theme.breakpoints.up("md")]: {
      top: "auto !important"
    },
    transform: "rotate(0deg)",
    color: "inherit",
    [theme.breakpoints.down("sm")]: {
      top: "10px !important"
    }
  },
  expansionPanelSummaryExpandIconExpanded: {},
  title: {
    fontSize: "15px",
    fontWeight: "bolder",
    marginTop: "0",
    marginBottom: "0",
    color: "inherit"
  },
  expansionPanelDetails: {
    padding: "5px 10px 5px 10px",
    color: theme.palette.accordion.expanded.color,
    backgroundColor: theme.palette.accordion.expanded.backgroundColor,
  }
});

export default accordionStyle;
