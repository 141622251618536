import React from "react"
import { withStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Paper from "@material-ui/core/Paper"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Button from "components/CustomButtons/Button.jsx"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import AddUser from "./AddUser"
import AddRole from "./AddRole"
import Review from "./Review"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

const managePeopleStyle = (theme) => ({
	// layout: {
	//   width: 'auto',
	//   marginLeft: theme.spacing(2),
	//   marginRight: theme.spacing(2),
	//   [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
	//     width: 600,
	//     marginLeft: theme.spacing(2),
	//     marginRight: theme.spacing(2),
	//   },
	// },
	// above css class causes paper to be extended beyon the parent card
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3)
		}
	},
	stepper: {
		padding: theme.spacing(3, 0, 5)
	},
	buttons: {
		display: "flex",
		justifyContent: "flex-end"
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1)
	}
})

const steps = ["Add User", "User Details", "Send Invitation"]

class ManagePeopleComponent extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			activeStep: 0,
			selectedUsers: [],
			usersRole: ""
		}
	}

	handleNext = () => {
		//deal with onClick Next on stepper-1: Add User
		if (this.state.activeStep == 0 && this.state.selectedUsers.length == 0) {
			this.props.showAlert(
				"warning",
				"Ooops!",
				"No user is selected! Please select at least one user"
			)
			return
		} else if (this.state.activeStep == 0 && !this.state.usersRole) {
			this.props.showAlert("warning", "Ooops!", "No role is selected! Please select a role")
			return
		} else if (this.state.activeStep == 0) {
			let users = this.state.selectedUsers.map((user) => {
				if (!user.role) return { ...user, role: this.state.usersRole }
				return { ...user }
			})
			this.setState({ selectedUsers: users, activeStep: 1 })
			return
		}

		//deal with onClick Next on stepper-2: Add Role
		if (this.state.activeStep == 1) {
			let isValid = true
			let userWithNullRole = null
			this.state.selectedUsers.map((user) => {
				let filteredRole = this.props.availableRoles.filter((r) => r.value == user.role)
				if (!user.role || user.role == "" || filteredRole.length == 0) {
					isValid = false
					if (!userWithNullRole) userWithNullRole = { ...user }
				}
			})
			if (isValid) this.setState({ activeStep: 2 })
			else if (userWithNullRole) {
				let message = "No role is selected for " + (userWithNullRole.name || userWithNullRole.email)
				this.props.showAlert("warning", "Ooops!", message)
			}
			return
		}

		//deal with onClick Next on stepper-3: Review
		if (this.state.activeStep == 2) {
			this.props.setManagePeopleData({ selectedUsers: this.state.selectedUsers }, () => {
				this.setState({ activeStep: 3 })
			})
			return
		}
	}

	handleBack = () => {
		this.setState({ activeStep: this.state.activeStep - 1 })
	}

	setSelectedUsers = (data) => {
		this.setState({ selectedUsers: data })
	}
	setUsersRole = (roleName) => {
		this.setState({ usersRole: roleName })
	}

	getStepContent = (step) => {
		let props1 = {
			setSelectedUsers: this.setSelectedUsers,
			selectedUsers: this.state.selectedUsers,
			availableRoles: this.props.availableRoles,
			usersRole: this.state.usersRole,
			setUsersRole: this.setUsersRole,
			searchUserPromise: this.props.searchUserPromise,
			setManagePeopleData: this.props.setManagePeopleData
		}
		let props2 = {
			availableRoles: this.props.availableRoles,
			selectedUsers: this.state.selectedUsers,
			setSelectedUsers: this.setSelectedUsers
		}
		let props3 = {
			selectedUsers: this.state.selectedUsers
		}
		switch (step) {
			case 0:
				return <AddUser {...props1} />
			case 1:
				return <AddRole {...props2} />
			case 2:
				return <Review {...props3} />
			default:
				throw new Error("Unknown step")
		}
	}

	render() {
		const { classes } = this.props
		return (
			<React.Fragment>
				<CssBaseline />

				<main className={classes.layout}>
					<Paper className={classes.paper}>
						<Typography component="h1" variant="h4" align="center">
							{this.props.title}
						</Typography>
						<Stepper activeStep={this.state.activeStep} className={classes.stepper}>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))}
						</Stepper>
						<React.Fragment>
							{this.state.activeStep === steps.length ? (
								<React.Fragment>
									<Typography variant="h5" gutterBottom>
										Invitations have been successfully sent!
									</Typography>
									<Typography variant="subtitle1">
										If you need to invite more users, click the ADD MORE button to start again.
									</Typography>
									<Button
										className={classes.button}
										color="success"
										onClick={() => {
											this.setState({ activeStep: 0, selectedUsers: [], usersRole: "" })
										}}
									>
										ADD MORE
									</Button>
								</React.Fragment>
							) : (
								<React.Fragment>
									{this.getStepContent(this.state.activeStep)}
									<div className={classes.buttons}>
										{this.state.activeStep !== 0 && (
											<Button onClick={this.handleBack} className={classes.button}>
												Back
											</Button>
										)}
										<Button
											variant="contained"
											color="linkedin"
											disabled={this.state.activeStep == 0 && this.state.selectedUsers.length == 0}
											onClick={this.handleNext}
											className={classes.button}
										>
											{this.state.activeStep === steps.length - 1 ? "INVITE" : "Next"}
										</Button>
									</div>
								</React.Fragment>
							)}
						</React.Fragment>
					</Paper>
				</main>
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK })
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(managePeopleStyle)(ManagePeopleComponent))
